import { makeStyles, Box, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    border: '1px solid',
    borderColor: theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    '&.active': {
      backgroundColor: theme.palette.background.paper,
      borderColor: theme.palette.common.black
    }
  },
  boxTitle: {
    fontSize: '1.1rem',
    fontWeight: 'normal',
    marginBottom: theme.spacing(1.5)
  },
  subtitleBox: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2)
  },
  subtitle: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightBold
  },
  boxNumber: {
    fontSize: '1.8rem',
    fontWeight: theme.typography.fontWeightLight,
    '&.single': {
      paddingTop: theme.spacing(1),
      fontSize: '2.3rem'
    }
  }
}));

interface ValueShape {
  subtitle?: string;
  value?: string | number | React.ReactElement;
}

interface Props {
  title: string;
  leftContent?: ValueShape;
  rightContent?: ValueShape;
  isActive?: boolean;
}

const OutlinedBox = ({ title, leftContent, rightContent, isActive }: Props) => {
  const classes = useStyles();

  return (
    <Box className={`${classes.boxContainer}${isActive ? ' active' : ''}`}>
      <Typography variant="h2" className={classes.boxTitle}>
        {title}
      </Typography>
      <Box className={classes.subtitleBox}>
        {leftContent && (
          <Box flex={0.5}>
            <Typography component="h3" className={classes.subtitle}>
              {leftContent?.subtitle}
            </Typography>
            <Box className={`${classes.boxNumber}${leftContent.subtitle ? '' : ' single'}`}>{leftContent.value}</Box>
          </Box>
        )}
        {rightContent && (
          <Box flex={0.5}>
            <Typography component="h3" className={classes.subtitle}>
              {rightContent.subtitle}
            </Typography>
            <Box className={`${classes.boxNumber}${rightContent.subtitle ? '' : ' single'}`}>{rightContent.value}</Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default OutlinedBox;
