import { Box, IconButton, makeStyles } from '@material-ui/core';

import SortDownIcon from '../../../assets/imgs/SortDownIcon';
import { Column, Sort as ISort } from '../Table.proptype';
import { ASC } from '../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    },
    '&:hover button': {
      opacity: 1,
      color: theme.palette.text.primary
    },
    backgroundColor: ({ isSortActive }: { isSortActive: boolean | null }) =>
      isSortActive ? theme.palette.primary.light : theme.palette.common.white
  },
  button: {
    cursor: 'pointer',
    marginLeft: '4px',
    opacity: ({ isSortActive }: { isSortActive: boolean | null }) => (isSortActive ? 1 : 0),
    color: ({ isSortActive }: { isSortActive: boolean | null }) =>
      isSortActive ? theme.palette.text.primary : 'transparent'
  },
  label: {
    cursor: 'pointer'
  }
}));

interface Props {
  column: Column;
  sort: ISort;
  onSort: (column: Column | null) => void;
}

const Sort = ({ column, sort, onSort }: Props) => {
  const isSortActive = sort?.column === column;
  const classes = useStyles({ isSortActive });

  return (
    <Box
      className={classes.root}
      onClick={() => {
        onSort(column);
      }}
    >
      <Box className={classes.label}>{column.renderHeader ? column.renderHeader() : column.headerName}</Box>
      <IconButton size="small" className={`${classes.button}${isSortActive ? ' sortActive' : ''}`}>
        {
          /** TODO: Logically if sort.dir === ASC, then we should flip the icon. For some reason, it works this way. FIXME */
          sort?.dir === ASC ? (
            <Box>
              <SortDownIcon />
            </Box>
          ) : (
            <Box style={{ transform: 'rotateZ(180deg) translateY(1px)' }}>
              <SortDownIcon />
            </Box>
          )
        }
      </IconButton>
    </Box>
  );
};

export default Sort;
