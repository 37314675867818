import { createAction, Dispatch } from '@reduxjs/toolkit';

import {
  EntityCompletionStatusFromApi,
  Container,
  GetCompletionStatusResponse,
  SaveCompletionStatusResponse
} from '../../models';
import HTTPService, { LambdaResponse } from '../../services/http';

export const completionStatusOnSendingRequest = createAction('entitiesCompletionStatus/onSendingRequest');
export const completionStatusOnReceived = createAction<GetCompletionStatusResponse>(
  'entitiesCompletionStatus/onReceived'
);
export const completionStatusOnFailed = createAction<unknown>('entitiesCompletionStatus/onFailed');
export const completionStatusOnPosted = createAction<SaveCompletionStatusResponse>('entitiesCompletionStatus/onPosted');

type setEntityCompletionStatusProps = {
  entityId: string;
  entityNumber: string;
  subJurisdictionId: string;
  entityCompletionStatusRef: string;
  newStatus: boolean;
  currentContainer: Container;
};

export const setEntityCompletionStatus = ({
  entityId,
  entityNumber,
  subJurisdictionId,
  entityCompletionStatusRef,
  newStatus,
  currentContainer
}: setEntityCompletionStatusProps) => {
  return async (dispatch: Dispatch) => {
    dispatch(completionStatusOnSendingRequest());
    try {
      const newCompletionStatus = {
        containerId: currentContainer.containerId,
        completionStatus: {
          subJurisdictionId,
          entityCompletionStatusRef,
          status: newStatus
        }
      };

      const { data } = await HTTPService.request<LambdaResponse<EntityCompletionStatusFromApi>>({
        method: 'post',
        relativePath: `/v1/entities/${entityId}/completion-status`,
        data: newCompletionStatus
      });
      dispatch(
        completionStatusOnPosted({
          entityNumber,
          completionStatus: data
        })
      );
    } catch (error: unknown) {
      dispatch(completionStatusOnFailed(error));
    }
  };
};
