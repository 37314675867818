import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import DataTypeSelector from './components/DataTypeSelector';
import SimpleAutocomplete from './components/SimpleAutocomplete';

import { DataTypeFilterOption, EntityFilterOption, FilterOption } from '../../../../models/auditTrail.interface';
import TimeRangeSelector from '../../../TimePeriodSelector';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    marginBottom: '21px'
  },
  title: {
    fontSize: '20px',
    letterSpacing: '0.15px'
  },
  top: {
    marginTop: '16px',
    marginLeft: '23px',
    marginRight: '30px',
    display: 'flex',
    alignItems: 'center'
  },
  bottom: {
    marginTop: '16px',
    marginBottom: '26px',
    marginLeft: '23px',
    marginRight: '30px',
    display: 'flex',
    alignItems: 'center'
  },
  timeSelector: {
    marginLeft: '40px'
  },
  label: {
    color: theme.palette.text.secondary,
    fontFamily: 'Basis Grotesque Pro Medium',
    letterSpacing: '0.25px',
    fontSize: '14px',
    lineHeight: '18px',
    marginBottom: '9px'
  },
  dataTypeFilter: {
    width: '700px'
  },
  simpleAutocomplete: {
    width: '250px',
    marginLeft: '15px'
  }
}));

export type FilterControlsProps = {
  startDate: Date;
  endDate: Date;
  entityFilter: EntityFilterOption | null;
  entityFilterOptions: EntityFilterOption[];
  userFilter: FilterOption | null;
  userFilterOptions: FilterOption[];
  dataTypeFilter: DataTypeFilterOption | null;
  dataTypeFilterOptions: DataTypeFilterOption[];
  onDatePeriodSave: (startDate: Date, endDate: Date) => void;
  onDatePeriodClear: () => void;
  onEntityFilterChange: (filter: EntityFilterOption | null) => void;
  onUserFilterChange: (filter: FilterOption | null) => void;
  onDataTypeFilterChange: (filter: DataTypeFilterOption | null) => void;
};

const FilterControls = ({
  startDate,
  endDate,
  entityFilter,
  entityFilterOptions,
  userFilter,
  userFilterOptions,
  dataTypeFilter,
  dataTypeFilterOptions,
  onDatePeriodSave,
  onDatePeriodClear,
  onEntityFilterChange,
  onUserFilterChange,
  onDataTypeFilterChange
}: FilterControlsProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.top}>
        <Typography className={classes.title}>{t('audit-trail')}</Typography>
        <Box className={classes.timeSelector}>
          <TimeRangeSelector
            startDate={startDate}
            endDate={endDate}
            onSave={onDatePeriodSave}
            onClear={onDatePeriodClear}
          />
        </Box>
      </Box>

      <Box className={classes.bottom}>
        <Box className={classes.dataTypeFilter}>
          <Typography variant="h3" className={classes.label}>
            {t('data-type')}
          </Typography>
          <DataTypeSelector
            placeholder={t('search-datatype-eg')}
            options={dataTypeFilterOptions}
            value={dataTypeFilter}
            getOptionLabel={(option) => option.name}
            onChange={(event: any, value: any) => {
              onDataTypeFilterChange(value);
            }}
          />
        </Box>

        <Box className={classes.simpleAutocomplete}>
          <Typography variant="h3" className={classes.label}>
            {t('entity_plural')}
          </Typography>
          <SimpleAutocomplete
            placeholder="Name, Number or Group"
            options={entityFilterOptions}
            value={entityFilter}
            getOptionLabel={(option) => option.name}
            onChange={(event: any, value: any) => {
              onEntityFilterChange(value);
            }}
          />
        </Box>

        <Box className={classes.simpleAutocomplete}>
          <Typography variant="h3" className={classes.label}>
            {t('user')}
          </Typography>
          <SimpleAutocomplete
            placeholder="User"
            options={userFilterOptions}
            value={userFilter}
            getOptionLabel={(option) => option.name}
            onChange={(event: any, value: any) => {
              onUserFilterChange(value);
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default FilterControls;
