import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TFunction } from 'i18next';

import { ReactComponent as AdditionalImportsIcon } from '../../../../assets/img/Icon_Link.svg';
import { ReactComponent as TrialBalanceIcon } from '../../../../assets/img/Icon_Upload Small.svg';
import { ReactComponent as ManualEntryIcon } from '../../../../assets/img/Icon_Users-Multi.svg';
import { useJurisdictions } from '../../../../hooks';
import { AuditTrailFromApi, Level, Step } from '../../../../models';
import { formatDate, getFullStepNameComponents } from '../../../../utils';
import LoadingWrapper from '../../../LoadingWrapper';
import TableWithComment from '../../../TableWithComment';

interface AuditTrailResultsProps {
  response: AuditTrailFromApi;
  isLoading: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    '& .MuiTableContainer-root': {
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '4px'
    },
    flex: '1',
    overflowY: 'auto',
    '& .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-body': {
      borderBottom: `1px solid ${theme.palette.divider}`
    }
  },
  additionalImportsIcon: {
    backgroundColor: theme.palette.secondary.dark,
    height: '30px',
    width: '30px',
    borderRadius: '50%',
    padding: '6px'
  },
  trialBalanceIcon: {
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: '50%',
    height: '30px',
    width: '30px',
    padding: '6.5px 0px 8.5px 0px'
  },
  manualEntryIcon: {
    backgroundColor: theme.palette.progress.lighter,
    borderRadius: '50%',
    height: '30px',
    width: '30px',
    padding: '5px 6px 7px 6px'
  },
  table: {
    '& .MuiTableHead-root > .MuiTableRow-head:first-child': {
      display: 'none'
    },
    '& .MuiTableHead-root > .MuiTableRow-head:nth-child(2)': {
      '& .MuiTableCell-head': {
        paddingTop: theme.spacing(1.2),
        borderBottom: `1px solid #E2E3E6`
      }
    },
    '& .MuiTableBody-root > .MuiTableRow-root:first-child > .MuiTableCell-body': {
      borderTop: 'none'
    },
    '& .MuiTableCell-body': {
      borderTop: '1px solid #E2E3E6',
      borderRight: 'none !important',
      backgroundColor: `${theme.palette.common.white} !important`
    },
    '& .MuiTableHead-root .MuiTableRow-root:nth-child(2) .MuiTableCell-root:first-child,& .MuiTableBody-root .MuiTableCell-body:first-child': {
      paddingLeft: theme.spacing(1.91)
    }
  }
}));

const formatSection = (level: Level, step: Step, t: TFunction) => {
  return `${t(level)} - ${t(step)}`;
};

const formatCreditName = (creditName: string, step: Step) => {
  if (step !== 'credits') {
    return '';
  }

  return `${creditName} - `;
};

const formatRows = (response: AuditTrailFromApi, t: TFunction) => {
  const results = response.records;
  const { entities } = response.filters;
  const { users } = response.filters;

  const formattedRows = results.map((result: any) => {
    const entity = entities.find((entityObj) => entityObj.entityId === result.entityId);
    const user = users.find((userObj) => userObj.userId === result.userId);
    const { level, step } = getFullStepNameComponents(result.stepName);

    result = { ...result };

    result.attributeName = `${formatCreditName(result.creditName, step)}${
      (result.attributeName ?? result.accountDescription) as string
    }`;
    result.section = formatSection(level, step, t);

    if (entity && user) {
      result.entityName = `${String(entity.name)} (${String(entity.entityNumber)})`;
      result.userName = `${String(user.firstName)} ${String(user.lastName)}`;
    }

    result.date = formatDate(result.createdAt, 'MM/DD/YYYY HH:mm');

    return result;
  });
  return formattedRows;
};

const AuditTrailResults = ({ response, isLoading = false }: AuditTrailResultsProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const rows = response ? formatRows(response, t) : [];
  const { entityJurisdictions } = useJurisdictions();
  const subJurisdictionsList = entityJurisdictions ? entityJurisdictions[0]?.subJurisdictions : [];

  const columns = [
    {
      field: 'entityName',
      headerName: t('Entity Name (Number)')
    },
    {
      field: 'section',
      headerName: t('Section')
    },
    {
      field: 'attributeName',
      headerName: t('Data Type'),
      renderCell(row: any) {
        const stateName: string = subJurisdictionsList?.find((state: any) => state.id === row.jurisdictionId)?.name;
        const shouldDisplayStateName = stateName && row.stepName !== 'state.apportionment';
        return shouldDisplayStateName ? t(`${stateName} - ${String(row.attributeName)}`) : row.attributeName;
      }
    },
    {
      field: 'accountNumber',
      headerName: t('Account Number')
    },
    {
      field: 'columnName',
      headerName: t('Data Column'),
      renderCell(row: any) {
        return t(row.columnName);
      }
    },
    {
      field: 'date',
      headerName: t('Date')
    },
    {
      field: 'userName',
      headerName: t('User')
    },
    {
      field: 'value',
      headerName: t('Value')
    },
    {
      field: 'inputTypeId',
      headerName: t('Input Type'),
      renderCell(row: any) {
        if (row.inputTypeId === 1) {
          return <ManualEntryIcon title={t('manual')} className={classes.manualEntryIcon} aria-label="manual-entry" />;
        }

        if (row.inputTypeId === 2) {
          return (
            <TrialBalanceIcon
              title={t('trial-balance')}
              className={classes.trialBalanceIcon}
              aria-label="trial-balance-upload"
            />
          );
        }

        if (row.inputTypeId === 3) {
          return (
            <AdditionalImportsIcon
              title={t('additional-imports')}
              className={classes.additionalImportsIcon}
              aria-label="additional-imports"
            />
          );
        }
      }
    }
  ];

  return (
    <Box className={classes.container}>
      <LoadingWrapper isLoading={isLoading}>
        <TableWithComment hideActionsMenu className={classes.table} columns={columns} rows={rows} />
      </LoadingWrapper>
    </Box>
  );
};

export default AuditTrailResults;
