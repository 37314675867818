import { useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { BrowserRouter, Switch, Redirect, Route, Link } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Business from '@material-ui/icons/Business';
import DashboardOutlined from '@material-ui/icons/DashboardOutlined';
import MonetizationOnOutlined from '@material-ui/icons/MonetizationOnOutlined';
import MultilineChart from '@material-ui/icons/MultilineChart';
import TrackChanges from '@material-ui/icons/TrackChanges';
import { Layout, ReactRouterSideMenu } from '@xbs/xbs-common-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  Analytics,
  Avatar,
  ActionBar,
  Dashboard,
  EntityDetails,
  EntityReview,
  Reports,
  UploadReview,
  AuditTrail
} from '../';
import { ReactComponent as AuditTrailIcon } from '../../assets/img/Icon_AuditTrail.svg';
import { WorldGroupId } from '../../constants';
import { useCategories, useCustomGroups } from '../../hooks';
import useMyWebSocket from '../../hooks/useWebSocket';
import { selectAuthInfo, selectIsAuthenticated } from '../../selectors';
import LocationService from '../../services/location';
import ModalsContainer from '../ModalsContainer';
import TBUploadManager from '../TBUploadManager';
import { hideTBUploadManagerButtonForRoles } from '../UserRoleStylesProvider/constants';

const items = [
  {
    icon: <DashboardOutlined />,
    text: 'Data Dashboard',
    url: '/'
  },
  {
    icon: <Business />,
    text: 'Entity Review',
    url: '/entity-review'
  },
  {
    icon: <MonetizationOnOutlined />,
    text: 'Entity Details',
    url: '/entity-details'
  },
  {
    icon: <TrackChanges />,
    text: 'Reports',
    url: '/reports'
  }
];

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.common.white
  },
  appBar: {
    boxSizing: 'border-box',
    height: '56px',
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: '17px',
    paddingRight: '6px'
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    marginLeft: '2px',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '17px',
    letterSpacing: '0.2px',
    flexGrow: 1
  },
  layout: {
    height: '100%',
    overflow: 'auto'
  }
}));

const Router = () => {
  useCategories();
  const classes = useStyles();
  const { t } = useTranslation();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const { uploadManager: uploadManagerFlag } = useFlags();
  const authInfo = useSelector(selectAuthInfo);

  useEffect(() => {
    if (!isAuthenticated) {
      LocationService.login();
    }
  }, [isAuthenticated]);

  const { customGroups } = useCustomGroups();
  const shouldShowAnalytics: boolean = customGroups.some((group) => group.groupId === WorldGroupId);

  const secondaryItems = useMemo(
    () => [
      ...(shouldShowAnalytics
        ? [
            {
              icon: <MultilineChart />,
              text: 'Analytics',
              url: '/analytics'
            }
          ]
        : []),
      ...[
        {
          icon: <AuditTrailIcon />,
          text: 'Audit Trail',
          url: '/audit-trail'
        }
      ]
    ],
    [shouldShowAnalytics]
  );

  const createTBUploadManagerRoute =
    uploadManagerFlag && !hideTBUploadManagerButtonForRoles.includes(authInfo?.roleUuid);

  // Global websocket handler
  useMyWebSocket({ filter: () => false });

  // TODO: AppBar implemented here, it'll be replaced by common-ui component in a future story
  return isAuthenticated ? (
    <BrowserRouter>
      <ModalsContainer />
      <Switch>
        {/* Exact routes for views taking the whole display */}
        <Route exact path="/upload-review/:uploadId">
          <UploadReview />
        </Route>
        <Route exact path="/reports/entities/:entityNumber/:currencyISO/:reportName">
          <Reports />
        </Route>
        <Route exact path="/reports/groups/:groupId/:currencyISO/:reportName">
          <Reports />
        </Route>
        {createTBUploadManagerRoute && (
          <Route exact path="/upload-manager">
            <TBUploadManager />
          </Route>
        )}
        {/* Other nested routes */}
        <Route>
          <Grid container spacing={0} direction="column" className={classes.root} wrap="nowrap">
            <AppBar position="static" className={classes.appBar} elevation={0}>
              <Toolbar disableGutters>
                <>
                  <IconButton edge="start" color="inherit" aria-label="menu">
                    <Link to="/" style={{ height: '25px', width: '25px' }}>
                      <img src="/imgs/EXACTERA_STANDALONE_SYMBOL_full_color_white.svg" width="25px" height="25px" />
                    </Link>
                  </IconButton>
                  <Typography variant="h1" className={classes.title}>
                    {t('Exactera Tax Provision')}
                  </Typography>
                </>
                <Avatar />
              </Toolbar>
            </AppBar>
            <div className={classes.layout}>
              <Layout
                actionBar={<ActionBar />}
                aside={
                  <>
                    <ReactRouterSideMenu items={items} />
                    {secondaryItems.length > 0 && <ReactRouterSideMenu items={secondaryItems} />}
                  </>
                }
                main={
                  <Switch>
                    <Route path="/entity-details">
                      <EntityDetails />
                    </Route>
                    <Route path="/entity-review">
                      <EntityReview />
                    </Route>
                    <Route path="/reports">
                      <Reports />
                    </Route>
                    {shouldShowAnalytics && (
                      <Route path="/analytics">
                        <Analytics />
                      </Route>
                    )}
                    <Route path="/audit-trail">
                      <AuditTrail />
                    </Route>
                    <Route exact path="/">
                      <Dashboard />
                    </Route>
                    <Redirect to="/" />
                  </Switch>
                }
              />
            </div>
          </Grid>
        </Route>
      </Switch>
    </BrowserRouter>
  ) : null;
};

export default Router;
