import { RefObject } from 'react';

import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    gap: theme.spacing(0.5),
    flexDirection: 'column',
    '& .MuiFab-root': {
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.secondary.light
    }
  }
}));

interface Props {
  zoomInRef: RefObject<HTMLButtonElement>;
  zoomOutRef: RefObject<HTMLButtonElement>;
}

const ZoomButtons = ({ zoomInRef, zoomOutRef }: Props) => (
  <Box className={useStyles().root}>
    <Fab ref={zoomInRef} size="small">
      <Add />
    </Fab>
    <Fab ref={zoomOutRef} size="small">
      <Remove />
    </Fab>
  </Box>
);

export default ZoomButtons;
