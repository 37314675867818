import { Entity, EntityCompletionStatus, Step } from '../../../src/models';
import { getFederalSteps, getStateSteps } from '../../constants';
import { getStepsWithStatus } from '../EntityDetails/utils';

interface EntityCounts {
  distinctJurisdictionsCount: number;
  distinctSubJurisdictionsCount: number;
}

export const countDistinctJurisdictionsAndSubJurisdictions = (entities: Record<string, Entity>): EntityCounts => {
  const jurisdictionIds = new Set();
  const subJurisdictionIds = new Set();

  Object.values(entities).forEach((entity) => {
    jurisdictionIds.add(entity.jurisdictionId);
    entity.subJurisdictionIds.forEach((subId: string) => subJurisdictionIds.add(subId));
  });

  return {
    distinctJurisdictionsCount: jurisdictionIds.size,
    distinctSubJurisdictionsCount: subJurisdictionIds.size
  };
};

const computeEntityStatusFromPercentage = (federalPercentage: number, statePercentage: number) => {
  return federalPercentage === 100 && statePercentage === 100 ? 'completed' : 'inProgress';
};

export const calculateEntityStatusCounts = (
  entitiesCompletionStatus: Record<string, EntityCompletionStatus>,
  entityByNumberMap: any
) => {
  let completedEntities = 0;
  let inProgressEntities = 0;

  const federalSteps = getFederalSteps() as readonly Step[];
  const stateSteps = getStateSteps() as readonly Step[];

  Object.entries(entitiesCompletionStatus).forEach(([entityNumber, entityStatus]) => {
    const numOfStates = entityByNumberMap[entityNumber]?.subJurisdictionIds?.length || 0;

    const { completedFederalSteps, completedStateSteps } = getStepsWithStatus({
      entityCompletionStatus: {
        ...entityStatus,
        numOfStates
      },
      federalSteps,
      stateSteps
    });

    const federalPercentage = Math.trunc((completedFederalSteps.length / federalSteps.length) * 100);
    const statePercentage = numOfStates > 0 ? Math.trunc((completedStateSteps.length / stateSteps.length) * 100) : 100;

    if (computeEntityStatusFromPercentage(federalPercentage, statePercentage) === 'completed') {
      completedEntities++;
    } else {
      inProgressEntities++;
    }
  });

  return {
    completedEntities,
    inProgressEntities
  };
};

export const calculateJurisdictionCounts = (entityByNumberMap: Record<string, Entity>) => {
  const { distinctJurisdictionsCount, distinctSubJurisdictionsCount } = countDistinctJurisdictionsAndSubJurisdictions(
    entityByNumberMap
  );
  return {
    jurisdictionsCount: distinctJurisdictionsCount,
    subJurisdictionsCount: distinctSubJurisdictionsCount
  };
};
