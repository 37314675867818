import { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MoreHoriz from '@material-ui/icons/MoreHoriz';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightBold,
    minHeight: 48
  },
  nameContainer: {
    flexGrow: 1,
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(0.8),
    backgroundColor: 'inherit',
    cursor: 'pointer'
  },
  name: {
    flexGrow: 1,
    padding: theme.spacing(0.65, 1.5),
    backgroundColor: theme.palette.primary.light,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.main
  },
  selectedName: {
    padding: theme.spacing(0.65, 1.42),
    backgroundColor: theme.palette.info.main,
    border: `1px solid ${theme.palette.action.focus}`
  },
  chip: {
    margin: theme.spacing(0, 0.5),
    padding: theme.spacing(1.45, 0),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.light,
    minWidth: '2.25rem'
  },
  selectedChip: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.info.main,
    border: `1px solid ${theme.palette.action.focus}`
  },
  doneButton: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main
  }
}));

const StyledMenu = withStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1)
  }
}))((props) => (
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children?: ReactNode; getContentAnchorEl: ... Remove this comment to see the full error message
  <Menu
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

type Props = {
  name: string;
  count?: number;
  isEditMode?: boolean;
  onDone?: (...args: any[]) => any;
  onEdit?: (...args: any[]) => any;
  onRemove?: (...args: any[]) => any;
};

const AttributeSelector = ({ name, count, isEditMode, onDone, onEdit, onRemove }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const moreActions = useRef();
  const [menuOpen, setMenuOpen] = useState(false);

  function handleCloseMenu() {
    setMenuOpen(false);
  }

  return (
    <Box className={classes.container}>
      <Paper variant="outlined" className={classes.nameContainer} onClick={onEdit}>
        <Box className={`${classes.name} ${isEditMode ? classes.selectedName : ''}`}>{name}</Box>
        {/* FIXME : Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
        <Chip className={`${classes.chip} ${isEditMode ? classes.selectedChip : ''}`} label={count || 0} />
      </Paper>
      {isEditMode ? (
        <Button disableElevation className={classes.doneButton} variant="contained" onClick={onDone}>
          {t('Done')}
        </Button>
      ) : (
        onRemove && (
          <>
            {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
            <IconButton
              ref={moreActions}
              aria-haspopup
              aria-label={t('More actions')}
              onClick={() => {
                setMenuOpen(true);
              }}
            >
              <MoreHoriz />
            </IconButton>
            {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; keepMounted: true; anch... Remove this comment to see the full error message */}
            <StyledMenu keepMounted anchorEl={moreActions.current} open={menuOpen} onClose={handleCloseMenu}>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  onRemove();
                }}
              >
                {t('Remove')}
              </MenuItem>
            </StyledMenu>
          </>
        )
      )}
    </Box>
  );
};

export default AttributeSelector;
