import { ComponentType, Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { EditEntityJurisdictionProps } from './EditEntityJurisdiction';

import { EntityWithRates, SubJurisdiction } from '../../../../models';
import { addSubJurisdictionsToEntity, deleteSubJurisdictionsFromEntity } from '../../../../redux/entities';
import { selectAlterSubJurisdictionsState, selectJurisdictionsList } from '../../../../selectors';

type Props = {
  component: ComponentType<EditEntityJurisdictionProps>;
  entity: EntityWithRates;
  setIsEditingExpandedEntity: Dispatch<SetStateAction<boolean>>;
};

const EditEntityJurisdictionConnector = ({
  component: EditEntityJurisdiction,
  entity,
  setIsEditingExpandedEntity
}: Props) => {
  const dispatch = useDispatch();
  const jurisdictionsList = useSelector(selectJurisdictionsList);
  const alterSubJurisdictionsState = useSelector(selectAlterSubJurisdictionsState);
  const [shouldClose, setShouldClose] = useState<boolean>(false);
  const [buttonsAreDisabled, setButtonsAreDisabled] = useState<boolean>(false);
  const { isLoading, error } = alterSubJurisdictionsState;

  useEffect(() => {
    if (shouldClose) {
      if (isLoading) {
        setButtonsAreDisabled(true);
      } else if (error) {
        setButtonsAreDisabled(false);
        setShouldClose(false);
      } else {
        setButtonsAreDisabled(false);
        setIsEditingExpandedEntity(false);
      }
    }
  }, [error, isLoading, setIsEditingExpandedEntity, shouldClose]);

  const setIsEditingExpandedEntityFalse = () => {
    setIsEditingExpandedEntity(false);
  };

  const handleSave = (selectedSubJurs: SubJurisdiction[]) => {
    const newSubJurIds = selectedSubJurs
      .filter((subJur) => !entity.subJurisdictionIds.includes(subJur.subJurisdictionId))
      .map((subJur) => subJur.subJurisdictionId);

    dispatch(addSubJurisdictionsToEntity({ entityId: entity.entityId, subjurisdictionIds: newSubJurIds }));
    setShouldClose(true);
  };

  const handleDelete = (subJursToDelete: Array<SubJurisdiction['id']>) => {
    dispatch(deleteSubJurisdictionsFromEntity({ entityId: entity.entityId, subjurisdictionIds: subJursToDelete }));
    setShouldClose(true);
  };

  const currentJurisdictionSubJurs = useMemo(() => {
    const currentJurisdictionId = entity.jurisdictionId;
    const currentJurisdictionSubJurs =
      jurisdictionsList.find((jur) => jur.jurisdictionId === currentJurisdictionId)?.subJurisdictions ?? [];

    return currentJurisdictionSubJurs;
  }, [entity, jurisdictionsList]);

  const currentEntitySubJurs = useMemo(
    () =>
      entity.subJurisdictionIds?.map(
        (subJurId) => currentJurisdictionSubJurs.find((subJur) => subJur.id === subJurId)!
      ) ?? [],
    [entity, currentJurisdictionSubJurs]
  );

  return (
    <EditEntityJurisdiction
      isLoading={isLoading}
      buttonsAreDisabled={buttonsAreDisabled}
      currentJurisdictionSubJurs={currentJurisdictionSubJurs}
      currentEntitySubJurs={currentEntitySubJurs}
      onCancel={setIsEditingExpandedEntityFalse}
      onSave={handleSave}
      onDelete={handleDelete}
    />
  );
};

export default EditEntityJurisdictionConnector;
