import IconButton from '@material-ui/core/IconButton';
import MoreHoriz from '@material-ui/icons/MoreHoriz';

import { MoreActionsButton } from '../..';
import { ActionsCellMoreActionsButtonProps } from '../../../models';

const ActionsCellMoreActionsButton = ({
  displayGroupActions,
  displayCategoryActions,
  handleOnCommentsClick,
  handleOnRowDelete,
  toggleEditCategoryDrawer,
  hasNewRow,
  row,
  t
}: ActionsCellMoreActionsButtonProps) => {
  if (row.showGroupActions === false) {
    return null;
  }

  if (row.groupActions) {
    return <MoreActionsButton actions={row.groupActions} />;
  }

  const groupActions = row.isNew
    ? [{ label: t('Delete'), onClick: handleOnRowDelete, disabled: hasNewRow && !row.isNew }]
    : [{ label: t('Edit Entities'), onClick: handleOnCommentsClick, disabled: row.isNew }];

  if (displayCategoryActions) {
    return (
      <MoreActionsButton
        actions={[
          {
            label: t('Edit'),
            onClick: () => {
              toggleEditCategoryDrawer(row);
            }
          },
          ...(handleOnRowDelete ? [{ label: t('Delete'), onClick: handleOnRowDelete }] : [])
        ]}
      />
    );
  }

  if (handleOnRowDelete) {
    if (displayGroupActions) {
      return <MoreActionsButton actions={groupActions} />;
    }

    return (
      <MoreActionsButton
        actions={[
          { label: t('Delete'), onClick: handleOnRowDelete, disabled: hasNewRow && !row.isNew },
          { label: t('Add Note'), onClick: handleOnCommentsClick, disabled: hasNewRow }
        ]}
      />
    );
  }

  return (
    <IconButton data-testid="more-actions-button" size="small" onClick={handleOnCommentsClick}>
      <MoreHoriz />
    </IconButton>
  );
};

export default ActionsCellMoreActionsButton;
