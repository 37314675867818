import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { UserRoles } from '@xbs/xbs-enums';

import { DottedProgress, DonutChart } from '..';
import { LEVELS, getFederalSteps, getStateSteps } from '../../../../constants';
import { useCompletionStatus, useCurrencies } from '../../../../hooks';
import { EntityWithJurisdictionName, CompletionStatus, Step } from '../../../../models';
import { selectUserRoleUuid } from '../../../../selectors';
import BoxedText from '../../../BoxedText';
import CurrencyWithSymbol from '../../../CurrencyWithSymbol';
import { getStepsWithStatus } from '../../utils';
import ArrowIcon from '../ArrowIcon';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1.5, 2),
    borderRadius: 5,
    background: theme.palette.common.white
  },
  currencyWithSymbol: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  innerContainer: {
    flexGrow: 1,
    flexBasis: 0,
    fontFamily: theme.typography.body2.fontFamily,
    letterSpacing: theme.typography.body2.letterSpacing,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight
  },
  status: {
    marginLeft: theme.spacing(1),
    '&.review': {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 2
    }
  },
  jurisdictionBox: {
    color: theme.palette.primary.main,
    display: 'flex',
    gap: theme.spacing(0.5)
  },
  dottedProgressLabel: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'fit-content',
    '& .MuiSvgIcon-root': {
      width: 16,
      height: 16
    }
  },
  statusLabel: {
    color: theme.palette.text.secondary
  },
  entityNameText: {
    lineHeight: '18px'
  },
  entityNumberText: {
    color: theme.palette.text.secondary,
    lineHeight: '14px',
    letterSpacing: '0.6px'
  }
}));

const DottedProgressLabel = ({ text, link }: { text: string; link: string }) => {
  const classes = useStyles();

  return (
    <Link
      className={classes.dottedProgressLabel}
      to={link}
      onClick={(evt) => {
        evt.stopPropagation();
      }}
    >
      <Box display="flex" alignItems="center">
        <Typography variant="body2">{text}</Typography>
        <ArrowIcon />
      </Box>
    </Link>
  );
};

function computeOverallStatus({ state = {}, federal = {} }: CompletionStatus, numOfStates: number) {
  const federalSteps = getFederalSteps();
  const stateSteps = getStateSteps();
  const isFederalFullyComplete = federalSteps.every((step) => Boolean(federal[step as Step]?.[0]));
  const isStateFullyComplete = stateSteps.every((step) => {
    const stepStatusesPerJurId = state[step as Step] ?? {};
    const completedStates = Object.values(stepStatusesPerJurId).filter(Boolean);
    const stepNumOfStates = ['rtp', 'nol', 'credits', 'accounts'].includes(step) ? numOfStates : 1;
    return completedStates.length === stepNumOfStates;
  });

  return isFederalFullyComplete && isStateFullyComplete ? 'complete' : 'input';
}

type EntityProgressProp = {
  source: EntityWithJurisdictionName;
};

const EntityProgress = ({ source }: EntityProgressProp) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { entityCompletionStatus } = useCompletionStatus(source.entityNumber);
  const { currencyById } = useCurrencies();
  const federalSteps = getFederalSteps() as readonly Step[];
  const stateSteps = getStateSteps() as readonly Step[];
  const roleUuid = useSelector(selectUserRoleUuid);
  const isUserReviewer = roleUuid === UserRoles.ByName.ProReviewer.RoleUuid;
  const status = computeOverallStatus(
    entityCompletionStatus?.completionStatus ?? {},
    entityCompletionStatus.numOfStates
  );
  const history = useHistory();

  function handleNavigation(jurisdictionType: string) {
    return (step: Step | React.MouseEvent) => {
      history.push(
        `/entity-details/${source.entityNumber}/${jurisdictionType}/${typeof step === 'string' ? step : ''}`
      );
    };
  }

  const {
    completedFederalSteps,
    completedStateSteps,
    inProgressFederalSteps,
    inProgressStateSteps
  } = getStepsWithStatus({
    entityCompletionStatus,
    isUserReviewer,
    federalSteps,
    stateSteps
  });

  return !isUserReviewer || completedFederalSteps.length > 0 || completedStateSteps.length > 0 ? (
    <Paper variant="outlined" className={classes.container}>
      <Grid container>
        <Grid container className={classes.innerContainer} alignItems="center" spacing={1}>
          <Grid item xs>
            <Typography variant="body2" className={classes.entityNameText}>
              {source.name}
            </Typography>
            <Typography variant="caption" className={classes.entityNumberText}>{`${t('ID')} ${
              source.entityNumber
            }`}</Typography>
          </Grid>
          <Grid item xs>
            <Box className={classes.jurisdictionBox}>
              <BoxedText>{source.isoCode}</BoxedText>
              {source.subJurisdictionIds.length > 0 && (
                <BoxedText isOneLine>{`+ ${source.subJurisdictionIds.length} ${t('STATES')}`}</BoxedText>
              )}
              <CurrencyWithSymbol
                className={classes.currencyWithSymbol}
                isoCode={currencyById[source.currencyId]?.isoCode}
              />
            </Box>
          </Grid>
          <Grid item xs>
            <Box display="flex">
              <Typography className={classes.statusLabel}>{t('Status')}</Typography>
              <Box component="span" className={`${classes.status} ${status}`}>
                {t(`entity-${status}`)}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container className={classes.innerContainer} alignItems="center" spacing={1}>
          {(completedFederalSteps.length > 0 || !isUserReviewer) && (
            <Grid item xs>
              <Box display="flex">
                <Box mr={1}>
                  <DottedProgressLabel text={t('Federal')} link={`/entity-details/${source.entityNumber}/federal`} />
                  <DottedProgress
                    entityNumber={source.entityNumber}
                    level={LEVELS.FEDERAL}
                    steps={federalSteps}
                    completed={completedFederalSteps}
                    inProgress={inProgressFederalSteps}
                    isUserReviewer={isUserReviewer}
                    onNavigate={handleNavigation('federal')}
                  />
                </Box>
                <DonutChart percentage={Math.trunc((completedFederalSteps.length / federalSteps.length) * 100)} />
              </Box>
            </Grid>
          )}
          <Grid item xs>
            {(!isUserReviewer || completedStateSteps.length > 0) && source.subJurisdictionIds.length > 0 && (
              <Box display="flex" flexGrow={1}>
                <Box mr={1}>
                  <DottedProgressLabel text={t('State')} link={`/entity-details/${source.entityNumber}/state`} />
                  <DottedProgress
                    entityNumber={source.entityNumber}
                    level={LEVELS.STATE}
                    steps={stateSteps}
                    completed={completedStateSteps}
                    inProgress={inProgressStateSteps}
                    isUserReviewer={isUserReviewer}
                    onNavigate={handleNavigation('state')}
                  />
                </Box>
                <DonutChart percentage={Math.trunc((completedStateSteps.length / stateSteps.length) * 100)} />
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  ) : null;
};

export default EntityProgress;
