import { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { CategoryFromApi } from '../../models';
import { categoriesOnSendingRequest, categoriesOnReceived, categoriesOnFailed } from '../../redux/categories';
import { selectCategories } from '../../selectors/categories';
import HTTPService, { LambdaResponse } from '../../services/http';

export function useCategories() {
  const dispatch = useDispatch();
  const categoriesState = useSelector(selectCategories);
  const [isLoading, setIsLoading] = useState(false);

  const isNameUnique = useCallback(
    (categoryName: string, currentRow = {}): boolean => {
      const { categories } = categoriesState;

      const otherCategories = categories.filter((category) => category.categoryId !== currentRow.id);

      const result = !otherCategories.some(
        ({ name }) => name.trim().toLocaleLowerCase() === categoryName.trim().toLocaleLowerCase()
      );

      return result;
    },
    [categoriesState]
  );

  const isColorUnique = useCallback(
    (categoryColor: string, currentRow = {}): boolean => {
      const { categories } = categoriesState;

      const otherCategories = categories.filter((category) => category.categoryId !== currentRow.id);

      const result = !otherCategories.some(({ color }) => categoryColor === color);

      return result;
    },
    [categoriesState]
  );

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    dispatch(categoriesOnSendingRequest());
    try {
      const { data: categories } = await HTTPService.request<LambdaResponse<CategoryFromApi[]>>({
        method: 'get',
        relativePath: '/v1/categories'
      });
      dispatch(categoriesOnReceived(categories));
    } catch (error: unknown) {
      dispatch(categoriesOnFailed(error as any));
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading, dispatch]);

  useEffect(() => {
    if (!categoriesState.isLoaded && !isLoading && !categoriesState.error) {
      void fetchData();
    }
  }, [dispatch, categoriesState, isLoading, fetchData]);

  return {
    fetchData,
    isNameUnique,
    isColorUnique,
    ...categoriesState
  };
}
