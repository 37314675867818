import { useCallback, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { CategoriesPopoverOptions } from './CategoriesPopover';

import { useCategoryUpdate } from '../../../../hooks/useCategoryUpdate';
import { CategoryFromApi } from '../../../../models';
import { enqueueNotification } from '../../../../redux/notifications';
import { selectCategories } from '../../../../selectors/categories';

interface CategoryOption extends CategoryFromApi {
  isSelected?: boolean;
}

const getCategoryOptionsForRow = (
  allCategories: CategoryFromApi[],
  currentCategory: CategoryFromApi | undefined,
  originalStepName: string
): CategoryOption[] => {
  return allCategories
    .filter((category) => {
      return category.accounts.some(({ stepName }) => {
        // Categorized items belonging to federal.temporary categories can be of federal.temporary.incomeStatement
        // or federal.temporary.balanceSheet type, so compare just with the federal.temporary prefix

        if (originalStepName.includes('federal.temporary') && stepName.includes('federal.temporary')) {
          return true;
        }

        return originalStepName === stepName;
      });
    })
    .map((cat) => ({
      ...cat,
      isSelected: currentCategory && cat.name === currentCategory.name
    }));
};

const withDataCategories = (CategoriesComponent: any) => {
  interface Props {
    [key: string]: any;
    singleSelected?: boolean;
    type?: 'edit' | 'create';
    isOpen?: boolean;
    accountName: string;
    category?: CategoryFromApi;
    updateHandler?: (loading: boolean, category?: CategoryFromApi) => void;
    closeHandler?: () => void;
  }

  const WithDataCategories = ({
    singleSelected,
    accountName,
    isOpen = false,
    type = 'create',
    category,
    originalStepName,
    updateHandler = () => null,
    closeHandler = () => null,
    ...props
  }: Props) => {
    const { categories } = useSelector(selectCategories);
    const { updateCategoryAccounts } = useCategoryUpdate();
    const [isLoading, setIsLoading] = useState<boolean>(isOpen);
    const dispatch = useDispatch();

    const categoryOptionsForRow = useMemo(() => {
      return getCategoryOptionsForRow(categories, category, originalStepName);
    }, [categories, category, originalStepName]);

    const editingCategory = useCallback(
      (loading: boolean, category?: CategoryFromApi) => {
        setIsLoading(loading);
        updateHandler(loading, category);
      },
      [updateHandler]
    );

    const updateCategory = useCallback(
      async (selected: CategoriesPopoverOptions[]) => {
        if (selected.length === 0) {
          return;
        }

        const selectedName: string = selected[0].name;

        const currentCategory: CategoryFromApi | undefined = categoryOptionsForRow.find(
          ({ name }: CategoryFromApi) => name === selectedName
        );

        if (!currentCategory) {
          return;
        }

        if (type === 'edit' && (category?.accounts?.length ?? 0) <= 1) {
          dispatch(
            enqueueNotification({
              message: 'The category needs to be associated with at least one account.',
              options: {
                variant: 'warning'
              }
            })
          );
        }

        closeHandler();
        editingCategory(true);
        await updateCategoryAccounts(currentCategory.categoryId, 'ADD', [[accountName, originalStepName]]);
        if (category && type === 'edit') {
          await updateCategoryAccounts(category.categoryId, 'DELETE', [[accountName, originalStepName]]);
        }

        editingCategory(false, currentCategory);
      },
      [
        categoryOptionsForRow,
        type,
        category,
        closeHandler,
        editingCategory,
        originalStepName,
        updateCategoryAccounts,
        accountName,
        dispatch
      ]
    );

    return categoryOptionsForRow.length > 0 ? (
      <CategoriesComponent
        stepName={originalStepName}
        singleSelected={singleSelected}
        isLoading={isLoading}
        options={categoryOptionsForRow}
        isOpen={isOpen}
        category={category}
        onUpdate={updateCategory}
        onClose={closeHandler}
        {...props}
      />
    ) : (
      <div />
    );
  };

  return WithDataCategories;
};

export default withDataCategories;
