import { createAction, Dispatch } from '@reduxjs/toolkit';

import { RootState } from '..';
import { PeriodRates, ExchangeRatesFromAPI, RateUpdate, TaxRatesFromAPI, TaxRateUpdate } from '../../models';
import { selectRatesForJurisdiction, selectRatesForEntityJurisdiction } from '../../selectors';
import HTTPService from '../../services/http';

interface RatesFromAPI {
  taxRates: TaxRatesFromAPI;
  exchangeRates: ExchangeRatesFromAPI;
}
interface RateUpdateOnFailed {
  error?: unknown;
  rateUpdate: RateUpdate;
  failureReason?: string;
}

export const ratesOnReceived = createAction<RatesFromAPI>('rates/onReceived');
export const ratesOnSendingRequest = createAction('rates/onSendingRequest');
export const ratesOnFailed = createAction<unknown>('rates/onFailed');
export const ratesOnJurisdictionTaxUpdateRequest = createAction<RateUpdate>('rates/onJurisdictionTaxUpdateRequest');
export const ratesOnRequestSuccess = createAction('rates/onRequestSuccess');
export const ratesOnJurisdictionTaxUpdateFailed = createAction<RateUpdateOnFailed>(
  'rates/onJurisdictionTaxUpdateFailed'
);
export const ratesOnEntityRateUpdateRequest = createAction<Required<RateUpdate>>('rates/onEntityRateUpdateRequest');
export const ratesOnEntityRateUpdateFailed = createAction<RateUpdateOnFailed>('rates/onEntityRateUpdateFailed');
export const ratesOnInvalidCellUpdate = createAction<RateUpdateOnFailed>('rates/ratesOnFailedCellsUpdate');

export const fxRatesOnCurrencyUpdateRequest = createAction<RateUpdate>('rates/onFxRatesOnCurrencyUpdateRequest');
export const fxRatesOnEntityRateUpdateRequest = createAction<RateUpdate>('rates/onEntityFxRateUpdateRequest');
export const fxRatesOnInvalidCellUpdate = createAction<RateUpdateOnFailed>('rates/fxRatesOnFailedCellsUpdate');
export const fxRatesOnEntityRateUpdateFailed = createAction<RateUpdateOnFailed>('rates/OnEntityFxRateUpdateFailed');
export const fxRatesOnJurisdictionFxUpdateFailed = createAction<RateUpdateOnFailed>(
  'rates/onJurisdictionFxUpdateFailed'
);

const getOldRate = (getState: () => RootState, rateUpdate: RateUpdate) => {
  const { jurisdictionId, currencyId, entityId, rateType, rateDomain } = rateUpdate as TaxRateUpdate;
  const key = rateDomain === 'tax' ? 'taxRates' : 'foreignExchangeRates';
  const jurRates = selectRatesForJurisdiction(jurisdictionId, currencyId)(getState())[key] as PeriodRates;
  const oldJurisdictionRate = jurRates[rateType];
  const entityRates = entityId
    ? selectRatesForEntityJurisdiction(entityId, jurisdictionId, currencyId)(getState())
    : null;
  const oldEntityRate = entityRates ? entityRates[key]?.[rateType] : null;
  const oldRate = oldEntityRate ?? oldJurisdictionRate;

  return oldRate;
};

export const updateTaxRate = (rateUpdate: RateUpdate) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const { entityId, rateType, rate } = rateUpdate;
    const haveEntityId = entityId !== undefined && entityId !== null;
    rateUpdate.rate = rate;
    if (Math.abs(rate) > 0.999999) {
      dispatch(
        ratesOnInvalidCellUpdate({
          rateUpdate,
          failureReason: 'Please enter a tax rate lower than 99.9999%'
        })
      );
      return;
    }

    const oldRate = getOldRate(getState, rateUpdate);
    if (rate === oldRate) {
      return;
    }

    const taxRateInfo = {
      ...rateUpdate,
      taxRateType: rateType,
      taxRate: rate
    };

    if (haveEntityId) {
      dispatch(ratesOnEntityRateUpdateRequest(rateUpdate as Required<RateUpdate>));
    } else {
      dispatch(ratesOnJurisdictionTaxUpdateRequest(rateUpdate));
    }

    try {
      await HTTPService.request({
        method: 'post',
        relativePath: `/v1/tax-rates`,
        data: { taxRateInfo }
      });

      dispatch(ratesOnRequestSuccess());
    } catch (error: unknown) {
      rateUpdate.rate = Number(oldRate);
      if (haveEntityId) {
        dispatch(ratesOnEntityRateUpdateFailed({ error, rateUpdate }));
      } else {
        dispatch(ratesOnJurisdictionTaxUpdateFailed({ error, rateUpdate }));
      }
    }
  };
};

export const updateExchangeRate = (rateUpdate: RateUpdate) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    let { entityId, rateType, rate } = rateUpdate;
    const haveEntityId = entityId !== undefined && entityId !== null;
    const SUPPORTED_FRACTIONAL_DIGITS = 1e6;

    rate = Math.round((rate + Number.EPSILON) * SUPPORTED_FRACTIONAL_DIGITS) / SUPPORTED_FRACTIONAL_DIGITS;
    rateUpdate.rate = rate;
    let failureReason;
    if (rate >= 1e9) {
      failureReason = 'Please enter an exchange rate that is lower than 999,999,999';
    } else if (rate <= 0) {
      failureReason = 'Please enter an exchange rate that is greater than 0';
    }

    if (failureReason) {
      dispatch(
        fxRatesOnInvalidCellUpdate({
          rateUpdate,
          failureReason
        } as { rateUpdate: RateUpdate; failureReason?: string })
      );
      return;
    }

    const oldRate = getOldRate(getState, rateUpdate);
    if (rate === oldRate) {
      return;
    }

    const exchangeRateInfo = {
      ...rateUpdate,
      rateType,
      rate
    };

    if (haveEntityId) {
      dispatch(fxRatesOnEntityRateUpdateRequest(rateUpdate as Required<RateUpdate>));
    } else {
      dispatch(fxRatesOnCurrencyUpdateRequest(rateUpdate));
    }

    try {
      await HTTPService.request({
        method: 'post',
        relativePath: `/v1/exchange-rates`,
        data: { exchangeRateInfo }
      });

      dispatch(ratesOnRequestSuccess());
    } catch (error: unknown) {
      rateUpdate.rate = Number(oldRate);
      if (haveEntityId) {
        dispatch(fxRatesOnEntityRateUpdateFailed({ error, rateUpdate } as { error?: string; rateUpdate: RateUpdate }));
      } else {
        dispatch(fxRatesOnJurisdictionFxUpdateFailed({ error, rateUpdate }));
      }
    }
  };
};
