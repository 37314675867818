import { HoveredState } from './GeoMap.proptype';

import { JurisdictionById, ReportValue } from '../../../../models';
import { formatPercentage, getReportInfoByEntityId } from '../../../../utils';

export const getFetchingParams = (entities: any, jurisdictionById: JurisdictionById) => {
  const results = { countryCodes: [], subJurisdictionNames: [] };
  for (const { jurisdictionId } of entities) {
    const jurisdiction = jurisdictionById[jurisdictionId];
    if (jurisdiction) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      results.countryCodes.push(jurisdiction.isoCode);
      if (jurisdiction.subJurisdictions) {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        results.subJurisdictionNames.push(...jurisdiction.subJurisdictions.map(({ name }: any) => name));
      }
    }
  }

  return results;
};

export function calculateEtr(entities: any, jurisdictionById: JurisdictionById, report: any) {
  const entitiesByIso = new Map();
  for (const { id, jurisdictionId } of entities) {
    const { isoCode } = jurisdictionById[jurisdictionId] || {};
    const { effectiveTaxRateRaw, totalProvision } = getReportInfoByEntityId(report, id);

    let jurisdictionEntities = entitiesByIso.get(isoCode);
    if (!jurisdictionEntities) {
      jurisdictionEntities = [];
      entitiesByIso.set(isoCode, jurisdictionEntities);
    }

    jurisdictionEntities.push({
      id,
      ptbi: effectiveTaxRateRaw?.ptbi,
      totalProvision,
      states: effectiveTaxRateRaw?.states
    });
  }

  const etrByIso = new Map();
  for (const [isoCode, entities] of entitiesByIso) {
    const sum = { ptbi: 0, totalProvision: 0, stateEtr: {} };
    for (const { ptbi, totalProvision, states } of entities) {
      sum.ptbi += ptbi;
      sum.totalProvision += totalProvision;
      for (const { name, value } of states || []) {
        // FIXME : Fix this the next time the file is edited.
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        sum.stateEtr[name] = (sum.stateEtr[name] || 0) + value;
      }
    }

    etrByIso.set(isoCode, {
      federal: sum.totalProvision / sum.ptbi,
      states: sum.stateEtr
    });
  }

  return etrByIso;
}

export const getHoveredValue = (
  hovered: HoveredState,
  jurisdictions: JurisdictionById,
  extractedValues: ReportValue[]
) => {
  if (!hovered) return;

  const jurisdictionsArray = Object.values(jurisdictions);

  const jurisdiction = jurisdictionsArray.find((j) => j.isoCode === hovered.iso);

  if (!jurisdiction) {
    return;
  }

  const valueObject = extractedValues.find((item) => item.name === jurisdiction.jurisdictionId);

  return valueObject?.value;
};

export const formatShortenedCurrency = (value: number): string => {
  if (value === null) {
    return '$0';
  }

  let isNegative = false;
  if (value < 0) {
    isNegative = true;
    value = Math.abs(value);
  }

  let result: string;

  if (value >= 1_000_000_000) {
    result = `$${(value / 1_000_000_000).toFixed(1)}B`;
  } else if (value >= 1_000_000) {
    result = `$${(value / 1_000_000).toFixed(1)}M`;
  } else if (value >= 1_000) {
    result = `$${(value / 1_000).toFixed(1)}K`;
  } else {
    result = `$${value.toFixed(2)}`;
  }

  if (isNegative) {
    result = `(${result})`;
  }

  return result;
};

export const formatHoveredValue = (type: string, value: number | undefined): string => {
  if (!value) return 'N/A';

  switch (type) {
    case 'effectiveTaxRate':
      return formatPercentage(value, '-', 4);
    case 'totalProvision':
    case 'deferredTaxAsset':
    case 'preTaxBookIncome':
      return formatShortenedCurrency(value);
    default:
      return value.toString();
  }
};

export const getMapValues = (selectedCard: string, reportValues: ReportValue[], totalProvisionToggle: string) => {
  let extractedValues: ReportValue[] = [];

  switch (selectedCard) {
    case 'effectiveTaxRate':
      extractedValues = reportValues.filter(
        (item: ReportValue) => item.columnMeta?.value?.formula?.includes('etr') && item.type !== 'total'
      );
      break;

    case 'totalProvision':
      if (totalProvisionToggle === 'Total') {
        extractedValues = reportValues.filter(
          (item: ReportValue) =>
            item.columnMeta?.value?.formula?.includes('summaryTotalOverallProvision') && item.type !== 'total'
        );
      } else if (totalProvisionToggle === 'Deferred') {
        extractedValues = reportValues.filter(
          (item: ReportValue) =>
            item.columnMeta?.value?.formula?.includes('summaryTotalDeferredProvision') && item.type !== 'total'
        );
      } else if (totalProvisionToggle === 'Current') {
        extractedValues = reportValues.filter(
          (item: ReportValue) =>
            item.columnMeta?.value?.formula?.includes('summaryTotalCurrentProvision') && item.type !== 'total'
        );
      }

      break;

    case 'deferredTaxAsset':
      extractedValues = reportValues.filter(
        (item: ReportValue) =>
          item.columnMeta?.value?.formula?.includes('summaryTotalDeferredTaxAssetLiability') && item.type !== 'total'
      );
      break;

    case 'preTaxBookIncome':
      extractedValues = reportValues.filter(
        (item: ReportValue) =>
          item.columnMeta?.value?.formula?.includes('summaryPreTaxBookIncome') && item.type !== 'total'
      );
      break;

    default:
      console.warn(`Unexpected value for selectedCard: ${selectedCard}`);
      break;
  }

  return extractedValues;
};

export const getDashboardBoxes = (reportValues: any[], names: string[]) => {
  const values: { [key: string]: any } = {};

  names.forEach((name) => {
    const item = reportValues.find((item) => item.name === name);
    values[`${name}Value`] = item?.value || null;
  });

  return values;
};
