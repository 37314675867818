import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { EtrAnalyzer, TrendTable, TrendChart, getTrendAnalysis } from './components';
import TotalBarTrendAnalysis from './components/TotalBarTrendAnalysis';

import { TitleNavigation, ToggleButtons } from '..';
import { useContainers, useJurisdictions, useDimensions, useMiscReport } from '../../hooks';
import { Container, TrendAnalysis } from '../../models';
import { selectContainerPriorToCurrentContainerTaxYear } from '../../selectors';

const useStyles = makeStyles((theme) => ({
  trendToggle: {
    padding: theme.spacing(1),
    alignSelf: 'flex-end',
    marginRight: '13px'
  },
  etrContainer: {
    backgroundColor: theme.palette.primary.light,
    position: 'relative',
    flexGrow: 1,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius
  },
  trendContainer: {
    backgroundColor: theme.palette.primary.light,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    flex: 1,
    overflow: 'auto'
  },
  trendBoxContainerChart: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    border: '1px solid',
    borderColor: theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
    height: '100%',
    overflow: 'auto'
  },
  trendBoxContainerTable: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    border: '1px solid',
    borderColor: theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
    height: '500px',
    width: 'fit-content',
    maxWidth: '1622px'
  }
}));

type TrendRouterProps = {
  currentContainer?: Container;
  containers?: Container[];
  hasContainersLoaded?: boolean;
  baselineTrendAnalysis: TrendAnalysis;
  comparisonTrendAnalysis: TrendAnalysis;
  previousContainer: Container;
  selectedBaseContainerId?: string;
  selectedPreviousContainerId?: string;
  handleChange(val: Container, dropdown: string): void;
};

const TrendRouter = ({
  currentContainer,
  containers,
  hasContainersLoaded,
  baselineTrendAnalysis,
  comparisonTrendAnalysis,
  previousContainer,
  selectedBaseContainerId,
  selectedPreviousContainerId,
  handleChange
}: TrendRouterProps) => {
  const trendTableTab = 'table';
  const trendChartTab = 'chart';
  const classes = useStyles();
  const { t } = useTranslation();
  const [tab, setTab] = useState(trendChartTab);
  const { width, wrapperRef: tableContainerRef } = useDimensions();

  const totalTaxEffectedDifference =
    (baselineTrendAnalysis.totalProvision?.taxEffectedAmount ?? 0) -
    (comparisonTrendAnalysis.totalProvision?.taxEffectedAmount ?? 0);

  const totalTaxRateDifference =
    (baselineTrendAnalysis.totalProvision?.taxRate ?? 0) - (comparisonTrendAnalysis.totalProvision?.taxRate ?? 0);

  return (
    <Box className={classes.trendContainer}>
      <Box
        ref={tableContainerRef}
        className={tab === trendChartTab ? classes.trendBoxContainerChart : classes.trendBoxContainerTable}
      >
        <div className={classes.trendToggle}>
          <ToggleButtons
            variant="contained"
            buttons={[
              {
                label: t('Chart'),
                isActive: tab === trendChartTab,
                onClick: () => {
                  setTab(trendChartTab);
                }
              },
              {
                label: t('Table'),
                isActive: tab === trendTableTab,
                onClick: () => {
                  setTab(trendTableTab);
                }
              }
            ]}
          />
        </div>
        {hasContainersLoaded || !currentContainer ? (
          <CircularProgress />
        ) : tab === trendChartTab ? (
          <TrendChart
            currentPeriod={currentContainer}
            periods={containers ?? []}
            previousPeriod={previousContainer}
            baselineTrendAnalysis={baselineTrendAnalysis}
            comparisonTrendAnalysis={comparisonTrendAnalysis}
            selectedBaseContainerId={selectedBaseContainerId}
            selectedPreviousContainerId={selectedPreviousContainerId}
            handleChange={handleChange}
          />
        ) : (
          <TrendTable
            currentPeriod={currentContainer}
            periods={containers ?? []}
            previousPeriod={previousContainer}
            baselineTrendAnalysis={baselineTrendAnalysis}
            comparisonTrendAnalysis={comparisonTrendAnalysis}
            selectedBaseContainerId={selectedBaseContainerId}
            selectedPreviousContainerId={selectedPreviousContainerId}
            handleChange={handleChange}
          />
        )}
      </Box>
      {tab === trendTableTab ? (
        <TotalBarTrendAnalysis
          totalTaxEffected={baselineTrendAnalysis.totalProvision?.taxEffectedAmount}
          totalTaxRate={baselineTrendAnalysis.totalProvision?.taxRate}
          totalTaxEffectedComparison={comparisonTrendAnalysis.totalProvision?.taxEffectedAmount}
          totalTaxRateComparison={comparisonTrendAnalysis.totalProvision?.taxRate}
          totalTaxEffectedDifference={totalTaxEffectedDifference}
          totalTaxRateDifference={totalTaxRateDifference}
          width={width ? `${width}px` : ''}
        />
      ) : null}
    </Box>
  );
};

const Analytics = () => {
  const classes = useStyles();
  const { currentContainer, containers, isLoading: hasContainersLoaded } = useContainers();
  const { jurisdictions, isLoading: hasJurisdictionsLoaded } = useJurisdictions();
  const initialPreviousContainer = useSelector(selectContainerPriorToCurrentContainerTaxYear);
  const [selectedPreviousContainer, setSelectedPreviousContainer] = useState<any>(initialPreviousContainer);
  const [selectedBaseContainer, setSelectedBaseContainer] = useState<any>(currentContainer);

  const { report: worldwideReport } = useMiscReport(
    'group',
    'worldwide-consolidated',
    'rate-reconciliation-summary',
    'USD'
  );

  const { report: previousReport } = useMiscReport(
    'group',
    'worldwide-consolidated',
    'rate-reconciliation-summary',
    'USD',
    selectedPreviousContainer?.containerId
  );

  const { report: baselineReport } = useMiscReport(
    'group',
    'worldwide-consolidated',
    'rate-reconciliation-summary',
    'USD',
    selectedBaseContainer?.containerId
  );

  const baselineTrendAnalysis = getTrendAnalysis(baselineReport);
  const comparisonTrendAnalysis = getTrendAnalysis(previousReport);

  const handleChange = (val: Container, dropdown: string) => {
    if (dropdown === 'base') {
      setSelectedBaseContainer(val);
    }

    if (dropdown === 'compare') {
      setSelectedPreviousContainer(val);
    }
  };

  const tabs = [
    {
      value: 'trend',
      render: () => (
        <TrendRouter
          currentContainer={currentContainer}
          containers={containers}
          hasContainersLoaded={hasContainersLoaded}
          baselineTrendAnalysis={baselineTrendAnalysis}
          comparisonTrendAnalysis={comparisonTrendAnalysis}
          previousContainer={initialPreviousContainer as any}
          selectedBaseContainerId={selectedBaseContainer?.containerId}
          selectedPreviousContainerId={selectedPreviousContainer?.containerId}
          handleChange={handleChange}
        />
      )
    },
    {
      value: 'etr',
      render: () => (
        <Box className={classes.etrContainer}>
          {hasContainersLoaded ||
          hasJurisdictionsLoaded ||
          !currentContainer ||
          !jurisdictions?.length ||
          !worldwideReport ? (
            <CircularProgress />
          ) : (
            <EtrAnalyzer jurisdictions={jurisdictions} worldwideReport={worldwideReport} />
          )}
        </Box>
      )
    }
  ];

  return <TitleNavigation tabs={tabs} label="analytics" isLoading={false} defaultTab={tabs[0].value} />;
};

export default Analytics;
