import { ComponentType } from 'react';

import { useRouteMatch } from 'react-router-dom';

import { SummaryRouterProps } from './SummaryRouter';

import { useEntities, useMiscReport } from '../../../../hooks';
import { SummaryReportRow } from '../../../../models';
import LoadingWrapper from '../../../LoadingWrapper';

const SummaryRouterConnector = ({ component: SummaryRouter }: { component: ComponentType<SummaryRouterProps> }) => {
  const { path } = useRouteMatch();
  const { isLoading: isEntitiesLoading, entityById } = useEntities();
  const { isLoading: isReportLoading, report } = useMiscReport(
    'group',
    'worldwide-consolidated',
    'summary-dashboard',
    'USD'
  );
  const reportRows: SummaryReportRow[] =
    report?.values?.[0]?.values?.map(({ name, value }) => ({ name, value: value ?? 0 })) ?? [];

  const isLoading = isEntitiesLoading || isReportLoading;

  return (
    <LoadingWrapper isLoading={isLoading}>
      <SummaryRouter path={path} reportRows={reportRows} entityById={entityById} />
    </LoadingWrapper>
  );
};

export default SummaryRouterConnector;
