import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Box, makeStyles, TextField } from '@material-ui/core';

import { ContainerNameMaxLength } from '../../../../constants';
import { PanelProps } from '../../../../models';
import { SET_CONTAINER_NAME } from '../../localReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '18px',
    margin: 'auto',
    width: '80%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(14),
    height: theme.spacing(8)
  },
  fullRowContent: {
    color: theme.palette.text.primary,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%'
  },
  input: {
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.common.white
    }
  }
}));

const NameContainer = ({
  localDispatch,
  localState: { newContainerName, defineContainerPeriods, containers },
  setIsRightActionEnabled
}: PanelProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { taxYear } = defineContainerPeriods;
  const newTaxYear = Number(taxYear);
  const [error, setError] = useState('');

  const setNewContainerName = (newName: string) => {
    localDispatch({
      type: SET_CONTAINER_NAME,
      payload: newName
    });
  };

  useEffect(() => {
    const isNameTooLong = newContainerName.length > ContainerNameMaxLength;
    const LENGTH_ERROR = `Short name is longer than ${String(
      ContainerNameMaxLength
    )} characters, please shorten the name.`;
    const doesNameExist = containers.some(
      (container) => container.containerName === newContainerName && container.taxYear === newTaxYear
    );
    const error = isNameTooLong
      ? LENGTH_ERROR
      : doesNameExist
      ? `Container with that name already exists on ${newTaxYear}`
      : '';
    setError(error);
    setIsRightActionEnabled(newContainerName.length > 0 && !isNameTooLong && !doesNameExist);
  }, [setError, setIsRightActionEnabled, newContainerName, containers, newTaxYear]);

  return (
    <Box className={classes.root}>
      <Box>
        <Box className={classes.fullRowContent}>{t('Choose short name for the new period')}</Box>
        <TextField
          fullWidth
          className={classes.input}
          value={newContainerName}
          error={error !== ''}
          helperText={t(error)}
          variant="outlined"
          size="small"
          InputProps={{
            placeholder: t('Add short name')
          }}
          onChange={(event) => {
            setNewContainerName(event.target.value);
          }}
        />
      </Box>
    </Box>
  );
};

export default NameContainer;
