import { SvgIcon } from '@material-ui/core';

const EffectiveTaxRateIcon = (props: any) => (
  <SvgIcon viewBox="0 0 256 256" {...props}>
    <path d="M215.9,232.3H40.1V17.7h113l62.7,58.9V232.3z M43.1,229.3h169.8V77.9L152,20.7H43.1V229.3z" />

    <g>
      <path
        d="M159.9,93.1c-0.3-0.3-0.8-0.3-1.1,0l-62.6,62.6c-0.3,0.3-0.3,0.8,0,1.1c0.2,0.2,0.4,0.2,0.6,0.2
      
      c0.2,0,0.4-0.1,0.6-0.2l62.6-62.6C160.2,93.9,160.2,93.4,159.9,93.1z"
      />

      <path
        d="M110,121.1c3.8,0,7.3-1.5,10-4.1c2.7-2.7,4.1-6.2,4.1-10c0-7.8-6.3-14.1-14.1-14.1c-7.8,0-14.1,6.3-14.1,14.1
      
      C95.9,114.8,102.2,121.1,110,121.1z M110,94.5c6.9,0,12.5,5.6,12.5,12.5c0,3.3-1.3,6.5-3.7,8.9c-2.4,2.4-5.5,3.7-8.9,3.7
      
      c-6.9,0-12.5-5.6-12.5-12.5C97.5,100.1,103.1,94.5,110,94.5z"
      />

      <path
        d="M146,128.9c-7.8,0-14.1,6.3-14.1,14.1c0,7.8,6.3,14.1,14.1,14.1c7.8,0,14.1-6.3,14.1-14.1
      
      C160.1,135.2,153.8,128.9,146,128.9z M146,155.5c-6.9,0-12.5-5.6-12.5-12.5c0-6.9,5.6-12.5,12.5-12.5c6.9,0,12.5,5.6,12.5,12.5
      
      C158.5,149.9,152.9,155.5,146,155.5z"
      />
    </g>
  </SvgIcon>
);

export default EffectiveTaxRateIcon;
