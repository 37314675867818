import { useEffect, useState } from 'react';

import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';

import * as XLSX from 'xlsx';

import { setFileName, setIsFileUploaded, setIsFileDownloaded } from '../../redux/uploadManager';
import { selectFile, selectIsFileDownloaded } from '../../selectors';
import { useDataImportUpload } from '../useDataImportUpload';

const removeForbiddenCharsFromFileName = (name: string) => {
  return name.replace(/[^\d !'()*.A-Za-z-]/g, '');
};

export const useDropzoneForSpreadsheet = () => {
  const [workbook, setWorkbook] = useState<XLSX.WorkBook>({} as XLSX.WorkBook);
  const isFileDownloaded = useSelector(selectIsFileDownloaded);
  const downloadedFile = useSelector(selectFile);

  useEffect(() => {
    if (isFileDownloaded) {
      const updatedFile = XLSX.read(downloadedFile);
      setWorkbook(updatedFile as any);
    }
  }, [downloadedFile, isFileDownloaded]);

  const dispatch = useDispatch();
  const { uploadFileToS3 } = useDataImportUpload();

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    accept: {
      'text/csv': [],
      'application/vnd.ms-excel': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
      'application/vnd.ms-excel.sheet.binary.macroenabled.12': ['.xlsb']
    },
    maxFiles: 1,
    noClick: true
  });

  useEffect(() => {
    const blankWorkbook = {
      SheetNames: ['Sheet1'],
      Sheets: {
        Sheet1: {
          '!ref': 'A1'
        }
      }
    };

    const reader = new FileReader();

    const onLoadHandler = function (e: ProgressEvent<FileReader>) {
      const data = e.target?.result;

      if (data) {
        const tempWorkbook = XLSX.read(data);
        setWorkbook(tempWorkbook);
      } else {
        setWorkbook(blankWorkbook);
      }
    };

    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];

      dispatch(setFileName(removeForbiddenCharsFromFileName(file.name)));

      reader.addEventListener('load', onLoadHandler);
      reader.readAsArrayBuffer(file);

      void uploadFileToS3(file);

      dispatch(setIsFileUploaded(true));
    } else {
      dispatch(setIsFileUploaded(false));
      dispatch(setIsFileDownloaded(false));
    }

    return () => {
      reader.removeEventListener('load', onLoadHandler);
    };
  }, [acceptedFiles, dispatch, uploadFileToS3]);

  return { acceptedFiles, getRootProps, getInputProps, open, workbook };
};
