import { useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import withStyles from '@material-ui/core/styles/withStyles';
import MoreHoriz from '@material-ui/icons/MoreHoriz';

const StyledMenu = withStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1)
  }
}))((props) => (
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children?: ReactNode; getContentAnchorEl: ... Remove this comment to see the full error message
  <Menu
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

type Props = {
  actions: Array<{
    label: string;
    disabled?: boolean;
    onClick?: (...args: any[]) => any;
  }>;
};

const MoreActionsButton = ({ actions }: Props) => {
  const { t } = useTranslation();
  const moreActions = useRef();
  const [menuOpen, setMenuOpen] = useState(false);

  function handleCloseMenu() {
    setMenuOpen(false);
  }

  return (
    <div data-testid="more-actions-button">
      {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
      <IconButton
        ref={moreActions}
        aria-haspopup
        aria-label={t('More actions')}
        onClick={() => {
          setMenuOpen(true);
        }}
      >
        <MoreHoriz />
      </IconButton>
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; keepMounted: true; an... Remove this comment to see the full error message */}
      <StyledMenu keepMounted anchorEl={moreActions.current} open={menuOpen} onClose={handleCloseMenu}>
        {actions.map(({ label, onClick, ...otherProps }) => {
          return (
            <MenuItem
              key={label}
              onClick={() => {
                handleCloseMenu();
                if (onClick) {
                  onClick();
                }
              }}
              {...otherProps}
            >
              {label}
            </MenuItem>
          );
        })}
      </StyledMenu>
    </div>
  );
};

export default MoreActionsButton;
