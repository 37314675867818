import { useState, useRef, useEffect, useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { Box, CircularProgress, IconButton, Menu, MenuItem, Popover, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MoreHoriz } from '@material-ui/icons';
import tinycolor from 'tinycolor2';

import { CategoryFromApi, Container } from '../../models';
import InternalCategoriesPopover from '../EntityDetails/components/CategoriesPopover/internalCategoriesPopover';

const useStyles = makeStyles((theme) => ({
  circle: {
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    cursor: 'pointer'
  },
  paper: {
    borderRadius: '15px',
    marginLeft: '8px',
    marginTop: '-4px'
  },
  text: {
    fontSize: theme.typography.caption.fontSize,
    borderRadius: '15px',
    border: 'solid 1px black',
    minWidth: '90px',
    height: '24px',
    paddingLeft: '10px',
    overflow: 'auto',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '10px',
    flex: 1,
    '& span': {
      fontWeight: 'bold'
    }
  },
  moreActionButtons: {
    '& > .MuiButtonBase-root': {
      padding: '4px',
      backgroundColor: 'red'
    },
    '.MuiMenu-paper': {
      backgroundColor: 'blue',
      marginTop: '0px !important'
    }
  },
  menu: {
    '& .MuiMenu-paper': {
      marginTop: '5px',
      width: '87px'
    }
  }
}));

interface Props {
  [key: string]: any;
  currentContainer?: Container;
  category: CategoryFromApi;
  originalStepName: string;
  accountName: string;
  actions?: Array<{
    label: string;
    disabled?: boolean;
    onClick?: (...args: any[]) => any;
  }>;
}

const ColorTooltip = ({
  currentContainer,
  category: receivedCategory,
  accountName: receivedAccountName,
  originalStepName,
  ...props
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [category, setCategory] = useState<CategoryFromApi>(receivedCategory);
  const [lightColor, setLightColor] = useState<string>(tinycolor(receivedCategory.color).lighten(40).toString());
  const [darkColor, setDarkColor] = useState<string>(tinycolor(receivedCategory.color).darken(20).toString());
  const [accountName, setAccountName] = useState<string>(receivedAccountName);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const moreActions = useRef();
  const tooltipRef = useRef();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [areActionsOpen, setAreActionsOpen] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
    setAreActionsOpen(false);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  const handleOpenMenu = () => {
    setMenuOpen(true);
  };

  const updateHandler = (loading: boolean) => {
    setIsLoading(loading);
  };

  useEffect(() => {
    setCategory(receivedCategory);
    setLightColor(tinycolor(receivedCategory.color).lighten(40).toString());
    setDarkColor(tinycolor(receivedCategory.color).darken(20).toString());
  }, [receivedCategory]);

  useEffect(() => {
    setAccountName(receivedAccountName);
  }, [receivedAccountName]);

  const actions = [
    {
      label: 'edit',
      onClick: () => {
        setAnchorEl(null);
        setMenuOpen(false);
        setAreActionsOpen(true);
      }
    }
  ];

  const categoriesPopoverCloseHandler = useCallback(() => {
    setAreActionsOpen(false);
  }, []);
  const open = Boolean(anchorEl);

  return (
    <Box {...props}>
      <Box ref={tooltipRef}>
        {isLoading ? (
          <CircularProgress size={15} />
        ) : (
          <Box className={classes.circle} style={{ backgroundColor: `${category.color}` }} onClick={handleClick} />
        )}
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        classes={{
          paper: classes.paper
        }}
        onClose={handleClose}
      >
        <div
          className={classes.text}
          style={{
            backgroundColor: lightColor,
            borderColor: category.color,
            color: darkColor,
            overflow: 'hidden'
          }}
        >
          <Typography variant="caption">{category.name}</Typography>
          {actions && !currentContainer?.isFinalized && (
            <>
              {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
              <IconButton
                ref={moreActions}
                aria-haspopup
                aria-label={t('More actions')}
                onClick={handleOpenMenu}
                onClose={handleCloseMenu}
              >
                <MoreHoriz />
              </IconButton>
              <Menu
                keepMounted
                anchorEl={moreActions.current}
                open={menuOpen}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                className={classes.menu}
                onClose={handleClose}
              >
                {actions.map(({ label, onClick }) => {
                  return (
                    <MenuItem key={label} onClick={onClick}>
                      {label}
                    </MenuItem>
                  );
                })}
              </Menu>
            </>
          )}
        </div>
      </Popover>
      <InternalCategoriesPopover
        singleSelected
        isOpen={areActionsOpen}
        anchorEl={tooltipRef.current}
        originalStepName={originalStepName}
        accountName={accountName}
        category={category}
        updateHandler={updateHandler}
        closeHandler={categoriesPopoverCloseHandler}
        type="edit"
      />
    </Box>
  );
};

export default ColorTooltip;
