import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import {
  entitiesOnReceived,
  entitiesOnSendingRequest,
  entitiesOnFailed,
  addSubJurisdictionsToEntity,
  deleteSubJurisdictionsFromEntity,
  setParentEntity,
  updateEntityName,
  updateEntityCurrency,
  updateEntityJurisdiction,
  updateEntityNumber,
  deleteEntity
} from './entities.actions';
import { entitiesInitialState } from './entities.initialState';

import { containersOnCurrentSwitched } from '../containers';
import { uploadReviewFinishOnSuccess, uploadReviewOnFailed } from '../uploadReview';

export const entitiesReducer = createReducer(entitiesInitialState, (builder) => {
  builder
    .addCase(entitiesOnSendingRequest, (state) => {
      return { ...state, isLoading: true, error: null };
    })

    .addCase(entitiesOnReceived, (state, action) => {
      const localJurisdictionId = action.payload.find((entity) => entity.isParent)?.jurisdictionId;

      return {
        ...state,
        entities: action.payload.map((entity) => ({
          ...entity,
          id: entity.entityNumber,
          isLocal: entity.jurisdictionId === localJurisdictionId,
          hasStates: entity.subJurisdictionIds.length > 0
        })),
        isLoading: false,
        isLoaded: true,
        error: null
      };
    })

    .addCase(entitiesOnFailed, (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    })

    .addCase(addSubJurisdictionsToEntity.fulfilled, (state, action) => {
      const selectedEntity = state.entities.find((entity) => entity.entityId === action.payload.entityId)!;
      selectedEntity.subJurisdictionIds.push(...action.payload.subjurisdictionIds);
      state.alterSubJurisdictions.isLoading = false;
      state.alterSubJurisdictions.error = null;
      return state;
    })

    .addCase(deleteSubJurisdictionsFromEntity.fulfilled, (state, action) => {
      const selectedEntity = state.entities.find((entity) => entity.entityId === action.payload.entityId)!;
      selectedEntity.subJurisdictionIds = selectedEntity.subJurisdictionIds.filter(
        (id) => !action.payload.subjurisdictionIds.includes(id)
      );
      state.alterSubJurisdictions.isLoading = false;
      state.alterSubJurisdictions.error = null;
      return state;
    })

    .addCase(setParentEntity.fulfilled, (state, action) => {
      const entities = state.entities.map((entity) => {
        return {
          ...entity,
          isParent: entity.entityId === action.payload
        };
      });
      return { ...state, entities, alterParent: { isLoading: false, error: null } };
    })
    .addCase(setParentEntity.pending, (state) => ({
      ...state,
      alterParent: {
        isLoading: true,
        error: null
      }
    }))
    .addCase(setParentEntity.rejected, (state, action) => ({
      ...state,
      alterParent: {
        isLoading: false,
        error: action.error.message
      }
    }))

    .addCase(updateEntityCurrency.pending, (state) => {
      state.updateEntityCurrency.isLoading = true;
      state.updateEntityCurrency.error = null;
    })
    .addCase(updateEntityCurrency.fulfilled, (state, action) => {
      const selectedEntity = state.entities.find((entity) => entity.entityId === action.payload);
      if (selectedEntity) {
        selectedEntity.currencyId = action.meta.arg.currencyId;
      }

      state.updateEntityCurrency.isLoading = false;
      state.updateEntityCurrency.error = null;
    })
    .addCase(updateEntityCurrency.rejected, (state, action) => {
      state.updateEntityCurrency.isLoading = false;
      state.updateEntityCurrency.error = action.error.message;
    })

    .addCase(updateEntityJurisdiction.pending, (state) => {
      state.updateEntityJurisdiction.isLoading = true;
      state.updateEntityJurisdiction.error = null;
    })
    .addCase(updateEntityJurisdiction.fulfilled, (state, action) => {
      const selectedEntity = state.entities.find((entity) => entity.entityId === action.payload);
      if (selectedEntity) {
        selectedEntity.jurisdictionId = action.meta.arg.jurisdictionId;
      }

      state.updateEntityJurisdiction.isLoading = false;
      state.updateEntityJurisdiction.error = null;
    })
    .addCase(updateEntityJurisdiction.rejected, (state, action) => {
      state.updateEntityJurisdiction.isLoading = false;
      state.updateEntityJurisdiction.error = action.error.message;
    })

    .addCase(updateEntityName.pending, (state) => {
      state.updateEntityName.isLoading = true;
      state.updateEntityName.error = null;
    })
    .addCase(updateEntityName.fulfilled, (state, action) => {
      const selectedEntity = state.entities.find((entity) => entity.entityId === action.payload);
      if (selectedEntity) {
        selectedEntity.name = action.meta.arg.entityName;
      }

      state.updateEntityName.isLoading = false;
      state.updateEntityName.error = null;
    })
    .addCase(updateEntityName.rejected, (state, action) => {
      state.updateEntityName.isLoading = false;
      state.updateEntityName.error = action.error.message;
    })

    .addCase(updateEntityNumber.pending, (state) => {
      state.updateEntityNumber.isLoading = true;
      state.updateEntityNumber.error = null;
    })
    .addCase(updateEntityNumber.fulfilled, (state, action) => {
      const selectedEntity = state.entities.find((entity) => entity.entityId === action.payload);
      if (selectedEntity) {
        selectedEntity.entityNumber = action.meta.arg.entityNumber;
        selectedEntity.id = action.meta.arg.entityNumber;
      }

      state.updateEntityNumber.isLoading = false;
      state.updateEntityNumber.error = null;
    })
    .addCase(updateEntityNumber.rejected, (state, action) => {
      state.updateEntityNumber.isLoading = false;
      state.updateEntityNumber.error = action.error.message;
    })

    .addCase(deleteEntity.pending, (state) => {
      state.deleteEntity.isLoading = true;
      state.deleteEntity.error = null;
    })
    .addCase(deleteEntity.fulfilled, (state, action) => {
      state.deleteEntity.isLoading = false;
      state.entities = state.entities.filter((entity) => entity.entityId !== action.payload);
    })
    .addCase(deleteEntity.rejected, (state, action) => {
      state.deleteEntity.isLoading = false;
      state.deleteEntity.error = action.payload;
    })

    .addMatcher(isAnyOf(addSubJurisdictionsToEntity.pending, deleteSubJurisdictionsFromEntity.pending), (state) => ({
      ...state,
      alterSubJurisdictions: {
        isLoading: true,
        error: null
      }
    }))
    .addMatcher(
      isAnyOf(addSubJurisdictionsToEntity.rejected, deleteSubJurisdictionsFromEntity.rejected),
      (state, action) => ({
        ...state,
        alterSubJurisdictions: {
          isLoading: false,
          error: action.error.message
        }
      })
    )
    .addMatcher(isAnyOf(uploadReviewFinishOnSuccess, uploadReviewOnFailed, containersOnCurrentSwitched), () => {
      return entitiesInitialState;
    });
});
