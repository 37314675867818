import { SvgIcon } from '@material-ui/core';

const CurrentIcon = (props: any) => (
  <SvgIcon viewBox="-3 -5 43 55" {...props}>
    <g>
      <path d="M24.4254835,0 L37.9783845,12.6253495 L37.9783845,46 L0,46 L0,0 L24.4254835,0 Z M24.1877133,0.64305685 L0.648464164,0.64305685 L0.648464164,45.3569432 L37.3515358,45.3569432 L37.3515358,12.9040075 L24.1877133,0.64305685 Z M14.4490135,14.998954 C14.582725,14.7634516 14.9217625,14.7625116 15.0567777,14.9972691 L19.8546176,23.3395069 C19.9164065,23.4469424 19.9167708,23.5790439 19.8555752,23.6868185 L15.147954,31.977645 C15.0142354,32.2131434 14.6751979,32.2140731 14.5401898,31.9793117 L9.74268163,23.6370739 C9.68089962,23.529643 9.68053538,23.3975504 9.74172399,23.2897805 Z M18.8072903,15.0823958 L23.4731191,23.2932859 C23.5343433,23.4010278 23.5340304,23.5331126 23.4722964,23.6405632 L18.7295164,31.8955676 C18.6332208,32.0631741 18.4192859,32.1209829 18.2516795,32.0246873 C18.084073,31.9283918 18.0262642,31.7144569 18.1225598,31.5468505 L22.7650875,23.4647627 L18.1986882,15.4282332 C18.1151254,15.28118 18.1497155,15.099216 18.2722564,14.9920449 L18.3300705,14.9510134 C18.4981314,14.8555131 18.7117899,14.9143349 18.8072903,15.0823958 Z M22.2225907,15.0823865 L26.8887512,23.2932766 C26.9499825,23.4010233 26.9496696,23.533117 26.8879285,23.6405724 L22.1448169,31.8955768 C22.0485162,32.0631803 21.8345796,32.1209827 21.6669761,32.0246821 C21.4993725,31.9283814 21.4415701,31.7144448 21.5378708,31.5468413 L26.1810875,23.4647627 L21.6139992,15.4282425 C21.5304319,15.2811918 21.5650165,15.0992268 21.6875541,14.992052 L21.745367,14.9510187 C21.9134249,14.8555132 22.1270853,14.9143286 22.2225907,15.0823865 Z M14.7540875,15.8767627 L10.4490875,23.4607627 L14.8410875,31.0997627 L19.1470875,23.5147627 L14.7540875,15.8767627 Z" />
    </g>
  </SvgIcon>
);

export default CurrentIcon;
