import { useCallback, useState } from 'react';

import { useDispatch } from 'react-redux';

import { AccountsEntityCountInterface } from '../../models';
import {
  accountsEntityCountOnSendingRequest,
  accountsEntityCountOnReceived,
  accountsEntityCountOnFailed
} from '../../redux/accountsEntityCount';
import HTTPService, { LambdaResponse } from '../../services/http';

export function useAccountsCount() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const getUseAccountsCount = useCallback(
    async (stepName: string) => {
      async function fetchData() {
        setIsLoading(true);
        dispatch(accountsEntityCountOnSendingRequest());
        try {
          const { data: accounts } = await HTTPService.request<LambdaResponse<AccountsEntityCountInterface[]>>({
            method: 'get',
            relativePath: `/v1/accounts/${stepName}/entity-count`
          });

          dispatch(accountsEntityCountOnReceived(accounts));
        } catch (error: unknown) {
          dispatch(accountsEntityCountOnFailed(error as any));
        } finally {
          setIsLoading(false);
        }
      }

      if (!isLoading) {
        void fetchData();
      }
    },
    [dispatch, isLoading]
  );

  return {
    getUseAccountsCount
  };
}
