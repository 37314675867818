import { useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useEntity } from '..';
import { Entity, FinancialInfoResponseForGet, Level, Step, SubJurisdiction } from '../../models';
import {
  financialDataOnFailed,
  financialDataOnInitialSendingRequest,
  financialDataOnReceived,
  financialDataOnSwitchingEntity,
  financialDataInitFailedCells
} from '../../redux/financialData';
import { selectFinancialData } from '../../selectors';
import HTTPService, { LambdaResponse } from '../../services/http';

export function useFinancialData(
  entityNumber: string,
  level: Level,
  steps: Step[],
  jurisdictionId?: SubJurisdiction['id']
) {
  const dispatch = useDispatch();
  const financialDataState = useSelector(selectFinancialData);
  const { entity } = useEntity(entityNumber);

  // TODO this is a hack as the steps list's reference currently change every render
  const currentStep = steps[0];

  useEffect(() => {
    dispatch(financialDataInitFailedCells());
  }, [dispatch, level, currentStep, jurisdictionId]);

  useEffect(() => {
    if (
      financialDataState.entityNumber &&
      !financialDataState.isFetchLoading &&
      entityNumber !== financialDataState.entityNumber
    ) {
      dispatch(financialDataOnSwitchingEntity());
    }
  }, [dispatch, financialDataState, entityNumber]);

  // TODO - this is a bad dependency as it changes every render (and even with useMemo steps reference changes every render)
  const stepsWithoutData = steps.filter(
    (step) => !Object.keys(financialDataState.tabsData).some((key) => key.includes(`${level}.${step}`))
  );

  const fetchDataForSpecificStep = async (level: Level, step: Step) => {
    dispatch(financialDataOnInitialSendingRequest());

    try {
      const { data } = await HTTPService.request<LambdaResponse<FinancialInfoResponseForGet>>({
        method: 'get',
        relativePath: `/v1/entities/${entity.entityId}/financial-value/${level}/${step}`
      });

      const financialInfo =
        Object.keys(data.financialInfo).length > 0 ? data.financialInfo : { [`${level}.${step}`]: [] };

      dispatch(
        financialDataOnReceived({
          tabsData: { ...financialInfo },
          entityNumber
        })
      );
    } catch (error: unknown) {
      dispatch(financialDataOnFailed({ error }));
    }
  };

  useEffect(() => {
    async function fetchData(entity: Entity) {
      dispatch(financialDataOnInitialSendingRequest());

      try {
        const promises = stepsWithoutData.map(async (step) => {
          const { data } = await HTTPService.request<LambdaResponse<FinancialInfoResponseForGet>>({
            method: 'get',
            relativePath: `/v1/entities/${entity.entityId}/financial-value/${level}/${step}`
          });

          const financialInfo =
            Object.keys(data.financialInfo).length > 0 ? data.financialInfo : { [`${level}.${step}`]: [] };

          return { ...financialInfo };
        });

        const financialInfoPromises = await Promise.all(promises);

        dispatch(
          financialDataOnReceived({
            tabsData: Object.assign({}, ...financialInfoPromises),
            entityNumber
          })
        );
      } catch (error: unknown) {
        dispatch(financialDataOnFailed({ error }));
      }
    }

    if (
      !financialDataState.isFetchLoading &&
      entity &&
      level &&
      stepsWithoutData.length > 0 &&
      !financialDataState.error
    ) {
      void fetchData(entity);
    }
  }, [dispatch, financialDataState, entity, level, steps, entityNumber, stepsWithoutData]);

  const tabsData = useMemo(() => {
    if (!jurisdictionId) {
      return financialDataState?.tabsData;
    }

    const filteredTabsData: { [key: string]: any[] } = {};

    for (const step of steps) {
      const LevelAndStep = `${level}.${step}`;
      const stepData = financialDataState?.tabsData[LevelAndStep] ?? [];

      filteredTabsData[LevelAndStep] = stepData.filter((step) => step?.jurisdictionId === jurisdictionId);
    }

    return filteredTabsData;
  }, [financialDataState?.tabsData, jurisdictionId, level, steps]);

  return { ...financialDataState, tabsData, fetchDataForSpecificStep };
}
