import { SvgIcon } from '@material-ui/core';

const SumIcon = (props: any) => (
  <SvgIcon viewBox="0 0 256 256" {...props}>
    <path d="M215.9,232.3H40.1V17.7h113l62.7,58.9V232.3z M43.1,229.3h169.8V77.9L152,20.7H43.1V229.3z" />

    <g>
      <g>
        <path
          d="M153.5,161h-51c-0.5,0-1-0.3-1.3-0.8c-0.3-0.5-0.3-1.1,0-1.5l20.5-33.7l-20.5-33.7c-0.3-0.5-0.3-1,0-1.5
          
          c0.3-0.5,0.8-0.8,1.3-0.8h51c0.8,0,1.5,0.7,1.5,1.5c0,0.8-0.7,1.5-1.5,1.5h-48.3l19.6,32.2c0.3,0.5,0.3,1.1,0,1.6L105.2,158h48.3
          
          c0.8,0,1.5,0.7,1.5,1.5S154.3,161,153.5,161z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default SumIcon;
