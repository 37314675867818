import { useState } from 'react';

import { TFunction } from 'react-i18next';
import { Link } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ArrowBack from '@material-ui/icons/ArrowBack';
import GetApp from '@material-ui/icons/GetApp';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ReactComponent as SettingsIcon } from '../../../../assets/img/Settings.svg';
import DefaultSwitch from '../../../DefaultSwitch/DefaultSwitch';
import UpdatingBadge from '../../../UpdatingBadge';

export interface ReportListHeaderProps {
  backTo: string;
  classes: Record<string, string>;
  areReportsBulkDownloading: boolean;
  onBulkReportsDownload: () => void;
  options: Record<string, string[]>;
  reportType: string;
  setReportType: (val: string) => void;
  t: TFunction;
  title: string;
  shouldGroupCategories: boolean;
  onShouldGroupCategoriesUpdate: () => void;
  type: 'entity' | 'group';
  isSomeReportUpdating: boolean;
}

const ReportListHeader = ({
  classes,
  backTo,
  areReportsBulkDownloading,
  onBulkReportsDownload,
  options,
  reportType,
  setReportType,
  t,
  title,
  shouldGroupCategories,
  onShouldGroupCategoriesUpdate,
  type,
  isSomeReportUpdating
}: ReportListHeaderProps) => {
  const [settingsPopoverAnchor, setSettingsPopoverAnchor] = useState<null | HTMLElement>(null);
  const { prov3137ReportsStatusRework } = useFlags();

  const downloadButton = (
    <IconButton
      disabled={areReportsBulkDownloading}
      color="primary"
      aria-label="Download report"
      component="span"
      style={{ cursor: areReportsBulkDownloading ? 'default' : 'pointer' }}
      onClick={areReportsBulkDownloading ? undefined : onBulkReportsDownload}
    >
      <GetApp />
    </IconButton>
  );

  const showSettingsPopover = Boolean(settingsPopoverAnchor);

  const handleSettingsOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setSettingsPopoverAnchor(event.currentTarget);
  };

  const handleSettingsClosed = () => {
    setSettingsPopoverAnchor(null);
  };

  return (
    <>
      <Box display="flex" alignItems="center">
        <IconButton component={Link} to={backTo}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h2" component="h1" className={classes.title}>
          {title}
        </Typography>
      </Box>
      <Box className={classes.headerActionsContainer}>
        {prov3137ReportsStatusRework && isSomeReportUpdating && <UpdatingBadge variant="warning" />}
        {areReportsBulkDownloading ? (
          <Tooltip disableFocusListener enterDelay={10} leaveTouchDelay={50} title="Report package loading">
            {downloadButton}
          </Tooltip>
        ) : (
          downloadButton
        )}
        <Select
          variant="outlined"
          IconComponent={KeyboardArrowDown}
          className={classes.select}
          defaultValue={options[type][0]}
          value={reportType}
          onChange={({ target: { value } }) => {
            setReportType(value as string);
          }}
        >
          {options[type].map((value) => (
            <MenuItem key={value} value={value}>
              {t(value)}
            </MenuItem>
          ))}
        </Select>
        <Button
          className={classes.settingsButton}
          style={{ backgroundColor: showSettingsPopover ? '#F5F5F7' : undefined }}
          onClick={handleSettingsOpen}
        >
          <SettingsIcon />
          {t('settings')}
        </Button>
        <Popover
          className={classes.settingsContainer}
          open={showSettingsPopover}
          anchorEl={settingsPopoverAnchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          onClose={handleSettingsClosed}
        >
          <Box className={classes.settingsContainer}>
            <Box className="setting">
              {t('group-categories')}
              <DefaultSwitch
                data-testid="group-categories-switch"
                checked={shouldGroupCategories}
                onClick={onShouldGroupCategoriesUpdate}
              />
            </Box>
          </Box>
        </Popover>
      </Box>
    </>
  );
};

export default ReportListHeader;
