import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import {
  ratesOnReceived,
  ratesOnSendingRequest,
  ratesOnJurisdictionTaxUpdateRequest,
  ratesOnFailed,
  ratesOnRequestSuccess,
  ratesOnJurisdictionTaxUpdateFailed,
  ratesOnEntityRateUpdateRequest,
  ratesOnEntityRateUpdateFailed,
  ratesOnInvalidCellUpdate,
  fxRatesOnCurrencyUpdateRequest,
  fxRatesOnEntityRateUpdateRequest,
  fxRatesOnInvalidCellUpdate,
  fxRatesOnEntityRateUpdateFailed,
  fxRatesOnJurisdictionFxUpdateFailed
} from './rates.actions';
import { ratesInitialState, RatesStateInterface } from './rates.initialState';

import { RateUpdate, TaxRateUpdate } from '../../models';
import { containersOnCurrentSwitched } from '../containers';
import { uploadReviewFinishOnSuccess, uploadReviewOnFailed } from '../uploadReview';

interface HandleFailedCellsState {
  state: RatesStateInterface;
  rowName: string;
  columnName: string;
  didFail?: boolean;
  failureReason?: string;
}

const handleFailedCellsState = ({
  state,
  rowName,
  columnName,
  didFail = false,
  failureReason
}: HandleFailedCellsState) => {
  if (rowName && columnName) {
    const result = {
      ...state.failedCells,
      [rowName]: { ...state.failedCells.rowName, [columnName]: failureReason ?? didFail }
    };
    return result;
  }

  return state.failedCells;
};

const handleJurisdictionRateChange = (state: RatesStateInterface, rateUpdate: RateUpdate) => {
  const { jurisdictionId, currencyId, rateType, rateDomain, rate } = rateUpdate as TaxRateUpdate;
  const key = rateDomain === 'tax' ? jurisdictionId : currencyId;
  return {
    ...state[rateDomain],
    customRates: {
      ...state[rateDomain].customRates,
      [key]: {
        ...state[rateDomain].customRates[key],
        [rateType]: rate
      }
    }
  };
};

const handleEntityRateChange = (state: RatesStateInterface, rateUpdate: RateUpdate) => {
  const { entityId, currencyId, jurisdictionId, rateType, rateDomain, rate } = rateUpdate as Required<TaxRateUpdate>;
  const key = rateDomain === 'tax' ? jurisdictionId : currencyId;
  return {
    ...state[rateDomain],
    customEntityRates: {
      ...state[rateDomain].customEntityRates,
      [entityId]: {
        ...state[rateDomain].customEntityRates[entityId],
        [key]: {
          ...state[rateDomain].customEntityRates[entityId]?.[key],
          [rateType]: rate
        }
      }
    }
  };
};

export const ratesReducer = createReducer(ratesInitialState, (builder) => {
  builder
    .addCase(ratesOnSendingRequest, (state) => {
      return { ...state, isLoading: true, error: null };
    })
    .addCase(ratesOnRequestSuccess, (state) => {
      return { ...state, isLoading: false, error: null };
    })
    .addCase(ratesOnFailed, (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    })
    .addCase(ratesOnReceived, (state, action) => {
      const { taxRates, exchangeRates } = action.payload;
      const { defaultJurisdictionTaxRates, customJurisdictionTaxRates, customEntityTaxRates } = taxRates;
      const { defaultExchangeRates, customCurrencyExchangeRates, customEntityExchangeRates } = exchangeRates;

      return {
        ...state,
        tax: {
          defaultRates: defaultJurisdictionTaxRates,
          customRates: customJurisdictionTaxRates,
          customEntityRates: customEntityTaxRates
        },
        exchange: {
          defaultRates: defaultExchangeRates,
          customRates: customCurrencyExchangeRates,
          customEntityRates: customEntityExchangeRates
        },
        isLoading: false,
        isLoaded: true,
        error: null
      };
    })
    .addMatcher(isAnyOf(ratesOnJurisdictionTaxUpdateFailed, fxRatesOnJurisdictionFxUpdateFailed), (state, action) => {
      const { error, rateUpdate } = action.payload;
      const { rowName, columnName, rateDomain } = rateUpdate;
      const failedCell = { state, rowName, columnName, didFail: true };
      return {
        ...state,
        [rateDomain]: handleJurisdictionRateChange(state, rateUpdate),
        failedCells: handleFailedCellsState(failedCell),
        isLoading: false,
        error
      };
    })
    .addMatcher(isAnyOf(ratesOnEntityRateUpdateFailed, fxRatesOnEntityRateUpdateFailed), (state, action) => {
      const { error, rateUpdate } = action.payload;
      const { rowName, columnName, rateDomain } = rateUpdate;
      const failedCell = { state, rowName, columnName, didFail: true };
      return {
        ...state,
        [rateDomain]: handleEntityRateChange(state, rateUpdate as Required<RateUpdate>),
        failedCells: handleFailedCellsState(failedCell),
        isLoading: false,
        error
      };
    })
    .addMatcher(isAnyOf(ratesOnInvalidCellUpdate, fxRatesOnInvalidCellUpdate), (state, action) => {
      const { rateUpdate, failureReason } = action.payload;
      const { rowName, columnName } = rateUpdate;
      const failedCell = { state, rowName, columnName, didFail: true, failureReason };
      return {
        ...state,
        isLoading: false,
        error: null,
        failedCells: handleFailedCellsState(failedCell)
      };
    })
    .addMatcher(isAnyOf(ratesOnJurisdictionTaxUpdateRequest, fxRatesOnCurrencyUpdateRequest), (state, action) => {
      const { rowName, columnName, rateDomain } = action.payload;
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        [rateDomain]: handleJurisdictionRateChange(state, action.payload!),
        failedCells: handleFailedCellsState({ state, rowName, columnName }),
        isLoading: true,
        error: null
      };
    })
    .addMatcher(isAnyOf(ratesOnEntityRateUpdateRequest, fxRatesOnEntityRateUpdateRequest), (state, action) => {
      const { rowName, columnName, rateDomain } = action.payload;
      return {
        ...state,
        [rateDomain]: handleEntityRateChange(state, action.payload),
        failedCells: handleFailedCellsState({ state, rowName, columnName }),
        isLoading: true,
        error: null
      };
    })
    .addMatcher(isAnyOf(uploadReviewFinishOnSuccess, uploadReviewOnFailed, containersOnCurrentSwitched), () => {
      return ratesInitialState;
    });
});
