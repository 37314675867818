import { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { Checkbox } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { makeRows } from './utils';

import { ContentAndTitle, TabTitle } from '..';
import { TableWithComment } from '../../..';
import { FEDERAL_UUID, LEVELS, TABS_WITH_IMPORT_BUTTON_AT_TOP } from '../../../../constants';
import { useCompletionStatus, useCurrencies, useFinancialData, useValuationAllowance } from '../../../../hooks';
import { FederalTabProps, FinancialInfo, Step } from '../../../../models';
import { setEntityCompletionStatus } from '../../../../redux/entitiesCompletionStatus';
import { selectDoesUserHaveRole } from '../../../../selectors';
import LoadingWrapper from '../../../LoadingWrapper';
import { ProReadOnly, ProReviewer } from '../../../UserRoleStylesProvider/constants';
import VirtualTable from '../../../VirtualTable';
import { DeferredRow } from '../../models';
import {
  handleEditRowForEntityDetails,
  handleOnCellOrCommentBlurForEntityDetails,
  getDeferredColumns,
  getRowNamesFromLevelSteps,
  getTableDataFromFinancialData,
  EntityNumberRouteMatch,
  getDeferredColumnsFlagged,
  getDeferredColumnsVirtual,
  getDeferredColumnsVirtualFlagged
} from '../../utils';
import { getLinkButtonCssProps } from '../../utils/styles';

const useStyles = makeStyles((theme) => ({
  linkButton: ({ isUserReadOnly }: { isUserReadOnly: boolean }) => ({
    ...getLinkButtonCssProps(theme, isUserReadOnly),
    '&.Mui-disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'inherit'
    }
  })
}));

const LEVEL = LEVELS.FEDERAL;
const STEP = 'deferred';
const LevelAndStep = `${LEVEL as string}.${STEP}`;

const CREDITS_STEP: Step = 'credits';
const NOL_STEP: Step = 'nol';
const RTP_STEP: Step = 'rtp';
const BALANCE_SHEET_STEP: Step = 'temporary.balanceSheet';
const INCOME_STATEMENT_STEP: Step = 'temporary.incomeStatement';

const STEPS: Step[] = [STEP, NOL_STEP, CREDITS_STEP, RTP_STEP, BALANCE_SHEET_STEP, INCOME_STATEMENT_STEP];

const nolAndCreditsImportColumns = new Set([
  'balanceSheetOnlyAdjustment',
  'beginningBalance',
  'deferredOnlyAdjustment',
  'generatedAmount',
  'oci',
  'goodwill',
  'fin48',
  'rtp',
  'usedAmount'
]);

const returnToProvisionImportColumns = new Set(['taxProvision', 'taxReturn', 'm1Adjustment']);
const temporaryImportColumns = new Set([
  'beginningBalance',
  'beginningPayments',
  'bookBalance',
  'endingBalance',
  'endingPayments',
  'taxBalance'
]);

const FederalDeferred = ({ entityId }: FederalTabProps) => {
  const {
    prov2826TarfEnhancements,
    prov3341FilterEmptyRowsTable,
    prov3322EditPerformanceFix: isPerformanceFixEnabled,
    prov3349VirtualTable
  } = useFlags();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isUserReadOnly = useSelector(selectDoesUserHaveRole([ProReadOnly.Name, ProReviewer.Name]));
  const classes = useStyles({ isUserReadOnly });
  const { currencyByEntityIdMap } = useCurrencies();
  const currencyIsoCode = currencyByEntityIdMap[entityId]?.isoCode;
  const [rows, setRows] = useState<DeferredRow[]>([]);
  const [hideEmptyRows, setHideEmptyRows] = useState(prov3341FilterEmptyRowsTable);
  const {
    params: { entityNumber }
  } = useRouteMatch<EntityNumberRouteMatch>();
  const { stepCompletionStatus } = useCompletionStatus(entityNumber, LevelAndStep, FEDERAL_UUID);

  const { tabsData, failedCells, isFetchLoading } = useFinancialData(entityNumber, LEVEL, STEPS);

  const returnToProvisionFinancialInfo = useMemo(
    () =>
      (tabsData[`${LEVEL}.${RTP_STEP}`] ?? []).filter(({ columnName }: FinancialInfo) =>
        returnToProvisionImportColumns.has(columnName)
      ),
    [tabsData]
  );

  const balanceSheetFinancialInfo = useMemo(
    () =>
      (tabsData[`${LEVEL}.${BALANCE_SHEET_STEP}`] ?? []).filter(({ columnName }: FinancialInfo) =>
        temporaryImportColumns.has(columnName)
      ),
    [tabsData]
  );

  const incomeStatementFinancialInfo = useMemo(
    () =>
      (tabsData[`${LEVEL}.${INCOME_STATEMENT_STEP}`] ?? []).filter(({ columnName }: FinancialInfo) =>
        temporaryImportColumns.has(columnName)
      ),
    [tabsData]
  );

  const grossFederalNolFinancialInfo = useMemo(
    () =>
      (tabsData[`${LEVEL}.${NOL_STEP}`] ?? []).filter(({ columnName }: FinancialInfo) =>
        nolAndCreditsImportColumns.has(columnName)
      ),
    [tabsData]
  );

  const taxCreditsFinancialInfo = useMemo(
    () =>
      (tabsData[`${LEVEL}.${CREDITS_STEP}`] ?? []).filter(({ columnName }: FinancialInfo) =>
        nolAndCreditsImportColumns.has(columnName)
      ),
    [tabsData]
  );

  const deferredFinancialInfo = useMemo(() => tabsData[LevelAndStep] ?? [], [tabsData]);

  const rowNames = useMemo(() => getRowNamesFromLevelSteps(tabsData, LEVEL, STEPS), [tabsData]);

  const source = useMemo(() => {
    return {
      deferred: getTableDataFromFinancialData(rowNames.deferred, deferredFinancialInfo),
      grossFederalNol: getTableDataFromFinancialData(rowNames.nol, grossFederalNolFinancialInfo),
      returnToProvision: getTableDataFromFinancialData(rowNames.rtp, returnToProvisionFinancialInfo),
      taxCredits: getTableDataFromFinancialData(rowNames.credits, taxCreditsFinancialInfo),
      balanceSheet: getTableDataFromFinancialData(rowNames[BALANCE_SHEET_STEP], balanceSheetFinancialInfo),
      incomeStatement: getTableDataFromFinancialData(rowNames[INCOME_STATEMENT_STEP], incomeStatementFinancialInfo)
    };
  }, [
    balanceSheetFinancialInfo,
    deferredFinancialInfo,
    grossFederalNolFinancialInfo,
    incomeStatementFinancialInfo,
    returnToProvisionFinancialInfo,
    rowNames,
    taxCreditsFinancialInfo
  ]);

  const valuationAllowance = useValuationAllowance(source.deferred);
  const isTarfFlagActive = prov2826TarfEnhancements;

  const columns = useMemo(() => {
    if (prov3349VirtualTable) {
      return isTarfFlagActive
        ? getDeferredColumnsVirtualFlagged(t, stepCompletionStatus.status || isUserReadOnly)
        : getDeferredColumnsVirtual(t, stepCompletionStatus.status || isUserReadOnly);
    }

    return isTarfFlagActive
      ? getDeferredColumnsFlagged(t, stepCompletionStatus.status || isUserReadOnly)
      : getDeferredColumns(t, stepCompletionStatus.status || isUserReadOnly);
  }, [isTarfFlagActive, prov3349VirtualTable, isUserReadOnly, stepCompletionStatus.status, t]);

  useEffect(() => {
    const rows = makeRows(
      t,
      columns,
      source,
      valuationAllowance,
      stepCompletionStatus.status || isUserReadOnly,
      dispatch,
      classes.linkButton
    );

    if (!hideEmptyRows) {
      setRows(rows);
      return;
    }

    // Remove this section after virtualize tables is done (flag is prov3341FilterEmptyRowsTable)
    const filterEmptyRows = rows.filter((row: any) => {
      const emptyValues = new Set([0, undefined, null]);

      if (
        row.creditName === 'federal.temporary.balanceSheet' ||
        row.creditName === 'federal.temporary.incomeStatement'
      ) {
        const fieldsToCheck =
          row.creditName === 'federal.temporary.balanceSheet'
            ? [
                'beginningAdjusted',
                'beginningBalance',
                'difference',
                'endingAdjusted',
                'endingBalance',
                'm1Adjustment',
                'manualEndingBalance',
                'rtp'
              ]
            : ['beginningBalance', 'bookBalance', 'difference', 'endingBalance', 'm1Adjustment', 'rtp', 'taxBalance'];

        return !fieldsToCheck.every((field) => emptyValues.has(row[field]));
      }

      return row;
    });

    setRows(filterEmptyRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columns,
    isUserReadOnly,
    stepCompletionStatus.status,
    source,
    t,
    valuationAllowance,
    classes.linkButton,
    hideEmptyRows
  ]);

  const dataForMethods = {
    columns,
    dispatch,
    entityId,
    financialInfo: deferredFinancialInfo,
    level: LEVEL,
    rows,
    setRows,
    step: STEP as Step,
    t
  };

  const handleCheckBoxOnClick = () => {
    setHideEmptyRows(!hideEmptyRows);
  };

  return (
    <LoadingWrapper isLoading={isFetchLoading}>
      <ContentAndTitle
        title={
          <TabTitle
            currencyIsoCode={currencyIsoCode}
            title={t('Deferred Rollforward')}
            isCompleted={stepCompletionStatus.status}
            shouldDisplayDataImportButton={TABS_WITH_IMPORT_BUTTON_AT_TOP.federal.deferred}
            onCompletionChange={(checked) => {
              dispatch(
                setEntityCompletionStatus({
                  ...stepCompletionStatus,
                  newStatus: checked
                })
              );
            }}
          />
        }
      >
        <>
          {prov3341FilterEmptyRowsTable && (
            <div>
              Hide Empty Rows:
              <Checkbox checked={hideEmptyRows} onClick={handleCheckBoxOnClick} />
            </div>
          )}
          {prov3349VirtualTable ? (
            <VirtualTable
              includeNotesColumn
              level="federal"
              step="deferred"
              columns={columns}
              rows={rows}
              renderOpts={{}}
              failedCells={failedCells}
              hideActionsMenu={stepCompletionStatus.status}
              onCellOrCommentBlur={(params: any) => {
                handleOnCellOrCommentBlurForEntityDetails({
                  ...dataForMethods,
                  ...params
                });
              }}
            />
          ) : (
            <TableWithComment
              columns={columns}
              failedCells={failedCells}
              rows={rows}
              hideActionsMenu={stepCompletionStatus.status}
              onCellChange={(params) => {
                if (!isPerformanceFixEnabled) {
                  handleEditRowForEntityDetails({
                    ...dataForMethods,
                    ...params
                  });
                }
              }}
              onCellOrCommentBlur={(params) => {
                if (isPerformanceFixEnabled) {
                  handleEditRowForEntityDetails({
                    ...dataForMethods,
                    ...params
                  });
                }

                handleOnCellOrCommentBlurForEntityDetails({
                  ...dataForMethods,
                  ...params
                });
              }}
            />
          )}
        </>
      </ContentAndTitle>
    </LoadingWrapper>
  );
};

export default FederalDeferred;
