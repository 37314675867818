import { createSelector } from '@reduxjs/toolkit';

import { selectEntityRates } from './rates';

import { Entity, Jurisdiction, Rates } from '../models';
import { RootState } from '../redux';
import { turnToObjByKey } from '../utils';

export const selectEntities = (state: RootState) => state.entities;
export const selectEntitiesIsLoading = (state: RootState) => state.entities.isLoading;
const selectEntitiesList = (state: RootState): Entity[] => state.entities.entities;

export const selectEntitiesMapByNumber = createSelector(selectEntitiesList, (entities) =>
  turnToObjByKey(entities, 'entityNumber')
);

export const selectEntitiesMapByName = createSelector(selectEntitiesList, (entities) =>
  turnToObjByKey(entities, 'name')
);
export const selectEntitiesMapById = createSelector(selectEntitiesList, (entities) =>
  turnToObjByKey(entities, 'entityId')
);

export const selectAlterSubJurisdictionsState = (state: RootState) => state.entities.alterSubJurisdictions;

export const selectParentEntity = createSelector(selectEntitiesList, (entities) =>
  entities.find((entity) => entity.isParent)
);

interface EntityRates {
  customEntityTax: Rates['customEntityRates'];
  customEntityExchange: Rates['customEntityRates'];
}

const getRatesForEntity = (rates: EntityRates, entityId: Entity['entityId']) => {
  return { taxRates: rates.customEntityTax?.[entityId], foreignExchangeRates: rates.customEntityExchange?.[entityId] };
};

export const selectEntitiesWithRates = createSelector(selectEntitiesList, selectEntityRates, (entities, rates) => {
  if (!entities && Object.keys(rates.customEntityTax).length === 0) {
    return [];
  }

  return entities.map((entity) => {
    return { ...entity, ...getRatesForEntity(rates, entity.entityId) };
  });
});

export const selectEntitiesWithRatesByEntityNumber = createSelector(selectEntitiesWithRates, (entitiesWithRates) => {
  return turnToObjByKey(entitiesWithRates, 'entityNumber');
});

export const selectRatesForEntityJurisdiction = (
  entityId: Entity['entityId'],
  jurisdictionId: Jurisdiction['id'],
  currencyId: Jurisdiction['currencyId']
) => (state: RootState) => {
  const entityRates = selectEntityRates(state);
  const ratesForEntity = getRatesForEntity(entityRates, entityId);
  return {
    taxRates: ratesForEntity.taxRates?.[jurisdictionId],
    foreignExchangeRates: ratesForEntity.foreignExchangeRates?.[currencyId]
  };
};
