import { createReducer, isAnyOf, PayloadAction } from '@reduxjs/toolkit';

import {
  categoriesOnFailed,
  categoriesOnReceived,
  categoriesOnSendingRequest,
  categoriesOnCreated,
  categoryDeleteRequest,
  categoryDeleteSuccess,
  categoryDeleteFailure,
  categoryUpdateFailure,
  categoryUpdateRequest,
  categoryUpdateSuccess,
  categoryAccountsUpdateFailure,
  categoryAccountsUpdateRequest,
  categoryAccountsUpdateSuccess
} from './categories.actions';
import { categoriesInitialState } from './categories.initialState';

import { CategoryAccountPair, CategoryFromApi, DeletedCategoryFromApi, UpdatedCategoryFromApi } from '../../models';
import { containersOnCurrentSwitched } from '../containers';

export const categoriesReducer = createReducer(categoriesInitialState, (builder) => {
  builder
    .addCase(categoriesOnSendingRequest, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(categoriesOnCreated, (state, action: PayloadAction<CategoryFromApi>) => {
      state.categories.push(action.payload);
      state.isLoading = false;
      state.isLoaded = true;
      state.error = null;
    })
    .addCase(categoriesOnReceived, (state, action: PayloadAction<CategoryFromApi[]>) => {
      state.categories = action.payload;
      state.isLoading = false;
      state.isLoaded = true;
      state.error = null;
    })
    .addCase(categoriesOnFailed, (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    .addCase(categoryDeleteRequest, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(categoryDeleteSuccess, (state, action: PayloadAction<DeletedCategoryFromApi>) => {
      state.categories = state.categories.filter((category) => category.categoryId !== action.payload.deleted);

      state.isLoading = false;
    })
    .addCase(categoryDeleteFailure, (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    .addCase(categoryUpdateRequest, (state) => {
      state.updateCategory.isLoading = true;
      state.updateCategory.error = null;
    })
    .addCase(categoryUpdateSuccess, (state, action: PayloadAction<UpdatedCategoryFromApi>) => {
      const index = state.categories.findIndex((category) => category.categoryId === action.payload.categoryId);
      if (index !== -1) {
        state.categories[index].name = action.payload.categoryName;
        state.categories[index].color = action.payload.categoryColor;
      }

      state.updateCategory.isLoading = false;
    })
    .addCase(categoryUpdateFailure, (state, action: PayloadAction<string>) => {
      state.updateCategory.isLoading = false;
      state.updateCategory.error = action.payload;
    })
    .addCase(categoryAccountsUpdateRequest, (state) => {
      state.updateCategory.isLoading = true;
      state.updateCategory.error = null;
    })
    .addCase(
      categoryAccountsUpdateSuccess,
      (
        state,
        action: PayloadAction<{
          categoryId: string;
          action: 'ADD' | 'DELETE';
          categoryAccountPairs: CategoryAccountPair[];
          entityIds?: string[];
        }>
      ) => {
        const { categoryId, action: actionType, categoryAccountPairs, entityIds } = action.payload;

        const category = state.categories.find((category) => category.categoryId === categoryId);
        if (category) {
          categoryAccountPairs.forEach(([attributeName, stepName]) => {
            const accountIndex = category.accounts.findIndex(
              (account) => account.attributeName === attributeName && account.stepName === stepName
            );
            if (actionType === 'ADD' && accountIndex === -1) {
              category.accounts.push({
                attributeName,
                stepName,
                entityIds: entityIds ?? []
              });
            } else if (actionType === 'DELETE' && accountIndex > -1) {
              category.accounts.splice(accountIndex, 1);
            }
          });
        }

        state.updateCategory.isLoading = false;
      }
    )

    .addCase(categoryAccountsUpdateFailure, (state, action: PayloadAction<string>) => {
      state.updateCategory.isLoading = false;
      state.updateCategory.error = action.payload;
    })
    .addMatcher(isAnyOf(containersOnCurrentSwitched), () => {
      return categoriesInitialState;
    });
});
