/* eslint-disable unicorn/prefer-number-properties */
/* The above is needed as "Number.isNan" will      */
/* convert the arg to number first therefore will  */
/* will only return "true" for the actual "NaN"    */
import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import DateFnsUtils from '@date-io/date-fns';
import { Box, makeStyles, TextField, Typography } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ContainerOperation, PanelProps } from '../../../../models';
import { SET_PERIOD_END, SET_PERIOD_START, SET_TAX_YEAR } from '../../localReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    margin: 'auto',
    width: '80%'
  },
  fullRowContent: {
    color: theme.palette.text.primary,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    width: '100%'
  },
  taxYearContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(5),
    height: theme.spacing(3)
  },
  inputTaxYear: {
    width: '48%',
    marginBottom: theme.spacing(0.5),
    backgroundColor: theme.palette.secondary.dark
  },
  inputsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(5),
    height: theme.spacing(8)
  },
  inputGridElement: {
    marginBottom: theme.spacing(2),
    width: '48%'
  },
  inputTitle: {
    color: theme.palette.primary.dark,
    marginBottom: theme.spacing(0.5)
  },
  inputFieldPicker: {
    width: '100%',
    marginTop: 0,
    backgroundColor: theme.palette.common.white
  }
}));

const ErrorMessage = ({ error, message }: { error: boolean; message: string }) =>
  error ? (
    <Typography color="error" variant="caption">
      {message}
    </Typography>
  ) : null;

const DefineContainerPeriods = ({
  localDispatch,
  localState: { containerOperation, defineContainerPeriods, rolledFromContainer },
  setIsRightActionEnabled
}: PanelProps) => {
  const { t } = useTranslation();
  const { prov3932ImproveAddNewPeriodModal } = useFlags();

  const classes = useStyles();

  const isYearValid = /^\d{4}$/.test(defineContainerPeriods.taxYear ?? '');
  const isPeriodStartValid =
    Boolean(defineContainerPeriods.periodStart) && !isNaN(defineContainerPeriods.periodStart as any);
  const isPeriodEndValid = Boolean(defineContainerPeriods.periodEnd) && !isNaN(defineContainerPeriods.periodEnd as any);
  const isStartBeforeEnd =
    isPeriodEndValid && isPeriodStartValid && defineContainerPeriods.periodStart! < defineContainerPeriods.periodEnd!;
  const isPristine = [
    defineContainerPeriods.periodStart,
    defineContainerPeriods.periodStart,
    defineContainerPeriods.periodEnd
    // eslint-disable-next-line unicorn/no-useless-undefined
  ].includes(undefined);

  useEffect(() => {
    if (!defineContainerPeriods.taxYear && rolledFromContainer?.taxYear) {
      handleTaxYearChange(
        (
          rolledFromContainer.taxYear + (containerOperation === 'copy' && prov3932ImproveAddNewPeriodModal ? 0 : 1)
        ).toString()
      );
    }
    // I only want to run this once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsRightActionEnabled(isYearValid && isPeriodStartValid && isPeriodEndValid && isStartBeforeEnd);
  }, [isPeriodEndValid, isPeriodStartValid, isStartBeforeEnd, isYearValid, setIsRightActionEnabled]);

  useEffect(() => {
    if (rolledFromContainer && prov3932ImproveAddNewPeriodModal) {
      const startDate = new Date(rolledFromContainer?.startDate);
      const endDate = new Date(rolledFromContainer?.endDate);
      if (containerOperation !== 'copy') {
        startDate.setFullYear(startDate.getFullYear() + 1);
        endDate.setFullYear(endDate.getFullYear() + 1);
      }

      if (startDate.getFullYear() !== endDate.getFullYear()) {
        handlePeriodStartChange(startDate);
        handlePeriodEndChange(endDate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTaxYearChange = (taxYear: string) => {
    localDispatch({
      type: SET_TAX_YEAR,
      payload: taxYear
    });
    const isYearValid = /^\d{4}$/.test(taxYear);

    if (isYearValid && !defineContainerPeriods.periodStart) {
      const month = rolledFromContainer?.startDate ? new Date(rolledFromContainer.startDate).getMonth() : 0;
      const date = rolledFromContainer?.startDate ? new Date(rolledFromContainer.startDate).getDate() : 1;
      handlePeriodStartChange(new Date(Number(taxYear), month, date));
    }
  };

  const handlePeriodStartChange = (periodStart: Date | null) => {
    localDispatch({
      type: SET_PERIOD_START,
      payload: periodStart
    });
    if (periodStart && !defineContainerPeriods.periodEnd) {
      const year = periodStart.getFullYear();
      const month = rolledFromContainer?.endDate ? new Date(rolledFromContainer.endDate).getMonth() : 11;
      const date = rolledFromContainer?.endDate ? new Date(rolledFromContainer.endDate).getDate() : 31;
      handlePeriodEndChange(new Date(year, month, date));
    }
  };

  const handlePeriodEndChange = (periodEnd: Date | null) => {
    localDispatch({
      type: SET_PERIOD_END,
      payload: periodEnd
    });
  };

  const confirmationTitleMap: Record<ContainerOperation, string> = {
    'rollforward-yearly': t('confirm-yearly-period'),
    'rollforward-interim': t('confirm-interim-period'),
    copy: t('confirm-new-period')
  };

  return (
    <Box className={classes.root}>
      <Box>
        <Box className={classes.fullRowContent}>{confirmationTitleMap[containerOperation]}</Box>
        <Box className={classes.taxYearContainer}>
          <Typography variant="h3" className={classes.inputTitle}>
            {t('Tax Year')}
          </Typography>
          <TextField
            fullWidth
            type="number"
            autoComplete="off"
            variant="outlined"
            size="small"
            error={!isPristine && !isYearValid}
            className={classes.inputTaxYear}
            value={defineContainerPeriods.taxYear ?? ''}
            onChange={({ target: { value } }) => {
              handleTaxYearChange(value);
            }}
          />
          <ErrorMessage error={!isPristine && !isYearValid} message={t('Tax Year should be defined as YYYY')} />
        </Box>
        <Box className={classes.inputsContainer}>
          <Box className={classes.inputGridElement}>
            <Typography variant="h3" className={classes.inputTitle}>
              {t('Time Period Start Date')}
            </Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                autoOk
                disabled={!isYearValid}
                error={!isPristine && !isPeriodStartValid}
                helperText={null}
                size="small"
                variant="inline"
                inputVariant="outlined"
                className={classes.inputFieldPicker}
                margin="normal"
                id="date-picker-period-start"
                format="MM/dd/yyyy"
                value={defineContainerPeriods.periodStart}
                onChange={handlePeriodStartChange}
              />
            </MuiPickersUtilsProvider>
            <ErrorMessage error={!isPristine && !isPeriodStartValid} message={t('Date is not valid')} />
          </Box>
          <Box className={classes.inputGridElement}>
            <Typography variant="h3" className={classes.inputTitle}>
              {t('Time Period End Date')}
            </Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                autoOk
                disabled={!isYearValid}
                error={!isPristine && (!isPeriodEndValid || !isStartBeforeEnd)}
                helperText={null}
                size="small"
                variant="inline"
                inputVariant="outlined"
                className={classes.inputFieldPicker}
                margin="normal"
                id="date-picker-period-end"
                format="MM/dd/yyyy"
                value={defineContainerPeriods.periodEnd}
                onChange={handlePeriodEndChange}
              />
            </MuiPickersUtilsProvider>
            <ErrorMessage error={!isPristine && !isPeriodEndValid} message={t('Date is not valid')} />
            <ErrorMessage
              error={!isPristine && isPeriodEndValid && isPeriodStartValid && !isStartBeforeEnd}
              message={t('Tax Period End Date can not be before Tax Period Start Date')}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DefineContainerPeriods;
