import { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { TableWithComment } from '../..';
import { calculateIncomeStatement } from '../../../calculations';
import { FEDERAL_UUID, LEVELS } from '../../../constants';
import { useCompletionStatus, useEntity, useFinancialData } from '../../../hooks';
import { Step } from '../../../models';
import LoadingWrapper from '../../LoadingWrapper';
import { Row } from '../../Table/Table.proptype';
import {
  EntityNumberRouteMatch,
  getRowNamesFromLevelSteps,
  getTableDataFromFinancialData,
  handleEditRowForEntityDetails,
  handleNewRowForEntityDetails,
  handleOnCellOrCommentBlurForEntityDetails,
  handleOnRowDeleteForEntityDetails,
  IncomeStatementAccountShape
} from '../utils';

export type IncomeStatementAccountRows = Row & IncomeStatementAccountShape;

const LEVEL = LEVELS.FEDERAL;
const STEP: Step = 'temporary.incomeStatement';
const LevelAndStep = `${LEVEL}.${STEP}`;
const STEPS: Step[] = ['temporary', 'rtp', 'deferred'];

const IncomeStatement = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    params: { entityNumber }
  } = useRouteMatch<EntityNumberRouteMatch>();
  const { entity } = useEntity(entityNumber);
  const {
    prov3322EditPerformanceFix: isPerformanceFixEnabled,
    prov3736ReservedWordValidation: showReservedWordsError
  } = useFlags();
  const entityId = entity?.entityId;

  const [rows, setRows] = useState<IncomeStatementAccountRows[]>([]);
  const [hasNewRow, setHasNewRow] = useState(false);

  const { stepCompletionStatus } = useCompletionStatus(entityNumber, `${LEVEL}.temporary`, FEDERAL_UUID);
  const { tabsData, failedCells, isFetchLoading } = useFinancialData(entityNumber, LEVEL, STEPS);
  const tempFinancialInfo = useMemo(() => tabsData[LevelAndStep] ?? [], [tabsData]);
  const deferredFinancialInfo = useMemo(() => tabsData['federal.deferred'] ?? [], [tabsData]);
  const rtpFinancialInfo = useMemo(() => tabsData['federal.rtp'] ?? [], [tabsData]);
  const balanceSheetFinancialInfo = useMemo(() => tabsData['federal.temporary.balanceSheet'] ?? [], [tabsData]);
  const mergedFinancials = useMemo(
    () => [...deferredFinancialInfo, ...rtpFinancialInfo, ...tempFinancialInfo, ...balanceSheetFinancialInfo],
    [balanceSheetFinancialInfo, deferredFinancialInfo, rtpFinancialInfo, tempFinancialInfo]
  );

  const rowNames = useMemo(() => getRowNamesFromLevelSteps(tabsData, LEVEL, [STEP])[STEP], [tabsData]);
  const rowsWithData = useMemo(() => getTableDataFromFinancialData(rowNames, tempFinancialInfo), [
    tempFinancialInfo,
    rowNames
  ]) as IncomeStatementAccountRows[];

  useEffect(() => {
    setRows(rowsWithData.map((row) => calculateIncomeStatement(row)));
  }, [rowsWithData]);

  const columns = [
    {
      field: 'accountNumber',
      filterable: true,
      sortable: true,
      placeholder: t('Trial Balance'),
      width: '18%'
    },
    {
      field: 'name',
      divider: true,
      filterable: true,
      sortable: true,
      placeholder: t('Adjustment'),
      isNewRowEditable: true,
      width: '18%'
    },
    {
      headerName: t('Book Balance'),
      field: 'bookBalance',
      isEditable: !stepCompletionStatus.status,
      isNumber: true
    },
    {
      headerName: t('Tax Balance'),
      field: 'taxBalance',
      isEditable: !stepCompletionStatus.status,
      isNumber: true
    },
    {
      headerName: t('M-1 Adjustment'),
      field: 'm1Adjustment',
      isNumber: true
    }
  ];

  const dataForMethods = {
    calculateFunc: calculateIncomeStatement,
    columns,
    dispatch,
    entityId,
    financialInfo: mergedFinancials,
    hasNewRow,
    level: LEVEL,
    rows,
    setHasNewRow,
    setRows,
    step: STEP,
    t,
    showReservedWordsError
  };

  return (
    <LoadingWrapper isLoading={isFetchLoading}>
      <TableWithComment
        columns={columns}
        failedCells={failedCells}
        newRowButtonLabel={
          stepCompletionStatus.status ? null : t(hasNewRow ? 'Save Adjustment' : 'Add Temporary Adjustment')
        }
        rows={rows}
        hideActionsMenu={stepCompletionStatus.status}
        totalHeaderName={t('Total')}
        handleOnRowDelete={(params) => {
          handleOnRowDeleteForEntityDetails({
            ...dataForMethods,
            ...params
          });
        }}
        onCellChange={(params) => {
          if (!isPerformanceFixEnabled) {
            handleEditRowForEntityDetails({
              ...dataForMethods,
              ...params
            });
          }
        }}
        onCellOrCommentBlur={(params) => {
          if (isPerformanceFixEnabled) {
            handleEditRowForEntityDetails({
              ...dataForMethods,
              ...params
            });
          }

          handleOnCellOrCommentBlurForEntityDetails({
            ...dataForMethods,
            ...params
          });
        }}
        onNewRowClick={() => {
          handleNewRowForEntityDetails({
            ...dataForMethods
          });
        }}
      />
    </LoadingWrapper>
  );
};

export default IncomeStatement;
