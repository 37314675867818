import { foreignEntitiesExcludedReportNames, RenderReportsProps } from './ReportListConnector';

import { shouldUseCalcEngineReport } from '../../../../hooks';
import { Container, CustomGroup, Entity, ListedReportShape } from '../../../../models';
import HTTPService from '../../../../services/http';
import transformGroupUuid from '../../../../utils/transformGroupUuid';
import ReportBox from '../ReportBox';

export const shortenName = (name: string) => {
  if (name === 'deferred-federal') {
    // Special case needed because for some reason this entry is abnormal.
    return 'Deferred Federal';
  }

  const regExp = /([A-Z][a-z]+)/g;
  const groups = name.match(regExp);
  return groups?.join(' ') ?? '';
};

export const renderReports = ({
  refinedReports,
  reportType,
  currencyISO,
  reportsOrder,
  hasStates,
  reportsMeta,
  reportSourceId,
  type
}: RenderReportsProps) => {
  if (!Array.isArray(refinedReports)) {
    return [];
  }

  const reportsFiltered = refinedReports.filter(({ type }) => reportType === 'all-reports' || type === reportType);
  const reportsToBeDisplayed = hasStates
    ? reportsFiltered
    : reportsFiltered.filter((report) => !foreignEntitiesExcludedReportNames.has(report.name));
  reportsToBeDisplayed.sort((reportA, reportB) => reportsOrder[reportA.name] - reportsOrder[reportB.name]);
  const composedReportList = reportsToBeDisplayed.map((report) => {
    const to = `${currencyISO}/${report.name}`;
    const isCurrencyConversion = currencyISO === 'USD';
    const reportsMetaKey = `${String(report.name)}_${String(transformGroupUuid(reportSourceId))}_${String(
      isCurrencyConversion
    )}`;
    const reportMetadata = reportsMeta[type][reportsMetaKey];

    return (
      <ReportBox
        key={report.name}
        {...report}
        to={to}
        reportStatus={reportMetadata?.status}
        reportTimestamp={reportMetadata?.reportTimestamp}
      />
    );
  });

  return composedReportList;
};

interface FetchReports {
  useCalcEngineReport?: boolean;
  container?: Container;
  currencyISO: string;
  entity?: Entity;
  reports: ListedReportShape[];
  group?: CustomGroup;
}

export const fetchExcelReport = async ({ container }: any) => {
  const result = await HTTPService.request({
    apiUrlKey: 'excelReports',
    method: 'get',
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    relativePath: `/v1/tenant/${container?.containerId}/container/${container?.containerId}/workbook`
  });

  return { result };
};

export const fetchReports = async ({
  useCalcEngineReport,
  container,
  currencyISO,
  entity,
  group,
  reports
}: FetchReports) => {
  const preSignedUrlRequestsPromises = [];

  const reportDate = new Date(container!.endDate).toISOString().split('T')[0];
  const reportDateCurrencyPath = `/${reportDate}/${currencyISO}`;

  const uuid = entity ? entity.entityId : transformGroupUuid(group?.groupId ?? '');
  for (const refinedReport of reports) {
    const urlPath = [entity ? 'entity' : 'group', uuid, refinedReport.name].join('/');
    if (shouldUseCalcEngineReport(refinedReport.name) && useCalcEngineReport) {
      const promise = HTTPService.request({
        apiUrlKey: 'calculationReports',
        method: 'get',
        relativePath: `/v1/reports/calc-engine/${urlPath}/${currencyISO}/${container!.taxYear}`
      });
      preSignedUrlRequestsPromises.push(promise);
    } else {
      const promise = HTTPService.request({
        apiUrlKey: 'reportsApiUrl',
        method: 'get',
        relativePath: `/v1/reports/${urlPath}${reportDateCurrencyPath}`
      });
      preSignedUrlRequestsPromises.push(promise);
    }
  }

  const results = await Promise.all(preSignedUrlRequestsPromises);

  const s3RequestsPromises = [];
  for (const result of results as any) {
    const newPromise =
      result?.data?.key && useCalcEngineReport
        ? result.data
        : HTTPService.simpleRequest({
            method: 'get',
            url: result.data?.url ?? result.data
          });

    s3RequestsPromises.push(newPromise);
  }

  return Promise.all(s3RequestsPromises);
};

interface GetComparisonFile {
  reportType: string;
  reportModelId: string;
  reportName: string;
  currencyIsoCode: string | undefined;
  taxYear: number | undefined;
}

export const getComparisonFile = async ({
  reportType,
  reportModelId,
  reportName,
  currencyIsoCode,
  taxYear
}: GetComparisonFile) => {
  const preSignedUrlRequestsPromises = [];

  const promise = HTTPService.request({
    apiUrlKey: 'calculationReports',
    method: 'get',
    relativePath: `/v1/reports/calc-engine/create-csv/${reportType}/${reportModelId}/${reportName}/${String(
      currencyIsoCode
    )}/${String(taxYear)}`
  });
  preSignedUrlRequestsPromises.push(promise);

  const results: any[] = await Promise.all(preSignedUrlRequestsPromises);
  return results;
};

export const getExcelFile = async ({
  reportType,
  reportModelId,
  reportName,
  currencyIsoCode,
  taxYear
}: GetComparisonFile) => {
  const results: string = await HTTPService.request({
    apiUrlKey: 'calculationReports',
    method: 'get',
    params: { reportType, reportModelId, reportName, currencyIsoCode, taxYear },
    relativePath: '/v1/reports/calc-engine/excel'
  });

  return results;
};
