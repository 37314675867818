export interface CalculateIncomeStatementReturn {
  bookBalance?: number;
  m1Adjustment?: number;
  rtp?: number;
  taxBalance?: number;
}

export const calculateIncomeStatement = <T extends CalculateIncomeStatementReturn>(inputs: T) => {
  const { bookBalance, taxBalance } = inputs ?? {};
  return {
    ...inputs,
    m1Adjustment: (bookBalance ?? 0) - (taxBalance ?? 0)
  };
};
