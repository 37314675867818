export const ADD_ATTRIBUTE = 'ADD_ATTRIBUTE';
export const REMOVE_ATTRIBUTE = 'REMOVE_ATTRIBUTE';
export const START_EDITING = 'START_EDITING';
export const TOGGLE_ROWS = 'TOGGLE_ROWS';
export const STOP_EDITING = 'STOP_EDITING';
export const INIT = 'INIT';

interface Props {
  attributes?: any[];
  assignedAttributeByRowId?: Record<string, any>;
}

export function makeInitialState(props?: Props) {
  const { attributes, assignedAttributeByRowId } = {
    attributes: props?.attributes ?? [],
    assignedAttributeByRowId: props?.assignedAttributeByRowId ?? {}
  };
  const selected: any = [];
  for (const attribute of Object.values(assignedAttributeByRowId ?? {})) {
    if (!selected.includes(attribute)) {
      selected.push(attribute);
    }
  }

  return {
    attributes: attributes ?? [],
    editedId: null,
    selected,
    ...formatAttributes(selected, { ...assignedAttributeByRowId })
  };
}

function formatAttributes(selected: any, assignedAttributeByRowId: Record<string, any>) {
  const assignedRowIdsByAttrId: Record<string, any> = {};
  for (const { id } of selected) {
    const rowIds = [];
    for (const [rowId, attr] of Object.entries(assignedAttributeByRowId)) {
      if (attr?.id === id) {
        rowIds.push(rowId);
      }
    }

    assignedRowIdsByAttrId[id] = rowIds;
  }

  return { assignedAttributeByRowId, assignedRowIdsByAttrId };
}

export function reducer(state: any, { type, payload = {} }: any = {}) {
  switch (type) {
    case INIT: {
      const { attributes, assignedAttributeByRowId } = payload;
      return makeInitialState({ attributes, assignedAttributeByRowId });
    }

    case ADD_ATTRIBUTE: {
      const { selected, attributes } = state;
      const editedId = payload.id;
      const edited = attributes.find(({ id }: any) => id === editedId);
      return edited && !selected.includes(edited) ? { ...state, editedId, selected: [...selected, edited] } : state;
    }

    case START_EDITING: {
      return {
        ...state,
        editedId: state.selected.find(({ id }: any) => payload.id === id)?.id || null
      };
    }

    case STOP_EDITING: {
      return { ...state, editedId: null };
    }

    case TOGGLE_ROWS: {
      const { editedId, assignedAttributeByRowId, attributes, selected } = state;
      const edited = attributes.find(({ id }: any) => id === editedId);
      if (!edited) {
        return state;
      }

      const newAssignedAttributeByRowId = { ...assignedAttributeByRowId };
      for (const rowId of payload.rowIds) {
        if (payload.areSelected) {
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          delete newAssignedAttributeByRowId[rowId];
        } else {
          newAssignedAttributeByRowId[rowId] = edited;
        }
      }

      return {
        ...state,
        ...formatAttributes(selected, { ...newAssignedAttributeByRowId })
      };
    }

    default:
      return state;
  }
}
