import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { useContainers } from '..';
import { ExchangeRatesFromAPI, TaxRatesFromAPI } from '../../models';
import { ratesOnFailed, ratesOnReceived, ratesOnSendingRequest } from '../../redux/rates';
import { selectRates } from '../../selectors/rates';
import HTTPService, { LambdaResponse } from '../../services/http';

export function useRates() {
  const dispatch = useDispatch();
  const ratesState = useSelector(selectRates);
  const { currentContainer } = useContainers();
  const {
    statutoryRatesV2: statutoryRatesFlag,
    prov3715TaxRatesStartDate: shouldUsePeriodStartForTaxRates
  } = useFlags();
  useEffect(() => {
    // cleanup flag prov-2711 prov-2869
    localStorage.setItem('statutoryRatesFlag', String(statutoryRatesFlag));

    async function fetchData() {
      dispatch(ratesOnSendingRequest());
      const endDate = new Date(currentContainer!.endDate).toISOString().split('T')[0];
      const startDate = new Date(currentContainer!.startDate).toISOString().split('T')[0];
      const taxRateDate = shouldUsePeriodStartForTaxRates ? startDate : endDate;

      try {
        const [{ data: taxRates }, { data: exchangeRates }] = await Promise.all([
          HTTPService.request<LambdaResponse<TaxRatesFromAPI>>({
            method: 'get',
            relativePath: `/v1/tax-rates/${taxRateDate}`
          }),
          HTTPService.request<LambdaResponse<ExchangeRatesFromAPI>>({
            method: 'get',
            relativePath: `/v1/exchange-rates/${startDate}/${endDate}`
          })
        ]);

        dispatch(ratesOnReceived({ taxRates, exchangeRates }));
      } catch (error: unknown) {
        dispatch(ratesOnFailed(error));
      }
    }

    if (
      !ratesState.isLoading &&
      !ratesState.isLoaded &&
      currentContainer?.containerId &&
      currentContainer?.endDate &&
      currentContainer?.startDate &&
      !ratesState.error
    ) {
      void fetchData();
    }
  }, [ratesState, statutoryRatesFlag, currentContainer, dispatch, shouldUsePeriodStartForTaxRates]);

  return ratesState;
}
