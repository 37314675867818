import { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Dialog,
  DialogActions,
  Button,
  Box,
  Typography,
  IconButton,
  TableCell,
  TableBody,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  Paper
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { useStyles } from './styles';

import { ReactComponent as EqualOutlineIcon } from '../../../../assets/img/Icon_EqualOutline.svg';
import { ReactComponent as EqualIcon } from '../../../../assets/img/IconEqual.svg';
import { ReactComponent as NotEqualIcon } from '../../../../assets/img/IconNotEqual.svg';
import { NULL_UUID } from '../../../../constants';
import { useFinancialData } from '../../../../hooks';
import { FinancialInfo, Level, Row, Step } from '../../../../models';
import { updateFinancialData } from '../../../../redux/financialData';
import { selectEntitiesCompletionStatus } from '../../../../selectors/entitiesCompletionStatus';
import theme from '../../../../theme';
import { EditableNumericCell } from '../../../Table/components';
import { onCellEvent } from '../../../Table/Table.proptype';
import { getNolColumns } from '../FederalNetOperatingLoss';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  level: Level;
  steps: Step[];
  total: number;
  entityNumber: string;
  entityId: string;
  currentStep: Step;
  creditName?: string;
  currentState?: any;
}

type UpdateData = {
  accountId: string;
  rowName: string;
  creditName: string;
  columnName: string;
  rowId: string;
  entityFinancialsTraceId: string;
  level: Level;
  step: Step;
  value: string | number;
};

const formatNumber = (num: number) => {
  if (num < 0) {
    return `(${Math.abs(num).toLocaleString()})`;
  }

  return num.toLocaleString();
};

const getRowsByCreditName = (rows: FinancialInfo[], step: Step, creditName?: string) => {
  if (step !== 'credits') return rows;
  const newRows = creditName
    ? rows.filter((row: Row) => {
        return row.creditName === creditName;
      })
    : rows.filter((row: Row) => {
        return row.creditName;
      });
  return newRows;
};

const doesRecordExist = (record: any, rowName: string, columnName: string) => {
  return record.columnName === columnName && record.rowName === rowName;
};

const TAX_RETURN_KEY = 'taxReturn';
const TAX_PROVISION_KEY = 'taxProvision';
const RTP_KEY = 'rtp';
const STATE_RTP_NOL_ROW_NAME = 'Post-Apportioned Net Operating Loss';
const NET_OPERATING_LOSS = 'Net Operating Loss';
const TAX_RETURN = 'Tax Return';
const TAX_PROVISION = 'Tax Provision';

const DEFAULT_RTP_RECORD = {
  jurisdictionId: null,
  columnName: null,
  step: 'rtp',
  value: 0
};

const CustomDialogActions = ({ classes, handleClose, handleSubmitAndClose, t }: any) => (
  <DialogActions className={classes.buttonContainer}>
    <Button className={classes.cancelButton} onClick={handleClose}>
      {t('Cancel')}
    </Button>
    <Button className={classes.saveButton} onClick={handleSubmitAndClose}>
      {t('Save')}
    </Button>
  </DialogActions>
);

const ModalHeader = ({ classes, handleClose, t }: any) => (
  <Box className={classes.headerContainer} display="flex" alignItems="center">
    <Box className={classes.iconContainer}>
      <EqualOutlineIcon />
    </Box>
    <Typography className={classes.headerText}>{t('Balance Calculations')}</Typography>
    <IconButton className={classes.closeButton} data-testid="close-button" onClick={handleClose}>
      <CloseIcon className={classes.closeIcon} />
    </IconButton>
  </Box>
);

const RtpSection = ({
  classes,
  makeTableForModal,
  areTotalsEqual,
  rtpDifference,
  t,
  creditName,
  rtpCompletionStatus
}: any) => (
  <Box className={classes.sectionCard}>
    <Box className={classes.rtpHeaderContainer}>
      <Typography className={classes.cardHeader}>{t('Return to Provision Section')}</Typography>
      <Typography className={classes.rtpCardSubheader}>
        {creditName ? t(creditName) : t('Net Operating Loss')}
      </Typography>
    </Box>
    <Box className={classes.tableTotalWrapper}>
      {makeTableForModal(RTP_KEY, null, rtpCompletionStatus)}
      <Box className={classes.totalSection}>
        <Typography className={classes.totalText}>{t('Total (Variance)')}</Typography>
        <Box
          className={classes.totalValueContainer}
          style={{
            backgroundColor: areTotalsEqual ? theme.palette.background.paper : theme.palette.progress.lighter
          }}
        >
          {areTotalsEqual ? <EqualIcon /> : <NotEqualIcon />}
          <Typography className={classes.valueText}>{formatNumber(rtpDifference)}</Typography>
        </Box>
      </Box>
    </Box>
  </Box>
);

const StepSection = ({
  classes,
  makeTableForModal,
  areTotalsEqual,
  rtpColumnTotal,
  t,
  creditName,
  nolOrCreditsCompletionStatus
}: any) => (
  <Box className={classes.sectionCard}>
    <Typography className={classes.cardHeader}>
      {creditName ? t('Credits Section') : t('Net Operating Loss Section')}
    </Typography>
    <Box className={classes.tableTotalWrapper}>
      {makeTableForModal('nol', true, nolOrCreditsCompletionStatus)}
      <Box className={classes.totalSection}>
        <Typography className={classes.totalText}>{t('Total')}</Typography>
        <Box
          className={classes.totalValueContainer}
          style={{
            backgroundColor: areTotalsEqual ? theme.palette.background.paper : theme.palette.progress.lighter
          }}
        >
          {areTotalsEqual ? <EqualIcon /> : <NotEqualIcon />}
          <Typography className={classes.valueText}>{formatNumber(rtpColumnTotal)}</Typography>
        </Box>
      </Box>
    </Box>
  </Box>
);

const BalanceCalculationsModal = ({
  setOpen,
  open,
  level,
  steps,
  total,
  entityNumber,
  entityId,
  creditName,
  currentStep,
  currentState
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let columns = getNolColumns(t, {});
  const { tabsData, failedCells } = useFinancialData(entityNumber, level, steps, currentState?.id);
  const rtpFinancialInfo = useMemo(() => tabsData[`${level}.rtp`] ?? [], [level, tabsData]);
  const currentStepFinancialInfo = useMemo(() => tabsData[`${level}.${currentStep}`] ?? [], [
    currentStep,
    level,
    tabsData
  ]);
  const [rtpDifference, setRtpDifference] = useState<number>(total);
  const [rtpColumnTotal, setRtpColumnTotal] = useState<number>(total);
  const [areTotalsEqual, setAreTotalsEqual] = useState<boolean>(false);
  const [pendingUpdates, setPendingUpdates] = useState<UpdateData[]>([]);
  const rtpNolRowName = currentState ? STATE_RTP_NOL_ROW_NAME : NET_OPERATING_LOSS;
  const entitiesCompletionStatus = useSelector(selectEntitiesCompletionStatus);
  const entityCompletionStatus =
    entitiesCompletionStatus?.entitiesCompletionStatus?.[entityNumber]?.completionStatus?.[level];
  const rtpCompletionStatus =
    entityCompletionStatus?.rtp?.[level === 'state' && currentState?.id ? currentState?.id : NULL_UUID];
  const nolOrCreditsCompletionStatus =
    entityCompletionStatus?.[steps.find((step) => step !== 'rtp') ?? 'rtp']?.[
      level === 'state' && currentState?.id ? currentState?.id : NULL_UUID
    ];

  const getDataForRtp = () => {
    const rowName = creditName ? creditName : rtpNolRowName;
    const taxReturnRecord = rtpFinancialInfo.find((record) => doesRecordExist(record, rowName, TAX_RETURN_KEY)) ?? {
      ...DEFAULT_RTP_RECORD,
      columnName: TAX_RETURN_KEY,
      rowName,
      entityFinancialsTraceId: '1',
      creditName: creditName ? 'state.credits' : null,
      jurisdictionId: currentState?.id ?? null
    };
    const taxProvisionRecord = rtpFinancialInfo.find((record) =>
      doesRecordExist(record, rowName, TAX_PROVISION_KEY)
    ) ?? {
      ...DEFAULT_RTP_RECORD,
      columnName: TAX_PROVISION_KEY,
      rowName,
      entityFinancialsTraceId: '2',
      creditName: creditName ? 'state.credits' : null,
      jurisdictionId: currentState?.id ?? null
    };

    const data = [
      {
        ...taxReturnRecord,
        name: TAX_RETURN,
        rtp: taxReturnRecord?.value ?? 0
      },
      {
        ...taxProvisionRecord,
        name: TAX_PROVISION,
        rtp: taxProvisionRecord?.value ?? 0
      }
    ];
    return data;
  };

  const getRtpColumnByRowName = useCallback(
    (rowName: string) => {
      return currentStepFinancialInfo.find((record) => {
        return record.rowName === rowName && record.columnName === 'rtp';
      });
    },
    [currentStepFinancialInfo]
  );

  const getDataForCreditsOrNol = useCallback(() => {
    const filteredData = creditName
      ? getRowsByCreditName(currentStepFinancialInfo, currentStep, creditName)
      : currentStepFinancialInfo;
    const finData = [];
    let newRecord;
    for (const record of filteredData) {
      let newRecordForCurrentRow;
      const doesRtpColumnExist = getRtpColumnByRowName(record.rowName);
      if (doesRtpColumnExist && record.columnName === 'rtp') finData.push({ ...record, rtp: record.value ?? 0 });
      else if (!doesRtpColumnExist) {
        newRecordForCurrentRow = {
          jurisdictionId: currentState?.id ?? null,
          accountNumber: record.accountNumber ?? null,
          columnName: 'rtp',
          step: currentStep,
          value: 0,
          rowName: record.rowName,
          rowId: record.rowId,
          creditName: creditName ?? null
        };
        if (newRecordForCurrentRow !== newRecord) {
          newRecord = newRecordForCurrentRow;
          finData.push({ ...newRecord, rtp: 0 });
        }
      }
    }

    return finData;
  }, [creditName, currentState, currentStep, currentStepFinancialInfo, getRtpColumnByRowName]);

  const [rtpData, setRtpData] = useState(getDataForRtp());
  const [processedRows, setProcessedRows] = useState(getDataForCreditsOrNol());

  useEffect(() => {
    const rows = getDataForCreditsOrNol();
    setProcessedRows(getRowsByCreditName(rows, currentStep, creditName));
  }, [creditName, getDataForCreditsOrNol, currentStep]);

  useEffect(() => {
    setRtpDifference(total);
  }, [total]);

  useEffect(() => {
    let totalValue = 0;
    processedRows.forEach((row: any) => {
      totalValue += Number(row.rtp);
    });
    setRtpColumnTotal(totalValue);
  }, [processedRows]);

  useEffect(() => {
    setRtpData(getDataForRtp());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rtpFinancialInfo, creditName]);

  useEffect(() => {
    let taxReturn = 0;
    let taxProvision = 0;

    rtpData.forEach((item) => {
      if (item.name === TAX_RETURN) {
        taxReturn = Number(item.rtp);
      } else if (item.name === TAX_PROVISION) {
        taxProvision = Number(item.rtp);
      }
    });

    const difference = taxReturn - taxProvision;
    setRtpDifference(difference);
  }, [rtpData]);

  useEffect(() => {
    if (rtpDifference === rtpColumnTotal) {
      setAreTotalsEqual(true);
    } else {
      setAreTotalsEqual(false);
    }
  }, [areTotalsEqual, rtpColumnTotal, rtpDifference]);

  const rtpColumn = columns.find((column) => column.field === RTP_KEY);
  columns = columns.filter((column) => ['name', RTP_KEY].includes(String(column.field)));

  const handleCellChange = (arg: onCellEvent) => {
    const isRtpDataBeingUpdated = rtpData.some(
      (row) => row.entityFinancialsTraceId === arg.row.entityFinancialsTraceId
    );
    if (arg.row.step === 'rtp' && isRtpDataBeingUpdated) {
      const updatedRtpData = rtpData.map((row) => {
        if (row.entityFinancialsTraceId === arg.row.entityFinancialsTraceId) {
          return {
            ...row,
            rtp: arg.value
          };
        }

        return row;
      });

      setRtpData(updatedRtpData);
      return;
    }

    const updatedRows = processedRows.map((row: any) => {
      if (row.rowId === arg.row.rowId) {
        return {
          ...row,
          rtp: arg.value
        };
      }

      return row;
    });
    setProcessedRows(updatedRows);
  };

  const handleCellBlur = (arg: onCellEvent) => {
    const updateData = {
      accountId: arg.row.accountId,
      rowName: arg.row.rowName ?? arg.row.name,
      creditName: arg.row.creditName,
      columnName: arg.row.columnName ?? arg.column.field,
      rowId: arg.row.rowId,
      entityFinancialsTraceId: arg.row.entityFinancialsTraceId,
      level,
      step: arg.row.step,
      value: arg.value,
      jurisdictionId: level === 'state' ? arg.row.jurisdictionId : undefined
    };

    const newPendingUpdates = [...pendingUpdates];

    const existingUpdateIndex = newPendingUpdates.findIndex(
      (update) =>
        update.rowId === updateData.rowId &&
        update.columnName === updateData.columnName &&
        update.entityFinancialsTraceId === updateData.entityFinancialsTraceId
    );

    if (existingUpdateIndex === -1) {
      newPendingUpdates.push(updateData);
    } else {
      newPendingUpdates[existingUpdateIndex] = updateData;
    }

    setPendingUpdates(newPendingUpdates);
  };

  const handleClose = (e: any) => {
    setRtpData(getDataForRtp());

    setProcessedRows(getDataForCreditsOrNol());

    setPendingUpdates([]);
    setOpen(false);
    e.stopPropagation();
    e.preventDefault();
  };

  const handleSubmitAndClose = (e: any) => {
    pendingUpdates.forEach((updateData) => {
      dispatch(updateFinancialData(updateData, entityId));
    });

    setPendingUpdates([]);

    setOpen(false);
    e.stopPropagation();
    e.preventDefault();
  };

  function makeTableForModal(sectionName: string, hasHeaders = false, completionStatus = false) {
    const tableRows = sectionName === RTP_KEY ? rtpData : processedRows;

    return (
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table>
          {hasHeaders ? (
            <TableHead>
              <TableRow>
                <TableCell className={classes.cardSubheader}>{t('Tax Period')}</TableCell>
                <TableCell className={classes.cardSubheader} align="right">
                  {t('Return to Provision')}
                </TableCell>
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {tableRows.map((row: Row) => {
              return (
                <TableRow key={row.entityFinancialsTraceId ?? row.rowId} className={classes.tableRow}>
                  <TableCell className={classes.tableCell}>{row?.name ?? row?.rowName}</TableCell>
                  <Box
                    className={classes.noPadding}
                    data-roles-disable-element={completionStatus ? 'finalizedContainer' : ''}
                  >
                    <EditableNumericCell
                      failedCells={failedCells}
                      column={{ ...rtpColumn }}
                      row={row}
                      onChange={handleCellChange}
                      onCellOrCommentBlur={handleCellBlur}
                    />
                  </Box>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <Dialog open={open} classes={{ paper: classes.dialogPaper }} onClose={handleClose}>
      <ModalHeader classes={classes} handleClose={handleClose} t={t} />
      <Box className={classes.contentContainer}>
        <Typography className={classes.bodyText1}>{t('Ensure totals are equal')}</Typography>
        <Typography className={classes.bodyText2}>
          {t(
            'Data can be added manually in each section but to ensure a compliant provision report please ensure the ultimate total values are the same across sections.'
          )}
        </Typography>
        <Box className={classes.sectionContainer}>
          <RtpSection
            classes={classes}
            makeTableForModal={makeTableForModal}
            areTotalsEqual={areTotalsEqual}
            rtpDifference={rtpDifference}
            creditName={creditName}
            rtpCompletionStatus={rtpCompletionStatus}
            t={t}
          />
          <StepSection
            classes={classes}
            makeTableForModal={makeTableForModal}
            areTotalsEqual={areTotalsEqual}
            rtpColumnTotal={rtpColumnTotal}
            creditName={creditName}
            nolOrCreditsCompletionStatus={nolOrCreditsCompletionStatus}
            t={t}
          />
        </Box>
      </Box>
      <CustomDialogActions
        classes={classes}
        handleClose={handleClose}
        handleSubmitAndClose={handleSubmitAndClose}
        t={t}
      />
    </Dialog>
  );
};

export default BalanceCalculationsModal;
