import { makeStyles } from '@material-ui/core';

import { Row } from '../../../../../../../../models';
import { formatNumber, formatPercentage, formatDisplayName } from '../../../../../../../../utils';

const useStyles = makeStyles((theme) => ({
  collectionLineItemName: {
    display: 'flex',
    fontFamily: 'Basis Grotesque Pro',
    paddingLeft: '10px',
    paddingRight: '12px',
    width: '75%%'
  },
  collectionLineItemValue: {
    display: 'flex',
    justifyContent: 'end',
    paddingRight: '10px',
    width: '20%'
  },
  lineItemContainer: {
    backgroundColor: theme.palette.common.white,
    borderTop: (props: { hasTopBorder: boolean }) =>
      props.hasTopBorder ? `1px solid ${theme.palette.divider}` : 'none',
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 0)
  }
}));

export const LineItem = ({ row, hasTopBorder = false }: { row: Row; hasTopBorder?: boolean }) => {
  const classes = useStyles({ hasTopBorder });
  const rowName: string = formatDisplayName(row.attributeName, row.name ?? '');
  let value: number;
  let formattedValue;
  if (row.format === 'percentage') {
    value = row.traceValue ?? 0;
    formattedValue = formatPercentage(value);
  } else {
    value = Math.round(row.traceValue ?? 0);
    formattedValue = formatNumber(value, 0, '0');
  }

  return (
    <div key={row.name} className={classes.lineItemContainer}>
      <div className={classes.collectionLineItemName}>{rowName}</div>
      <div className={classes.collectionLineItemValue}>{formattedValue}</div>
    </div>
  );
};
