import { FinancialInfo } from '../../../models';

const getFormattedFinancialInfoValue = (financialInfoData: Pick<FinancialInfo, 'columnName' | 'value'>) => {
  switch (financialInfoData.columnName) {
    case 'note':
      return financialInfoData.value;
    case 'state':
      // `as string` is needed, as TS thinks the return type can be number[], but it can't (if the column is state)
      return Array.isArray(financialInfoData.value) ? financialInfoData.value : [financialInfoData.value as string];
    default:
      return Number(financialInfoData.value);
  }
};

export default getFormattedFinancialInfoValue;
