import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Container, EntityById, Report } from '../../../../models';
import { ReportMeta } from '../../../../redux/reports';
import { selectCategories } from '../../../../selectors/categories';
import { selectShouldGroupCategoriesOnReports, selectToggledCategoriesOnReports } from '../../../../selectors/ui';
import { isByGroupMultiReportsReport } from '../../utils';
import composeTableData from '../../utils/data-processing/composeTableData';
import UnifiedMultiReportView from '../UnifiedMultiReportView';
import UnifiedReportView from '../UnifiedReportView';

const ReportPage = ({
  isSingleEntity,
  container,
  currency,
  report,
  entityById,
  groupName,
  isNewReportAvailable,
  currentReportMeta,
  onReloadReportClick
}: Props) => {
  const { t } = useTranslation();

  const categoriesState = useSelector(selectCategories);
  const shouldGroupCategories = useSelector(selectShouldGroupCategoriesOnReports);
  const toggledCategories = useSelector(selectToggledCategoriesOnReports);

  const { rows, rowsForExcel, columns, totals, reportName } = composeTableData({
    entityById,
    report,
    t,
    categories: categoriesState.categories,
    shouldGroupCategories,
    toggledCategories
  });
  const props = {
    isSingleEntity,
    entityById,
    container,
    currency,
    report,
    columns,
    rows,
    rowsForExcel,
    totals,
    groupName,
    reportName,
    isNewReportAvailable,
    currentReportMeta,
    onReloadReportClick
  };

  return isByGroupMultiReportsReport(report) ? <UnifiedMultiReportView {...props} /> : <UnifiedReportView {...props} />;
};

type Props = {
  isSingleEntity?: boolean;
  container?: Container;
  currency: string;
  report: Report;
  entityById: EntityById;
  groupName: string;
  isNewReportAvailable: boolean;
  currentReportMeta: ReportMeta;
  onReloadReportClick: () => void;
};

export default ReportPage;
