import { useEffect, useState } from 'react';

import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxOutlined from '@material-ui/icons/CheckBoxOutlined';
import CheckBoxRounded from '@material-ui/icons/CheckBoxRounded';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';

import ImportButton from './components/ImportButton/ImportButton';
import { validateMap } from './utils';
import { useStyles } from './ValidateScreen.styles';

import { useFinancialData } from '../../hooks';
import { usePreProcessedFinancialInfo } from '../../hooks/usePreProcessedFinancialInfo';
import { DataImportSelection, Level, Step, SubJurisdiction } from '../../models';
import { saveValidateImport } from '../../redux/validateImport';
import {
  selectDataImportSelection,
  selectSubJurisdictionSidemenuSelectedId,
  selectUploadManagerCreditName
} from '../../selectors';
import { EntityNumberRouteMatch } from '../EntityDetails/utils';
import ErrorMessageBanner from '../ErrorMessageBanner/ErrorMessageBanner';
import { ComposedRow, composeRows } from '../Spreadsheet/utils';
import Table from '../Table';

type ValidateScreenProps = {
  keyHeaderNames: string[];
  level: Level;
  step: Step;
  entitySubJurisdictions: SubJurisdiction[];
  isTabCompleted: boolean;
};

const findDuplicates = (
  dataImportSelection: DataImportSelection,
  dataSelectionName: string,
  checkmarkIndices: boolean[]
): string[][] => {
  const nameSet = new Set();
  const creditNameSet = new Set();
  const duplicates: string[][] = [];

  for (const [i, checkmarkIndex] of checkmarkIndices.entries()) {
    const nameValue = String(dataImportSelection[dataSelectionName].formattedValues[i]);
    const creditNameValue = dataImportSelection['Credit Name']?.formattedValues?.[i];
    const foundNewDuplicate = nameSet.has(nameValue) && !duplicates.some((pair) => pair.includes(nameValue));
    const isCreditNameDuplicated = creditNameValue && creditNameSet.has(creditNameValue);

    if (checkmarkIndex) {
      if (foundNewDuplicate) {
        if (isCreditNameDuplicated) {
          duplicates.push([String(creditNameValue), nameValue]);
        } else if (!creditNameValue) {
          duplicates.push([String(creditNameValue), nameValue]);
        }
      }

      nameSet.add(nameValue);
      creditNameSet.add(creditNameValue);
    }
  }

  return duplicates;
};

const findDuplicatesInFederalTemporarySubTabs = (
  accountDescriptionValues: any,
  step: Step,
  tabsData: { [key: string]: any[] },
  checkmarkIndices: boolean[]
) => {
  let subTabData;
  const duplicates: any[] = [];
  if (step === 'temporary.balanceSheet') {
    subTabData = tabsData['federal.temporary.incomeStatement'].map((data) => data.rowName.toLowerCase());
  } else if (step === 'temporary.incomeStatement') {
    subTabData = tabsData['federal.temporary.balanceSheet'].map((data) => data.rowName.toLowerCase());
  } else {
    return duplicates;
  }

  for (const [i, checkmarkIndex] of checkmarkIndices.entries()) {
    const value = accountDescriptionValues[i].toLowerCase();
    const hasDuplicateValue = subTabData.includes(value);
    if (checkmarkIndex && hasDuplicateValue) {
      duplicates.push(value);
    }
  }

  return duplicates;
};

const ValidateScreen = ({
  keyHeaderNames,
  level,
  step,
  entitySubJurisdictions,
  isTabCompleted
}: ValidateScreenProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dataImportSelection = useSelector(selectDataImportSelection);
  const { t } = useTranslation();
  const {
    params: { entityNumber }
  } = useRouteMatch<EntityNumberRouteMatch>();
  const creditName = useSelector(selectUploadManagerCreditName);
  const subJurisdictionId = useSelector(selectSubJurisdictionSidemenuSelectedId);
  const { tabsData, fetchDataForSpecificStep } = useFinancialData(entityNumber, level, [step]);
  const { prov3250AddColumnsToApportionmentTab: showAdditionalColumns } = useFlags();
  // when prov3250AddColumnsToApportionmentTab flag is removed, revert all flaggedApportionmentStep references back to step, update apportionment step conditionals and remove 'apportionment-flagged' references
  const flaggedApportionmentStep =
    showAdditionalColumns && level === 'state' && step === 'apportionment' ? 'apportionment-flagged' : step;

  useFinancialData(entityNumber, level, ['permanent']);
  useFinancialData(entityNumber, level, ['temporary']);
  /**
   * Some tabs require pre-processing of their financial records. For instance, a tab may depend on
   * row names that are defined in another tab (e.g. State RTP tab depends of State Modifications tab
   * records to know what records should be accepted based on the row names and subjurisdictions
   * associations that are defined in State Modifications).
   */

  const financialInfo = usePreProcessedFinancialInfo({
    creditName,
    dataImportSelection,
    level,
    step,
    tabsData,
    subJurisdictionId,
    entitySubJurisdictions
  });
  const COLUMN_NAMES = validateMap.get(`${level}.${flaggedApportionmentStep}`);

  // Only using keyHeaderNames array version to filter out empty rows pertaining to empty keyheader values in composed row data
  const composedRows = composeRows({
    creditName,
    selectedData: dataImportSelection,
    finData: financialInfo,
    level,
    step: flaggedApportionmentStep,
    keyHeaderNames,
    entitySubJurisdictions,
    subJurisdictionId
  });

  // eslint-disable-next-line unicorn/no-new-array
  const [checkmarkIndices, setCheckmarkIndices] = useState<boolean[]>(new Array(composedRows.length).fill(true));
  const [duplicateNames, setDuplicateNames] = useState<string[][]>([]);
  const [selectionErrorMessage, setSelectionErrorMessage] = useState<string>('');
  const [duplicateValuesBetweenSubTabs, setDuplicateValuesBetweenSubTabs] = useState<string[]>([]);
  const [duplicateValuesError, setDuplicateValuesError] = useState<string>('');

  const composedRowsLength = composedRows.length;
  useEffect(() => {
    dispatch(saveValidateImport(composedRowsLength));
  }, [composedRowsLength, dispatch]);

  useEffect(() => {
    const accountDescriptionValues = dataImportSelection['Account Description']?.formattedValues;
    const duplicatesInTempSubTabs = findDuplicatesInFederalTemporarySubTabs(
      accountDescriptionValues,
      step,
      tabsData,
      checkmarkIndices
    );
    setDuplicateValuesBetweenSubTabs(duplicatesInTempSubTabs);
    if (duplicatesInTempSubTabs.length > 0) {
      setDuplicateValuesError('Adjustment name already exists');
    } else {
      setDuplicateValuesError('');
    }
  }, [checkmarkIndices, dataImportSelection, step, tabsData]);

  useEffect(() => {
    const dataSelectionName = keyHeaderNames[0];
    const duplicates = findDuplicates(dataImportSelection, dataSelectionName, checkmarkIndices);
    setDuplicateNames(duplicates);
    if (duplicates.length > 0) {
      setSelectionErrorMessage('Some adjustments are duplicate. Uncheck the undesired adjustments before proceeding.');
    } else {
      setSelectionErrorMessage('');
    }
  }, [checkmarkIndices, dataImportSelection, keyHeaderNames]);

  // Only using first index here because it still helps user to see if changes were made to modifiction type or state for state modifications entity details tab
  // eslint-disable-next-line unicorn/no-array-reduce
  const columns: any = COLUMN_NAMES?.filter((name: string) => Object.keys(dataImportSelection).includes(name))?.reduce(
    (acc, headerName) =>
      [
        ...acc,
        {
          renderHeader: () => t(headerName),
          field: headerName,
          renderCell: (row: ComposedRow) => {
            const isNewRow = row.isNew;
            const isMatchingCell = row.cells[headerName].isSameValue;
            const isIgnoredCell = row.cells[headerName].isIgnored;
            const isExistingRowAndSameValue = !isNewRow && isMatchingCell;
            const isExistingRowAndDifferentValue = !isNewRow && !isMatchingCell;
            let valueToDisplay = row.cells[headerName].formattedValue;
            if (headerName === 'Modification Type' && row.step === 'modifications') {
              /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
              valueToDisplay = `${row.cells[headerName].value.charAt(0).toUpperCase()}${row.cells[
                headerName
              ].value.slice(1)}`;
            }

            const showErrorOnDuplicateName =
              headerName.toLowerCase() === keyHeaderNames[0].toLowerCase() &&
              duplicateNames.some(
                (pair) => pair.includes(valueToDisplay) && (pair[0] === row.creditName || pair[0] === 'undefined')
              );

            const showErrorOnDuplicateValueBetweenSubTab =
              headerName === 'Account Description' &&
              (row.step === 'temporary.balanceSheet' || row.step === 'temporary.incomeStatement') &&
              duplicateValuesBetweenSubTabs.includes(valueToDisplay.toLowerCase());

            return (
              <div>
                {headerName === 'State' && row.step === 'modifications' && row.cells[headerName].value.length > 1 ? (
                  <Box
                    className={classNames(classes.tableCell, {
                      [classes.darkCell]: isExistingRowAndSameValue && !isIgnoredCell,
                      [classes.clearCell]: (isNewRow || isExistingRowAndDifferentValue) && !isIgnoredCell,
                      [classes.whiteBackground]: headerName.toLowerCase() === keyHeaderNames[0].toLowerCase()
                    })}
                  >
                    <Select value={row.cells[headerName].value[0]} label="State">
                      {row.cells[headerName].value.map((stateArray: any) => {
                        return (
                          <MenuItem key={stateArray} value={stateArray}>
                            {stateArray}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <MenuItem>({row.cells[headerName].value.length})</MenuItem>
                  </Box>
                ) : (
                  <Box
                    className={classNames(classes.tableCell, {
                      [classes.darkCell]: isExistingRowAndSameValue && !isIgnoredCell,
                      [classes.clearCell]: (isNewRow || isExistingRowAndDifferentValue) && !isIgnoredCell,
                      [classes.whiteBackground]: headerName.toLowerCase() === keyHeaderNames[0].toLowerCase(),
                      [classes.errorBorder]: showErrorOnDuplicateName || showErrorOnDuplicateValueBetweenSubTab
                    })}
                  >
                    {valueToDisplay}
                  </Box>
                )}
              </div>
            );
          }
        }
      ] as any,
    [] as string[]
  );

  const handleChange = (index: number) => {
    const copy = [...checkmarkIndices];
    copy[index] = !checkmarkIndices[index];
    setCheckmarkIndices(copy);
  };

  const checkMarkColumn = {
    renderHeader: () => t('Import'),
    field: 'Import',
    renderCell: (row: ComposedRow) => {
      return (
        <Checkbox
          checked={checkmarkIndices[row.index] && row.isValid}
          checkedIcon={
            row.isNew ? (
              <CheckBoxRounded className={classes.checkbox} />
            ) : (
              <CheckBoxOutlined className={classes.checkbox} />
            )
          }
          icon={<CheckBoxOutlineBlank className={classes.checkbox} />}
          onChange={() => {
            handleChange(row.index);
          }}
        />
      );
    }
  };

  if (columns.length > 0) {
    columns.push(checkMarkColumn);
  }

  const handleImportFinished = () => {
    void fetchDataForSpecificStep(level, step);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.controls}>
        {createPortal(
          <ImportButton
            checkmarkIndices={checkmarkIndices}
            level={level}
            rows={composedRows}
            isTabCompleted={isTabCompleted}
            step={flaggedApportionmentStep}
            hasErrorState={Boolean(selectionErrorMessage || duplicateValuesError)}
            onImportFinished={handleImportFinished}
          />,
          document.querySelector('#button-box-for-import-button')!
        )}
      </Box>
      {selectionErrorMessage && <ErrorMessageBanner message={selectionErrorMessage} />}
      {duplicateValuesError && <ErrorMessageBanner message={duplicateValuesError} />}
      <Table columns={columns} rows={composedRows} className={classes.table} />
    </Box>
  );
};

export default ValidateScreen;
