import { ActionCreatorWithPayload, createAction, Dispatch } from '@reduxjs/toolkit';

import { UploadReviewStateInterface } from './uploadReview.initialState';

import { RootState } from '..';
import { UPLOAD_REVIEW_ID } from '../../constants';
import {
  UploadReviewTrialBalance,
  UploadReviewTrialBalanceMetaData,
  UploadReviewTrialBalancePayload,
  SubJurisdictionsByEntityId,
  EntityFromApi,
  EntityFromUpload,
  TrialBalanceProperty
} from '../../models';
import HTTPService, { LambdaResponse } from '../../services/http';
import { turnToObjByKey } from '../../utils';

const mapEntityCurrenciesForPayload = (
  uploadReviewState: UploadReviewStateInterface,
  assignedAttributeByRowId: Record<string, any>
) => {
  return {
    entities: uploadReviewState.entities.map((entity) => {
      return {
        ...entity,
        [UPLOAD_REVIEW_ID[uploadReviewState.tab]]:
          assignedAttributeByRowId[entity.id]?.[UPLOAD_REVIEW_ID[uploadReviewState.tab]]
      };
    })
  };
};

const mapEntityJurisdictionsForPayload = (
  uploadReviewState: UploadReviewStateInterface,
  assignedAttributeByRowId: Record<string, any>
) => {
  return {
    entities: uploadReviewState.entities.map((entity) => {
      const currentJur = entity[UPLOAD_REVIEW_ID[uploadReviewState.tab]];
      const incomingJur = assignedAttributeByRowId[entity.id]?.[UPLOAD_REVIEW_ID[uploadReviewState.tab]];
      const hasChanged = currentJur !== incomingJur;

      return {
        ...entity,
        [UPLOAD_REVIEW_ID[uploadReviewState.tab]]:
          assignedAttributeByRowId[entity.id]?.[UPLOAD_REVIEW_ID[uploadReviewState.tab]],
        subJurisdictionIds: hasChanged ? [] : entity.subJurisdictionIds
      };
    })
  };
};

const mapSubJurisdictionIdsForPayload = (
  uploadReviewState: UploadReviewStateInterface,
  assignedAttributeByRowId: SubJurisdictionsByEntityId
) => {
  return {
    entities: uploadReviewState.entities.map((entity) => ({
      ...entity,
      [UPLOAD_REVIEW_ID[uploadReviewState.tab]]:
        assignedAttributeByRowId[entity.id]?.map((subJurisdiction) => subJurisdiction.subJurisdictionId) ?? []
    }))
  };
};

const payloadStrategies: Record<
  string,
  (uploadReviewState: UploadReviewStateInterface, updatedData: any) => TrialBalanceProperty
> = {
  currencies: mapEntityCurrenciesForPayload,
  jurisdictions: mapEntityJurisdictionsForPayload,
  states: mapSubJurisdictionIdsForPayload,
  ranges: (_uploadReviewState, updatedData) => ({ ranges: updatedData }),
  'tax-sensitive': (_uploadReviewState, updatedData) => ({ categories: updatedData })
};

const buildTrialBalance = (
  uploadReviewState: UploadReviewStateInterface,
  updatedData: Record<string, any>
): UploadReviewTrialBalancePayload => {
  const trialBalancePayload: UploadReviewTrialBalancePayload = {
    trialBalance: payloadStrategies[uploadReviewState.tab](uploadReviewState, updatedData)
  };

  return trialBalancePayload;
};

export const uploadReviewMetaDataOnSendingRequest = createAction('uploadReview/metaData/OnSendingRequest');
export const uploadReviewMetaDataOnReceivedEmpty = createAction<{
  metaData: UploadReviewTrialBalanceMetaData;
}>('uploadReview/metaData/onReceivedEmpty');
export const uploadReviewMetaDataOnReceived = createAction<{
  metaData: UploadReviewTrialBalanceMetaData;
}>('uploadReview/metaData/onReceived');
export const uploadReviewMetaDataOnFailed = createAction<unknown>('uploadReview/metaData/onFailed');

export const uploadReviewOnSendingRequest = createAction('uploadReview/onSendingRequest');
export const uploadReviewOnReceived = createAction<{
  trialBalance: UploadReviewTrialBalance;
}>('uploadReview/onReceived');
export const uploadReviewOnFailed = createAction<unknown>('uploadReview/onFailed');

export const uploadReviewResetData = createAction('uploadReview/ResetData');

export const uploadReviewSetTab = createAction<UploadReviewStateInterface['tab']>('uploadReview/setTab');
export const uploadReviewFinishOnSuccess = createAction('uploadReview/onFinishSuccess');
export const uploadReviewEntityUpdate = createAction<TrialBalanceProperty>('uploadReview/onEntityUpdate');
export const uploadReviewRangesUpdate = createAction<TrialBalanceProperty>('uploadReview/onRangesUpdate');
export const uploadReviewTaxSensitiveUpdate = createAction<TrialBalanceProperty>(
  'uploadReview/onRangesTaxSensitiveUpdate'
);
export const uploadReviewOnUpdateSuccess = createAction('uploadReview/onUpdateSuccess');
export const uploadReviewOnUpdateFailed = createAction<unknown>('uploadReview/onUpdateFailed');
export const uploadReviewOnInitialUploadSendingRequest = createAction('uploadReview/onInitialUploadSendingRequest');
export const uploadReviewOnInitialUploadSuccess = createAction('uploadReview/onInitialUploadSuccess');
export const uploadReviewOnInitialUploadFailed = createAction<unknown>('uploadReview/onInitialUploadFailed');

const updateStrategies: Record<string, ActionCreatorWithPayload<TrialBalanceProperty>> = {
  currencies: uploadReviewEntityUpdate,
  jurisdictions: uploadReviewEntityUpdate,
  states: uploadReviewEntityUpdate,
  ranges: uploadReviewRangesUpdate,
  'tax-sensitive': uploadReviewTaxSensitiveUpdate
};

export const uploadData = (data: Record<string, any>) => {
  return async (dispatch: Dispatch) => {
    dispatch(uploadReviewOnInitialUploadSendingRequest());

    try {
      await HTTPService.request<LambdaResponse<UploadReviewTrialBalance>>({
        method: 'post',
        relativePath: '/v1/upload',
        data
      });

      dispatch(uploadReviewOnInitialUploadSuccess());
    } catch (error: unknown) {
      dispatch(uploadReviewOnInitialUploadFailed(error));
    }
  };
};

export const updateTabData = (updatedData: Record<string, any>) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const uploadReviewState = getState().uploadReview;
    const data = buildTrialBalance(uploadReviewState, updatedData);
    dispatch(updateStrategies[uploadReviewState.tab](data.trialBalance));

    try {
      await HTTPService.request<LambdaResponse<UploadReviewTrialBalance>>({
        method: 'patch',
        relativePath: '/v1/upload',
        data
      });

      dispatch(uploadReviewOnUpdateSuccess());
    } catch (error: unknown) {
      dispatch(uploadReviewOnUpdateFailed(error));
    }
  };
};

export const finishUploadReview = () => {
  return async (dispatch: Dispatch) => {
    dispatch(uploadReviewOnSendingRequest());

    try {
      await HTTPService.request<LambdaResponse<{ uploadId: string }>>({
        method: 'post',
        relativePath: '/v1/finish-upload'
      });
      dispatch(uploadReviewFinishOnSuccess());
    } catch (error: unknown) {
      dispatch(uploadReviewOnFailed(error));
    }
  };
};

const getPrepopulatedEntities = async (entities: EntityFromUpload[]) => {
  const { data: entitiesFromDB } = await HTTPService.request<LambdaResponse<EntityFromApi[]>>({
    method: 'get',
    relativePath: `/v1/entities`
  });

  const entitiesByNumber = turnToObjByKey(entitiesFromDB, 'entityNumber');

  const prepopulatedEntities = entities.map((entity) => {
    const { id } = entity;
    return {
      ...entity,
      currencyId: entitiesByNumber[id]?.currencyId,
      jurisdictionId: entitiesByNumber[id]?.jurisdictionId,
      subJurisdictionIds: entitiesByNumber[id]?.subJurisdictionIds
    };
  });

  return prepopulatedEntities;
};

export const prepopulateUploadReview = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(uploadReviewOnSendingRequest());

    const { entities } = getState().uploadReview;

    try {
      const prepopulatedEntities = await getPrepopulatedEntities(entities);

      const trialBalanceUpdate: UploadReviewTrialBalancePayload = { trialBalance: { entities: prepopulatedEntities } };

      dispatch(uploadReviewEntityUpdate(trialBalanceUpdate.trialBalance));

      await HTTPService.request<LambdaResponse<UploadReviewTrialBalance>>({
        method: 'patch',
        relativePath: '/v1/upload',
        data: trialBalanceUpdate
      });

      dispatch(uploadReviewOnUpdateSuccess());
    } catch (error: unknown) {
      dispatch(uploadReviewOnFailed(error));
    }
  };
};
