export function getTotalProvision({ federal, state }: any = {}) {
  const provision = { current: 0, deferred: 0 };
  // FIXME : Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  provision.current += (federal?.current || 0) + (state?.current || 0);
  // FIXME : Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  provision.deferred += (federal?.deferred || 0) + (state?.deferred || 0);
  return provision.current + provision.deferred;
}

export function getReportInfoByEntityId(report: any, entityId: string) {
  return {
    effectiveTaxRateRaw: report?.effectiveTaxRate?.entities?.find((reportEntity: any) => reportEntity.id === entityId),
    totalProvision: getTotalProvision(
      report?.totalProvision?.find((reportEntity: any) => reportEntity.id === entityId)
    ),
    deferredRaw: report?.deferredTaxAsset?.find((reportEntity: any) => reportEntity.id === entityId)
  };
}

export function formatDisplayName(attributeName: string, name: string) {
  return attributeName ? `${attributeName} - ${name}` : name;
}

export function translateReportTypeToUI(reportType: string) {
  switch (reportType) {
    case 'single-entity':
      return 'entity';
    case 'multi-entity':
      return 'group';
    default:
      return reportType;
  }
}
