import { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { CategoryCreated, CategoryForCreate } from '../../models';
import { categoriesOnFailed, categoriesOnSendingRequest } from '../../redux/categories';
import HTTPService, { LambdaResponse } from '../../services/http';
import { useCategories } from '../useCategories';

export const useCategoryCreate = () => {
  const dispatch = useDispatch();
  const { fetchData } = useCategories();

  const createCategory = useCallback(
    async (category: CategoryForCreate) => {
      dispatch(categoriesOnSendingRequest());

      try {
        const { data } = await HTTPService.request<LambdaResponse<CategoryCreated>>({
          method: 'post',
          relativePath: `/v1/categories`,
          data: {
            ...category
          }
        });

        if (data.category && !data.category.accounts) {
          data.category.accounts = [];
        }

        await fetchData();
      } catch (error: unknown) {
        dispatch(categoriesOnFailed(error as any));
      }
    },
    [dispatch, fetchData]
  );

  return { createCategory };
};
