import { useMemo, useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { SlideOut } from '@xbs/xbs-common-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  STATE_LEVEL,
  STATE_MODIFICATIONS,
  MODIFICATIONS_STEP,
  MODIFICATIONS_TEMPORARY,
  MODIFICATIONS_PERMANENT,
  STATE_MODIFICATIONS_TEMPORARY,
  STATE_MODIFICATIONS_PERMANENT
} from './constants';
import { getFinancialInfo, getSubJurisdictionsSelection } from './helpers';
import useStateModificationsColumns from './hooks/useStateModificationsColumns';
import { StateModificationsRow } from './StateModifications.types';

import { ContentAndTitle, TabTitle } from '..';
import { TableWithComment } from '../../..';
import { FEDERAL_UUID } from '../../../../constants';
import { useCompletionStatus, useCurrencies, useFinancialData } from '../../../../hooks';
import { StateTabProps, Step, SubJurisdiction } from '../../../../models';
import { setEntityCompletionStatus } from '../../../../redux/entitiesCompletionStatus';
import LoadingWrapper from '../../../LoadingWrapper';
import {
  EntityNumberRouteMatch,
  handleEditRowForEntityDetails,
  handleNewRowForEntityDetails,
  handleOnCellOrCommentBlurForEntityDetails,
  handleOnRowDeleteForEntityDetails,
  getStepBasedTableDataFromFinancialData,
  getRowNamesFromLevelSteps,
  doesRowNameAlreadyExistInStep
} from '../../utils';
import ApplicableStatesSelector from '../ApplicableStatesSelector';

const StateModifications = ({ states, entityId }: StateTabProps) => {
  const { t } = useTranslation();
  const { currencyByEntityIdMap } = useCurrencies();
  const currencyIsoCode = currencyByEntityIdMap[entityId]?.isoCode;
  const [selected, setSelected] = useState<StateModificationsRow | null>(null);
  const [rows, setRows] = useState<StateModificationsRow[]>([]);
  const [hasNewRow, setHasNewRow] = useState(false);
  const {
    prov3322EditPerformanceFix: isPerformanceFixEnabled,
    prov3736ReservedWordValidation: showReservedWordsError
  } = useFlags();
  const dispatch = useDispatch();
  const {
    params: { entityNumber }
  } = useRouteMatch<EntityNumberRouteMatch>();
  const stateSteps: Step[] = [MODIFICATIONS_STEP, 'deferred', 'rtp'];

  const { stepCompletionStatus } = useCompletionStatus(entityNumber, STATE_MODIFICATIONS, FEDERAL_UUID);
  const { tabsData, failedCells, isFetchLoading } = useFinancialData(entityNumber, STATE_LEVEL, stateSteps);
  const [financialInfo, financialInfoPerm, financialInfoTemp] = useMemo(() => {
    const financialInfo =
      getFinancialInfo(tabsData, [
        STATE_MODIFICATIONS_TEMPORARY,
        STATE_MODIFICATIONS_PERMANENT,
        'state.deferred',
        'state.rtp'
      ]) ?? [];
    const financialInfoPerm = financialInfo.filter((cell) => cell.step === MODIFICATIONS_PERMANENT);
    const financialInfoTemp = financialInfo.filter((cell) => cell.step === MODIFICATIONS_TEMPORARY);
    return [financialInfo, financialInfoPerm, financialInfoTemp];
  }, [tabsData]);

  const dataForMethods = {
    dispatch,
    entityId,
    financialInfo,
    hasNewRow,
    level: STATE_LEVEL,
    rows,
    setHasNewRow,
    setRows,
    subjurisdictions: states,
    step: MODIFICATIONS_STEP,
    t,
    showReservedWordsError
  };

  const handleCellChange = (params: any) => {
    handleEditRowForEntityDetails({
      ...dataForMethods,
      ...params,
      step: params.row.step
    });
  };

  const columns = useStateModificationsColumns({
    hasNewRow,
    setSelected,
    entityNumber,
    rows,
    handleCellChange
  });

  const rowNames = useMemo(
    () => getRowNamesFromLevelSteps(tabsData, STATE_LEVEL, [MODIFICATIONS_TEMPORARY, MODIFICATIONS_PERMANENT]),
    [tabsData]
  );
  const rowsWithData = useMemo(
    () => [
      ...getStepBasedTableDataFromFinancialData(rowNames[MODIFICATIONS_PERMANENT], financialInfoPerm),
      ...getStepBasedTableDataFromFinancialData(rowNames[MODIFICATIONS_TEMPORARY], financialInfoTemp)
    ],
    [financialInfoPerm, financialInfoTemp, rowNames]
  );
  const newRow = rows[rows.length - 1];
  const rowsToSet = useMemo(() => [...rowsWithData, ...(newRow?.isNew ? [newRow] : [])], [newRow, rowsWithData]);

  useEffect(() => {
    setRows(rowsToSet);
  }, [rowsToSet]);

  const newRowButtonLabel = stepCompletionStatus.status
    ? null
    : t(hasNewRow ? 'Save Modification' : 'Add Modification');

  const handleCompletionChange = (checked: boolean) => {
    dispatch(
      setEntityCompletionStatus({
        ...stepCompletionStatus,
        newStatus: checked
      })
    );
  };

  const handleStatesSelectorChange = (subJurisdictions: SubJurisdiction[]) => {
    const column = { field: 'state' };
    const selectedIndex = rows.findIndex((row) => row.name === selected?.name && row.step === selected?.step);
    const saveHandler = selected?.isNew ? handleEditRowForEntityDetails : handleOnCellOrCommentBlurForEntityDetails;
    const prevSubJurisdictionsSelection = selected?.state ?? [];
    const incomingSubJurisdictionIds = subJurisdictions.map(({ subJurisdictionId }) => subJurisdictionId);
    const subJurisdictionsSelection = getSubJurisdictionsSelection(
      prevSubJurisdictionsSelection,
      incomingSubJurisdictionIds
    );
    const newRow = { ...selected, state: subJurisdictionsSelection };

    saveHandler({
      ...dataForMethods,
      column,
      value: subJurisdictionsSelection,
      row: rows[selectedIndex],
      step: selected?.step!
    });

    setSelected(newRow);
  };

  useEffect(() => {
    if (newRow?.isNew) {
      if (newRow.name && newRow.step && doesRowNameAlreadyExistInStep(newRow.name, newRow.step, financialInfo)) {
        newRow.duplicateName = true;
      } else {
        newRow.duplicateName = false;
      }
    }
  }, [financialInfo, newRow]);

  return (
    <LoadingWrapper isLoading={isFetchLoading}>
      <>
        <ContentAndTitle
          title={
            <TabTitle
              currencyIsoCode={currencyIsoCode}
              title={t('Modifications')}
              isCompleted={stepCompletionStatus.status}
              onCompletionChange={handleCompletionChange}
            />
          }
        >
          <TableWithComment
            columns={columns}
            failedCells={failedCells}
            newRowButtonLabel={newRowButtonLabel}
            rows={rows}
            totalHeaderName={t('Total')}
            hideActionsMenu={stepCompletionStatus.status}
            handleOnRowDelete={(params) => {
              handleOnRowDeleteForEntityDetails({
                ...dataForMethods,
                ...params,
                step: params.row.step
              });
              setSelected(null);
            }}
            onCellChange={isPerformanceFixEnabled ? undefined : handleCellChange}
            onCellOrCommentBlur={(params) => {
              if (isPerformanceFixEnabled) {
                handleCellChange(params);
              }

              handleOnCellOrCommentBlurForEntityDetails({
                ...dataForMethods,
                ...params,
                step: params.row.step
              });
              setSelected(null);
            }}
            onNewRowClick={(row) => {
              handleNewRowForEntityDetails({
                columns,
                ...dataForMethods,
                step: row?.step
              });
              setSelected(null);
            }}
          />
        </ContentAndTitle>
        <SlideOut.Drawer
          isFloating={false}
          isDividerHidden={false}
          title={t('Applicable States')}
          subtitle={selected?.name ?? ''}
          titleHeight={76}
          isOpen={Boolean(selected) && !selected?.duplicateName}
          onClose={() => {
            setSelected(null);
          }}
        >
          <ApplicableStatesSelector
            states={states}
            appliedSubJurisdictionIds={selected?.state}
            onChange={handleStatesSelectorChange}
          />
        </SlideOut.Drawer>
      </>
    </LoadingWrapper>
  );
};

export default StateModifications;
