import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Box, makeStyles, TextField } from '@material-ui/core';

import { Row } from '../../Table/Table.proptype';
import { TableWithCommentProps } from '../TableWithComment.proptype';

const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: 'white',
    padding: theme.spacing(1)
  },
  inputContainer: {
    margin: theme.spacing(2)
  }
}));

type Props = {
  isDisabled?: boolean;
  row: Row;
  rowComments: Row['comments'];
  hasNewRow: boolean;
  onCellOrCommentBlur?: TableWithCommentProps['onCellOrCommentBlur'];
};

const CommentsPanel = ({ isDisabled = false, row, rowComments, hasNewRow, onCellOrCommentBlur }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [comments, setComments] = useState(rowComments);

  useEffect(() => {
    setComments(rowComments);
  }, [rowComments]);

  return (
    <Box className={classes.inputContainer}>
      <TextField
        multiline
        fullWidth
        autoComplete="off"
        value={comments ?? ''}
        disabled={hasNewRow || isDisabled}
        InputProps={{
          disableUnderline: true,
          className: classes.input,
          placeholder: t('Leave note here...'),
          rows: 10
        }}
        data-testid="comments-panel-text-field"
        onChange={({ target: { value } }) => {
          setComments(value);
        }}
        onBlur={(event) => onCellOrCommentBlur?.({ value: event.target.value, row, column: { field: 'note' } })}
      />
    </Box>
  );
};

export default CommentsPanel;
