import isRemoveRowAttributeName from './isRemoveRowAttributeName';
import isTotalsSummaryCollection from './isTotalsSummaryCollection';

import { Report, ReportFragment } from '../../../../models';

const getReportTotalsSummary = (obj: Partial<Report>) => {
  const totalsCollection = obj.values?.find((value: ReportFragment) => isTotalsSummaryCollection(value));
  const totalsCollectionValuesFiltered = totalsCollection?.values?.filter(
    (value: ReportFragment) => !isRemoveRowAttributeName(value)
  );
  return totalsCollectionValuesFiltered;
};

export default getReportTotalsSummary;
