import { makeStyles } from '@material-ui/core';
import Close from '@material-ui/icons/Close';

import SelectedItemChip from '../../../../SelectedItemChip';

const useStyles = makeStyles(() => ({
  mainText: {
    fontSize: '14px',
    letterSpacing: '0.25px'
  },
  closeButton: {
    marginLeft: '15px',
    marginRight: '5px',
    fontSize: '19px'
  }
}));

type DeletableSelectedItemChipProps = {
  text: string;
  onClear: () => void;
};

const DeletableSelectedItemChip = ({ text, onClear }: DeletableSelectedItemChipProps) => {
  const classes = useStyles();

  return (
    <SelectedItemChip
      className={classes}
      text={text}
      icon={<Close className={classes.closeButton} onClick={onClear} />}
    />
  );
};

export default DeletableSelectedItemChip;
