import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Box, Divider, makeStyles } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useContainers } from '../../../../hooks';
import { Container, ContainerOperation, PanelProps } from '../../../../models';
import ContainersAccordion from '../../../ContainersAccordion';
import LoadingWrapper from '../../../LoadingWrapper';
import { SET_ROLLED_FROM_CONTAINER } from '../../localReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    margin: 'auto',
    width: '80%'
  },
  fullRowContent: {
    color: theme.palette.text.primary,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    width: '100%'
  },
  divider: {
    margin: theme.spacing(0.5, 0),
    backgroundColor: theme.palette.primary.dark
  },
  accordionContainer: {
    backgroundColor: theme.palette.primary.light,
    height: theme.spacing(20),
    overflowY: 'scroll'
  },
  accordion: {
    boxShadow: 'none',
    color: theme.palette.text.dark,
    margin: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingDown: theme.spacing(0.5),
    paddingUp: theme.spacing(0.5),
    '&::before': {
      opacity: 0
    },
    '& .MuiAccordionSummary-root': {
      padding: 0
    }
  },
  accordionDetails: {
    borderRadius: theme.spacing(0.5),
    marginBottom: theme.spacing(0.2),
    marginTop: theme.spacing(0.2),
    width: '100%'
  },
  notSelectedMenuItem: {},
  selectedMenuItem: {
    border: `1px solid ${theme.palette.info.dark}`,
    backgroundColor: theme.palette.info.main,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold
  },
  accordionButton: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    fontSize: '12px',
    justifyContent: 'left',
    height: '24px',
    lineHeight: '24px',
    padding: 0,
    paddingLeft: theme.spacing(0.5),
    width: '76px'
  }
}));

const ChooseRolledFromContainer = ({
  localDispatch,
  localState: { containers, rolledFromContainer, containerOperation },
  setIsRightActionEnabled
}: PanelProps) => {
  const { t } = useTranslation();
  const { currentContainer } = useContainers();
  const classes: Record<string, string> = useStyles();
  const { root, ...classesForAccordion } = classes;
  const [selectedContainer, setSelectedContainer] = useState<Container | undefined>();
  const { prov3932ImproveAddNewPeriodModal } = useFlags();
  useEffect(() => {
    setIsRightActionEnabled(Boolean(rolledFromContainer));
  }, [rolledFromContainer, setIsRightActionEnabled]);

  const handleContainerSelected = (name: string, taxYear: string) => {
    const selectedContainer = containers.find(
      (container) => container.containerName === name && container.taxYear.toString() === taxYear
    );
    setSelectedContainer(selectedContainer);
    localDispatch({
      type: SET_ROLLED_FROM_CONTAINER,
      payload: selectedContainer!
    });
    setIsRightActionEnabled(true);
  };

  const titleMap: Record<ContainerOperation, string> = {
    'rollforward-interim': t('choose-period-to-rollforward'),
    'rollforward-yearly': t('choose-period-to-rollforward'),
    copy: t('choose-period-to-copy')
  };

  useEffect(() => {
    if (!selectedContainer && prov3932ImproveAddNewPeriodModal) {
      handleContainerSelected(String(currentContainer?.containerName), String(currentContainer?.taxYear));
    }
    // I only want to run this once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContainer, currentContainer]);

  return (
    <LoadingWrapper isLoading={!selectedContainer && prov3932ImproveAddNewPeriodModal}>
      <Box className={root}>
        <Box>
          <Box className={classes.fullRowContent}>{titleMap[containerOperation]}</Box>
          <Divider className={classes.divider} />
          <Box className={classes.accordionContainer}>
            <ContainersAccordion
              classesOverrides={classesForAccordion}
              containers={containers}
              selectedContainer={rolledFromContainer}
              onContainerSelected={handleContainerSelected}
            />
          </Box>
        </Box>
      </Box>
    </LoadingWrapper>
  );
};

export default ChooseRolledFromContainer;
