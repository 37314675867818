import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '80%',
    overflow: 'hidden',
    padding: theme.spacing(2.5)
  },
  title: {
    fontSize: '1.1rem',
    marginBottom: theme.spacing(1)
  }
}));

interface Props {
  title?: string | JSX.Element;
  children: JSX.Element;
}

const ContentAndTitle = ({ title, children }: Props) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography variant="h2" component="h3" className={classes.title}>
        {title}
      </Typography>
      {children}
    </Box>
  );
};

export default ContentAndTitle;
