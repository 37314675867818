import Box from '@material-ui/core/Box';

import { Currency, EntityWithRates, Jurisdiction } from '../../../../models';
import ActionSearchBox, { SelectorOption } from '../../../ActionSearchBox';
import { ConfirmationDialogWithButtons } from '../../../ConfirmationDialog';
import MoreActionsButton from '../../../MoreActionsButton';
import { EditableCell } from '../../../Table/components/TableBody';
import { Column, FailedCells } from '../../../Table/Table.proptype';
import ChangeParentEntityWithConfirmation from '../ChangeParentEntityWithConfirmation/ChangeParentEntityWithConfirmation';

export const allowedSpecialCharacters = '_\\-,;!?\\.\\\'"\\(\\)\\[\\]\\{\\}@\\*/\\\\&%#`\\^\\+<=>\\|~\\$:\\s';

export type ColumnWithField = Column & { field: string };

export const isValidEntityName = (newName: string, currentName: string, entities: EntityWithRates[]) => {
  if (newName.length === 0 || newName.length > 255) {
    return false;
  }

  for (const entity of entities) {
    const newNameMatchesExistingName = newName === entity.name && newName !== currentName;
    if (newNameMatchesExistingName) {
      return false;
    }
  }

  const entityNameRegex = new RegExp(`^[a-zA-Z0-9${allowedSpecialCharacters}]*$`);
  return entityNameRegex.test(newName);
};

export const isValidEntityNumber = (newNumber: string, currentNumber: string, entities: EntityWithRates[]) => {
  if (newNumber.length === 0 || newNumber.length > 256) {
    return false;
  }

  for (const entity of entities) {
    const newNumberMatchesExistingNumber = newNumber === entity.entityNumber && newNumber !== currentNumber;
    if (newNumberMatchesExistingNumber) {
      return false;
    }
  }

  const convertedNumber = Number(newNumber);
  const isInteger = Number.isInteger(convertedNumber);
  const isNonNegative = convertedNumber > -1;
  return isInteger && isNonNegative;
};

interface GetColumnsProps {
  t: (key: string) => string;
  classes: Record<string, string>;
  isValidEntityNameInput: Record<string, boolean>;
  isValidEntityNumberInput: Record<string, boolean>;
  failedCells: FailedCells;
  handleEntityNameChange: (entityId: string, existingValue: string, newValue: string) => void;
  handleEntityNameBlur: (entityId: string, existingValue: string, newValue: string) => void;
  handleEntityNumberChange: (entityId: string, existingValue: string, newValue: string) => void;
  handleEntityNumberBlur: (entityId: string, existingValue: string, newValue: string) => void;
  handleEntityCurrencyUpdate: (entityId: string, existingValue: number, newValue: number) => void;
  handleEntityJurisdictionUpdate: (entityId: string, existingValue: string, newValue: string) => void;
  onParentEntityChange: (entityId: EntityWithRates['id']) => void;
  setIsEditingExpandedEntity: React.Dispatch<React.SetStateAction<boolean>>;
  setExpandedEntity: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  currencyById: Record<string, Currency>;
  jurisdictionsById: Record<string, Jurisdiction>;
  handleDeleteEntity: (entityId: string) => void;
  isContainerFinalized: boolean;
  entityJurisdictionCurrencyRestriction: boolean;
}

export const getColumns = ({
  t,
  classes,
  isValidEntityNameInput,
  isValidEntityNumberInput,
  failedCells,
  handleEntityNameChange,
  handleEntityNameBlur,
  handleEntityNumberChange,
  handleEntityNumberBlur,
  handleEntityCurrencyUpdate,
  handleEntityJurisdictionUpdate,
  onParentEntityChange,
  setIsEditingExpandedEntity,
  setExpandedEntity,
  currencyById,
  jurisdictionsById,
  handleDeleteEntity,
  isContainerFinalized,
  entityJurisdictionCurrencyRestriction
}: GetColumnsProps): Column[] => {
  const getCurrencyValue = (row: EntityWithRates) => {
    const currency = currencyById[row.currencyId];
    const symbol = currency?.symbol ?? '';
    const isoCode = currency?.isoCode ?? '';
    return `${symbol}${isoCode}`;
  };

  const getJurisdictionValue = (row: EntityWithRates) => {
    const jurisdiction = jurisdictionsById[row.jurisdictionId];
    return jurisdiction?.isoCode ?? '';
  };

  const columns: ColumnWithField[] = [
    {
      field: 'isParent',
      headerName: t('Parent Entity'),
      width: '10%',
      renderCell: (row: EntityWithRates) => (
        <ChangeParentEntityWithConfirmation
          isContainerFinalized={isContainerFinalized}
          row={row}
          onParentEntityChange={onParentEntityChange}
        />
      )
    },
    {
      field: 'id',
      filterable: true,
      sortable: true,
      width: '15%',
      placeholder: t('Entity ID'),
      renderCell: (row: EntityWithRates) => {
        const isValid =
          isValidEntityNumberInput[row.entityId] === undefined ? true : isValidEntityNumberInput[row.entityId];
        return isContainerFinalized ? (
          row.entityNumber
        ) : (
          <EditableCell
            isEntityReviewCell
            editableCellClassName={isValid ? classes.idCell : `${classes.idCell} ${classes.inputError}`}
            column={{ field: 'id' }}
            row={row}
            failedCells={failedCells}
            isValidEntityInput={isValidEntityNumberInput[row.entityId]}
            onChange={({ value }) => {
              handleEntityNumberChange(row.entityId, row.entityNumber, value as string);
            }}
            onCellOrCommentBlur={({ value }) => {
              handleEntityNumberBlur(row.entityId, row.entityNumber, value as string);
            }}
          />
        );
      }
    },
    {
      field: 'name',
      filterable: true,
      sortable: true,
      width: '25%',
      placeholder: t('Entity Name'),
      renderCell: (row: EntityWithRates) => {
        const isValid =
          isValidEntityNameInput[row.entityId] === undefined ? true : isValidEntityNameInput[row.entityId];
        return isContainerFinalized ? (
          row.name
        ) : (
          <EditableCell
            isEntityReviewCell
            editableCellClassName={isValid ? classes.nameCell : `${classes.nameCell} ${classes.inputError}`}
            column={{ field: 'name' }}
            row={row}
            failedCells={failedCells}
            isValidEntityInput={isValidEntityNameInput[row.entityId]}
            onChange={({ value }) => {
              handleEntityNameChange(row.entityId, row.name, value as string);
            }}
            onCellOrCommentBlur={({ value }) => {
              handleEntityNameBlur(row.entityId, row.name, value as string);
            }}
          />
        );
      }
    },
    {
      field: 'jurisdiction',
      filterable: true,
      sortable: true,
      width: '15%',
      placeholder: t('Tax Jurisdictions'),
      getValue: getJurisdictionValue,
      renderCell: (row: EntityWithRates) => {
        const jurisdictionsByIdFiltered =
          currencyById[row.currencyId]?.isoCode === 'USD' || !entityJurisdictionCurrencyRestriction
            ? jurisdictionsById
            : Object.fromEntries(
                Object.entries(jurisdictionsById).filter(
                  ([_jurisdictionId, jurisdiction]) => jurisdiction.isoCode !== 'USA'
                )
              );
        return isContainerFinalized ? (
          getJurisdictionValue(row)
        ) : (
          <ActionSearchBox
            row={row}
            optionsById={jurisdictionsByIdFiltered}
            nameKey="isoCode"
            valueKey="jurisdictionId"
            handleSave={handleEntityJurisdictionUpdate}
          />
        );
      }
    },
    {
      field: 'currency',
      headerName: t('Currency'),
      getValue: getCurrencyValue,
      renderCell: (row: EntityWithRates, _value: number | string) => {
        const currencyByIdFiltered =
          jurisdictionsById[row.jurisdictionId]?.isoCode === 'USA' && entityJurisdictionCurrencyRestriction
            ? Object.fromEntries(
                Object.entries(currencyById).filter(([_currencyId, currency]) => currency.isoCode === 'USD')
              )
            : currencyById;
        return isContainerFinalized ? (
          getCurrencyValue(row)
        ) : (
          <ActionSearchBox
            row={row}
            optionsById={currencyByIdFiltered}
            nameKey="isoCode"
            valueKey="currencyId"
            getDisplayLabel={(option: SelectorOption): string =>
              `${currencyById[option.id]?.symbol}${String(option.label)}`
            }
            handleSave={handleEntityCurrencyUpdate}
          />
        );
      }
    },
    {
      field: 'subJurisdictionIds',
      headerName: t('States / Cities'),
      getValue: (row: EntityWithRates) => (row.subJurisdictionIds?.length ? row.subJurisdictionIds.length : '-'),
      renderCell: (row: EntityWithRates, value: number | string) => (
        <Box display="flex" width={1} alignItems="center">
          <Box flexGrow={1}>{value}</Box>
          <MoreActionsButton
            actions={[
              {
                label: 'Edit Entity',
                onClick: () => {
                  setIsEditingExpandedEntity(false);
                  setExpandedEntity(row.entityId);
                }
              },
              {
                label: ((
                  <ConfirmationDialogWithButtons
                    buttonClass={classes.confirmationButton}
                    dialogTitle=""
                    buttonName="Delete"
                    dialogBody="Are you sure you want to delete this entity?"
                    onSubmit={() => {
                      handleDeleteEntity(row.entityId);
                    }}
                  />
                ) as unknown) as string,
                disabled: row.isParent || isContainerFinalized
              }
            ]}
          />
        </Box>
      )
    }
  ];
  return columns;
};
