import { FailedCells } from '../../components/Table/Table.proptype';
import { FinancialInfoTabsData } from '../../models';

export interface FinancialDataStateInterface {
  entityNumber: string;
  error: unknown;
  failedCells: FailedCells;
  isFetchLoading: boolean;
  isUploadLoading: boolean;
  tabsData: FinancialInfoTabsData;
}

export const financialDataInitialState: FinancialDataStateInterface = {
  entityNumber: '',
  error: null,
  failedCells: {},
  isFetchLoading: false,
  isUploadLoading: false,
  tabsData: {}
};
