import { makeStyles } from '@material-ui/core';
import AutorenewIcon from '@material-ui/icons/Autorenew';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '382px',
    height: '45px',
    font: theme.typography.body2.fontFamily,
    fontSize: '12px',
    marginTop: '15px',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#6E56DB',
    color: theme.palette.primary.contrastText,
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  refreshButton: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    height: '30px',
    width: '92px',
    border: '1px solid',
    borderColor: theme.palette.divider,
    backgroundColor: '#6E56DB',
    borderRadius: '4px',
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.focus,
      opacity: '0.7'
    }
  },
  icon: {
    width: theme.spacing(1.3)
  }
}));

const RefreshReportAlertBanner = ({ refreshHandler }: { refreshHandler: () => void }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div style={{ display: 'inline-block', paddingRight: '15px' }}>NEW DATA UPDATE AVAILABLE</div>
      <div className={classes.refreshButton} onClick={refreshHandler}>
        <AutorenewIcon className={classes.icon} />
        <span>Refresh</span>
      </div>
    </div>
  );
};

export default RefreshReportAlertBanner;
