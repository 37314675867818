import { EntityCompletionStatus } from '../../models';

export interface EntitiesCompletionStatusStateInterface {
  entitiesCompletionStatus: Record<string, EntityCompletionStatus>;
  hasLoaded: boolean;
  isLoading: boolean;
  error: unknown;
}

export const entitiesCompletionStatusInitialState: EntitiesCompletionStatusStateInterface = {
  entitiesCompletionStatus: {},
  hasLoaded: false,
  isLoading: false,
  error: null
};
