import { createAction } from '@reduxjs/toolkit';

import { SetAuthTokensAction, AuthStateInterface, SET_AUTH_TOKENS } from './auth.types';

export const setRoleData = createAction<{ roleId: number; roleUuid: string }>('auth/setRoleData');

export function setAuthTokens({
  authToken,
  refreshToken,
  expiration,
  email,
  userId,
  isXBSEmployee
}: AuthStateInterface): SetAuthTokensAction {
  return {
    type: SET_AUTH_TOKENS,
    payload: {
      authToken,
      refreshToken,
      expiration,
      email,
      userId,
      isXBSEmployee
    }
  };
}
