import classnames from 'classnames';

import { Column } from '../../../../models';

const addTotalClassToTotalFields = (columns: Column[]) =>
  columns.map((column) => {
    if (column.field?.match(/total/i)) {
      return { ...column, className: classnames(column.className, 'totalColumn') };
    }

    return column;
  });

export default addTotalClassToTotalFields;
