import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import {
  completionStatusOnFailed,
  completionStatusOnPosted,
  completionStatusOnReceived,
  completionStatusOnSendingRequest
} from './entitiesCompletionStatus.actions';
import { entitiesCompletionStatusInitialState } from './entitiesCompletionStatus.initialState';

import { Level, Step } from '../../models';
import { containersOnCurrentSwitched } from '../containers';
import { uploadReviewFinishOnSuccess, uploadReviewOnFailed } from '../uploadReview';

export const entitiesCompletionStatusReducer = createReducer(entitiesCompletionStatusInitialState, (builder) => {
  builder
    .addCase(completionStatusOnSendingRequest, (state) => {
      return { ...state, isLoading: true, error: null };
    })
    .addCase(completionStatusOnReceived, (state, action) => ({
      ...state,
      entitiesCompletionStatus: action.payload.completionStatus,
      isLoading: false,
      hasLoaded: true,
      error: null
    }))
    .addCase(completionStatusOnPosted, (state, action) => {
      const { completionStatus, entityNumber } = action.payload;
      const { entityCompletionStatusRef, subJurisdictionId } = completionStatus;
      const levelStepArray = entityCompletionStatusRef.split('.');
      const level = levelStepArray[0] as Level;
      const step = levelStepArray[1] as Step;
      return {
        ...state,
        entitiesCompletionStatus: {
          ...state.entitiesCompletionStatus,
          [entityNumber]: {
            completionStatus: {
              ...state.entitiesCompletionStatus[entityNumber].completionStatus,
              [level]: {
                ...state.entitiesCompletionStatus[entityNumber].completionStatus[level],
                [step]: {
                  ...state.entitiesCompletionStatus[entityNumber].completionStatus[level][step],
                  [subJurisdictionId]: completionStatus.status
                }
              }
            }
          }
        },
        isLoading: false,
        error: null
      };
    })
    .addCase(completionStatusOnFailed, (state, action) => {
      return {
        ...state,
        isLoading: false,
        hasLoaded: true,
        error: action.payload
      };
    })
    .addMatcher(isAnyOf(uploadReviewFinishOnSuccess, uploadReviewOnFailed, containersOnCurrentSwitched), () => {
      return entitiesCompletionStatusInitialState;
    });
});
