import { makeStyles } from '@material-ui/core';

import { Column, DataImportSelection } from '../../../../models';
import SelectionButton from '../SelectionButton';

const useStyles = makeStyles(() => ({
  container: { display: 'flex' },
  rowSelectionInput: {
    width: '90%',
    marginLeft: '25px',
    marginRight: '25px'
  }
}));

interface SpreadsheetSelectionProps {
  dataImportSelection: DataImportSelection;
  stepColumns: Column[];
  onFieldSelected: (fieldName: string) => void;
}

const SpreadsheetSelection = ({ dataImportSelection, stepColumns, onFieldSelected }: SpreadsheetSelectionProps) => {
  const classes = useStyles();

  return stepColumns ? (
    <div>
      <div className={classes.container}>
        {stepColumns.map((column) => (
          <SelectionButton
            key={column.headerName}
            reduxValue={dataImportSelection?.[column.headerName!]?.range}
            handleEvent={() => {
              if (column.headerName !== undefined) {
                onFieldSelected(column.headerName);
              }
            }}
            buttonText={column.headerName === undefined ? '' : column.headerName}
            isActive={column.isActive}
          />
        ))}
      </div>
    </div>
  ) : null;
};

export default SpreadsheetSelection;
