import { Level, Step } from '../models';

interface HasAccountIdOrName {
  [key: string]: any;
  id?: string;
  name?: string;
}

export const findAdjustment = (row: HasAccountIdOrName, adjustments: HasAccountIdOrName[] = []): HasAccountIdOrName => {
  return (
    adjustments.find(({ accountId, name }) => (row.accountId ? row.accountId === accountId : row.name === name)) ?? {}
  );
};

export const foundAdjustment = (adjustment: Record<string, any>) => Object.keys(adjustment).length > 0;

interface HasIdOrName {
  [key: string]: any;
  id?: string;
  name?: string;
}

export const findByIdOrName = (object: HasIdOrName = {}, collection: HasIdOrName[] = []): Record<string, any> => {
  return collection.find(({ id, name }) => (object.id ? object.id === id : object.name === name)) ?? {};
};

export const removeStatePrefixFromAdjustmentName = (rowName: string) => {
  const splittedOnDash = rowName.split('-');
  if (splittedOnDash.length === 1) return rowName;
  const rowNameWithoutState = splittedOnDash?.slice(1)?.join('-')?.trim(); // State is on index 0
  return rowNameWithoutState;
};

export const getFullStepNameComponents = (fullStepName: string) => {
  const splitStepName = fullStepName.split('.');
  const level = splitStepName[0] as Level;
  const step = splitStepName[1] as Step;
  const subStep = splitStepName[2] as string | undefined;
  const stepWithSubStep = splitStepName.slice(1).join('.') as Step;

  return {
    level,
    step,
    subStep,
    stepWithSubStep
  };
};
