import { useEffect, RefObject } from 'react';

const useClickOutsideDialog = (dialogRef: RefObject<HTMLElement>, setIsDialogOpen: (value: boolean) => void) => {
  useEffect(() => {
    const handleClickOutsideDialog = (event: MouseEvent) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target as Node)) {
        setIsDialogOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutsideDialog);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideDialog);
    };
  }, [dialogRef, setIsDialogOpen]);
};

export default useClickOutsideDialog;
