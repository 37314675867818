import { RootState } from '../redux';

export const selectRollForwardModalIsOpen = (store: RootState) => store.ui.isRfModalOpen;
export const selectIsTemporaryBalanceSheetTabOpen = (store: RootState) => store.ui.isTemporaryBalanceSheetTabOpen;
export const selectSubJurisdictionSidemenuSelectedId = (store: RootState) => store.ui.subJurisdictionSidemenuSelectedId;
export const selectIsEntityDetailsGroupedViewToggled = (store: RootState) => store.ui.isEntityDetailsGroupedViewToggled;
export const selectShouldGroupCategoriesOnReports = (store: RootState) => store.ui.shouldGroupCategoriesOnReports;
export const selectToggledCategoriesOnReports = (store: RootState) => store.ui.toggledCategoriesOnReports;
export const selectSelectedDashboardCard = (store: RootState) => store.ui.dashboardSelectedCard;
export const selectCountriesData = (store: RootState) => store.ui.countriesData;
