import { SvgIcon } from '@material-ui/core';

const ReturnToProvisionIcon = (props: any) => (
  <SvgIcon viewBox="0 0 256 256" {...props}>
    <path d="M210.8,232.3H35V17.7h113l62.7,58.9V232.3z M38,229.3h169.8V77.9l-60.9-57.2H38V229.3z" />

    <g>
      <g>
        <g>
          <path
            d="M80.2,101.8c2.1-2.1,4.3-4.3,6.4-6.4c3.4-3.4,6.8-6.8,10.2-10.2c0.8-0.8,1.6-1.6,2.3-2.3c-0.5,0-0.9,0-1.4,0
              
              c2.1,2.1,4.3,4.3,6.4,6.4c3.4,3.4,6.8,6.8,10.2,10.2c0.8,0.8,1.6,1.6,2.3,2.3c0.9,0.9,2.2-0.5,1.4-1.4c-2.1-2.1-4.3-4.3-6.4-6.4
              
              c-3.4-3.4-6.8-6.8-10.2-10.2c-0.8-0.8-1.6-1.6-2.3-2.3c-0.4-0.4-1-0.4-1.4,0c-2.1,2.1-4.3,4.3-6.4,6.4
              
              c-3.4,3.4-6.8,6.8-10.2,10.2c-0.8,0.8-1.6,1.6-2.3,2.3C78,101.3,79.4,102.7,80.2,101.8L80.2,101.8z"
          />
        </g>
      </g>

      <g>
        <g>
          <path
            d="M97.5,82.2c0,0.6,0,1.2,0,1.9c0,1.7,0,3.4,0,5.1c0,2.5,0,5,0,7.5c0,3.1,0,6.2,0,9.3c0,3.4,0,6.7,0,10.1
              
              c0,3.4,0,6.8,0,10.2c0,3.2,0,6.3,0,9.5c0,2.7,0,5.4,0,8.1c0,1.9,0,3.8,0,5.8c0,0.9,0,1.8,0,2.7c0,0,0,0.1,0,0.1
              
              c0,1.2,1.9,1.2,1.9,0c0-0.6,0-1.2,0-1.9c0-1.7,0-3.4,0-5.1c0-2.5,0-5,0-7.5c0-3.1,0-6.2,0-9.3c0-3.4,0-6.7,0-10.1
              
              c0-3.4,0-6.8,0-10.2c0-3.2,0-6.3,0-9.5c0-2.7,0-5.4,0-8.1c0-1.9,0-3.8,0-5.8c0-0.9,0-1.8,0-2.7c0,0,0-0.1,0-0.1
              
              C99.5,80.9,97.5,80.9,97.5,82.2L97.5,82.2z"
          />
        </g>
      </g>

      <g>
        <g>
          <path
            d="M127.7,149.5c2.1,2.1,4.3,4.3,6.4,6.4c3.4,3.4,6.8,6.8,10.2,10.2c0.8,0.8,1.6,1.6,2.3,2.3
              
              c0.4,0.4,1,0.4,1.4,0c2.1-2.1,4.3-4.3,6.4-6.4c3.4-3.4,6.8-6.8,10.2-10.2c0.8-0.8,1.6-1.6,2.3-2.3c0.9-0.9-0.5-2.2-1.4-1.4
              
              c-2.1,2.1-4.3,4.3-6.4,6.4c-3.4,3.4-6.8,6.8-10.2,10.2c-0.8,0.8-1.6,1.6-2.3,2.3c0.5,0,0.9,0,1.4,0c-2.1-2.1-4.3-4.3-6.4-6.4
              
              c-3.4-3.4-6.8-6.8-10.2-10.2c-0.8-0.8-1.6-1.6-2.3-2.3C128.2,147.3,126.8,148.7,127.7,149.5L127.7,149.5z"
          />
        </g>
      </g>

      <g>
        <g>
          <path
            d="M148.3,167.8c0-0.6,0-1.2,0-1.9c0-1.7,0-3.4,0-5.1c0-2.5,0-5,0-7.5c0-3.1,0-6.2,0-9.3c0-3.4,0-6.7,0-10.1
              
              c0-3.4,0-6.8,0-10.2c0-3.2,0-6.3,0-9.5c0-2.7,0-5.4,0-8.1c0-1.9,0-3.8,0-5.8c0-0.9,0-1.8,0-2.7c0,0,0-0.1,0-0.1
              
              c0-1.2-1.9-1.2-1.9,0c0,0.6,0,1.2,0,1.9c0,1.7,0,3.4,0,5.1c0,2.5,0,5,0,7.5c0,3.1,0,6.2,0,9.3c0,3.4,0,6.7,0,10.1
              
              c0,3.4,0,6.8,0,10.2c0,3.2,0,6.3,0,9.5c0,2.7,0,5.4,0,8.1c0,1.9,0,3.8,0,5.8c0,0.9,0,1.8,0,2.7c0,0,0,0.1,0,0.1
              
              C146.3,169,148.3,169,148.3,167.8L148.3,167.8z"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default ReturnToProvisionIcon;
