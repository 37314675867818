import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import makeStyles from '@material-ui/core/styles/makeStyles';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import classnames from 'classnames';

import { SubJurisdiction } from '../../../../models';
import { disableElementForRoles } from '../../../UserRoleStylesProvider/constants';

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    justifyContent: 'space between'
  },
  cell: {
    padding: theme.spacing(1.2, 1.2, 1.2, 0)
  },
  containerError: {
    position: 'relative',
    border: `2px solid ${theme.palette.error.main}`
  },

  input: {
    '& .MuiInput-input': {
      textAlign: 'inherit',
      fontSize: theme.typography.fontSize
    }
  },
  error: {
    '& .MuiInput-input': {
      textAlign: 'inherit',
      fontSize: theme.typography.fontSize,
      color: theme.palette.error.main
    }
  },
  message: {
    padding: '5px 10px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.text.primary,
    textAlign: 'center',
    borderRadius: theme.shape.borderRadius,
    position: 'absolute',
    left: 0,
    bottom: theme.spacing(5.2),
    zIndex: 2
  }
}));

const SubJurisdictionsDropdown = ({
  label,
  subJurisdictions,
  isCellFailed,
  ...rest
}: {
  [key: string]: any;
  label: string;
  isCellFailed: boolean;
  subJurisdictions: SubJurisdiction[];
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Box role="container" className={classnames(classes.cell, { [classes.containerError]: isCellFailed && isFocused })}>
      {isCellFailed && isFocused ? (
        <Box role="message" className={classes.message}>
          {t('Save Unsuccessful')}
        </Box>
      ) : null}
      <Box className={classes.box} data-roles-disable-element={disableElementForRoles.join(' ')}>
        <Select
          displayEmpty
          disableUnderline
          defaultValue=""
          IconComponent={KeyboardArrowDown}
          inputProps={{ 'aria-label': label, 'data-testid': 'subJurisdictions-dropdown' }}
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
          {...rest}
        >
          <MenuItem disabled value="">
            {label}
          </MenuItem>
          {subJurisdictions?.map(({ id, name, subJurisdictionId }: any) => (
            <MenuItem key={id} value={subJurisdictionId}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  );
};

export default SubJurisdictionsDropdown;
