import { useMemo, useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Box } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { LEVELS } from '../../../../constants';
import { FederalOrStateProps, Level, Step } from '../../../../models';
import { TaxPeriodRow } from '../../../EntityDetails/models';
import {
  getTableDataFromFinancialData,
  handleEditRowForEntityDetails,
  handleOnCellOrCommentBlurForEntityDetails
} from '../../../EntityDetails/utils';
import TableWithComment from '../../../TableWithComment';

const CurrentYearPayments = ({
  entityId,
  currentStateId,
  failedCells,
  isFederal = true,
  stepCompletionStatus,
  tabsData
}: FederalOrStateProps) => {
  const LEVEL: Level = isFederal ? LEVELS.FEDERAL : LEVELS.STATE;
  const STEP: Step = useMemo(() => 'accounts', []);
  const LevelAndStep = useMemo(() => `${LEVEL as string}.${STEP as string}`, [LEVEL, STEP]);
  const { prov3322EditPerformanceFix: isPerformanceFixEnabled } = useFlags();

  const { t } = useTranslation();
  const [rows, setRows] = useState<TaxPeriodRow[]>([]);
  const dispatch = useDispatch();
  const financialInfo = useMemo(() => tabsData[LevelAndStep] ?? [], [LevelAndStep, tabsData]);
  const rowNames = useMemo(
    () => [
      { name: 'Q1 Estimated Tax', creditName: 'currentYearPayments', jurisdictionId: currentStateId },
      { name: 'Q2 Estimated Tax', creditName: 'currentYearPayments', jurisdictionId: currentStateId },
      { name: 'Q3 Estimated Tax', creditName: 'currentYearPayments', jurisdictionId: currentStateId },
      { name: 'Q4 Estimated Tax', creditName: 'currentYearPayments', jurisdictionId: currentStateId }
    ],
    [currentStateId]
  );
  const rowsWithData = useMemo(() => getTableDataFromFinancialData(rowNames, financialInfo), [
    financialInfo,
    rowNames
  ]) as TaxPeriodRow[];

  useEffect(() => {
    setRows(rowsWithData);
  }, [rowsWithData]);

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: t('Current Year Payments')
      },
      {
        field: 'amount',
        headerName: t('Amount'),
        isEditable: !stepCompletionStatus,
        isNumber: true
      }
    ],
    [stepCompletionStatus, t]
  );

  const dataForMethods = {
    columns,
    creditName: 'currentYearPayments',
    dispatch,
    entityId,
    financialInfo,
    jurisdictionId: currentStateId,
    level: LEVEL,
    rows,
    setRows,
    step: STEP,
    t
  };

  return (
    <Box style={{ overflow: 'visible' }}>
      <TableWithComment
        columns={columns}
        failedCells={failedCells}
        rows={rows}
        hideActionsMenu={stepCompletionStatus}
        totalHeaderName={t('Total Current Year Payments')}
        onCellChange={(params) => {
          if (!isPerformanceFixEnabled) {
            handleEditRowForEntityDetails({
              ...dataForMethods,
              ...params
            });
          }
        }}
        onCellOrCommentBlur={(params) => {
          if (isPerformanceFixEnabled) {
            handleEditRowForEntityDetails({
              ...dataForMethods,
              ...params
            });
          }

          handleOnCellOrCommentBlurForEntityDetails({
            ...dataForMethods,
            ...params
          });
        }}
      />
    </Box>
  );
};

export default CurrentYearPayments;
