import { PANELS } from './panels';

import { StateType, Actions } from '../../models';

export const BACK = 'BACK';
export const CREATE = 'CREATE'; // TODO
export const NEXT = 'NEXT';
export const SET_CONTAINER_OPERATION = 'SET_CONTAINER_OPERATION';
export const SET_TAX_YEAR = 'SET_TAX_YEAR';
export const SET_PERIOD_START = 'SET_PERIOD_START';
export const SET_PERIOD_END = 'SET_PERIOD_END';
export const SET_ROLLED_FROM_CONTAINER = 'SET_ROLLED_FROM_CONTAINER';
export const SET_CONTAINER_NAME = 'SET_CONTAINER_NAME';

export const INITIAL_STATE: StateType = {
  currentPanelIndex: 0,
  nPanels: PANELS.length,
  defineContainerPeriods: {},
  newContainerName: '',
  containerOperation: 'rollforward-yearly',
  containers: []
};

/**
 * TODO: Implement further as other panels are added.
 */
const getNextPanelIndex = (state: StateType) => {
  return state.currentPanelIndex + 1;
  // switch (state.currentPanelIndex) {
  //   case 0:
  //     return 1;

  //   default:
  //     return 0;
  // }
};

/**
 * TODO: Implement further as other panels are added.
 */
const getPrevPanelIndex = (state: StateType) => {
  return state.currentPanelIndex - 1;
  // switch (state.currentPanelIndex) {
  //   case 1:
  //     return 0;

  //   default:
  //     return 0;
  // }
};

export const reducer = (state: StateType, action: Actions): StateType => {
  switch (action.type) {
    case BACK: {
      return {
        ...state,
        currentPanelIndex: getPrevPanelIndex(state)
      };
    }

    case NEXT: {
      return {
        ...state,
        currentPanelIndex: getNextPanelIndex(state)
      };
    }

    case SET_CONTAINER_OPERATION: {
      return {
        ...state,
        containerOperation: action.payload
      };
    }

    case SET_TAX_YEAR: {
      return {
        ...state,
        defineContainerPeriods: {
          ...state.defineContainerPeriods,
          taxYear: action.payload
        }
      };
    }

    case SET_PERIOD_START: {
      return {
        ...state,
        defineContainerPeriods: {
          ...state.defineContainerPeriods,
          periodStart: action.payload
        }
      };
    }

    case SET_PERIOD_END: {
      return {
        ...state,
        defineContainerPeriods: {
          ...state.defineContainerPeriods,
          periodEnd: action.payload
        }
      };
    }

    case SET_ROLLED_FROM_CONTAINER: {
      return {
        ...state,
        rolledFromContainer: action.payload,
        defineContainerPeriods: {}
      };
    }

    case SET_CONTAINER_NAME: {
      return {
        ...state,
        newContainerName: action.payload
      };
    }

    default: {
      return state;
    }
  }
};
