import { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { DeletedCategoryFromApi } from '../../models';
import { categoryDeleteRequest, categoryDeleteSuccess, categoryDeleteFailure } from '../../redux/categories';
import HTTPService, { LambdaResponse } from '../../services/http';

export const useCategoryDelete = () => {
  const dispatch = useDispatch();

  const deleteCategory = useCallback(
    async (categoryId: string) => {
      dispatch(categoryDeleteRequest());

      try {
        const { data } = await HTTPService.request<LambdaResponse<DeletedCategoryFromApi>>({
          method: 'delete',
          relativePath: `/v1/categories/${categoryId}`
        });

        dispatch(categoryDeleteSuccess(data));
      } catch (error: unknown) {
        dispatch(categoryDeleteFailure(error as any));
        throw error;
      }
    },
    [dispatch]
  );
  return { deleteCategory };
};
