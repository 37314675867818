import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { Container } from '../../models';
import { formatDateLongDayFirst } from '../../utils';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.action.focus,
    color: theme.palette.common.white,
    height: '26px',
    '& .MuiButtonBase-root': {
      lineHeight: '18px'
    }
  },
  finalizedText1: {
    flex: 0.8,
    textAlign: 'left',
    marginLeft: '18px'
  },
  finalizedText2: {
    flex: 1,
    textAlign: 'left'
  }
}));

interface FinalizeContainerMessageProps {
  currentContainer: Container;
}

const FinalizeContainerMessage = ({ currentContainer }: FinalizeContainerMessageProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return currentContainer?.isFinalized ? (
    <Box className={classes.container}>
      <Typography className={classes.finalizedText1} variant="caption">
        {`${t('finalized-container-text1')} - ${formatDateLongDayFirst(currentContainer?.finalizedAt ?? null)}`}
      </Typography>
      <Typography className={classes.finalizedText2} variant="caption">
        {t('finalized-container-text2')}
      </Typography>
    </Box>
  ) : null;
};

export default FinalizeContainerMessage;
