import { makeStyles } from '@material-ui/core/styles';

interface StyleProps {
  isNotEditableShaded?: boolean;
  isBackgroundColorWhite?: boolean;
  isGroupedViewToggleOn?: boolean;
}

export const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%'
  },
  tableWithComment: {
    '& .MuiTableRow-root > .MuiTableCell-body:not(:last-child)': {
      borderRight: 'none'
    },
    '& .MuiTableRow-root:not(.total) > .MuiTableCell-body:last-child': {
      backgroundColor: theme.palette.common.white
    },
    '& .MuiTableCell-head.right': {
      textAlign: 'right'
    },
    '& table': {
      tableLayout: 'fixed'
    }
  },
  table: ({ isGroupedViewToggleOn, isBackgroundColorWhite = true }: StyleProps) => ({
    '& .MuiTableCell-root': {
      padding: theme.spacing(1.2),
      backgroundColor: isBackgroundColorWhite ? theme.palette.common.white : undefined,
      whiteSpace: 'pre-wrap',
      '&.active-row.active-column': {
        backgroundColor: theme.palette.action.selected
      },
      '&.MuiTableCell-body': {
        fontFamily: theme.typography.h3.fontFamily
      }
    },
    '& .MuiTableCell-head': {
      fontFamily: theme.typography.h3.fontFamily,
      fontSize: theme.typography.body2.fontSize,
      color: theme.palette.text.secondary,
      borderBottom: '1px solid rgba(251, 251, 252, 1)'
    },
    '& .MuiTableHead-root > tr:not(:first-child) .MuiTableCell-head': {
      padding: theme.spacing(0, 1.2, 1.2)
    },
    '& .MuiTableHead-root': {
      pointerEvents: isGroupedViewToggleOn ? 'none' : 'auto'
    },
    '& .checkbox': {
      width: '30px',
      textAlign: 'center',

      '& .MuiButtonBase-root': {
        padding: theme.spacing(0.5),

        '&.Mui-checked.Mui-disabled': {
          opacity: 0.5
        }
      },
      '&.MuiTableCell-head .MuiButtonBase-root': {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.action.hover,
        padding: theme.spacing(1, 1.5)
      },
      '&.MuiTableCell-head, &.MuiTableCell-body': {
        padding: theme.spacing(0, 1)
      }
    },
    '& .MuiTableCell-body.sticky': {
      position: 'sticky',
      left: 0
    },
    '& .MuiTableCell-head.sticky': {
      zIndex: 3
    },
    '& .MuiTableCell-body.sortable-numerical': {
      paddingRight: theme.spacing(4)
    },
    '& .MuiTableRow-hover:hover:not(.new-row) .MuiTableCell-body.MuiTableCell-root:not(:focus-within)': {
      // can't use theme.palette.action.hover because it is semi-transparent, and doesn't play well with sticky columns
      backgroundColor: '#f5f5f5'
    },
    '& .selected:not(:hover) .MuiTableCell-body.MuiTableCell-root': {
      // can't use theme.palette.action.selected because it is semi-transparent, and doesn't play well with sticky columns
      backgroundColor: '#ebebeb'
    },
    '& .MuiTableCell-body.no-rows': {
      padding: theme.spacing(3),
      textAlign: 'center',
      borderBottom: 'none'
    },
    '& .editable.MuiTableCell-body.MuiTableCell-root': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary,
      padding: 0
    },
    '& .total .MuiTableCell-body.MuiTableCell-root': {
      backgroundColor: theme.palette.action.selected,
      color: theme.palette.text.secondary,
      borderTop: `2px solid ${theme.palette.grey[400]}`,
      borderBottom: `2px solid ${theme.palette.grey[400]}`,
      fontWeight: theme.typography.fontWeightBold,
      paddingRight: '14.4px'
    },
    '& .category-total .MuiTableCell-body.MuiTableCell-root': {
      fontWeight: 'bolder',
      borderTop: '1px solid black',
      borderBottom: '1px solid black'
    },
    '& .space .MuiTableCell-body.MuiTableCell-root': {
      backgroundColor: 'white!important',
      color: 'white',
      height: '3px',
      border: 'none',
      padding: '0'
    },
    '& .float-right': {
      float: 'right'
    },
    '& .float-left': {
      float: 'left'
    }
  }),
  flat: {
    '& .MuiTableCell-body:not(:last-child)': {
      borderRight: `1px solid ${theme.palette.divider}`
    },
    '& .MuiTableCell-body:not(:only-child)': {
      backgroundColor: ({ isNotEditableShaded = true }: StyleProps) =>
        isNotEditableShaded ? theme.palette.grey[50] : theme.palette.common.white,
      color: ({ isNotEditableShaded = true }: StyleProps) =>
        theme.palette.text[isNotEditableShaded ? 'secondary' : 'primary'],
      borderColor: 'rgba(251, 251, 252, 1)'
    },
    'border-cell': {
      '& .MuiTableCell-body:not(:only-child)': {
        backgroundColor: ({ isNotEditableShaded = true }: StyleProps) =>
          isNotEditableShaded ? theme.palette.grey[50] : theme.palette.common.white,
        color: ({ isNotEditableShaded = true }: StyleProps) =>
          theme.palette.text[isNotEditableShaded ? 'secondary' : 'primary']
      }
    }
  },
  outline: {
    '& .MuiTableCell-body.no-rows:first-child, & .MuiTableCell-body.no-rows:last-child': {
      borderColor: 'transparent'
    },
    '& .MuiTableCell-body:first-child, & .checkbox + .MuiTableCell-body': {
      borderLeft: `1px solid ${theme.palette.divider}`,
      borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`
    },
    '& .MuiTableCell-body:last-child': {
      borderRight: `1px solid ${theme.palette.divider}`,
      borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`
    },
    '& .MuiTableCell-root.checkbox': {
      borderColor: 'transparent'
    },
    '& .MuiTableRow-hover:hover .MuiTableCell-root.checkbox, & .selected .MuiTableCell-root.MuiTableCell-body.checkbox': {
      backgroundColor: theme.palette.common.white
    },
    '& .MuiTableCell-body': {
      borderTop: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(0.7, 1.2)
    },
    '& .MuiTableCell-head': {
      borderBottom: 'none',
      padding: theme.spacing(0.7, 1.2)
    },
    '& .filter.MuiTableCell-head': {
      paddingLeft: 0
    }
  },
  outlineSeparator: {
    '& .MuiTableCell-body.MuiTableCell-root': {
      border: 'none',
      padding: theme.spacing(0.5, 0)
    }
  },
  divider: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  sectionHeader: {
    '&.MuiTableCell-root': {
      padding: theme.spacing(3, 0, 1, 1),
      borderColor: 'rgba(251, 251, 252, 1)'
    }
  },
  colGroups: {
    '& .MuiTableCell-root': {
      borderBottom: 'none',
      padding: theme.spacing(0, 1, 0.8),
      position: 'relative'
    }
  },
  colGroup: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.action.hover,
    padding: theme.spacing(0.5, 1.5),
    fontWeight: theme.typography.fontWeightBold
  }
}));
