import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Box, Tabs, Tab, Typography, Button } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import { SlideOut } from '@xbs/xbs-common-ui';

import { useStyles } from './UploadOverview.styles';

import { Row } from '../../../../models';
import Table from '../../../Table';

const UploadOverview = ({ isLoading = false, rows }: { isLoading?: boolean; rows: Row[] }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [tab, setTab] = useState('uploads');

  function handleTabChange(event: any, newValue: any) {
    setTab(newValue);
  }

  const columns = [
    {
      field: 'name',
      headerName: t('File Name'),
      width: '15%',
      filterable: true,
      sortable: true,
      placeholder: t('File Name')
    },
    {
      field: 'version',
      headerName: t('Version'),
      width: '5%'
    },
    {
      field: 'type',
      headerName: t('Type'),
      isNumber: false,
      width: '10%'
    },
    {
      field: 'uploaded',
      headerName: t('Uploaded'),
      isNumber: false,
      width: '10%'
    },
    {
      field: 'user',
      headerName: t('User'),
      isNumber: false,
      width: '35%'
    },
    {
      field: 'status',
      headerName: t('Status'),
      isNumber: false,
      width: '15%',
      renderCell: (row: Row) => {
        return (
          <Typography
            variant="caption"
            className={`${classes.colorSpan} ${classes.statusColumn}${
              row.status[0] ? ` ${row.status[0] as string}` : ''
            }`}
          >
            {row.status[1]}
          </Typography>
        );
      }
    },
    {
      field: 'action',
      isNumber: false,
      width: '15%',
      renderCell: (row: Row) => {
        return (
          <Button
            color="inherit"
            className={`${classes.colorSpan} ${classes.actionColumn}${
              row.action[0] ? ` ${row.action[0] as string}` : ''
            }`}
            style={{ cursor: row?.onClickAction ? 'pointer' : 'not-allowed' }}
            onClick={row?.onClickAction}
          >
            <Typography variant="caption">{row.action[1]}</Typography>
            {row.action[0] && <ArrowForwardIos />}
          </Button>
        );
      }
    }
  ];

  const hasUploads = rows?.length > 0;

  return (
    <SlideOut.Container>
      <Box className={classes.container}>
        <Typography variant="h2" component="h1" className={classes.title}>
          <Box component="span" flexGrow={1}>
            {t('Upload Overview')}
          </Box>
        </Typography>
        <Box className={classes.content}>
          <Tabs indicatorColor="primary" textColor="primary" value={tab} onChange={handleTabChange}>
            <Tab label={t('Uploads')} value="uploads" />
          </Tabs>
          <Box className={classes.tabContent}>
            {isLoading ? (
              <Box className={classes.noUploads}>Loading uploads</Box>
            ) : hasUploads ? (
              <Table className={classes.table} columns={columns} rows={rows} />
            ) : (
              <Box className={classes.noUploads}>No uploads</Box>
            )}
          </Box>
        </Box>
      </Box>
    </SlideOut.Container>
  );
};

export default UploadOverview;
