import { styled } from '@material-ui/core';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';

const ProStaticDatePicker = styled(StaticDatePicker)(() => ({
  '& .MuiPickersLayout-toolbar, .MuiPickersLayout-actionBar': {
    display: 'none'
  },
  '& .MuiDateCalendar-root': {
    width: '320px'
  },
  '& .MuiPickersDay-root.Mui-selected': {
    backgroundColor: '#6E56DB',
    '&:hover, &:focus': {
      backgroundColor: '#6E56DB'
    }
  },
  '& .MuiPickersCalendarHeader-label': {
    fontFamily: 'Basis Grotesque Pro Medium',
    color: '#000'
  }
}));

export default ProStaticDatePicker;
