import ReactDOM from 'react-dom';
import { initReactI18next } from 'react-i18next';
import { Provider } from 'react-redux';

import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';

import { App } from './components';
import en from './locales/en.json';
import fr from './locales/fr.json';
import * as serviceWorker from './serviceWorker';
import './global.css';
import store from './store';

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources: { en, fr },
    nonExplicitSupportedLngs: true,
    fallbackLng: 'en',
    interpolation: { escapeValue: false }
  })
  .catch((error: any) => {
    console.log('Error initializing i18n. Error:', error);
  });

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.querySelector('#root')
);

// TODO: make the app a PWA by tweeking the service worker and calling register(): https://bit.ly/CRA-PWA
serviceWorker.unregister();
