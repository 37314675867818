import { ColorSpecs } from '../components/UploadReview/components/AccountRangesTab/AccountRangesTab.proptype';
import { alphaSort } from '../components/UploadReview/components/AccountRangesTab/utils';
import { AccountRange, AccountShape } from '../models';

// locale doesn't matter here: we only need the ability to compare strings containing numbers

export function isAboveOrEqual(high: string, low: string) {
  return Boolean(high) && Boolean(low) && alphaSort(low, high) <= 0;
}

export function isInRange(id: string, [low, high]: string[]) {
  return isAboveOrEqual(id, low) && isAboveOrEqual(high, id);
}

export function getInitialStateForRanges({
  flattenAccounts: accounts,
  colorSpecs,
  ranges
}: {
  flattenAccounts: AccountShape[];
  colorSpecs: ColorSpecs;
  ranges: AccountRange[];
}) {
  const rangeValidity = computeValidity(ranges);
  return {
    rangeValidity,
    ...refreshStylesAndCounts(accounts, ranges, rangeValidity, colorSpecs)
  };
}

function computeValidity(ranges: AccountRange[]): Record<string, boolean> {
  const rangeValidity: Record<string, boolean> = {};
  for (const { id, range } of ranges) {
    rangeValidity[id] = isAboveOrEqual(range[1], range[0]) && doesNotOverlap(id, range, ranges);
  }

  return rangeValidity;
}

function doesNotOverlap(id: AccountRange['id'], [low, high]: AccountRange['range'], ranges: AccountRange[]) {
  for (const other of ranges) {
    if (other.id !== id && (isInRange(low, other.range) || isInRange(high, other.range))) {
      return false;
    }
  }

  return true;
}

function refreshStylesAndCounts(
  accounts: AccountShape[],
  ranges: AccountRange[],
  rangeValidity: Record<string, boolean>,
  colorSpecs: ColorSpecs
) {
  const styles: Record<string, Record<string, string>> = {};
  const counts: Record<AccountRange['id'], number> = {};
  if (Array.isArray(accounts)) {
    const firstAccountByRangeId: Record<AccountRange['id'], any> = {};
    const lastAccountByRangeId: Record<AccountRange['id'], any> = {};
    for (const { id } of accounts) {
      for (const { id: rangeId, range } of ranges) {
        if (rangeValidity[rangeId] && isInRange(id, range)) {
          styles[id] = {
            backgroundColor: colorSpecs[rangeId].background
          };
          // eslint-disable-next-line max-depth
          if (!(rangeId in firstAccountByRangeId)) {
            firstAccountByRangeId[rangeId] = id;
            styles[id].borderTop = `2px solid ${colorSpecs[rangeId].plain}`;
          }

          lastAccountByRangeId[rangeId] = id;
          counts[rangeId] = (counts[rangeId] || 0) + 1;
          break;
        }
      }
    }

    for (const [rangeId, id] of Object.entries(lastAccountByRangeId)) {
      styles[id].borderBottom = `2px solid ${colorSpecs[rangeId].plain}`;
    }
  }

  return { styles, counts };
}
