import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { containersOnCurrentSwitched } from '../../redux/containers';
import { selectContainerContainers } from '../../selectors';
import PlatformService from '../../services/platform';

export const PLATFORM_CONTAINER_CHANGED = 'XBS-Container-Changed';

const PlatformProvider = ({ children }: { children: JSX.Element }) => {
  const { Events } = PlatformService._platformInstance;
  const containers = useSelector(selectContainerContainers);
  const [isListentingForPlatformEvents, setIsListentingForPlatformEvents] = useState(false);
  const dispatch = useDispatch();

  if (!isListentingForPlatformEvents) {
    Events.on(PLATFORM_CONTAINER_CHANGED, async (newContainerUUID: string) => {
      const container = containers.find((container) => container.containerId === newContainerUUID)!;
      // eslint-disable-next-line no-alert
      window.alert('Warning: Current container has been switched on another browser tab');
      dispatch(containersOnCurrentSwitched(container.containerId));
    });
    setIsListentingForPlatformEvents(true);
  }

  return children;
};

export default PlatformProvider;
