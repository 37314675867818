import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';

import theme from '../../theme';

interface PageSelectorProps {
  totalPages: number;
  isLoading: boolean;
  onPageChange: (page: number) => void;
  onPageSizeChange: (size: number) => void;
  pageSizeOptions: number[];
  currentPageNumber: number;
  currentPageSize: number;
}

const useStyles = makeStyles((theme) => ({
  pageSelectorContainer: {
    paddingTop: '10px',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center'
  },
  rowsSelector: {
    maxWidth: '70px'
  },
  select: {
    height: '30px',
    width: '70px'
  },
  text: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
    alignSelf: 'center'
  }
}));

const PageSelector = ({
  totalPages,
  isLoading,
  onPageChange,
  onPageSizeChange,
  pageSizeOptions,
  currentPageNumber,
  currentPageSize
}: PageSelectorProps) => {
  const classes = useStyles();

  const handlePageChange = (event: any, value: number) => {
    onPageChange(value);
  };

  const handlePageSizeChange = (event: any) => {
    onPageSizeChange(event.target.value as number);
  };

  return (
    <Stack direction="row" spacing={1} className={classes.pageSelectorContainer}>
      {!isLoading && (
        <>
          <Typography variant="body2" className={classes.text}>
            Rows per page:
          </Typography>
          <FormControl className={classes.rowsSelector}>
            <Select
              displayEmpty
              MenuProps={{
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'left'
                },
                transformOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                }
              }}
              sx={{ fontSize: '13px', color: theme.palette.text.secondary }}
              id="page-size-select"
              value={currentPageSize}
              className={classes.select}
              onChange={handlePageSizeChange}
            >
              {pageSizeOptions.map((size) => (
                <MenuItem key={size} value={size} sx={{ fontSize: '13px', color: theme.palette.text.secondary }}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Pagination count={totalPages} page={currentPageNumber + 1} onChange={handlePageChange} />
        </>
      )}
    </Stack>
  );
};

export default PageSelector;
