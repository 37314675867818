import { useState, useMemo, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Box } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { LEVELS } from '../../../../constants';
import { FederalOrStateProps, Level, Step } from '../../../../models';
import { TaxPeriodRow } from '../../../EntityDetails/models';
import {
  getRowNamesFromLevelSteps,
  getTableDataFromFinancialData,
  handleOnRowDeleteForEntityDetails,
  handleEditRowForEntityDetails,
  handleOnCellOrCommentBlurForEntityDetails,
  handleNewRowForEntityDetails
} from '../../../EntityDetails/utils';
import TableWithComment from '../../../TableWithComment';

const InterestAndPayments = ({
  currentStateId,
  entityId,
  failedCells,
  isFederal = true,
  stepCompletionStatus,
  tabsData
}: FederalOrStateProps) => {
  const LEVEL: Level = useMemo(() => (isFederal ? LEVELS.FEDERAL : LEVELS.STATE), [isFederal]);
  const STEP: Step = useMemo(() => 'accounts', []);
  const STEPS: Step[] = useMemo(() => [STEP], [STEP]);
  const LevelAndStep = useMemo(() => `${LEVEL}.${STEP}`, [LEVEL, STEP]);
  const {
    prov3322EditPerformanceFix: isPerformanceFixEnabled,
    prov3736ReservedWordValidation: showReservedWordsError
  } = useFlags();
  const { t } = useTranslation();
  const [rows, setRows] = useState<TaxPeriodRow[]>([]);
  const [hasNewRow, setHasNewRow] = useState(false);
  const dispatch = useDispatch();
  const financialInfo = useMemo(() => tabsData[LevelAndStep] ?? [], [LevelAndStep, tabsData]);
  const rowNames = useMemo(() => getRowNamesFromLevelSteps(tabsData, LEVEL, STEPS)[STEP], [
    LEVEL,
    STEP,
    STEPS,
    tabsData
  ]);
  const rowsWithData = useMemo(() => getTableDataFromFinancialData(rowNames, financialInfo), [
    financialInfo,
    rowNames
  ]) as TaxPeriodRow[];

  const newRow = useMemo(() => rows[rows.length - 1], [rows]);
  const rowsToSet = useMemo(
    () => [
      ...rowsWithData.filter((row) => row.creditName === 'interestAndPenalties'),
      ...(newRow?.isNew ? [newRow] : [])
    ],
    [newRow, rowsWithData]
  );

  useEffect(() => {
    setRows(rowsToSet);
  }, [rowsToSet]);

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: t('Interest/Penalties'),
        isNewRowEditable: true
      },
      {
        field: 'amount',
        headerName: '',
        isEditable: !stepCompletionStatus,
        isNumber: true
      }
    ],
    [stepCompletionStatus, t]
  );

  const dataForMethods = {
    columns,
    creditName: 'interestAndPenalties',
    dispatch,
    entityId,
    financialInfo,
    jurisdictionId: currentStateId,
    hasNewRow,
    level: LEVEL,
    rows,
    setHasNewRow,
    setRows,
    step: STEP,
    t,
    showReservedWordsError
  };

  return (
    <Box style={{ overflow: 'visible' }}>
      <TableWithComment
        columns={columns}
        failedCells={failedCells}
        rows={rows}
        totalHeaderName={t('Total Interest/Penalties')}
        newRowButtonLabel={
          stepCompletionStatus ? null : t(hasNewRow ? 'Save Interest / Penalties' : 'Add Interest / Penalties')
        }
        hideActionsMenu={stepCompletionStatus}
        handleOnRowDelete={(params) => {
          handleOnRowDeleteForEntityDetails({
            ...dataForMethods,
            ...params
          });
        }}
        onCellChange={(params) => {
          if (!isPerformanceFixEnabled) {
            handleEditRowForEntityDetails({
              ...dataForMethods,
              ...params
            });
          }
        }}
        onCellOrCommentBlur={(params) => {
          if (isPerformanceFixEnabled) {
            handleEditRowForEntityDetails({
              ...dataForMethods,
              ...params
            });
          }

          handleOnCellOrCommentBlurForEntityDetails({
            ...dataForMethods,
            ...params
          });
        }}
        onNewRowClick={() => {
          handleNewRowForEntityDetails({
            ...dataForMethods
          });
        }}
      />
    </Box>
  );
};

export default InterestAndPayments;
