import { useState } from 'react';

import { Box, Radio } from '@material-ui/core';

import { EntityWithRates } from '../../../../models';
import ConfirmationDialog from '../../../ConfirmationDialog/components/ConfirmationDialog';
import { disableParentEntityRadioButtonForRoles } from '../../../UserRoleStylesProvider/constants';

type ChangeParentEntityWithConfirmationProps = {
  row: EntityWithRates;
  onParentEntityChange: (entityId: EntityWithRates['id']) => void;
  isContainerFinalized?: boolean;
};

const ChangeParentEntityWithConfirmation = ({
  row,
  onParentEntityChange,
  isContainerFinalized
}: ChangeParentEntityWithConfirmationProps) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleParentChangeWithConfirmation = () => {
    setShowConfirmation(true);
  };

  const handleConfirmationSubmit = () => {
    onParentEntityChange(row.entityId);
    setShowConfirmation(false);
  };

  return (
    <Box display="flex" alignItems="center">
      <Radio
        data-roles-disable-radio-button-element={disableParentEntityRadioButtonForRoles.join(' ')}
        checked={Boolean(row.isParent)}
        name="isParent-radio-buttons"
        onChange={handleParentChangeWithConfirmation}
        {...(isContainerFinalized ? { disabled: true } : {})}
      />
      {showConfirmation && (
        <ConfirmationDialog
          dialogTitle="Confirm"
          dialogBody="Are you sure you want to change Parent Entity for this container?"
          open={showConfirmation}
          setOpen={setShowConfirmation}
          onSubmit={handleConfirmationSubmit}
        />
      )}
    </Box>
  );
};

export default ChangeParentEntityWithConfirmation;
