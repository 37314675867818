/* eslint-disable unicorn/prefer-array-find */
import {
  federalStepsByName,
  FED_TEMP_BALANCE_SHEET_LEVEL_AND_STEP,
  FED_TEMP_INCOME_STATEMENT_LEVEL_AND_STEP,
  LEVELS,
  ATTRIBUTE_NAMES
} from '../../../../constants';
import { FinancialInfoTabsData, Level, Step } from '../../../../models';
import { Column, Row } from '../../../Table/Table.proptype';
import { renderValue } from '../../../Table/utils';

export const getRowByStateValue = (
  tabsData: FinancialInfoTabsData,
  level: Level,
  steps: Step[],
  subJurisdictionId?: string
) => {
  const entries = steps.map((step: Step) => {
    const LevelAndStep = `${level}.${step}`;
    const groupOfCells = tabsData[LevelAndStep];

    const validStateCells = groupOfCells?.filter(
      (cell: any) =>
        (cell.columnName === 'state' && cell.value.includes(subJurisdictionId)) ||
        (cell.jurisdictionId && cell.jurisdictionId === subJurisdictionId)
    );

    const tabsDataFilteredByStateValue = [LevelAndStep, validStateCells];

    return tabsDataFilteredByStateValue;
  });

  return Object.fromEntries(entries);
};

export const renderCell = (_row: Row, value: number, column: Column) => {
  const nonEditableColumn = { ...column, isEditable: false };

  return renderValue(value, nonEditableColumn);
};

interface ThingWithNumberValue {
  value: number;
}

const sumValues = (arr: ThingWithNumberValue[]) =>
  // eslint-disable-next-line unicorn/no-array-reduce
  arr.reduce((acc: number, entry: ThingWithNumberValue) => acc + entry.value ?? 0, 0);

export const getFederalTaxableIncomeLossBeforeStateTaxAndNol = (
  tabsData: FinancialInfoTabsData,
  columnName: 'taxProvision' | 'taxReturn'
) => {
  const federalRtpRows =
    (tabsData?.[`${LEVELS.FEDERAL}.${federalStepsByName.RTP}`] as ThingWithNumberValue[]) ??
    ([] as ThingWithNumberValue[]);
  const federalRtpPermanentRows = federalRtpRows.filter(
    (row: Row) => row.creditName === `${LEVELS.FEDERAL}.${federalStepsByName.PERMANENT}`
  );

  const ptbiAdjustmentsRows: ThingWithNumberValue[] = federalRtpRows.filter(
    (row: Row) => row.columnName === columnName && row.rowName === ATTRIBUTE_NAMES.adjustments
  );
  const ptbiPtbiRows: ThingWithNumberValue[] = federalRtpRows.filter(
    (row: Row) => row.columnName === columnName && row.rowName === ATTRIBUTE_NAMES.preTaxBookIncomeLoss
  );
  const ptbiSum = (ptbiAdjustmentsRows[0]?.value ?? 0) + (ptbiPtbiRows[0]?.value ?? 0);

  const federalPermanentRows = federalRtpPermanentRows.filter((row: Row) => row.columnName === columnName);
  const federalPermanentTotal = sumValues(federalPermanentRows);

  const federalRtpBalanceSheetRows = federalRtpRows.filter(
    (row: Row) => row.creditName === FED_TEMP_BALANCE_SHEET_LEVEL_AND_STEP && row.columnName === columnName
  );
  const federalBalanceSheetTotal = sumValues(federalRtpBalanceSheetRows);

  const federalIncomeStatementRows = federalRtpRows.filter(
    (row: Row) => row.creditName === FED_TEMP_INCOME_STATEMENT_LEVEL_AND_STEP && row.columnName === columnName
  );
  const federalIncomeStatementTotal = sumValues(federalIncomeStatementRows);

  const grandTotal = ptbiSum + federalPermanentTotal + federalBalanceSheetTotal + federalIncomeStatementTotal;

  return grandTotal;
};
