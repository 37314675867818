import { createSelector } from '@reduxjs/toolkit';

import { selectCurrencyById } from '.';

import { USD_CURRENCY_ID } from '../constants';
import { Currency, Entity } from '../models';
import { RootState } from '../redux';

export const selectCustomGroups = (state: RootState) => state.customGroups;

export const selectCustomGroupsEntities = createSelector(
  selectCustomGroups,
  (customGroupsState) => customGroupsState.customGroupsEntities
);

export const selectCustomGroupCurrencies = createSelector(
  selectCustomGroupsEntities,
  selectCurrencyById,
  (customGroupsEntities, currencyById) => {
    const formattedCustomGroups: Record<string, Currency[]> = {};
    for (const [groupId, entities] of Object.entries(customGroupsEntities)) {
      const groupCurrencies = getGroupCurrency(entities, currencyById);
      formattedCustomGroups[groupId] = groupCurrencies;
    }

    return formattedCustomGroups;
  }
);

export const getGroupCurrency = (groupEntityData: Entity[], currencyById: Record<string, Currency>) => {
  const currencySet = new Set(groupEntityData.map((entity: Entity) => entity.currencyId));

  const groupCurrency = [currencyById[USD_CURRENCY_ID]];
  if (currencySet.size === 1 && !currencySet.has(USD_CURRENCY_ID)) {
    groupCurrency.push(currencyById[groupEntityData[0].currencyId]);
  }

  return groupCurrency;
};
