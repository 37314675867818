import { useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Box, makeStyles } from '@material-ui/core';

import { TableWithComment } from '../../..';
import { calculateBalanceSheet } from '../../../../calculations';
import { EntityById } from '../../../../models';
import { useEditableCategoryRows } from '../../hooks';
import { AdjustmentWithCategoryShape, getTemporaryBalanceSheetColumns } from '../../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid',
    borderColor: theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    flexGrow: 1,
    overflow: 'hidden',
    padding: theme.spacing(2)
  }
}));

function computeRowValues(row: any, adjustment: any) {
  return {
    ...row,
    ...calculateBalanceSheet({ ...adjustment, ...row, ...(row.rtp || {}) })
  };
}

type Props = {
  // @ts-expect-error ts-migrate(2749) FIXME: 'unifiedCategoryShape' refers to a value, but is b... Remove this comment to see the full error message
  category?: unifiedCategoryShape;
  adjustment?: AdjustmentWithCategoryShape;
  entityById: EntityById;
};

const FederalTemporaryCategory = ({ category, adjustment, entityById }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const columns = [
    {
      field: 'id',
      filterable: true,
      sortable: true,
      placeholder: t('Entity Id'),
      isNewRowEditable: true,
      getOptions: () => unassignedEntityIds,
      width: '12%'
    },
    {
      field: 'name',
      divider: true,
      filterable: true,
      sortable: true,
      placeholder: t('Entity Name'),
      width: '15%'
    },
    ...getTemporaryBalanceSheetColumns(t, true) // FIXME: Fix second parameter once Categories is implemented post-MVP. (PROV-523, PROV-373)
  ];

  const editComputedValues = ({ column, value, row }: any) => {
    const editedValues =
      column === columns[0] ? { id: value, name: entityById[value]?.name } : { [column.field]: value };
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    return computeRowValues({ ...row, ...editedValues });
  };

  const {
    rows,
    setRows,
    hasNewRow,
    setHasNewRow,
    handleNewRow,
    handleEditRow,
    getUnassignedEntityIds
  } = useEditableCategoryRows(editComputedValues);

  const unassignedEntityIds = useMemo(() => getUnassignedEntityIds({ entityById, adjustment }), [
    entityById,
    adjustment,
    getUnassignedEntityIds
  ]);

  useEffect(() => {
    if (!adjustment || !category) {
      setRows([]);
      return;
    }

    setHasNewRow(false);
    const selectedCategory = category.find(
      ({ accountId, name }: any) => accountId === adjustment.accountId && name === adjustment.name
    );
    const categoryEntities = selectedCategory?.entities || [];
    const categoryRows = categoryEntities.map((row: any) => ({
      ...computeRowValues(row, adjustment),
      name: entityById[row.id]?.name
    }));
    setRows(categoryRows);
  }, [adjustment, category, entityById, setHasNewRow, setRows]);

  return (
    <Box className={classes.root}>
      <TableWithComment
        columns={columns}
        rows={rows}
        sort={hasNewRow ? null : { column: columns[0], dir: TableWithComment.ASC }}
        newRowButtonLabel={unassignedEntityIds.length > 0 ? t(hasNewRow ? 'Save Entity' : 'Add Entity') : null}
        noRowLabel={t(adjustment?.name ? 'No entities with this adjustment yet' : 'Please select an adjustment above')}
        onNewRowClick={handleNewRow}
        onCellChange={handleEditRow}
      />
    </Box>
  );
};

export default FederalTemporaryCategory;
