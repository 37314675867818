import { useEffect, useMemo, useReducer } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Box, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { reducer, makeInitialState, SET_DATA, START_EDITING, TOGGLE_ROWS, STOP_EDITING } from './reducer';
import { TaxSensitiveTabProps } from './TaxSensitiveTab.proptype';

import { AttributeSelector, EntityTableHeader, Layout } from '..';
import { Table } from '../../..';
import { updateTabData } from '../../../../redux/uploadReview';

const useStyles = makeStyles((theme) => ({
  rangeDivider: {
    margin: theme.spacing(1.2, 0)
  }
}));

const TaxSensitiveTab = ({ categorySpecs, entities, accounts, ranges, rangeIdsByCategoryId }: TaxSensitiveTabProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [{ categories, edited, rows, selected }, localDispatch] = useReducer(
    reducer,
    { ranges, accounts, entities, categorySpecs, rangeIdsByCategoryId },
    makeInitialState
  );

  useEffect(() => {
    if (Array.isArray(accounts) && Array.isArray(entities)) {
      localDispatch({ type: SET_DATA, payload: { ranges, accounts, entities, categorySpecs } });
    }
  }, [ranges, accounts, entities, categorySpecs, localDispatch]);

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: t('Account'),
        width: '10%'
      },
      {
        field: 'description',
        headerName: t('Description'),
        width: '25%',
        sticky: true,
        divider: true
      },
      ...entities.map(({ id: field, name }) => ({
        field,
        isNumber: true,
        renderHeader: () => <EntityTableHeader id={field} name={name} />
      }))
    ],
    [entities, t]
  );

  return (
    <Layout
      asideTitle={t('Tax Sensitive Accounts')}
      description={t('Please define the following items within the Trial Balance.')}
      asideContent={
        <Box overflow="auto">
          {categorySpecs.map(({ id }, i) => (
            <Box key={id}>
              {i > 0 && <Divider className={classes.rangeDivider} />}
              <AttributeSelector
                name={t(id)}
                count={categories[i].accountIds.length}
                isEditMode={id === edited?.id}
                onEdit={() => {
                  localDispatch({ type: START_EDITING, payload: { id } });
                }}
                onDone={() => {
                  dispatch(updateTabData(categories));
                  localDispatch({ type: STOP_EDITING });
                }}
              />
            </Box>
          ))}
        </Box>
      }
      mainTitle={t('Accounts Listing')}
      dataCountLabel={t('_ found', { count: rows?.length })}
      mainContent={
        <Table
          columns={columns}
          rows={rows}
          isSelectable={Boolean(edited)}
          sort={{ column: columns?.[0], dir: Table.ASC }}
          selected={selected}
          noRowLabel={t('No account found. Please check account ranges in the previous step.')}
          isNotEditableShaded={false}
          onSelect={(selectedRows: any, areSelected: any) => {
            localDispatch({
              type: TOGGLE_ROWS,
              payload: {
                rowIds: selectedRows.map(({ id }: any) => id),
                areSelected
              }
            });
          }}
        />
      }
    />
  );
};

export default TaxSensitiveTab;
