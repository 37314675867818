import { useMemo, useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, IconButton, Theme, Typography, makeStyles } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import { useContainers } from '../../../../hooks';
import { useCategoryDelete } from '../../../../hooks/useCategoryDelete';
import { CategoryFromApi, Row } from '../../../../models';
import { selectCategories } from '../../../../selectors/categories';
import ColorCategoryName from '../../../ColorCategoryName';
import ConfirmationDialog from '../../../ConfirmationDialog/components/ConfirmationDialog';
import LoadingSpinner from '../../../LoadingSpinner';
import TableWithComment from '../../../TableWithComment';
import EditCategoryDrawer from '../EditCategoryDrawer/EditCategoryDrawer';
import NewCategoryDrawer from '../NewCategoryDrawer';

interface StyleProps {
  categoriesLength: number;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  categoryTableContainer: {
    paddingLeft: theme.spacing(1.42),
    paddingRight: theme.spacing(1.42),
    paddingTop: theme.spacing(0)
  },
  categoryTable: {
    '& .MuiTable-root': {
      '& .MuiTableBody-root .MuiTableRow-root:first-child .MuiTableCell-root': {
        borderTop: `1px solid ${theme.palette.divider}`
      },
      '& .MuiTableCell-root.MuiTableCell-head': {
        fontFamily: theme.typography.fontFamily,
        '&.MuiTableCell-stickyHeader': {
          paddingTop: '0px',
          '&:nth-child(2), &:nth-child(3)': {
            paddingBottom: '0px'
          }
        }
      },
      '& .MuiTableCell-root.MuiTableCell-body': {
        backgroundColor: theme.palette.common.white,
        borderBottom: ({ categoriesLength }) => (categoriesLength > 0 ? `1px solid ${theme.palette.divider}` : 'none'),
        borderRight: 'none',
        paddingTop: theme.spacing(0.42),
        paddingBottom: theme.spacing(0.42),
        fontFamily: theme.typography.fontFamily
      }
    }
  },
  noCategoriesMessage: {
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.h3.fontFamily,
    fontSize: theme.typography.h3.fontSize,
    letterSpacing: theme.typography.h3.letterSpacing,
    display: 'flex',
    justifyContent: 'center'
  },
  dialogActions: {
    marginTop: '20px',
    justifyContent: 'space-between',
    '& [data-testid="cancelBtn"]': {
      color: theme.palette.primary.main
    },
    '& [data-testid="confirmBtn"]': {
      height: '36px',
      width: '96px'
    },
    '& button': {
      fontWeight: 'bold'
    }
  },
  dialogTitle: {
    display: 'inline-block',
    color: theme.palette.text.primary,
    fontFamily: theme.typography.h3.fontFamily,
    fontSize: theme.typography.h2.fontSize,
    padding: '0px 20px 10px 0px',
    marginTop: '-6px',
    verticalAlign: 'middle'
  },
  dialogTitleBlock: {
    justifyContent: 'space-between',
    display: 'flex'
  },
  dialog: {
    fontFamily: theme.typography.h3.fontFamily,
    '& [role="dialog"]': {
      padding: '23px 24px 24px 24px',
      height: '241px',
      width: '437px',
      borderRadius: '4px',
      lineHeight: '22px'
    }
  },
  dialogContent: {
    width: '383px',
    height: '66px',
    padding: '0',
    fontSize: theme.typography.h3.fontSize,
    fontFamily: theme.typography.fontFamily,
    paddingLeft: '5px',
    paddingRight: '1px',
    margin: 'auto'
  },
  inlineBlock: {
    display: 'inline-block'
  },
  titlePadding: {
    padding: '10px 20px 10px 20px'
  }
}));

const getDistinctEntityCount = (category: CategoryFromApi) => {
  const entitySet = new Set();
  category.accounts.forEach((account) => {
    account.entityIds.forEach((entityId) => {
      entitySet.add(entityId);
    });
  });
  return entitySet.size;
};

const DataCategories = () => {
  const [currentRow, setCurrentRow]: any = useState({});
  const categoriesData = useSelector(selectCategories);
  const { deleteCategory } = useCategoryDelete();
  const { currentContainer } = useContainers();
  const classes = useStyles({ categoriesLength: categoriesData.categories.length });
  const { t } = useTranslation();

  const columns = [
    {
      field: 'name',
      headerName: t('Category'),
      width: '23%',
      filterable: true,
      sortable: true,
      placeholder: t('Category'),
      renderCell: (row: Row) => {
        const category = categoriesData.categories.find((category) => category.categoryId === row.id);
        return <ColorCategoryName color={category?.color ?? '#000'} text={row.name ?? ''} />;
      }
    },
    {
      field: 'taggedItems',
      headerName: t('Tagged Items'),
      isNumber: true,
      width: '50%'
    },
    {
      field: 'entities',
      headerName: t('Entities'),
      isNumber: true,
      width: '20%'
    }
  ];

  const rows = useMemo(
    () =>
      categoriesData.categories.map((category: CategoryFromApi) => ({
        id: category.categoryId,
        name: category.name,
        taggedItems: category.accounts.length,
        entities: getDistinctEntityCount(category)
      })),
    [categoriesData]
  );

  const [isCategoryDrawerOpen, setIsCategoryDrawerOpen] = useState<boolean>(false);
  const [isDeleteCategoryOpen, setIsDeleteCategoryOpen] = useState<boolean>(false);
  const [isEditCategoryDrawerOpen, setIsEditCategoryDrawerOpen] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const toggleCategoryDrawer = () => {
    setIsCategoryDrawerOpen((isOpen) => !isOpen);
  };

  const handleClose = useCallback(() => {
    setIsCategoryDrawerOpen(false);
  }, []);

  const toggleEditCategoryDrawer = (row: Row) => {
    setCurrentRow(row);
    setIsEditCategoryDrawerOpen((isOpen) => !isOpen);
  };

  const toggleDeleteCategoryModal = (row: Row) => {
    setCurrentRow(row);
    setIsDeleteCategoryOpen((isOpen) => !isOpen);
  };

  const handleCloseEditCategoryDrawer = useCallback(() => {
    setIsEditCategoryDrawerOpen(false);
  }, []);

  return isUpdating || categoriesData.isLoading ? (
    <LoadingSpinner />
  ) : (
    <Box className={classes.categoryTableContainer}>
      <ConfirmationDialog
        open={isDeleteCategoryOpen}
        setOpen={setIsDeleteCategoryOpen}
        dialogTitle={t('Delete Category')}
        dialogBody={t(
          'This category will be deleted from this Tax Period and any categorized accounts will no longer have the category association.'
        )}
        injectedClasses={{
          dialogActions: classes.dialogActions,
          dialog: classes.dialog,
          dialogContent: classes.dialogContent
        }}
        renderDialogTitle={() => {
          return (
            <div className={classes.dialogTitleBlock}>
              <div className={classes.inlineBlock}>
                <div className={[classes.inlineBlock, classes.titlePadding].join(' ')}>
                  <Delete />
                </div>

                <Typography className={classes.dialogTitle} id="alert-dialog-title">
                  {t('Delete Category')}
                </Typography>
              </div>
              <div className={classes.inlineBlock}>
                <IconButton role="button" onClick={toggleDeleteCategoryModal}>
                  <img src="/imgs/panel_close.svg" />
                </IconButton>
              </div>
            </div>
          );
        }}
        onSubmit={async () => {
          await deleteCategory(currentRow.row.id);
        }}
      />
      <NewCategoryDrawer isOpen={isCategoryDrawerOpen} onClose={handleClose} />
      <EditCategoryDrawer
        isOpen={isEditCategoryDrawerOpen}
        currentRow={currentRow}
        currentContainer={currentContainer}
        setIsUpdating={setIsUpdating}
        onClose={handleCloseEditCategoryDrawer}
      />
      <TableWithComment
        displayCategoryActions
        className={classes.categoryTable}
        columns={columns}
        rows={rows}
        toggleCategoryDrawer={toggleCategoryDrawer}
        handleOnRowDelete={currentContainer?.isFinalized ? undefined : toggleDeleteCategoryModal}
        toggleEditCategoryDrawer={toggleEditCategoryDrawer}
      />
      {categoriesData.categories.length === 0 && (
        <Typography className={classes.noCategoriesMessage}>
          There are no categories to display. To create one, click the + Add Category button.
        </Typography>
      )}
    </Box>
  );
};

export default DataCategories;
