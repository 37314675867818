import { ComponentType, useState } from 'react';

import { Box } from '@material-ui/core';
import { WorkBook } from 'xlsx';

import MainPage from './components/MainPage/MainPage';
import TrialBalanceSpreadsheet from './components/Spreadsheet/TrialBalanceSpreadsheet';
import { TBUploadManagerProps } from './TBUploadManager';

import { useContainers } from '../../hooks';
import { useScript } from '../../hooks/useScript';
import theme from '../../theme';
import { useStyles } from '../FileUpload/FileUpload.styles';
import { stox } from '../FileUpload/utils';

const TBUploadManagerConnector = ({
  component: TBUploadManager
}: {
  component: ComponentType<TBUploadManagerProps>;
}) => {
  useScript('https://unpkg.com/x-data-spreadsheet@1.1.5/dist/xspreadsheet.js');
  const classes = useStyles();
  const { currentContainer } = useContainers();
  const [workbook, setWorkbook] = useState<WorkBook>({} as WorkBook);

  const workbookWasUploaded = workbook && Object.values(workbook).length > 0;
  const stoxOutput = stox(workbook);

  return (
    <TBUploadManager
      containerName={currentContainer?.containerName ?? ''}
      workbookWasUploaded={workbookWasUploaded}
      setWorkbook={setWorkbook}
    >
      {workbookWasUploaded ? (
        <Box className={classes.spreadsheetBox}>
          <TrialBalanceSpreadsheet
            height="90%"
            width="99%"
            data={stoxOutput?.formattedWorkbook}
            options={{
              mode: 'read',
              showToolbar: false,
              showGrid: true,
              showContextmenu: false,
              col: { indexWidth: 38, width: 130, len: stoxOutput?.maxRowLength },
              row: { len: stoxOutput?.maxColLength },
              style: { font: { name: 'Basis Grotesque Pro Medium', size: 10 }, color: theme.palette.text.dark }
            }}
            workbook={workbook}
          />
        </Box>
      ) : (
        <MainPage setWorkbook={setWorkbook} />
      )}
    </TBUploadManager>
  );
};

export default TBUploadManagerConnector;
