import { Provision } from '../../components/UserRoleStylesProvider/constants';
import { ConfigurationStateInterface } from '../../redux/configuration';
import { selectConfiguration } from '../../selectors/configuration';
import store from '../../store';

class LocationService {
  login() {
    const {
      loginStackRelativeUrl,
      xbsEnvironmentShort,
      exacteraDomainUrl
    }: ConfigurationStateInterface = selectConfiguration(store.getState());
    if (!loginStackRelativeUrl) {
      return;
    }

    localStorage.clear();
    const { pathname, search, hash, origin, hostname } = window.location;
    const productUuid = Provision.Uuid;
    localStorage.setItem('productUuid', productUuid);
    if (hostname === 'localhost' || hostname.includes('exactera')) {
      window.location.replace(
        `${origin}/${loginStackRelativeUrl}${`?redirect_url=${encodeURIComponent(pathname + search + hash)}`}`
      );
    } else {
      const redirectUrl =
        xbsEnvironmentShort === 'prod'
          ? `https://pro.${exacteraDomainUrl}`
          : `https://pro.${xbsEnvironmentShort}.${exacteraDomainUrl}`;
      window.location.replace(redirectUrl);
    }
  }

  logout() {
    localStorage.clear();
    window.location.assign('/');
  }
}

export default LocationService;
