import { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { Box, makeStyles, Typography } from '@material-ui/core';

import { PeriodSelector, Differences, GroupBarChart, createTableRows, cannotRender } from './';

import { Container, TrendAnalysis, TrendAnalysisValue } from '../../../models';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    overflow: 'auto',
    flexGrow: 1
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    gap: theme.spacing(1),
    height: '100%'
  },
  dropdown: {
    display: 'flex'
  },
  versusWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: theme.spacing(0, 1)
  },
  versusText: {
    marginBottom: theme.spacing(0.5),
    textAlign: 'center',
    borderRadius: '50%',
    background: theme.palette.secondary.main,
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightBold,
    width: '2em',
    height: '2em',
    lineHeight: '1.8em'
  }
}));

type Props = {
  currentPeriod?: Container;
  previousPeriod?: Container;
  periods?: Container[];
  baselineTrendAnalysis?: TrendAnalysis;
  comparisonTrendAnalysis?: TrendAnalysis;
  selectedBaseContainerId?: string;
  selectedPreviousContainerId?: string;
  handleChange(val: Container, dropdown: string): void;
};

const TrendChart = ({
  currentPeriod,
  previousPeriod,
  periods,
  baselineTrendAnalysis,
  comparisonTrendAnalysis,
  handleChange,
  selectedBaseContainerId,
  selectedPreviousContainerId
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [unableToRender, setUnableToRender] = useState(false);
  const [rows, setRows] = useState<TrendAnalysisValue[]>([]);
  useEffect(() => {
    const cantRender = cannotRender(baselineTrendAnalysis, comparisonTrendAnalysis);
    setUnableToRender(cantRender);
    const rowsForChart =
      // redundant checks needed for type narrowing
      cantRender || baselineTrendAnalysis === undefined || comparisonTrendAnalysis === undefined
        ? []
        : createTableRows(t, baselineTrendAnalysis, comparisonTrendAnalysis);
    setRows(rowsForChart);
  }, [t, baselineTrendAnalysis, comparisonTrendAnalysis]);

  if (!previousPeriod && currentPeriod) {
    previousPeriod = currentPeriod;
  }

  return rows ? (
    <Box className={classes.root}>
      <Box className={classes.wrapper}>
        <Box className={classes.dropdown}>
          <PeriodSelector
            isBase
            title="Baseline Period"
            periods={periods}
            defaultPeriod={currentPeriod}
            currentPeriod={currentPeriod}
            handleChange={handleChange}
            selectedBaseContainerId={selectedBaseContainerId}
            selectedPreviousContainerId={selectedPreviousContainerId}
          />
          <Box className={classes.versusWrapper}>
            <Typography variant="body1" className={classes.versusText}>
              {t('vs')}
            </Typography>
          </Box>
          <PeriodSelector
            title="Comparison Period"
            periods={periods}
            defaultPeriod={previousPeriod}
            currentPeriod={currentPeriod}
            handleChange={handleChange}
            selectedBaseContainerId={selectedBaseContainerId}
            selectedPreviousContainerId={selectedPreviousContainerId}
          />
        </Box>
        {unableToRender ? <h2>No Data Available.</h2> : <GroupBarChart rows={rows} />}
        {unableToRender ? null : <Differences rows={rows} />}
      </Box>
    </Box>
  ) : null;
};

export default TrendChart;
