import { createSelector } from '@reduxjs/toolkit';

import { selectEntitiesWithRates } from './entities';

import { Currency } from '../models';
import { RootState } from '../redux';
import { turnToObjByKey } from '../utils';

export const selectCurrencies = (state: RootState) => state.currencies;
export const selectCurrenciesList = (state: RootState) => state.currencies.currencies;

export const selectEntityCurrencyIsoCode = (entityId: string) => (state: RootState) => {
  const entity = state.entities.entities.find((entity) => entity.entityId === entityId);
  if (entity === undefined) {
    return;
  }

  const { currencyId } = entity;
  const currency = state.currencies.currencies.find((currency) => currency.currencyId === currencyId)!;

  return currency.isoCode;
};

export const selectCurrencyById = createSelector(selectCurrencies, (currencies) => {
  return turnToObjByKey(currencies.currencies, 'currencyId');
});

export const selectEntityCurrencies = createSelector(
  selectCurrencyById,
  selectEntitiesWithRates,
  (currencyById, entities) => {
    if (Object.keys(currencyById).length === 0) {
      return [];
    }

    const entityCurrenciesMap: Record<Currency['currencyId'], Currency> = {};
    for (const { currencyId } of entities) {
      entityCurrenciesMap[currencyId] = currencyById[currencyId];
    }

    const currencies = Object.values(entityCurrenciesMap);

    return currencies;
  }
);

export const selectCurrenciesByEntityId = createSelector(
  selectCurrencyById,
  selectEntitiesWithRates,
  (currencyById, entities) => {
    if (Object.keys(currencyById).length === 0) {
      return {};
    }

    const currencyByEntityIdMap: Record<string, Currency> = {};
    for (const entity of entities) {
      currencyByEntityIdMap[entity.entityId] = currencyById[entity.currencyId];
    }

    return currencyByEntityIdMap;
  }
);
