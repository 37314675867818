import { useMemo, useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Box } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { LEVELS } from '../../../../constants';
import { FederalOrStateProps, Level, Step } from '../../../../models';
import { TaxPeriodRow } from '../../../EntityDetails/models';
import {
  getTableDataFromFinancialData,
  handleEditRowForEntityDetails,
  handleOnCellOrCommentBlurForEntityDetails
} from '../../../EntityDetails/utils';
import TableWithComment from '../../../TableWithComment';

const BeginningBalance = ({
  entityId,
  currentStateId,
  failedCells,
  isFederal = true,
  stepCompletionStatus,
  tabsData
}: FederalOrStateProps) => {
  const LEVEL: Level = isFederal ? LEVELS.FEDERAL : LEVELS.STATE;
  const STEP: Step = useMemo(() => 'accounts', []);
  const LevelAndStep = useMemo(() => `${LEVEL as string}.${STEP as string}`, [LEVEL, STEP]);

  const { t } = useTranslation();
  const [rows, setRows] = useState<TaxPeriodRow[]>([]);
  const dispatch = useDispatch();
  const financialInfo = useMemo(() => tabsData[LevelAndStep] ?? [], [LevelAndStep, tabsData]);
  const { prov2830AddAccountsRows, prov3322EditPerformanceFix: isPerformanceFixEnabled } = useFlags();

  const rowNames = useMemo(() => {
    const rowPrefix = isFederal ? 'Federal' : 'State';
    const existingRows = [
      {
        name: `${rowPrefix} Current Tax Expense / (Benefit)`,
        creditName: 'beginningBalance',
        jurisdictionId: currentStateId
      },
      {
        name: `${rowPrefix} Deferred Tax Expense / (Benefit)`,
        creditName: 'beginningBalance',
        jurisdictionId: currentStateId
      },
      {
        name: `${rowPrefix} Current Tax Receivable / (Payable)`,
        creditName: 'beginningBalance',
        jurisdictionId: currentStateId
      },
      {
        name: `${rowPrefix} Deferred Tax Asset / (Liability)`,
        creditName: 'beginningBalance',
        jurisdictionId: currentStateId
      }
    ];

    const newRows = [
      {
        name: 'FIN48',
        creditName: 'beginningBalance',
        jurisdictionId: currentStateId
      },
      {
        name: 'OCI',
        creditName: 'beginningBalance',
        jurisdictionId: currentStateId
      },
      {
        name: 'Goodwill',
        creditName: 'beginningBalance',
        jurisdictionId: currentStateId
      }
    ];

    if (isFederal) {
      existingRows.push(
        {
          name: 'Cash',
          creditName: 'beginningBalance',
          jurisdictionId: currentStateId
        },
        {
          name: 'Other Balance Sheet',
          creditName: 'beginningBalance',
          jurisdictionId: currentStateId
        }
      );
    }

    return prov2830AddAccountsRows ? [...existingRows, ...newRows] : existingRows;
  }, [currentStateId, isFederal, prov2830AddAccountsRows]);

  const rowsWithData = useMemo(() => getTableDataFromFinancialData(rowNames, financialInfo), [
    financialInfo,
    rowNames
  ]) as TaxPeriodRow[];

  useEffect(() => {
    setRows(rowsWithData);
  }, [rowsWithData]);

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: t('Beginning Balance')
      },
      {
        field: 'amount',
        headerName: t('Amount'),
        isEditable: !stepCompletionStatus,
        isNumber: true
      }
    ],
    [stepCompletionStatus, t]
  );

  const dataForMethods = {
    columns,
    creditName: 'beginningBalance',
    dispatch,
    entityId,
    financialInfo,
    jurisdictionId: currentStateId,
    level: LEVEL,
    rows,
    setRows,
    step: STEP,
    t
  };

  return (
    <Box style={{ overflow: 'visible' }}>
      <TableWithComment
        columns={columns}
        failedCells={failedCells}
        rows={rows}
        hideActionsMenu={stepCompletionStatus}
        onCellChange={(params) => {
          if (!isPerformanceFixEnabled) {
            handleEditRowForEntityDetails({
              ...dataForMethods,
              ...params
            });
          }
        }}
        onCellOrCommentBlur={(params) => {
          if (isPerformanceFixEnabled) {
            handleEditRowForEntityDetails({
              ...dataForMethods,
              ...params
            });
          }

          handleOnCellOrCommentBlurForEntityDetails({
            ...dataForMethods,
            ...params
          });
        }}
      />
    </Box>
  );
};

export default BeginningBalance;
