import React from 'react';

import { useSelector } from 'react-redux';

import { Box, makeStyles, Card } from '@material-ui/core';

import { selectTraceabilityColumn } from '../../../../../../selectors/traceability';
import { formatCurrency, formatPercentage } from '../../../../../../utils';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.common.white,
    height: '95px',
    padding: theme.spacing(1.5, '32px'),
    position: 'sticky',
    top: 0,
    zIndex: 10,
    '& .MuiChip-root': {
      marginBottom: '16px',
      paddingLeft: '4px',
      maxWidth: '80%'
    },
    '& .MuiChip-label': {
      overflow: 'scroll',
      textOverflow: 'clip'
    }
  },
  boxContainer: {
    padding: theme.spacing(1, 0),
    display: 'flex',
    textAlign: 'right',
    alignItems: 'flex-end',

    '& > :first-child': {
      flexGrow: 1,
      textAlign: 'left'
    }
  },
  accountNumber: {
    paddingLeft: '8px',
    paddingTop: '4px',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  'nameContainer--name': {
    backgroundColor: theme.palette.info.main,
    borderRadius: '4px'
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
    alignItems: 'flex-start'
  },
  name: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.info.main,
    borderRadius: '4px',
    color: theme.palette.primary.main,
    fontSize: '14px',
    lineHeight: '25px',
    maxWidth: '220px',
    height: '12px',
    margin: '8px',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  columnName: {
    ...theme.typography.subtitle1,
    color: theme.palette.text.secondary,
    paddingLeft: theme.spacing(1),
    fontSize: '12px',
    overflow: 'hidden',
    textTransform: 'uppercase'
  },
  amount: {
    padding: theme.spacing(0.25, 1.5),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.action.selected,
    fontWeight: theme.typography.h2.fontWeight,
    maxWidth: '290px',
    whiteSpace: 'pre',
    width: 'fit-content',
    height: '30px'
  },
  amountNumber: {
    fontSize: '20px',
    color: theme.palette.primary.main
  },
  amountContainer: {
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },
  closeIcon: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    position: 'absolute',
    top: '24px',
    right: '40px',
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  }
}));

interface TraceabilityContentHeaderProps {
  name: string;
  currency?: string;
  value: number;
  accountNumber?: string;
  format?: string;
}

interface FormatHeaderParams {
  format?: string;
  currency?: string;
  value: number;
  fixedValue: string | null;
}

const formatHeaderValue = ({ format, currency, value, fixedValue }: FormatHeaderParams) => {
  return format === 'percentage' ? formatPercentage(value) : formatCurrency(fixedValue, currency);
};

const TraceabilityContentHeader = ({
  name,
  currency,
  value,
  accountNumber,
  format
}: TraceabilityContentHeaderProps) => {
  const classes = useStyles();
  const fixedValue = typeof value === 'number' ? value.toFixed(2) : null;
  const amountNumber = formatHeaderValue({ format, currency, value, fixedValue });
  const accountNumberString = accountNumber && `Account Number: ${accountNumber}`;
  const traceabiltyColumn = useSelector(selectTraceabilityColumn);
  const columnName = traceabiltyColumn?.headerName;

  return (
    <Card className={classes.cardContainer} elevation={0}>
      <Box>
        <Box className={classes.nameContainer}>
          <Box className={classes['nameContainer--name']}>
            <Box className={classes.name}>{name}</Box>
          </Box>
          {accountNumber && <Box className={classes.accountNumber}>{accountNumberString}</Box>}
        </Box>
      </Box>
      <Box className={classes.amountContainer}>
        {columnName && <Box className={classes.columnName}>{columnName}</Box>}
        <Box className={classes.amountNumber}>{amountNumber}</Box>
      </Box>
    </Card>
  );
};

export default React.memo(TraceabilityContentHeader);
