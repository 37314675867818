import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.action.hover,
    padding: theme.spacing(0.5, 1.5)
  }
}));

interface Props {
  id: string;
  name: string;
}

const EntityTableHeader = ({ id, name }: Props) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      {id}
      <br />
      <Box fontWeight="fontWeightBold">{name}</Box>
    </Box>
  );
};

export default EntityTableHeader;
