import { SvgIcon } from '@material-ui/core';

const ArrowIcon = (props: any) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Final-Screens" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="TaxPro-Entity-Details" transform="translate(-1190.000000, -246.000000)">
          <g id="Icon/ArrowMove" transform="translate(1190.000000, 246.000000)">
            <polygon id="Path" points="0 0 16 0 16 16 0 16" />
            <polygon
              id="Path"
              fill="#677283"
              points="4.74333333 12.3893333 5.74588219 13.3333333 11.41 8 5.74588219 2.66666667 4.74333333 3.61066667 9.40490229 8"
            />
          </g>
        </g>
      </g>
    </svg>
  </SvgIcon>
);

export default ArrowIcon;
