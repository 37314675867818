import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import {
  financialDataInitFailedCells,
  financialDataInitState,
  financialDataOnDeleted,
  financialDataOnFailed,
  financialDataOnPosted,
  financialDataOnReceived,
  financialDataOnUploadSendingRequest,
  financialDataOnInitialSendingRequest,
  financialDataOnUpdated,
  financialDataOnUpdatedFailedCells,
  financialDataDeleteStateRTPTaxProvisionCol
} from './financialData.actions';
import { financialDataInitialState } from './financialData.initialState';

import { FailedCells, FailedCellsNoCredit, FailedCellsWithCredit } from '../../components/Table/Table.proptype';
import { FinancialDataOnUpdatedFailedCells, FinancialInfo, FinancialInfoTabsData } from '../../models';
import { containersOnCurrentSwitched } from '../containers';
import { addSubJurisdictionsToEntity } from '../entities';
import { uploadReviewFinishOnSuccess, uploadReviewOnFailed } from '../uploadReview';

const handleFailedCellsStateNoCredit = ({
  columnNames,
  didFail = false,
  failedReasons,
  rowNames,
  failedCellsState
}: FinancialDataOnUpdatedFailedCells & {
  failedCellsState: FailedCellsNoCredit;
}) => {
  if (rowNames && columnNames) {
    const updatedFailedCells = {
      ...failedCellsState
    };

    rowNames.forEach((rowName, index) => {
      updatedFailedCells[rowName] = {
        ...failedCellsState[rowName],
        ...updatedFailedCells[rowName],
        [columnNames[index]]: failedReasons?.[index] ?? didFail
      };
    });

    return updatedFailedCells;
  }

  return failedCellsState;
};

const handleFailedCellsState = (
  props: FinancialDataOnUpdatedFailedCells & {
    failedCellsState: FailedCells;
  }
): FailedCells => {
  const { creditName, failedCellsState, ...propsNoCreditName } = props;
  return creditName
    ? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      ({
        ...failedCellsState,
        [creditName]: handleFailedCellsStateNoCredit({
          ...propsNoCreditName,
          failedCellsState: (failedCellsState[creditName] ?? {}) as FailedCellsNoCredit
        })
      } as FailedCellsWithCredit)
    : handleFailedCellsStateNoCredit({
        ...propsNoCreditName,
        failedCellsState: failedCellsState as FailedCellsNoCredit
      });
};

export const financialDataReducer = createReducer(financialDataInitialState, (builder) => {
  builder
    .addCase(financialDataOnUploadSendingRequest, (state) => {
      return { ...state, isUploadLoading: true, error: null };
    })
    .addCase(financialDataOnInitialSendingRequest, (state) => {
      return { ...state, isFetchLoading: true, error: null };
    })
    .addCase(financialDataOnReceived, (state, action) => {
      const { tabsData, entityNumber } = action.payload;

      return {
        ...state,
        entityNumber,
        tabsData: { ...state.tabsData, ...tabsData },
        isUploadLoading: false,
        isFetchLoading: false,
        error: null
      };
    })
    .addCase(financialDataOnPosted, (state, action) => {
      const updatedTabsData = { ...state.tabsData };
      action.payload.financialInfo.forEach((financialData: FinancialInfo) => {
        const levelAndStep = `${financialData.level}.${financialData.step}`;

        updatedTabsData[levelAndStep] = updatedTabsData[levelAndStep]
          ? [...updatedTabsData[levelAndStep], { ...financialData }]
          : [{ ...financialData }];
      });

      const rowNames = action.payload.financialInfo.map((item) => item.rowName);
      const columnNames = action.payload.financialInfo.map((item) => item.columnName);

      return {
        ...state,
        tabsData: updatedTabsData,
        failedCells: action.payload.shouldKeepError
          ? state.failedCells
          : handleFailedCellsState({ failedCellsState: state.failedCells, rowNames, columnNames }),
        isUploadLoading: false,
        error: null
      };
    })
    .addCase(financialDataOnUpdated, (state, action) => {
      const levelAndStep = `${action.payload.level}.${action.payload.step}`;

      const idx = state.tabsData[levelAndStep].findIndex(
        (item) => item.entityFinancialsTraceId === action.payload.entityFinancialsTraceId
      );

      return {
        ...state,
        tabsData: {
          ...state.tabsData,
          [levelAndStep]: [
            ...state.tabsData[levelAndStep].slice(0, idx),
            { ...state.tabsData[levelAndStep][idx], value: action.payload.value },
            ...state.tabsData[levelAndStep].slice(idx + 1)
          ]
        },
        failedCells: action.payload.shouldKeepError
          ? state.failedCells
          : handleFailedCellsState({
              failedCellsState: state.failedCells,
              rowNames: [action.payload.rowName],
              columnNames: [action.payload.columnName]
            }),
        isUploadLoading: false,
        error: null
      };
    })
    .addCase(financialDataOnDeleted, (state, action) => {
      const { level, step, rowName } = action.payload[0];
      const levelAndStep = `${level}.${step}`;

      const removedColumnNames = state.tabsData[levelAndStep]
        .filter((item) => item.rowName === rowName)
        .map((item) => item.columnName);

      const updatedFailedCells = handleFailedCellsState({
        failedCellsState: state.failedCells,
        rowNames: [rowName],
        columnNames: removedColumnNames
      });

      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete updatedFailedCells[rowName];

      const rowIdsToDeleteByLevelStep: Record<string, string[]> = {};
      for (const row of action.payload) {
        const { level, step, rowId } = row;
        const levelAndStep = `${level}.${step}`;
        const idsForLevelStep = rowIdsToDeleteByLevelStep[levelAndStep] ?? [];
        rowIdsToDeleteByLevelStep[levelAndStep] = [...idsForLevelStep, rowId];
      }

      const updatedFinancials: FinancialInfoTabsData = {};
      for (const [levelAndStep, rowIdsToDelete] of Object.entries(rowIdsToDeleteByLevelStep)) {
        updatedFinancials[levelAndStep] = state.tabsData[levelAndStep].filter(
          (finData) => !rowIdsToDelete.includes(finData.rowId)
        );
      }

      return {
        ...state,
        tabsData: {
          ...state.tabsData,
          ...updatedFinancials
        },
        failedCells: updatedFailedCells,
        isUploadLoading: false,
        error: null
      };
    })
    .addCase(financialDataDeleteStateRTPTaxProvisionCol, (state) => {
      const preservedRowNames = new Set([
        'Apportionment Factor',
        'Prior Year State Tax Rate',
        'Federal Taxable Income/(Loss) Before State Tax and NOL'
      ]);

      for (const [levelAndStep] of Object.entries(state.tabsData)) {
        for (const finData of state.tabsData[levelAndStep]) {
          if (
            !preservedRowNames.has(finData.rowName) &&
            finData.columnName === 'taxProvision' &&
            finData.level === 'state' &&
            finData.step === 'rtp'
          ) {
            finData.value = 0;
          }
        }
      }
    })

    .addCase(financialDataOnUpdatedFailedCells, (state, action) => {
      return {
        ...state,
        failedCells: handleFailedCellsState({ failedCellsState: state.failedCells, ...action.payload })
      };
    })
    .addCase(financialDataOnFailed, (state, action) => {
      const { error, rowNames, columnNames, creditName } = action.payload;

      return {
        ...state,
        isFetchLoading: false,
        isUploadLoading: false,
        error,
        failedCells: handleFailedCellsState({
          failedCellsState: state.failedCells,
          rowNames,
          columnNames,
          creditName,
          didFail: true
        })
      };
    })
    .addCase(financialDataInitFailedCells, (state) => {
      return {
        ...state,
        failedCells: {}
      };
    })
    .addMatcher(
      isAnyOf(
        financialDataInitState,
        uploadReviewFinishOnSuccess,
        uploadReviewOnFailed,
        containersOnCurrentSwitched,
        addSubJurisdictionsToEntity.fulfilled
      ),
      () => {
        return financialDataInitialState;
      }
    );
});
