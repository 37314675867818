import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useRates } from '..';
import { JurisdictionFromApi } from '../../models';
import { onJurisdictionsFailed, onJurisdictionsReceived, onFetchingJurisdictions } from '../../redux/jurisdictions';
import {
  selectJurisdictions,
  selectJurisdictionsWithRates,
  selectJurisdictionsWithRatesById,
  selectSubJurisdictionsWithRatesById,
  selectEntityJurisdictions
} from '../../selectors/jurisdictions';
import HTTPService, { LambdaResponse } from '../../services/http';

export function useJurisdictions() {
  const dispatch = useDispatch();
  useRates();
  const jurisdictionsState = useSelector(selectJurisdictions);
  const jurisdictionsWithRates = useSelector(selectJurisdictionsWithRates);
  const jurisdictionsWithRatesById = useSelector(selectJurisdictionsWithRatesById);
  const subJurisdictionsWithRatesById = useSelector(selectSubJurisdictionsWithRatesById);
  const entityJurisdictions = useSelector(selectEntityJurisdictions);

  useEffect(() => {
    async function fetchData() {
      dispatch(onFetchingJurisdictions());

      try {
        const { data: jurisdictions } = await HTTPService.request<LambdaResponse<JurisdictionFromApi[]>>({
          method: 'get',
          relativePath: `/v1/jurisdictions`
        });

        dispatch(onJurisdictionsReceived(jurisdictions));
      } catch (error: unknown) {
        dispatch(onJurisdictionsFailed(error));
      }
    }

    if (!jurisdictionsState.isLoading && !jurisdictionsState.hasLoaded && !jurisdictionsState.error) {
      void fetchData();
    }
  }, [jurisdictionsState, dispatch]);

  return {
    ...jurisdictionsState,
    jurisdictions: jurisdictionsWithRates,
    jurisdictionById: jurisdictionsWithRatesById,
    subJurisdictionById: subJurisdictionsWithRatesById,
    entityJurisdictions
  };
}
