import { useEffect, useState } from 'react';

import { Button, Checkbox, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { EulaProps } from './Eula.interface';
import EulaText from './EulaText';

const useStyles = makeStyles(() => ({
  agreeButton: {
    color: 'white',
    backgroundColor: '#C84091',
    opacity: 0.9,
    height: 50,
    width: '100%',
    borderRadius: '20px',
    '&.MuiButton-root:hover': {
      backgroundColor: '#C84091',
      opacity: 1
    }
  },
  checkbox: {
    color: '#C84091',
    '&.Mui-checked': {
      color: '#C84091'
    }
  },
  eulaContainer: {
    height: '80vh',
    padding: '3% 25%',
    color: 'white',
    zIndex: 1,
    borderRadius: '.25rem'
  },
  submitButton: {
    backgroundColor: '#C84091',
    color: 'white',
    fontWeight: 'lighter',
    paddingLeft: '4rem',
    paddingRight: '4rem',
    height: '50px',
    borderRadius: '10px'
  },
  checkLicense: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  root: {
    position: 'absolute',
    backgroundColor: 'black',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const ErrorMessage = ({ error, message }: { error: boolean; message: string }) =>
  error ? (
    <Typography color="error" variant="caption">
      {message}
    </Typography>
  ) : null;

const Eula = ({ onAccept }: EulaProps) => {
  const [isEulaAccepted, setIsEulaAccepted] = useState(false);
  const [shouldShowError, setShouldShowError] = useState(false);
  const classes = useStyles();

  const handleSubmit = () => {
    if (isEulaAccepted) {
      onAccept();
    } else {
      setShouldShowError(true);
    }
  };

  const handleCheckboxToggle = () => {
    setIsEulaAccepted((value) => !value);
  };

  useEffect(() => {
    if (isEulaAccepted) {
      setShouldShowError(false);
    }
  }, [isEulaAccepted]);

  return (
    <div className={classes.root}>
      <div className={classes.eulaContainer}>
        <EulaText />
        <div className={classes.checkLicense}>
          <Checkbox checked={isEulaAccepted} className={classes.checkbox} onChange={handleCheckboxToggle} />
          <Typography>
            By selecting this check box and clicking on the button below you agree to this License Agreement
          </Typography>
        </div>
        <Button className={classes.agreeButton} onClick={handleSubmit}>
          I Agree
        </Button>
        <ErrorMessage
          error={shouldShowError}
          message="You must accept and agree to the End User License Agreement by checking the check box above."
        />
      </div>
    </div>
  );
};

export default Eula;
