import { useSelector } from 'react-redux';

import { ConnectorProps } from '../../../../../../models';
import { selectEntityCurrencyIsoCode } from '../../../../../../selectors';
import {
  selectTraceabilityIsLoading,
  selectTraceabilityReport,
  selectTraceabilityRow
} from '../../../../../../selectors/traceability';
import LoadingWrapper from '../../../../../LoadingWrapper';

const TraceabilityReportConnector = ({ component: TraceabilityReport }: ConnectorProps) => {
  const report = useSelector(selectTraceabilityReport);
  const isLoading = useSelector(selectTraceabilityIsLoading);
  const value = report?.meta?.value ?? '';
  const entityId = report?.reportModelId ?? '';
  const currency = useSelector(selectEntityCurrencyIsoCode(entityId));
  const traceabilityRow = useSelector(selectTraceabilityRow);
  const name = traceabilityRow?.name ?? '';
  const accountNumber = report?.accountNumber;
  const format = report?.meta?.format ?? '';

  if (!report) {
    return null;
  }

  return (
    <LoadingWrapper isLoading={isLoading}>
      <TraceabilityReport
        name={name}
        report={report}
        currency={currency}
        value={value}
        accountNumber={accountNumber}
        format={format}
      />
    </LoadingWrapper>
  );
};

export default TraceabilityReportConnector;
