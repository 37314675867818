import { createMuiTheme } from '@material-ui/core/styles';

import { regular, bold, medium, headingRegular, headingBold } from './fonts';

const divider = '#E2E3E6'; // neutral - 90
const textSecondary = '#707983';
const spacing = 12;
const highlight = '#EFEFFF'; // TaxPro - P15
const primaryMain = '#232A5C'; // TaxPro - P100
const white = '#FFF'; // Core - Colour 0
const progressMain = '#F4CD52'; // Progress - 40
const progressLighter = '#FEF7E0'; // Progress - 15
const border = '#6E45DB'; // P active
const taxPro25Color = '#DCDBFE'; // TaxPro - P25

const theme = createMuiTheme({
  palette: {
    common: {
      black: '#000',
      white
    },
    primary: {
      light: '#F5F5F7', // neutral 70,
      main: primaryMain,
      dark: '#8E949F', // neutral 100
      contrastText: white
    },
    secondary: {
      light: taxPro25Color,
      lighter: '#FAF9FA', // neutral 60
      main: taxPro25Color,
      dark: '#EFEFF2', // neutral 80
      contrastText: white
    },
    text: {
      primary: '#464D59', // Core - Colour 1
      secondary: '#677283', // Core - Colour 2
      dark: '#363636' // Core - Colour 3
    },
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    background: {
      paper: '#F5F5F5',
      comparison: '#F9F8FE' // P10
    } as { paper: string; comparison: string },
    action: {
      focus: border, // TaxPro - P Active
      hover: taxPro25Color
    },
    info: {
      main: highlight,
      dark: border
    },
    progress: {
      main: progressMain,
      lighter: progressLighter
    },
    error: {
      main: '#B00020' // Error 100
    },
    divider
  },
  spacing: (factor) => factor * spacing,
  typography: {
    fontFamily: '"Basis Grotesque Pro", sans-serif',
    fontSize: 14,
    button: {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '"normal"' is not assignable to type 'TextTra... Remove this comment to see the full error message
      textTransform: 'normal'
    },
    h1: {
      fontFamily: '"Basis Grotesque Pro Bold", sans-serif',
      fontSize: '20px',
      fontWeight: 'normal',
      letterSpacing: 0.15
    },
    h2: {
      fontSize: '18px',
      fontWeight: 'normal',
      letterSpacing: 0.15
    },
    h3: {
      fontFamily: '"Basis Grotesque Pro Medium", sans-serif',
      fontSize: '16px',
      fontWeight: 500,
      letterSpacing: 0.15
    },
    body1: {
      letterSpacing: 0.25,
      fontSize: '14px',
      fontWeight: 'normal'
    },
    body2: {
      fontFamily: '"Basis Grotesque Pro Medium", sans-serif',
      letterSpacing: 0.25,
      fontSize: '14px',
      fontWeight: 500
    },
    body3: {
      letterSpacing: 0.15,
      fontSize: '16px',
      fontWeight: 'normal'
    },
    caption: {
      fontFamily: '"Basis Grotesque Pro Medium", sans-serif',
      fontSize: '12px',
      fontWeight: 500,
      letterSpacing: 0.65
    },
    button1: {
      fontSize: '14px',
      fontWeight: 'normal',
      letterSpacing: 0.2
    },
    button2: {
      fontFamily: '"Basis Grotesque Pro Medium", sans-serif',
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: 0.25
    },
    subtitle1: {
      color: textSecondary,
      fontWeight: 600
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [regular, bold, headingRegular, headingBold, medium]
      }
    },
    MuiButton: {
      root: {
        borderRadius: '2px'
      },
      contained: {
        boxShadow: 'none'
      },
      outlined: {
        height: '2.25rem',
        color: primaryMain,
        border: `0.063rem solid ${primaryMain}`,
        borderRadius: '0.25rem',
        fontWeight: 500,
        letterSpacing: '0.013rem',
        lineHeight: '1.12rem'
      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: '1px'
      }
    },
    MuiMobileStepper: {
      root: {
        justifyContent: 'center'
      }
    },
    MuiTabs: {
      root: {
        borderBottom: `1px solid ${divider}`,

        '& .MuiTab-root.Mui-selected': {
          fontWeight: 600,
          backgroundColor: highlight,
          '&:after': {
            borderLeftColor: highlight
          }
        }
      }
    },
    MuiCheckbox: {
      colorSecondary: {
        '&.Mui-checked': {
          color: primaryMain
        }
      }
    },
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ MuiCssBaseline: { '@global': { '@font-face... Remove this comment to see the full error message
    MuiAutocomplete: {
      inputRoot: {
        '&.MuiOutlinedInput-root': {
          padding: 0,
          '&[class*="MuiOutlinedInput-root"]': {
            '& .MuiAutocomplete-input:first-child': {
              padding: spacing
            },
            '& .MuiAutocomplete-endAdornment': {
              right: 0
            },
            '& .MuiAutocomplete-clearIndicator': {
              marginRight: spacing * 0.5
            },
            '& .MuiAutocomplete-popupIndicator': {
              marginLeft: 0
            }
          }
        }
      },
      hasPopupIcon: {
        '& .MuiAutocomplete-inputRoot.MuiOutlinedInput-root.MuiInputBase-root': {
          paddingRight: 30
        }
      }
    },
    MuiSelect: {
      outlined: {
        padding: `${spacing}px ${spacing * 2.5}px ${spacing}px ${spacing}px`
      }
    }
  }
});

export default theme;
