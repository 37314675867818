import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Skeleton from '@material-ui/lab/Skeleton';

import { EntityHeader, EntityProgress } from '.';

import { EntityWithJurisdictionName } from '../../../models';
import { selectEntitiesIsLoading } from '../../../selectors';
import InViewObserverWrapper from '../../InViewObserverWrapper';
import LoadingWrapper from '../../LoadingWrapper';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  progressContainer: {
    flexGrow: 1,
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: theme.spacing(1.5),
    gap: theme.spacing(1),
    background: theme.palette.primary.light
  }
}));

interface EntityListProps {
  entities: EntityWithJurisdictionName[];
}

const EntityList = ({ entities }: EntityListProps) => {
  const classes = useStyles();
  const isLoading = useSelector(selectEntitiesIsLoading);

  const LoadingSkeleton = <Skeleton animation="wave" variant="rect" width="100%" height={87} />;

  return (
    <Box className={classes.root}>
      <EntityHeader tab="entities" />
      <LoadingWrapper isLoading={isLoading}>
        <Box className={classes.progressContainer}>
          {entities.map((entity) => (
            <InViewObserverWrapper key={entity.entityId} fallback={LoadingSkeleton}>
              <EntityProgress source={entity} />
            </InViewObserverWrapper>
          ))}
        </Box>
      </LoadingWrapper>
    </Box>
  );
};

export default EntityList;
