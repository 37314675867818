import React from 'react';

import { Button, SvgIconProps } from '@material-ui/core';

import ConfirmationDialog from '../ConfirmationDialog';

interface Props {
  buttonClass?: string;
  buttonIcon?: React.ReactElement<SvgIconProps>;
  buttonName?: string;
  dialogTitle: string;
  dialogBody?: string;
  onSubmit: () => void;
}

const ConfirmationDialogWithButtons = ({
  buttonClass,
  buttonName,
  buttonIcon,
  dialogTitle,
  dialogBody,
  onSubmit
}: Props) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Button className={buttonClass} aria-label="dialog button" onClick={handleClickOpen}>
        {buttonName}
        {buttonIcon}
      </Button>
      <ConfirmationDialog
        setOpen={setOpen}
        dialogTitle={dialogTitle}
        dialogBody={dialogBody}
        open={open}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default ConfirmationDialogWithButtons;
