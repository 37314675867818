import { createSelector } from '@reduxjs/toolkit';

import { selectEntitiesWithRates } from './entities';
import { selectJurisdictionRates } from './rates';

import { PeriodRates, Jurisdiction, Rates, SubJurisdiction } from '../models';
import { RootState } from '../redux';
import { turnToObjById } from '../utils';

export const selectJurisdictions = (state: RootState) => state.jurisdictions;
export const selectJurisdictionsList = (state: RootState) => state.jurisdictions.jurisdictions;

interface JurisdictionRates {
  defaultTax: Rates['defaultRates'];
  customTax: Rates['customRates'];
  defaultExchange: Rates['defaultRates'];
  customExchange: Rates['customRates'];
}

const getRatesForJurisdiction = (
  rates: JurisdictionRates,
  jurisdictionId: string,
  currencyId: number | null,
  statutoryRatesFlag: boolean
) => {
  const defaultTaxTypePeriodRates = rates.defaultTax[jurisdictionId] as PeriodRates;
  const defaultTax = rates.defaultTax[jurisdictionId] as { taxRate: number };
  const defaultTaxFlagStrategy = statutoryRatesFlag
    ? defaultTaxTypePeriodRates
    : { beginning: null, current: defaultTax?.taxRate, end: defaultTax?.taxRate };

  const customTax = rates.customTax[jurisdictionId];
  const taxRates = {
    beginning: customTax?.beginning ?? defaultTaxFlagStrategy?.beginning,
    current: customTax?.current ?? defaultTaxFlagStrategy?.current,
    end: customTax?.end ?? defaultTaxFlagStrategy?.end
  };

  let foreignExchangeRates = {};
  if (currencyId) {
    const defaultExchange = rates.defaultExchange[currencyId] as PeriodRates;
    const customExchange = rates.customExchange[currencyId];
    foreignExchangeRates = {
      beginning: customExchange?.beginning ?? defaultExchange?.beginning,
      current: customExchange?.current ?? defaultExchange?.current,
      end: customExchange?.end ?? defaultExchange?.end
    };
  }

  return { taxRates, foreignExchangeRates };
};

export const selectJurisdictionsWithRates = createSelector(
  selectJurisdictions,
  selectJurisdictionRates,
  (jurisdictions, rates) => {
    if (!jurisdictions) {
      return [];
    }

    // cleanup flag prov-2711 prov-2869
    const statutoryRatesFlagLocalStorage = localStorage.getItem('statutoryRatesFlag');
    const statutoryRatesFlag =
      statutoryRatesFlagLocalStorage === 'undefined' ? false : JSON.parse(String(statutoryRatesFlagLocalStorage));
    const jurisdictionsWithRates: Jurisdiction[] = jurisdictions.jurisdictions.map((jur) => {
      const subJursWithRates: SubJurisdiction[] = jur.subJurisdictions.map((subjur) => {
        const { taxRates } = getRatesForJurisdiction(rates, subjur.id, null, statutoryRatesFlag);
        return { ...subjur, taxRates };
      });

      const { taxRates, foreignExchangeRates } = getRatesForJurisdiction(
        rates,
        jur.jurisdictionId,
        jur.currencyId,
        statutoryRatesFlag
      ) as {
        taxRates: PeriodRates;
        foreignExchangeRates: PeriodRates;
      };

      return {
        ...jur,
        taxRates,
        foreignExchangeRates,
        subJurisdictions: subJursWithRates
      };
    });

    return jurisdictionsWithRates;
  }
);

export const selectRatesForJurisdiction = (jurisdictionId: string, currencyId?: number) => (state: RootState) => {
  const rates = selectJurisdictionRates(state);
  if (Object.keys(rates.defaultTax).length === 0) {
    return {
      taxRates: { beginning: null, current: null, end: null },
      foreignExchangeRates: { beginning: null, current: null, end: null }
    };
  }

  // cleanup flag prov-2711 prov-2869
  const statutoryRatesFlagLocalStorage = localStorage.getItem('statutoryRatesFlag');
  const statutoryRatesFlag =
    statutoryRatesFlagLocalStorage === 'undefined' ? false : JSON.parse(String(statutoryRatesFlagLocalStorage));
  return getRatesForJurisdiction(rates, jurisdictionId, currencyId ?? null, statutoryRatesFlag);
};

export const selectJurisdictionsWithRatesById = createSelector(selectJurisdictionsWithRates, (jurisdictions) => {
  return turnToObjById(jurisdictions);
});

export const selectSubJurisdictionsWithRatesById = createSelector(selectJurisdictionsWithRates, (jurisdictions) => {
  const subJurisdictions = jurisdictions.flatMap(({ subJurisdictions }) => subJurisdictions);
  return turnToObjById(subJurisdictions);
});

export const selectEntityJurisdictions = createSelector(
  selectJurisdictionsWithRatesById,
  selectEntitiesWithRates,
  (jurisdictionById, entities) => {
    if (Object.keys(jurisdictionById).length === 0) {
      return [];
    }

    const entityJurisdictions: Record<string, Set<string>> = {};
    for (const { jurisdictionId, subJurisdictionIds } of entities) {
      const entityJur = entityJurisdictions[jurisdictionId] ?? [];
      entityJurisdictions[jurisdictionId] = new Set([...entityJur, ...subJurisdictionIds]);
    }

    const jurisdictions = Object.entries(entityJurisdictions).map(([key, value]: [string, Set<string>]) => {
      const jurisdiction = jurisdictionById[key];
      const subJurisdictions = jurisdiction.subJurisdictions.filter(({ id }: SubJurisdiction) => value.has(id));
      return { ...jurisdiction, subJurisdictions };
    });

    return jurisdictions;
  }
);
