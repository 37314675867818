import i18next from 'i18next';

const formats: { currencies: any; default: Intl.NumberFormat } = {
  currencies: new Map(),
  default: new Intl.NumberFormat()
};

function initFormats() {
  // since we don't display currency, we can not use the currencySign: 'accounting' option
  formats.default = new Intl.NumberFormat('en', {
    signDisplay: 'never'
  });
}

function getCurrencyFormat(currency: string) {
  let format = formats.currencies.get(currency);
  if (!format) {
    format = new Intl.NumberFormat(i18next.language, {
      style: 'currency',
      currency,
      currencyDisplay: 'symbol',
      currencySign: 'accounting',
      minimumFractionDigits: 0
    });
    formats.currencies.set(currency, format);
  }

  return format;
}

initFormats();
i18next.on('languageChanged', initFormats);
// eslint-disable-next-line max-params
export function formatNumber(
  number?: number | string | null,
  digits = 0,
  zeroValue = '-',
  shouldAbbreviate = false,
  numberOfDecimals = 0
) {
  if (number === undefined || number === null || Number.isNaN(number) || !number) {
    return zeroValue;
  }

  // cheap catch for allowing preformatted percentage values
  if (typeof number === 'string' && number.includes('%')) {
    return shouldAbbreviate ? abbreviateNumber(number) : number;
  }

  const result =
    numberOfDecimals > 0
      ? Number(number).toFixed(4)
      : (formats as any)?.default.format(Number.parseFloat(`${number}`).toFixed(digits));

  if (number > 0) {
    return shouldAbbreviate ? abbreviateNumber(result) : result;
  }

  return shouldAbbreviate ? abbreviateNumber(`(${String(result)})`) : `(${String(result)})`;
}

export function unformatNumber(value: number | string): string {
  const rawNumber = String(value).replace(/,/g, '');
  return isNumber(Number(rawNumber)) ? rawNumber : String(value);
}

export function formatPercentage(number?: number | string, NaNValue = '-', numberOfDecimals = 0): string {
  const percentageResult =
    numberOfDecimals > 0
      ? ((Number(number) * 10000) / 100).toFixed(numberOfDecimals)
      : Math.round(Number(number) * 10000) / 100;
  let percentString =
    Number.isNaN(number) || Number.isNaN(Number(number)) || (!number && number !== 0) || number === undefined
      ? NaNValue
      : `${percentageResult}%`;
  percentString = percentString !== NaNValue && Number(number) < 0 ? `(${percentString.slice(1)})` : percentString;
  return percentString;
}

export function unFormatPercentage(percentage: string): number {
  return Number.parseFloat(percentage) / 100;
}

export function formatCurrency(number?: number | string | null, currency = 'USD', digits = 0, zeroValue = '-'): string {
  if (number === undefined || number === null) return zeroValue;

  const result = getCurrencyFormat(currency).format(Number.parseFloat(`${number}`).toFixed(digits));
  return Number.isNaN(number) || !number ? zeroValue : result;
}

export function parseFloatLocal(stringNumber: string): number {
  const thousandSeparator = formats.default.format(11111).replace(/\p{Number}/gu, '');
  const decimalSeparator = formats.default.format(1.1).replace(/\p{Number}/gu, '');

  return Number.parseFloat(
    stringNumber
      .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
      .replace(new RegExp('\\' + decimalSeparator), '.')
  );
}

export function isNumber(value: any) {
  return typeof value === 'number' && !Number.isNaN(value);
}

export function isPercentage(value: any) {
  const percentageRegex = /^-?\d+(?:.\d+)?%$/;
  return typeof value === 'string' && percentageRegex.test(value);
}

export function abbreviateNumber(number: string | number): string {
  // adapted from this abbreviateNumber function authored by chucktator: https://stackoverflow.com/questions/10599933/convert-long-number-into-abbreviated-string-in-javascript-with-a-special-shortn
  let copy = String(number);
  let isPercentage = false;
  let isNegative = false;

  // first, get the number itself with no commas or other characters

  if (copy.startsWith('(') && copy.endsWith(')')) {
    // remove parens - number is negative
    copy = copy.slice(1, -1);
    isNegative = true;
  }

  if (copy.endsWith('%')) {
    // remove percentage
    copy = copy.slice(0, -1);
    isPercentage = true;
  }

  // remove any currency prefix (and save it)
  const currencyPrefix = /^[^\d(]/.exec(copy)?.[0] ?? '';
  copy = copy.replace(currencyPrefix, '');

  // remove the commas and round to remove decimals
  const value = Math.round(Number(copy.replace(/,/g, '')));

  // return the original number if abbreviation isn't necessary
  if (value <= 999999 || !value) return number.toLocaleString('en-US');

  let newValue = '';
  let suffixNum;
  const suffixes = ['', '', 'M', 'B', 'T'];

  if (String(value).length <= 9) suffixNum = 2;
  else if (String(value).length <= 12) suffixNum = 3;
  else suffixNum = 4;

  let shortValue: number | string;
  shortValue = value / 1000 ** suffixNum;
  shortValue = Number.parseFloat(shortValue.toFixed(3));
  newValue = `${currencyPrefix}${String(shortValue)}${suffixes[suffixNum]}`;

  if (isPercentage) newValue = `${newValue}%`;

  if (isNegative) newValue = `(${newValue})`;

  return newValue;
}
