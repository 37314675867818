import { useState } from 'react';

import i18next from 'i18next';

const collator = new Intl.Collator(i18next.language, { numeric: true });

export const useEditableCategoryRows = (customEdit: any) => {
  const [rows, setRows] = useState([]);
  const [hasNewRow, setHasNewRow] = useState(false);

  function handleNewRow() {
    if (hasNewRow) {
      const newRow = rows[rows.length - 1];
      if ((newRow as any).name?.trim().length) {
        setHasNewRow(false);
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
        setRows([...rows.slice(0, -1), { ...newRow, isNew: false }]);
      }
    } else {
      setHasNewRow(true);
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ isNew: boolean; }' is not assignable to ty... Remove this comment to see the full error message
      setRows([...rows, { isNew: true }]);
    }
  }

  function handleEditRow({ value, column, row }: any) {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    const idx = rows.indexOf(row);
    if (idx >= 0) {
      const edited = customEdit ? customEdit({ value, column, row }) : { ...row, [column.field]: value };
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
      setRows([...rows.slice(0, idx), edited, ...rows.slice(idx + 1)]);
    }
  }

  function getUnassignedEntityIds({ entityById, adjustment }: any) {
    if (!entityById || !adjustment.name) {
      return [];
    }

    const ids = [];
    for (const id of Object.keys(entityById)) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'never'.
      if (!rows.some((row) => row.id === id && !row.isNew)) {
        ids.push(id);
      }
    }

    const sortedIds = ids.sort((valA, valB) => {
      return collator.compare(valA, valB);
    });

    return sortedIds;
  }

  return { rows, setRows, hasNewRow, setHasNewRow, handleNewRow, handleEditRow, getUnassignedEntityIds };
};
