import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import makeStyles from '@material-ui/core/styles/makeStyles';
import ChevronRight from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
    gap: theme.spacing(0.5),
    textDecoration: 'none',
    color: 'inherit',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  }
}));

interface Props {
  linkTo: string;
}

const ViewReportsButton = ({ linkTo }: Props) => {
  const { link } = useStyles();
  const { t } = useTranslation();

  return (
    <Link className={link} to={linkTo}>
      {t('View All Reports')}
      <ChevronRight />
    </Link>
  );
};

export default ViewReportsButton;
