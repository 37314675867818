import React, { Dispatch, useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import DefaultSwitch from '../../../../DefaultSwitch/DefaultSwitch';

const useStyles = makeStyles((theme) => ({
  titleRow: {
    padding: '15px',
    paddingBottom: '0px',
    color: '#677283',
    fontFamily: 'Basis Grotesque Pro Medium',
    fontSize: '12px',
    letterSpacing: '0.6px',
    lineHeight: '14px',
    textTransform: 'uppercase'
  },
  toggleButtonRow: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '20px',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingTop: '20px'
  },
  toggleGroupedViewModalRoot: {
    alignItems: 'left',
    backgroundColor: theme.palette.common.white,
    borderRadius: '4px',
    boxShadow: '0 0 8px 0 rgba(0,0,0,0.28)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minWidth: '260px',
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(2),
    fontSize: '0.87rem',
    letterSpacing: '0.012rem',
    lineHeight: '1.1rem',
    width: '260px',
    zIndex: 100,
    '& .MuiIconButton-label': {
      width: 'default',
      height: 'default'
    }
  }
}));

interface ToggleGroupedViewModalProps {
  isOpen: boolean;
  isToggled: boolean;
  onToggle: () => void;
  setIsOpen: Dispatch<React.SetStateAction<boolean>>;
}

const ToggleGroupedViewModal = ({ isOpen, isToggled, onToggle, setIsOpen }: ToggleGroupedViewModalProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const wrapper = useRef<HTMLElement>();

  useEffect(() => {
    const clickOutsideFn = (e: any) => {
      const iconNode = document.querySelector('.js-categories-toggle-button');
      if (wrapper.current && !wrapper.current?.contains(e.target) && !iconNode?.contains?.(e.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener('mousedown', clickOutsideFn);

    return () => {
      window.removeEventListener('mousedown', clickOutsideFn);
    };
  }, [setIsOpen]);

  return isOpen ? (
    <Box ref={wrapper} className={classes.toggleGroupedViewModalRoot}>
      <Box className={classes.titleRow}>{t('Configure Table View')}</Box>
      <Box className={classes.toggleButtonRow}>
        {t('group-categories')}
        <DefaultSwitch checked={isToggled} onChange={onToggle} />
      </Box>
    </Box>
  ) : null;
};

export default ToggleGroupedViewModal;
