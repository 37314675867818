import { TFunction } from 'react-i18next';

import handleReportLineItemValueFormatting from './handleReportLineItemValueFormatting';

import { CategoryFromApi } from '../../../../models';
import { makeSectionTitle } from '../../components/ReportTable';

const reportRowCategory = ({ name, stepName }: any, categories: CategoryFromApi[] = []) => {
  const category = categories.find((category) => {
    return category.accounts.some(
      (account) =>
        account.stepName === stepName && account.attributeName.toLowerCase().trim() === name.toLowerCase().trim()
    );
  });

  return category ? { category } : {};
};

const composeRowProperties = ({
  value,
  isCollection,
  t,
  percentageFields,
  isCategorizable,
  categories,
  reportName
}: ComposeRowPropertiesTypes) => {
  switch (value.type) {
    case 'collection':
      return makeSectionTitle(t(value.name));
    case 'group':
      return { name: t(value.name) };
    case 'lineItem':
    case 'total':
      return {
        ...handleReportLineItemValueFormatting(value, percentageFields, reportName),
        name: t(value.name),
        ...(isCollection && value.type !== 'total' && { className: 'indent' }),
        ...(value.type === 'total' && { isTotal: true }),
        ...(isCategorizable ? reportRowCategory(value, categories) : {})
      };
    default:
  }
};

type ComposeRowPropertiesTypes = {
  value: any;
  isCollection: boolean;
  t: TFunction;
  percentageFields: string[];
  isCategorizable: boolean;
  categories?: CategoryFromApi[];
  reportName: string;
};

export default composeRowProperties;
