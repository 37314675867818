import { SvgIcon } from '@material-ui/core';

const DeferredIcon = (props: any) => (
  <SvgIcon viewBox="0 0 256 256" {...props}>
    <path d="M216.9,232.3H41.1V17.7h113l62.7,58.9V232.3z M44.1,229.3h169.8V77.9L153,20.7H44.1V229.3z" />

    <g>
      <path d="M143.3,89.3c0.7,0,1.2-0.5,1.2-1.2s-0.5-1.2-1.2-1.2h-28.6c-0.7,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2H143.3z" />

      <path d="M108.8,95.2V81c0-0.7-0.5-1.2-1.2-1.2s-1.2,0.5-1.2,1.2v14.3c0,0.7,0.5,1.2,1.2,1.2S108.8,95.9,108.8,95.2z" />

      <path d="M151.6,95.2V81c0-0.7-0.5-1.2-1.2-1.2s-1.2,0.5-1.2,1.2v14.3c0,0.7,0.5,1.2,1.2,1.2S151.6,95.9,151.6,95.2z" />

      <path
        d="M171.6,86.9h-14c-0.7,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2h14c0.7,0,1.4,0.6,1.4,1.2v15.5H85V90.5
      
      c0-0.5,0.2-1.2,1-1.2h14.5c0.7,0,1.2-0.5,1.2-1.2s-0.5-1.2-1.2-1.2H86c-1.9,0-3.3,1.5-3.3,3.6v76.1c0,2,1.4,3.6,3.3,3.6h85.7
      
      c2,0,3.8-1.7,3.8-3.6V90.5C175.4,88.6,173.7,86.9,171.6,86.9z M171.6,167.8H86c-0.7,0-1-0.6-1-1.2v-58.3h88v58.3
      
      C173.1,167.2,172.3,167.8,171.6,167.8z"
      />
    </g>

    <path
      d="M104.4,112.6h-1.8h-0.4h-8.9h0h-2.2c-0.3,0-0.5,0.2-0.5,0.6V125c0,0.3,0.2,0.6,0.5,0.6h13.3
  
  c0.3,0,0.6-0.3,0.6-0.6v-11.8C105,112.9,104.7,112.6,104.4,112.6z M104.6,115v0.6v9.4c0,0.1-0.1,0.2-0.2,0.2H91.1
  
  c-0.1,0-0.1-0.1-0.1-0.2v-9.4V115v-1.8c0-0.1,0-0.2,0.1-0.2h2.2h0h8.9h0.4h1.8c0.1,0,0.2,0.1,0.2,0.2V115z"
    />

    <path
      d="M125.2,112.6h-1.8H123h-8.9h0h-2.2c-0.3,0-0.5,0.2-0.5,0.6V125c0,0.3,0.2,0.6,0.5,0.6h13.3
  
  c0.3,0,0.6-0.3,0.6-0.6v-11.8C125.8,112.9,125.5,112.6,125.2,112.6z M125.4,115v0.6v9.4c0,0.1-0.1,0.2-0.2,0.2h-13.3
  
  c-0.1,0-0.1-0.1-0.1-0.2v-9.4V115v-1.8c0-0.1,0-0.2,0.1-0.2h2.2h0h8.9h0.4h1.8c0.1,0,0.2,0.1,0.2,0.2V115z"
    />

    <path
      d="M145.9,112.6h-1.8h-0.4h-8.9h0h-2.2c-0.3,0-0.5,0.2-0.5,0.6V125c0,0.3,0.2,0.6,0.5,0.6h13.3
  
  c0.3,0,0.6-0.3,0.6-0.6v-11.8C146.5,112.9,146.2,112.6,145.9,112.6z M146.1,115v0.6v9.4c0,0.1-0.1,0.2-0.2,0.2h-13.3
  
  c-0.1,0-0.1-0.1-0.1-0.2v-9.4V115v-1.8c0-0.1,0-0.2,0.1-0.2h2.2h0h8.9h0.4h1.8c0.1,0,0.2,0.1,0.2,0.2V115z"
    />

    <path
      d="M166.7,112.6h-1.8h-0.4h-8.9h0h-2.2c-0.3,0-0.5,0.2-0.5,0.6V125c0,0.3,0.2,0.6,0.5,0.6h13.3
  
  c0.3,0,0.6-0.3,0.6-0.6v-11.8C167.2,112.9,167,112.6,166.7,112.6z M166.9,115v0.6v9.4c0,0.1-0.1,0.2-0.2,0.2h-13.3
  
  c-0.1,0-0.1-0.1-0.1-0.2v-9.4V115v-1.8c0-0.1,0-0.2,0.1-0.2h2.2h0h8.9h0.4h1.8c0.1,0,0.2,0.1,0.2,0.2V115z"
    />

    <path
      d="M104.4,130.2h-1.8h-0.4h-8.9h0h-2.2c-0.3,0-0.5,0.2-0.5,0.6v11.8c0,0.3,0.2,0.6,0.5,0.6h13.3
  
  c0.3,0,0.6-0.3,0.6-0.6v-11.8C105,130.5,104.7,130.2,104.4,130.2z M104.6,132.6v0.6v9.4c0,0.1-0.1,0.2-0.2,0.2H91.1
  
  c-0.1,0-0.1-0.1-0.1-0.2v-9.4v-0.6v-1.8c0-0.1,0-0.2,0.1-0.2h2.2h0h8.9h0.4h1.8c0.1,0,0.2,0.1,0.2,0.2V132.6z"
    />

    <path
      d="M125.2,130.2h-1.8H123h-8.9h0h-2.2c-0.3,0-0.5,0.2-0.5,0.6v11.8c0,0.3,0.2,0.6,0.5,0.6h13.3
  
  c0.3,0,0.6-0.3,0.6-0.6v-11.8C125.8,130.5,125.5,130.2,125.2,130.2z M125.4,132.6v0.6v9.4c0,0.1-0.1,0.2-0.2,0.2h-13.3
  
  c-0.1,0-0.1-0.1-0.1-0.2v-9.4v-0.6v-1.8c0-0.1,0-0.2,0.1-0.2h2.2h0h8.9h0.4h1.8c0.1,0,0.2,0.1,0.2,0.2V132.6z"
    />

    <path
      d="M145.9,130.2h-1.8h-0.4h-8.9h0h-2.2c-0.3,0-0.5,0.2-0.5,0.6v11.8c0,0.3,0.2,0.6,0.5,0.6h13.3
  
  c0.3,0,0.6-0.3,0.6-0.6v-11.8C146.5,130.5,146.2,130.2,145.9,130.2z M146.1,132.6v0.6v9.4c0,0.1-0.1,0.2-0.2,0.2h-13.3
  
  c-0.1,0-0.1-0.1-0.1-0.2v-9.4v-0.6v-1.8c0-0.1,0-0.2,0.1-0.2h2.2h0h8.9h0.4h1.8c0.1,0,0.2,0.1,0.2,0.2V132.6z"
    />

    <path
      d="M166.7,130.2h-1.8h-0.4h-8.9h0h-2.2c-0.3,0-0.5,0.2-0.5,0.6v11.8c0,0.3,0.2,0.6,0.5,0.6h13.3
  
  c0.3,0,0.6-0.3,0.6-0.6v-11.8C167.2,130.5,167,130.2,166.7,130.2z M166.9,132.6v0.6v9.4c0,0.1-0.1,0.2-0.2,0.2h-13.3
  
  c-0.1,0-0.1-0.1-0.1-0.2v-9.4v-0.6v-1.8c0-0.1,0-0.2,0.1-0.2h2.2h0h8.9h0.4h1.8c0.1,0,0.2,0.1,0.2,0.2V132.6z"
    />

    <path
      d="M104.4,147.9h-1.8h-0.4h-8.9h0h-2.2c-0.3,0-0.5,0.2-0.5,0.6v11.8c0,0.3,0.2,0.6,0.5,0.6h13.3
  
  c0.3,0,0.6-0.3,0.6-0.6v-11.8C105,148.1,104.7,147.9,104.4,147.9z M104.6,150.2v0.6v9.4c0,0.1-0.1,0.2-0.2,0.2H91.1
  
  c-0.1,0-0.1-0.1-0.1-0.2v-9.4v-0.6v-1.8c0-0.1,0-0.2,0.1-0.2h2.2h0h8.9h0.4h1.8c0.1,0,0.2,0.1,0.2,0.2V150.2z"
    />

    <path
      d="M125.2,147.9h-1.8H123h-8.9h0h-2.2c-0.3,0-0.5,0.2-0.5,0.6v11.8c0,0.3,0.2,0.6,0.5,0.6h13.3
  
  c0.3,0,0.6-0.3,0.6-0.6v-11.8C125.8,148.1,125.5,147.9,125.2,147.9z M125.4,150.2v0.6v9.4c0,0.1-0.1,0.2-0.2,0.2h-13.3
  
  c-0.1,0-0.1-0.1-0.1-0.2v-9.4v-0.6v-1.8c0-0.1,0-0.2,0.1-0.2h2.2h0h8.9h0.4h1.8c0.1,0,0.2,0.1,0.2,0.2V150.2z"
    />

    <path
      d="M145.9,147.9h-1.8h-0.4h-8.9h0h-2.2c-0.3,0-0.5,0.2-0.5,0.6v11.8c0,0.3,0.2,0.6,0.5,0.6h13.3
  
  c0.3,0,0.6-0.3,0.6-0.6v-11.8C146.5,148.1,146.2,147.9,145.9,147.9z M146.1,150.2v0.6v9.4c0,0.1-0.1,0.2-0.2,0.2h-13.3
  
  c-0.1,0-0.1-0.1-0.1-0.2v-9.4v-0.6v-1.8c0-0.1,0-0.2,0.1-0.2h2.2h0h8.9h0.4h1.8c0.1,0,0.2,0.1,0.2,0.2V150.2z"
    />

    <path
      d="M166.7,147.9h-1.8h-0.4h-8.9h0h-2.2c-0.3,0-0.5,0.2-0.5,0.6v11.8c0,0.3,0.2,0.6,0.5,0.6h13.3
  
  c0.3,0,0.6-0.3,0.6-0.6v-11.8C167.2,148.1,167,147.9,166.7,147.9z M166.9,150.2v0.6v9.4c0,0.1-0.1,0.2-0.2,0.2h-13.3
  
  c-0.1,0-0.1-0.1-0.1-0.2v-9.4v-0.6v-1.8c0-0.1,0-0.2,0.1-0.2h2.2h0h8.9h0.4h1.8c0.1,0,0.2,0.1,0.2,0.2V150.2z"
    />
  </SvgIcon>
);

export default DeferredIcon;
