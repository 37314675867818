import { ComponentType, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import Box from '@material-ui/core/Box';

import { AuditTrailProps } from './AuditTrail';
import { DataTypeIdsByName, tabsFilterOptions, tabsFilterOptionsForInterim } from './constants';

import { UsaGroupId, UsaJurisdictionId, WorldGroupId } from '../../constants';
import { useCategories, useContainers, useCustomGroups, useEntities } from '../../hooks';
import { useAuditTrail } from '../../hooks/useAuditTrail';
import { CategoryFromApi, CustomGroupFromApi, Entity, EntityFromApi, Level, Step, UserFromApi } from '../../models';
import {
  AccountFromAuditTrailApi,
  AuditTrailRequestFilters,
  DataTypeFilterOption,
  EntityFilterOption,
  FilterOption
} from '../../models/auditTrail.interface';
import { fetchCustomGroupEntities } from '../../redux/customGroups';
import { formatDate } from '../../utils';
import transformGroupUuid from '../../utils/transformGroupUuid';
import ColorCircle from '../ColorCircle';

const getDataTypeFilterOptions = ({
  accounts,
  isInterim,
  categories
}: {
  accounts: AccountFromAuditTrailApi[];
  isInterim: boolean;
  categories: CategoryFromApi[];
}) => {
  const tabsOptions = isInterim ? tabsFilterOptionsForInterim : tabsFilterOptions;

  const accountNumbersOptions: DataTypeFilterOption[] = accounts
    .filter((account) => account.number !== null)
    .map((account) => {
      return {
        name: `${account.description} (${account.number})`,
        id: account.number,
        accountDescription: account.description,
        accountNumber: account.number,
        type: 'accountNumbers'
      };
    });

  const adjustmentsOptions: DataTypeFilterOption[] = accounts
    .filter((account) => account.stepName !== null)
    .map((account) => {
      const splitStepName = account.stepName.split('.');
      const level = splitStepName[0] as Level;
      const step = splitStepName.slice(1).join('.') as Step;

      return {
        name: `${account.description} ${account.number ? `(${account.number})` : ''}`,
        id: account.description,
        accountDescription: account.description,
        accountNumber: account.number,
        type: 'adjustments',
        level,
        step
      };
    });

  const categoriesOptions: DataTypeFilterOption[] = categories.map((category) => {
    return {
      name: category.name,
      id: category.categoryId,
      type: 'categories',
      component: () => (
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          {category.name}
          <Box style={{ marginLeft: '10px' }}>
            <ColorCircle withLightBackground color={category.color} />
          </Box>
        </Box>
      )
    };
  });

  const dataTypeFilterOptions: DataTypeFilterOption[] = [
    ...adjustmentsOptions,
    ...tabsOptions,
    ...accountNumbersOptions,
    ...categoriesOptions
  ];

  return dataTypeFilterOptions;
};

const getEntityFilterOptions = ({
  allEntities,
  entities,
  groups,
  customGroupsEntities
}: {
  allEntities: Entity[];
  entities: EntityFromApi[];
  groups: CustomGroupFromApi[];
  customGroupsEntities: Record<string, Entity[]>;
}) => {
  const entitySet = new Set(entities.map((entity: any) => entity.entityId));
  const entitiesFromUsa = allEntities.filter((entity) => entity.jurisdictionId === UsaJurisdictionId);

  const entityOptions: EntityFilterOption[] = entities.map((entity) => {
    return {
      name: `${entity.name} (${entity.entityNumber})`,
      id: entity.entityId,
      type: 'entity'
    };
  });

  const groupOptions: EntityFilterOption[] = groups
    .filter((group) => {
      if (group.groupId === WorldGroupId) {
        return entities.length > 0;
      }

      if (group.groupId === UsaGroupId) {
        return entitiesFromUsa?.some((entity) => entitySet.has(entity.entityId));
      }

      return customGroupsEntities[group.groupId]?.some((entity) => entitySet.has(entity.entityId));
    })
    .map((group) => {
      return {
        name: group.name,
        id: transformGroupUuid(group.groupId),
        type: 'group'
      };
    });

  const entityFilterOptions: EntityFilterOption[] = [...entityOptions, ...groupOptions];

  return entityFilterOptions;
};

const getUserFilterOptions = ({ users }: { users: UserFromApi[] }) => {
  const userFilterOptions: FilterOption[] = users.map((user) => {
    return {
      name: `${user.firstName} ${user.lastName}`,
      id: user.userId
    };
  });

  return userFilterOptions;
};

const AuditTrailConnector = ({ component: AuditTrail }: { component: ComponentType<AuditTrailProps> }) => {
  const defaultStartDate = new Date('2015-01-01 00:00:00');
  const defaultEndDate = new Date();
  const defaultPageNumber = 0;
  const defaultPageSize = 100;
  const pageSizeOptions = [5, 10, 25, 50, 100];

  const dispatch = useDispatch();

  const [pageNumber, setPageNumber] = useState(defaultPageNumber);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [selectedFilters, setSelectedFilters] = useState<AuditTrailRequestFilters>({
    startDate: formatDate(defaultStartDate, 'YYYY-MM-DD'),
    endDate: formatDate(defaultEndDate, 'YYYY-MM-DD'),
    datatypeId: DataTypeIdsByName.entityFinancials
  });

  const { auditTrail, isLoading } = useAuditTrail(selectedFilters, pageNumber, pageSize);
  const { currentContainer } = useContainers();
  const { entities: allEntities } = useEntities();
  const { categories } = useCategories();
  const { customGroups, customGroupsEntities } = useCustomGroups();
  const [startDate, setStartDate] = useState<Date>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date>(defaultEndDate);
  const [entityFilter, setEntityFilter] = useState<EntityFilterOption | null>(null);
  const [userFilter, setUserFilter] = useState<FilterOption | null>(null);
  const [dataTypeFilter, setDataTypeFilter] = useState<DataTypeFilterOption | null>(null);

  const totalPages = Math.ceil(auditTrail.count / pageSize);

  const dataTypeFilterOptions: DataTypeFilterOption[] = getDataTypeFilterOptions({
    accounts: auditTrail?.filters?.accounts ?? [],
    isInterim: Boolean(currentContainer?.isInterimReportingPeriod),
    categories
  });

  useEffect(() => {
    if (customGroups.length > 0) {
      for (const group of customGroups) {
        dispatch(fetchCustomGroupEntities({ groupId: group.groupId }));
      }
    }
  }, [customGroups, dispatch]);

  const entityFilterOptions: EntityFilterOption[] = getEntityFilterOptions({
    allEntities,
    entities: auditTrail?.filters?.entities ?? [],
    groups: customGroups,
    customGroupsEntities
  });

  const userFilterOptions: FilterOption[] = getUserFilterOptions({
    users: auditTrail?.filters?.users ?? []
  });

  const handleOnDatePeriodSave = (startDate: Date, endDate: Date) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleOnDatePeriodClear = () => {
    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
  };

  const resolveValueFromEntityFilter = (selected: EntityFilterOption | null, type: EntityFilterOption['type']) => {
    if (!selected || type !== selected?.type) {
      return;
    }

    return selected.id;
  };

  const resolveValueFromDataTypeFilter = (
    selected: DataTypeFilterOption | null,
    type: 'category' | 'accountNumber' | 'accountDescription' | 'tab'
  ) => {
    switch (type) {
      case 'category':
        return selected?.type === 'categories' ? selected?.id : undefined;
      case 'accountDescription':
        return selected?.type === 'accountNumbers' || selected?.type === 'adjustments'
          ? selected?.accountDescription ?? undefined
          : undefined;
      case 'accountNumber':
        return selected?.type === 'accountNumbers' || selected?.type === 'adjustments'
          ? selected?.accountNumber ?? undefined
          : undefined;
      case 'tab':
        if (selected?.type === 'adjustments') {
          return `${selected?.level ?? ''}.${selected?.step ?? ''}`;
        }

        if (selected?.type === 'tabs') {
          return selected?.id;
        }

        break;
      default:
    }
  };

  useEffect(() => {
    const setFilteringCriteria = () => {
      // JSON.parse/stringify to remove undefined fields
      const filters: AuditTrailRequestFilters = JSON.parse(
        JSON.stringify({
          startDate: formatDate(startDate, 'YYYY-MM-DD'),
          endDate: formatDate(endDate, 'YYYY-MM-DD'),
          datatypeId: DataTypeIdsByName.entityFinancials,
          groupId: resolveValueFromEntityFilter(entityFilter, 'group'),
          entityId: resolveValueFromEntityFilter(entityFilter, 'entity'),
          categoryId: resolveValueFromDataTypeFilter(dataTypeFilter, 'category'),
          accountNumber: resolveValueFromDataTypeFilter(dataTypeFilter, 'accountNumber'),
          accountDescription: resolveValueFromDataTypeFilter(dataTypeFilter, 'accountDescription'),
          tab: resolveValueFromDataTypeFilter(dataTypeFilter, 'tab'),
          userId: userFilter?.id
        })
      );

      setSelectedFilters(filters);
      setPageNumber(defaultPageNumber);
    };

    setFilteringCriteria();
  }, [startDate, endDate, entityFilter, userFilter, dataTypeFilter]);

  const handlePageNumberChange = (page: number) => {
    setPageNumber(page - 1);
  };

  const handlePageSizeChange = (size: number) => {
    setPageNumber(defaultPageNumber);
    setPageSize(size);
  };

  return (
    <AuditTrail
      auditTrailData={auditTrail}
      isLoading={isLoading}
      startDate={startDate}
      endDate={endDate}
      entityFilter={entityFilter}
      userFilter={userFilter}
      dataTypeFilter={dataTypeFilter}
      entityFilterOptions={entityFilterOptions}
      userFilterOptions={userFilterOptions}
      dataTypeFilterOptions={dataTypeFilterOptions}
      totalPages={totalPages}
      pageSizeOptions={pageSizeOptions}
      currentPageNumber={pageNumber}
      currentPageSize={pageSize}
      onDatePeriodSave={handleOnDatePeriodSave}
      onDatePeriodClear={handleOnDatePeriodClear}
      onEntityFilterChange={setEntityFilter}
      onUserFilterChange={setUserFilter}
      onDataTypeFilterChange={setDataTypeFilter}
      onPageChange={handlePageNumberChange}
      onPageSizeChange={handlePageSizeChange}
    />
  );
};

export default AuditTrailConnector;
