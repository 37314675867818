import { useMemo } from 'react';

import { Collapse, makeStyles, Card } from '@material-ui/core';

import { useDropdownIcon } from '../../../../../../../../hooks';
import { Row, TraceReportCollectionValue } from '../../../../../../../../models';
import { LineItem } from '../LineItem/LineItem';
import { TotalRow } from '../TotalRow/TotalRow';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    padding: '0px 30px 34px 30px',
    backgroundColor: theme.palette.common.white,
    '& .MuiCollapse-wrapperInner > div:last-child': {
      borderBottom: `1px solid ${theme.palette.text.primary}`
    },
    '& .MuiCollapse-wrapperInner > div:first-child': {
      borderTop: `1px solid ${theme.palette.divider}`
    }
  },
  headerRow: {
    alignItems: 'center',
    display: 'flex',
    height: '66px',
    minHeight: '66px',
    justifyContent: 'space-between',
    paddingLeft: `${theme.spacing(1)}`,
    '& *': {
      paddingBottom: '0px'
    },
    '& .MuiButtonBase-root.MuiIconButton-sizeSmall': {
      backgroundColor: theme.palette.info.dark,
      '& svg': {
        fill: theme.palette.common.white,
        '& path': {
          transform: 'translateY(-8px) translateX(-5px) scale(1.5)'
        }
      }
    }
  },
  hideText: {
    marginRight: '5px',
    marginTop: '3px',
    opacity: 0.5,
    color: theme.palette.info.dark
  },
  iconCell: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '14px',
    maxHeight: '32px',
    paddingRight: '0px',
    paddingTop: '0px',
    textAlign: 'end',
    width: '100px'
  },
  sectionHeader: {
    alignItems: 'center',
    display: 'flex',
    marginRight: '12px',
    minWidth: '200px',
    padding: '0px',
    paddingLeft: '10px'
  }
}));

export const Collection = ({ row }: { row: TraceReportCollectionValue }) => {
  const classes = useStyles();
  const { Button: DropdownButton, isOpen } = useDropdownIcon();

  const totalRow = useMemo(() => row.values.find((someRow: Row) => someRow?.type === 'total'), [row]);

  return (
    <Card className={classes.cardContainer} elevation={0}>
      <div className={classes.headerRow}>
        <div className={classes.sectionHeader}>{row.name}</div>
        <div className={classes.iconCell}>
          <span className={classes.hideText}>{isOpen ? 'Hide' : 'Show'}</span>
          <DropdownButton />
        </div>
      </div>
      <div>
        <div style={{ margin: '33px 28x 35px 20px' }}>
          <Collapse unmountOnExit in={isOpen === null ? false : isOpen} timeout="auto">
            {row.values.map((rowVal, idx) =>
              // eslint-disable-next-line react/no-array-index-key
              rowVal.type === 'lineItem' ? <LineItem key={idx} row={rowVal} /> : null
            )}
          </Collapse>
          {totalRow && <TotalRow row={totalRow} />}
        </div>
      </div>
    </Card>
  );
};
