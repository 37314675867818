import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { setCountriesData } from '../../redux/ui';
import { selectCountriesData } from '../../selectors/ui';

export function useCountriesData() {
  const dispatch = useDispatch();
  const countriesData = useSelector(selectCountriesData);

  useEffect(() => {
    async function fetchData() {
      // data from Natural Earth Data, provided as GeoJSON by http://geojson.xyz/
      const response = await fetch(
        'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_admin_0_countries.geojson'
      );
      dispatch(setCountriesData(await response.json()));
    }

    if (countriesData === null) {
      void fetchData();
    }
  }, [countriesData, dispatch]);

  return { countriesData };
}
