import { useEffect, useMemo } from 'react';

import { useDispatch } from 'react-redux';

import { Box, makeStyles, TableBody, Table, Card } from '@material-ui/core';

import TraceabilityContentHeader from './TraceabilityContentHeader';
import { XConnector } from './TraceabilityReportRowComponents/';
import TraceabilityReportRowOrRowGroup from './TraceabilityReportRowOrRowGroup';

import IconPanel from '../../../../../../assets/imgs/IconPanel';
import { TraceReport } from '../../../../../../models/reports.interface';
import { onCloseTraceability } from '../../../../../../redux/traceability';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'visible',
    padding: theme.spacing(1)
  },
  divider: {
    height: '19px',
    width: '100%',
    borderBottom: 'none'
  },
  table: {
    '& .MuiTable-root': {
      '& .MuiTableHead-root': {
        display: 'none'
      },
      '& .total .MuiTableCell-root.MuiTableCell-body': {
        color: 'inherit',
        borderTop: 'none',
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.grey[200],
        fontWeight: theme.typography.fontWeightRegular,
        padding: '0px'
      },
      '& .MuiTableCell-root.MuiTableCell-body': {
        padding: theme.spacing(0.5, 0),
        borderRightColor: 'transparent',
        backgroundColor: 'transparent',

        '&.section-header': {
          paddingTop: theme.spacing(2)
        },
        '&.empty-row': {
          padding: theme.spacing(1.5, 0)
        }
      }
    }
  },
  closeIcon: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    position: 'absolute',
    top: '24px',
    right: '40px',
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  }
}));

export type TraceabilityReportProps = {
  currency: string;
  name: string;
  report: TraceReport;
  value: number;
  accountNumber?: string;
  format?: string;
};

const TraceabilityReport = ({ currency, name, report, value, accountNumber, format }: TraceabilityReportProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const reportValuesWithLastValueFirst = useMemo(() => [...report.values.slice(-1), ...report.values.slice(0, -1)], [
    report.values
  ]);

  const handleClose = () => {
    dispatch(onCloseTraceability());
  };

  useEffect(() => {
    // NOTE (jr): This is meant to emulate `ComponentWillUnmount`.
    // This is necessary to handle an edge case where the user uses the browser "Back" button.
    return () => {
      dispatch(onCloseTraceability());
    };
  }, [dispatch]);

  return (
    <Box className={classes.root}>
      <IconPanel className={classes.closeIcon} handleClick={handleClose} />
      <TraceabilityContentHeader
        name={name}
        currency={currency}
        value={value}
        accountNumber={accountNumber}
        format={format}
      />
      {reportValuesWithLastValueFirst.map((val, index) => (
        // NOTE: this component is not dynamic, so it is safe to use the index as key.
        <div key={name}>
          <Card elevation={0}>
            <Table>
              <TableBody>
                <TraceabilityReportRowOrRowGroup row={val} />
              </TableBody>
            </Table>
          </Card>
          {index < reportValuesWithLastValueFirst.length - 1 ? <XConnector /> : null}
        </div>
      ))}
    </Box>
  );
};

export default TraceabilityReport;
