import { useMemo } from 'react';

import { Container } from '../../models';
import MultiAccordion from '../MultiAccordion';

const groupContainersByYear = (containers: Container[]): Array<Record<string, string[]>> => {
  const taxYearToContainers: Record<number, Container[]> = {};
  for (const container of containers) {
    const { taxYear } = container;
    taxYearToContainers[taxYear] = [...(taxYearToContainers[taxYear] ?? []), container];
  }

  for (const group of Object.values(taxYearToContainers)) {
    group.sort((containerA, containerB) =>
      containerA.containerName < containerB.containerName
        ? -1
        : containerA.containerName > containerB.containerName
        ? 1
        : 0
    );
  }

  const result = [];

  for (const [key, value] of Object.entries(taxYearToContainers)) {
    result.push({
      [key]: value.map((val) => val.containerName)
    });
  }

  result.sort((groupA, groupB) => Number(Object.keys(groupB)[0]) - Number(Object.keys(groupA)[0]));

  return result;
};

type Props = {
  containers: Container[];
  selectedContainer?: Container;
  onContainerSelected: (name: string, taxYear: string) => void;
  classesOverrides?: Record<string, string>;
};

const ContainersAccordion = ({ containers = [], onContainerSelected, selectedContainer, classesOverrides }: Props) => {
  const containersGroupedAndSorted = useMemo(() => groupContainersByYear(containers), [containers]);
  const selectedRow =
    selectedContainer ??
    (({
      taxYear: (containersGroupedAndSorted[0] && Object.keys(containersGroupedAndSorted[0])[0]) ?? ''
    } as unknown) as Container);

  return (
    <MultiAccordion
      groupedRows={containersGroupedAndSorted}
      selectedRow={{ value: selectedRow.containerName, groupKey: selectedRow.taxYear.toString() }}
      options={{ isSingleExpanded: true }}
      classesOverrides={classesOverrides}
      onRowClick={onContainerSelected}
    />
  );
};

export default ContainersAccordion;
