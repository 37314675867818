import { Level, Step, SubJurisdiction } from '../../../models';

const validateSubJurisdictionUserInput = (userInput: string, subJurisdictionsSet: Set<SubJurisdiction['name']>) => {
  return [...subJurisdictionsSet].map((subJur) => subJur.toLowerCase()).includes(userInput.toLowerCase());
};

export const generateSubJurValidationArray = (
  userInput: string[],
  subJurisdictionsSet: Set<SubJurisdiction['name']>
) => {
  const subJurisdictionValidationArray = userInput.map((v: string) =>
    validateSubJurisdictionUserInput(v, subJurisdictionsSet)
  );
  return subJurisdictionValidationArray;
};

export const filterOutInvalidStates = (stateArray: string[], subJurisdictionValidationArray: boolean[]) => {
  return stateArray.filter((_, index) => subJurisdictionValidationArray[index]);
};

export const validateCellValue = ({
  isExistingRow = false,
  value,
  level,
  step,
  headerName,
  subJurisdictionNamesSet
}: {
  isExistingRow?: boolean;
  value?: any;
  level: Level;
  step: Step;
  headerName: string;
  subJurisdictionNamesSet: Set<SubJurisdiction['name']>;
}) => {
  if (level === 'state') {
    switch (step) {
      case 'apportionment': {
        if (headerName === 'State') {
          return validateSubJurisdictionUserInput(value, subJurisdictionNamesSet);
        }

        break;
      }

      case 'apportionment-flagged': {
        if (headerName === 'State') {
          return validateSubJurisdictionUserInput(value, subJurisdictionNamesSet);
        }

        break;
      }

      case 'tax-effected': {
        if (headerName === 'State') {
          return validateSubJurisdictionUserInput(value, subJurisdictionNamesSet);
        }

        break;
      }

      case 'modifications': {
        if (headerName === 'Modification Type') {
          return value.toLowerCase() === 'permanent' || value.toLowerCase() === 'temporary';
        }

        if (headerName === 'State') {
          const subJurisdictionValidationArray = value.map((v: string) =>
            validateSubJurisdictionUserInput(v, subJurisdictionNamesSet)
          );
          return !subJurisdictionValidationArray.includes(false);
        }

        break;
      }

      case 'rtp':
        return isExistingRow;

      case 'deferred':
        if (headerName === 'State') {
          return validateSubJurisdictionUserInput(value, subJurisdictionNamesSet);
        }

        return isExistingRow;

      default:
        return true;
    }
  } else if (level === 'federal') {
    switch (step) {
      case 'rtp':
        return isExistingRow;

      case 'deferred':
        return isExistingRow;

      default:
        break;
    }
  }

  return true;
};
