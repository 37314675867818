import { Dispatch, SetStateAction } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import ExpandLessOutlined from '@material-ui/icons/ExpandLessOutlined';
import ExpandMoreOutlined from '@material-ui/icons/ExpandMoreOutlined';
import classnames from 'classnames';

import ContainersDropdown from './ContainersDropdown';

import { useClickOutside } from '../../../hooks/useClickOutside';
import { Container } from '../../../models';

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    justifyContent: 'left'
  },
  buttonShaded: {
    backgroundColor: theme.palette.info.main
  },
  containersMenuContainer: {
    color: theme.palette.text.secondary,
    minWidth: theme.spacing(25),
    overflow: 'visible',
    whiteSpace: 'nowrap'
  }
}));

interface Props {
  currentContainer: Container;
  containers: Container[];
  onContainerSelected: (name: string, taxYear: string) => void;
  isDropdownOpen: boolean;
  isLoading: boolean;
  setIsDropdownOpen: Dispatch<SetStateAction<boolean>>;
}

const ContainersDropdownContainer = ({
  currentContainer,
  containers,
  onContainerSelected,
  isDropdownOpen,
  isLoading,
  setIsDropdownOpen
}: Props) => {
  const classes = useStyles();
  const dropdownRef = useClickOutside(() => {
    setIsDropdownOpen(false);
  });

  return (
    <Grid item className={classes.containersMenuContainer}>
      {isLoading ? (
        <CircularProgress size={25} />
      ) : (
        <Box ref={dropdownRef} mr={4}>
          <Button
            className={classnames(classes.button, {
              [classes.buttonShaded]: isDropdownOpen
            })}
            endIcon={isDropdownOpen ? <ExpandMoreOutlined /> : <ExpandLessOutlined />}
            onClick={() => {
              setIsDropdownOpen((open: boolean) => !open);
            }}
          >
            <Typography variant="caption">{`${currentContainer.taxYear.toString()} ${
              currentContainer.containerName
            }`}</Typography>
          </Button>
          {isDropdownOpen && (
            <Grid item sm={3} lg={2}>
              {!isLoading && (
                <Box>
                  <ContainersDropdown
                    containers={containers}
                    currentContainer={currentContainer}
                    setIsDropdownOpen={setIsDropdownOpen}
                    onContainerSelected={onContainerSelected}
                  />
                </Box>
              )}
            </Grid>
          )}
        </Box>
      )}
    </Grid>
  );
};

export default ContainersDropdownContainer;
