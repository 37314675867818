import { GenericRecord } from '../calculations/utils';
import { ASC, filterAndSort } from '../components/Table/utils';

export function turnToObjById<T>(array: T[]) {
  return turnToObjByKey(array, 'id');
}

type ArrayItem = Record<string, any>;

export function turnToObjByKey<T extends ArrayItem>(array: T[], key: string): Record<string, T> {
  const obj: Record<string, any> = {};
  array.forEach((item) => {
    obj[item[key]] = item;
  });

  return obj;
}

export function groupByName(array: GenericRecord[] = []) {
  const byType = new Map();
  for (const obj of array) {
    let list = byType.get(obj.name);
    if (!list) {
      list = [];
      byType.set(obj.name, list);
    }

    list.push(obj);
  }

  return byType;
}

export function groupByCreditName(array: GenericRecord[] = []) {
  const byType = new Map();
  for (const obj of array) {
    let list = byType.get(obj.creditName);
    if (!list) {
      list = [];
      byType.set(obj.creditName, list);
    }

    list.push(obj);
  }

  return byType;
}

export function sortOnColumn(rows: any, column: any) {
  return filterAndSort(rows, [], { column, dir: ASC });
}
