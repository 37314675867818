import { useState, useMemo, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { ContentAndTitle, TabTitle } from '../.';
import { TableWithComment } from '../../..';
import { FEDERAL_UUID, LEVELS } from '../../../../constants';
import { useCompletionStatus, useCurrencies, useFinancialData } from '../../../../hooks';
import { DiscreteTabProps, Level, Step } from '../../../../models';
import { setEntityCompletionStatus } from '../../../../redux/entitiesCompletionStatus';
import LoadingWrapper from '../../../LoadingWrapper';
import {
  EntityNumberRouteMatch,
  getDiscreteColumns,
  getRowNamesFromLevelSteps,
  getTableDataFromFinancialData,
  handleEditRowForEntityDetails,
  handleNewRowForEntityDetails,
  handleOnCellOrCommentBlurForEntityDetails,
  handleOnRowDeleteForEntityDetails
} from '../../utils';

const LEVEL: Level = LEVELS.DISCRETE;
const STEP: Step = 'adjustment';
const STEPS: Step[] = [STEP];
const LevelAndStep = `${LEVEL}.${STEP}`;

const DiscreteAdjustment = ({ entityId }: DiscreteTabProps) => {
  const { t } = useTranslation();
  const [rows, setRows] = useState<any>([]);
  const [hasNewRow, setHasNewRow] = useState(false);
  const dispatch = useDispatch();
  const {
    params: { entityNumber }
  } = useRouteMatch<EntityNumberRouteMatch>();
  const { stepCompletionStatus } = useCompletionStatus(entityNumber, LevelAndStep, FEDERAL_UUID);
  const { tabsData, failedCells, isFetchLoading } = useFinancialData(entityNumber, LEVEL, STEPS);
  const { currencyByEntityIdMap } = useCurrencies();
  const currencyIsoCode = currencyByEntityIdMap[entityId]?.isoCode;
  const financialInfo = useMemo(() => tabsData[LevelAndStep] ?? [], [tabsData]);
  const rowNames = useMemo(() => getRowNamesFromLevelSteps(tabsData, LEVEL, STEPS)[STEP], [tabsData]);
  const rowsWithData = useMemo(() => getTableDataFromFinancialData(rowNames, financialInfo), [financialInfo, rowNames]);

  const newRow = rows[rows.length - 1];
  const rowsToSet = useMemo(() => [...rowsWithData, ...(newRow?.isNew ? [newRow] : [])], [newRow, rowsWithData]);

  useEffect(() => {
    setRows(rowsToSet);
  }, [rowsToSet]);

  const columns = getDiscreteColumns(t, !stepCompletionStatus.status);

  const dataForMethods = {
    columns,
    dispatch,
    entityId,
    financialInfo,
    hasNewRow,
    level: LEVEL,
    rows,
    setHasNewRow,
    setRows,
    step: STEP,
    t
  };

  return (
    <LoadingWrapper isLoading={isFetchLoading}>
      <ContentAndTitle
        title={
          <TabTitle
            currencyIsoCode={currencyIsoCode}
            title={t('discrete-items')}
            isCompleted={stepCompletionStatus.status}
            onCompletionChange={(checked) => {
              dispatch(
                setEntityCompletionStatus({
                  ...stepCompletionStatus,
                  newStatus: checked
                })
              );
            }}
          />
        }
      >
        <TableWithComment
          columns={columns}
          failedCells={failedCells}
          newRowButtonLabel={
            stepCompletionStatus.status ? null : t(hasNewRow ? 'Save Discrete Item' : 'Add Discrete Item')
          }
          rows={rows}
          hideActionsMenu={stepCompletionStatus.status}
          totalHeaderName={t('Total')}
          handleOnRowDelete={(params) => {
            handleOnRowDeleteForEntityDetails({
              ...dataForMethods,
              ...params
            });
          }}
          onCellChange={(params) => {
            handleEditRowForEntityDetails({
              ...dataForMethods,
              ...params
            });
          }}
          onCellOrCommentBlur={(params) => {
            handleOnCellOrCommentBlurForEntityDetails({
              ...dataForMethods,
              ...params
            });
          }}
          onNewRowClick={() => {
            handleNewRowForEntityDetails({
              ...dataForMethods
            });
          }}
        />
      </ContentAndTitle>
    </LoadingWrapper>
  );
};

export default DiscreteAdjustment;
