import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Box } from '@material-ui/core';

import { useExcel } from '../../../../hooks/useExcel';
import { Column, Container, EntityById, Report, ReportForExport, Row, TotalsRow } from '../../../../models';
import { ReportMeta } from '../../../../redux/reports';
import { useReportIcon } from '../../utils';
import ReportLayout from '../ReportLayout';
import ReportTable from '../ReportTable';
import ReportTotalsTable from '../ReportTotalsTable';

const UnifiedReportView = ({
  isSingleEntity,
  container,
  currency,
  report,
  entityById,
  columns,
  rows,
  rowsForExcel,
  totals,
  groupName,
  reportName,
  isNewReportAvailable,
  currentReportMeta,
  onReloadReportClick
}: Props) => {
  const { t } = useTranslation();
  const [reportsArrayForExport, setReportArrayForExport] = useState<ReportForExport[]>([]);
  const { name, reportModelId, subtype, modelTimestamp } = report;
  const ReportIcon = useReportIcon(subtype);
  const { exportReports } = useExcel();
  const { pathname } = useLocation();
  const closeUrl = pathname.split('/').slice(0, -1).join('/');

  const entityName: string = entityById[reportModelId]?.name;
  const entityNumber: string = entityById[reportModelId]?.entityNumber;

  const title = t(`${name} _`, {
    name: isSingleEntity ? `${entityName} (${entityNumber})` : groupName
  });

  useEffect(() => {
    const reportsArrayForExport = [
      {
        rows: rowsForExcel,
        totals,
        columns,
        reportName: t(name),
        rawName: name,
        title,
        startDate: container?.startDate,
        endDate: container?.endDate,
        currency
      }
    ];
    setReportArrayForExport(reportsArrayForExport);
  }, [rowsForExcel, totals, columns, t, name, title, container, currency]);

  const shouldBeFullWidth = columns?.length > 2;

  return (
    <ReportLayout
      hasPaperFullWidth={shouldBeFullWidth ?? !isSingleEntity}
      icon={<ReportIcon style={{ fontSize: '4rem' }} />}
      title={title}
      container={container}
      currency={currency}
      closeUrl={closeUrl}
      modelTimestamp={modelTimestamp}
      isNewReportAvailable={isNewReportAvailable}
      currentReportMeta={currentReportMeta}
      onDownloadClicked={async () => exportReports(reportsArrayForExport)}
      onReloadReportClick={onReloadReportClick}
    >
      <Box flexGrow={1} overflow="hidden">
        <ReportTable
          columns={columns}
          currency={currency}
          report={report}
          rows={rows}
          container={container}
          reportName={reportName}
        />
      </Box>
      {totals && <ReportTotalsTable rows={totals} width="30%" />}
    </ReportLayout>
  );
};

type Props = {
  isSingleEntity?: boolean;
  container?: Container;
  currency: string;
  report: Report;
  entityById: EntityById;
  columns: Column[];
  rows: Row[];
  rowsForExcel: Row[];
  totals?: TotalsRow[];
  groupName: string;
  reportName: string;
  isNewReportAvailable: boolean;
  currentReportMeta: ReportMeta;
  onReloadReportClick: () => void;
};

export default UnifiedReportView;
