import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { Container, Currency, TraceReport, TraceabilityStateInterface } from '../../models';
import HTTPService, { LambdaResponse } from '../../services/http';
import { containersOnCurrentSwitched } from '../containers';

const SLICE_NAME = 'traceability';
const FETCH_REPORT_ACTION_NAME = 'fetchReport';

export type FetchTraceabilityReportInput = {
  column: TraceabilityStateInterface['column'];
  report: TraceReport;
  row: TraceabilityStateInterface['row'];
  currency: Currency['isoCode'];
  container: Container;
  parentEntityId: string;
};

export const traceabilityInitialState: TraceabilityStateInterface = {
  column: null,
  error: null,
  isActive: false,
  isLoading: false,
  report: null,
  row: null
};

export const fetchTraceabilityReport = createAsyncThunk(
  FETCH_REPORT_ACTION_NAME,
  async ({ column, report, row, currency, container, parentEntityId }: FetchTraceabilityReportInput) => {
    const { executionId, name, reportModelId, reportTypeName } = report;
    const { field = '' } = column ?? {};
    const { columnMeta, name: accountDescription } = row ?? {};
    const reportDate = new Date(container.endDate).toISOString().split('T')[0];
    const relativePath = `/v1/reports/trace/${String(reportTypeName)}/${String(reportModelId)}/${encodeURIComponent(
      name
    )}/${reportDate}/${currency}`;
    const apiUrlKey = 'reportsApiUrl';

    const response = await HTTPService.request<LambdaResponse<TraceReport>>({
      method: 'get',
      params: {
        ...columnMeta[field],
        executionId,
        accountDescription,
        parentEntityId
      },
      apiUrlKey,
      relativePath
    });

    const data = { ...response.data };

    for (const value of data?.values ?? []) {
      if (value.type === 'symbol') {
        // `symbol` types do not have a name, but there is an assumption that
        // every value has a name. Since we don't render symbols, we assign an arbitrary name.
        value.name = 'symbol';
      }
    }

    if (column?.headerName === 'Tax Rate' && data.meta) {
      data.meta.format = 'percentage';
    }

    const state: Pick<TraceabilityStateInterface, 'column' | 'row' | 'report'> = {
      column,
      row,
      report: data
    };

    return state;
  }
);

const traceabilitySlice = createSlice({
  name: SLICE_NAME,
  initialState: traceabilityInitialState,
  reducers: {
    onCloseTraceability: (state) => {
      return { ...state, column: null, row: null, report: null };
    },
    onTraceabilitySetActive: (state) => {
      return { ...state, column: null, isActive: true, row: null };
    },
    onTraceabilitySetInactive: (state) => {
      return { ...state, column: null, isActive: false, row: null };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTraceabilityReport.pending, (state) => {
        if (!state.isLoading) {
          return { ...state, isLoading: true };
        }

        return state;
      })
      .addCase(fetchTraceabilityReport.fulfilled, (state, action) => {
        const { column, report, row } = action.payload;

        if (state.isLoading && state.isActive) {
          return {
            ...state,
            column,
            error: null,
            isLoading: false,
            report,
            row
          };
        }

        return state;
      })
      .addCase(fetchTraceabilityReport.rejected, (state, action) => {
        if (state.isLoading) {
          return { ...state, error: action.error, isLoading: false, report: null };
        }

        return state;
      })
      .addMatcher(isAnyOf(containersOnCurrentSwitched), () => {
        return traceabilityInitialState;
      });
  }
});

export const { onTraceabilitySetActive, onTraceabilitySetInactive, onCloseTraceability } = traceabilitySlice.actions;
export const traceabilityReducer = traceabilitySlice.reducer;
