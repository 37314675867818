export type GenericRecord = Record<string, any>;
export type GenericNumericalRecord = Record<string, number>;
export type GenericArrayRecord = Record<string, any[]>;

export function makeListExtractor(sourceProperty: string, listProperties: string[]) {
  return function (input: GenericArrayRecord) {
    const source = input[sourceProperty] ?? [];
    for (const property of listProperties) {
      const list: any = [];
      input[`${property}List`] = list;
      for (const data of source) {
        list.push(data[property]);
      }
    }

    return input;
  };
}

export function sum(list: Array<number | undefined | null> = []): number {
  let sum = 0;
  for (const item of list) {
    sum += item ?? 0;
  }

  return sum;
}
