import { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { TableWithPlot, TabButtons } from '.';

import { totalColor } from './TotalProvision';

import { SummaryEntityDataRow } from '../../../models';
import { abbreviateNumber, formatCurrency } from '../../../utils';
import { Tab, totalTab } from '../utils';
import { filterRowsByTab } from '../utils/data-processing/parseDashboardReport';

interface DeferredTaxAssetProps {
  reportEntityRows: SummaryEntityDataRow[];
}

const DeferredTaxAsset = ({ reportEntityRows }: DeferredTaxAssetProps) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState<Tab>(totalTab);

  const { sort, rows, columns } = useMemo(() => {
    const field = tab === totalTab ? 'deferredTaxAssetTab' : `${tab}DeferredTaxAssetTab`;

    const cols = [
      {
        placeholder: t('Entity'),
        filterable: true,
        sortable: true,
        field: 'name',
        width: '25%'
      },
      {
        headerName: t('Amount'),
        field,
        sortable: true,
        isCurrency: true,
        width: '10%'
      },
      {
        series: [{ field, color: totalColor }],
        formatTick: (value: any) => abbreviateNumber(value ? formatCurrency(value) : '0'),
        isCurrency: true
      }
    ];
    return {
      columns: cols,
      rows: filterRowsByTab(reportEntityRows, tab),
      sort: { column: tab === totalTab ? cols[1] : null, dir: TableWithPlot.ASC }
    };
  }, [tab, t, reportEntityRows]);

  return (
    <>
      <TabButtons tab={tab} worldLabel={t('Deferred Tax Asset / (Liability)')} onChange={setTab} />
      <TableWithPlot
        singleBarPlot
        columns={columns}
        sort={sort}
        filters={new Map()}
        isNotEditableShaded={false}
        rows={rows}
        variant="border-cell"
        barHeight="16px"
      />
    </>
  );
};

export default DeferredTaxAsset;
