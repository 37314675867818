import { useTranslation } from 'react-i18next';

import { Box, Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'right',
    padding: theme.spacing(1),
    '& button:first-child': {
      marginRight: theme.spacing(1)
    }
  }
}));

interface Props {
  buttonsAreDisabled: boolean;
  onCancel: () => void;
  onSave: () => void;
}

const EditEntityJurisdictionActionButtons = ({ buttonsAreDisabled, onCancel, onSave }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.buttons}>
      <Button disabled={buttonsAreDisabled} onClick={onCancel}>
        {t('Cancel')}
      </Button>
      <Button disabled={buttonsAreDisabled} variant="contained" color="primary" onClick={onSave}>
        {t('Save Changes')}
      </Button>
    </Box>
  );
};

export default EditEntityJurisdictionActionButtons;
