import { makeStyles } from '@material-ui/core';

import { ReactComponent as MenuOpenIcon } from '../../../../../assets/img/Icon_MenuOpen.svg';
import SelectedItemChip from '../../../../SelectedItemChip';

const useStyles = makeStyles(() => ({
  titleText: {
    fontSize: '14px',
    fontFamily: 'Basis Grotesque Pro Medium',
    letterSpacing: '0.25px'
  },
  mainText: {
    fontSize: '14px',
    letterSpacing: '0.25px'
  },
  icon: {
    marginLeft: '15px',
    marginRight: '5px'
  }
}));

type DropdowSelectedItemChipProps = {
  title: string;
  text: string;
  onClick: () => void;
  mainSectionRef?: any;
};

const DropdowSelectedItemChip = ({ title, text, onClick, mainSectionRef }: DropdowSelectedItemChipProps) => {
  const classes = useStyles();

  return (
    <SelectedItemChip
      className={classes}
      title={title}
      text={text}
      icon={<MenuOpenIcon data-testid="DropdowSelectedItemChipIcon" className={classes.icon} onClick={onClick} />}
      mainSectionRef={mainSectionRef}
    />
  );
};

export default DropdowSelectedItemChip;
