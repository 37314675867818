import { useDispatch } from 'react-redux';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { toggleIsRfModalOpen } from '../../redux/ui';
import { hideAddNewPeriodButtonForRoles } from '../UserRoleStylesProvider/constants';

const useStyles = makeStyles((theme) => ({
  addText: {
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(0.5, 1),
    cursor: 'pointer'
  }
}));

interface Props {
  onClick: () => void;
}

const AddNewPeriod = ({ onClick }: Props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  return (
    <Typography
      data-roles-hide-element={hideAddNewPeriodButtonForRoles.join(' ')}
      className={classes.addText}
      onClick={() => {
        dispatch(toggleIsRfModalOpen(true));
        onClick();
      }}
    >
      + Add New Period
    </Typography>
  );
};

export default AddNewPeriod;
