import { useTranslation } from 'react-i18next';

import { Box, Typography, makeStyles } from '@material-ui/core';

interface Props {
  text: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex'
  },
  chip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: '0px 10px 0px 10px',
    marginLeft: '10px',
    borderRadius: '5px'
  }
}));

const BetaChip = ({ text }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.container}>
      <Typography>{t(text)}</Typography>
      <Box className={classes.chip}>
        <Typography variant="caption">BETA</Typography>
      </Box>
    </Box>
  );
};

export default BetaChip;
