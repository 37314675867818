import { useState } from 'react';

import { useDispatch } from 'react-redux';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import tinycolor from 'tinycolor2';

import { categoryRowsReportToggle } from '../../redux/ui';

type CircleStylesProps = {
  color: string;
  darkColor: string;
  lightColor: string;
};

const useStyles = makeStyles((theme) => ({
  circle: ({ color, darkColor, lightColor }: CircleStylesProps) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    height: theme.spacing(2),
    width: theme.spacing(2),
    border: `1px solid ${color}`,
    borderRadius: '15px',
    fontSize: '12px',
    color: darkColor,
    backgroundColor: lightColor,
    cursor: 'pointer',
    paddingRight: '1px'
  }),
  circleContainer: {
    paddingRight: '8px'
  },
  paper: {
    borderRadius: '15px',
    marginLeft: '8px',
    marginTop: '-4px'
  },
  text: {
    fontSize: theme.typography.caption.fontSize,
    borderRadius: '15px',
    border: 'solid 1px black',
    minWidth: '90px',
    height: theme.spacing(2),
    paddingLeft: '10px',
    overflow: 'auto',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '10px',
    flex: 1,
    '& span': {
      fontWeight: 'bold'
    }
  }
}));

interface Props {
  color: string;
  categoryId: string;
}

const TextTooltip = ({ color, categoryId }: Props) => {
  const [lightColor, _setLightColor] = useState<string>(tinycolor(color).lighten(40).toString());
  const [darkColor, _setDarkColor] = useState<string>(tinycolor(color).darken(20).toString());
  const classes = useStyles({ color, lightColor, darkColor });
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(categoryRowsReportToggle(categoryId));
  };

  return (
    <Box className={classes.circleContainer}>
      <Box className={classes.circle} onClick={handleClick}>
        C
      </Box>
    </Box>
  );
};

export default TextTooltip;
