import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '0.25rem',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden'
  },
  title: {
    padding: theme.spacing(2, 2, 0),
    display: 'block',
    marginBottom: theme.spacing(1)
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'auto'
  },
  tabContent: {
    overflow: 'auto'
  },
  table: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '& .MuiTable-root': {
      '& .MuiTableRow-root.MuiTableRow-head:nth-child(2) .MuiTableCell-stickyHeader': {
        borderBottom: `1px solid ${theme.palette.divider}`
      },
      '& .MuiTableCell-root.MuiTableCell-head': {
        fontFamily: theme.typography.fontFamily,
        '&.MuiTableCell-stickyHeader': {
          paddingTop: '0px',
          '&:nth-child(2), &:nth-child(3)': {
            paddingBottom: '0px'
          }
        }
      },
      '& .MuiTableCell-root.MuiTableCell-body': {
        backgroundColor: theme.palette.common.white,
        borderBottom: `1px solid ${theme.palette.divider}`,
        borderRight: 'none',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        fontFamily: theme.typography.fontFamily
      },
      '& th.MuiTableCell-stickyHeader.MuiTableCell-head': {
        paddingBottom: '5px'
      }
    }
  },
  colorSpan: {
    fontSize: theme.typography.body1.fontSize,
    padding: theme.spacing(0.3),
    borderRadius: '4px',
    '&.info': {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.info.dark
    },
    '&.warning': {
      backgroundColor: '#FEF7E0',
      color: '#82703A'
    }
  },
  statusColumn: {
    textAlign: 'center',
    display: 'block',
    width: '100%'
  },
  actionColumn: {
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    padding: '4px 14px',
    '& .MuiSvgIcon-root': {
      width: theme.typography.body1.fontSize,
      marginLeft: '5px'
    }
  },
  noUploads: {
    color: theme.palette.info.dark,
    textAlign: 'center',
    padding: theme.spacing(2),
    width: '100%'
  }
}));
