import { Row } from '../../../../models';
import { formatPercentage } from '../../../../utils';

const handleReportLineItemValueFormatting = (row: Row, percentageFields: string[], reportName: string) => {
  const formattedObject = {};
  Object.entries(row).forEach(([key, value]) => {
    handleSpecialFormattingCases({ key, value, reportName, percentageFields, formattedObject, row });
  });

  return formattedObject;
};

interface getSpecialFormatConditionsProps {
  isRetrievingFormat: boolean;
  reportName?: string;
  columnName?: string;
  rowName?: string;
  value?: any;
}
// eslint-disable-next-line complexity
export const getSpecialFormatConditions = ({
  isRetrievingFormat,
  reportName,
  columnName,
  rowName,
  value
}: getSpecialFormatConditionsProps) => {
  let condition;
  let format;
  const ETRReportName = ['beginning-state-etr', 'current-state-etr', 'end-state-etr'];
  if (reportName === 'state-rtp') {
    condition =
      (rowName === 'Apportionment Factor' || rowName === 'Prior Year State Tax Rate') &&
      (columnName?.includes('stateRtpFinancials_taxProvision_') ||
        columnName?.includes('stateRtpFinancials_taxReturn_') ||
        columnName?.includes('stateRtpFinancials_grossVariance_'));
    format = formatPercentage(value, '-', 4);
  } else if (reportName === 'federal-rtp') {
    condition =
      rowName === 'Prior Year Federal Tax Rate' &&
      (columnName?.includes('taxProvision_') ||
        columnName?.includes('taxReturn_') ||
        columnName?.includes('grossVariance_'));
    format = formatPercentage(value, '-', 4);
  } else if (reportName === 'rate-reconciliation-summary' || reportName === 'rate-reconciliation-detail') {
    condition = columnName?.includes('taxRate');
    format = formatPercentage(value, '-', 4);
  } else if (reportName === 'state-current-provision') {
    if (rowName === 'Apportionment Factor') {
      condition = rowName === 'Apportionment Factor';
      format = formatPercentage(value, '-', 4);
    }

    if (rowName === 'Current State Tax Rate') {
      condition = rowName === 'Current State Tax Rate';
      format = formatPercentage(value, '-', 4);
    }
  } else if (
    reportName === 'federal-current-provision' ||
    reportName === 'federal-current-provision-state-tax-deduction-rtp'
  ) {
    condition = rowName === 'Current Federal Tax Rate';
    format = formatPercentage(value, '-', 4);
  } else if (reportName && ETRReportName.includes(reportName)) {
    condition = true; // all values in report need this rule
    format = formatPercentage(value, '-', 4);
  } else if (reportName === 'summary') {
    if (rowName === 'Effective Tax Rate') {
      condition = rowName === 'Effective Tax Rate';
      format = formatPercentage(value, '-', 4);
    }
  } else {
    return false;
  }

  return isRetrievingFormat && condition ? format : condition;
};

interface handleSpecialFormattingCasesProps {
  key: any;
  value: any;
  reportName: string;
  percentageFields: string[];
  formattedObject: any;
  row: Row;
}

// usingSpecializedFormatting comes from pro-modules report definition
const handleSpecialFormattingCases = ({
  key,
  value,
  reportName,
  percentageFields,
  formattedObject,
  row
}: handleSpecialFormattingCasesProps) => {
  if (Number(value) && value !== 0 && Boolean(row.usingSpecializedFormatting)) {
    const specialFormat = getSpecialFormatConditions({
      isRetrievingFormat: true,
      reportName,
      columnName: key,
      rowName: row.name,
      value
    });
    if (specialFormat) {
      return Object.assign(formattedObject, {
        [key]: specialFormat
      });
    }
  }

  const isPercentageValue = percentageFields.includes(key) || row.format === 'percentage';
  if (isPercentageValue && typeof value === 'number' && value !== 0) {
    return Object.assign(formattedObject, { [key]: formatPercentage(value) });
  }

  return Object.assign(formattedObject, { [key]: value });
};

export default handleReportLineItemValueFormatting;
