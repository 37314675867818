import { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { useContainers, useEntity } from '..';
import { discreteSteps, federalSteps, stateSteps, NULL_UUID, FinalizedContainerStatuses } from '../../constants';
import {
  EntityCompletionStatus,
  Level,
  Step,
  ContainerCompletionStatusFromApi,
  StepCompletionStatusReturnType
} from '../../models';
import {
  completionStatusOnFailed,
  completionStatusOnReceived,
  completionStatusOnSendingRequest,
  EntitiesCompletionStatusStateInterface
} from '../../redux/entitiesCompletionStatus';
import { selectEntities } from '../../selectors/entities';
import { selectEntitiesCompletionStatus } from '../../selectors/entitiesCompletionStatus';
import HTTPService, { LambdaResponse } from '../../services/http';
import { turnToObjByKey } from '../../utils';
export interface EntityCompletionStatusReturnType extends EntityCompletionStatus {
  numOfStates: number;
}

export function useCompletionStatus(entityNumber: string): { entityCompletionStatus: EntityCompletionStatusReturnType };
export function useCompletionStatus(
  entityNumber: string,
  entityCompletionStatusRef: string,
  subJurisdictionId?: string
): { entityCompletionStatus: EntityCompletionStatusReturnType; stepCompletionStatus: StepCompletionStatusReturnType };

export function useCompletionStatus(
  entityNumber: string,
  entityCompletionStatusRef?: string,
  subJurisdictionId?: string
) {
  const dispatch = useDispatch();
  const { currentContainer } = useContainers();
  const { entity } = useEntity(entityNumber);
  const entitiesCompletionStatusState: EntitiesCompletionStatusStateInterface = useSelector(
    selectEntitiesCompletionStatus
  );
  const entitiesState = useSelector(selectEntities);
  const entitiesMapById = turnToObjByKey(entitiesState.entities ?? [], 'entityNumber');
  useEffect(() => {
    async function fetchData() {
      // Make sure entities are queried before executing this execute-once code
      if (!entitiesState.isLoaded) return;
      dispatch(completionStatusOnSendingRequest());
      try {
        const isContainerFinalized = currentContainer
          ? FinalizedContainerStatuses.includes(currentContainer.containerStatus) || currentContainer.isFinalized
          : true;

        if (currentContainer && !isContainerFinalized) {
          const { data } = await HTTPService.request<LambdaResponse<ContainerCompletionStatusFromApi>>({
            method: 'get',
            relativePath: `/v1/completion-status`
          });
          dispatch(
            completionStatusOnReceived({
              completionStatus: data
            })
          );
          return;
        }

        const shouldSpecifySubJurId = new Set(
          stateSteps.filter((step) => ['credits', 'nol', 'rtp', 'accounts'].includes(step))
        );
        const finalizedContainerData = Object.fromEntries(
          Object.values(entitiesMapById).map((entity) => {
            return [
              entity.entityNumber,
              {
                completionStatus: {
                  state: Object.fromEntries(
                    stateSteps.map((step) => [
                      step,
                      Object.fromEntries(
                        (shouldSpecifySubJurId.has(step)
                          ? entity.subJurisdictionIds
                          : [NULL_UUID]
                        ).map((subJurisdictionId) => [subJurisdictionId, true])
                      )
                    ])
                  ),
                  federal: Object.fromEntries(
                    federalSteps.map((step) => [
                      step,
                      {
                        [NULL_UUID]: true
                      }
                    ])
                  ),
                  discrete: Object.fromEntries(
                    discreteSteps.map((step) => [
                      step,
                      {
                        [NULL_UUID]: true
                      }
                    ])
                  )
                }
              }
            ];
          })
        );

        dispatch(
          completionStatusOnReceived({
            completionStatus: finalizedContainerData
          })
        );
      } catch (error: unknown) {
        dispatch(completionStatusOnFailed(error));
      }
    }

    if (
      !entitiesCompletionStatusState.isLoading &&
      !entitiesCompletionStatusState.hasLoaded &&
      !entitiesCompletionStatusState.error
    ) {
      void fetchData();
    }
  }, [
    entitiesCompletionStatusState,
    entityNumber,
    currentContainer,
    entitiesMapById,
    entitiesState.isLoaded,
    dispatch
  ]);

  const entityCompletionStatus = entitiesCompletionStatusState.entitiesCompletionStatus[entityNumber];
  let isStepCompleted = false;

  if (entityCompletionStatus && entityCompletionStatusRef && typeof subJurisdictionId !== 'undefined') {
    const levelStepArray = entityCompletionStatusRef.split('.');
    const level = levelStepArray[0] as Level;
    const step = levelStepArray[1] as Step;
    const stepCompletionStatus = entityCompletionStatus?.completionStatus?.[level]?.[step] ?? {};

    isStepCompleted = Boolean(stepCompletionStatus[subJurisdictionId]);
  }

  return {
    entityCompletionStatus: {
      ...entitiesCompletionStatusState.entitiesCompletionStatus[entityNumber],
      numOfStates: entity?.subJurisdictionIds?.length ?? 0
    },
    stepCompletionStatus: {
      entityId: entity?.entityId,
      entityNumber,
      subJurisdictionId,
      entityCompletionStatusRef,
      currentContainer,
      status: isStepCompleted
    }
  };
}
