import { Box, CircularProgress, CircularProgressProps, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  progressContainer: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  }
}));

interface Props {
  size?: CircularProgressProps['size'];
}

export const LoadingSpinner = (props: Props) => (
  <Box className={useStyles({}).progressContainer}>
    <CircularProgress {...props} />
  </Box>
);

export default LoadingSpinner;
