import { headersObjectToSortedArray, concatHeaderAndAddToMap, reportsWithTripleHeaders } from './useHeaderProcessor';

import { EntityById, Header, Report } from '../../../../models';
import isUUID from '../isUUID';

export function processHeaders({ entityById, report }: { entityById: EntityById; report: Report }) {
  let { headers, reportTypeName, avoidEntityNameConcatenation } = report;

  headers = headers.map((header) => ({ ...header, isNumber: !header.format || header.format !== 'percentage' }));

  if (reportTypeName === 'multi-entity' && reportsWithTripleHeaders.has(report.name)) {
    let newHeaders: Header[] = [];
    let headerGroupsByEntity: Record<string, Header[]> = {};
    let lastGroup: string | undefined = '';
    headers.forEach((header: Header) => {
      if (lastGroup && lastGroup !== header.headerDisplayGroup) {
        newHeaders = [...newHeaders, ...headersObjectToSortedArray(headerGroupsByEntity)];
        headerGroupsByEntity = {};
      }

      lastGroup = header.headerDisplayGroup;

      const headerFieldSplitted = header.field?.split('_') ?? [];
      const entityId = headerFieldSplitted.find((headerPart) => isUUID(headerPart));
      if (entityId && !avoidEntityNameConcatenation) {
        headerGroupsByEntity = concatHeaderAndAddToMap(header, entityId, entityById, headerGroupsByEntity);
      } else {
        newHeaders = [...newHeaders, ...headersObjectToSortedArray(headerGroupsByEntity)];
        newHeaders = [...newHeaders, header];
        headerGroupsByEntity = {};
      }
    });
    headers = [...newHeaders, ...headersObjectToSortedArray(headerGroupsByEntity)];
  }

  return headers;
}
