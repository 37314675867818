import * as d3 from 'd3';

import { wrap } from './utils';

// https://bl.ocks.org/Rdilorenzo73/d3ef804fca7ed0ddaf67a0fb74f76682#index.html

type UnionType = string | number | boolean | null;

export function drawGroupBarChartJson(
  svgRef: SVGSVGElement | null,
  {
    width: w,
    height: h,
    colors,
    data,
    onEnter,
    onLeave,
    classes
  }: {
    width: number;
    height: number;
    colors: any;
    data: any;
    onEnter: any;
    onLeave: any;
    classes: any;
  }
) {
  const margin = { top: 8, right: 0, bottom: 48, left: 48 };
  const width = w - margin.left - margin.right;
  const height = h - margin.top - margin.bottom;

  const svg = d3.select(svgRef).attr('viewBox', [0, 0, w, h] as any);
  const g = svg.selectAll('g').remove() && svg.append('g').attr('transform', `translate(${margin.left},${margin.top})`);
  const valuesArray = data.map((e: any) =>
    Object.values({ taxRate: e.taxRate, taxRateComparison: e.taxRateComparison })
  );
  const values = Array.prototype.concat.apply([], [...valuesArray]);

  const marginAroundOrigin = 0.05;
  let [min, max] = [d3.min(values), d3.max(values)];
  if (min < 0 && min > -marginAroundOrigin) {
    min -= marginAroundOrigin;
  }

  if (max > 0 && max < marginAroundOrigin) {
    max += marginAroundOrigin;
  }

  const y = d3.scaleLinear().domain([min, max]).range([height, 0]);
  const x0 = d3.scaleBand().rangeRound([0, width]).paddingInner(0.5).paddingOuter(0.5);
  const x1 = d3.scaleBand().paddingInner(0.35);
  const z = d3.scaleOrdinal().range([colors.mainBackground, colors.secondaryBackground]);
  const yAxis = d3.axisLeft(y).tickFormat((x: any) => `${x as string}%`);
  const subCategories = data.length > 0 ? Object.keys(data[0]).slice(1) : [];

  x0.domain(data.map((d: any) => d.name));
  x1.domain(subCategories).rangeRound([0, x0.bandwidth()]);

  const selection = g
    .selectAll('g')
    .data(data)
    .enter()
    .append('g')
    .attr('transform', (d: any) => `translate(${String(x0(d.name))},0)`);

  selection
    .selectAll('rect')
    .data((d: any) => subCategories.map((key) => ({ key, value: d[key], ...d })))
    .enter()
    .append('rect')
    .attr('class', classes.chartBar)
    .attr('x', (d: any) => x1(d.key) as UnionType)
    .attr('y', (d: any) => (d.value < 0 ? y(0) : y(d.value)))
    .attr('width', x1.bandwidth())
    .attr('height', (d: any) => Math.abs(y(d.value) - y(0)))
    .attr('fill', (d: any) => z(d.key) as UnionType)
    .on('mousemove', (event: MouseEvent, d: any) => {
      onEnter(event, d);
    })
    .on('mouseout', (event: MouseEvent, d: any) => {
      onLeave(event, d);
    });

  g.append('g')
    .attr('class', classes.chartAxis)
    .attr('transform', `translate(0,${height})`)
    .call(d3.axisBottom(x0))
    .selectAll('.tick text')
    .call(wrap, Number(x0.bandwidth()) + Number(x0.bandwidth()));

  g.append('g').attr('class', classes.chartAxis).call(yAxis);
  if (data.length > 0) {
    g.append('line').attr('y1', y(0)).attr('y2', y(0)).attr('x1', 0).attr('x2', width).attr('stroke', colors.border);
  }
}
