import { Column } from '../../../../models';

const composeTableColumnsWithDividers = (columns: Column[]): Column[] => {
  return columns.flatMap((column: Column, index: number) => {
    const isNewSection =
      Boolean(column.type[index - 1] && columns[index + 1]) && column?.type !== columns[index - 1]?.type;

    if (isNewSection) {
      return [
        {
          className: 'gap',
          headerName: ' ',
          field: ' '
        },
        column
      ];
    }

    return column;
  });
};

export default composeTableColumnsWithDividers;
