import { ReportForXls } from './utils';

import { isPercentage, unFormatPercentage } from '../../utils';

interface locationProps {
  currentRow: string;
  previousRow?: string;
  subsequentRow?: string;
}
export function findValidRow(report: ReportForXls, location: locationProps) {
  const reportsThatDoNotFormatOnRow = [
    'rate-reconciliation-summary',
    'rate-reconciliation-detail',
    'beginning-state-etr',
    'current-state-etr',
    'end-state-etr'
  ];
  if (report.rawName === 'state-rtp') {
    const isStateRtpApportionment =
      location.currentRow.toLowerCase() === 'apportionment factor' &&
      location?.previousRow?.toLowerCase() === 'pre-apportioned state taxable income' &&
      location?.subsequentRow?.toLowerCase() === 'post-apportioned state taxable income/(nol) before nol';
    const isStateRtpPriorTaxRate =
      location.currentRow.toLowerCase() === 'prior year state tax rate' &&
      location?.previousRow?.toLowerCase() === 'post-apportioned state taxable income/(nol)' &&
      location?.subsequentRow?.toLowerCase() === 'state tax expense/(benefit) before credits';
    return isStateRtpApportionment || isStateRtpPriorTaxRate;
  }

  if (report.rawName === 'federal-rtp') {
    const isFRtpPriorTaxRate =
      location.currentRow.toLowerCase() === 'prior year federal tax rate' &&
      location?.previousRow?.toLowerCase() === 'federal taxable income/(loss)' &&
      location?.subsequentRow?.toLowerCase() === 'federal tax expense/(benefit) before credits';
    return isFRtpPriorTaxRate;
  }

  if (report.rawName === 'state-current-provision') {
    const isScpApportionment =
      location.currentRow.toLowerCase() === 'apportionment factor' &&
      location?.previousRow?.toLowerCase() === 'pre-apportioned state taxable income' &&
      location?.subsequentRow?.toLowerCase() === 'post-apportioned state taxable income/(nol) before nol';
    const isScpCurrentTaxRate =
      location.currentRow.toLowerCase() === 'current state tax rate' &&
      location?.previousRow?.toLowerCase() === 'post-apportioned state taxable income/(nol)' &&
      location?.subsequentRow?.toLowerCase() === 'state tax expense/(benefit) before credits';
    return isScpApportionment || isScpCurrentTaxRate;
  }

  if (
    report.rawName === 'federal-current-provision' ||
    report.rawName === 'federal-current-provision-state-tax-deduction-rtp'
  ) {
    const isFcpCurrentTaxRate =
      location.currentRow.toLowerCase() === 'current federal tax rate' &&
      location?.previousRow?.toLowerCase() === 'federal taxable income/(loss)' &&
      location?.subsequentRow?.toLowerCase() === 'federal tax expense/(benefit) before credits';
    return isFcpCurrentTaxRate;
  }

  return reportsThatDoNotFormatOnRow.includes(report.rawName);
}

// Negative percentage has parenthesis in string data type that exceljs cannot read. need to remove them and have the excel format handle it
const transformValue = (value: any) => {
  let valueToTransform = value;

  if (typeof value === 'string' && value.includes('(') && value.includes(')')) {
    valueToTransform = unFormatPercentage(valueToTransform.replace(/[()]/g, ''));
    valueToTransform = Math.abs(valueToTransform) * -1;
    return valueToTransform;
  }

  return unFormatPercentage(valueToTransform);
};

interface formatSpecificCellProps {
  name: string;
  value: any;
  columnIndex: string;
  headers: string[];
  isSpecializedFormat: boolean;
  itemValue: string;
  isReportCategoryTotal: boolean;
}
// eslint-disable-next-line complexity
export function formatSpecificCell(cell: formatSpecificCellProps): [any, string] {
  const cellColumn: string = cell.headers[Number(cell.columnIndex) - 1];
  const stateEffectiveTaxRateNames = ['beginning-state-etr', 'current-state-etr', 'end-state-etr'];
  const stateEffectiveTaxRateRowNames = ['beginning apportionment', 'current apportionment', 'ending apportionment'];
  // this is meant to just check if the value has a percentage, not being used as part of value transformation
  const percentageWithoutParenthesis = typeof cell.value === 'string' ? cell.value.replace(/[()]/g, '') : cell.value;
  const isCellPercentage = isPercentage(percentageWithoutParenthesis);

  // For textual and values that are empty also avoiding the percentages and negative percentages that are string
  // This is handling report table and total sections
  const nonNumeralCheck =
    (typeof cell.value === 'string' && cellColumn === 'Item') ||
    !cell.value ||
    (typeof cell.value === 'string' && !isCellPercentage);
  if (nonNumeralCheck) {
    const itemCategoryCheck =
      cell.isReportCategoryTotal && Number(cell.columnIndex) === 1 ? `${String(cell.value)} Total` : cell.value;

    return [itemCategoryCheck, ''];
  }

  const percentageFormat = '###0.0000%;(###0.0000%)';
  const value = isCellPercentage ? transformValue(cell.value) : cell.value;
  const format = isCellPercentage ? percentageFormat : '#,##0;(#,##0)';

  if (cell.isSpecializedFormat) {
    if (
      cell.name === 'state-rtp' &&
      (cellColumn.includes('Tax Provision') ||
        cellColumn.includes('Tax Return') ||
        cellColumn.includes('Gross Variance'))
    ) {
      return [value, '###0.0000%;(###0.0000%)'];
    }

    if (
      cell.name === 'federal-rtp' &&
      (cellColumn.includes('Tax Provision') ||
        cellColumn.includes('Tax Return') ||
        cellColumn.includes('Gross Variance'))
    ) {
      return [value, percentageFormat];
    }

    if (cell.name === 'state-current-provision') {
      if (cell.itemValue.toLowerCase() === 'apportionment factor') {
        return [value, '###0.0000%;(###0.0000%)'];
      }

      if (cell.itemValue.toLowerCase() === 'current state tax rate') {
        return [value, format];
      }
    }

    if (
      cell.name === 'federal-current-provision' ||
      cell.name === 'federal-current-provision-state-tax-deduction-rtp'
    ) {
      return [value, format];
    }

    if (
      (cell.name === 'rate-reconciliation-summary' || cell.name === 'rate-reconciliation-detail') &&
      cellColumn === 'Tax Rate'
    ) {
      return [value, format];
    }

    if (stateEffectiveTaxRateNames.includes(cell.name)) {
      if (stateEffectiveTaxRateRowNames.includes(cell.itemValue.toLowerCase())) {
        return [value, '###0.0000%;(###0.0000%)'];
      }

      return [value, format];
    }
  }

  return isCellPercentage ? [value, format] : [Math.round(value), format];
}
