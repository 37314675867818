import AddNewPeriodModal from '../AddNewPeriodModal';

/* This component will hold all modals that doesn't have *
 * A specific parent, meaning they should should up no   *
 * matter what part of the app are you in right now.     *
 * example for a modal WITH parent: Confirmation modal   *
 * for an action on this specific part of the app.       *
 * example for a modal WITHOUT a parent: Inactivity      *
 * warning modal that tells you you are about to be      *
 * logged out if you don't interact with the page        */

const ModalsContainer = () => {
  return (
    // TODO: this warning can be removed once we have more modals
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <AddNewPeriodModal />
    </>
  );
};

export default ModalsContainer;
