import React, { MouseEvent, useEffect, useRef, useState } from 'react';

import { ChromePicker, ColorResult } from 'react-color';

import { Box, Button, makeStyles, Popover } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';

import ColorCicle from './components/ColorCircle';

import theme from '../../theme';

const useStyles = makeStyles((theme) => ({
  selector: {
    width: '67px',
    height: '40px',
    '& .MuiSelect-selectMenu': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    }
  },
  menuItem: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    width: '67px',
    height: '45px',
    padding: '0',
    display: 'flex',
    backgroundColor: theme.palette.common.white,
    border: `solid 1px ${theme.palette.divider}`,
    borderRadius: '4px',
    boxSizing: 'content-box',
    '&.MuiButtonBase-root:hover': {
      border: `solid 1px ${theme.palette.common.black}`,
      backgroundColor: theme.palette.secondary.light
    },
    '& .MuiButton-label': {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    '& .MuiButton-endIcon': {
      marginRight: '5px',
      right: '0px',
      justifySelf: 'flex-end'
    }
  },
  circleContainer: {
    display: 'flex'
  }
}));

interface Props {
  categoryColor?: string;
  onSelect: (color: string) => void;
}

const ColorPicker = ({ categoryColor, onSelect }: Props) => {
  const classes = useStyles();
  const [color, setColor] = useState<string>(categoryColor ?? theme.palette.primary.main);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const containerRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen && containerRef?.current) {
      containerRef.current.focus();
    }
  }, [isOpen]);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpen((open) => !open);
  };

  const handleChange = (selectedColor: ColorResult) => {
    setColor(selectedColor.hex);
    onSelect(selectedColor.hex);
  };

  const handleblur = (event: any) => {
    if (!event.relatedTarget) {
      setIsOpen(false);
    }
  };

  return (
    <Box>
      <Button variant="contained" className={classes.button} endIcon={<KeyboardArrowDown />} onClick={handleClick}>
        <Box className={classes.circleContainer}>
          <ColorCicle color={color} />
        </Box>
      </Button>

      <Box ref={containerRef} tabIndex={0} onBlur={handleblur}>
        <Popover
          open={isOpen}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left'
          }}
        >
          <ChromePicker disableAlpha color={color} onChange={handleChange} />
        </Popover>
      </Box>
    </Box>
  );
};

export default ColorPicker;
