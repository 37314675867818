import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Box, Button } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ContentAndTitle, StateSelector, TabTitle } from '..';
import { TableWithComment } from '../../..';
import { ReactComponent as NotEqualIcon } from '../../../../assets/img/IconNotEqual.svg';
import { calculateEndingBalanceForCredits } from '../../../../calculations';
import { LEVELS, reservedNames } from '../../../../constants';
import { useCompletionStatus, useCurrencies, useFinancialData } from '../../../../hooks';
import { Column, Row, StateTabProps, Step, SubJurisdictionWithCompletion } from '../../../../models';
import { setEntityCompletionStatus } from '../../../../redux/entitiesCompletionStatus';
import LoadingWrapper from '../../../LoadingWrapper';
import { renderCell } from '../../../Table/components/TableBody';
import { FailedCells, TableProps } from '../../../Table/Table.proptype';
import { hideElementForRoles } from '../../../UserRoleStylesProvider/constants';
import { CreditRow, getCreditRows } from '../../creditUtils';
import useClickOutsideDialog from '../../hooks/useClickOutsideDialog';
import {
  EntityNumberRouteMatch,
  handleEditRowForEntityDetails,
  handleNewRowForEntityDetails,
  handleOnCellOrCommentBlurForEntityDetails,
  handleOnRowDeleteForEntityDetails,
  isCreditNameInFinancials
} from '../../utils';
import BalanceCalculationsModal from '../BalanceCalculationsModal';
import InterlinkedDataDialog from '../InterlinkedDataDialog';
import RtpColumnIcons from '../RtpColumnIcons';

const LEVEL = LEVELS.STATE;
const STEP: Step = 'credits';
const STEPS: Step[] = [STEP, 'rtp'];
const LevelAndStep = `${LEVEL}.${STEP}`;

const StateCredits = ({ entityId, states }: StateTabProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currencyByEntityIdMap } = useCurrencies();
  const currencyIsoCode = currencyByEntityIdMap[entityId]?.isoCode;
  const [currentState, setCurrentState] = useState<SubJurisdictionWithCompletion>();
  const [statesWithCompletion, setStatesWithCompletion] = useState<SubJurisdictionWithCompletion[]>([]);
  const [rows, setRows] = useState<CreditRow[]>([]);
  const [hasNewRow, setHasNewRow] = useState(false);
  const [hasNewCredit, setHasNewCredit] = useState(false);
  const [creditNameOfNewRow, setCreditNameOfNewRow] = useState('');
  const [newCreditName, setNewCreditName] = useState('');
  const [isCreditNameDuplicated, setIsCreditNameDuplicated] = useState<boolean>(false);
  const [isCreditNameReservedName, setIsCreditNameReservedName] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [creditNameForModal, setCreditNameForModal] = useState('');
  const [isBalanceModalOpen, setIsBalanceModalOpen] = useState(false);

  const {
    params: { entityNumber }
  } = useRouteMatch<EntityNumberRouteMatch>();
  const { entityCompletionStatus, stepCompletionStatus } = useCompletionStatus(
    entityNumber,
    LevelAndStep,
    currentState?.id
  );
  const { completionStatus: stateCompletionStatus } = entityCompletionStatus;
  const { tabsData, failedCells, isFetchLoading } = useFinancialData(entityNumber, LEVEL, STEPS, currentState?.id);
  const financialInfo = useMemo(() => tabsData[LevelAndStep] ?? [], [tabsData]);
  const rtpFinancialInfo = useMemo(() => tabsData['state.rtp'] ?? [], [tabsData]);
  const dialogRef = useRef<HTMLDivElement | null>(null);
  const tableRef = useRef<HTMLDivElement | null>(null);
  const anchorEl = tableRef.current;
  const {
    prov2818InterlinkData,
    prov2826TarfEnhancements,
    prov3322EditPerformanceFix: isPerformanceFixEnabled,
    prov3736ReservedWordValidation: showReservedWordsError,
    prov4011TotalRowFix
  } = useFlags();
  const history = useHistory();

  const getRtpDifference = useCallback(
    (creditName: string) => {
      const creditsTaxReturnRecord = rtpFinancialInfo.find(
        (record) => record.columnName === 'taxReturn' && record.rowName === creditName
      );
      const creditsTaxProvisionRecord = rtpFinancialInfo.find(
        (record) => record.columnName === 'taxProvision' && record.rowName === creditName
      );
      const creditsTaxReturnValue = creditsTaxReturnRecord ? Number(creditsTaxReturnRecord.value) : 0;
      const creditsTaxProvisionValue = creditsTaxProvisionRecord ? Number(creditsTaxProvisionRecord.value) : 0;
      const rtpCreditsDifference = creditsTaxReturnValue - creditsTaxProvisionValue;
      return rtpCreditsDifference;
    },
    [rtpFinancialInfo]
  );

  const shouldShowRtpIcon = prov2818InterlinkData;
  const columns = useMemo(() => {
    return [
      {
        field: 'name',
        headerName: t('Tax Period'),
        isNewRowEditable: true,
        className: 'creditTaxPeriodColumn'
      },
      {
        field: 'beginningBalance',
        headerName: t('Beginning Balance'),
        isEditable: !stepCompletionStatus.status,
        isNumber: true
      },
      {
        field: 'rtp',
        headerName: t('Return to Provision'),
        isEditable: !stepCompletionStatus.status,
        isNumber: true,
        shouldShowRtpIcon,
        renderCell: (
          row: Row,
          value: number,
          column: Column,
          failedCells: FailedCells,
          onCellChange: TableProps['onCellChange'],
          onCellOrCommentBlur: TableProps['onCellOrCommentBlur'],
          renderOpts: any,
          rows: Row[],
          onCellClick?: TableProps['onCellClick']
          // eslint-disable-next-line max-params
        ) => {
          return shouldShowRtpIcon ? (
            <RtpColumnIcons
              {...{
                ignoreRenderCell: true,
                isTotalRow: row.total === true || row.isTotal === true,
                value,
                column,
                failedCells,
                renderOpts,
                rows,
                row,
                totalDifference: getRtpDifference(row.isTotal ? row?.credits?.[0]?.creditName : undefined),
                disableButton: hasNewRow,
                onCellChange,
                onCellOrCommentBlur,
                onCellClick,
                handleIconButtonClick: () => {
                  setCreditNameForModal(row?.credits[0].creditName);
                  setIsDialogOpen((prevIsDialogOpen) => !prevIsDialogOpen);
                }
              }}
            />
          ) : (
            renderCell(
              row,
              value,
              column,
              failedCells,
              onCellChange,
              onCellOrCommentBlur,
              renderOpts,
              rows,
              '',
              onCellClick,
              true
            )
          );
        }
      },
      {
        field: 'generatedAmount',
        headerName: t('Amount Generated in Current Year'),
        isEditable: !stepCompletionStatus.status,
        isNumber: true
      },
      {
        field: 'usedAmount',
        headerName: t('Amount (Used) in Current Year'),
        isEditable: !stepCompletionStatus.status,
        isNumber: true
      },
      {
        field: 'deferredOnlyAdjustment',
        headerName: t('Deferred Only Adjustment'),
        isEditable: !stepCompletionStatus.status,
        isNumber: true
      },
      ...(prov2826TarfEnhancements
        ? [
            {
              field: 'oci',
              headerName: t('OCI'),
              isEditable: !stepCompletionStatus.status,
              isNumber: true
            },
            {
              field: 'goodwill',
              headerName: t('Goodwill'),
              isEditable: !stepCompletionStatus.status,
              isNumber: true
            },
            {
              field: 'fin48',
              headerName: t('FIN48'),
              isEditable: !stepCompletionStatus.status,
              isNumber: true
            }
          ]
        : []),

      {
        field: 'balanceSheetOnlyAdjustment',
        headerName: t('Balance Sheet Only Adjustment'),
        isEditable: !stepCompletionStatus.status,
        isNumber: true
      },
      {
        field: 'endingBalance',
        headerName: t('Ending Balance'),
        getValue: (row: any) =>
          'endingBalance' in row ? row.endingBalance : calculateEndingBalanceForCredits(row).endingBalance,
        isNumber: true
      },
      {
        field: 'remaining',
        headerName: t('Carry Over Period Remaining'),
        isEditable: !stepCompletionStatus.status,
        isNumber: true
      }
    ];
  }, [getRtpDifference, hasNewRow, prov2826TarfEnhancements, shouldShowRtpIcon, stepCompletionStatus.status, t]);

  useClickOutsideDialog(dialogRef, setIsDialogOpen);
  const handleClose = () => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    if (!isBalanceModalOpen) setCreditNameForModal('');
  }, [isBalanceModalOpen]);

  useEffect(() => {
    setIsCreditNameDuplicated(isCreditNameInFinancials(financialInfo, newCreditName));
  }, [financialInfo, newCreditName]);

  useEffect(() => {
    setIsCreditNameReservedName(showReservedWordsError && reservedNames.includes(newCreditName.toLowerCase()));
  }, [newCreditName, showReservedWordsError]);

  useEffect(() => {
    if (creditNameOfNewRow) {
      const dataForMethods = {
        columns,
        dispatch,
        entityId,
        financialInfo,
        hasNewRow,
        jurisdictionId: currentState?.id,
        level: LEVEL,
        rows,
        setHasNewRow,
        setRows,
        step: STEP,
        t,
        showReservedWordsError
      };
      handleNewRowForEntityDetails({
        ...dataForMethods,
        creditName: creditNameOfNewRow
      });
      setCreditNameOfNewRow('');
    }
  }, [
    columns,
    dispatch,
    entityId,
    financialInfo,
    hasNewRow,
    rows,
    setRows,
    creditNameOfNewRow,
    currentState,
    t,
    showReservedWordsError
  ]);

  const newRow = rows.find((row) => row.isNew);

  useEffect(() => {
    const dataForRows = {
      financialInfo,
      newRow,
      hasNewCredit,
      isCompleted: stepCompletionStatus.status,
      onNewRowClicked: setCreditNameOfNewRow,
      setNewCreditName,
      t,
      isCreditNameDuplicated,
      isCreditNameReservedName,
      newCreditName,
      prov4011TotalRowFix
    };
    const rowsToSet = getCreditRows(dataForRows);
    setRows(rowsToSet);
  }, [
    financialInfo,
    stepCompletionStatus.status,
    newRow,
    hasNewCredit,
    t,
    isCreditNameDuplicated,
    isCreditNameReservedName,
    newCreditName,
    prov4011TotalRowFix
  ]);

  useEffect(() => {
    if (!hasNewRow) {
      setHasNewCredit(false);
      setNewCreditName('');
    }
  }, [hasNewRow]);

  useEffect(() => {
    const withCompletion = states?.map((state) => ({
      ...state,
      isCompleted: Boolean(stateCompletionStatus?.state?.credits?.[state.id])
    }));

    setStatesWithCompletion(withCompletion);

    if (!currentState) {
      setCurrentState(withCompletion[0]);
    }
  }, [states, currentState, stateCompletionStatus]);

  const dataForMethods = {
    columns,
    dispatch,
    entityId,
    financialInfo,
    hasNewRow,
    jurisdictionId: currentState?.id,
    level: LEVEL,
    rows,
    setHasNewRow,
    setRows,
    step: STEP,
    t,
    showReservedWordsError
  };

  const handleAddNewCredit = () => {
    setHasNewCredit(true);
    handleNewRowForEntityDetails({
      ...dataForMethods
    });
  };

  const handleSaveNewCredit = () => {
    if (newCreditName.length > 0) {
      const newRows = rows.map((row) => ({ ...row, creditName: row.isNew ? newCreditName : row.creditName }));
      handleNewRowForEntityDetails({
        ...dataForMethods,
        rows: newRows
      });
    }
  };

  const shouldHideNewRowButton = (hasNewRow && !hasNewCredit) || stepCompletionStatus.status;

  return (
    <>
      <LoadingWrapper isLoading={isFetchLoading}>
        <ContentAndTitle
          title={
            currentState ? (
              <TabTitle
                currencyIsoCode={currencyIsoCode}
                title={
                  <>
                    {t('credits')}
                    <Box component="span" ml={2}>
                      {currentState?.name}
                    </Box>
                  </>
                }
                isCompleted={stepCompletionStatus.status}
                onCompletionChange={(checked) => {
                  dispatch(
                    setEntityCompletionStatus({
                      ...stepCompletionStatus,
                      newStatus: checked
                    })
                  );
                }}
              />
            ) : (
              ''
            )
          }
        >
          <Box display="flex" overflow="hidden">
            <StateSelector
              data-testid="state-selector"
              states={statesWithCompletion}
              currentState={currentState}
              onSelect={(state) => {
                if (hasNewRow) {
                  setRows(rows.filter(({ isNew }) => !isNew));
                  setHasNewRow(false);
                }

                setCurrentState(state);
              }}
            />
            <Box ref={tableRef} display="flex" flexDirection="column" style={{ overflow: 'auto' }}>
              <TableWithComment
                columns={columns}
                rows={rows}
                failedCells={failedCells}
                renderOpts={{ placeholder: 'Enter Tax Period' }}
                hideActionsMenu={stepCompletionStatus.status}
                handleOnRowDelete={(params) => {
                  handleOnRowDeleteForEntityDetails({
                    ...dataForMethods,
                    ...params
                  });
                }}
                onCellChange={(params) => {
                  if (!isPerformanceFixEnabled) {
                    handleEditRowForEntityDetails({
                      ...dataForMethods,
                      ...params
                    });
                  }
                }}
                onCellOrCommentBlur={(params) => {
                  if (isPerformanceFixEnabled) {
                    handleEditRowForEntityDetails({
                      ...dataForMethods,
                      ...params
                    });
                  }

                  handleOnCellOrCommentBlurForEntityDetails({
                    ...dataForMethods,
                    ...params
                  });
                }}
              />
              <Box mt={2}>
                {shouldHideNewRowButton ? null : (
                  <Button
                    data-roles-hide-element={hideElementForRoles.join(' ')}
                    variant="outlined"
                    disabled={isCreditNameDuplicated || isCreditNameReservedName}
                    onClick={hasNewCredit ? handleSaveNewCredit : handleAddNewCredit}
                  >
                    {t(hasNewCredit ? 'Save New Credit' : 'Add New Credit')}
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </ContentAndTitle>
      </LoadingWrapper>
      <InterlinkedDataDialog
        ref={dialogRef}
        open={isDialogOpen}
        anchorEl={anchorEl}
        icon={<NotEqualIcon />}
        value={getRtpDifference(creditNameForModal) ?? 0}
        level={LEVEL}
        step={STEP}
        handleClose={handleClose}
        creditName={creditNameForModal}
        onBalanceClick={() => {
          setIsBalanceModalOpen(true);
          return null;
        }}
        onViewClick={() => {
          history.push(`/entity-details/${entityNumber}/state/rtp`);
          return null;
        }}
      />
      <BalanceCalculationsModal
        currentStep={STEP}
        level={LEVEL}
        steps={STEPS}
        entityId={entityId}
        open={isBalanceModalOpen}
        setOpen={setIsBalanceModalOpen}
        entityNumber={entityNumber}
        total={getRtpDifference(creditNameForModal) ?? 0}
        creditName={creditNameForModal}
        currentState={currentState}
      />
    </>
  );
};

export default StateCredits;
