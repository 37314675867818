import { PropsWithChildren } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { WorkBook } from 'xlsx';

import { ReactComponent as UploadManagerIcon } from '../../assets/img/UploadManager.svg';
import { selectFileName } from '../../selectors';

const useStyles = makeStyles((theme) => ({
  closeBtn: {
    marginRight: '0.87rem',
    color: theme.palette.primary.main,
    width: '5.6rem'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  containerName: {
    marginLeft: 'auto',
    marginRight: '1.5rem'
  },
  content: {
    backgroundColor: theme.palette.secondary.lighter,
    height: '100%'
  },
  header: {
    alignItems: 'center',
    backgroundColor: 'white',
    display: 'flex',
    height: '3.5rem',
    paddingTop: '17.5px',
    paddingBottom: '17.5px'
  },
  title: {
    marginLeft: '1.56rem',
    marginRight: '21px',
    display: 'inline-flex',
    alignItems: 'center',
    gap: '0.4rem'
  },
  backButton: {
    border: `${theme.palette.common.black} 2px`,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.light,
    marginLeft: '10px',
    height: '39px',
    width: '39px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  subtitle: {
    marginRight: '48px'
  },
  fileNameBox: {
    display: 'flex',
    backgroundColor: theme.palette.info.dark,
    height: '30px',
    border: '1px solid',
    borderRadius: '4px',
    borderColor: theme.palette.info.dark,
    paddingLeft: '14px',
    marginLeft: '14px'
  },
  fileNameText: {
    color: theme.palette.common.white,
    fontFamily: theme.typography.body2.fontFamily,
    lineHeight: '18px',
    paddingTop: '4px',
    paddingBottom: '8px',
    paddingRight: '21px'
  },
  editIcon: {
    color: theme.palette.common.white,
    width: '10px',
    height: '10px',
    marginTop: '7px',
    marginRight: '12px',
    marginBottom: '12px'
  }
}));

export type TBUploadManagerProps = {
  containerName: string;
  workbookWasUploaded: boolean;
  setWorkbook: any;
};

const TBUploadManager = ({
  containerName,
  workbookWasUploaded,
  setWorkbook,
  children
}: PropsWithChildren<TBUploadManagerProps>) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const fileName = useSelector(selectFileName);

  const handleOnClose = () => {
    const areThereRoutesInTheStack = history.length > 0;
    if (areThereRoutesInTheStack) {
      history.goBack();
    } else {
      history.push('/');
    }
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        {workbookWasUploaded ? (
          <>
            <Box className={classes.backButton}>
              <IconButton role="button" onClick={() => setWorkbook({} as WorkBook)}>
                <ArrowBack />
              </IconButton>
            </Box>
            <Box className={classes.title}>
              <Typography variant="h3">{t('upload-manager')}</Typography>
            </Box>
            <Box className={classes.subtitle}>
              <Typography variant="h3" color="primary">
                {t('link-data')}
              </Typography>
            </Box>
            <Box className={classes.fileNameBox}>
              <Typography className={classes.fileNameText}>{fileName}</Typography>
              <img className={classes.editIcon} src="/imgs/EditSmall.svg" />
            </Box>
            <Box className={classes.containerName}>
              <Typography variant="body1">{containerName}</Typography>
            </Box>
          </>
        ) : (
          <>
            <Box className={classes.title}>
              <UploadManagerIcon />
              <Typography variant="h3">{t('upload-manager')}</Typography>
            </Box>
            <Box className={classes.containerName}>
              <Typography variant="body1">{containerName}</Typography>
            </Box>
          </>
        )}
        <Button className={classes.closeBtn} variant="outlined" onClick={handleOnClose}>
          {t('close')}
        </Button>
      </Box>

      <Box className={classes.content}>{children}</Box>
    </Box>
  );
};

export default TBUploadManager;
