import { makeStyles } from '@material-ui/core';

import theme from '../../../../theme';

const useStyles = makeStyles(() => ({
  closeButton: {
    marginRight: '2rem',
    marginLeft: 'auto'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '80%',
    margin: 'auto',
    minHeight: 0
  },
  containerName: {
    marginLeft: 'auto',
    marginRight: '15%'
  },
  drawer: {
    '& .MuiDrawer-paper': {
      width: '25%',
      minWidth: '578px'
    },
    '& .MuiTypography-root': {
      color: theme.palette.text.primary
    }
  },
  editingCategoryBar: {
    height: '28px',
    backgroundColor: theme.palette.info.dark
  },
  editingCategoryBarText: {
    color: `${theme.palette.common.white} !important`,
    paddingLeft: '38px',
    paddingTop: '2px',
    fontFamily: theme.typography.caption.fontFamily,
    fontSize: theme.typography.caption.fontSize
  },
  entityCount: {
    paddingLeft: '20px',
    marginRight: '10px'
  },
  fileNameText: {
    color: theme.palette.common.white,
    fontFamily: theme.typography.body2.fontFamily,
    lineHeight: '18px',
    paddingTop: '4px',
    paddingBottom: '8px',
    paddingRight: '21px',
    fontSize: '14px'
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    paddingTop: '17.5px',
    paddingBottom: '17.5px'
  },
  title: {
    marginLeft: '1.56rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: '12px'
  },
  categoryName: {
    flex: '1',
    '& .MuiInputBase-root': {
      height: '45px',
      backgroundColor: theme.palette.common.white
    }
  },
  inputContainer: {
    height: '45px',
    backgroundColor: theme.palette.common.white
  },
  tagged: {
    '& .MuiAutocomplete-root:last-child': {
      marginTop: '10px'
    }
  },
  dataType: {
    backgroundColor: theme.palette.common.white,
    '& .MuiInputBase-root': {
      height: '44px'
    },
    '& .MuiAutocomplete-endAdornment': {
      marginRight: '15px',
      '& .MuiAutocomplete-popupIndicator': {
        pointerEvents: 'none',
        margin: theme.spacing(0, 0.5),
        transform: 'none',
        color: theme.palette.text.secondary,
        '&.Mui-disabled': {
          color: 'rgba(0, 0, 0, 0.38)'
        }
      }
    }
  },
  taggedAccounts: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: `solid ${theme.palette.primary.dark} 1px`,
    width: '100%',
    marginTop: '10px',
    padding: '3px',
    alignItems: 'center',
    '& span': {
      backgroundColor: theme.palette.text.secondary,
      display: 'inline-block',
      padding: '3px',
      borderRadius: '4px',
      height: '28px',
      width: '143px',
      textAlign: 'center',
      color: theme.palette.common.white
    },
    '& > :first-child': {
      width: '80%'
    },
    '& > :last-child': {
      width: '10%',
      textAlign: 'center'
    }
  },
  taggedAccountElement: {
    display: 'flex',
    flexDirection: 'row',
    height: '45px',
    alignItems: 'center',
    borderBottom: `solid 1px ${theme.palette.divider}`,
    '& >  :first-child': {
      width: '20%'
    },
    '& >  :nth-child(2)': {
      width: '60%'
    },
    '& >  :nth-child(3)': {
      width: '10%'
    },
    '& >  :last-child': {
      width: '10%',
      textAlign: 'center'
    }
  },
  footer: {
    backgroundColor: theme.palette.common.white,
    paddingRight: '10%',
    paddingLeft: 'auto',
    paddingTop: '18px',
    paddingBottom: '18px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    '& button': {
      color: theme.palette.primary.main,
      borderRadius: '4px',
      height: '36px',
      padding: '0 18px'
    },
    '& button:last-child': {
      width: '148px',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.light,
      '&.Mui-disabled': {
        backgroundColor: theme.palette.primary.dark
      }
    }
  },
  scrollContainer: {
    height: '530px',
    overflowY: 'auto'
  }
}));

export { useStyles };
