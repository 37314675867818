import { useTranslation } from 'react-i18next';

import { makeStyles, Box, Typography } from '@material-ui/core';

import { DifferencePeriodHeader } from './';

import { abbreviateNumber, formatNumber, formatPercentage } from './../../../utils';

import { Row } from '../../../models';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(2)
  },
  headingWrapper: {
    textAlign: 'end',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around'
  },
  title: {
    fontSize: theme.typography.overline.fontSize,
    fontWeight: 'bold',
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  text: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    width: '6.45em',
    textAlign: 'center',
    padding: theme.spacing(0.584),
    fontSize: theme.typography.body2.fontSize,
    margin: theme.spacing(0.417),
    color: theme.palette.text.secondary
  }
}));

const Differences = ({ rows }: { rows: Row[] }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <DifferencePeriodHeader />
      <Box className={classes.wrapper}>
        <Box className={classes.headingWrapper}>
          <Typography className={classes.title}>{t('Tax-Effected Amount')}</Typography>
          <Typography className={classes.title}>{t('Tax Rate')}</Typography>
        </Box>

        {rows?.map((row) => (
          <Box key={row.name}>
            <Typography className={classes.text}>
              {formatNumber(row.taxEffectedAmount - row.taxEffectedAmountComparison, 0, '-', true)}
            </Typography>
            <Typography className={classes.text}>
              {abbreviateNumber(formatPercentage(row.taxRate - row.taxRateComparison))}
            </Typography>
          </Box>
        )) ?? null}
      </Box>
    </>
  );
};

export default Differences;
