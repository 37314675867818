import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { resetValidateImport, saveValidateImport } from './validateImport.actions';
import { validateImportInitialState } from './validateImport.initialState';

import { containersOnCurrentSwitched } from '../containers';

export const validateImportReducer = createReducer(validateImportInitialState, (builder) => {
  builder
    .addCase(saveValidateImport, (state, action) => {
      state.amount = action.payload;
    })
    .addCase(resetValidateImport, () => validateImportInitialState)
    .addMatcher(isAnyOf(containersOnCurrentSwitched), () => {
      return validateImportInitialState;
    });
});
