import { Reducer } from 'redux';

import { setRoleData } from './auth.actions';
import { authInitialState } from './auth.initialState';
import { AuthStateInterface, AuthActionTypes, SET_AUTH_TOKENS } from './auth.types';

const authReducer: Reducer<AuthStateInterface, AuthActionTypes> = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  authState: AuthStateInterface = authInitialState,
  action: AuthActionTypes
): AuthStateInterface => {
  const now = new Date();
  switch (action.type) {
    case SET_AUTH_TOKENS: {
      localStorage.setItem('idToken', String(action.payload.authToken));
      localStorage.setItem('refreshToken', String(action.payload.refreshToken));
      localStorage.setItem(
        'expires',
        action.payload.expiration ? String(action.payload.expiration.getTime() / 1000) : ''
      );
      localStorage.setItem('lastRefreshDate', String(now.getDate()));
      const authStateWithoutRole = { ...authState };
      delete (authStateWithoutRole as any).role;
      return {
        ...authStateWithoutRole,
        authToken: action.payload.authToken,
        expiration: action.payload.expiration,
        refreshToken: action.payload.refreshToken,
        lastRefreshDate: now.getDate()
      };
    }

    case setRoleData.type: {
      return {
        ...authState,
        roleId: action.payload.roleId,
        roleUuid: action.payload.roleUuid
      };
    }

    default:
      return authState;
  }
};

export default authReducer;
