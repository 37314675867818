import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { Box, Link, Typography } from '@material-ui/core';

import { useEntities } from '../../../hooks';
import { Level, Step } from '../../../models';
import { fetchFile, FileMetadata, setFileName, setUploadManagerIsOpen } from '../../../redux/uploadManager';
import { selectFileMetadata, selectIsFileDownloaded } from '../../../selectors';
import { mapToStepTitle } from '../../Spreadsheet/utils';
import Table from '../../Table';
import { Row } from '../../Table/Table.proptype';
import { useStyles } from '../FileUpload.styles';
interface ChooseFileProps {
  level: Level;
  step: Step;
}

const formatDateTime = (dateTime: string) => {
  const convertedDateTime = new Date(dateTime);
  const date = convertedDateTime.toDateString();
  let hours = convertedDateTime.getHours();
  const amOrPm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12 || 12;
  let minutes: string | number = convertedDateTime.getMinutes();
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }

  const formattedTime = `${hours}:${minutes}${amOrPm}`;
  const formattedDateTime = `${date} ${formattedTime}`;
  const formattedDateTimeWithoutDay = formattedDateTime.split(' ').slice(1).join(' ');
  return formattedDateTimeWithoutDay;
};

const ChooseFile = ({ level, step }: ChooseFileProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [rows, setRows] = useState<FileMetadata[]>([]);
  const { currentEntity } = useEntities();
  const entityName = currentEntity?.name;
  const capitalizedLevel = level === 'federal' ? 'Federal' : 'State';
  const mappedStep = mapToStepTitle[step] ?? '';

  const fileMetadata: FileMetadata[] = useSelector(selectFileMetadata);
  const isFileDownloaded = useSelector(selectIsFileDownloaded);

  useEffect(() => {
    const tempRows = [];
    for (const { fileName, lastModified } of fileMetadata) {
      const formatted = formatDateTime(lastModified);
      tempRows.push({ fileName, lastModified: formatted });
    }

    setRows(tempRows);
  }, [fileMetadata]);

  useEffect(() => {
    if (isFileDownloaded) {
      dispatch(setUploadManagerIsOpen(true));
    }
  }, [dispatch, isFileDownloaded]);

  const columns = [
    {
      field: 'fileName',
      filterable: true,
      sortable: true,
      width: '50%',
      placeholder: t('File Name'),
      renderCell: (row: Row) => {
        return (
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              // fetchFile will set the fileVersion and download the presigned file
              dispatch(fetchFile(row.fileName));
              dispatch(setFileName(row.fileName));
            }}
          >
            {row.fileName}
          </Link>
        );
      }
    },
    {
      field: 'lastModified',
      headerName: t('Uploaded'),
      width: '50%'
    }
  ];
  return (
    <Box className={classes.chooseFileSection}>
      <Typography className={classes.chooseFileHeader}>{t('Choose file')}</Typography>
      <Typography className={classes.chooseFileSubHeader1}>{t(`Link data to ${String(entityName)}`)}</Typography>
      <Typography className={classes.chooseFileSubHeader2}>{`${String(capitalizedLevel)} - ${String(
        mappedStep
      )}`}</Typography>
      <Table rows={rows} columns={columns} noRowLabel={t('No files')} isNotEditableShaded={false} />
    </Box>
  );
};

export default ChooseFile;
