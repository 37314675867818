import { ModelMeta, ReportMeta } from './reports.actions';

export const reportsInitialState: {
  modelsMeta: Record<string, Record<string, ModelMeta>>;
  reportsMeta: Record<string, Record<string, ReportMeta>>;
} = {
  modelsMeta: {
    entity: {},
    group: {}
  },
  reportsMeta: {
    entity: {},
    group: {}
  }
};
