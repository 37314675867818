import { useReducer, useState, useMemo, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  reducer as defaultReducer,
  makeInitialState as defaultMakeInitialState,
  ADD_ATTRIBUTE,
  START_EDITING,
  STOP_EDITING,
  TOGGLE_ROWS,
  INIT
} from './reducer';

import { AttributeSelector, Layout } from '../';
import { SearchBox, Table } from '../../..';
import { UPLOAD_REVIEW_CURRENCY_WARNING, UsaJurisdictionId, USD_CURRENCY_ID } from '../../../../constants';
import { enqueueNotification } from '../../../../redux/notifications';
import { updateTabData } from '../../../../redux/uploadReview';
import { Clone } from '../../../../utils';
import { Column } from '../../../Table/Table.proptype';

const useStyles = makeStyles((theme) => ({
  searchbox: {
    margin: theme.spacing(1, 0),
    width: '100%'
  },
  attributeContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    gap: theme.spacing(1)
  }
}));

const defaultMakeAttributeColumnGetter = (assignedAttributeByRowId: any) => (row: any) =>
  assignedAttributeByRowId[row.id]?.name || '-';

type OwnProps = {
  asideTitle: string;
  description: string;
  searchCaption: string;
  searchPlaceholder?: string;
  attributeValues: Array<{
    id: string;
    name: string;
  }>;
  mainTitle: string;
  dataCountLabel: string;
  attributeColumnName?: string;
  columns: Column[];
  data?: any[];
  attributeByRowId?: any; // TODO: PropTypes.instanceOf(Map)
  noRowLabel?: string;
  onEditedAttribute?: (...args: any[]) => any;
  reducer?: (state: any, action: any) => any;
  makeInitialState?: (...args: any[]) => any;
  makeAttributeColumnGetter?: (...args: any[]) => any;
  isAttributeColumnFilterable?: boolean;
};

const TabLayout = ({
  searchCaption,
  searchPlaceholder,
  attributeValues,
  attributeColumnName,
  attributeByRowId,
  columns,
  data,
  noRowLabel,
  onEditedAttribute,
  reducer = defaultReducer,
  makeInitialState = defaultMakeInitialState,
  makeAttributeColumnGetter = defaultMakeAttributeColumnGetter,
  isAttributeColumnFilterable = true,
  ...layoutProps
}: OwnProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [{ editedId, selected, assignedAttributeByRowId, assignedRowIdsByAttrId }, localDispatch] = useReducer(
    reducer,
    { attributes: attributeValues, assignedAttributeByRowId: attributeByRowId },
    makeInitialState
  );
  const { entityJurisdictionCurrencyRestriction } = useFlags();
  const [searched, setSearched] = useState('');
  const [filters, setFilters] = useState(new Map());

  useEffect(() => {
    localDispatch({ type: INIT, payload: { attributes: attributeValues, assignedAttributeByRowId: attributeByRowId } });
  }, [attributeValues, attributeByRowId]);

  useEffect(() => onEditedAttribute?.(editedId ? attributeValues.find(({ id }: any) => editedId === id) : null), [
    onEditedAttribute,
    attributeValues,
    editedId
  ]);

  const handleSaveSelection = () => {
    const assignedAttributeEntries =
      Object.keys(assignedAttributeByRowId).length > 1
        ? Object.entries(assignedAttributeByRowId)
        : [Object.entries(assignedAttributeByRowId)];

    const entityJurisdictionCurrencyValidation = selectedRows.every((entityRow) => {
      const entityAssignedAttribute: any = assignedAttributeEntries.find(
        ([entityNumber]: string[]) => entityNumber === entityRow.id
      )?.[1];

      // Assigned attribute is currency
      if (
        entityRow.jurisdictionId === UsaJurisdictionId &&
        entityAssignedAttribute &&
        'symbol' in entityAssignedAttribute && // Assigned attribute validation
        entityAssignedAttribute.currencyId !== USD_CURRENCY_ID
      )
        return false;

      // Assigned attribute is jurisdiction
      if (
        entityRow.currencyId !== USD_CURRENCY_ID &&
        entityAssignedAttribute &&
        'jurisdictionId' in entityAssignedAttribute && // Assigned attribute validation
        entityAssignedAttribute.jurisdictionId === UsaJurisdictionId
      )
        return false;

      return true;
    });

    if (entityJurisdictionCurrencyRestriction && !entityJurisdictionCurrencyValidation) {
      dispatch(
        enqueueNotification({
          message: UPLOAD_REVIEW_CURRENCY_WARNING,
          options: {
            variant: 'warning'
          }
        })
      );
      return;
    }

    dispatch(updateTabData(assignedAttributeByRowId));
    localDispatch({ type: STOP_EDITING });
  };

  const columnsWithAttribute = useMemo(
    () => [
      ...columns,
      {
        headerName: attributeColumnName,
        field: attributeColumnName,
        filterable: isAttributeColumnFilterable,
        sortable: true,
        placeholder: attributeColumnName,
        getValue: makeAttributeColumnGetter(assignedAttributeByRowId)
      }
    ],
    [columns, attributeColumnName, assignedAttributeByRowId, makeAttributeColumnGetter, isAttributeColumnFilterable]
  );

  const selectedRows = useMemo(() => {
    const selectedIds = assignedRowIdsByAttrId[editedId];
    if (!Array.isArray(data) || !Array.isArray(selectedIds)) {
      return [];
    }

    return data.filter(({ id }) => selectedIds.includes(id));
  }, [assignedRowIdsByAttrId, editedId, data]);

  useEffect(() => {
    setFilters(new Map());
  }, [editedId]);

  function handleSelection(event: any, value: any) {
    if (value) {
      localDispatch({ type: ADD_ATTRIBUTE, payload: value });
      setSearched('');
    }
  }

  const handleRemove = (id: string | number) => {
    let newAssignedAttributeByRowId = Clone.json(assignedAttributeByRowId);
    const entityIds = Object.keys(newAssignedAttributeByRowId);

    entityIds.forEach((entityId) => {
      const row = newAssignedAttributeByRowId[entityId];
      if (Array.isArray(row)) {
        const newRows = row.filter((item) => item.id !== id);
        newAssignedAttributeByRowId[entityId] = newRows;
      } else if (newAssignedAttributeByRowId[entityId].id === id) {
        const { [entityId]: _removed, ...newRows } = newAssignedAttributeByRowId;
        newAssignedAttributeByRowId = newRows;
      }
    });

    dispatch(updateTabData(newAssignedAttributeByRowId));
  };

  return (
    <Layout
      {...layoutProps}
      asideContent={
        <>
          <Typography variant="caption">{searchCaption}</Typography>
          <SearchBox
            blurOnSelect
            id="attribute-selector"
            className={classes.searchbox}
            options={attributeValues.filter(
              (attribute: any) => !selected.some((selectedAttribute: any) => selectedAttribute.id === attribute.id)
            )}
            getOptionLabel={({ name }: any) => name || ''}
            value={null}
            inputValue={searched}
            placeholder={searchPlaceholder}
            onChange={handleSelection}
            onInputChange={(event: any, value: any) => {
              setSearched(value);
            }}
          />
          <Box className={classes.attributeContainer}>
            {selected.map(({ id, name }: any) => (
              <AttributeSelector
                key={id}
                name={name}
                count={assignedRowIdsByAttrId[id]?.length}
                isEditMode={id === editedId}
                onRemove={() => {
                  handleRemove(id);
                }}
                onEdit={() => {
                  localDispatch({ type: START_EDITING, payload: { id } });
                }}
                onDone={handleSaveSelection}
              />
            ))}
          </Box>
        </>
      }
      mainContent={
        <Table
          variant="outlined"
          columns={columnsWithAttribute}
          rows={data}
          filters={filters}
          isSelectable={Boolean(editedId)}
          selected={selectedRows}
          noRowLabel={noRowLabel}
          onFilter={setFilters}
          onSelect={(rows: any, areSelected: any) => {
            localDispatch({
              type: TOGGLE_ROWS,
              payload: { rowIds: rows.map(({ id }: any) => id), areSelected }
            });
          }}
        />
      }
    />
  );
};

export default TabLayout;
