import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SummaryHeaderBox from './SummaryHeaderBox';

import { SummaryReportHeader } from '../../../../models';
import { formatCurrency, formatPercentage, abbreviateNumber } from '../../../../utils';

const EffectiveTaxRate = 'effective-tax-rate' as const;
const TotalProvision = 'total-provision' as const;
const DeferredAssetLiability = 'deferred-asset-liability' as const;

const useStyles = makeStyles((theme) => ({
  outlineContainer: {
    alignItems: 'stretch',
    display: 'flex',
    justifyContent: 'flex-start',
    gap: theme.spacing(2),
    height: '99px',
    maxWidth: '959px',
    width: '959px',
    '& > *': {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      [theme.breakpoints.up('lg')]: {
        minWidth: 300
      }
    }
  },
  overflowContainer: {
    minHeight: '110px',
    width: '100%',
    overflow: 'auto'
  }
}));

interface Props {
  activeTab: typeof EffectiveTaxRate | typeof TotalProvision | typeof DeferredAssetLiability;
  headerValues: SummaryReportHeader;
}

const SummaryHeader = ({ headerValues, activeTab }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { pathname } = useLocation();
  const rootPath = pathname.split('/').slice(0, -1).join('/');
  const percentageLeftContent = formatPercentage(headerValues.etrBox, '-', 4);

  return (
    <Box className={classes.overflowContainer}>
      <Box className={classes.outlineContainer}>
        <Link to={`${rootPath}/${SummaryHeader.EffectiveTaxRate}`}>
          <SummaryHeaderBox
            isActive={activeTab === SummaryHeader.EffectiveTaxRate}
            title={t('Effective Tax Rate')}
            leftContent={percentageLeftContent}
          />
        </Link>
        <Link to={`${rootPath}/${SummaryHeader.TotalProvision}`}>
          <SummaryHeaderBox
            isActive={activeTab === SummaryHeader.TotalProvision}
            title={t('Total Provision')}
            leftContent={formatCurrency(headerValues.totalOverallProvBox)}
            rightLabelTop={'C ' + abbreviateNumber(formatCurrency(headerValues.totalCurrentProvBox))}
            rightLabelBottom={'D ' + abbreviateNumber(formatCurrency(headerValues.totalDeferredProvBox))}
          />
        </Link>
        <Link to={`${rootPath}/${SummaryHeader.DeferredAssetLiability}`}>
          <SummaryHeaderBox
            isActive={activeTab === SummaryHeader.DeferredAssetLiability}
            title={t('Deferred Tax Asset / (Liability)')}
            leftContent={formatCurrency(headerValues.deferredTaxAssetBox)}
          />
        </Link>
      </Box>
    </Box>
  );
};

SummaryHeader.EffectiveTaxRate = EffectiveTaxRate;
SummaryHeader.TotalProvision = TotalProvision;
SummaryHeader.DeferredAssetLiability = DeferredAssetLiability;

export default SummaryHeader;
