import { ReactNode } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';

import { selectFileName, selectIsFileDownloaded, selectIsFileUploaded } from '../../selectors';
import theme from '../../theme';

const useStyles = makeStyles(() => ({
  closeButton: {
    marginRight: '2rem'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  containerName: {
    marginLeft: 'auto',
    marginRight: '15%'
  },
  content: {
    backgroundColor: theme.palette.secondary.lighter,
    height: '100%'
  },
  divider: {
    '&.MuiDivider-middle': {
      marginLeft: '0.69rem',
      marginRight: '0.69rem'
    },
    '&.MuiDivider-vertical': {
      height: '50%',
      width: '2px'
    }
  },
  drawer: {
    '& .MuiDrawer-paper': {
      width: '92%'
    }
  },
  editIcon: {
    color: theme.palette.common.white,
    width: '10px',
    height: '10px',
    marginTop: '7px',
    marginRight: '12px',
    marginBottom: '12px'
  },
  fileNameBox: {
    display: 'flex',
    backgroundColor: theme.palette.info.dark,
    height: '30px',
    border: '1px solid',
    borderRadius: '4px',
    borderColor: theme.palette.info.dark,
    paddingLeft: '14px',
    marginLeft: '14px'
  },
  fileNameText: {
    color: theme.palette.common.white,
    fontFamily: theme.typography.body2.fontFamily,
    lineHeight: '18px',
    paddingTop: '4px',
    paddingBottom: '8px',
    paddingRight: '21px'
  },
  header: {
    alignItems: 'center',
    backgroundColor: 'white',
    display: 'flex',
    paddingTop: '17.5px',
    paddingBottom: '17.5px'
  },
  subtitle: {},
  title: {
    marginLeft: '1.56rem'
  }
}));

export interface UploadManagerProps {
  children: ReactNode;
  containerName: string;
  isOpen: boolean;
  onClose: () => void;
}

const UploadManager: React.FC<UploadManagerProps> = ({
  children,
  containerName,
  isOpen,
  onClose
}: UploadManagerProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const fileName = useSelector(selectFileName);
  const isFileUploaded = useSelector(selectIsFileUploaded);
  const isFileDownloaded = useSelector(selectIsFileDownloaded);

  return (
    <Drawer open={isOpen} className={classes.drawer} anchor="right" onClose={onClose}>
      <Box className={classes.container}>
        <Box className={classes.header}>
          <Box className={classes.title}>
            <Typography variant="h3">{t('upload-manager')}</Typography>
          </Box>
          <Divider className={classes.divider} orientation="vertical" variant="middle" />
          <Box className={classes.subtitle}>
            <Typography variant="h3" color="primary">
              {t('link-data')}
            </Typography>
          </Box>
          {isFileUploaded ||
            (isFileDownloaded && (
              <Box className={classes.fileNameBox}>
                <Typography className={classes.fileNameText}>{fileName}</Typography>
                <img className={classes.editIcon} src="/imgs/EditSmall.svg" />
              </Box>
            ))}
          <Box className={classes.containerName}>
            <Typography variant="body1">{containerName}</Typography>
          </Box>

          <Box className={classes.closeButton}>
            <IconButton role="button" onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </Box>
        <Box className={classes.content}>{children}</Box>
      </Box>
    </Drawer>
  );
};

export default UploadManager;
