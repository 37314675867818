import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Box, makeStyles } from '@material-ui/core';

import { useExcel } from '../../../../hooks/useExcel';
import {
  Column,
  Container,
  Entity,
  EntityById,
  Report,
  ReportForExport,
  Row,
  RowsForExport,
  TotalsRow
} from '../../../../models';
import { ReportMeta } from '../../../../redux/reports';
import { useReportIcon, isLineItemWithFinancialValues, filterReportColumnsByEntityId, padColumns } from '../../utils';
import ReportLayout from '../ReportLayout';
import ReportTable from '../ReportTable';
import ReportTotalsTable from '../ReportTotalsTable';

const useStyles = makeStyles((theme) => ({
  colGroup: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.action.hover,
    padding: theme.spacing(0.5, 1.5),
    position: 'sticky',
    left: '0',
    fontWeight: theme.typography.fontWeightBold,
    width: '400px',
    zIndex: 3
  },
  reportTableContainer: {
    marginBottom: theme.spacing(2.5),
    width: 'min-content'
  },
  reportTable: {
    overflowX: 'visible',
    '& .MuiTable-root': {
      width: 'auto'
    },
    '& .MuiTableCell-root.sticky:first-child': {
      boxShadow: `-24px 0 0 0 ${theme.palette.common.white}`,
      zIndex: 3
    },
    '& .MuiTableCell-root.MuiTableCell-head': {
      verticalAlign: 'bottom',
      '&:first-child': {
        top: 'auto',
        minWidth: '400px'
      },
      '&:not(:first-child)': {
        boxShadow: `0 -24px 0 0 ${theme.palette.common.white}`,
        minWidth: '200px'
      },
      '&.totalColumn': {
        backgroundColor: theme.palette.primary.light,
        fontWeight: 'bolder'
      }
    }
  }
}));

export const buildColumnsForExport = (columns: Column[]) => {
  const uniqueMap = new Map();
  const columnsCopy = JSON.parse(JSON.stringify(columns));
  for (const item of columnsCopy) {
    if (item.field && item.field !== 'name' && item.className !== 'gap') {
      if (item?.className === 'totalColumn') {
        const firstOccurance = item.field.indexOf('_');
        item.field = item.field.slice(0, firstOccurance);
      } else {
        const lastOccurance = item.field.lastIndexOf('_');
        const beforeLastOccurance = item.field.lastIndexOf('_', lastOccurance - 1);

        if (lastOccurance !== -1 && beforeLastOccurance !== -1) {
          const firstPart = item.field.slice(0, beforeLastOccurance);
          const secondPart = item.field.slice(lastOccurance, -1);
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          item.field = `${firstPart}${secondPart}`;
        }
      }
    }

    uniqueMap.set(item.headerName, item);
  }

  return [...uniqueMap.values()];
};

export const buildRowsForExport = (rowsByEntity: any, entityById: Record<string, Entity>) => {
  const rowsForExport: RowsForExport[] = [];
  Object.keys(rowsByEntity).forEach((row) => {
    rowsForExport.push({
      className: 'section-header',
      name: `${entityById[row]?.name} (${entityById[row]?.entityNumber})`
    });
    rowsByEntity[row].forEach((entityRow: any) => {
      rowsForExport.push(entityRow);
    });
  });

  rowsForExport.forEach((row: any) => {
    if (row.type === 'lineItem') {
      if (row.name.includes('Apportionment')) {
        row.usingSpecializedFormatting = true;
      }

      Object.keys(row).forEach((key) => {
        if (key.includes('combinedTotal') || key.includes('separatedTotal') || key.includes('total')) {
          const firstOccurance = key.indexOf('_');
          const newKeyName = key.slice(0, firstOccurance);
          row[newKeyName] = row[key];
        } else {
          const lastOccurance = key.lastIndexOf('_');
          const beforeLastOccurance = key.lastIndexOf('_', lastOccurance - 1);

          if (lastOccurance !== -1 && beforeLastOccurance !== -1) {
            const firstPart = key.slice(0, beforeLastOccurance);
            const secondPart = key.slice(lastOccurance, -1);
            row[`${firstPart}${secondPart}`] = row[key];
          }
        }
      }, []);
    }
  });

  return rowsForExport;
};

const UnifiedMultiReportView = ({
  container,
  currency = '',
  report,
  entityById,
  columns,
  rows,
  totals,
  groupName,
  isNewReportAvailable,
  currentReportMeta,
  onReloadReportClick
}: Props) => {
  const { t } = useTranslation();
  const [reportsArrayForExport, setReportArrayForExport] = useState<ReportForExport[]>([]);
  const { name, subtype, modelTimestamp } = report;
  const ReportIcon = useReportIcon(subtype);
  const { exportReports } = useExcel();
  const { pathname } = useLocation();
  const closeUrl = pathname.split('/').slice(0, -1).join('/');
  const classes = useStyles();
  const title = t(`${name} _`, { name: t(groupName) });

  const rowsByEntity: Record<string, Array<Record<string, unknown>>> = {};
  let columnsByEntity: Record<string, Column[]> = {};
  const [metaDataRow] = rows;
  const entityIds = Object.values(metaDataRow.columnMeta).map((item: any) => item.entityId);
  const uniqueEntityIds = [...new Set(entityIds)];

  uniqueEntityIds.forEach((entityId) => {
    rowsByEntity[entityId] = [];
    columnsByEntity[entityId] = filterReportColumnsByEntityId(columns, entityId);
  });

  columnsByEntity = padColumns(columnsByEntity);

  rows.forEach((row) => {
    const { format, name, type } = row;
    const rowColumnsKeyValuePairs = Object.entries(row);

    uniqueEntityIds.forEach((entityId) => {
      const entityColumnsKeyValuePairs = rowColumnsKeyValuePairs.filter(([key]) => key.includes(entityId));
      const rowToBe = {
        format,
        name,
        type,
        ...Object.fromEntries(entityColumnsKeyValuePairs)
      };

      if (isLineItemWithFinancialValues(rowToBe)) {
        rowsByEntity[entityId].push(rowToBe);
      }
    });
  });

  useEffect(() => {
    const columnsForExport = buildColumnsForExport(columns);
    const rowsForExport = buildRowsForExport(rowsByEntity, entityById);
    const reportsArrayForExport = [
      {
        rows: rowsForExport,
        totals,
        columns: columnsForExport,
        reportName: t(name),
        rawName: name,
        title,
        startDate: container?.startDate,
        endDate: container?.endDate,
        currency
      }
    ];
    setReportArrayForExport(reportsArrayForExport);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totals, t, name, title, container, currency, columns, entityById]);

  return (
    <ReportLayout
      hasPaperFullWidth
      icon={<ReportIcon style={{ fontSize: '4rem' }} />}
      title={title}
      container={container}
      currency={currency}
      closeUrl={closeUrl}
      modelTimestamp={modelTimestamp}
      isNewReportAvailable={isNewReportAvailable}
      currentReportMeta={currentReportMeta}
      onDownloadClicked={async () => exportReports(reportsArrayForExport)}
      onReloadReportClick={onReloadReportClick}
    >
      {Object.entries(rowsByEntity).map(([entityId, entityRows]) => {
        return (
          <Box key={entityId} className={classes.reportTableContainer}>
            <Box
              className={classes.colGroup}
            >{`${entityById[entityId]?.name} (${entityById[entityId]?.entityNumber})`}</Box>
            <Box flexGrow={1} overflow="visible">
              <ReportTable
                columns={columnsByEntity[entityId]}
                currency={currency}
                report={report}
                rows={entityRows}
                className={classes.reportTable}
                reportName={name}
              />
            </Box>
            {totals && <ReportTotalsTable rows={totals} width="30%" />}
          </Box>
        );
      })}
    </ReportLayout>
  );
};

type Props = {
  container?: Container;
  currency?: string;
  report: Report;
  entityById: EntityById;
  columns: Column[];
  rows: Row[];
  totals?: TotalsRow[];
  groupName: string;
  isNewReportAvailable: boolean;
  currentReportMeta: ReportMeta;
  onReloadReportClick: () => void;
};

export default UnifiedMultiReportView;
