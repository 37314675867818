import { Box } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';

import ReportListHeader from './ReportListHeader';
import { renderReports } from './utils';

import { reportsOrder, reportsOrderDeprecated } from '../../constants';

const ReportList = ({
  backTo,
  classes,
  currencyISO,
  hasStates,
  areReportsBulkDownloading,
  onBulkReportsDownload,
  options,
  refinedReports,
  reportType,
  setReportType,
  shouldGroupCategories,
  onShouldGroupCategoriesUpdate,
  t,
  title,
  type,
  reportsMeta,
  reportSourceId,
  isSomeReportUpdating
}: any) => {
  const { prov2884FederalCurrentProvisionStateTaxDeductionRtp } = useFlags();

  return (
    <Box className={classes.root}>
      <Box className={classes.headerContainer}>
        <ReportListHeader
          shouldGroupCategories={shouldGroupCategories}
          classes={classes}
          backTo={backTo}
          areReportsBulkDownloading={areReportsBulkDownloading}
          options={options}
          reportType={reportType}
          setReportType={setReportType}
          t={t}
          title={title}
          type={type}
          isSomeReportUpdating={isSomeReportUpdating}
          onShouldGroupCategoriesUpdate={onShouldGroupCategoriesUpdate}
          onBulkReportsDownload={onBulkReportsDownload}
        />
      </Box>
      <Box className={classes.allReportsContainer}>
        {renderReports({
          refinedReports,
          reportType,
          currencyISO,
          reportsOrder: prov2884FederalCurrentProvisionStateTaxDeductionRtp ? reportsOrder : reportsOrderDeprecated,
          hasStates,
          reportsMeta,
          reportSourceId,
          type
        })}
      </Box>
    </Box>
  );
};

export default ReportList;
