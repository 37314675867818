import { ChangeEvent, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';

const useStyles = makeStyles((theme) => ({
  inputField: {
    alignItems: 'center',
    border: `1px solid ${theme.palette.info.dark}`,
    borderRadius: '4px',
    boxShadow: '0 0 9px 0 rgba(0,0,0,0.05)',
    display: 'flex',
    height: '36px',
    margin: '4px 0 14px 0',
    paddingLeft: '12px',
    paddingRight: '1px',
    width: '98%'
  },
  button: {
    fontFamily: theme.typography.body2.fontFamily,
    letterSpacing: '0.25px',
    lineHeight: '18px',
    borderRadius: '4px',
    border: '1px solid',
    borderColor: 'transparent'
  },
  doneButton: {
    marginLeft: '0.25rem'
  },
  cancelButton: {
    marginLeft: 'auto'
  }
}));

type SpreadsheetCellSelectionBarProps = {
  range: string | null;
  onBlur: (inputSelection: string) => void;
  onCancel: () => void;
  onDone: (inputSelection: string) => void;
};

export const checkIfValidExcelSelection = (value: string) => {
  return /^[A-Z]+\d+$|^[A-Z]+\d+:[A-Z]+\d+$/.test(value);
};

const SpreadsheetCellSelectionBar = ({ range, onCancel, onDone, onBlur }: SpreadsheetCellSelectionBarProps) => {
  const [inputSelection, setInputSelection] = useState(range ?? '');
  const [isValidSelection, setIsValidSelection] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    const selection = range ?? '';

    setInputSelection(selection);
    setIsValidSelection(checkIfValidExcelSelection(selection));
  }, [range]);

  const handleOnInputChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const selection = event.target.value.trim().toUpperCase();

    setInputSelection(selection);
    setIsValidSelection(checkIfValidExcelSelection(selection));
  };

  const handleOnInputBlur = () => {
    if (isValidSelection) {
      onBlur(inputSelection);
    }
  };

  const handleOnDoneClick = () => {
    if (isValidSelection) {
      onBlur(inputSelection);
      onDone(inputSelection);
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'Enter':
        handleOnDoneClick();
        break;
      default:
        break;
    }
  };

  return (
    <Box className={classes.inputField} onKeyDown={handleKeyDown as any}>
      <Input
        disableUnderline
        fullWidth
        data-testid="spreadsheetCellSelectionBarInput"
        value={inputSelection}
        onChange={handleOnInputChange}
        onBlur={handleOnInputBlur}
      />

      <Button
        className={`${classes.button} ${classes.cancelButton}`}
        color="primary"
        variant="contained"
        onClick={onCancel}
      >
        {t('cancel')}
      </Button>

      <Button
        className={`${classes.button} ${classes.doneButton}`}
        color="primary"
        disabled={!isValidSelection}
        variant="contained"
        onClick={handleOnDoneClick}
      >
        {t('done')}
      </Button>
    </Box>
  );
};

export default SpreadsheetCellSelectionBar;
