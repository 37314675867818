import { removeStatePrefixFromAdjustmentName } from './adjustments';

import ColorTooltip from '../components/ColorTooltip';
import CategoriesPopover from '../components/EntityDetails/components/CategoriesPopover';
import { renderCell } from '../components/Table/components/TableBody';
import { FailedCells, TableProps } from '../components/Table/Table.proptype';
import { CategoryFromApi, Column, Container, Level, Row, Step } from '../models';

export const pickCategoryForRow = (row: Row, categoriesByAccount: Record<string, CategoryFromApi[]>) => {
  let rowName = String(row.name).toLowerCase().trim();

  if (row?.creditName?.includes('state.modifications') && rowName) {
    rowName = removeStatePrefixFromAdjustmentName(rowName);
  }

  const categories = categoriesByAccount[rowName];
  return categories?.find((category) => {
    return category.accounts.some((account) => {
      const stepName = `${row.level as string}.${row.step as string}`;
      const standardizedAttributeName = account.attributeName.toLowerCase().trim();
      switch (stepName) {
        case 'federal.deferred':
        case 'federal.rtp':
        case 'state.rtp':
        case 'state.deferred':
          if (row.creditName) {
            return account.stepName === row.creditName && standardizedAttributeName === rowName;
          }

          return account.stepName === stepName && standardizedAttributeName === rowName;
        default:
          return account.stepName === stepName && standardizedAttributeName === rowName;
      }
    });
  });
};

export function groupEntityDetailsCategoryRows(rows: TableProps['rows'], columns: Column[], reportName?: string) {
  const categoriesWithRows: Record<string, any> = {};
  const totals: Record<string, Map<Column, number>> = {};
  const alreadyInsertedCategories = new Set<string>([]);
  const result: Row[] = [];
  let sectionCategories: Row[] = [];
  let sectionCategoryless: Row[] = [];

  /**
   * Currently the rows are placed as:
   * - section header
   * - categories for this section
   * - categoryless rows for this section (including isTotal, isNew rows)
   * - section header
   * - categories for this section
   * - categoryless rows for this section (including isTotal, isNew rows)
   */

  rows?.forEach((row: Row) => {
    const { category, sectionHeader } = row;

    if (sectionHeader && typeof sectionHeader === 'object') {
      result.push(...sectionCategories, ...sectionCategoryless, row);
      sectionCategories = [];
      sectionCategoryless = [];
      return;
    }

    if (category) {
      totals[category.categoryId] = totals[category.categoryId] || new Map<Column, number>();
      categoriesWithRows[category.categoryId] = categoriesWithRows[category.categoryId] || {
        ...category,
        rows: []
      };

      for (const column of columns) {
        const value: number = getCell(row, column);
        if (column.isNumber && String(Number(value)) !== 'NaN') {
          const columnAccumulate = totals[category.categoryId].get(column) ?? 0;
          totals[category.categoryId].set(column, columnAccumulate + value);
        }
      }

      categoriesWithRows[category.categoryId].rows.push(row);

      if (!alreadyInsertedCategories.has(category.categoryId)) {
        alreadyInsertedCategories.add(category.categoryId);
        sectionCategories.push({ name: category.categoryId });
      }
    } else {
      sectionCategoryless.push(row);
    }
  });

  result.push(...sectionCategories, ...sectionCategoryless);

  for (const [categoryId, category] of Object.entries(categoriesWithRows)) {
    const categoryGroup = [...category.rows];
    const totalRow: Row = {};
    for (const column of columns) {
      if (column.field) {
        totalRow[column.field] = totals[categoryId].get(column);
      }
    }

    totalRow.category = category;

    totalRow.isTotal = true;
    totalRow.name = `'${String(category.name)}' Total`;
    totalRow.categorizable = false;
    totalRow.isCategoryTotalRow = totalRow.isTotal && totalRow.category;
    totalRow.getValue = () => 0;
    totalRow.renderCell = (
      row: Row,
      value: number,
      column: Column,
      failedCells: FailedCells,
      onCellChange: TableProps['onCellChange'],
      onCellOrCommentBlur: TableProps['onCellOrCommentBlur'],
      renderOpts: any,
      rows: Row[],
      onCellClick?: TableProps['onCellClick']
      // eslint-disable-next-line max-params
    ) => {
      return column.field === 'name' ? (
        <div>{getCell(row, column)}</div>
      ) : (
        renderCell(
          row,
          value,
          column,
          failedCells,
          onCellChange,
          onCellOrCommentBlur,
          renderOpts,
          rows,
          reportName,
          onCellClick,
          true
        )
      );
    };

    categoryGroup.push(totalRow, { isTotal: true, isSpace: true, name: 'emptyRow', categorizable: false });

    const categoryPlaceholderToReplaceIndex = result.findIndex((row) => row.name === categoryId);

    if (categoryPlaceholderToReplaceIndex !== -1) {
      result[categoryPlaceholderToReplaceIndex] = categoryGroup;
    }
  }

  return result.flat();
}

export const getFinancialRecordOriginalStepName = (row: Row, level: Level, step: Step) => {
  const stepName = `${level}.${step}`;

  switch (stepName) {
    case 'federal.deferred':
    case 'federal.rtp':
    case 'state.rtp':
    case 'state.deferred': {
      const stepNamePrefixesFromOtherTabs = {
        'federal.deferred': ['federal.temporary'],
        'federal.rtp': ['federal.temporary', 'federal.permanent', 'federal.tax-effected'],
        'state.rtp': ['state.modifications', 'state.tax-effected'],
        'state.deferred': ['federal.temporary', 'state.modifications']
      };

      if (row.creditName && stepNamePrefixesFromOtherTabs[stepName].some((prefix) => row.creditName.includes(prefix))) {
        return row.creditName;
      }

      return stepName;
    }

    default:
      return stepName;
  }
};

export function getCell(row: Row, { getValue, field }: any) {
  return getValue ? getValue(row) : row[field];
}

export const categorizableEntityDetailsCell = (row: Row, column: Column, currentContainer?: Container) => {
  let accountName = row.name;

  if (row?.creditName?.includes('state.modifications') && accountName) {
    accountName = removeStatePrefixFromAdjustmentName(accountName);
  }

  return (
    <div>
      {getCell(row, column)}
      {row.category ? (
        <div className="float-right" data-testid="category-row-color-tool-tip">
          <ColorTooltip
            currentContainer={currentContainer}
            category={row.category}
            originalStepName={getFinancialRecordOriginalStepName(row, row.level, row.step)}
            accountName={accountName ?? ''}
          />
        </div>
      ) : row.categorizable === false || currentContainer?.isFinalized ? null : (
        <div className="float-right" data-testid="category-row-categories-popover">
          <CategoriesPopover
            originalStepName={getFinancialRecordOriginalStepName(row, row.level, row.step)}
            accountName={accountName ?? ''}
          />
        </div>
      )}
    </div>
  );
};
