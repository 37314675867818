const SortDownIcon = () => (
  <svg
    width="10px"
    height="14px"
    viewBox="0 0 10 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>B6000848-4E76-4AF9-8450-471E1994F4AD</title>
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-389.000000, -1726.000000)" fill="#677283" id="Icon/Sort">
        <g transform="translate(382.000000, 1721.000000)">
          <g id="Icon/Sort-Down" transform="translate(7.000000, 5.000000)">
            <path d="M4,0 L6.08333333,0 L6.08333333,9 L10,9 L4.85135243,14 L0,9 L4,9 L4,0 Z" id="Sort-Down" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SortDownIcon;
