import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { fetchCustomGroups } from '../../redux/customGroups';
import { selectCustomGroups } from '../../selectors/customGroups';

export function useCustomGroups() {
  const dispatch = useDispatch();
  const customGroupsState = useSelector(selectCustomGroups);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      dispatch(fetchCustomGroups());
      setIsLoading(false);
    }

    if (!customGroupsState.isLoaded && !isLoading && !customGroupsState.error) {
      void fetchData();
    }
  }, [customGroupsState.error, customGroupsState.isLoaded, dispatch, isLoading]);

  return {
    ...customGroupsState
  };
}
