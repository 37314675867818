import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { enqueueNotification } from '../../redux/notifications';
import HTTPService, { LambdaResponse } from '../../services/http';

export const useDataImportUpload = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const uploadFileToS3 = useCallback(
    async (file: File) => {
      try {
        const { data } = await HTTPService.request<
          LambdaResponse<{ preSignedUrl: string; originalFileName: string; sanitizedFileName: string }>
        >({
          method: 'post',
          relativePath: '/v1/data-imports',
          data: {
            fileName: file.name,
            fileType: file.type || 'application/vnd.ms-excel.sheet.binary.macroenabled.12' // NOTE: Workaround due to .xlsb uploads not recognizing fileType
          }
        });

        const { preSignedUrl } = data;

        await HTTPService.simpleRequest({
          method: 'put',
          url: preSignedUrl,
          data: file
        });
      } catch {
        dispatch(
          enqueueNotification({
            message: t('upload-manager-upload-file-error'),
            options: {
              variant: 'warning'
            }
          })
        );
      }
    },
    [dispatch, t]
  );

  return { uploadFileToS3 };
};
