import { makeStyles } from '@material-ui/core';

import theme from '../../../../theme';

const useStyles = makeStyles(() => ({
  bodyText1: {
    color: theme.palette.text.dark,
    fontSize: theme.typography.h2.fontSize,
    fontFamily: theme.typography.h3.fontFamily,
    fontWeight: theme.typography.h3.fontWeight,
    letterSpacing: theme.typography.body1.letterSpacing,
    lineHeight: '24px',
    padding: '21px 20px 0px 75px'
  },
  bodyText2: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.h3.fontSize,
    letterSpacing: theme.typography.h3.letterSpacing,
    lineHeight: '22px',
    padding: '7px 20px 10px 75px',
    width: '940px'
  },
  buttonContainer: {
    backgroundColor: theme.palette.common.white,
    height: theme.spacing(6)
  },
  cancelButton: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.body2.fontSize,
    fontFamily: theme.typography.body2.fontFamily,
    letterSpacing: theme.typography.body1.letterSpacing,
    borderRadius: '4px',
    lineHeight: '18px',
    paddingTop: '9px',
    paddingBottom: '9px',
    marginRight: '8px'
  },
  cardHeader: {
    color: theme.palette.text.dark,
    fontSize: theme.typography.h3.fontSize,
    fontFamily: theme.typography.h3.fontFamily,
    fontWeight: theme.typography.h3.fontWeight,
    letterSpacing: '0.22px',
    marginLeft: '25px',
    marginTop: '19px'
  },
  cardSubheader: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.common.white,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body1.fontSize,
    letterSpacing: theme.typography.body1.letterSpacing,
    marginLeft: '25px',
    paddingBottom: '8px',
    paddingTop: '24px',
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  rtpCardSubheader: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.body1.fontSize,
    letterSpacing: theme.typography.body1.letterSpacing,
    lineHeight: '18px',
    marginLeft: '25px'
  },
  closeButton: {
    padding: '5px',
    marginLeft: 'auto',
    marginRight: '21px',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  closeIcon: {
    fontSize: '20px',
    color: theme.palette.text.secondary
  },
  contentContainer: {
    overflow: 'auto'
  },
  dialogPaper: {
    minWidth: '1200px',
    height: '805px',
    display: 'flex',
    flexDirection: 'column'
  },
  headerContainer: {
    height: '73px',
    backgroundColor: theme.palette.common.white
  },
  headerText: {
    color: theme.palette.text.dark,
    fontSize: theme.typography.h2.fontSize,
    fontFamily: theme.typography.h3.fontFamily,
    fontWeight: theme.typography.h3.fontWeight,
    letterSpacing: theme.typography.body1.letterSpacing,
    lineHeight: '24px',
    padding: '10px 20px 10px 14px'
  },
  iconContainer: {
    backgroundColor: theme.palette.info.main,
    marginLeft: '27px',
    padding: '5px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  rtpHeaderContainer: {
    paddingBottom: '39px'
  },
  sectionCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '446px',
    width: '541px',
    borderRadius: '4px',
    backgroundColor: theme.palette.common.white,
    boxShadow: '0 0 4px 0 rgba(0,0,0,0.12)'
  },
  saveButton: {
    width: '151px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    fontSize: theme.typography.body2.fontSize,
    fontFamily: theme.typography.body2.fontFamily,
    letterSpacing: theme.typography.body1.letterSpacing,
    borderRadius: '4px',
    marginRight: '77px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CC`
    }
  },
  sectionContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    margin: '21px 20px 87px 20px'
  },
  tableContainer: {
    backgroundColor: theme.palette.common.white,
    display: 'inline-block',
    maxHeight: '320px',
    flexGrow: 1,
    overflow: 'auto',
    paddingLeft: '26px',
    paddingRight: '26px',
    boxShadow: 'none',
    '& tbody > tr > div': {
      backgroundColor: theme.palette.common.white,
      height: '44.5px',
      paddingTop: '8px',
      paddingBottom: '0px',
      textAlign: 'right'
    }
  },
  tableRow: {
    height: '44px',
    paddingTop: '0px',
    paddingBottom: '0px',
    border: `1px solid ${theme.palette.divider}`
  },
  tableCell: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.lighter,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body1.fontSize,
    letterSpacing: theme.typography.body1.letterSpacing,
    lineHeight: '18px',
    paddingTop: '0px',
    paddingBottom: '0px',
    border: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderLeft: 'hidden',
    minWidth: '265px'
  },
  tableTotalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between'
  },
  totalSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: '25px',
    boxSizing: 'border-box',
    height: '83px',
    borderTop: `1px solid ${theme.palette.divider}`
  },
  totalText: {
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    letterSpacing: theme.typography.body2.letterSpacing,
    fontWeight: theme.typography.body2.fontWeight,
    lineHeight: '18px',
    textAlign: 'left',
    paddingLeft: '24px'
  },
  totalValueContainer: {
    height: '44px',
    width: '188px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '5px 10px 5px 10px'
  },
  valueText: {
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    letterSpacing: theme.typography.body2.letterSpacing,
    fontWeight: theme.typography.body2.fontWeight
  },
  noPadding: {
    padding: '0px!important'
  }
}));

export { useStyles };
