import { ReactElement, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Close from '@material-ui/icons/Close';
import { SnackbarKey, SnackbarProvider, useSnackbar } from 'notistack';

import { closeNotification, removeNotification } from '../../redux/notifications';
import { selectNotifications } from '../../selectors';

/**
 * The following code and the notifications reducer code are partially derived from the work of user
 * "iamhosseindhv" and the "notistack" library documentation.
 */

const useStyles = makeStyles((theme) => ({
  iconButton: {
    cursor: 'pointer',
    padding: theme.spacing(0.5)
  }
}));

const NotificationsProviderComponent = ({ children }: { children: ReactElement }) => {
  const [displayed, setDisplayed] = useState<SnackbarKey[]>([]);
  const dispatch = useDispatch();
  const notifications = useSelector(selectNotifications);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        closeSnackbar(key);
        return;
      }

      if (displayed.includes(key)) return;

      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, myKey) => {
          options.onClose?.(event, reason, myKey);
        },
        onExited: (_event, myKey) => {
          dispatch(removeNotification(myKey));
          setDisplayed((displayedKeys) => displayedKeys.filter((displayedKey) => displayedKey !== myKey));
        }
      });

      setDisplayed((displayedKeys) => [...displayedKeys, key]);
    });
  }, [closeSnackbar, dispatch, displayed, enqueueSnackbar, notifications]);

  return children;
};

const NotificationsProvider = ({ children }: { children: ReactElement }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <SnackbarProvider
      preventDuplicate
      action={(key) => (
        <IconButton className={classes.iconButton} color="inherit" onClick={() => dispatch(closeNotification(key))}>
          <Close />
        </IconButton>
      )}
      iconVariant={{
        error: ''
      }}
    >
      <NotificationsProviderComponent>{children}</NotificationsProviderComponent>
    </SnackbarProvider>
  );
};

export default NotificationsProvider;
