import { Currency, CustomGroup } from '../../../models';

export const formatCustomGroups = (
  customGroupsResponse: CustomGroup[],
  customGroupCurrencies: Record<string, Currency[]>
) => {
  const formattedCustomGroups = customGroupsResponse.map((group: CustomGroup) => {
    const groupCurrency = customGroupCurrencies[group.groupId];

    return [
      group.groupId,
      {
        ...group,
        id: group.groupId,
        isEditable: false,
        entities: [],
        currencies: groupCurrency
      }
    ];
  });
  return formattedCustomGroups;
};

export const formatCustomGroupsForUpdate = (
  customGroupsResponse: CustomGroup[],
  customGroupCurrencies: Record<string, Currency[]>
): CustomGroup[] => {
  const updatedCustomGroups = customGroupsResponse.map((group: CustomGroup) => {
    const groupCurrency = customGroupCurrencies[group.groupId];

    return {
      ...group,
      id: group.groupId,
      isEditable: false,
      entities: [],
      currencies: groupCurrency
    };
  });
  return updatedCustomGroups;
};
