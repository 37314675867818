import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

import { Currency } from '../../../models';

interface Props {
  currencies: Currency[];
  value: Currency;
  handleChange: (value: any) => void;
}

const CurrencySelector = ({ currencies, value, handleChange }: Props) => {
  return (
    <Select
      disableUnderline
      IconComponent={KeyboardArrowDown}
      value={value?.currencyId}
      onChange={({ target: { value } }) => {
        handleChange(value);
      }}
    >
      {currencies?.map(({ name, currencyId }) => (
        <MenuItem key={currencyId} value={currencyId}>
          {name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CurrencySelector;
