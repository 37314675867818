import { useEffect } from 'react';

export function useScript(url: string) {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    document.body.append(script);

    return () => {
      script.remove();
    };
  }, [url]);
}
