import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { formatDate } from '../../../utils';
import ProStaticDatePicker from '../../ProStaticDatePicker';

const useStyles = makeStyles((theme) => ({
  inputTitle: {
    color: theme.palette.text.secondary,
    fontFamily: 'Basis Grotesque Pro Medium',
    letterSpacing: '0.25px',
    fontSize: '14px',
    lineHeight: '18px',
    marginBottom: '9px'
  },
  input: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#6E56DB'
        }
      }
    }
  },
  inputGruopLeft: {
    marginLeft: '24px'
  },
  inputGruopRight: {
    marginLeft: '24px'
  },
  popoverContent: {
    backgroundColor: theme.palette.common.white
  },
  calendarsSection: {
    display: 'flex',
    paddingTop: '29px',
    paddingRight: '29px'
  },
  button: {
    fontFamily: theme.typography.body2.fontFamily,
    letterSpacing: '0.25px',
    lineHeight: '18px',
    borderRadius: '4px',
    border: '1px solid',
    borderColor: 'transparent',
    paddingBottom: '8px',
    paddingTop: '8px',
    paddingLeft: '28px',
    paddingRight: '28px'
  },
  bottomControls: {
    padding: '16px',
    textAlign: 'end',
    backgroundColor: theme.palette.common.white
  },
  doneButton: {
    marginLeft: '0.25rem'
  },
  cancelButton: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main
  }
}));

type SelectionPopoverProps = {
  initialStartDate: Date;
  initialEndDate: Date;
  anchorEl: HTMLButtonElement | null;
  onSave: (startDate: Date, endDate: Date) => void;
  onClose: () => void;
};

const TimePeriodSelectorPopover = ({
  initialStartDate,
  initialEndDate,
  anchorEl,
  onSave,
  onClose
}: SelectionPopoverProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [textStartDate, setTextStartDate] = useState<string | null>(formatDate(initialStartDate, 'MM/DD/YYYY'));
  const [textEndDate, setTextEndDate] = useState<string>(formatDate(initialEndDate, 'MM/DD/YYYY'));
  const [startDate, setStartDate] = useState<Date>(initialStartDate);
  const [endDate, setEndDate] = useState<Date>(initialEndDate);

  const handleOnCancel = () => {
    onClose();
  };

  const handleOnSave = () => {
    onSave(startDate, endDate);
    onClose();
  };

  useEffect(() => {
    setTextStartDate(formatDate(initialStartDate, 'MM/DD/YYYY'));
    setTextEndDate(formatDate(initialEndDate, 'MM/DD/YYYY'));
    setStartDate(initialStartDate);
    setEndDate(initialEndDate);
  }, [initialStartDate, initialEndDate]);

  return (
    <Popover
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      onClose={onClose}
    >
      <Box className={classes.popoverContent}>
        <Box className={classes.calendarsSection}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box>
              <Box className={classes.inputGruopLeft}>
                <Typography variant="h3" className={classes.inputTitle}>
                  {`${t('from')}:`}
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  className={classes.input}
                  value={textStartDate}
                  onChange={(e) => {
                    setTextStartDate(e.target.value);
                    const newDate = new Date(e.target.value);

                    if (newDate.toString() !== 'Invalid Date') {
                      setStartDate(newDate);
                    }
                  }}
                />
              </Box>

              <ProStaticDatePicker
                displayStaticWrapperAs="desktop"
                value={startDate}
                renderInput={(params: any) => <TextField {...params} />}
                onChange={(newDate) => {
                  setTextStartDate(formatDate(newDate as Date, 'MM/DD/YYYY'));
                  setStartDate(newDate as Date);
                }}
              />
            </Box>

            <Box>
              <Box className={classes.inputGruopRight}>
                <Typography variant="h3" className={classes.inputTitle}>
                  {`${t('to')}:`}
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  className={classes.input}
                  value={textEndDate}
                  onChange={(e) => {
                    setTextEndDate(e.target.value);
                    const newDate = new Date(e.target.value);

                    if (newDate.toString() !== 'Invalid Date') {
                      setEndDate(newDate);
                    }
                  }}
                />
              </Box>
              <ProStaticDatePicker
                displayStaticWrapperAs="desktop"
                value={endDate}
                renderInput={(params: any) => <TextField {...params} />}
                onChange={(newDate) => {
                  setTextEndDate(formatDate(newDate as Date, 'MM/DD/YYYY'));
                  setEndDate(newDate as Date);
                }}
              />
            </Box>
          </LocalizationProvider>
        </Box>

        <Divider />

        <Box className={classes.bottomControls}>
          <Button
            className={`${classes.button} ${classes.cancelButton}`}
            color="primary"
            variant="contained"
            onClick={handleOnCancel}
          >
            {t('cancel')}
          </Button>
          <Button
            className={`${classes.button} ${classes.doneButton}`}
            color="primary"
            variant="contained"
            onClick={handleOnSave}
          >
            {t('done')}
          </Button>
        </Box>
      </Box>
    </Popover>
  );
};

export default TimePeriodSelectorPopover;
