import { UsaGroupId, UsaGroupUuid, WorldGroupId, WorldGroupUuid } from '../constants';

const transformGroupUuid = (groupId: string): string => {
  if (groupId === UsaGroupId) {
    return UsaGroupUuid;
  }

  if (groupId === WorldGroupId) {
    return WorldGroupUuid;
  }

  return groupId;
};

export const transformGroupUuidToId = (groupId: string): string => {
  if (groupId === UsaGroupUuid) {
    return UsaGroupId;
  }

  if (groupId === WorldGroupUuid) {
    return WorldGroupId;
  }

  return groupId;
};

export default transformGroupUuid;
