import { makeStyles, Theme } from '@material-ui/core/styles';
import * as d3 from 'd3';

type StyleProps = {
  size: number;
  isCompleted: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  svg: {
    width: ({ size }) => size,
    height: ({ size }) => size
  },
  arcContainer: {
    transform: ({ size }) => `translate(${size / 2}px, ${size / 2}px) scale(0.9)`
  },
  'path-0': {
    fill: ({ isCompleted }) => (isCompleted ? theme.palette.action.focus : theme.palette.progress.main),
    stroke: ({ isCompleted }) => (isCompleted ? theme.palette.action.focus : theme.palette.progress.main),
    strokeWidth: 4
  },
  'path-1': {
    fill: ({ isCompleted }) => (isCompleted ? theme.palette.action.focus : theme.palette.progress.lighter),
    stroke: ({ isCompleted }) => (isCompleted ? theme.palette.action.focus : theme.palette.progress.lighter),
    strokeWidth: 4
  },
  text: {
    textAnchor: 'middle',
    fontSize: theme.typography.fontSize,
    fill: ({ isCompleted }) => (isCompleted ? theme.palette.common.white : theme.palette.grey[600])
  },
  percentageSign: {
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: theme.typography.fontSize * 0.7
  },
  circle: {
    cx: 0,
    cy: 0,
    r: 21,
    fill: theme.palette.primary.main
  }
}));

interface Props {
  percentage: number;
  size?: number;
}

const DonutChart = ({ percentage, size = 50 }: Props) => {
  const isCompleted = percentage === 100;
  const classes = useStyles({ size, isCompleted });
  const values = [
    {
      key: 0,
      value: percentage
    },
    {
      key: 1,
      value: 100 - percentage
    }
  ];
  const createPie = d3
    .pie()
    .value((d: any) => d.value)
    .sort(null);
  const createArc = d3
    .arc()
    .innerRadius(size / 2 - 2)
    .outerRadius(size / 2);
  const data = createPie(values as any);

  return (
    <svg className={classes.svg}>
      <g className={classes.arcContainer}>
        {data.map((d: any, i: number) => (
          <g key={values[i].key}>
            <path d={createArc(d) ?? undefined} className={classes[`path-${i}`]} />
          </g>
        ))}
        {isCompleted && <circle className={classes.circle} />}
        <text className={classes.text}>
          <tspan x="0" dy="-3" className={classes.percentageSign}>
            %
          </tspan>
          <tspan x="0" dy="13">
            {percentage}
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default DonutChart;
