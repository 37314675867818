import { useTranslation } from 'react-i18next';

import { Box, makeStyles } from '@material-ui/core';
import AutorenewIcon from '@material-ui/icons/Autorenew';

const useStyles = makeStyles((theme) => ({
  container: {
    color: '#ABB1BA',
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.spacing(1),
    gap: theme.spacing(0.16),
    padding: '0 8px',
    height: theme.spacing(1.6),
    '& > span': {
      textTransform: 'uppercase'
    },
    '&.warning': {
      backgroundColor: '#FEF7E0',
      color: '#82703A'
    }
  },
  icon: {
    width: theme.spacing(1.3)
  }
}));

interface Props {
  variant?: 'warning';
}
const UpdatingBadge = ({ variant }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={`${classes.container} ${variant ?? ''}`}>
      <AutorenewIcon className={classes.icon} />
      <span>{t('updating')}</span>
    </Box>
  );
};

export default UpdatingBadge;
