export interface EulaStateInterface {
  accepted: boolean;
  isLoading: boolean;
  isLoaded: boolean;
  error: unknown;
}

export const eulaInitialState: EulaStateInterface = {
  accepted: false,
  isLoading: false,
  isLoaded: false,
  error: null
};
