import { GenericNumericalRecord, GenericRecord, makeListExtractor, sum } from './utils';

export const extractListsForCredits = makeListExtractor('credits', [
  'beginningBalance',
  'rtp',
  'generatedAmount',
  'usedAmount',
  'deferredOnlyAdjustment',
  'oci',
  'goodwill',
  'fin48',
  'balanceSheetOnlyAdjustment',
  'remaining'
]);

export const calculateCreditSum = (inputs: any, prov4011TotalRowFix = false) => {
  const {
    beginningBalanceList,
    rtpList,
    generatedAmountList,
    usedAmountList,
    deferredOnlyAdjustmentList,
    ociList,
    goodwillList,
    fin48List,
    balanceSheetOnlyAdjustmentList,
    remainingList
  } = extractListsForCredits(inputs);

  const generatedAmount = sum(generatedAmountList);
  const usedAmount = sum(usedAmountList);

  return {
    ...inputs,
    beginningBalance: sum(beginningBalanceList),
    generatedAmount,
    usedAmount,
    rtp: sum(rtpList),
    deferredOnlyAdjustment: sum(deferredOnlyAdjustmentList),
    oci: sum(ociList),
    goodwill: sum(goodwillList),
    fin48: sum(fin48List),
    balanceSheetOnlyAdjustment: sum(balanceSheetOnlyAdjustmentList),
    m1Adjustment: usedAmount + generatedAmount,
    remaining: prov4011TotalRowFix ? null : sum(remainingList)
  };
};

interface CalculateEndingBalanceForCreditsReturn extends GenericRecord {
  endingBalance: number;
}

export const calculateEndingBalanceForCredits = (
  inputs: GenericRecord = {}
): CalculateEndingBalanceForCreditsReturn => {
  const {
    beginningBalance,
    rtp,
    generatedAmount,
    usedAmount,
    deferredOnlyAdjustment,
    oci,
    goodwill,
    fin48,
    balanceSheetOnlyAdjustment
  }: GenericNumericalRecord = inputs;
  return {
    ...inputs,
    endingBalance:
      (beginningBalance ?? 0) +
      (rtp ?? 0) +
      (generatedAmount ?? 0) +
      (usedAmount ?? 0) +
      (deferredOnlyAdjustment ?? 0) +
      (oci ?? 0) +
      (goodwill ?? 0) +
      (fin48 ?? 0) +
      (balanceSheetOnlyAdjustment ?? 0)
  };
};
