import { UiStateInterface } from './ui.types';

import { DASHBOARD_CARD_TYPES } from '../../constants';

export const uiInitialState: UiStateInterface = {
  isRfModalOpen: false,
  isTemporaryBalanceSheetTabOpen: false,
  subJurisdictionSidemenuSelectedId: undefined,
  isEntityDetailsGroupedViewToggled: false,
  shouldGroupCategoriesOnReports: false,
  toggledCategoriesOnReports: {},
  dashboardSelectedCard: DASHBOARD_CARD_TYPES.effectiveTaxRate,
  countriesData: null
};
