import i18next from 'i18next';

import { useEntities } from '../../../../hooks';
import { Entity, Header, Report } from '../../../../models';
import isUUID from '../isUUID';

const collator = new Intl.Collator(i18next.language, { numeric: true });

export const reportsWithTripleHeaders = new Set(['state-rtp', 'state-current-provision']);

export const concatHeaderAndAddToMap = (
  header: Header,
  entityId: string,
  entityById: Record<string, Entity>,
  headerGroupsByEntity: Record<string, Header[]>
) => {
  const entityName = entityById[entityId]?.name;
  headerGroupsByEntity[entityName] = headerGroupsByEntity[entityName] ?? [];
  const headerName = entityName && header.name ? `${entityName} - ${header.name}` : header.name;
  headerGroupsByEntity[entityName].push({ ...header, name: headerName });
  return headerGroupsByEntity;
};

export const headersObjectToSortedArray = (headerGroupsByEntity: Record<string, Header[]>) => {
  let headers: Header[] = [];
  const entityNames = Object.keys(headerGroupsByEntity);
  const sortedEntityNames = entityNames.sort((entityName1, entityName2) => collator.compare(entityName1, entityName2));
  sortedEntityNames.forEach((entityName) => {
    headers = [...headers, ...headerGroupsByEntity[entityName]];
  });
  return headers;
};

export function useHeaderProcessor(report: Report) {
  let { headers, reportTypeName } = report;
  const { entityById } = useEntities();

  if (reportTypeName === 'multi-entity' && reportsWithTripleHeaders.has(report.name)) {
    let newHeaders: Header[] = [];
    let headerGroupsByEntity: Record<string, Header[]> = {};
    let lastGroup: string | undefined = '';
    headers.forEach((header) => {
      if (lastGroup && lastGroup !== header.headerDisplayGroup) {
        newHeaders = [...newHeaders, ...headersObjectToSortedArray(headerGroupsByEntity)];
        headerGroupsByEntity = {};
      }

      lastGroup = header.headerDisplayGroup;

      const headerFieldSplitted = header.field?.split('_') ?? [];
      const entityId = headerFieldSplitted.find((headerPart) => isUUID(headerPart));
      if (entityId) {
        headerGroupsByEntity = concatHeaderAndAddToMap(header, entityId, entityById, headerGroupsByEntity);
      } else {
        newHeaders = [...newHeaders, ...headersObjectToSortedArray(headerGroupsByEntity)];
        newHeaders = [...newHeaders, header];
        headerGroupsByEntity = {};
      }
    });
    headers = [...newHeaders, ...headersObjectToSortedArray(headerGroupsByEntity)];
  }

  return headers;
}
