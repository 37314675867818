import { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useRouteMatch, useParams, useHistory, useLocation } from 'react-router-dom';

import { SlideOut } from '@xbs/xbs-common-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ByEntityReport, ByGroupReport, ReportList, ReportPage } from './components';
import SummaryRouter from './components/SummaryRouter';
import { formatCustomGroups, formatCustomGroupsForUpdate } from './utils/handleCustomGroups';

import { TitleNavigation } from '..';
import { USD_CURRENCY_ID, WorldGroupId, UsaJurisdictionId } from '../../constants';
import {
  useEntities,
  useJurisdictions,
  useCurrencies,
  useContainers,
  useCustomGroups,
  useFeatureFlags,
  useCompletionStatus,
  useDetailedReport,
  useReportList
} from '../../hooks';
import { Currency, CustomGroup, Entity, EntityById, Group, JurisdictionById } from '../../models';
import { fetchCustomGroupEntities } from '../../redux/customGroups';
import { updateReportsTimestamps } from '../../redux/reports';
import { selectDoesUserHaveRole } from '../../selectors';
import { selectCustomGroupCurrencies, selectCustomGroupsEntities } from '../../selectors/customGroups';
import { selectEntitiesCompletionStatus } from '../../selectors/entitiesCompletionStatus';
import transformGroupUuid from '../../utils/transformGroupUuid';
import { isEntityFullyComplete } from '../EntityDetails/utils';
import LoadingSpinner from '../LoadingSpinner';
import { ProReviewer } from '../UserRoleStylesProvider/constants';

export type MappableGroup = Iterable<
  readonly [string, { id: string; name: string; isEditable: boolean; entities: never[]; currencies: Currency[] }]
>;

export type GroupById = Map<'us-consolidated' | 'worldwide-consolidated' | string, Group>;

type EntityReportListProps = {
  entityByNumber: Record<string, Entity>;
};

const EntityReportList = ({ entityByNumber }: EntityReportListProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { prov3137ReportsStatusRework } = useFlags();
  const { entityNumber, currencyISO } = useParams<{ entityNumber: string; currencyISO: string }>();

  const entityId = entityByNumber[entityNumber]?.entityId;
  const { isLoading, reportsList: reports, error } = useReportList('entity', entityId, currencyISO);

  useEffect(() => {
    const reportsMeta = {
      isCurrencyConversion: currencyISO === 'USD',
      reportSourceId: entityId,
      reportType: 'single-entity',
      timestamps: reports?.map((report) => {
        return {
          reportName: report.name,
          reportTimestamp: report.reportTimestamp
        };
      })
    };

    if (prov3137ReportsStatusRework && reports) {
      dispatch({ type: updateReportsTimestamps, payload: reportsMeta });
    }
  }, [reports, currencyISO, dispatch, entityId, prov3137ReportsStatusRework]);

  const headerTitle = entityByNumber[entityNumber]?.name;
  const hasStates = entityByNumber[entityNumber]?.hasStates ?? false;

  useEffect(() => {
    if (!entityNumber || error) {
      history.push('/reports/entities');
    }
  }, [entityNumber, error, history]);

  return isLoading || !reports || !headerTitle ? (
    <LoadingSpinner />
  ) : (
    <ReportList
      currencyISO={currencyISO}
      title={headerTitle}
      reports={reports}
      backTo="/reports/entities"
      hasStates={hasStates}
      type="entity"
    />
  );
};

type GroupReportListProps = {
  groupById: GroupById;
};

const GroupReportList = ({ groupById }: GroupReportListProps) => {
  const history = useHistory();
  const { groupId, currencyISO } = useParams<{ groupId: string; currencyISO: string }>();
  const groupUuid = transformGroupUuid(groupId);
  const { isLoading, reportsList: reports, error } = useReportList('group', groupUuid, currencyISO);
  const { prov3137ReportsStatusRework, prov3477RefactorStateRelatedReportList } = useFlags();
  const headerTitle = groupById.get(groupId)?.name;
  const { entities } = useEntities();
  const dispatch = useDispatch();
  const usdCurrencyId = 150;
  const areAllContainerEntitiesForeign = entities.every((entity) => entity.currencyId !== usdCurrencyId);
  const groupsEntities = useSelector(selectCustomGroupsEntities);
  const areAllGroupEntitiesForeign =
    groupId && groupsEntities[groupId]
      ? groupsEntities[groupId].every((entity) => entity.currencyId !== usdCurrencyId)
      : false;

  let hasStates = !areAllGroupEntitiesForeign;

  if (groupId === 'worldwide-consolidated') {
    hasStates = !areAllContainerEntitiesForeign;
  }

  if (groupId === 'us-consolidated') {
    hasStates = true;
  }

  if (prov3477RefactorStateRelatedReportList) {
    hasStates = groupId && groupsEntities[groupId] ? groupsEntities[groupId].some((entity) => entity.hasStates) : false;

    if (groupId === 'worldwide-consolidated') {
      hasStates = entities.some((entity) => entity.hasStates);
    }

    if (groupId === 'us-consolidated') {
      hasStates = entities
        .filter((entity) => entity.jurisdictionId === UsaJurisdictionId)
        .some((entity) => entity.hasStates);
    }
  }

  useEffect(() => {
    const reportsMeta = {
      isCurrencyConversion: currencyISO === 'USD',
      reportSourceId: groupUuid,
      reportType: 'multi-entity',
      timestamps: reports?.map((report) => {
        return {
          reportName: report.name,
          reportTimestamp: report.reportTimestamp
        };
      })
    };

    if (prov3137ReportsStatusRework && reports) {
      dispatch({ type: updateReportsTimestamps, payload: reportsMeta });
    }
  }, [reports, currencyISO, dispatch, groupUuid, prov3137ReportsStatusRework]);

  useEffect(() => {
    if (!groupId || error) {
      history.push('/reports/groups');
    }
  }, [error, groupId, history]);

  return isLoading || !reports || !headerTitle ? (
    <LoadingSpinner />
  ) : (
    <ReportList
      hasStates={hasStates}
      currencyISO={currencyISO}
      title={headerTitle}
      reports={reports}
      backTo="/reports/groups"
      type="group"
    />
  );
};

type DetailedReportProps = {
  reportType: 'group' | 'entity';
  id: string;
  reportName: string;
  entityById: EntityById;
  currencyISO: string;
  groupName: string;
};

const DetailedReport = ({ reportType, id, reportName, entityById, currencyISO, groupName }: DetailedReportProps) => {
  const history = useHistory();
  const { currentContainer } = useContainers();
  const {
    isLoading,
    report,
    error,
    isNewReportAvailable,
    currentReportMeta,
    setNewReportAsCurrent
  } = useDetailedReport(reportType, id, reportName, currencyISO);
  const { pathname } = useLocation();
  const backUrl = pathname.split('/').slice(0, -1).join('/');
  const isSingleEntity = report?.reportTypeName === 'single-entity';

  const onReloadReportClick = () => {
    setNewReportAsCurrent();
  };

  useEffect(() => {
    if (error) {
      history.push(backUrl);
    }
  }, [backUrl, error, history]);

  return isLoading || !report ? (
    <LoadingSpinner />
  ) : (
    <ReportPage
      isNewReportAvailable={isNewReportAvailable}
      isSingleEntity={isSingleEntity}
      container={currentContainer}
      entityById={entityById}
      report={report}
      currency={currencyISO}
      groupName={groupName}
      currentReportMeta={currentReportMeta}
      onReloadReportClick={onReloadReportClick}
    />
  );
};

const Reports = () => {
  const { path } = useRouteMatch();
  const [reportCurrencySelections, setReportCurrencySelections] = useState<Record<string, Currency>>({});
  const [groupName, setGroupName] = useState('');
  const [formattedCustomGroups, setFormattedCustomGroups] = useState<MappableGroup>([]);
  const [updatedCustomGroups, setUpdatedCustomGroups] = useState<CustomGroup[]>([]);
  const { entityNumber, groupId, reportName, currencyISO } = useParams<{
    entityNumber: string;
    groupId: string;
    reportName: string;
    currencyISO: string;
  }>();
  const areReportsLoading = false;
  const {
    isLoading: isEntitiesLoading,
    entities,
    entityById,
    entityByNumber,
    isLoaded: entitiesLoaded
  } = useEntities();
  const { isLoading: isJurisdictionLoading, jurisdictionById } = useJurisdictions();
  const { isLoading: isCurrencyLoading, currencyById } = useCurrencies();
  const isInterimContainer = useContainers().currentContainer?.isInterimReportingPeriod;
  const shouldHideByEntitySection = isInterimContainer;

  const { isLoading: isCustomGroupsLoading, customGroups, error } = useCustomGroups();
  const dispatch = useDispatch();

  const customGroupCurrencies = useSelector(selectCustomGroupCurrencies);

  useEffect(() => {
    setFormattedCustomGroups(formatCustomGroups(customGroups, customGroupCurrencies) as MappableGroup);
    setUpdatedCustomGroups(formatCustomGroupsForUpdate(customGroups, customGroupCurrencies));
  }, [customGroupCurrencies, customGroups]);

  useEffect(() => {
    if (customGroups.length > 0) {
      for (const group of customGroups) {
        dispatch(fetchCustomGroupEntities({ groupId: group.groupId }));
      }
    }
  }, [customGroups, dispatch]);

  const groupById = useMemo(() => {
    return new Map([...formattedCustomGroups]);
  }, [formattedCustomGroups]);

  const handleReportCurrencyChange = (id: string) => (currencyId: string) => {
    setReportCurrencySelections((reportCurrencySelections) => {
      return { ...reportCurrencySelections, [id]: currencyById[currencyId] };
    });
  };

  const { checkConventionalFlag, checkUserIdsFlag } = useFeatureFlags();

  let shouldShowSummary =
    customGroups.some((group) => group.groupId === WorldGroupId) ||
    checkConventionalFlag('alwaysShowSummaryTab') ||
    checkUserIdsFlag('alwaysShowSummaryTabUserAccess');

  const isUserReviewer = useSelector(selectDoesUserHaveRole([ProReviewer.Name]));

  const { entityCompletionStatus: _ } = useCompletionStatus(entities?.[0]?.entityNumber);

  const entitiesCompletionStatus = useSelector(selectEntitiesCompletionStatus);

  shouldShowSummary =
    shouldShowSummary &&
    (!isUserReviewer ||
      entities.every((entity) =>
        isEntityFullyComplete(
          entity,
          entitiesCompletionStatus?.entitiesCompletionStatus?.[entity.entityNumber]?.completionStatus ?? {}
        )
      ));

  const tabs = [
    ...(shouldShowSummary
      ? [
          {
            value: 'summary',
            render: () => <SummaryRouter />
          }
        ]
      : []),
    {
      value: 'groups',
      render: () => (
        <ByGroupReport
          isCustomGroupsLoading={isCustomGroupsLoading}
          reportCurrencySelections={reportCurrencySelections}
          handleReportCurrencyChange={handleReportCurrencyChange}
          groups={updatedCustomGroups}
          entities={entities}
          error={error}
        />
      )
    }
  ];

  if (!shouldHideByEntitySection) {
    tabs.push({
      value: 'entities',
      render: () => (
        <ByEntityReport
          jurisdictionById={jurisdictionById as JurisdictionById}
          currencyById={currencyById}
          entities={entities}
          reportCurrencySelections={reportCurrencySelections}
          handleReportCurrencyChange={handleReportCurrencyChange}
        />
      )
    });
  }

  useEffect(() => {
    for (const [id, _group] of groupById) {
      if (id === groupId) {
        setGroupName(_group.name);
      }
    }
  }, [groupById, groupId]);

  useEffect(() => {
    const dropDownMap: Record<string, Currency> = {};
    for (const [groupId, _group] of groupById) {
      dropDownMap[groupId] = currencyById[USD_CURRENCY_ID];
    }

    for (const entity of entities) {
      dropDownMap[entity.entityId] = currencyById[entity.currencyId];
    }

    setReportCurrencySelections(dropDownMap);
  }, [currencyById, entities, groupById]);
  // Since report details take the whole display, their route is in src/components/Router
  // we need to display them straight when we have a match
  return (entityNumber || groupId) && reportName ? (
    // This is to support refreshing on the report page
    entitiesLoaded ? (
      <DetailedReport
        reportType={entityNumber ? 'entity' : 'group'}
        id={entityNumber ? entityByNumber[entityNumber].entityId : groupId}
        reportName={reportName}
        entityById={entityById}
        currencyISO={currencyISO}
        groupName={groupName}
      />
    ) : (
      <LoadingSpinner />
    )
  ) : (
    <SlideOut.Container>
      <Switch>
        {!shouldHideByEntitySection && (
          <Route exact path={`${path}/entities/:entityNumber/:currencyISO`}>
            <EntityReportList entityByNumber={entityByNumber} />
          </Route>
        )}
        <Route exact path={`${path}/groups/:groupId/:currencyISO`}>
          <GroupReportList groupById={groupById} />
        </Route>
        <Route exact path={`${path}/summary`}>
          <SummaryRouter />
        </Route>
        <Route path={path}>
          <TitleNavigation
            tabs={tabs}
            label="reports"
            isLoading={
              isEntitiesLoading ||
              areReportsLoading ||
              isJurisdictionLoading ||
              isCurrencyLoading ||
              isCustomGroupsLoading
            }
            defaultTab={tabs[0].value}
          />
        </Route>
      </Switch>
    </SlideOut.Container>
  );
};

export default Reports;
