import { LEVELS } from '../../../../constants';
import { Step } from '../../../../models';

export const LEVEL = LEVELS.STATE;
export const STEP: Step = 'rtp';
export const STATE_RTP = 'state.rtp';
export const DYNAMIC_TABS_STEPS: Step[] = ['tax-effected', 'modifications', 'credits'];

export const RTP_REFERENCED_STEPS: Step[] = [
  'modifications.permanent',
  'modifications.temporary',
  'tax-effected',
  'credits'
];

export const INTERLINKED_DATA_STEPS: Step[] = ['rtp', 'nol', 'credits'];
