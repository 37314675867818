import { ProductNames, UserRoles } from '@xbs/xbs-enums';

export const { ProAdmin, ProReadOnly, ProReviewer, ProPreparer } = UserRoles.ByName;

export const { Provision } = ProductNames.ByName;

const nonAdminRoles = [ProReadOnly.RoleUuid, ProReviewer.RoleUuid, ProPreparer.RoleUuid];

export const disableEntityReviewActionsForRoles = [ProReadOnly.RoleUuid];
export const hideElementForRoles = [ProReadOnly.RoleUuid, ProReviewer.RoleUuid];
export const grayOutAllChildrenForRoles = [ProReadOnly.RoleUuid];

export const disableElementForRoles = [ProReadOnly.RoleUuid, ProReviewer.RoleUuid];

export const disableParentEntityRadioButtonForRoles = [ProReadOnly.RoleUuid, ProReviewer.RoleUuid];

export const hideAddNewPeriodButtonForRoles = nonAdminRoles;

export const hideTBUploadManagerButtonForRoles = nonAdminRoles;

export const disableTableInputForRoles = [ProReadOnly.RoleUuid];

export const disableStepInProgressStatusForRoles = [ProReviewer.RoleUuid];

export const disableEntitiesTableInputForRoles = nonAdminRoles;

export const disablePointerEventsForNonAdmins = nonAdminRoles;
