import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useContainers } from '..';
import { UploadReviewTrialBalance, UseUploadReturnInterface } from '../../models';
import {
  uploadReviewOnFailed,
  uploadReviewOnSendingRequest,
  uploadReviewOnReceived,
  prepopulateUploadReview
} from '../../redux/uploadReview';
import {
  selectCurrencyByEntityId,
  selectJurisdictionByEntityId,
  selectSubJurisdictionsByEntityId,
  selectUploadReview,
  selectUploadReviewAccountsSorted
} from '../../selectors';
import HTTPService, { LambdaResponse } from '../../services/http';

const trialBalanceIsEmpty = (trialBalance: UploadReviewTrialBalance) => !trialBalance.fileName;
const trialBalanceIsPristine = (trialBalance: UploadReviewTrialBalance) => !trialBalance.isDirty;

export function useUpload(): UseUploadReturnInterface {
  const dispatch = useDispatch();
  const uploadReviewState = useSelector(selectUploadReview);
  const currencyByEntityId = useSelector(selectCurrencyByEntityId);
  const jurisdictionByEntityId = useSelector(selectJurisdictionByEntityId);
  const subJurisdictionsByEntityId = useSelector(selectSubJurisdictionsByEntityId);
  const accountsSorted = useSelector(selectUploadReviewAccountsSorted);
  const { currentContainer } = useContainers();

  useEffect(() => {
    async function fetchData() {
      dispatch(uploadReviewOnSendingRequest());
      try {
        const { data: trialBalance } = await HTTPService.request<LambdaResponse<UploadReviewTrialBalance>>({
          method: 'get',
          relativePath: '/v1/upload'
        });
        if (trialBalanceIsEmpty(trialBalance)) {
          return;
        }

        dispatch(uploadReviewOnReceived({ trialBalance }));

        if (trialBalanceIsPristine(trialBalance)) {
          dispatch(prepopulateUploadReview());
        }
      } catch (error: unknown) {
        dispatch(uploadReviewOnFailed(error));
      }
    }

    if (!uploadReviewState.isLoading && !uploadReviewState.hasLoaded && !uploadReviewState.error) {
      void fetchData();
    }
  }, [currentContainer, dispatch, uploadReviewState]);

  return {
    ...uploadReviewState,
    accounts: accountsSorted,
    currencyByEntityId,
    jurisdictionByEntityId,
    subJurisdictionsByEntityId,
    isUploadReviewCompleted: uploadReviewState.metaData.isUploadReviewCompleted
  };
}
