import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import {
  fetchCustomGroups,
  createCustomGroup,
  fetchCustomGroupEntities,
  updateCustomGroupEntities,
  deleteCustomGroups
} from './customGroups.actions';
import { customGroupsInitialState } from './customGroups.initialState';

import { Action, CustomGroupFromApi, Entity } from '../../models';

export const customGroupsReducer = createReducer(customGroupsInitialState, (builder) => {
  builder
    .addCase(fetchCustomGroups.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(fetchCustomGroups.fulfilled, (state, action: PayloadAction<CustomGroupFromApi[]>) => {
      state.customGroups = action.payload;
      state.error = undefined;
      state.isLoading = false;
    })
    .addCase(createCustomGroup.fulfilled, (state, action: Action) => {
      state.customGroups.push(action.payload);
      state.isLoading = false;
      state.error = undefined;
    })
    .addCase(
      fetchCustomGroupEntities.fulfilled,
      (state, action: PayloadAction<{ entities: Entity[]; groupId: string }>) => {
        state.customGroupsEntities[action.payload.groupId] = action.payload.entities;
        state.error = undefined;
      }
    )
    .addCase(
      updateCustomGroupEntities.fulfilled,
      (state, action: PayloadAction<{ entities: Entity[]; groupId: string }>) => {
        state.customGroupsEntities[action.payload.groupId] = action.payload.entities;
        state.error = undefined;
      }
    )
    .addCase(deleteCustomGroups.fulfilled, (state, action) => {
      const { groupIds } = action.payload;
      for (const groupId of groupIds) {
        const groupIndex = state.customGroups.findIndex((group) => group.groupId === groupId);
        state.customGroups.splice(groupIndex, 1);
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete state.customGroupsEntities[groupId];
      }

      state.error = undefined;
    })
    .addMatcher(
      (action) => action.type.match(/^customGroups\/.+\/rejected$/),
      (state, action: PayloadAction<Error | undefined>) => {
        state.error = action.payload?.message;
      }
    );
});
