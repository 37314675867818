import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { RequestObject, SimpleRequestObject } from './httpService.types';

import { Provision } from '../../components/UserRoleStylesProvider/constants';
import { REFRESH_URL, CANCELED_HTTP_ERROR_MESSAGE } from '../../constants';
import { AuthStateInterface } from '../../redux/auth';
import { ConfigurationStateInterface } from '../../redux/configuration';
import { enqueueNotification } from '../../redux/notifications';
import { selectAuthInfo } from '../../selectors/authentication';
import { selectConfiguration } from '../../selectors/configuration';
import store from '../../store';
import { decodeToken } from '../../utils/decodeToken';
import LocationService from '../location';
import PlatformService from '../platform';

class HTTPService {
  private readonly client;
  private abortController;

  constructor() {
    this.client = axios.create();
    this.client.interceptors.request.use(this.authorizeRequest.bind(this));
    this.client.interceptors.request.use(this.getProxiedRequest.bind(this));
    this.client.interceptors.request.use(this.addXbsHeaders.bind(this));
    // TODO: uncomment when we're ready to start using the proxy
    // this.client.interceptors.request.use(this.getProxiedRequest.bind(this));

    this.client.interceptors.response.use((response) => response, this.handleErrorResponse.bind(this));

    this.abortController = new AbortController();
  }

  public async request<T>({ method, apiUrlKey, relativePath = '', params, data }: RequestObject) {
    const config: ConfigurationStateInterface = selectConfiguration(store.getState());

    const requestConfig: AxiosRequestConfig = {
      signal: this.abortController.signal,
      method,
      url: relativePath,
      params,
      data,
      baseURL: apiUrlKey === 'mock' ? '' : String(config[apiUrlKey ?? 'apiUrl'])
    };

    return this.client.request<T>(requestConfig).then(({ data }) => data);
  }

  public async simpleRequest({ method, url, data, responseType }: SimpleRequestObject) {
    const client = axios.create();
    const requestConfig = {
      method,
      url,
      data,
      responseType
    };
    return client.request(requestConfig).then(({ data }) => data);
  }

  public async refreshToken(): Promise<AuthStateInterface> {
    const authorizationInfo: AuthStateInterface = selectAuthInfo(store.getState());
    const { authUrl }: ConfigurationStateInterface = selectConfiguration(store.getState());
    const { authToken, refreshToken } = authorizationInfo;
    const refreshedAuthResponse = await fetch(`${authUrl}/${REFRESH_URL}`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${String(authToken)}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ refreshToken })
    });

    if (!refreshedAuthResponse.ok) {
      LocationService.login();
      return authorizationInfo;
    }

    const refreshedAuth = await refreshedAuthResponse.json();
    const {
      data: {
        idToken: {
          jwtToken: newAccessToken,
          payload: { exp: newExpiration }
        },
        refreshToken: { token: newRefreshToken }
      }
    } = refreshedAuth;

    const { userId, email } = decodeToken(newAccessToken);

    return {
      authToken: newAccessToken,
      refreshToken: newRefreshToken,
      expiration: new Date(newExpiration * 1000),
      userId,
      email
    };
  }

  abortPendingRequests() {
    this.abortController.abort();
    this.abortController = new AbortController();
  }

  authorizeRequest(requestConfig: AxiosRequestConfig): AxiosRequestConfig {
    const authorizationInfo: AuthStateInterface = selectAuthInfo(store.getState());
    const { authToken } = authorizationInfo;

    if (!authToken) {
      LocationService.login();
    }

    requestConfig.headers = { ...requestConfig.headers, Authorization: `Bearer ${String(authToken)}` };
    return requestConfig;
  }

  private async addXbsHeaders(requestConfig: AxiosRequestConfig): Promise<AxiosRequestConfig> {
    const session = await PlatformService.getIdentitySession();
    requestConfig.headers = {
      ...requestConfig.headers,
      XbsProductUuid: Provision.Uuid
    };

    if (session.containerUuid) {
      requestConfig.headers.XbsContainerUuid = session.containerUuid;
    }

    if (session.roleUuid) {
      requestConfig.headers.XbsRoleUuid = session.roleUuid;
    }

    if (session.userUuid) {
      requestConfig.headers.XbsUserUuid = session.userUuid;
    }

    return requestConfig;
  }

  private getProxiedRequest(requestConfig: AxiosRequestConfig): AxiosRequestConfig {
    const { authUrl, proxyUrl }: ConfigurationStateInterface = selectConfiguration(store.getState());
    requestConfig.baseURL = requestConfig.baseURL ?? '';
    const isHttps = requestConfig.baseURL.startsWith('https://');
    const isAuthStack = requestConfig.baseURL.includes(authUrl);
    const isS3Call = requestConfig.baseURL.includes('s3.amazonaws.com');
    const shouldProxyRequest = isHttps && !isAuthStack && !isS3Call;
    if (shouldProxyRequest) {
      const params = new URLSearchParams(requestConfig.params || {}).toString();
      const url = requestConfig.url ? `${requestConfig.baseURL}${requestConfig.url}` : requestConfig.baseURL;
      const urlWithParams = params ? `${url}?${params}` : url;
      requestConfig.url = `${proxyUrl}/v1/http?redirectUrl=${encodeURIComponent(urlWithParams)}`;
    }

    return requestConfig;
  }

  private handleErrorResponse(error: AxiosError): void {
    if (error.message === CANCELED_HTTP_ERROR_MESSAGE) throw error; // This is for requests that we actively canceled

    const { response: errorResponse } = error;

    if (errorResponse?.status === 401) {
      LocationService.login();
    }

    const message = errorResponse?.data?.errors?.message ?? 'An error has been encountered. Please refresh the page.';

    store.dispatch(
      enqueueNotification({
        message,
        options: {
          variant: 'error'
        }
      })
    );

    throw error;
  }
}

export default HTTPService;
