import { createReducer } from '@reduxjs/toolkit';

import { eulaOnSendingRequest, eulaOnFailed, eulaOnReceived } from './eula.actions';
import { eulaInitialState } from './eula.initialState';

export const eulaReducer = createReducer(eulaInitialState, (builder) => {
  builder
    .addCase(eulaOnSendingRequest, (state) => {
      return { ...state, isLoading: true, error: null };
    })
    .addCase(eulaOnReceived, (state, action) => {
      return { ...state, accepted: action.payload, isLoading: false, error: null, isLoaded: true };
    })
    .addCase(eulaOnFailed, (state, action) => {
      return { ...state, isLoading: false, error: action.payload };
    });
});
