import { makeStyles, Theme, useTheme } from '@material-ui/core';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const useStyles = makeStyles(() => ({
  slider: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const createRcSliderStyles = (theme: Theme) => ({
  handle: {
    height: 18,
    width: 18,
    backgroundColor: theme.palette.primary.main,
    marginTop: -1,
    borderRadius: 10,
    border: `1px solid ${theme.palette.divider}`
  },
  rail: {
    backgroundColor: theme.palette.secondary.light,
    height: 12,
    borderRadius: 6
  },
  trackPositive: {
    height: 12,
    backgroundColor: '#E48C87',
    borderRadius: 5
  },
  trackNegative: {
    height: 12,
    backgroundColor: theme.palette.action.focus,
    borderRadius: 5
  }
});

interface Props {
  value: number;
  startPoint: number;
  step: number;
  min: number;
  max: number;
  onChange: (value: number) => void;
}

const CustomSlider = (props: Props) => {
  const muiTheme = useTheme();
  const styles = createRcSliderStyles(muiTheme);
  const classes = useStyles();

  return (
    <Slider
      className={classes.slider}
      handleStyle={styles.handle}
      railStyle={styles.rail}
      trackStyle={props.value > 0 ? styles.trackPositive : styles.trackNegative}
      {...props}
    />
  );
};

export default CustomSlider;
