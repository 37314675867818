import { Dispatch, SetStateAction } from 'react';

import EditEntityJurisdiction from './EditEntityJurisdiction';
import EditEntityJurisdictionConnector from './EditEntityJurisdictionConnector';

import { EntityWithRates } from '../../../../models';

interface Props {
  entity: EntityWithRates;
  setIsEditingExpandedEntity: Dispatch<SetStateAction<boolean>>;
}

const ConnectedComponent = ({ entity, setIsEditingExpandedEntity }: Props) => (
  <EditEntityJurisdictionConnector
    entity={entity}
    setIsEditingExpandedEntity={setIsEditingExpandedEntity}
    component={EditEntityJurisdiction}
  />
);

export default ConnectedComponent;
