import { useState, useMemo, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ContentAndTitle, TabTitle } from '../';
import { TableWithComment } from '../../..';
import { FEDERAL_UUID, LEVELS } from '../../../../constants';
import { useCompletionStatus, useCurrencies, useFinancialData } from '../../../../hooks';
import { StateTabProps, Step } from '../../../../models';
import { setEntityCompletionStatus } from '../../../../redux/entitiesCompletionStatus';
import LoadingWrapper from '../../../LoadingWrapper';
import { Row } from '../../../Table/Table.proptype';
import {
  EntityNumberRouteMatch,
  getRowNamesFromLevelSteps,
  getTableDataFromFinancialData,
  handleEditRowForEntityDetails,
  handleNewRowForEntityDetails,
  handleOnCellOrCommentBlurForEntityDetails,
  handleOnRowDeleteForEntityDetails
} from '../../utils';
import SubJurisdictionsDropdown from '../SubJurisdictionsDropdown';

type TaxEffectedShape = {
  amount?: number;
  comments?: string | null;
  note?: string;
};

export type TaxEffectedRow = Row & TaxEffectedShape;

const LEVEL = LEVELS.STATE;
const STEP: Step = 'tax-effected';
const LevelAndStep = `${LEVEL}.${STEP}`;

const useStyles = makeStyles((theme) => ({
  select: {
    marginLeft: theme.spacing(1.2)
  }
}));

const StateTaxEffected = ({ states, entityId }: StateTabProps) => {
  const { t } = useTranslation();
  const [rows, setRows] = useState<TaxEffectedRow[]>([]);
  const [hasNewRow, setHasNewRow] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { currencyByEntityIdMap } = useCurrencies();
  const {
    prov3322EditPerformanceFix: isPerformanceFixEnabled,
    prov3736ReservedWordValidation: showReservedWordsError,
    prov4011TotalRowFix
  } = useFlags();
  const currencyIsoCode = currencyByEntityIdMap[entityId]?.isoCode;
  const {
    params: { entityNumber }
  } = useRouteMatch<EntityNumberRouteMatch>();
  const { stepCompletionStatus } = useCompletionStatus(entityNumber, LevelAndStep, FEDERAL_UUID);
  const { tabsData, failedCells, isFetchLoading } = useFinancialData(entityNumber, LEVEL, [STEP, 'deferred']);
  const taxEffectedFinancials = useMemo(() => tabsData[LevelAndStep] ?? [], [tabsData]);
  const rtpFinancialInfo = useMemo(() => tabsData['state.rtp'] ?? [], [tabsData]);
  const mergedFinancialInfo = useMemo(() => [...taxEffectedFinancials, ...rtpFinancialInfo], [
    taxEffectedFinancials,
    rtpFinancialInfo
  ]);

  const rowNames = useMemo(() => getRowNamesFromLevelSteps(tabsData, LEVEL, [STEP])[STEP], [tabsData]);
  const rowsWithData = useMemo(() => getTableDataFromFinancialData(rowNames, taxEffectedFinancials), [
    taxEffectedFinancials,
    rowNames
  ]) as TaxEffectedRow[];
  const newRow = rows[rows.length - 1];
  const rowsToSet = useMemo(() => [...rowsWithData, ...(newRow?.isNew ? [newRow] : [])], [newRow, rowsWithData]);

  useEffect(() => {
    setRows(rowsToSet);
  }, [rowsToSet]);

  const columns = [
    {
      field: 'name',
      placeholder: t('Adjustment Name'),
      headerName: t('Adjustment Name'),
      isNewRowEditable: true,
      width: '30%',
      sortable: true
    },
    {
      field: 'state',
      placeholder: t('state'),
      headerName: t('state'),
      isEditable: !stepCompletionStatus.status,
      sortable: true,
      getValue: (row: Row) => states.find((_state) => _state.id === row.state?.[0])?.name ?? '',
      isNewRowEditable: true,
      width: '18%',
      isActions: prov4011TotalRowFix,
      renderCell: (row: any) => {
        const shouldApplyEditableStyles = (!stepCompletionStatus.status && !hasNewRow) || row.isNew;
        const saveHandler = row.isNew ? handleEditRowForEntityDetails : handleOnCellOrCommentBlurForEntityDetails;
        const isCellFailed = Boolean(failedCells[row.name]?.state);

        return Object.keys(row)?.length ? (
          <SubJurisdictionsDropdown
            isCellFailed={isCellFailed}
            label={t('State')}
            value={row?.state || ''}
            subJurisdictions={states}
            disabled={stepCompletionStatus.status}
            className={classNames({ [classes.select]: shouldApplyEditableStyles })}
            onChange={(event: any) => {
              saveHandler({
                ...dataForMethods,
                column: { field: 'state' },
                value: event.target.value,
                row
              });
            }}
          />
        ) : (
          ''
        );
      }
    },
    {
      field: 'amount',
      headerName: t('Amount'),
      isEditable: !stepCompletionStatus.status,
      isNumber: true,
      width: '20%',
      isNewRowEditable: true
    }
  ];

  const dataForMethods = {
    columns,
    dispatch,
    entityId,
    financialInfo: mergedFinancialInfo,
    hasNewRow,
    level: LEVEL,
    rows,
    setHasNewRow,
    setRows,
    subjurisdictions: states,
    step: STEP,
    t,
    showReservedWordsError
  };

  return (
    <LoadingWrapper isLoading={isFetchLoading}>
      <ContentAndTitle
        title={
          <TabTitle
            currencyIsoCode={currencyIsoCode}
            title={t('Tax-Effected Adjustments')}
            isCompleted={stepCompletionStatus.status}
            onCompletionChange={(checked) => {
              dispatch(
                setEntityCompletionStatus({
                  ...stepCompletionStatus,
                  newStatus: checked
                })
              );
            }}
          />
        }
      >
        <TableWithComment
          columns={columns}
          failedCells={failedCells}
          newRowButtonLabel={
            stepCompletionStatus.status
              ? null
              : t(hasNewRow ? 'Save New Tax-Effected Adjustment' : 'Add New Tax-Effected Adjustment')
          }
          rows={rows}
          totalHeaderName={t('Total')}
          hideActionsMenu={stepCompletionStatus.status}
          handleOnRowDelete={(params) => {
            handleOnRowDeleteForEntityDetails({
              ...dataForMethods,
              ...params
            });
          }}
          onCellChange={(params) => {
            if (!isPerformanceFixEnabled) {
              handleEditRowForEntityDetails({
                ...dataForMethods,
                ...params
              });
            }
          }}
          onCellOrCommentBlur={(params) => {
            if (isPerformanceFixEnabled) {
              handleEditRowForEntityDetails({
                ...dataForMethods,
                ...params
              });
            }

            handleOnCellOrCommentBlurForEntityDetails({
              ...dataForMethods,
              ...params
            });
          }}
          onNewRowClick={() => {
            handleNewRowForEntityDetails({
              ...dataForMethods
            });
          }}
        />
      </ContentAndTitle>
    </LoadingWrapper>
  );
};

export default StateTaxEffected;
