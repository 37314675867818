import { createSelector } from '@reduxjs/toolkit';

import { CustomPeriodRates, FxRatesRecord } from '../models';
import { RootState } from '../redux';

export const selectRates = (state: RootState) => state.rates;

export const selectTaxRates = (state: RootState) => state.rates.tax;
export const selectExchangeRates = (state: RootState) => state.rates.exchange;

export const selectJurisdictionRates = createSelector(
  selectTaxRates,
  selectExchangeRates,
  (taxRates, exchangeRates) => {
    return {
      defaultTax: taxRates.defaultRates,
      customTax: taxRates.customRates,
      defaultExchange: exchangeRates.defaultRates,
      customExchange: exchangeRates.customRates
    };
  }
);

export const selectEntityRates = createSelector(selectTaxRates, selectExchangeRates, (taxRates, exchangeRates) => {
  return {
    customEntityTax: taxRates.customEntityRates,
    customEntityExchange: exchangeRates.customEntityRates
  };
});

export const selectCustomExchangeRates = (state: RootState) => state.rates.exchange.customRates;

export const selectDefaultExchangeRates = (state: RootState) => state.rates.exchange.defaultRates;

export const selectCurrentContainerFxRates = createSelector(
  selectCustomExchangeRates,
  selectDefaultExchangeRates,
  (customFxRates, defaultFxRates) =>
    // eslint-disable-next-line unicorn/no-array-reduce
    Object.entries(defaultFxRates).reduce(
      (acc: FxRatesRecord, [currencyId, defaultFxRate]: [string, CustomPeriodRates]) => ({
        ...acc,
        [currencyId]: {
          beginning: customFxRates[currencyId]?.beginning ?? defaultFxRate.beginning ?? 1,
          current: customFxRates[currencyId]?.current ?? defaultFxRate.current ?? 1,
          end: customFxRates[currencyId]?.end ?? defaultFxRate.end ?? 1
        }
      }),
      {}
    )
);
