import { memo, useMemo } from 'react';

import TableCell from '@mui/material/TableCell';

import { CategoryFromApi, Container } from '../../models';
import { pickCategoryForRow, categorizableEntityDetailsCell } from '../../utils/table';
import { EditableCell } from '../Table/components/TableBody';
import { Column, Row, TableProps } from '../Table/Table.proptype';
import { getAlignment, getClassName, renderValue, getCell } from '../Table/utils';

interface RenderCellProps {
  row: Row;
  renderOpts?: any;
  column: Column;
  categoriesByAccountKey: Record<any, CategoryFromApi[]>;
  currentContainer?: Container;
  value: string | number;
  failedCells: TableProps['failedCells'];
  onCellOrCommentBlur: TableProps['onCellOrCommentBlur'];
  onCellClick: any;
}

const renderCell = (props: RenderCellProps) => {
  const {
    row,
    renderOpts,
    value,
    column,
    failedCells,
    categoriesByAccountKey,
    onCellOrCommentBlur,
    currentContainer,
    onCellClick
  } = props;

  if (!failedCells || !column.field) {
    return;
  }

  if (
    column.field === 'name' &&
    row.name &&
    Object.keys(categoriesByAccountKey).length > 0 &&
    !row.isNew &&
    row.categorizable !== false
  ) {
    row.category = pickCategoryForRow(row, categoriesByAccountKey);
    return categorizableEntityDetailsCell(row, column, currentContainer);
  }

  if (row.renderCell) {
    return row.renderCell(row, value, column, failedCells, undefined, onCellOrCommentBlur, onCellClick, renderOpts);
  }

  if (
    (column.isEditable || (column.isNewRowEditable && row.isNew) || (row.isEditable && column.field !== 'name')) &&
    !row.nonEditableFields?.includes(column.field) &&
    Boolean(onCellOrCommentBlur) &&
    !row.isTotal
  ) {
    return (
      <EditableCell
        column={column}
        row={row}
        failedCells={failedCells}
        renderOpts={renderOpts}
        onCellOrCommentBlur={onCellOrCommentBlur}
      />
    );
  }

  return renderValue(value, column, renderOpts, row);
};

interface RowContentProps {
  index: number;
  row: Row;
  renderOpts: any;
  columns: Column[];
  categoriesByAccountKey: Record<any, CategoryFromApi[]>;
  // eslint-disable-next-line react/require-default-props
  currentContainer?: Container;
  classes: Record<string, string>;
  failedCells: TableProps['failedCells'];
  onCellOrCommentBlur: TableProps['onCellOrCommentBlur'];
}

const RowContent = memo((props: RowContentProps) => {
  const {
    index,
    row,
    renderOpts,
    columns,
    classes,
    failedCells,
    categoriesByAccountKey,
    onCellOrCommentBlur,
    currentContainer
  } = props;

  const cells = useMemo(
    () =>
      columns.map((column) => {
        if (!column.field) {
          return null;
        }

        if (column.isActions && column.renderCell) {
          return (
            <TableCell
              key={`${index}-${column.field}`}
              align={getAlignment(column)}
              className={getClassName({ column, row, classes, isActiveRow: false })}
              width={column.width}
              style={column.getCellStyle ? column.getCellStyle(row, column) : {}}
            >
              {column.renderCell(row)}
            </TableCell>
          );
        }

        const field = String(column.field);
        const fieldMetaData = row?.columnMeta?.[field];
        const { formula, entityId, jurisdictionId } = fieldMetaData || {};
        const value = getCell(row, column);

        return (
          <TableCell
            key={`${index}-${column.field}`}
            align={getAlignment(column)}
            className={getClassName({ column, row, classes, isActiveRow: false })}
            width={column.width}
            style={column.getCellStyle ? column.getCellStyle(row, column) : {}}
            data-meta-field={field}
            data-meta-formula={formula}
            data-meta-rowname={row?.name}
            data-meta-entityid={entityId}
            data-meta-jurisdictionid={jurisdictionId}
            data-meta-creditname={row?.creditName}
          >
            {column.shouldShowRtpIcon && column.renderCell
              ? column.renderCell(
                  row,
                  value,
                  column,
                  failedCells,
                  undefined,
                  onCellOrCommentBlur,
                  undefined,
                  renderOpts,
                  []
                )
              : !(column.isActions && row.isTotal) &&
                renderCell({
                  row,
                  renderOpts,
                  value,
                  column,
                  failedCells,
                  onCellOrCommentBlur,
                  categoriesByAccountKey,
                  onCellClick: undefined,
                  currentContainer
                })}
          </TableCell>
        );
      }),
    [
      classes,
      columns,
      failedCells,
      index,
      onCellOrCommentBlur,
      row,
      categoriesByAccountKey,
      renderOpts,
      currentContainer
    ]
  );

  if (row.sectionHeader) {
    return (
      <TableCell colSpan={columns.length} className={classes.sectionHeader}>
        {row.sectionHeader}
      </TableCell>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{cells}</>;
});

export default RowContent;
