import { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowRight from '@material-ui/icons/ArrowRight';

export const useDropdownIcon = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const Button = () => (
    <div style={{ transform: 'scale(0.8)' }}>
      <IconButton
        aria-label="expand row"
        size="small"
        onClick={() => {
          setIsOpen((isOpen) => !isOpen);
        }}
      >
        {isOpen ? <ArrowDropDown /> : <ArrowRight />}
      </IconButton>
    </div>
  );

  return {
    Button,
    isOpen,
    setIsOpen
  };
};
