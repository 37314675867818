import { ComponentType, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useContainers } from '../../hooks';
import { AddNewPeriodModalProps, RollforwardApiPayload } from '../../models';
import { containersOnReceived } from '../../redux/containers';
import { enqueueNotification } from '../../redux/notifications';
import { toggleIsRfModalOpen } from '../../redux/ui';
import { selectRollForwardModalIsOpen } from '../../selectors';
import ChronService from '../../services/chron';
import HTTPService from '../../services/http';

const AddNewPeriodModalConnector = ({
  component: AddNewPeriodModal
}: {
  component: ComponentType<AddNewPeriodModalProps>;
}) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectRollForwardModalIsOpen);
  const { containers, currentContainer, fetchContainers } = useContainers();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleClose = () => {
    dispatch(toggleIsRfModalOpen(false));
  };

  const checkRolledStatus = async (name: string, taxYear: number, isCopy: boolean, retriesLeft = 10) => {
    if (currentContainer && retriesLeft > 0) {
      ChronService.addTimeout(
        'checkRolledStatus',
        async () => {
          const { data: containers } = await fetchContainers();
          const rolledContainer = containers.find((cont) => cont.name === name && cont.taxYear === taxYear);
          if (rolledContainer) {
            dispatch(containersOnReceived(containers));
            dispatch(
              enqueueNotification({
                message: `"${name} - ${taxYear}" was ${isCopy ? 'copied' : 'rolled'} and is ready to be used`,
                options: {
                  variant: 'success'
                }
              })
            );
          } else {
            void checkRolledStatus(name, taxYear, isCopy, retriesLeft - 1);
          }
        },
        3000
      );
    }
  };

  const handleRollClicked = async (rollForwardPayload: RollforwardApiPayload) => {
    try {
      setIsButtonDisabled(true);
      await HTTPService.request({
        method: 'post',
        apiUrlKey: 'principleUrl',
        relativePath: `/v1/container/roll/${rollForwardPayload.existingContainerId}`,
        data: rollForwardPayload
      });
      setIsButtonDisabled(false);
      dispatch(
        enqueueNotification({
          message: `${rollForwardPayload.newIsCopy ? 'Copying' : 'Rolling'} process started for "${
            rollForwardPayload.newName
          } - ${rollForwardPayload.newTaxYear}"`,
          options: {
            variant: 'success'
          }
        })
      );
      handleClose();
      void checkRolledStatus(rollForwardPayload.newName, rollForwardPayload.newTaxYear, rollForwardPayload.newIsCopy);
    } catch {
      setIsButtonDisabled(false);
    }
  };

  return isOpen ? (
    <AddNewPeriodModal
      isCreateDisabled={isButtonDisabled}
      containers={containers}
      onCreate={handleRollClicked}
      onClose={handleClose}
    />
  ) : null;
};

export default AddNewPeriodModalConnector;
