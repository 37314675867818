import { TFunction } from 'react-i18next';

import { Grid, makeStyles, Typography } from '@material-ui/core';

import { Container } from '../../../models';
import { formatDateShort } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  periodEnding: {
    color: theme.palette.text.secondary,
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  endDate: {
    color: theme.palette.primary.main
  }
}));

interface Props {
  currentContainer: Container;
  t: TFunction;
}

const PeriodEndingItem = ({ currentContainer, t }: Props) => {
  const classes = useStyles();

  return (
    <Grid item>
      <Typography variant="caption">
        <span className={classes.periodEnding}>{t('Period Ending')}</span>
        <span className={classes.endDate}>{formatDateShort(currentContainer.endDate)}</span>
      </Typography>
    </Grid>
  );
};

export default PeriodEndingItem;
