import { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { CategoryForUpdate, CategoryFromApi, CategoryAccountPair, UpdatedCategoryFromApi } from '../../models';
import {
  categoryUpdateRequest,
  categoryUpdateSuccess,
  categoryUpdateFailure,
  categoryAccountsUpdateRequest,
  categoryAccountsUpdateSuccess,
  categoryAccountsUpdateFailure
} from '../../redux/categories';
import HTTPService, { LambdaResponse } from '../../services/http';

export const useCategoryUpdate = () => {
  const dispatch = useDispatch();

  const updateCategory = useCallback(
    async (categoryId: string, category: CategoryForUpdate) => {
      dispatch(categoryUpdateRequest());

      try {
        const { data } = await HTTPService.request<LambdaResponse<UpdatedCategoryFromApi>>({
          method: 'patch',
          relativePath: `/v1/categories/${categoryId}`,
          data: category
        });

        dispatch(categoryUpdateSuccess(data));
      } catch (error: unknown) {
        dispatch(categoryUpdateFailure(error as any));
        throw error;
      }
    },
    [dispatch]
  );

  const updateCategoryAccounts = useCallback(
    async (categoryId: string, action: 'ADD' | 'DELETE', categoryAccountPairs: CategoryAccountPair[]) => {
      dispatch(categoryAccountsUpdateRequest({ categoryId, action, categoryAccountPairs }));

      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { data } = await HTTPService.request<LambdaResponse<CategoryFromApi>>({
          method: 'patch',
          relativePath: `/v1/categories/${categoryId}/accounts`,
          data: { action, categoryAccountPairs }
        });

        dispatch(categoryAccountsUpdateSuccess({ categoryId, action, categoryAccountPairs }));
      } catch (error: unknown) {
        dispatch(categoryAccountsUpdateFailure(error as any));
        throw error;
      }
    },
    [dispatch]
  );

  return { updateCategory, updateCategoryAccounts };
};
