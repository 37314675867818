import { createAction, Dispatch } from '@reduxjs/toolkit';

import { testForFailure } from './helpers/cellFailureStrategies';
import getFormattedFinancialInfoValue from './helpers/getFormattedFinancialInfoValue';

import { RootState } from '..';
import { NULL_UUID } from '../../constants';
import {
  Entity,
  FinancialDataOnDeleted,
  FinancialDataOnFailed,
  FinancialDataOnReceived,
  FinancialDataOnUpdatedFailedCells,
  FinancialInfo,
  FinancialInfoResponseForPatch,
  Level,
  Step,
  FinancialInfoForCreate,
  WithOptional,
  FinancialDataOnUpdated,
  FinancialDataOnPosted
} from '../../models';
import HTTPService, { LambdaResponse } from '../../services/http';
import { addNullAccountIdToRowsWhichDoNotHaveAccountId } from '../../utils';

export const financialDataInitState = createAction('financialData/initState');
export const financialDataInitFailedCells = createAction('financialData/initFailedCells');
export const financialDataOnUploadSendingRequest = createAction('financialData/onUploadSendingRequest');
export const financialDataOnInitialSendingRequest = createAction('financialData/onInitialSendingRequest');
export const financialDataOnReceived = createAction<FinancialDataOnReceived>('financialData/onReceived');
export const financialDataOnPosted = createAction<FinancialDataOnPosted>('financialData/onPosted');
export const financialDataOnUpdated = createAction<FinancialDataOnUpdated>('financialData/onUpdated');
export const financialDataOnDeleted = createAction<FinancialDataOnDeleted>('financialData/onDeleted');
export const financialDataOnUpdatedFailedCells = createAction<FinancialDataOnUpdatedFailedCells>(
  'financialData/OnUpdatedFailedCells'
);
export const financialDataOnFailed = createAction<FinancialDataOnFailed>('financialData/onFailed');
export const financialDataOnSwitchingEntity = financialDataInitState;
export const financialDataUpdateFinancialData = createAction('financialData/updateFinancialData');
export const financialDataCreateFinancialData = createAction('financialData/createFinancialData');
export const financialDataDeleteFinancialData = createAction('financialData/deleteFinancialData');
export const financialDataDeleteStateRTPTaxProvisionCol = createAction('financialData/deleteStateRTPTaxProvisionCol');

export const updateFinancialData = (updatedData: FinancialInfo, entityId: Entity['entityId']) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(financialDataUpdateFinancialData());
    const financialDataState = getState().financialData;
    const levelAndStep = `${updatedData.level}.${updatedData.step}`;

    const financialDataTabsDataForLevelAndStep = financialDataState.tabsData[levelAndStep];

    const existingFinancialItem = financialDataTabsDataForLevelAndStep?.find(
      (item) =>
        item.rowName === updatedData.rowName &&
        item.columnName === updatedData.columnName &&
        (item.creditName === updatedData.creditName || !item.creditName) &&
        (item.jurisdictionId === updatedData.jurisdictionId || !item.jurisdictionId)
    );

    const { customError, shouldSave } = testForFailure(updatedData, entityId, getState());

    if (customError) {
      dispatch(
        financialDataOnUpdatedFailedCells({
          rowNames: [updatedData.rowName],
          columnNames: [updatedData.columnName],
          creditName: updatedData.creditName,
          didFail: true,
          failedReasons: [customError]
        })
      );
    }

    if (!shouldSave) return;
    const shouldKeepError = Boolean(customError);

    if (!existingFinancialItem) return dispatch<any>(createFinancialData([updatedData], entityId, { shouldKeepError }));

    const oldValue = existingFinancialItem?.value;
    const entityFinancialsTraceId = existingFinancialItem?.entityFinancialsTraceId;

    if (updatedData.value === (updatedData.columnName === 'note' ? oldValue : Number(oldValue))) return;

    dispatch(financialDataOnUploadSendingRequest());

    const value = getFormattedFinancialInfoValue(updatedData);
    try {
      const { data: financialInfo } = await HTTPService.request<LambdaResponse<FinancialInfoResponseForPatch>>({
        method: 'patch',
        relativePath: `/v1/entities/${entityId}/financial-value`,
        data: {
          financialInfo: {
            entityFinancialsTraceId,
            value
          }
        }
      });

      const result = {
        ...existingFinancialItem,
        ...financialInfo,
        shouldKeepError
      };

      dispatch(financialDataOnUpdated(result));
    } catch (error: unknown) {
      dispatch(
        financialDataOnFailed({
          error,
          rowNames: [updatedData.rowName],
          columnNames: [updatedData.columnName],
          jurisdictionId: updatedData.jurisdictionId,
          creditName: updatedData.creditName
        })
      );
    }
  };
};

export const createFinancialData = (
  updatedData: FinancialInfoForCreate[],
  entityId: Entity['id'],
  options?: {
    onDone?: () => void;
    shouldKeepError?: boolean;
  }
) => {
  return async (dispatch: Dispatch) => {
    dispatch(financialDataCreateFinancialData());
    dispatch(financialDataOnUploadSendingRequest());
    let rowName = '';

    const financialInfoDataWithoutUndefinedValues = updatedData.filter((item) => item.value !== undefined);
    const financialInfo = financialInfoDataWithoutUndefinedValues.map((item) => {
      item = addNullAccountIdToRowsWhichDoNotHaveAccountId(item);
      const { accountId, ...rest } = item;
      const isAccountIdValid = accountId !== NULL_UUID;
      const formattedValue = getFormattedFinancialInfoValue(item);

      const obj: WithOptional<FinancialInfoForCreate, 'rowName'> = {
        ...rest,
        accountId,
        value: formattedValue
      };

      if (isAccountIdValid) {
        rowName = item.rowName;
        delete obj.rowName;
      }

      if (!item.creditName) {
        delete obj.creditName;
      }

      return obj;
    });

    try {
      const { data } = await HTTPService.request<LambdaResponse<FinancialInfo[]>>({
        method: 'post',
        relativePath: `/v1/entities/${entityId}/financial-value`,
        data: {
          financialInfo
        }
      });

      const result = data.map((item) => ({ ...item, rowName: item.rowName || rowName }));

      options?.onDone?.();
      dispatch(financialDataOnPosted({ shouldKeepError: options?.shouldKeepError, financialInfo: result }));
    } catch (error: unknown) {
      dispatch(
        financialDataOnFailed({
          error,
          rowNames: financialInfoDataWithoutUndefinedValues.map((item) => item.rowName),
          columnNames: financialInfoDataWithoutUndefinedValues.map((item) => item.columnName),
          jurisdictionId: financialInfoDataWithoutUndefinedValues[0].jurisdictionId,
          creditName: financialInfoDataWithoutUndefinedValues[0].creditName
        })
      );
    }
  };
};

export const deleteFinancialData = (
  entityId: Entity['id'],
  rowsForDeletion: Array<{
    level: Level;
    rowName: string;
    rowId: string;
    step: Step;
  }>
) => {
  return async (dispatch: Dispatch) => {
    dispatch(financialDataDeleteFinancialData());
    dispatch(financialDataOnUploadSendingRequest());

    const rowIdsForDeletion = rowsForDeletion.map((row) => row.rowId);

    try {
      await HTTPService.request({
        method: 'post',
        relativePath: `/v1/entities/${entityId}/financial-value/delete-batch`,
        data: { rowIds: rowIdsForDeletion }
      });

      dispatch(financialDataOnDeleted(rowsForDeletion));
    } catch (error: unknown) {
      dispatch(financialDataOnFailed({ error }));
    }
  };
};

export const deleteStateRTPTaxProvisionCol = (entityId: Entity['id']) => {
  return async (dispatch: Dispatch) => {
    const stepColNameRefId = '85d004d0-162b-11ec-b521-0800200c9a66';
    const attributeNames = [
      'Apportionment Factor',
      'Prior Year State Tax Rate',
      'Federal Taxable Income/(Loss) Before State Tax and NOL'
    ];

    try {
      await HTTPService.request({
        method: 'delete',
        relativePath: `/v1/entities/${entityId}/financial-value`,
        data: { stepColNameRefId, attributeNames }
      });

      dispatch(financialDataDeleteStateRTPTaxProvisionCol());
    } catch (error: unknown) {
      dispatch(financialDataOnFailed({ error }));
    }
  };
};
