import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableRow-root > .MuiTableCell-body:not(:last-child)': {
      borderRight: 'none'
    }
  },
  confirmationButton: {
    backgroundColor: 'inherit !important',
    transition: 'none',
    paddingTop: 0,
    paddingBottom: 0,
    width: '100%',
    '&:hover': {
      transition: 'none'
    }
  },
  drawerContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '97%'
  },
  drawerHeader: {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    display: 'flex',
    paddingBottom: theme.spacing(0.2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(0.2),
    position: 'absolute',
    textTransform: 'uppercase',
    top: 0,
    width: '100%'
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center',
    height: '0px',
    borderRadius: '4px',
    paddingTop: '20px',
    paddingBottom: '20px',
    marginTop: '-20px',
    marginBottom: '-20px',
    width: '100%',
    '& input': {
      fontFamily: theme.typography.h3.fontFamily,
      fontWeight: theme.typography.h3.fontWeight,
      minWidth: '340px',
      borderRadius: '4px',
      paddingTop: '12px',
      paddingBottom: '12px',
      marginTop: '-12px',
      marginBottom: '-12px'
    },
    '&:hover input:not(:focus)': {
      backgroundColor: theme.palette.secondary.lighter,
      borderRadius: '4px',
      paddingTop: '12px',
      paddingBottom: '12px',
      marginTop: '-12px',
      marginBottom: '-12px',
      paddingLeft: '15px',
      marginLeft: '-15px',
      paddingRight: '15px',
      marginRight: '-15px'
    }
  },
  idCell: {
    display: 'flex',
    alignItems: 'center',
    height: '0px',
    borderRadius: '4px',
    paddingTop: '20px',
    paddingBottom: '20px',
    marginTop: '-20px',
    marginBottom: '-20px',
    width: '100%',
    '& input': {
      fontFamily: theme.typography.h3.fontFamily,
      fontWeight: theme.typography.h3.fontWeight,
      borderRadius: '4px',
      paddingTop: '12px',
      paddingBottom: '12px',
      marginTop: '-12px',
      marginBottom: '-12px'
    },
    '&:hover input:not(:focus)': {
      backgroundColor: theme.palette.secondary.lighter,
      borderRadius: '4px',
      paddingTop: '12px',
      paddingBottom: '12px',
      marginTop: '-12px',
      marginBottom: '-12px',
      paddingLeft: '15px',
      marginLeft: '-15px',
      paddingRight: '48px',
      marginRight: '-48px'
    }
  },
  inputError: {
    border: `2px solid ${theme.palette.error.main}`,
    maxWidth: '370px'
  }
}));
