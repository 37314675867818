import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.common.white,
    boxSizing: 'border-box',
    height: '100px',
    width: '357px',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px'
  },
  containerSelected: {
    boxShadow: '0 0 8px 0 rgba(130,130,130,0.5)',
    borderTop: `6px solid ${theme.palette.action.focus}`
  },
  floatingContainer: {
    padding: '20px',
    paddingLeft: '24px',
    position: 'relative'
  },
  floatingContainerSelected: {
    transform: 'translateY(-5px)'
  },
  title: {
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    letterSpacing: '0.25px',
    lineHeight: '18px',
    paddingBottom: '13px'
  },
  value: {
    marginTop: '15px',
    fontSize: '20px',
    letterSpacing: '0.15px',
    lineHeight: '26px'
  },
  jurisdictionFirstBox: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.primary.main,
    borderRadius: '4px',
    fontSize: theme.typography.caption.fontSize,
    letterSpacing: theme.typography.caption.letterSpacing,
    lineHeight: '14px',
    height: '30px',
    width: '107px',
    marginRight: theme.spacing(1),
    paddingTop: '7px',
    paddingBottom: '9px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  jurisdictionSecondBox: {
    backgroundColor: (theme.palette.background as any).comparison,
    color: theme.palette.text.primary,
    borderRadius: '4px',
    fontSize: theme.typography.caption.fontSize,
    letterSpacing: theme.typography.caption.letterSpacing,
    lineHeight: '14px',
    height: '30px',
    width: '125px',
    marginRight: theme.spacing(1),
    paddingTop: '7px',
    paddingBottom: '9px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  entityStatusFirstBox: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.primary.main,
    borderRadius: '4px',
    fontSize: '14px',
    letterSpacing: '0.6px',
    lineHeight: '14px',
    height: '30px',
    width: '30px',
    marginRight: '10px',
    paddingTop: '3px',
    paddingBottom: '3px',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  entityStatusSecondBox: {
    backgroundColor: theme.palette?.progress?.lighter,
    color: '#82703A',
    borderRadius: '4px',
    fontSize: '14px',
    letterSpacing: '0.6px',
    lineHeight: '14px',
    height: '30px',
    width: '30px',
    marginRight: '10px',
    padding: `3px ${theme.spacing(1)}`,
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  completeText: {
    color: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily
  },
  inProgressText: {
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily
  }
}));
