import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import GridOn from '@material-ui/icons/GridOn';

import { dataTab } from '../FileUpload';
import { useStyles } from '../FileUpload.styles';

const UploadFile = ({ getRootProps, getInputProps, open }: any) => {
  const classes = useStyles({ activeTab: dataTab });
  const { t } = useTranslation();

  return (
    <Box>
      <Typography className={classes.uploadHeader}>{t('upload-file')}</Typography>
      <Box className={classes.uploadBox}>
        <Box className="container">
          <Box {...getRootProps()} className={classes.dragDropBox} aria-label="drag drop box">
            <input {...getInputProps()} />
            <GridOn className={classes.gridOn} />
          </Box>
          <Typography className={classes.dragDropText}>{t('drag-and-drop-file')}</Typography>
          <Typography className={classes.fileTypeText}>XLSX, XLS, XLSB, CSV</Typography>
          <Button className={classes.browseButton} onClick={open}>
            {t('or-browse-computer')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default UploadFile;
