import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import makeStyles from '@material-ui/styles/makeStyles';

import { PreTaxBookIncomeSectionProps } from '../../../../models/preTaxBookIncome.interface';
import TableWithComment from '../../../TableWithComment';
import { getTableDataFromFinancialData } from '../../utils';

const useStyles = makeStyles(() => ({
  fullYearProjectionTable: {
    height: 'auto !important'
  }
}));

const FullYearProjection = ({
  failedCells,
  stepCompletionStatus,
  handleOnCellOrCommentBlur,
  tabsData
}: PreTaxBookIncomeSectionProps) => {
  const { fullYearProjectionTable } = useStyles();
  const { t } = useTranslation();
  const financialInfo = useMemo(() => tabsData['federal.ptbi'] ?? [], [tabsData]);

  const columns = [
    {
      field: 'name',
      headerName: t('Full-Year Projection'),
      width: '30%'
    },
    {
      field: 'amount',
      headerName: t('Amount'),
      isEditable: !stepCompletionStatus.status,
      isNumber: true,
      width: '20%'
    }
  ];

  const rows = useMemo(
    () =>
      getTableDataFromFinancialData(
        [{ name: t('Pre-Tax Book Income / (Loss)') }, { name: t('Adjustments') }],
        financialInfo.filter((cell: { creditName: string }) => cell.creditName === 'fullYearProjection')
      ),
    [financialInfo, t]
  );

  return (
    <TableWithComment
      className={fullYearProjectionTable}
      columns={columns}
      failedCells={failedCells}
      rows={rows}
      totalHeaderName={t('Adjusted Projected Pre-Tax Book Income')}
      hideActionsMenu={stepCompletionStatus.status}
      onCellOrCommentBlur={handleOnCellOrCommentBlur}
    />
  );
};

export default FullYearProjection;
