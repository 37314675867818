import { ComponentType } from 'react';

import { useDispatch } from 'react-redux';

import { EulaProps } from './Eula.interface';

import { acceptEula } from '../../redux/eula';

const EulaConnector = ({ component: Eula }: { component: ComponentType<EulaProps> }) => {
  const dispatch = useDispatch();
  const onAccept = () => dispatch(acceptEula());

  return <Eula onAccept={onAccept} />;
};

export default EulaConnector;
