import { SvgIconProps } from '@material-ui/core';

// do not import from components root, Jest is entering infinite loop for utils.test.js
import CurrentIcon from './components/CurrentIcon';
import DeferredIcon from './components/DeferredIcon';
import EffectiveTaxRateIcon from './components/EffectiveTaxRateIcon';
import ReturnToProvisionIcon from './components/ReturnToProvisionIcon';
import SumIcon from './components/SumIcon';
import TARFIcon from './components/TARFIcon';
import { MULTI_TABLE_REPORTS } from './constants';

import { getFederalAmountForModification } from '../../calculations';
import { REPORT_STATUS } from '../../constants';
import { Jurisdiction, Report, ReportFragment, Column, Row } from '../../models';
import { ModelMeta } from '../../redux/reports';

export const worldTab = 'world-consolidated' as const;
export const totalTab = 'total' as const;
export const foreignTab = 'foreign' as const;
export const federalTab = 'federal' as const;
export const stateTab = 'state' as const;
export type Tab = typeof federalTab | typeof foreignTab | typeof stateTab | typeof totalTab;

const reportIconMap: Record<string, (props: SvgIconProps) => JSX.Element> = {
  sum: SumIcon,
  current: CurrentIcon,
  deferred: DeferredIcon,
  etr: EffectiveTaxRateIcon,
  rtp: ReturnToProvisionIcon,
  tarf: TARFIcon
};

export const useReportIcon = (reportType = 'sum') => reportIconMap[reportType] ?? reportIconMap.sum;

// TODO consider turning as a calculation
export function computeStateEffectiveTaxRate(
  subJurisdictionData: Array<Record<string, any>>,
  jurisdiction: Jurisdiction,
  federal?: any
) {
  const effectiveTaxRateBySubJurisdictionId = new Map();
  const taxRateBySubJurisdictionId = new Map();
  const taxEffectedAdjustmentsBySubJurisdictionId = new Map();
  const modificationsBySubJurisdictionId = new Map();

  for (const { taxEffected, id: stateId, credits, modifications } of subJurisdictionData) {
    let taxEffectedAdjustments = 0;
    let stateCredits = 0;
    if (Array.isArray(taxEffected)) {
      for (const adjustment of taxEffected) {
        taxEffectedAdjustments += adjustment.amount;
      }
    }

    if (Array.isArray(credits?.taxPeriods)) {
      for (const credit of credits.taxPeriods) {
        stateCredits += credit.usedAmount;
      }
    }

    if (Array.isArray(modifications) && federal) {
      for (const modification of modifications) {
        let row = modificationsBySubJurisdictionId.get(stateId);
        const { federalAmount } = getFederalAmountForModification(modification, federal);

        if (!row) {
          row = {
            ...modification,
            federalAmount
          };
          modificationsBySubJurisdictionId.set(stateId, row);
        }
      }
    }

    taxEffectedAdjustmentsBySubJurisdictionId.set(stateId, { taxEffectedAdjustments, stateCredits });
  }

  let totalSeparate = 0;
  let totalCombined = 0;
  let total = 0;
  for (const { id, taxRates, isCombined } of jurisdiction.subJurisdictions) {
    const effectiveTaxRate =
      (taxRates?.current ?? 0) * (subJurisdictionData.find((data: any) => data.id === id)?.apportionment?.amount || 0);
    effectiveTaxRateBySubJurisdictionId.set(id, effectiveTaxRate);
    taxRateBySubJurisdictionId.set(id, taxRates);
    total += effectiveTaxRate;
    if (isCombined) {
      totalCombined += effectiveTaxRate;
    } else {
      totalSeparate += effectiveTaxRate;
    }
  }

  return {
    total,
    totalCombined,
    totalSeparate,
    effectiveTaxRateBySubJurisdictionId,
    taxRateBySubJurisdictionId,
    taxEffectedAdjustmentsBySubJurisdictionId,
    modificationsBySubJurisdictionId
  };
}

export function isByGroupMultiReportsReport(report: Report) {
  const predicate = MULTI_TABLE_REPORTS.includes(report.name) && report.reportTypeName === 'multi-entity';
  return predicate;
}

export const isLineItemWithFinancialValues = (row: ReportFragment) =>
  row.type === 'lineItem' &&
  Object.entries(row).some(
    ([key, value]) => !['name', 'type', 'format', 'columnMeta'].includes(key) && ![null, 0].includes(value)
  );

export const filterReportColumnsByEntityId = (columns: Column[], entityId: string): Column[] => {
  const columnsForEntityId = columns.filter(({ field }: Column) => new RegExp(`${entityId}|name`).exec(String(field)));
  const uniqueColumnFields = [...new Set(columnsForEntityId.map(({ field }) => field))];
  const uniqueColumnsForEntityId: Column[] = [];

  uniqueColumnFields.forEach((columnField) => {
    const foundColumn = columnsForEntityId.find(({ field }) => field === columnField);
    if (foundColumn !== undefined) {
      uniqueColumnsForEntityId.push(foundColumn);
    }
  });
  return uniqueColumnsForEntityId;
};

export const padColumns = (columnsByEntity: Record<string, Column[]>) => {
  const maxNumberOfColumns = Math.max(...Object.values(columnsByEntity).map((columns) => columns.length));

  Object.entries(columnsByEntity).forEach(([entityId, columns]) => {
    const cols: Column[] = Array.from({ length: maxNumberOfColumns });

    for (let i = 0; i < maxNumberOfColumns; i++) {
      if (columns[i] === undefined) {
        cols[i] = {};
      } else {
        cols[i] = columns[i];
      }
    }

    columnsByEntity[entityId] = cols;
  });

  return columnsByEntity;
};

export const sortRowsByName = (rowA: Row, rowB: Row) => {
  if (rowA.name !== undefined && rowB.name !== undefined) {
    return rowA.name.toLowerCase() >= rowB.name.toLowerCase() ? 1 : -1;
  }

  return 0;
};

export const sortCategorizableCollection = (a: Row | Row[], b: Row | Row[]): number => {
  const aIsArray = Array.isArray(a);
  const bIsArray = Array.isArray(b);
  const aAsArray = a as Row[];
  const bAsArray = b as Row[];
  a = a as Row;
  b = b as Row;

  if (a.isTotal || b.isTotal) {
    return 0;
  }

  if (aIsArray && bIsArray) {
    return aAsArray[0].category!.name.toLowerCase() >= bAsArray[0].category!.name.toLowerCase() ? 1 : -1;
  }

  if (aIsArray) {
    return aAsArray[0].category!.name.toLowerCase() >= b.name!.toLowerCase() ? 1 : -1;
  }

  if (bIsArray) {
    return a.name!.toLowerCase() >= bAsArray[0].category!.name.toLowerCase() ? 1 : -1;
  }

  return sortRowsByName(a, b);
};

export const isSomeModelUpdating = (modelsStatus: ModelMeta[]): boolean => {
  return modelsStatus.some(({ status }) => status === REPORT_STATUS.inProgress);
};

export const getModelStatus = (
  modelsStatus: Record<string, ModelMeta>,
  reportSourceId: string,
  isCurrencyConversion: boolean
) => {
  const key = `${reportSourceId}_${String(isCurrencyConversion)}`;
  return modelsStatus[key]?.status;
};
