import { useTranslation } from 'react-i18next';

import { Box, Button, Divider, makeStyles, Paper } from '@material-ui/core';

import theme from '../../../../../../theme';
import LoadingWrapper from '../../../../../LoadingWrapper';

const useStyles = makeStyles(() => ({
  layoutSelector: {
    minWidth: '397px',
    backgroundColor: theme.palette.common.white,
    '& .MuiAutocomplete-listbox': {
      width: '100%',
      '& .MuiAutocomplete-option': {
        paddingBlock: '0',
        '&[aria-selected="true"]': {
          backgroundColor: theme.palette.secondary.main,
          borderLeft: `2px solid ${theme.palette.info.dark}`
        }
      }
    }
  },
  title: {
    height: '45px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    textAlign: 'left',
    padding: '0 16px',
    '& :first-child': {
      width: '20%'
    },
    '& :nth-child(2)': {
      width: '60%'
    },
    '& :last-child': {
      width: '10%',
      textAlign: 'center'
    }
  },
  selectorButtons: {
    color: theme.palette.info.dark,
    height: '62px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '8px',
    zIndex: 100,
    '& > button': {
      height: '36px'
    },
    '& > :last-child': {
      border: `solid 1px ${theme.palette.info.dark}`,
      color: theme.palette.primary.main,
      width: '123px',
      borderRadius: '4px',
      marginLeft: '6px'
    }
  }
}));

const SelectorLayout = ({ children, onClose, onSelect, isLoading = false, ...props }: any) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper tabIndex={0} {...props} className={classes.layoutSelector}>
      <LoadingWrapper isLoading={isLoading}>
        <>
          <Box className={classes.title}>
            <Box>{t('Account Number')}</Box>
            <Box>{t('Description')}</Box>
            <Box>{t('Entities')}</Box>
          </Box>
          <Divider />
          {children}
          <Divider />
          <Box className={classes.selectorButtons}>
            <Button variant="text" onMouseDown={onClose}>
              {t('Cancel')}
            </Button>
            <Button variant="outlined" onMouseDown={onSelect}>
              {t('Select')}
            </Button>
          </Box>
        </>
      </LoadingWrapper>
    </Paper>
  );
};

export default SelectorLayout;
