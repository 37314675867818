import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  totalContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    backgroundColor: theme.palette.primary.main,
    height: '56px',
    width: '100%',
    borderRadius: '4px',
    boxShadow: '0 0 13px 0 rgba(0,0,0,0.26)',
    marginTop: '16.5px'
  },
  totalText: {
    fontFamily: theme.typography.body2.fontFamily,
    letterSpacing: theme.typography.body2.letterSpacing,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight,
    color: theme.palette.common.white,
    textAlign: 'right',
    lineHeight: '22px',
    marginRight: '12px'
  },
  numbersText: {
    fontFamily: theme.typography.body2.fontFamily,
    letterSpacing: theme.typography.body2.letterSpacing,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight,
    color: theme.palette.common.white,
    textAlign: 'right',
    lineHeight: '22px',
    marginRight: '12px',
    marginTop: '6px'
  },
  taxRateContainer: {
    alignContent: 'center',
    borderRadius: '4px',
    backgroundColor: theme.palette.info.dark,
    margin: '10px 32px 10px 14px',
    height: '36px',
    width: '114px'
  },
  taxProvisionContainer: {
    alignContent: 'center',
    borderRadius: '4px',
    backgroundColor: theme.palette.info.dark,
    margin: '10px 32px 10px 14px',
    height: '36px',
    width: '125px'
  }
}));

type TotalBarProps = {
  totalTaxEffected: number | string;
  totalTaxRate: string;
};

const TotalBar = ({ totalTaxEffected, totalTaxRate }: TotalBarProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.totalContainer}>
      <Typography className={classes.totalText}>{t('Total Tax Provision')}</Typography>
      <Box className={classes.taxProvisionContainer}>
        <Typography className={classes.numbersText}>{totalTaxEffected}</Typography>
      </Box>
      <Typography className={classes.totalText}>{t('Effective Tax Rate')}</Typography>
      <Box className={classes.taxRateContainer}>
        <Typography className={classes.numbersText}>{totalTaxRate}</Typography>
      </Box>
    </Box>
  );
};

export default TotalBar;
