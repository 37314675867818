import { SearchboxElementType } from './components/SearchboxSelector/SearchboxSelector';

export const customFilterOptions = (options: SearchboxElementType[], { inputValue }: any) => {
  inputValue = inputValue.trim();
  return options.filter(
    (option: SearchboxElementType) =>
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      option.number?.toLowerCase().includes(inputValue.toLowerCase()) ||
      option.name?.toLowerCase().includes(inputValue.toLowerCase())
  );
};

export const filterByName = (options: SearchboxElementType[], { inputValue }: any) => {
  inputValue = inputValue.trim();
  return options.filter((option: SearchboxElementType) =>
    option.name?.toLowerCase().includes(inputValue.toLowerCase())
  );
};
