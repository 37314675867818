import { Redirect, Route, Switch } from 'react-router-dom';

import { Box, makeStyles } from '@material-ui/core';

import { EntityById, SummaryEntityDataRow, SummaryReportRow } from '../../../../models';
import {
  parseEntitiesAndTotalsFromDashboardReport,
  parseHeaderValuesFromReport
} from '../../utils/data-processing/parseDashboardReport';
import DeferredTaxAsset from '../DeferredTaxAsset';
import EffectiveTaxRate from '../EffectiveTaxRate';
import SummaryHeader from '../SummaryHeader';
import TotalProvision from '../TotalProvision';

const useStyles = makeStyles((theme) => {
  const spacing = theme.spacing(3);
  return {
    summaryContainer: {
      backgroundColor: theme.palette.primary.light,
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      overflow: 'hidden',
      gap: theme.spacing(2)
    },
    summaryReportContainer: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.common.white,
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    },
    summaryReportContainerOuter: {
      backgroundColor: theme.palette.common.white,
      border: '1px solid',
      borderColor: theme.palette.divider,
      borderRadius: theme.shape.borderRadius,
      overflow: 'auto',
      padding: spacing,
      maxHeight: '500px'
    }
  };
});

const summaryTabs = [
  {
    value: SummaryHeader.EffectiveTaxRate,
    render: (reportEntityRows: SummaryEntityDataRow[]) => <EffectiveTaxRate reportEntityRows={reportEntityRows} />
  },
  {
    value: SummaryHeader.TotalProvision,
    render: (reportEntityRows: SummaryEntityDataRow[]) => <TotalProvision reportEntityRows={reportEntityRows} />
  },
  {
    value: SummaryHeader.DeferredAssetLiability,
    render: (reportEntityRows: SummaryEntityDataRow[]) => <DeferredTaxAsset reportEntityRows={reportEntityRows} />
  }
];

export type SummaryRouterProps = {
  reportRows: SummaryReportRow[];
  path: string;
  entityById: EntityById;
};

const SummaryRouter = ({ path, reportRows, entityById }: SummaryRouterProps) => {
  const classes = useStyles();
  const entitiesWithDashboardData = parseEntitiesAndTotalsFromDashboardReport(reportRows, entityById);
  const headerValues = parseHeaderValuesFromReport(reportRows);

  return (
    <Box className={classes.summaryContainer}>
      <Switch>
        {summaryTabs.map(({ value, render }) => (
          <Route key={value} path={`${path}/${value}`}>
            <SummaryHeader headerValues={headerValues} activeTab={value} />
            <Box className={classes.summaryReportContainerOuter}>
              <Box className={classes.summaryReportContainer}>{render(entitiesWithDashboardData)}</Box>
            </Box>
          </Route>
        ))}
        <Redirect to={`${path}/${summaryTabs[0].value}`} />
      </Switch>
    </Box>
  );
};

export default SummaryRouter;
