import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    minWidth: 200,
    '& .MuiAutocomplete-popupIndicator': {
      color: theme.palette.text.primary,
      margin: theme.spacing(0, 0.5),
      transform: 'none'
    }
  },
  paper: {
    backgroundColor: theme.palette.common.white
  },
  listbox: {
    padding: theme.spacing(0.5)
  },
  option: {
    padding: theme.spacing(0.7),
    '&:hover': {
      borderRadius: theme.shape.borderRadius
    }
  }
}));

interface Props {
  [key: string]: any;
  className?: string;
  icon?: JSX.Element | null;
  placeholder?: string;
  isError?: boolean;
  options: any[];
}

const SearchBox = ({ icon = <Search />, placeholder, isError, className, ...otherProps }: Props) => {
  const { autocomplete, ...classes } = useStyles();

  return (
    <Autocomplete
      data-testid="autocomplete"
      {...otherProps}
      classes={classes}
      className={`${className ? `${className} ` : ''}${autocomplete}`}
      renderInput={(params) => <TextField {...params} placeholder={placeholder} error={isError} variant="outlined" />}
      popupIcon={icon}
      getOptionSelected={(option, value) =>
        value ? option.level === value.level && option.step === value.step : false
      }
    />
  );
};

export default SearchBox;
