import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '..';
import { CustomGroupFromApi, Entity } from '../../models';
import HTTPService, { LambdaResponse } from '../../services/http';

interface CreateCustomGroupProps {
  parentEntityId: string;
  groupName: string | undefined;
}

interface FetchCustomGroupEntitiesProps {
  groupId: string;
}

interface UpdateCustomGroupEntitiesProps {
  entityIds: string[];
  groupId: string;
  action: 'ADD' | 'REMOVE';
}

interface DeleteCustomGroupsProps {
  groupIds: string[];
}

export const fetchCustomGroups = createAsyncThunk<CustomGroupFromApi[], void, { rejectValue: Error }>(
  'customGroups/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const { data: customGroups } = await HTTPService.request<LambdaResponse<CustomGroupFromApi[]>>({
        method: 'get',
        relativePath: '/v1/custom-groups'
      });
      return customGroups;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const createCustomGroup = createAsyncThunk(
  'customGroups/create',
  async ({ parentEntityId, groupName }: CreateCustomGroupProps, { rejectWithValue }) => {
    try {
      const { data: customGroups } = await HTTPService.request<LambdaResponse<CustomGroupFromApi[]>>({
        method: 'post',
        relativePath: '/v1/custom-groups',
        data: {
          parentEntityId,
          groupName
        }
      });
      return customGroups;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchCustomGroupEntities = createAsyncThunk<
  { entities: Entity[]; groupId: string },
  FetchCustomGroupEntitiesProps,
  { rejectValue: Error }
>('customGroups/fetchEntities', async ({ groupId }: FetchCustomGroupEntitiesProps, { getState, rejectWithValue }) => {
  try {
    const { data: entities } = await HTTPService.request<LambdaResponse<Array<Partial<Entity>>>>({
      method: 'get',
      relativePath: `/v1/custom-groups/${groupId}/entities`
    });

    const { entities: containerEntities } = getState() as RootState;

    const groupEntitiesIds = new Set(entities.map((entity) => entity.entityId));

    const groupEntities = containerEntities.entities.filter((entity) => groupEntitiesIds.has(entity.entityId));

    return {
      entities: groupEntities,
      groupId
    };
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const updateCustomGroupEntities = createAsyncThunk<
  { entities: Entity[]; groupId: string },
  UpdateCustomGroupEntitiesProps,
  { rejectValue: Error }
>(
  'customGroups/updateEntities',
  async ({ action, entityIds, groupId }: UpdateCustomGroupEntitiesProps, { getState, rejectWithValue }) => {
    try {
      await HTTPService.request({
        method: 'patch',
        relativePath: `/v1/custom-groups/${groupId}/entities`,
        data: {
          action,
          entityIds
        }
      });

      const { customGroups, entities: containerEntities } = getState() as RootState;

      const entityIdsSet = new Set(entityIds);

      const groupEntities = [...customGroups.customGroupsEntities[groupId]];

      let newGroupEntities;

      if (action === 'ADD') {
        groupEntities.push(...containerEntities.entities.filter((entity) => entityIdsSet.has(entity.entityId)));
        newGroupEntities = groupEntities;
      } else {
        newGroupEntities = groupEntities.filter((entity) => !entityIdsSet.has(entity.entityId));
      }

      return {
        entities: newGroupEntities,
        groupId
      };
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const deleteCustomGroups = createAsyncThunk<
  { groupIds: string[] },
  DeleteCustomGroupsProps,
  { rejectValue: Error }
>('customGroups/delete', async ({ groupIds }: DeleteCustomGroupsProps, { getState, rejectWithValue }) => {
  try {
    await HTTPService.request({
      method: 'patch',
      relativePath: `/v1/custom-groups`,
      data: {
        groupIds
      }
    });

    const { customGroups } = getState() as RootState;
    const updatedGroups = customGroups.customGroups.filter(
      (group: { groupId: string }) => !groupIds.includes(group.groupId)
    );
    const deletedGroups: any = customGroups.customGroups.filter((group: { groupId: string }) =>
      groupIds.includes(group.groupId)
    );
    const deletedGroupsWithoutEntities = deletedGroups.map((group: CustomGroupFromApi) => {
      return { ...group, entityCount: 0 };
    });

    return {
      groupIds,
      updatedGroups,
      deletedGroupsWithoutEntities
    };
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});
