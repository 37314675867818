import { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { Box, Button } from '@material-ui/core';
import classNames from 'classnames';

import { useStyles } from './ImportButton.styles';

import { useEntities } from '../../../../hooks';
import { addDataImport, resetDataImport } from '../../../../redux/dataImport';
import { resetUploadManager } from '../../../../redux/uploadManager';
import {
  selectUploadManagerCreditName,
  selectFileName,
  selectNameRangeDataImport,
  selectFileVersion,
  selectAuthInfo,
  selectEntitiesMapByNumber
} from '../../../../selectors';
import { EntityNumberRouteMatch } from '../../../EntityDetails/utils';
import { ImportButtonProps, getFormattedRows } from '../../../Spreadsheet/utils';

const ImportButton = ({
  checkmarkIndices,
  level,
  rows = [],
  step,
  onImportFinished,
  hasErrorState,
  isTabCompleted
}: ImportButtonProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { currentEntity } = useEntities();
  let creditName = useSelector(selectUploadManagerCreditName);
  const fileName = String(useSelector(selectFileName));
  const fileVersion = String(useSelector(selectFileVersion));
  const dataImportRange = useSelector(selectNameRangeDataImport);
  const userAuth = useSelector(selectAuthInfo);
  const {
    params: { entityNumber }
  } = useRouteMatch<EntityNumberRouteMatch>();
  const entityByNumberMap = useSelector(selectEntitiesMapByNumber);
  const [selectionData, setSelectionData] = useState({});

  // handles case where creditName is undefined because financial
  // data has not been entered for cells in the PTBI step
  if (level === 'federal' && step === 'ptbi') {
    creditName = 'fullYearProjection';
  }

  const [formattedRowsToCreate, formattedRowsToUpdate] = getFormattedRows({
    checkmarkIndices,
    creditName,
    level,
    rows,
    step,
    fileName
  });

  useEffect(() => {
    const { entityId } = entityByNumberMap[entityNumber];
    const { userId } = userAuth;
    const checkBoxList = checkmarkIndices.map((check, index) => {
      return {
        index,
        isChecked: check
      };
    });
    setSelectionData({
      fileName,
      fileVersion,
      selection: dataImportRange,
      importCheckBoxList: checkBoxList,
      level,
      step,
      userId,
      entityId
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkmarkIndices]);

  const handleClick = async () => {
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await dispatch(
      addDataImport({
        entityId: currentEntity?.entityId ?? '',
        createItems: formattedRowsToCreate,
        updateItems: formattedRowsToUpdate,
        selectionData
      })
    );

    onImportFinished();

    dispatch(resetUploadManager());
    dispatch(resetDataImport());
  };

  const shouldDisableButton =
    (formattedRowsToCreate.length === 0 && formattedRowsToUpdate.length === 0) || hasErrorState || isTabCompleted;

  return (
    <Box className={classNames({ [classes.disabled]: shouldDisableButton })}>
      <Button
        className={classes.importButton}
        disabled={shouldDisableButton}
        color="primary"
        variant="contained"
        onClick={handleClick}
      >
        Import
      </Button>
    </Box>
  );
};

export default ImportButton;
