import { combineReducers, Reducer } from 'redux';

import { accountsEntityCountReducer } from './accountsEntityCount/accountsEntityCount.reducer';
import { auditTrailReducer } from './auditTrail';
import { authReducer, AuthStateInterface, AUTH_LOGOUT_SUCCESS } from './auth';
import { categoriesReducer } from './categories/categories.reducer';
import { configurationReducer, ConfigurationStateInterface } from './configuration';
import { containersReducer } from './containers';
import { currenciesReducer, CurrenciesStateInterface } from './currencies';
import { customGroupsReducer } from './customGroups';
import { dataImportReducer } from './dataImport';
import { entitiesReducer } from './entities';
import { entitiesCompletionStatusReducer, EntitiesCompletionStatusStateInterface } from './entitiesCompletionStatus';
import { eulaReducer, EulaStateInterface } from './eula';
import { financialDataReducer, FinancialDataStateInterface } from './financialData';
import { jurisdictionsReducer, JurisdictionsStateInterface } from './jurisdictions';
import { notificationsReducer } from './notifications';
import { ratesReducer, RatesStateInterface } from './rates';
import { reportsReducer } from './reports';
import { traceabilityReducer } from './traceability';
import { uiReducer, UiStateInterface } from './ui';
import { uploadHistoryReducer, UploadHistoryState } from './uploadHistory';
import { uploadManagerReducer, UploadManagerState } from './uploadManager/uploadManager.reducer';
import { uploadReviewReducer, UploadReviewStateInterface } from './uploadReview';
import { validateImportReducer } from './validateImport';

import {
  AccountsEntityCountStateInterface,
  AuditTrailStateInterface,
  CategoriesStateInterface,
  ContainersStateInterface,
  CustomGroupsStateInterface,
  DataImportState,
  EntitiesStateInterface,
  NotificationsReducerStateInterface,
  ReportsReducerStateInterface,
  TraceabilityStateInterface,
  ValidateImportState
} from '../models';

const mainReducer: Reducer = combineReducers<RootState>({
  auth: authReducer,
  eula: eulaReducer,
  categories: categoriesReducer,
  accountsEntityCount: accountsEntityCountReducer,
  configuration: configurationReducer,
  containers: containersReducer,
  dataImport: dataImportReducer,
  jurisdictions: jurisdictionsReducer,
  currencies: currenciesReducer,
  uploadReview: uploadReviewReducer,
  entitiesCompletionStatus: entitiesCompletionStatusReducer,
  traceability: traceabilityReducer,
  financialData: financialDataReducer,
  rates: ratesReducer,
  customGroups: customGroupsReducer,
  entities: entitiesReducer,
  notifications: notificationsReducer,
  reports: reportsReducer,
  ui: uiReducer,
  uploadHistory: uploadHistoryReducer,
  uploadManager: uploadManagerReducer,
  validateImport: validateImportReducer,
  auditTrail: auditTrailReducer
});

export interface RootState {
  auth: AuthStateInterface;
  eula: EulaStateInterface;
  categories: CategoriesStateInterface;
  accountsEntityCount: AccountsEntityCountStateInterface;
  configuration: ConfigurationStateInterface;
  containers: ContainersStateInterface;
  currencies: CurrenciesStateInterface;
  dataImport: DataImportState;
  entitiesCompletionStatus: EntitiesCompletionStatusStateInterface;
  jurisdictions: JurisdictionsStateInterface;
  uploadReview: UploadReviewStateInterface;
  traceability: TraceabilityStateInterface;
  financialData: FinancialDataStateInterface;
  rates: RatesStateInterface;
  customGroups: CustomGroupsStateInterface;
  entities: EntitiesStateInterface;
  notifications: NotificationsReducerStateInterface;
  reports: ReportsReducerStateInterface;
  ui: UiStateInterface;
  uploadHistory: UploadHistoryState;
  uploadManager: UploadManagerState;
  validateImport: ValidateImportState;
  auditTrail: AuditTrailStateInterface;
}

const rootReducer: Reducer<RootState> = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = {} as RootState,
  action
): RootState => {
  let newState = state;
  if (action.type === AUTH_LOGOUT_SUCCESS) {
    localStorage.clear();
    // eslint-disable-next-line  @typescript-eslint/consistent-type-assertions
    newState = {} as RootState;
  }

  return mainReducer(newState, action);
};

export default rootReducer;
