export const SET_CONFIG_DATA = 'SET_CONFIG_DATA';

export interface ConfigurationStateInterface {
  apiUrl: string; // main provision api
  reportsApiUrl: string; // provision reports api
  calculationReports: string; // calculation reports api
  uiUrl: string; // provision ui
  principleUrl: string; // principle api
  authUrl: string; // base url of auth stack
  loginStackRelativeUrl: string; // relative path for generic login page
  proxyUrl: string; // base url of platform proxy
  excelReports: string;
  xbsEnvironment: string;
  xbsEnvironmentShort: string;
  buildTime: string;
  clientAppId: string;
  launchDarklyDevelopId: string;
  launchDarklyStagingId: string;
  launchDarklyProductionId: string;
  webSocketDevelopUrl: string;
  webSocketStagingUrl: string;
  webSocketProductionUrl: string;
  exacteraDomainUrl: string;
}

export interface SetConfigDataAction {
  type: typeof SET_CONFIG_DATA;
  payload: ConfigurationStateInterface;
}

export type ConfigActionTypes = SetConfigDataAction;
