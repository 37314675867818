import { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import pako from 'pako';

import ReportList from './ReportList';
import { fetchReports, shortenName } from './utils';

import { REPORT_STATUS } from '../../../../constants';
import { useContainers, useCustomGroups, useEntities } from '../../../../hooks';
import { useExcel } from '../../../../hooks/useExcel';
import { ListedReportShape, ReportForExport } from '../../../../models';
import { ReportMeta } from '../../../../redux/reports';
import { setShouldGroupCategoriesOnReports } from '../../../../redux/ui';
import { selectShouldGroupCategoriesOnReports } from '../../../../selectors';
import { selectCategories } from '../../../../selectors/categories';
import { selectReportsMeta } from '../../../../selectors/reports';
import transformGroupUuid from '../../../../utils/transformGroupUuid';
import { isLineItemWithFinancialValues } from '../../utils';
import composeTableData from '../../utils/data-processing/composeTableData';
import { buildColumnsForExport, buildRowsForExport } from '../UnifiedMultiReportView/UnifiedMultiReportView';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  headerActionsContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
    gap: theme.spacing(0.5)
  },
  allReportsContainer: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
    overflow: 'auto'
  },
  select: {
    margin: theme.spacing(1.5, 0),
    minWidth: 250
  },
  headerContainer: {
    padding: theme.spacing(0.5, 1.5, 0.5, 1),
    display: 'flex'
  },
  title: {
    padding: theme.spacing(2, 2, 2, 1),
    display: 'inline-block'
  },
  button: {
    margin: theme.spacing(1.5, 0),
    borderRadius: theme.shape.borderRadius
  },
  settingsButton: {
    fontFamily: 'Basis Grotesque Pro Medium',
    height: '2.5rem',
    paddingLeft: '0.7rem',
    paddingRight: '0.7rem',
    radius: '0.25rem',
    '& .MuiButton-label': {
      gap: '0.35rem'
    }
  },
  settingsContainer: {
    '& .MuiPopover-paper': {
      backgroundColor: theme.palette.common.white,
      padding: '0.75rem 0.9rem',
      borderRadius: '0.25rem',
      width: '16rem'
    },
    '& .setting': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  }
}));

export type RenderReportsProps = {
  refinedReports: ListedReportShape[];
  reportType: string;
  currencyISO: string;
  reportsOrder: Record<string, number>;
  hasStates: boolean;
  reportsMeta: Record<string, Record<string, ReportMeta>>;
  reportSourceId: string;
  type: string;
};

type Props = {
  currencyISO: string;
  title: string;
  reports: ListedReportShape[];
  backTo: string;
  hasStates: boolean;
  type: 'entity' | 'group';
};

export const foreignEntitiesExcludedReportNames = new Set([
  'state-current-provision',
  'federal-current-provision-state-tax-deduction-rtp',
  'deferred-state',
  'deferred-fbos',
  'deferred-state-fbos',
  'deferred-fed-state-fbos',
  'state-rtp',
  'beginning-state-etr',
  'current-state-etr',
  'end-state-etr'
]);

const checkIfSomeReportIsUpdating = ({
  reportsMeta,
  type,
  isCurrencyConversion
}: {
  reportsMeta: Record<string, Record<string, ReportMeta>>;
  type: string;
  isCurrencyConversion: boolean;
}) => {
  return Object.values(reportsMeta[type]).some(
    (reportMeta) =>
      reportMeta.status === REPORT_STATUS.inProgress &&
      Boolean(reportMeta.isCurrencyConversion) === isCurrencyConversion
  );
};

const ReportListConnector = ({ currencyISO, title, reports, backTo, hasStates, type }: Props) => {
  const { prov2884FederalCurrentProvisionStateTaxDeductionRtp, prov3603CalcEngine2Report } = useFlags();
  const options = {
    entity: ['all-reports', 'tarf', 'sum', 'deferred', 'etr', 'rtp', 'current'],
    group: ['all-reports', 'tarf', 'sum', 'deferred', 'etr', 'rtp', 'current']
  };

  const [reportType, setReportType] = useState(options[type][0]);
  const reportsMeta = useSelector(selectReportsMeta);
  const isCurrencyConversion = currencyISO === 'USD';
  const [areReportsBulkDownloading, setAreReportsBulkDownloading] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { currentContainer } = useContainers();
  const isInterim = currentContainer?.isInterimReportingPeriod;
  const shouldGroupCategories = useSelector(selectShouldGroupCategoriesOnReports);
  const categoriesState = useSelector(selectCategories);

  const refinedReports = useMemo(() => {
    let myReports = [...reports];

    const reportShouldBeExcludedMap: Record<string, boolean> = {
      'actual-year-to-date-etr': !isInterim,
      'federal-current-provision-state-tax-deduction-rtp': !prov2884FederalCurrentProvisionStateTaxDeductionRtp
    };

    myReports = myReports.filter((report) => {
      return !reportShouldBeExcludedMap[report.name];
    });

    if (!hasStates) {
      myReports = myReports.filter((report) => !foreignEntitiesExcludedReportNames.has(report.name));
    }

    return myReports;
  }, [hasStates, isInterim, reports, prov2884FederalCurrentProvisionStateTaxDeductionRtp]);

  const { entityNumber, groupId } = useParams<{ entityNumber: string; groupId: string }>();
  const { entityById, entityByNumber } = useEntities();
  const { customGroups } = useCustomGroups();
  const { exportReports } = useExcel();

  const entity = useMemo(() => entityByNumber[entityNumber], [entityByNumber, entityNumber]);
  const group = useMemo(() => customGroups.find((group) => group.groupId === groupId), [customGroups, groupId]);
  const isSomeReportUpdating = checkIfSomeReportIsUpdating({ reportsMeta, type, isCurrencyConversion });

  useEffect(() => {
    const storedShouldGroupCategories = localStorage.getItem('shouldGroupCategories');
    if (storedShouldGroupCategories !== null) {
      dispatch(setShouldGroupCategoriesOnReports(JSON.parse(storedShouldGroupCategories)));
    }
  }, [dispatch]);

  const handleShouldGroupCategoriesUpdate = () => {
    const newShouldGroupCategories = !shouldGroupCategories;
    dispatch(setShouldGroupCategoriesOnReports(newShouldGroupCategories));
    localStorage.setItem('shouldGroupCategories', JSON.stringify(newShouldGroupCategories));
  };

  const handleBulkReportsDownload = async () => {
    setAreReportsBulkDownloading(true);

    const fullReports = await fetchReports({
      useCalcEngineReport: prov3603CalcEngine2Report,
      container: currentContainer,
      currencyISO,
      entity,
      group: group!,
      reports: refinedReports
    });
    const fullReportDefinitions =
      fullReports?.[0]?.type === 'Buffer'
        ? fullReports.map((report) => {
            const decompressedData = pako.inflate(report.data, { to: 'string' });
            return JSON.parse(decompressedData);
          })
        : fullReports;

    const composedReports = [];

    const fullReportsFiltered = fullReportDefinitions.filter(
      (report) =>
        (report.type === reportType || reportType === 'all-reports') &&
        (hasStates || !foreignEntitiesExcludedReportNames.has(report.name))
    );

    for (const report of fullReportsFiltered) {
      const composedReport = composeTableData({
        entityById,
        report,
        t,
        categories: categoriesState.categories,
        shouldGroupCategories,
        toggledCategories: {} // hardcoded because I never expect UI interaction with mass download
      });
      composedReports.push(composedReport);
    }

    const isEntity = Boolean(entity);
    const sourceName = isEntity ? `${entity.name} (${entity.entityNumber})` : `${group?.name ?? ''}`;

    const newFormattedReports: ReportForExport[] = composedReports.map((report, index) => {
      const reportName = shortenName(fullReportsFiltered[index].key);
      const rawName = fullReportsFiltered[index].name;
      const titleWithReportName = t(`${String(fullReportsFiltered[index].name)} _`, { name: sourceName });
      let columnsForExport = report.columns;
      let rowsForExport = report.rowsForExcel;
      if (
        !isEntity &&
        (report.reportName === 'beginning-state-etr' ||
          report.reportName === 'current-state-etr' ||
          report.reportName === 'end-state-etr')
      ) {
        const rowsByEntity: Record<string, Array<Record<string, unknown>>> = {};
        const [metaDataRow] = report.rows;
        const entityIds = Object.values(metaDataRow.columnMeta).map((item: any) => item.entityId);
        const uniqueEntityIds = [...new Set(entityIds)];
        uniqueEntityIds.forEach((entityId) => {
          rowsByEntity[entityId] = [];
        });
        report.rows.forEach((row) => {
          const { format, name, type } = row;
          const rowColumnsKeyValuePairs = Object.entries(row);

          uniqueEntityIds.forEach((entityId) => {
            const entityColumnsKeyValuePairs = rowColumnsKeyValuePairs.filter(([key]) => key.includes(entityId));
            const rowToBe = {
              format,
              name,
              type,
              ...Object.fromEntries(entityColumnsKeyValuePairs)
            };

            if (isLineItemWithFinancialValues(rowToBe)) {
              rowsByEntity[entityId].push(rowToBe);
            }
          });
        });
        columnsForExport = buildColumnsForExport(report.columns);
        rowsForExport = buildRowsForExport(rowsByEntity, entityById);
      }

      return {
        rows: rowsForExport,
        rowsForExcel: rowsForExport,
        totals: report.totals,
        columns: columnsForExport,
        reportName,
        rawName,
        title: titleWithReportName,
        startDate: currentContainer?.startDate,
        endDate: currentContainer?.endDate,
        currency: currencyISO
      };
    });

    await exportReports(newFormattedReports);

    setAreReportsBulkDownloading(false);
  };

  return (
    <ReportList
      shouldGroupCategories={shouldGroupCategories}
      backTo={backTo}
      classes={classes}
      currencyISO={currencyISO}
      hasStates={hasStates}
      areReportsBulkDownloading={areReportsBulkDownloading}
      options={options}
      refinedReports={refinedReports}
      reportType={reportType}
      setReportType={setReportType}
      t={t}
      title={title}
      type={type}
      reportsMeta={reportsMeta}
      reportSourceId={entity?.entityId ?? transformGroupUuid(group?.groupId!)}
      isSomeReportUpdating={isSomeReportUpdating}
      onShouldGroupCategoriesUpdate={handleShouldGroupCategoriesUpdate}
      onBulkReportsDownload={handleBulkReportsDownload}
    />
  );
};

export default ReportListConnector;
