import { IconButton } from '@material-ui/core';
import saveAs from 'file-saver';

import { ReactComponent as CompareIcon } from '../../../../assets/img/ComparableIcon.svg';
import { formatDateLong } from '../../../../utils';
import { getComparisonFile } from '../ReportList/utils';

type ReportCompareDownloadButtonProps = {
  reportType: string;
  reportModelId: string;
  reportName: string;
  currencyIsoCode: string | undefined;
  taxYear: number | undefined;
  timestamp: string | undefined;
};

const ReportCompareDownloadButton = ({
  reportType,
  reportModelId,
  reportName,
  currencyIsoCode,
  taxYear,
  timestamp
}: ReportCompareDownloadButtonProps) => {
  const handleButtonClick = async () => {
    const response: string[] = await getComparisonFile({
      reportType,
      reportModelId,
      reportName,
      currencyIsoCode,
      taxYear
    });
    const formattedDate = formatDateLong(timestamp).replace(/,/g, '');
    const blob = new Blob(response, { type: 'text/csv' });
    saveAs(blob, `${reportName}-${formattedDate}-compare.csv`);
  };

  return (
    <IconButton onClick={handleButtonClick}>
      <CompareIcon />
    </IconButton>
  );
};

export default ReportCompareDownloadButton;
