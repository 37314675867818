import { createAction } from '@reduxjs/toolkit';

import { CategoryAccountPair, CategoryFromApi, DeletedCategoryFromApi, UpdatedCategoryFromApi } from '../../models';

export const categoriesOnSendingRequest = createAction('categories/onSendingRequest');
export const categoriesOnReceived = createAction<CategoryFromApi[]>('categories/onReceived');
export const categoriesOnFailed = createAction<string>('categories/onFailed');

export const categoriesCreateSendingRequest = createAction('categories/createSendingRequest');
export const categoriesOnCreated = createAction<CategoryFromApi>('categories/categoriesOnCreated');

export const categoryUpdateRequest = createAction('categories/updateRequest');
export const categoryUpdateSuccess = createAction<UpdatedCategoryFromApi>('categories/updateSuccess');
export const categoryUpdateFailure = createAction<string>('categories/updateFailure');

export const categoryDeleteRequest = createAction('categories/deleteRequest');
export const categoryDeleteSuccess = createAction<DeletedCategoryFromApi>('categories/deleteSuccess');
export const categoryDeleteFailure = createAction<string>('categories/deleteFailure');

export const categoryAccountsUpdateRequest = createAction<{
  categoryId: string;
  action: 'ADD' | 'DELETE';
  categoryAccountPairs: CategoryAccountPair[];
}>('categories/categoryAccountsUpdateRequest');
export const categoryAccountsUpdateSuccess = createAction<{
  categoryId: string;
  action: 'ADD' | 'DELETE';
  categoryAccountPairs: CategoryAccountPair[];
}>('categories/categoryAccountsUpdateSuccess');
export const categoryAccountsUpdateFailure = createAction<string>('categories/categoryAccountsUpdateFailure');
