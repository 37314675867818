import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { UserPrinciple } from '../../models';
import { eulaOnFailed, eulaOnReceived, eulaOnSendingRequest } from '../../redux/eula';
import { selectEula } from '../../selectors';
import HTTPService, { LambdaResponse } from '../../services/http';

export function useEula() {
  const dispatch = useDispatch();
  const eulaState = useSelector(selectEula);

  useEffect(() => {
    async function fetchData() {
      dispatch(eulaOnSendingRequest());
      try {
        const { data: userPrinciple } = await HTTPService.request<LambdaResponse<UserPrinciple[]>>({
          apiUrlKey: 'principleUrl',
          method: 'get',
          relativePath: '/v1/user'
        });
        dispatch(eulaOnReceived(Boolean(userPrinciple[0].licenseAgreement)));
      } catch (error: unknown) {
        dispatch(eulaOnFailed(error));
      }
    }

    if (!eulaState.isLoading && !eulaState.isLoaded && !eulaState.error) {
      void fetchData();
    }
  }, [eulaState, dispatch]);

  return eulaState;
}
