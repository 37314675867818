import { isInRange } from '.';

import { AccountRange, AccountShape, Category, Entity } from '../models';

export function computePTBI(
  range: AccountRange['range'],
  categories: Category[],
  accounts: AccountShape[],
  entityId?: Entity['id']
) {
  if (!Array.isArray(categories) || !Array.isArray(accounts) || !Array.isArray(range)) {
    return { ptbi: 0 };
  }

  let ptbi = 0;
  const excludedIds = categories.find(({ id }) => id === 'income-tax')?.accountIds ?? [];
  for (const { id, totals } of accounts) {
    if (!excludedIds.includes(id) && isInRange(id, range)) {
      ptbi -= entityId
        ? totals[entityId] || 0
        : Object.values(totals)
            .map((element) => Number(element))
            // eslint-disable-next-line unicorn/no-array-reduce
            .reduce((sum, value) => sum + value);
    }
  }

  return { ptbi };
}
