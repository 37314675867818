import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    padding: '4rem'
  },
  controls: {
    display: 'flex',
    justifyContent: 'end',
    paddingBottom: '5px'
  },
  table: {
    height: '100%',
    margin: 'auto',
    maxHeight: '65vh',
    marginTop: '31px',
    overflow: 'auto',
    width: '80%',
    '& th': {
      backgroundColor: `${theme.palette.secondary.lighter!} !important`
    },
    '& .MuiTableCell-body': {
      borderRight: '0px !important',
      padding: '8px'
    },
    '& .MuiTableCell-head': {
      color: theme.palette.text.dark,
      fontFamily: 'Basis Grotesque Pro',
      fontWeight: 600,
      maxWidth: '120px',
      padding: '8px'
    }
  },
  darkCell: {
    border: `1px solid ${theme.palette.secondary.light} !important`
  },
  clearCell: {
    border: `1px solid ${theme.palette.info.dark} !important`
  },
  whiteBackground: {
    backgroundColor: `${theme.palette.common.white} !important`
  },
  errorBorder: {
    border: `1px solid ${theme.palette.error.main} !important`
  },
  tableCell: {
    alignItems: 'center',
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.primary.light,
    borderRadius: '4px',
    color: theme.palette.text.dark,
    display: 'flex',
    fontFamily: 'Basis Grotesque Pro',
    justifyContent: 'flex-start',
    lineHeight: '34px',
    minHeight: '30px',
    padding: '0px 12px',
    width: '80%'
  },
  darkBorder: {},
  checkbox: {
    color: theme.palette.info.dark
  }
}));
