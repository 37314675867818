import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { clearDataImport, resetDataImport, saveDataImport } from './dataImport.actions';
import { dataImportInitialState } from './dataImport.initialState';

import { containersOnCurrentSwitched } from '../containers';

export const dataImportReducer = createReducer(dataImportInitialState, (builder) => {
  builder
    .addCase(saveDataImport, (state, action) => {
      if (state.selection[action.payload.field] === undefined) {
        state.selection[action.payload.field] = {} as any;
      }

      state.selection[action.payload.field].range = action.payload.range;
      state.selection[action.payload.field].formattedValues = action.payload.formattedValues;
      state.selection[action.payload.field].rawValues = action.payload.rawValues;
    })
    .addCase(clearDataImport, (state, action) => {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete state.selection[action.payload.headerName];
    })
    .addCase(resetDataImport, () => dataImportInitialState)
    .addMatcher(isAnyOf(containersOnCurrentSwitched), () => {
      return dataImportInitialState;
    });
});
