import {
  calculatePreTaxAmount,
  calculateRates,
  calculateSlidersProps,
  calculateTaxEffectedAmount
} from '../../../calculations';
import { GenericRecord } from '../../../calculations/utils';
import { etrSliderLimit, etrSliderSmallStep, etrSliderStep } from '../utils';

export const RESET = 'RESET';
export const CHANGE_RATE = 'CHANGE_RATE';
export const CHANGE_PTBI = 'CHANGE_PTBI';

const initialSliderValues = {
  permanent: 0,
  stateBenefit: 0,
  foreignRateDifferential: 0,
  credits: 0,
  allowance: 0,
  deferred: 0,
  deferredRate: 0,
  returnToProvison: 0,
  federalTaxAdjustments: 0
};

export function makeInitialState(analysis = {} as any) {
  const taxRates = calculateRates({ analysis, ptbi: analysis?.ptbi?.preTaxAmount });

  const rows = {
    ptbi: {
      preTaxAmount: analysis?.ptbi?.preTaxAmount,
      taxEffectedAmount: analysis?.ptbi?.taxEffectedAmount,
      taxRate: analysis?.ptbi?.taxRate
    },
    ...generateRows(analysis, taxRates)
  };

  return {
    analysis,
    initialState: { ...rows },
    rows,
    sliders: { ...initialSliderValues },
    sliderProps: calculateSlidersProps({ taxRates, etrSliderLimit, etrSliderSmallStep, etrSliderStep })
  };
}

function generateRows(analysis: any, taxRates: any) {
  const rows: GenericRecord = {};

  for (const row of Object.keys(taxRates)) {
    rows[row] = {
      preTaxAmount: analysis?.[row]?.preTaxAmount,
      taxEffectedAmount: analysis?.[row]?.taxEffectedAmount,
      taxRate: analysis?.[row]?.taxRate
    };
  }

  return rows;
}

function computeTaxRate(state: any, rowId: any, newValue: any) {
  const updatedTaxEffectedAmount = calculateTaxEffectedAmount({
    ptbi: state.rows.ptbi.preTaxAmount,
    taxRate: newValue
  });
  return {
    ...state.rows,
    [rowId]: {
      taxRate: newValue,
      taxEffectedAmount: updatedTaxEffectedAmount,
      preTaxAmount: calculatePreTaxAmount({
        taxEffectedAmount: updatedTaxEffectedAmount,
        taxRate: state.rows.ptbi.taxRate,
        rowId
      })
    }
  };
}

function computePtbi(state: any, newValue: any) {
  const taxRates = calculateRates({ analysis: state.rows, ptbi: newValue });
  const newState = {
    ...state,
    rows: {
      ...state.rows,
      ptbi: {
        ...state.rows.ptbi,
        taxEffectedAmount: calculateTaxEffectedAmount({ ptbi: newValue, taxRate: state.rows.ptbi.taxRate }),
        preTaxAmount: newValue
      }
    }
  };

  for (const row of Object.keys(taxRates)) {
    // FIXME : Fix this the next time the file is edited.
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    const updatedTaxRate = newState.sliders[row] + taxRates[row];
    newState.rows = {
      ...newState.rows,
      [row]: {
        ...newState.rows[row],
        taxRate: updatedTaxRate
      }
    };
    newState.initialState = {
      ...newState.initialState,
      [row]: {
        ...newState.initialState[row],
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        taxRate: taxRates[row]
      }
    };
  }

  return newState;
}

export function reducer(state: any, { type, payload = {} }: any = {}) {
  switch (type) {
    case RESET: {
      return makeInitialState(state.analysis);
    }

    case CHANGE_RATE: {
      const { rowId, rate } = payload;

      return {
        ...state,
        // FIXME : Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        rows: computeTaxRate(state, rowId, state.initialState[rowId].taxRate + rate),
        sliders: { ...state.sliders, [rowId]: rate }
      };
    }

    case CHANGE_PTBI: {
      return computePtbi(state, payload.ptbi);
    }

    default: {
      return state;
    }
  }
}
