import { TFunction } from 'react-i18next';

import { GenericRecord } from '../../../calculations/utils';
import { Report, TrendAnalysis, TrendAnalysisValue } from '../../../models';

export const createTableRows = (
  t: TFunction,
  currentTrendAnalysis: TrendAnalysis,
  comparisonTrendAnalysis: TrendAnalysis
): TrendAnalysisValue[] => {
  return [
    {
      name: t('Adjusted Pre-Tax Book Income / (Loss)'),
      preTaxAmount: currentTrendAnalysis.ptbi.preTaxAmount,
      taxEffectedAmount: currentTrendAnalysis.ptbi.taxEffectedAmount,
      taxRate: currentTrendAnalysis.ptbi.taxRate,
      preTaxAmountComparison: comparisonTrendAnalysis.ptbi.preTaxAmount,
      taxEffectedAmountComparison: comparisonTrendAnalysis.ptbi.taxEffectedAmount,
      taxRateComparison: comparisonTrendAnalysis.ptbi.taxRate
    },
    {
      name: t('Permanent Differences'),
      preTaxAmount: currentTrendAnalysis.permanent.preTaxAmount,
      taxEffectedAmount: currentTrendAnalysis.permanent.taxEffectedAmount,
      taxRate: currentTrendAnalysis.permanent.taxRate,
      preTaxAmountComparison: comparisonTrendAnalysis.permanent.preTaxAmount,
      taxEffectedAmountComparison: comparisonTrendAnalysis.permanent.taxEffectedAmount,
      taxRateComparison: comparisonTrendAnalysis.permanent.taxRate
    },
    {
      name: t('State Tax, Net of Federal Benefit'),
      preTaxAmount: currentTrendAnalysis.stateBenefit.preTaxAmount,
      taxEffectedAmount: currentTrendAnalysis.stateBenefit.taxEffectedAmount,
      taxRate: currentTrendAnalysis.stateBenefit.taxRate,
      preTaxAmountComparison: comparisonTrendAnalysis.stateBenefit.preTaxAmount,
      taxEffectedAmountComparison: comparisonTrendAnalysis.stateBenefit.taxEffectedAmount,
      taxRateComparison: comparisonTrendAnalysis.stateBenefit.taxRate
    },
    {
      name: t('Foreign Rate Differential'),
      taxEffectedAmount: currentTrendAnalysis.foreignRateDifferential.taxEffectedAmount,
      taxRate: currentTrendAnalysis.foreignRateDifferential.taxRate,
      taxEffectedAmountComparison: comparisonTrendAnalysis.foreignRateDifferential.taxEffectedAmount,
      taxRateComparison: comparisonTrendAnalysis.foreignRateDifferential.taxRate
    },
    {
      name: t('Tax Credits'),
      taxEffectedAmount: currentTrendAnalysis.credits.taxEffectedAmount,
      taxRate: currentTrendAnalysis.credits.taxRate,
      taxEffectedAmountComparison: comparisonTrendAnalysis.credits.taxEffectedAmount,
      taxRateComparison: comparisonTrendAnalysis.credits.taxRate
    },
    {
      name: t('Change in Valuation Allowance'),
      taxEffectedAmount: currentTrendAnalysis.allowance.taxEffectedAmount,
      taxRate: currentTrendAnalysis.allowance.taxRate,
      taxEffectedAmountComparison: comparisonTrendAnalysis.allowance.taxEffectedAmount,
      taxRateComparison: comparisonTrendAnalysis.allowance.taxRate
    },
    {
      name: t('Deferred Rate Change'),
      taxEffectedAmount: currentTrendAnalysis.deferredRate.taxEffectedAmount,
      taxRate: currentTrendAnalysis.deferredRate.taxRate,
      taxEffectedAmountComparison: comparisonTrendAnalysis.deferredRate.taxEffectedAmount,
      taxRateComparison: comparisonTrendAnalysis.deferredRate.taxRate
    },
    {
      name: t('Deferred Only Adjustments'),
      taxEffectedAmount: currentTrendAnalysis.deferred.taxEffectedAmount,
      taxRate: currentTrendAnalysis.deferred.taxRate,
      taxEffectedAmountComparison: comparisonTrendAnalysis.deferred.taxEffectedAmount,
      taxRateComparison: comparisonTrendAnalysis.deferred.taxRate
    },
    {
      name: t('Return to Provision'),
      taxEffectedAmount: currentTrendAnalysis.returnToProvison.taxEffectedAmount,
      taxRate: currentTrendAnalysis.returnToProvison.taxRate,
      taxEffectedAmountComparison: comparisonTrendAnalysis.returnToProvison.taxEffectedAmount,
      taxRateComparison: comparisonTrendAnalysis.returnToProvison.taxRate
    },
    {
      name: t('Federal Tax-Effected Adjustments'),
      taxEffectedAmount: currentTrendAnalysis.federalTaxAdjustments.taxEffectedAmount,
      taxRate: currentTrendAnalysis.federalTaxAdjustments.taxRate,
      taxEffectedAmountComparison: comparisonTrendAnalysis.federalTaxAdjustments.taxEffectedAmount,
      taxRateComparison: comparisonTrendAnalysis.federalTaxAdjustments.taxRate
    },
    {
      name: t('Total Tax Provision'),
      taxEffectedAmount: currentTrendAnalysis.totalProvision.taxEffectedAmount,
      taxRate: currentTrendAnalysis.totalProvision.taxRate,
      taxEffectedAmountComparison: comparisonTrendAnalysis.totalProvision.taxEffectedAmount,
      taxRateComparison: comparisonTrendAnalysis.totalProvision.taxRate
    }
  ];
};

// TODO (JR): Understand why certain fields in the report need to be skipped. (PROV-1695)
const HEADER_FIELDERS = [
  'ptbi',
  'permanent',
  'stateBenefit',
  'foreignRateDifferential',
  'credits',
  'allowance',
  'deferredRate',
  // This is a workaround because there are fields in the report that we do not understand what they are doing,
  // And we do not want to invest resources currently to investigate.
  // Specifically `Deferred International FX Rate Change`.
  'PASS',
  'deferred',
  'returnToProvison',
  'federalTaxAdjustments',
  'totalProvision',
  'PASS' // We also skip `Total Tax Provision per Summary Report` for this component.
];

export const getTrendAnalysis = (worldwideReport?: Report | null): TrendAnalysis => {
  const rows = worldwideReport?.values ?? [];
  // eslint-disable-next-line unicorn/no-array-reduce
  return rows.reduce((acc: any, value: any, index: number) => {
    if (HEADER_FIELDERS[index] === 'PASS') return acc;
    return {
      ...acc,
      [HEADER_FIELDERS[index]]: {
        preTaxAmount: value.preTaxAmountWorldwide_dynamicHeader ?? 0,
        taxEffectedAmount: value.taxEffectedAmountWorldwide_dynamicHeader ?? 0,
        taxRate: value.taxRateWorldwide_dynamicHeader ?? 0
      }
    };
  }, {});
};

const isEmpty = (obj?: GenericRecord) => Object.keys(obj ?? {}).length === 0;

export const cannotRender = (baselineTrendAnalysis?: TrendAnalysis, comparisonTrendAnalysis?: TrendAnalysis) =>
  baselineTrendAnalysis === undefined ||
  comparisonTrendAnalysis === undefined ||
  isEmpty(baselineTrendAnalysis) ||
  isEmpty(comparisonTrendAnalysis);
