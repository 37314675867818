import * as XLSX from 'xlsx';

import { DataImportSelection, FormattedWorksheet } from '../../models';

type CellMerge = [number, number];

interface CellData {
  text: string;
  style?: number;
  merge?: CellMerge;
}

interface RowData {
  cells: {
    [key: number]: CellData;
  };
}

interface LabeledWorksheet {
  name: string;
  rows: RowData[];
}

interface FormattedWorkbook {
  name: string;
  rows: RowData[];
}

interface ValidateTabProps {
  noSelectionHasBeenMade: boolean;
  dataImportSelection: DataImportSelection;
  numberOfDataImportRows: number[];
  keyHeaderNames: string[];
  step?: string;
}

const convertWorksheet = (worksheet: XLSX.WorkSheet) => {
  if (worksheet['!ref'] === undefined) return;
  const range = XLSX.utils.decode_range(worksheet['!ref']);
  range.s = { r: 0, c: 0 };
  const convertedWorksheet: FormattedWorksheet = XLSX.utils.sheet_to_json(worksheet, {
    raw: false,
    header: 1,
    range,
    defval: ''
  });

  return convertedWorksheet;
};

export const isValidateTabEnabled = ({
  noSelectionHasBeenMade,
  dataImportSelection,
  numberOfDataImportRows,
  keyHeaderNames,
  step
}: ValidateTabProps) => {
  const doKeyHeaderCellsExist: boolean = keyHeaderNames
    .map((key, index) => {
      return (
        dataImportSelection?.[key]?.rawValues?.length === numberOfDataImportRows[index] &&
        numberOfDataImportRows[index] !== 0
      );
    }, [])
    .every((key) => key);

  if (step === 'credits') {
    const doCreditNameCellsExist =
      dataImportSelection?.['Credit Name']?.rawValues?.length === numberOfDataImportRows[0];
    return keyHeaderNames.length > 0 ? doKeyHeaderCellsExist && doCreditNameCellsExist : !noSelectionHasBeenMade;
  }

  return keyHeaderNames.length > 0 ? doKeyHeaderCellsExist : !noSelectionHasBeenMade;
};

// adapted from SheetJS/x-spreadsheet integration file: https://cdn.sheetjs.com/xspreadsheet/xlsxspread.js
export const stox = (workbook: XLSX.WorkBook) => {
  const { SheetNames, Sheets } = workbook;

  const length = Object.keys(workbook).length ?? 0;
  if (length === 0) {
    return;
  }

  const formattedWorkbook: FormattedWorkbook[] = [];
  let maxRowLength = 0;
  let maxColLength = 0;

  SheetNames.forEach(function (name) {
    const labeledWorksheet: LabeledWorksheet = { name, rows: {} as any };
    const worksheet = Sheets[name];

    const convertedWorksheet = convertWorksheet(worksheet);

    if (convertedWorksheet === undefined) return;

    if (convertedWorksheet.length > maxColLength) {
      maxColLength = convertedWorksheet.length;
    }

    convertedWorksheet.forEach((row, i) => {
      if (row.length > maxRowLength) {
        maxRowLength = row.length;
      }

      const cells: any = {};

      row.forEach((cell, j) => {
        cells[j] = {
          text: cell
        };
      });

      labeledWorksheet.rows[i] = { cells };
    });

    formattedWorkbook.push(labeledWorksheet);
  });

  return { formattedWorkbook, maxRowLength, maxColLength };
};
