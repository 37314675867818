import React from 'react';

import { Chip, makeStyles, Theme } from '@material-ui/core';

export type OutlinedButtonProps = {
  borderColor?: string;
  fillColor?: string;
  children: React.ReactNode;
  onClick?: () => void;
};

const useStyles = makeStyles<Theme, { borderColor?: string; fillColor?: string }>((theme) => {
  return {
    chip: {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: (props) => props.fillColor ?? theme.palette.info.main,
      border: (props) => `1px solid ${props.borderColor ?? theme.palette.secondary.main}`,
      '&.MuiChip-clickable.MuiChip-outlined:hover': {
        backgroundColor: (props) => props.fillColor ?? theme.palette.info.main
      }
    }
  };
});

const OutlinedButton: React.FC<OutlinedButtonProps> = ({
  borderColor,
  children,
  fillColor,
  onClick
}: OutlinedButtonProps) => {
  const classes = useStyles({ borderColor, fillColor });

  return <Chip label={children} className={classes.chip} variant="outlined" onClick={onClick} />;
};

export default OutlinedButton;
