import { useTranslation } from 'react-i18next';

import { JurisdictionsTabProps } from './JurisdictionsTab.proptype';

import { TabLayout } from '..';

const JurisdictionsTab = ({ jurisdictions, entities, jurisdictionByEntityId }: JurisdictionsTabProps) => {
  const { t } = useTranslation();
  return (
    <TabLayout
      asideTitle={t('Jurisdictions')}
      mainTitle={t('Entity List')}
      description={t('Please select the jurisdiction for each entity within the Trial Balance.')}
      searchCaption={t('Assign jurisdictions to entities')}
      searchPlaceholder={t('Select a Jurisdiction (United States, Spain, etc)')}
      attributeValues={jurisdictions}
      columns={[
        {
          field: 'id',
          filterable: true,
          sortable: true,
          placeholder: t('Entity No.'),
          width: '20%'
        },
        {
          field: 'name',
          filterable: true,
          sortable: true,
          placeholder: t('Entity Name'),
          width: '50%'
        }
      ]}
      attributeColumnName={t('Jurisdiction')}
      dataCountLabel={t('_ found', { count: entities?.length })}
      data={entities}
      attributeByRowId={jurisdictionByEntityId}
      noRowLabel={t('No Entities found')}
    />
  );
};

export default JurisdictionsTab;
