import { MouseEventHandler, ReactNode, forwardRef, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { Typography, DialogContent, Box, DialogActions, Button, Paper, Popper } from '@material-ui/core';

import { useStyles } from './styles';

import { Level, Step } from '../../../../models';

interface InterlinkedDataDialogProps {
  open: boolean;
  handleClose: () => void;
  anchorEl: HTMLElement | null;
  onBalanceClick: MouseEventHandler;
  onViewClick: MouseEventHandler;
  icon: ReactNode;
  value: number;
  level: Level;
  step: Step;
  // eslint-disable-next-line react/require-default-props
  creditName?: string;
}

const getPlacementOffset = (level: Level, step: Step) => {
  if (step === 'credits') return '-80, -282';
  if (level === 'federal' && step === 'rtp') return '-15, -282';
  if (level === 'state' && step === 'rtp') return '-42, -282';
  if (step === 'nol') return '105, -282';
};

const InterlinkedDataDialog = forwardRef<HTMLDivElement, InterlinkedDataDialogProps>(
  (
    {
      open,
      handleClose,
      anchorEl,
      onBalanceClick,
      onViewClick,
      icon,
      value,
      level,
      step,
      creditName = ''
    }: InterlinkedDataDialogProps,
    ref
  ) => {
    const classes = useStyles();
    const { t } = useTranslation();

    useEffect(() => {
      const handleScroll = () => {
        if (open) {
          handleClose();
        }
      };

      window.addEventListener('scroll', handleScroll, true);

      return () => {
        window.removeEventListener('scroll', handleScroll, true);
      };
    }, [open, handleClose]);

    return (
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        modifiers={{
          flip: {
            enabled: false
          },
          offset: {
            offset: getPlacementOffset(level, step)
          }
        }}
        style={{ zIndex: 1200 }}
      >
        <Paper ref={ref} className={classes.paper}>
          <Typography className={classes.dialogHeading}>{t('Interlinked data unequal')}</Typography>
          <Typography className={classes.dialogtext1}>{t('Return to Provision')}</Typography>
          <Typography className={classes.dialogtext2}>
            {creditName ? t(creditName) : t('Net Operating Loss')}
          </Typography>

          <DialogContent className={classes.dialogContent}>
            <Box className={classes.varianceTextContainer}>
              <Typography className={classes.varianceText}>{t('Variance')}</Typography>
            </Box>
            <Box className={classes.varianceBoxContainer}>
              <Box className={classes.varianceBox}>
                <Box className={classes.varianceIcon}>{icon}</Box>
                <Box className={classes.varianceValueText}>
                  {value < 0 ? `(${Math.abs(value).toLocaleString()})` : value.toLocaleString()}
                </Box>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button className={classes.balanceButton} onClick={onBalanceClick}>
              Balance
            </Button>
            <Button className={classes.viewButton} onClick={onViewClick}>
              View
            </Button>
          </DialogActions>
        </Paper>
      </Popper>
    );
  }
);

export default InterlinkedDataDialog;
