import { RootState } from '../redux';

export const selectDataImportSelection = (state: RootState) => state.dataImport.selection;

export const selectNumberOfDataImportRows = (keyColumnNames: string[]) => (state: RootState) => {
  const result: number[] = keyColumnNames.map((key) => {
    if (state.dataImport?.selection[key]) {
      return state.dataImport.selection[key].rawValues.length;
    }

    return 0;
  }, []);

  return result;
};

export const selectNameRangeDataImport = (state: RootState) => {
  return Object.keys(state.dataImport.selection).map((columnName) => {
    return {
      title: columnName,
      range: state.dataImport.selection[columnName].range
    };
  });
};
