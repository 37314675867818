import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@material-ui/core';

import { TableWithPlot } from '.';

import { totalColor } from './TotalProvision';

import { SummaryEntityDataRow } from '../../../models';
import { abbreviateNumber, formatPercentage } from '../../../utils';
import { getEntityRowsForSummaryTab } from '../utils/data-processing/parseDashboardReport';

interface EffectiveTaxRateProps {
  reportEntityRows: SummaryEntityDataRow[];
}

const EffectiveTaxRate = ({ reportEntityRows }: EffectiveTaxRateProps) => {
  const entitiesEtrRows = getEntityRowsForSummaryTab(reportEntityRows, 'etr');

  const { t } = useTranslation();
  const columns = [
    {
      placeholder: t('Entity'),
      filterable: true,
      sortable: true,
      field: 'name',
      width: '25%'
    },
    {
      headerName: t('Tax Rate'),
      field: 'value',
      isPercentage: true,
      sortable: true,
      width: '10%'
    },
    {
      series: [{ field: 'value', color: totalColor }],
      formatTick: (value: any) => abbreviateNumber(value ? formatPercentage(value) : '0%'),
      isPercentage: true
    }
  ];

  const rows = entitiesEtrRows.map((entry) =>
    typeof (entry as any).value === 'string' ? { ...entry, value: 0 } : entry
  );

  return (
    <>
      <Box mb={2}>
        <Typography variant="h2" component="h3">
          {t('Effective Tax Rate Details')}
        </Typography>
      </Box>
      <TableWithPlot
        singleBarPlot
        columns={columns}
        sort={{ column: null, dir: TableWithPlot.ASC }}
        isNotEditableShaded={false}
        rows={rows}
        variant="border-cell"
        barHeight="16px"
      />
    </>
  );
};

export default EffectiveTaxRate;
