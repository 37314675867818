import { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { ConfigurationStateInterface } from '../../redux/configuration';
import { updateModelMeta, updateReportMeta } from '../../redux/reports';
import { selectAuthInfo, selectConfiguration } from '../../selectors';
import PlatformService from '../../services/platform/index';
import { buildMessage } from '../../utils/websocket';
import { useContainers } from '../useContainers';

type MessageData = {
  type: string;
  data: any;
};

type UseMyWebSocketProps = {
  filter: (message: MessageEvent) => boolean;
};

const useMyWebSocket = ({ filter }: UseMyWebSocketProps) => {
  const dispatch = useDispatch();
  const authInfo = useSelector(selectAuthInfo);
  const { currentContainer } = useContainers();
  const {
    xbsEnvironment,
    webSocketDevelopUrl,
    webSocketStagingUrl,
    webSocketProductionUrl
  }: ConfigurationStateInterface = useSelector(selectConfiguration);
  const { prov3080Websocket, prov3137ReportsStatusRework } = useFlags();
  const shouldConnect = prov3080Websocket;
  const shouldProcessReportRelatedMessages = prov3137ReportsStatusRework;

  const getWebSocketUrl = useCallback(async () => {
    let webSocketUrl = webSocketProductionUrl;
    if (xbsEnvironment === 'develop') webSocketUrl = webSocketDevelopUrl;
    if (xbsEnvironment === 'staging') webSocketUrl = webSocketStagingUrl;

    let session;

    try {
      session = await PlatformService.getIdentitySession();
    } catch {}

    const { productUuid = '', roleUuid = '', userUuid = '' } = session ?? {};
    const { authToken = '' } = authInfo;

    const sessionHeaders = {
      Authorization: authToken,
      Xbsproductuuid: productUuid,
      Xbsroleuuid: roleUuid,
      Xbscontaineruuid: currentContainer?.containerId ?? '',
      Xbsuseruuid: userUuid,
      Xbsviewedtab: 'entityDetails'
    };

    const queryString = new URLSearchParams(sessionHeaders).toString();
    const webSocketUrlWithQueryString = `${webSocketUrl}?${queryString}`;

    return webSocketUrlWithQueryString;
  }, [
    authInfo,
    currentContainer?.containerId,
    xbsEnvironment,
    webSocketDevelopUrl,
    webSocketStagingUrl,
    webSocketProductionUrl
  ]);

  const { sendMessage, lastJsonMessage, readyState } = useWebSocket(
    getWebSocketUrl,
    {
      reconnectAttempts: 10,
      reconnectInterval: 5000,
      share: true,
      filter,
      shouldReconnect: (closeEvent) => closeEvent.code !== 1000 && closeEvent.code !== 1005,
      onMessage: (message) => {
        const parsedMsg = JSON.parse(message.data);

        switch (parsedMsg.type) {
          case 'modelStatus':
            if (shouldProcessReportRelatedMessages) {
              dispatch({ type: updateModelMeta, payload: parsedMsg.data });
            }

            break;
          case 'reportStatus':
            if (shouldProcessReportRelatedMessages) {
              dispatch({ type: updateReportMeta, payload: parsedMsg.data });
            }

            break;
          default:
            break;
        }
      }
    },
    shouldConnect ?? false
  );

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      console.log('WebSocket is running');
      const data = {};
      const newConnectionMessage = buildMessage('getUsersList', data);
      sendMessage(newConnectionMessage);
    }
  }, [readyState, sendMessage]);

  return {
    sendMessage,
    lastMessageData: lastJsonMessage as MessageData | undefined,
    readyState
  };
};

export default useMyWebSocket;
