import addTotalClassToTotalFields from './addTotalClassToTotalFields';
import composeTableColumnsWithDividers from './composeTableColumnsWithDividers';

import { Column, Header } from '../../../../models';

interface Props {
  headers: Header[];
}

const composeTableColumns = ({ headers }: Props): Column[] => {
  const tableColumns = headers.map(({ headerDisplayGroup, headerDisplayGroup2, field, name = '' }: Header) => {
    switch (field) {
      case 'name':
        return {
          field,
          sticky: true,
          className: 'unclickable',
          type: 'row-header',
          headerName: name
        };
      default:
        return {
          headerName: name,
          type: 'default',
          field,
          isNumber: true,
          ...(headerDisplayGroup && { headerGroup: headerDisplayGroup }),
          ...(headerDisplayGroup2 && { headerGroup2: headerDisplayGroup2 })
        };
    }
  });
  const columnsWithHighlightedTotalColumns = addTotalClassToTotalFields(tableColumns);
  const columnsWithDividers = composeTableColumnsWithDividers(columnsWithHighlightedTotalColumns);

  return columnsWithDividers;
};

export default composeTableColumns;
