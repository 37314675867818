import { Level, Step, SubJurisdiction } from '../../models';

export const validateMap = new Map<string, string[]>();

validateMap.set('federal.permanent', ['Trial Balance', 'Account Description', 'Amount', 'Percentage Disallowed']);
validateMap.set('federal.temporary.incomeStatement', [
  'Trial Balance',
  'Account Description',
  'Book Balance',
  'Tax Balance'
]);
validateMap.set('federal.temporary.balanceSheet', [
  'Trial Balance',
  'Account Description',
  'Beginning Balance',
  'Beginning Payments',
  'Ending Balance',
  'Ending Payments'
]);
validateMap.set('federal.nol', [
  'Tax Period',
  'Beginning Balance',
  'Return to Provision',
  'Amount Generated in Current Year',
  'Amount (Used) in Current Year',
  'Deferred Only Adjustment',
  'Balance Sheet Only Adjustment',
  'Carry Over Period Remaining'
]);
validateMap.set('state.apportionment', ['State', 'Apportionment']);
validateMap.set('state.apportionment-flagged', ['State', 'Beginning', 'Current', 'End']);
validateMap.set('state.nol', [
  'Tax Period',
  'Beginning Balance',
  'Return to Provision',
  'Amount Generated in Current Year',
  'Amount (Used) in Current Year',
  'Deferred Only Adjustment',
  'Balance Sheet Only Adjustment',
  'Carry Over Period Remaining'
]);
validateMap.set('federal.tax-effected', ['Account Description', 'Amount']);
validateMap.set('federal.rtp', ['Name', 'Tax Provision', 'Tax Return']);
validateMap.set('state.rtp', ['Name', 'Tax Provision', 'Tax Return']);
validateMap.set('state.tax-effected', ['Adjustment Name', 'State', 'Amount']);
validateMap.set('state.credits', [
  'Credit Name',
  'Tax Period',
  'Beginning Balance',
  'Return to Provision',
  'Amount Generated in Current Year',
  'Amount (Used) in Current Year',
  'Deferred Only Adjustment',
  'Balance Sheet Only Adjustment',
  'Carry Over Period Remaining'
]);
validateMap.set('state.modifications', ['Modification Type', 'Account Description', 'Amount', 'State']);
validateMap.set('federal.deferred', [
  'Adjustment Name',
  'Beginning Balance',
  'Deferred Only Adjustment',
  'Balance Sheet Only Adjustment'
]);
validateMap.set('federal.credits', [
  'Credit Name',
  'Tax Period',
  'Beginning Balance',
  'Return to Provision',
  'Amount Generated in Current Year',
  'Amount (Used) in Current Year',
  'Deferred Only Adjustment',
  'Balance Sheet Only Adjustment',
  'Carry Over Period Remaining'
]);
validateMap.set('state.deferred', [
  'State',
  'Adjustment Name',
  'Beginning Balance',
  'Deferred Only Adjustment',
  'Balance Sheet Only Adjustment'
]);

export const TABS_TO_FILTER_FINANCIAL_INFO_BY_SUBJURISDICTION: Record<Level, Partial<Record<Step, boolean>>> = {
  federal: {},
  state: {
    rtp: true,
    nol: true,
    credits: true
  },
  discrete: {}
};

export const COMBINED_STATE_REGEX = /\s*[–-]\s*combined\s*/gi;

export const formatSubjurisdictionName = (stateName: string): string => {
  return stateName?.replace(COMBINED_STATE_REGEX, '').trim();
};

export const parseSubjurisdictionsByName = (
  subjurisdictions: SubJurisdiction[] = []
): Record<string, SubJurisdiction> => {
  const response: Record<string, SubJurisdiction> = {};

  for (const subjurisdiction of Object.values(subjurisdictions)) {
    response[formatSubjurisdictionName(subjurisdiction.name).toLowerCase()] = subjurisdiction;
  }

  return response;
};
