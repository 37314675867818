import { useEffect, useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

import { useContainers } from '../../hooks';
import { selectAuthInfo } from '../../selectors/authentication';
import { selectConfiguration } from '../../selectors/configuration';
import store from '../../store';

interface LaunchDarklyProviderProps {
  children: JSX.Element;
}
let LDProvider: any;

const LaunchDarklyProvider = ({ children }: LaunchDarklyProviderProps) => {
  const { userId } = selectAuthInfo(store.getState());
  const { currentContainer } = useContainers();
  const {
    xbsEnvironment,
    launchDarklyDevelopId,
    launchDarklyStagingId,
    launchDarklyProductionId
  } = selectConfiguration(store.getState());
  const [isLdConfigActive, setIsLdConfigActive] = useState(false);

  const initializeLaunchDarkly = async (launchDarklyId: string, userId?: string, containerId?: string) => {
    LDProvider = await asyncWithLDProvider({
      clientSideID: launchDarklyId,
      user: {
        key: userId,
        country: containerId
      }
    });
    setIsLdConfigActive(true);
  };

  useEffect(() => {
    if (!isLdConfigActive) {
      let launchDarklyId = launchDarklyProductionId;
      if (xbsEnvironment === 'develop') launchDarklyId = launchDarklyDevelopId;
      if (xbsEnvironment === 'staging') launchDarklyId = launchDarklyStagingId;
      if (xbsEnvironment === 'production') launchDarklyId = launchDarklyProductionId;
      if (userId) {
        void initializeLaunchDarkly(launchDarklyId, userId.toString(), currentContainer?.containerId);
      }
    }
  }, [
    isLdConfigActive,
    xbsEnvironment,
    launchDarklyDevelopId,
    launchDarklyStagingId,
    launchDarklyProductionId,
    userId,
    currentContainer
  ]);

  return isLdConfigActive ? <LDProvider>{children}</LDProvider> : <CircularProgress />;
};

export default LaunchDarklyProvider;
