import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  circle: {
    fill: theme.palette.info.dark
  },
  line: {
    stroke: theme.palette.info.dark,
    strokeWidth: '2px'
  }
}));

export const XConnector = () => {
  const classes = useStyles();

  return (
    <svg version="1.1" width="100%" height="50" xmlns="http://www.w3.org/2000/svg">
      <line className={classes.line} x1="50%" y1="0" x2="50%" y2="100%" />
      <circle className={classes.circle} cx="50%" cy="50%" r="12px" />
      <line x1="48.75%" y1="40%" x2="51.25%" y2="60%" stroke="white" strokeWidth="2px" />
      <line x1="51.25%" y1="40%" x2="48.75%" y2="60%" stroke="white" strokeWidth="2px" />
    </svg>
  );
};
