import { useCallback, useEffect, useState } from 'react';

import { Box, TextField, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CheckBoxOutlineBlank, CheckBoxRounded, Search } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';

import SelectorLayout from './SelectorLayout';

import theme from '../../../../../../theme';
import { customFilterOptions } from '../../../NewCategoryDrawer/utils';

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: '12px'
  },
  option: {
    display: 'flex',
    width: '100%',
    heigh: '36px',
    alignItems: 'center',
    padding: '0',
    boxSizing: 'border-box',
    '& .number': {
      flexBasis: '20%'
    },
    '& .description': {
      flexBasis: '60%'
    },
    '& .count': {
      flexBasis: '10%',
      textAlign: 'center'
    },
    '& .checkbox-container': {
      flexBasis: '10%',
      height: '37px'
    },
    '& .check': {
      '&.disabled': {
        display: 'none'
      }
    }
  },
  checkbox: {
    color: theme.palette.info.dark,
    height: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
    '&.disabled': {
      display: 'none'
    }
  },
  textField: {
    minWidth: '397px',
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.common.white,
      height: '45px'
    },
    '& .MuiInputBase-adornedStart > span.MuiAutocomplete-tag': {
      paddingLeft: '10px'
    },
    '& .MuiAutocomplete-endAdornment': {
      paddingRight: '18px',
      '& .MuiAutocomplete-popupIndicator': {
        pointerEvents: 'none',
        margin: theme.spacing(0, 0.5),
        transform: 'none'
      }
    }
  }
}));

export type SearchboxElementType = {
  number?: string;
  name: string;
  count: number;
  isDisabled?: boolean;
  prevSelected?: boolean;
  stepName?: string;
};

interface Props {
  [key: string]: any;
  onSelect: (selected: any) => void;
  options: SearchboxElementType[];
  placeholder?: string;
  isLoading?: boolean;
  value: SearchboxElementType[];
  isDisabled?: boolean;
}

const SearchboxSelector = ({
  options,
  onSelect,
  placeholder,
  isLoading = false,
  value,
  isDisabled,
  ...props
}: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<SearchboxElementType[]>(value);
  const [inputValue, setInputValue] = useState('');

  props.onInputChange = (event: any, value: any, reason: any) => {
    if (event && event.type === 'blur') {
      setInputValue('');
    } else if (reason !== 'reset') {
      setInputValue(value);
    }
  };

  props.inputValue = inputValue;

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const openDropdown = () => {
    if (!isDisabled) {
      setOpen(true);
    }
  };

  const onChange = (event: any, values: any) => {
    setSelected(values);
  };

  const selectHandler = useCallback(() => {
    onSelect(selected);
    setOpen(false);
  }, [onSelect, selected]);

  const cancelHandler = useCallback(() => {
    setSelected(value);
    setOpen(false);
    onSelect(value);
  }, [onSelect, value]);

  const handleCheckboxClick = (option: SearchboxElementType) => (event: React.MouseEvent) => {
    event.stopPropagation();

    setSelected((prevSelected) => {
      if (prevSelected.some((s) => s.name === option.name)) {
        return prevSelected.filter((s) => s.name !== option.name);
      }

      return [...prevSelected, option];
    });
  };

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      renderTags={() => null}
      id="searchbox-selector"
      options={options}
      getOptionLabel={(option) => option.name}
      filterOptions={customFilterOptions}
      open={open}
      getOptionDisabled={(option) => Boolean(option.isDisabled)}
      renderOption={(option) => (
        <Box key={option.name} className={classes.option}>
          <Box className="number">{option.number}</Box>
          <Box className="description">{option.name}</Box>
          <Box className="count">{option.count}</Box>
          <Box className="checkbox-container">
            <Checkbox
              checked={selected.some((s) => s.name === option.name)}
              checkedIcon={<CheckBoxRounded className={classes.checkbox} />}
              icon={<CheckBoxOutlineBlank className={classes.checkbox} />}
              className={`check${option.isDisabled ? ' disabled' : ''}`}
              onClick={handleCheckboxClick(option)}
            />
          </Box>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          className={classes.textField}
          onClick={openDropdown}
          {...params}
          placeholder={placeholder}
          variant="outlined"
        />
      )}
      PaperComponent={({ children, ...props }) => (
        <SelectorLayout isLoading={isLoading} onClose={cancelHandler} onSelect={selectHandler} {...props}>
          {children}
        </SelectorLayout>
      )}
      popupIcon={<Search />}
      value={selected}
      disabled={isDisabled}
      onFocus={openDropdown}
      onChange={onChange}
      onBlur={cancelHandler}
      {...props}
    />
  );
};

export default SearchboxSelector;
