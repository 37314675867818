export const SET_AUTH_TOKENS = 'SET_AUTH_TOKENS';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_LOGOUT_FAILURE = 'AUTH_LOGOUT_FAILURE';

export interface AuthStateInterface {
  authToken?: string;
  expiration?: Date;
  refreshToken?: string;
  lastRefreshDate?: number;
  email?: string;
  userId?: number;
  roleId?: number;
  roleUuid?: string;
  isXBSEmployee?: boolean;
}

export interface SetAuthTokensAction {
  type: typeof SET_AUTH_TOKENS;
  payload: AuthStateInterface;
}

export type AuthActionTypes = SetAuthTokensAction;
