import { useTranslation } from 'react-i18next';

import { Box, Table, TableBody, TableCell, TableRow, makeStyles } from '@material-ui/core';

import { formatNumber } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'inline-block',
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(1, 1.5),
    marginTop: theme.spacing(2),
    alignSelf: 'flex-start',
    width: (props: any) => props.width
  },
  table: {
    '& .MuiTableCell-body': {
      borderBottomColor: theme.palette.text.primary,
      backgroundColor: theme.palette.grey[50],
      fontFamily: theme.typography.h3.fontFamily,
      fontWeight: theme.typography.h3.fontWeight,
      padding: theme.spacing(1, 0)
    },
    '& .MuiTableRow-root:not(:nth-child(3)) .MuiTableCell-body': {
      borderBottom: 'none'
    }
  }
}));

interface Props {
  width: string;
  total: {
    taxedBeginningBalance: number;
    taxedEndingBalance: number;
    taxedBalanceSheetOnlyAdjustment: number;
  };
}

const DeferredSummaryTable = ({ total, width }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles({ width });

  return (
    <Box className={classes.container}>
      <Table classes={{ root: classes.table }}>
        <TableBody>
          <TableRow>
            <TableCell>{t('Beginning Balance')}</TableCell>
            <TableCell align="right">{formatNumber(total.taxedBeginningBalance)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t('deferred-rollforward-balance-sheet-only')}</TableCell>
            <TableCell align="right">{formatNumber(total.taxedBalanceSheetOnlyAdjustment)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t('deferred-rollforward-ending-balance')}</TableCell>
            <TableCell align="right">{formatNumber(total.taxedEndingBalance)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t('deferred-rollforward-tax-expense')}</TableCell>
            <TableCell align="right">
              {formatNumber(
                total.taxedBeginningBalance + total.taxedBalanceSheetOnlyAdjustment - total.taxedEndingBalance
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default DeferredSummaryTable;
