import { useTranslation } from 'react-i18next';

import { Box, Table, TableBody, TableCell, TableRow, makeStyles } from '@material-ui/core';

import { TotalsRow } from '../../../../models';
import { formatNumber } from '../../../../utils';

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'inline-block',
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(1, 1.5),
    marginTop: theme.spacing(2),
    alignSelf: 'flex-start',
    width: (props: any) => props.width
  },
  table: {
    '& .MuiTableCell-body': {
      borderBottomColor: theme.palette.text.primary,
      backgroundColor: theme.palette.grey[50],
      fontFamily: theme.typography.h3.fontFamily,
      fontWeight: theme.typography.h3.fontWeight,
      padding: theme.spacing(1, 0)
    },
    '& .MuiTableRow-root:not(:nth-last-child(2)) .MuiTableCell-body': {
      borderBottom: 'none'
    }
  }
}));

const ReportTotalsTable = ({ rows, width }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles({ width });

  return (
    <Box className={classes.container}>
      <Table classes={{ root: classes.table }} data-testid="report-totals-table">
        <TableBody>
          {rows.map(({ name, value }) => (
            <TableRow key={name} data-testid={`report-totals-table-${name}`}>
              <TableCell>{t(name)}</TableCell>
              <TableCell align="right">{formatNumber(value, 0)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

type Props = {
  rows: TotalsRow[];
  width: string;
};

export default ReportTotalsTable;
