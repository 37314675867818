import { useEffect, useRef, useState } from 'react';

// https://wattenberger.com/blog/react-and-d3#sizing-responsivity
export function useDimensions() {
  const wrapperRef = useRef<Element>();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const element = wrapperRef.current;
    const resizeObserver = new ResizeObserver((entries) => {
      if (Array.isArray(entries) && entries.length > 0) {
        const { contentRect } = entries[0];
        if (width !== contentRect.width) {
          setWidth(contentRect.width);
        }

        if (height !== contentRect.height) {
          setHeight(contentRect.height);
        }
      }
    });
    if (element) {
      resizeObserver.observe(element);
    }

    return () => {
      if (element) {
        resizeObserver.unobserve(element);
      }
    };
    // run this hook on initialization only
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { width, height, wrapperRef };
}
