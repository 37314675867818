import { makeStyles } from '@material-ui/core';

import theme from '../../../../theme';

const useStyles = makeStyles(() => ({
  balanceButton: {
    height: '36px',
    width: '87px',
    boxSizing: 'border-box',
    border: '1px solid',
    borderColor: theme.palette.info.dark,
    borderRadius: '4px',
    color: theme.palette.primary.main,
    letterSpacing: '0.21px',
    lineHeight: '18px'
  },
  dialogActions: {
    justifyContent: 'space-between',
    padding: '21px 16px'
  },
  dialogContent: {
    padding: 0
  },
  dialogHeading: {
    color: theme.palette.text.dark,
    fontFamily: theme.typography.h3.fontFamily,
    fontSize: theme.typography.h3.fontSize,
    fontWeight: theme.typography.h3.fontWeight,
    letterSpacing: theme.typography.h3.letterSpacing,
    lineHeight: '22px',
    paddingBottom: '8px',
    paddingLeft: '16.5px',
    paddingTop: '13px'
  },
  dialogtext1: {
    color: theme.palette.text.primary,
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight,
    letterSpacing: theme.typography.body2.letterSpacing,
    lineHeight: '18px',
    paddingLeft: '16.5px'
  },
  dialogtext2: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight,
    letterSpacing: theme.typography.body2.letterSpacing,
    lineHeight: '18px',
    paddingLeft: '16.5px',
    paddingBottom: '8px'
  },
  paper: {
    width: '298px',
    height: '234px',
    backgroundColor: theme.palette.common.white,
    borderBottom: 'none',
    borderRadius: '4px',
    boxShadow: '0 0 8px 0 rgba(0,0,0,0.28)'
  },
  varianceBoxContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  varianceBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.light,
    height: '44px',
    width: '265px',
    borderBottom: '1px solid',
    borderColor: theme.palette.text.secondary,
    paddingLeft: '12.5px',
    paddingRight: '15px'
  },
  varianceIcon: {
    paddingTop: '6px'
  },
  varianceTextContainer: {
    paddingTop: '6px',
    paddingBottom: '4px',
    paddingRight: '28.5px'
  },
  varianceText: {
    color: theme.palette.text.secondary,
    letterSpacing: theme.typography.h3.letterSpacing,
    lineHeight: '18px',
    textAlign: 'right'
  },
  varianceValueText: {
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    lineHeight: '18px'
  },
  viewButton: {
    height: '36px',
    width: '63px',
    boxSizing: 'border-box',
    border: '1px solid',
    borderColor: theme.palette.info.dark,
    borderRadius: '4px',
    color: theme.palette.primary.main,
    letterSpacing: '0.21px',
    lineHeight: '18px'
  }
}));

export { useStyles };
