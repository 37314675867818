import { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Box, makeStyles } from '@material-ui/core';

import { TableWithPlot, TabButtons } from '.';

import { SummaryEntityDataRow } from '../../../models';
import theme from '../../../theme';
import { abbreviateNumber, formatCurrency } from '../../../utils';
import { Tab, totalTab } from '../utils';
import { filterRowsByTab } from '../utils/data-processing/parseDashboardReport';

const useStyles = makeStyles((theme) => ({
  legend: {
    display: 'inline-block',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '50%',
    height: 10,
    width: 10,
    marginRight: theme.spacing(0.5)
  }
}));

export const totalColor = theme.palette.primary.main;
export const currentColor = 'rgba(232,247,247,0.7)';
export const deferredColor = 'rgba(229,243,250,0.7)';

interface TotalProvisionProps {
  reportEntityRows: SummaryEntityDataRow[];
}

const TotalProvision = ({ reportEntityRows }: TotalProvisionProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [tab, setTab] = useState<Tab>(totalTab);

  const { rows, columns } = useMemo(() => {
    const fieldPrefix = tab === totalTab ? tab : `${tab}Total`;

    const cols = [
      {
        placeholder: t('Entity'),
        filterable: true,
        sortable: true,
        field: 'name',
        width: '12%'
      },
      {
        renderHeader: () => [
          <Box
            key={1}
            className={classes.legend}
            style={{ backgroundColor: totalColor, border: `1px solid ${totalColor}` }}
          />,
          t('Total')
        ],
        field: `${fieldPrefix}OverallProvTab`,
        sortable: true,
        isCurrency: true,
        width: '12%'
      },
      {
        renderHeader: () => [
          <Box
            key={1}
            className={classes.legend}
            style={{ backgroundColor: currentColor, border: `2px solid #45D1AA` }}
          />,
          t('Current')
        ],
        field: `${fieldPrefix}CurrentProvTab`,
        sortable: true,
        isCurrency: true,
        width: '12%'
      },
      {
        renderHeader: () => [
          <Box
            key={1}
            className={classes.legend}
            style={{ backgroundColor: deferredColor, border: `2px solid #409BC7` }}
          />,
          t('Deferred')
        ],
        field: `${fieldPrefix}DeferredProvTab`,
        sortable: true,
        isCurrency: true,
        width: '12%'
      },
      {
        series: [
          {
            field: `${fieldPrefix}OverallProvTab`,
            color: totalColor
          },
          { field: `${fieldPrefix}CurrentProvTab`, color: currentColor },
          { field: `${fieldPrefix}DeferredProvTab`, color: deferredColor }
        ],
        formatTick: (value: any) => abbreviateNumber(value ? formatCurrency(value) : '0'),
        isCurrency: true
      }
    ];
    return {
      columns: cols,
      rows: filterRowsByTab(reportEntityRows, tab),
      sort: { column: tab === totalTab ? cols[1] : null, dir: TableWithPlot.ASC }
    };
  }, [t, tab, reportEntityRows, classes.legend]);

  return (
    <>
      <TabButtons tab={tab} worldLabel={t('Total Provision')} onChange={setTab} />
      <TableWithPlot
        columns={columns}
        filters={new Map()}
        isNotEditableShaded={false}
        rows={rows.map((entry) => (typeof (entry as any).value === 'string' ? { ...entry, value: 0 } : entry))}
        variant="border-cell"
        barHeight="8px"
      />
    </>
  );
};

export default TotalProvision;
