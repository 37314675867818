import { ReactInstance, ReactNode, ComponentType } from 'react';

import Box from '@material-ui/core/Box';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    backgroundColor: theme.palette.primary.light,
    paddingLeft: 0,
    paddingRight: 0
  },
  paper: {
    borderRadius: theme.shape.borderRadius,
    padding: 0,
    width: theme.spacing(60)
  },
  headerIcon: {
    marginRight: theme.spacing(0.8)
  },
  headerWrapper: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(0.8, 2.5),
    '& > *': {
      width: '100%',
      display: 'flex',
      alignItems: 'center'
    }
  },
  modalTitle: {
    flexGrow: 1
  },
  dialogActionsWrapper: {
    display: 'flex',
    width: '100%'
  },
  actionsWrapper: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    padding: theme.spacing(0.8, 2.5),
    width: '50%'
  },
  leftActionsWrapper: {
    justifyContent: 'left'
  },
  rightActionsWrapper: {
    justifyContent: 'right'
  },
  rightButtons: {
    width: '50%'
  },
  leftButtons: {
    width: '50%'
  }
}));

interface HeaderIconProps {
  color: string;
  className: string;
}

export interface ModalActions extends ButtonProps {
  name: string;
  handler: () => void;
}

export interface Props {
  children: ReactNode;
  headerContent?: ReactNode;
  headerIcon?: ComponentType<HeaderIconProps>;
  closeIcon?: ComponentType;
  leftActions?: ModalActions[];
  rightActions?: ModalActions[];
  isOpen: boolean;
  title: string | ReactNode;
  maxWidth?: 'lg' | 'xs' | 'sm' | 'md' | 'xl';
  container?: ReactInstance | (() => ReactInstance | null);
  onClose: () => void;
}

const ProModal = ({
  children,
  container,
  headerContent,
  headerIcon: HeaderIcon,
  leftActions = [],
  rightActions = [],
  title,
  isOpen,
  maxWidth,
  closeIcon: CustomCloseIcon,
  onClose
}: Props) => {
  const classes = useStyles();

  const hasContainer = Boolean(container);

  return (
    <Dialog
      fullWidth
      aria-labelledby="provision-modal"
      aria-describedby="provision-generic-modal"
      open={isOpen}
      maxWidth={maxWidth ?? 'sm'}
      scroll="paper"
      classes={{ paper: classes.paper }}
      container={container}
      style={hasContainer ? { position: 'absolute' } : {}}
      BackdropProps={hasContainer ? { style: { position: 'absolute' } } : {}}
      disablePortal={hasContainer}
      disableEnforceFocus={hasContainer}
      disableBackdropClick={!hasContainer}
      onClose={onClose}
    >
      <DialogTitle classes={{ root: classes.headerWrapper }}>
        {HeaderIcon && <HeaderIcon className={classes.headerIcon} color="primary" />}
        <Typography color="primary" component="span" className={classes.modalTitle} variant="h1">
          {title}
        </Typography>
        {headerContent}
        <IconButton onClick={onClose}>{CustomCloseIcon ? <CustomCloseIcon /> : <Close />}</IconButton>
      </DialogTitle>
      <DialogContent dividers classes={{ dividers: classes.dialogContent }}>
        {children}
      </DialogContent>
      <Box className={classes.dialogActionsWrapper}>
        <DialogActions className={classnames(classes.actionsWrapper, classes.leftActionsWrapper)}>
          {leftActions.map(({ name, handler, ...buttonProps }) => (
            <Button key={name} onClick={handler} {...buttonProps}>
              {name}
            </Button>
          ))}
        </DialogActions>
        <DialogActions className={classnames(classes.actionsWrapper, classes.rightActionsWrapper)}>
          {rightActions.map(({ name, handler, ...buttonProps }) => (
            <Button key={name} onClick={handler} {...buttonProps}>
              {name}
            </Button>
          ))}
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ProModal;
