import * as d3 from 'd3';

// https://levelup.gitconnected.com/react-d3-rendering-a-map-5e006b2f4895
// https://www.d3indepth.com/geographic/
export function drawGeoJson(
  svgRef: any,
  { width, height, colors, data, zoomInRef, zoomOutRef, onEnter, onLeave }: any
) {
  const zoomed = ({ transform }: any) => g.style('stroke-width', `${1.5 / transform.k}px`).attr('transform', transform);
  const zoom = d3.zoom().scaleExtent([1, 8]).on('zoom', zoomed);

  const svg = d3.select(svgRef).attr('viewBox', [0, 0, width, height] as any);
  svg.selectAll('g').remove();
  const g = svg.append('g');

  const projection = d3.geoNaturalEarth1().fitSize([width, height], data);

  const geoGenerator = d3.geoPath().projection(projection);

  g.selectAll('.country')
    .data(data.features)
    .join('path')
    .attr('width', width)
    .attr('height', height)
    .attr('class', ({ properties: { selected } }: any) => `country ${selected ? 'selected' : ''}`)
    .attr('fill', ({ properties: { selected } }: any) => (selected ? colors.selectedBackground : colors.background))
    .attr('stroke', ({ properties: { selected } }: any) => (selected ? colors.selectedBorder : colors.border))
    .on('mousemove', (event: any, { properties }: any) => {
      if (properties.selected) {
        onEnter(event, properties);
      }
    })
    .on('mouseout', (event: any, { properties }: any) => {
      if (properties.selected) {
        onLeave(event, properties);
      }
    })
    .attr('d', geoGenerator as any);

  svg.call(zoom);

  d3.select(zoomInRef.current).on('click', () => svg.transition().call(zoom.scaleBy, 2));
  d3.select(zoomOutRef.current).on('click', () => svg.transition().call(zoom.scaleBy, 0.5));
}
