import { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ContentAndTitle, StateSelector, TabTitle } from '../';
import { LEVELS } from '../../../../constants';
import { useCompletionStatus, useCurrencies, useFinancialData } from '../../../../hooks';
import { StateTabProps, Step, SubJurisdictionWithCompletion } from '../../../../models';
import { setEntityCompletionStatus } from '../../../../redux/entitiesCompletionStatus';
import LoadingWrapper from '../../../LoadingWrapper';
import BeginningBalance from '../../../SharedComponents/FederalOrStateAccounts/components/BeginningBalance';
import CurrentYearPayments from '../../../SharedComponents/FederalOrStateAccounts/components/CurrentYearPayments';
import InterestAndPenalties from '../../../SharedComponents/FederalOrStateAccounts/components/InterestAndPenalties';
import OtherAdjustments from '../../../SharedComponents/FederalOrStateAccounts/components/OtherAdjustments';
import PriorYearPayments from '../../../SharedComponents/FederalOrStateAccounts/components/PriorYearPayments';
import { TaxPeriodRow } from '../../models';
import { EntityNumberRouteMatch, getRowNamesFromLevelSteps, getTableDataFromFinancialData } from '../../utils';

const LEVEL = LEVELS.STATE;
const STEP: Step = 'accounts';
const STEPS = [STEP] as Step[];
const LevelAndStep = `${LEVEL}.${STEP}`;

const useStyles = makeStyles(() => ({
  stateAccountsBox: {
    overflow: 'auto',
    '& .MuiTableCell-body.no-rows': {
      display: 'none'
    }
  },
  tableRoot: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'auto',
    '& .MuiTableCell-body.no-rows': {
      display: 'none'
    }
  }
}));

const StateAccounts = ({ entityId, states }: StateTabProps) => {
  const { t } = useTranslation();
  const [currentState, setCurrentState] = useState<SubJurisdictionWithCompletion>();
  const [statesWithCompletion, setStatesWithCompletion] = useState<SubJurisdictionWithCompletion[]>([]);
  const { currencyByEntityIdMap } = useCurrencies();
  const currencyIsoCode = currencyByEntityIdMap[entityId]?.isoCode;
  const { tableRoot } = useStyles();

  const [rows, setRows] = useState<TaxPeriodRow[]>([]);
  const [hasNewRow, setHasNewRow] = useState(false);
  const dispatch = useDispatch();
  const {
    params: { entityNumber }
  } = useRouteMatch<EntityNumberRouteMatch>();
  const { entityCompletionStatus: currentEntity, stepCompletionStatus } = useCompletionStatus(
    entityNumber,
    LevelAndStep,
    currentState?.id
  );
  const { completionStatus: stateCompletionStatus } = currentEntity;

  const { tabsData, failedCells, isFetchLoading } = useFinancialData(entityNumber, LEVEL, STEPS, currentState?.id);

  const financialInfo = useMemo(() => tabsData[LevelAndStep] ?? [], [tabsData]);

  const rowNames = useMemo(() => getRowNamesFromLevelSteps(tabsData, LEVEL, STEPS)[STEP], [tabsData]);
  const rowsWithData = useMemo(() => getTableDataFromFinancialData(rowNames, financialInfo), [
    financialInfo,
    rowNames
  ]) as TaxPeriodRow[];

  const newRow = rows[rows.length - 1];
  const rowsToSet = useMemo(() => [...rowsWithData, ...(newRow?.isNew ? [newRow] : [])], [newRow, rowsWithData]);

  useEffect(() => {
    setRows(rowsToSet);
  }, [rowsToSet]);

  useEffect(() => {
    const withCompletion = states?.map((state) => ({
      ...state,
      isCompleted: Boolean(stateCompletionStatus?.state?.accounts?.[state.id])
    }));

    setStatesWithCompletion(withCompletion);

    if (!currentState) {
      setCurrentState(withCompletion[0]);
    }
  }, [states, currentState, stateCompletionStatus]);

  return (
    <LoadingWrapper isLoading={isFetchLoading}>
      <ContentAndTitle
        title={
          currentState ? (
            <TabTitle
              currencyIsoCode={currencyIsoCode}
              title={
                <div data-testid="tab-title">
                  {t('accounts')}
                  <Box component="span" ml={2}>
                    {currentState?.name}
                  </Box>
                </div>
              }
              isCompleted={stepCompletionStatus.status}
              shouldDisplayDataImportButton={false}
              onCompletionChange={(checked) => {
                dispatch(
                  setEntityCompletionStatus({
                    ...stepCompletionStatus,
                    newStatus: checked
                  })
                );
              }}
            />
          ) : (
            ''
          )
        }
      >
        <Box display="flex" overflow="hidden">
          <StateSelector
            data-testid="state-selector"
            states={statesWithCompletion}
            currentState={currentState}
            onSelect={(state) => {
              if (hasNewRow) {
                setRows(rows.filter(({ isNew }) => !isNew));
                setHasNewRow(false);
              }

              setCurrentState(state);
            }}
          />
          <Box className={tableRoot}>
            <CurrentYearPayments
              isFederal={false}
              currentStateId={currentState?.id}
              entityId={entityId}
              failedCells={failedCells}
              states={states}
              stepCompletionStatus={stepCompletionStatus.status}
              tabsData={tabsData}
            />
            <BeginningBalance
              isFederal={false}
              currentStateId={currentState?.id}
              entityId={entityId}
              failedCells={failedCells}
              states={states}
              stepCompletionStatus={stepCompletionStatus.status}
              tabsData={tabsData}
            />
            <PriorYearPayments
              isFederal={false}
              currentStateId={currentState?.id}
              entityId={entityId}
              failedCells={failedCells}
              states={states}
              stepCompletionStatus={stepCompletionStatus.status}
              tabsData={tabsData}
            />
            <InterestAndPenalties
              isFederal={false}
              currentStateId={currentState?.id}
              entityId={entityId}
              failedCells={failedCells}
              states={states}
              stepCompletionStatus={stepCompletionStatus.status}
              tabsData={tabsData}
            />
            <OtherAdjustments
              isFederal={false}
              currentStateId={currentState?.id}
              entityId={entityId}
              failedCells={failedCells}
              states={states}
              stepCompletionStatus={stepCompletionStatus.status}
              tabsData={tabsData}
            />
          </Box>
        </Box>
      </ContentAndTitle>
    </LoadingWrapper>
  );
};

export default StateAccounts;
