import i18next from 'i18next';

import { renderCell } from './components/TableBody';
import { Column, FailedCells, Row, TableProps } from './Table.proptype';

import { CategoryFromApi, Container } from '../../models';
import { formatCurrency, formatNumber, formatPercentage } from '../../utils/numbers';
import { pickCategoryForRow, categorizableEntityDetailsCell } from '../../utils/table';
import { sortRowsByName } from '../Reports/utils';
import { getSpecialFormatConditions } from '../Reports/utils/data-processing/handleReportLineItemValueFormatting';
import isUUID from '../Reports/utils/isUUID';
import TextTooltip from '../TextTooltip/';

let collator: any = null;

function initCollator() {
  collator = new Intl.Collator(i18next.language, { numeric: true });
}

i18next.on('languageChanged', initCollator);
initCollator();

export const ASC = 'asc';
export const DESC = 'desc';

export const OUTLINED = 'outlined';
export const FLAT = 'flat';
export const STYLED_CELL = 'border-cell';

const overrideForSpecificCellFormat = (column: Column, row?: Row, value?: any, reportName?: string) => {
  if (!value) {
    return false;
  }

  if (
    getSpecialFormatConditions({
      isRetrievingFormat: false,
      reportName,
      columnName: column?.field,
      rowName: row?.name,
      value
    })
  ) {
    return true;
  }

  return false;
};

interface getIsCustomPercentageCellProps {
  column: Column;
  value: any;
  row?: Row;
  reportName?: string;
}
const getIsCustomPercentageCell = ({ column, value, row, reportName }: getIsCustomPercentageCellProps) => {
  if (reportName && overrideForSpecificCellFormat(column, row, value, reportName)) {
    return;
  }

  const columnFieldSplitted = column.field?.split('_');
  const uuidLessFieldName = columnFieldSplitted?.filter((part) => !isUUID(part)).join('_');
  const percentageFieldsIncludesName = row?.percentageFields?.includes(uuidLessFieldName ?? '');
  const isCustomPercentageCell = uuidLessFieldName && percentageFieldsIncludesName;
  return isCustomPercentageCell;
};

// eslint-disable-next-line max-params
export function renderValue(
  value: any,
  column: Column,
  { digits, zeroValue, currency, numberOfDecimals }: any = {},
  row?: Row,
  reportName?: string
) {
  const isCustomPercentageCell = getIsCustomPercentageCell({ column, value, row, reportName });
  const formatPercentageLogic = formatPercentage(value, '-', 4);
  return isCustomPercentageCell
    ? formatPercentageLogic
    : column?.isNumber
    ? formatNumber(value, digits, zeroValue, column?.shouldAbbreviate, numberOfDecimals || 0)
    : column?.isPercentage
    ? formatPercentageLogic
    : column?.isCurrency
    ? formatCurrency(value, currency, zeroValue)
    : value;
}

export function contains(text: any, seed: any) {
  return !text || !seed || text.toLowerCase().includes(seed.toLowerCase());
}

export function getCell(row: Row, { getValue, field }: any) {
  return getValue ? getValue(row) : row[field];
}

export function getAlignment({ isCurrency, isNumber, isPercentage }: any) {
  return isNumber || isPercentage || isCurrency ? 'right' : 'inherit';
}

const cellIsEditable = ({ column, row, rows }: { column: Column; row: Row; rows?: Row[] }): boolean => {
  const { field = '', isEditable, isNewRowEditable } = column;
  const hasNewRow = rows?.some((row) => row.isNew);

  return Boolean(
    (isEditable || (isNewRowEditable && row.isNew) || (row.isEditable && field !== 'name')) &&
      (!rows || row.isNew || !hasNewRow) &&
      !row.nonEditableFields?.includes(field)
  );
};

export function getClassName({
  column,
  row,
  classes,
  isActiveRow,
  isActiveColumn,
  rows
}: {
  column: Column;
  row: Row | Record<any, any>;
  classes: Record<string, any>;
  isActiveRow?: boolean;
  isActiveColumn?: boolean;
  rows?: Row[];
}) {
  const { divider, sticky, className, sortable, filterable } = column;
  const classNames = [];
  if (divider) {
    classNames.push(classes.divider);
  }

  if (cellIsEditable({ column, row, rows })) {
    classNames.push('editable');
  }

  if (sticky) {
    classNames.push('sticky');
  }

  if (sortable && !filterable && getAlignment(column) === 'right') {
    classNames.push('sortable-numerical');
  }

  if (isActiveColumn) {
    classNames.push('active-column');
  }

  if (isActiveRow) {
    classNames.push('active-row');
  }

  if (className) {
    classNames.push(className);
  }

  if (row.className) {
    classNames.push(row.className);
  }

  return classNames.join(' ');
}

const categorizableReportCell = (row: Row, column: Column) => (
  <div key={row.category ? row.category.categoryId : row.name}>
    {row.category && (
      <div className="float-left" style={{ color: row.category.color }}>
        <TextTooltip color={row.category.color} categoryId={row.category.categoryId} />
      </div>
    )}
    {getCell(row, column)}
  </div>
);

export function renderCategorizableCell(view = 'entity-details', currentContainer?: Container) {
  return (
    row: Row,
    value: number,
    column: Column,
    failedCells: FailedCells,
    onCellChange: TableProps['onCellChange'],
    onCellOrCommentBlur: TableProps['onCellOrCommentBlur'],
    renderOpts: any,
    rows: Row[],
    reportName?: string,
    onCellClick?: TableProps['onCellClick']
    // eslint-disable-next-line max-params
  ) => {
    if (column.field === 'name') {
      switch (view) {
        case 'reports':
          return categorizableReportCell(row, column);
        case 'entity-details':
        default:
          return categorizableEntityDetailsCell(row, column, currentContainer);
      }
    }

    if (!getCell(row, column) && view === 'reports') {
      return '';
    }

    const isNonSectionHeaderRow = row.className !== 'section-header';

    return renderCell(
      row,
      value,
      column,
      failedCells,
      onCellChange,
      onCellOrCommentBlur,
      renderOpts,
      rows,
      reportName,
      onCellClick,
      isNonSectionHeaderRow
    );
  };
}

export function categorizeRows(
  rows: TableProps['rows'],
  categoriesByAccount: Record<string, CategoryFromApi[]>,
  currentContainer?: Container
) {
  return (
    rows?.map((row: Row) => {
      if (row.name && Object.keys(categoriesByAccount).length > 0 && !row.isNew && row.categorizable !== false) {
        row.category = pickCategoryForRow(row, categoriesByAccount);
        row.renderCell = renderCategorizableCell('entity-details', currentContainer);
      }

      return row;
    }) ?? []
  );
}

export function groupReportsCategoryRows(rows: TableProps['rows'], columns: Column[]) {
  const categoriesWithRows: Record<string, any> = {};
  const categoriesTotalized: Row[] = [];
  const totals: Record<string, Map<Column, number>> = {};
  const rowsWithoutCategory: Row[] = [];

  rows?.forEach((row: Row) => {
    const { category } = row;

    if (category) {
      totals[category.categoryId] = totals[category.categoryId] || new Map<Column, number>();
      categoriesWithRows[category.categoryId] = categoriesWithRows[category.categoryId] || {
        ...category,
        rows: []
      };

      for (const column of columns) {
        const value: number = getCell(row, column);
        if (column.isNumber && String(Number(value)) !== 'NaN') {
          const columnAccumulate = totals[category.categoryId].get(column) ?? 0;
          totals[category.categoryId].set(column, columnAccumulate + value);
        }
      }

      categoriesWithRows[category.categoryId].rows.push(row);
      return;
    }

    rowsWithoutCategory.push(row);
  });

  for (const [categoryId, category] of Object.entries(categoriesWithRows)) {
    const categoryGroup = [...category.rows];
    const totalRow: Row = {};
    for (const column of columns) {
      if (column.field) {
        totalRow[column.field] = totals[categoryId].get(column);
      }
    }

    totalRow.category = category;
    totalRow.type = 'lineItem';
    totalRow.isReportCategoryTotal = true;
    totalRow.name = category.name;
    totalRow.renderCell = renderCategorizableCell('reports');

    categoryGroup.sort(sortRowsByName);

    categoryGroup.push(totalRow);

    categoriesTotalized.push(categoryGroup);
  }

  return [...categoriesTotalized, ...rowsWithoutCategory];
}

export function filterAndSort(rows: TableProps['rows'], filters: any, sort: any) {
  const result = [];
  for (const row of rows ?? []) {
    if (row) {
      let keepRow = true;
      for (const [, { searched, column }] of filters) {
        const value = getCell(row, column);
        if (!value || !contains(value, searched)) {
          keepRow = false;
          break;
        }
      }

      if (keepRow) {
        result.push(row);
      }
    }
  }

  return result.sort((rowA, rowB) => {
    if (!sort.column) {
      return 0;
    }

    const valA = getCell(rowA, sort.column);
    const valB = getCell(rowB, sort.column);
    return (
      (sort.column.isNumber || sort.column.isCurrency || sort.column.isPercentage
        ? valB - valA
        : collator.compare(valA, valB)) * (sort.dir === ASC ? 1 : -1)
    );
  });
}
