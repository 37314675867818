import { useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CancelRounded from '@material-ui/icons/CancelRounded';
import Search from '@material-ui/icons/Search';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';

import InnerSearchPopover from './components/InnerSearchPopover';
import TopSearchPopoverLayout from './components/TopSearchPopoverLayout';

import { AuditTrailSearchFocus, StepShortNames } from '../../../../../../constants';
import { Level, Step } from '../../../../../../models';
import { DataTypeFilterOption } from '../../../../../../models/auditTrail.interface';
import DropdowSelectedItemChip from '../DropdownSelectedItemChip';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: '3px',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    '& .MuiAutocomplete-popupIndicator': {
      color: theme.palette.primary.dark,
      transform: 'none'
    },
    '& .MuiInputBase-input': {
      color: theme.palette.common.black,
      letterSpacing: '0.25px',
      marginLeft: '15px'
    },
    '& .MuiInputBase-root': {
      paddingRight: '0px !important'
    }
  },
  autocompleteOption: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingTop: '4px',
    paddingBottom: '4px'
  },
  autocompleteOptionType: {
    fontSize: '12px',
    letterSpacing: '0.6px',
    color: theme.palette.text.primary
  },
  clearButton: {
    fontSize: '20px',
    color: theme.palette.text.secondary,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  searchIcon: {
    fontSize: '24px',
    color: '#8E949F'
  }
}));

const DataTypeSelector = ({
  value,
  onChange,
  placeholder,
  options,
  getOptionLabel
}: Partial<AutocompleteProps<DataTypeFilterOption, boolean | undefined, boolean | undefined, boolean | undefined>>) => {
  const val = value as DataTypeFilterOption | null;
  const { t } = useTranslation();
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');
  const [searchFocus, setSearchFocus] = useState<keyof typeof AuditTrailSearchFocus>(AuditTrailSearchFocus.all);
  const [recentSearches] = useState([]);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const dropdownMainSectionRef = useRef<HTMLDivElement | null>(null);
  const hideRecentSearchFeature = true;
  const showRecentSearches = inputValue.length === 0 && !hideRecentSearchFeature;

  const getUserFriendlyStepName = (level: Level, step: Step) => {
    return `${t(level)} ${StepShortNames[step as any]}`;
  };

  const translateTypeToSearchFocusText = (type: keyof typeof AuditTrailSearchFocus, level?: Level, step?: Step) => {
    switch (type) {
      case AuditTrailSearchFocus.accountNumbers:
        return `${t('account-no')}.`;
      case AuditTrailSearchFocus.adjustments:
        return getUserFriendlyStepName(level!, step!);
      default:
        return t(type);
    }
  };

  const handleOnInnerSearchOpen = () => {
    setAnchorEl(dropdownMainSectionRef.current);
  };

  const handleOnInnerSearchClose = () => {
    setAnchorEl(null);
  };

  const handleOnClearText = () => {
    setInputValue('');
  };

  const handleOnClear = () => {
    // Set the value equal to null
    onChange?.({} as any, null, {} as any);
  };

  const handleSearchFocusChange = (val: keyof typeof AuditTrailSearchFocus) => {
    setSearchFocus(val);
  };

  const optionsFilteredBySearchFocus: DataTypeFilterOption[] =
    useMemo(() => {
      if (searchFocus === 'all') {
        return options;
      }

      return options?.filter((option) => {
        return option.type === searchFocus;
      });
    }, [searchFocus, options]) ?? [];

  const optionsFilteredForInnerSearch: DataTypeFilterOption[] =
    useMemo(() => {
      if (!val) {
        return [];
      }

      return options?.filter((option) => {
        if (option.type === 'adjustments') {
          // We don't distinguish between temporary income statement and balance sheet.
          if (val.step?.startsWith('temporary')) {
            return option.level === val.level && option.step?.startsWith('temporary');
          }

          // We don't distinguish between permanent and temporary modifications.
          if (val.step?.startsWith('modifications')) {
            return option.level === val.level && option.step?.startsWith('modifications');
          }

          return option.type === val.type && option.level === val.level && option.step === val.step;
        }

        return option.type === val.type;
      });
    }, [val, options]) ?? [];

  return (
    <>
      <Autocomplete
        value={value as DataTypeFilterOption}
        placeholder={placeholder}
        getOptionLabel={getOptionLabel}
        inputValue={inputValue}
        options={showRecentSearches ? recentSearches : optionsFilteredBySearchFocus}
        popupIcon={<Search />}
        renderInput={(params) => {
          return (
            <>
              <Box className={classes.container} style={{ display: val ? undefined : 'none' }}>
                <DropdowSelectedItemChip
                  title={translateTypeToSearchFocusText(val?.type!, val?.level, val?.step)}
                  text={val?.name ?? ''}
                  mainSectionRef={dropdownMainSectionRef}
                  onClick={handleOnInnerSearchOpen}
                />
                <CancelRounded
                  style={{ marginLeft: '8px', marginRight: '8px' }}
                  className={classes.clearButton}
                  onClick={handleOnClear}
                />
              </Box>
              <TextField
                style={{ display: val ? 'none' : undefined }}
                {...params}
                placeholder={placeholder}
                className={classes.container}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                  endAdornment: (
                    <>
                      {inputValue && (
                        <CancelRounded
                          style={{ marginRight: '8px' }}
                          className={classes.clearButton}
                          onClick={handleOnClearText}
                        />
                      )}
                      <Search className={classes.searchIcon} />
                    </>
                  )
                }}
              />
            </>
          );
        }}
        PaperComponent={({ children }) => (
          <TopSearchPopoverLayout
            showingRecentSearches={showRecentSearches}
            searchFocus={searchFocus}
            onSearchFocusChange={handleSearchFocusChange}
          >
            {children}
          </TopSearchPopoverLayout>
        )}
        renderOption={(option) => {
          const opt = option;
          return (
            <Box className={classes.autocompleteOption}>
              {opt.component?.() ?? <Typography>{opt.name}</Typography>}
              <Typography className={classes.autocompleteOptionType}>
                {translateTypeToSearchFocusText(opt.type, opt.level, opt.step).toUpperCase()}
              </Typography>
            </Box>
          );
        }}
        onChange={onChange}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
      />
      <InnerSearchPopover
        anchorEl={anchorEl}
        subtitle={translateTypeToSearchFocusText(val?.type!, val?.level, val?.step)}
        options={optionsFilteredForInnerSearch}
        onClose={handleOnInnerSearchClose}
        onChange={onChange}
      />
    </>
  );
};

export default DataTypeSelector;
