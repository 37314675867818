import { useTranslation } from 'react-i18next';

import { Card, Box, Divider, makeStyles } from '@material-ui/core';

import DropdownFooter from './DropdownFooter';

import { Container } from '../../../models';
import ContainersAccordion from '../../ContainersAccordion';

const useStyles = makeStyles((theme) => ({
  containersMenu: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(0.2),
    boxShadow:
      '-1px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%), 0 0 1000px 1000px rgb(0 0 0 / 20%)',
    left: theme.spacing(2),
    maxHeight: '70vh',
    minWidth: theme.spacing(28),
    overflow: 'auto',
    position: 'absolute',
    top: theme.spacing(10),
    zIndex: 100,
    '& .MuiAccordion-rounded:last-child': {
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px'
    }
  },
  headerBox: {
    color: theme.palette.text.dark,
    fontSize: theme.spacing(1.5),
    padding: theme.spacing(1.5),
    marginLeft: theme.spacing(1)
  }
}));

interface ContainersDropdownProps {
  containers: Container[];
  currentContainer: Container;
  onContainerSelected: (name: string, taxYear: string) => void;
  setIsDropdownOpen: (isOpen: boolean) => void;
}

const ContainersDropdown = ({
  containers,
  currentContainer,
  onContainerSelected,
  setIsDropdownOpen
}: ContainersDropdownProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card raised className={classes.containersMenu}>
      <Box className={classes.headerBox}>{t('Tax Period')}</Box>
      <Divider />
      <ContainersAccordion
        containers={containers}
        selectedContainer={currentContainer}
        onContainerSelected={onContainerSelected}
      />
      <DropdownFooter setIsDropdownOpen={setIsDropdownOpen} />
    </Card>
  );
};

export default ContainersDropdown;
