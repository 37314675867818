import { useMemo } from 'react';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import MuiTableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Adjust from '@material-ui/icons/Adjust';
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';

import { Filter, Sort } from '.';

import { TableHeadProps } from '../Table.proptype';
import { getAlignment, getClassName } from '../utils';

function createHeaderGroups(
  headerGroups: Map<string, Record<string, any>>,
  groupCells: any[],
  { isReport, classes }: Record<string, any>
) {
  let i = 0;
  for (const [, { name, render, count, column }] of headerGroups) {
    // if a report, don't pass the header cell styles to the headerGroup cells
    const columnClass = isReport ? {} : column;

    groupCells[i] = (
      <TableCell key={i++} colSpan={count} className={getClassName({ column: columnClass, row: {}, classes })}>
        {render ? render() : name && typeof name !== 'symbol' && <Box className={classes.colGroup}>{name}</Box>}
      </TableCell>
    );
  }
}

function TableHead({
  classes,
  columns,
  rows,
  sort,
  filters,
  selected,
  isSelectable,
  selectorsDisabled,
  onFilterChange,
  onSortClick,
  onSelectChange,
  renderOpts
}: TableHeadProps) {
  return useMemo(() => {
    if (!Array.isArray(columns)) {
      return null;
    }

    const headerCells = Array.from({ length: columns.length + (isSelectable ? 1 : 0) });
    const headerGroups = new Map();
    const headerGroups2 = new Map();
    let i = 0;
    if (isSelectable) {
      const indeterminate = selected.length > 0 && selected.length !== rows?.length;
      headerCells[i] = (
        <TableCell key={i++} className="checkbox">
          <Checkbox
            indeterminate={indeterminate}
            icon={<RadioButtonUnchecked />}
            checkedIcon={<RadioButtonChecked />}
            indeterminateIcon={<Adjust />}
            disabled={selectorsDisabled}
            checked={selected.length === rows?.length}
            onChange={() => {
              onSelectChange?.(rows, selected.length === rows?.length || indeterminate);
            }}
          />
        </TableCell>
      );
    }

    for (const column of columns) {
      headerCells[i] = (
        <TableCell
          key={i++}
          align={getAlignment(column)}
          className={`${getClassName({ column, row: {}, classes })} ${column.filterable ? 'filter' : ''}`}
          width={column.width}
        >
          {column.filterable ? (
            <Filter
              value={filters.get(column.field)?.searched || ''}
              column={column}
              sort={column.sortable ? sort : null}
              onChange={onFilterChange}
              onSort={onSortClick}
            />
          ) : column.sortable ? (
            <Sort column={column} sort={sort} onSort={onSortClick} />
          ) : column.renderHeader ? (
            column.renderHeader()
          ) : (
            column.headerName
          )}
        </TableCell>
      );
      const groupId = column.headerGroup ?? Symbol('');
      const groupId2 = column.headerGroup2 ?? Symbol('');

      const group = headerGroups.get(groupId) || { count: 0 };
      headerGroups.set(groupId, {
        count: ++group.count,
        name: column.headerGroup,
        render: column.renderHeaderGroup,
        column
      });
      const group2 = headerGroups2.get(groupId2) || { count: 0 };
      headerGroups2.set(groupId2, {
        count: ++group2.count,
        name: groupId2,
        render: column.renderHeaderGroup,
        column
      });
    }

    const groupCells: any[] = Array.from({ length: headerGroups.size });
    const groupCells2: any[] = Array.from({ length: headerGroups2.size });
    const isReport = renderOpts?.isReport;

    const shouldRenderheaderGroups = [...headerGroups.values()].some((group) => typeof group.name !== 'symbol');
    const shouldRenderheaderGroups2 = [...headerGroups2.values()].some((group) => typeof group.name !== 'symbol');

    createHeaderGroups(headerGroups, groupCells, { isReport, classes });
    createHeaderGroups(headerGroups2, groupCells2, { isReport, classes });

    return (
      <MuiTableHead>
        {shouldRenderheaderGroups2 ? <TableRow className={classes.colGroups}>{groupCells2}</TableRow> : null}
        {shouldRenderheaderGroups ? <TableRow className={classes.colGroups}>{groupCells}</TableRow> : null}
        <TableRow>{headerCells}</TableRow>
      </MuiTableHead>
    );
  }, [
    columns,
    rows,
    classes,
    sort,
    filters,
    isSelectable,
    selectorsDisabled,
    selected,
    onFilterChange,
    onSortClick,
    onSelectChange,
    renderOpts
  ]);
}

export default TableHead;
