import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ReportOutlined from '@material-ui/icons/ReportOutlined';

import { disableEntitiesTableInputForRoles } from '../../../UserRoleStylesProvider/constants';
import { Column, EditableCellProps, FailedCells, FailedCellsNoCredit, onCellEvent, Row } from '../../Table.proptype';
import { getCell, renderValue } from '../../utils';

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    justifyContent: 'space between'
  },
  cell: {
    padding: theme.spacing(1.2)
  },
  container: {
    position: 'relative',
    padding: theme.spacing(1),
    border: `2px solid ${theme.palette.action.focus}`
  },
  containerError: {
    position: 'relative',
    border: `2px solid ${theme.palette.error.main}`
  },
  icon: {
    color: theme.palette.error.main
  },
  input: {
    '& .MuiInput-input': {
      textAlign: 'inherit',
      fontSize: theme.typography.fontSize
    }
  },
  error: {
    '& .MuiInput-input': {
      textAlign: 'inherit',
      fontSize: theme.typography.fontSize,
      color: theme.palette.error.main
    }
  },
  message: {
    padding: '5px 10px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.text.primary,
    textAlign: 'center',
    borderRadius: theme.shape.borderRadius,
    position: 'absolute',
    left: 0,
    bottom: theme.spacing(5.2),
    zIndex: 2
  },
  entityReviewErrorMessage: {
    padding: '5px 10px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.text.primary,
    textAlign: 'center',
    borderRadius: theme.shape.borderRadius,
    position: 'absolute',
    left: 0,
    bottom: theme.spacing(3.7),
    zIndex: 2
  },
  select: {
    width: '100%'
  }
}));

type HandleEvent = {
  target: {
    value: any;
  };
};

function isCellFailed(failedCells: FailedCells, column: Column, row: Row): boolean {
  if (column.field) {
    return Boolean((failedCells[row.name ?? ''] ?? {})[column.field]);
  }

  return false;
}

// eslint-disable-next-line complexity
const EditableTextualCell = ({
  editableCellClassName,
  failedCells,
  column,
  row,
  renderOpts,
  isEntityReviewCell,
  isValidEntityInput,
  onChange,
  onCellOrCommentBlur
}: EditableCellProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [edited, setEdited] = useState<string>('');

  const failedCellNoCredit: FailedCellsNoCredit = row.creditName
    ? ((failedCells[row.creditName] ?? failedCells) as FailedCellsNoCredit)
    : (failedCells as FailedCellsNoCredit);

  useEffect(() => {
    if (!isFocused) {
      setEdited(getCell(row, column));
    }
  }, [isFocused, row, column]);

  function handleEvent({ target: { value: newValue } }: HandleEvent, callback?: (arg: onCellEvent) => void): void {
    const newValueTrimmedStart: string = newValue.trimStart();

    setEdited(newValueTrimmedStart);

    const newValueTrimmed = newValueTrimmedStart.trim();

    if (callback) {
      callback({
        value: newValueTrimmed,
        row,
        column
      });
    }
  }

  const options = column.getOptions?.(row);

  function getClassName(): string {
    if (isFocused) {
      return classes.container;
    }

    if (isFocused || isCellFailed(failedCellNoCredit, column, row)) {
      return classes.containerError;
    }

    return '';
  }

  const isEditedOptionAvailable = Boolean(
    edited && (options?.includes(edited) || options?.some((obj: any) => obj.value === edited))
  );

  return options ? (
    <Select
      displayEmpty
      variant="outlined"
      value={isEditedOptionAvailable ? edited : ''}
      disabled={options.length === 0}
      className={classes.select}
      onChange={(event) => {
        handleEvent(event, onChange);
      }}
    >
      {column.usePlaceholderForSelect && column.placeholder && (
        <MenuItem disabled value="">
          {column.placeholder}
        </MenuItem>
      )}
      {typeof options[0] === 'object'
        ? options.map((obj: any) => (
            <MenuItem key={obj.name} disabled={obj.disabled} selected={edited === obj.value} value={obj.value}>
              {obj.name}
            </MenuItem>
          ))
        : options.map((value: any) => (
            <MenuItem key={value} selected={edited === value} value={value}>
              {value}
            </MenuItem>
          ))}
    </Select>
  ) : (
    <Box
      // ... is used here to include the data roles prop only if the cell is an Entity Review cell
      {...(isEntityReviewCell
        ? { 'data-roles-disable-pointer-events': disableEntitiesTableInputForRoles.join(' ') }
        : {})}
      role="container"
      className={[classes.cell, getClassName(), isEntityReviewCell ? String(editableCellClassName) : '']
        .join(' ')
        .trim()}
    >
      {isCellFailed(failedCellNoCredit, column, row) && !isFocused ? (
        <Box role="message" className={classes.message}>
          {
            // @ts-expect-error TODO - we need to figure out if column field and row name can really be undefined}
            (failedCellNoCredit[row.name ?? ''] ?? {})[column.field] === true
              ? t('Save Unsuccessful')
              : // @ts-expect-error TODO - we need to figure out if column field and row name can really be undefined}
                (failedCellNoCredit[row.name ?? ''] ?? {})[column.field]
          }
        </Box>
      ) : null}
      {isValidEntityInput === false && (
        <Box role="message" className={classes.entityReviewErrorMessage}>
          {t('Invalid entry')}
        </Box>
      )}
      <Box className={classes.box}>
        {((isCellFailed(failedCellNoCredit, column, row) && !isFocused) || isValidEntityInput === false) && (
          <ReportOutlined role="icon" className={classes.icon} />
        )}
        <Input
          disableUnderline
          fullWidth
          className={isCellFailed(failedCellNoCredit, column, row) ? classes.error : classes.input}
          placeholder={renderOpts?.placeholder}
          value={(isFocused ? edited : renderValue(edited, column || {}, renderOpts)) || ''}
          onChange={(event) => {
            handleEvent(event, onChange);
          }}
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={(event) => {
            setIsFocused(false);
            handleEvent(event, onCellOrCommentBlur);
          }}
        />
      </Box>
    </Box>
  );
};

export default EditableTextualCell;
