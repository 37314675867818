import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { CurrencyFromApi } from '../../models';
import { onCurrenciesFailed, onCurrenciesReceived, onFetchingCurrencies } from '../../redux/currencies';
import { selectCurrentContainerFxRates } from '../../selectors';
import {
  selectCurrencies,
  selectCurrenciesByEntityId,
  selectCurrencyById,
  selectEntityCurrencies
} from '../../selectors/currencies';
import HTTPService, { LambdaResponse } from '../../services/http';

export function useCurrencies() {
  const dispatch = useDispatch();
  const currenciesState = useSelector(selectCurrencies);
  const currencyById = useSelector(selectCurrencyById);
  const entityCurrencies = useSelector(selectEntityCurrencies);
  const currencyByEntityIdMap = useSelector(selectCurrenciesByEntityId);
  const containerFxRatesByCurrencyId = useSelector(selectCurrentContainerFxRates);

  useEffect(() => {
    async function fetchData() {
      dispatch(onFetchingCurrencies());

      try {
        const { data: currencies } = await HTTPService.request<LambdaResponse<CurrencyFromApi[]>>({
          method: 'get',
          relativePath: `/v1/currencies`
        });
        dispatch(onCurrenciesReceived(currencies));
      } catch (error: unknown) {
        dispatch(onCurrenciesFailed(error));
      }
    }

    if (!currenciesState.isLoading && !currenciesState.hasLoaded && !currenciesState.error) {
      void fetchData();
    }
  }, [currenciesState, dispatch]);

  return { ...currenciesState, currencyByEntityIdMap, currencyById, entityCurrencies, containerFxRatesByCurrencyId };
}
