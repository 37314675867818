import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@material-ui/core';

import ChooseFile from './components/ChooseFile';
import FileUploadHeader from './components/FileUploadHeader';
import UploadFile from './components/UploadFile';
import { useStyles } from './FileUpload.styles';
import { stox } from './utils';

import { useDropzoneForSpreadsheet } from '../../hooks';
import { useScript } from '../../hooks/useScript';
import { fetchFileMetadata, setUploadManagerIsOpen } from '../../redux/uploadManager/uploadManager.actions';
import {
  selectEntitiesMapByName,
  selectIsFileDownloaded,
  selectIsFileUploaded,
  selectIsTemporaryBalanceSheetTabOpen,
  selectNumberOfDataImportRows,
  selectValidateImportState
} from '../../selectors';
import { selectEntitiesCompletionStatus } from '../../selectors/entitiesCompletionStatus';
import theme from '../../theme';
import Spreadsheet from '../Spreadsheet/Spreadsheet';
import { FileUploadProps, getKeyHeaderName, mapToStepTitle, transformTemporaryStepName } from '../Spreadsheet/utils';
import ValidateScreen from '../ValidateScreen/ValidateScreen';
import ValidateScreenFederalPtbi from '../ValidateScreenFederalPtbi';

export type TabName = 'data' | 'validate';

export const dataTab = 'data';
export const validateTab = 'validate';

const FileUpload = ({ entityName, level, step, entitySubJurisdictions }: FileUploadProps) => {
  useScript('https://unpkg.com/x-data-spreadsheet@1.1.5/dist/xspreadsheet.js');
  const [activeTab, setActiveTab] = useState<TabName>(dataTab);
  const classes = useStyles({ activeTab });
  const dispatch = useDispatch();
  const isFileUploaded = useSelector(selectIsFileUploaded);
  const isFileDownloaded = useSelector(selectIsFileDownloaded);
  const entitiesMapByName = useSelector(selectEntitiesMapByName);
  const entitiesCompletionStatus = useSelector(selectEntitiesCompletionStatus);
  step = transformTemporaryStepName(step, useSelector(selectIsTemporaryBalanceSheetTabOpen));
  const keyHeaderNames = getKeyHeaderName(level, step);
  const numberOfDataImportRows = useSelector(selectNumberOfDataImportRows(keyHeaderNames));
  const numberOfValidateImportRows = useSelector(selectValidateImportState);

  const entity = entitiesMapByName?.[entityName];
  const entityCompletionStatus =
    entitiesCompletionStatus?.entitiesCompletionStatus?.[entity?.entityNumber]?.completionStatus;
  const tabCompletionStatus = Object.values(entityCompletionStatus?.[level]?.[step.split('.')[0]] ?? {}).some(
    (completionStatus) => completionStatus
  );

  useEffect(() => {
    return () => {
      dispatch(setUploadManagerIsOpen(false));
    };
  }, [dispatch]);

  const { getInputProps, getRootProps, open, workbook } = useDropzoneForSpreadsheet();

  const stoxOutput = stox(workbook);

  useEffect(() => {
    dispatch(fetchFileMetadata());
  }, [dispatch]);

  return isFileUploaded || isFileDownloaded ? (
    <>
      <FileUploadHeader
        activeTab={activeTab}
        entityName={entityName}
        keyHeaderNames={keyHeaderNames}
        mapToStepTitle={mapToStepTitle}
        numberOfDataImportRows={numberOfDataImportRows}
        numberOfValidateImportRows={numberOfValidateImportRows?.amount}
        setActiveTab={setActiveTab}
        level={level}
        step={step}
      />
      {activeTab === dataTab ? (
        <Box className={classes.spreadsheetBox}>
          <Spreadsheet
            height="90%"
            width="99%"
            data={stoxOutput?.formattedWorkbook}
            keyHeaderNames={keyHeaderNames}
            level={level}
            step={step}
            options={{
              mode: 'read',
              showToolbar: false,
              showGrid: true,
              showContextmenu: false,
              col: { indexWidth: 38, width: 130, len: stoxOutput?.maxRowLength },
              row: { len: stoxOutput?.maxColLength },
              style: { font: { name: 'Basis Grotesque Pro Medium', size: 10 }, color: theme.palette.text.dark }
            }}
            workbook={workbook}
          />
        </Box>
      ) : (
        <Box className={classes.validateSection}>
          {level === 'federal' && step === 'ptbi' ? (
            <ValidateScreenFederalPtbi />
          ) : (
            <ValidateScreen
              step={step}
              level={level}
              keyHeaderNames={keyHeaderNames}
              entitySubJurisdictions={entitySubJurisdictions}
              isTabCompleted={tabCompletionStatus}
            />
          )}
        </Box>
      )}
    </>
  ) : (
    <Box className={classes.uploadContent}>
      <ChooseFile step={step} level={level} />
      <Box data-roles-disable-element={tabCompletionStatus ? 'finalizedContainer' : ''}>
        <UploadFile getRootProps={getRootProps} getInputProps={getInputProps} open={open} />
      </Box>
    </Box>
  );
};

export default FileUpload;
