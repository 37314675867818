import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme } from '@material-ui/core/styles';
import DeleteOutline from '@material-ui/icons/DeleteOutline';

import { RangeSelectorProps, RangeSelectorPropsStyleProps } from './RangeSelector.proptype';

import { SearchBox } from '../../..';

const useStyles = makeStyles<Theme, RangeSelectorPropsStyleProps>((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',

    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(1)
    },
    '& .MuiTextField-root': {
      flexGrow: 1
    }
  },
  searchbox: {
    flexGrow: 1,
    minWidth: 0,
    '& .MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
      '& > .MuiOutlinedInput-input': {
        padding: theme.spacing(0.25)
      }
    }
  },
  name: {
    paddingBottom: theme.spacing(0.5)
  },
  divider: {
    width: 20,
    height: 1,
    backgroundColor: theme.palette.grey[500],
    margin: '0 2px'
  },
  chip: {
    borderRadius: theme.shape.borderRadius,
    minWidth: '2.5rem',
    color: ({ color }) => theme.palette.getContrastText(color),
    backgroundColor: ({ color }) => color
  },
  remove: {
    margin: theme.spacing(0, 0.5)
  },
  unset: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1, 1.5),
    cursor: 'pointer',

    '& > .hint': {
      fontWeight: 'normal',
      color: theme.palette.text.secondary
    },
    '& > :not(.hint)': {
      flexGrow: 1
    }
  }
}));

const RangeSelector = ({
  color = '#000',
  count,
  isValid,
  name,
  onChange,
  onEdit,
  onRemove,
  options,
  range
}: RangeSelectorProps) => {
  const classes = useStyles({ color });
  const { t } = useTranslation();
  const [currentRange, setCurrentRange] = useState(range ?? []);
  useEffect(() => {
    if (Array.isArray(range)) {
      setCurrentRange(range);
    }
  }, [range]);

  return range?.length ? (
    <>
      <Box className={classes.name}>{name}</Box>
      <Box className={classes.container}>
        <SearchBox
          freeSolo
          id={`${name}-low`}
          className={classes.searchbox}
          options={options}
          getOptionLabel={(option: any) => option.id || ''}
          inputValue={currentRange[0] || ''}
          value={options.find(({ id }) => id === currentRange[0]) ?? {}}
          icon={null}
          isError={!isValid && Boolean(currentRange[0])}
          placeholder={t('Acc. number')}
          onInputChange={(event: any, value: any) => {
            if (event) {
              const newRange = [value, currentRange[1]];
              setCurrentRange(newRange);
              onChange(newRange);
            }
          }}
        />
        <Box className={classes.divider} />
        <SearchBox
          freeSolo
          id={`${name}-high`}
          className={classes.searchbox}
          options={options}
          getOptionLabel={(option: any) => option.id || ''}
          inputValue={currentRange[1] || ''}
          value={options.find(({ id }) => id === currentRange[1]) ?? {}}
          icon={null}
          isError={!isValid && Boolean(currentRange[1])}
          placeholder={t('Acc. number')}
          onInputChange={(event: any, value: any) => {
            if (event) {
              const newRange = [currentRange[0], value];
              setCurrentRange(newRange);
              onChange(newRange);
            }
          }}
        />
        <IconButton className={classes.remove} aria-label={t('Remove')} onClick={onRemove}>
          <DeleteOutline />
        </IconButton>
        <Chip className={classes.chip} label={count ?? 0} />
      </Box>
    </>
  ) : (
    <Paper variant="outlined" className={classes.unset} onClick={onEdit}>
      <Box>{name}</Box>
      <Box className="hint">{t('Select range')}</Box>
    </Paper>
  );
};

export default RangeSelector;
