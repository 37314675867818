import { AuditTrailStateInterface } from '../../models';

export const auditTrailInitialState: AuditTrailStateInterface = {
  error: null,
  auditTrail: {
    count: 0,
    records: [],
    filters: {
      users: [],
      entities: [],
      accounts: []
    }
  }
};
