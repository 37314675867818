import { RootState } from '../../../..';
import { ATTRIBUTE_NAMES, COLUMNS, federalStepsByName, LEVELS } from '../../../../../constants';
import { FinancialInfo } from '../../../../../models';
import { selectEntitiesWithRates, selectEntityJurisdictions } from '../../../../../selectors';
import { FailureStrategy } from '../cellFailureStrategies.types';

export const FedTaxRateReconciliation: FailureStrategy = {
  shouldTest(finData: FinancialInfo) {
    return (
      finData.level === LEVELS.FEDERAL &&
      finData.step === federalStepsByName.RTP &&
      finData.columnName === COLUMNS.taxProvision &&
      finData.rowName === ATTRIBUTE_NAMES.priorYearFedTaxRate
    );
  },

  test(finData: FinancialInfo, entityId: string, state: RootState) {
    const entities = selectEntitiesWithRates(state);
    const entity = entities.find((entity) => entity.entityId === entityId);
    const entityJurisdictions = selectEntityJurisdictions(state);
    const jurisdiction = entityJurisdictions.find(({ id }) => id === entity!.jurisdictionId);
    const entityTaxRates = entity!.taxRates?.[entity!.jurisdictionId];
    const jurTaxRates = jurisdiction!.taxRates;
    const begTaxRate = entityTaxRates?.beginning ?? jurTaxRates.beginning;
    const error =
      begTaxRate === finData.value
        ? null
        : `This rate does not match ${
            jurisdiction!.name
          } beginning tax rate in Entity Review; The Rate Reconciliation report will be off as a result.`;
    return error;
  },

  shouldSave() {
    return true;
  }
};
