import { useDispatch, useSelector } from 'react-redux';

import { Typography, makeStyles } from '@material-ui/core';

import { Column, Container, Report, Row, TraceReport } from '../../../models';
import {
  fetchTraceabilityReport,
  FetchTraceabilityReportInput,
  onCloseTraceability
} from '../../../redux/traceability';
import { selectParentEntity } from '../../../selectors/entities';
import { traceabilitySelectors } from '../../../selectors/traceability';
import Table from '../../Table';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTable-root': {
      '& .MuiTableHead-root': {
        position: 'sticky',
        top: 0,
        zIndex: 1
      },
      '& .total .MuiTableCell-root.MuiTableCell-body': {
        color: 'inherit',
        borderTop: 'none',
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        backgroundColor: theme.palette.grey[50],
        fontFamily: theme.typography.h3.fontFamily,
        fontWeight: theme.typography.h3.fontWeight
      },
      '& .MuiTableCell-root.MuiTableCell-body': {
        borderRightColor: 'transparent',
        '&.section-header': {
          paddingTop: theme.spacing(3),
          cursor: 'default'
        },
        '&.indent': {
          paddingLeft: theme.spacing(4)
        },
        '&.empty-row': {
          padding: theme.spacing(1.5, 0),
          cursor: 'default'
        },
        '&.unclickable': {
          cursor: 'default'
        }
      },
      '& .MuiTableRow-hover:hover': {
        '& .MuiTableCell-root.MuiTableCell-body.empty-row, & .MuiTableCell-root.MuiTableCell-body.gap': {
          backgroundColor: theme.palette.common.white
        }
      },
      '& .MuiTableCell-root.gap': {
        '&.MuiTableCell-body, &.MuiTableCell-head': {
          backgroundColor: theme.palette.common.white,
          padding: 0,
          border: 'none',
          maxWidth: theme.spacing(2),
          cursor: 'default'
        }
      },
      '& .MuiTableCell-root.MuiTableCell-head': {
        verticalAlign: 'bottom'
      },
      'main & .MuiTableCell-body': {
        cursor: (props: any) => (props.isTraceabilityActive ? 'pointer' : 'default')
      },
      '& .MuiTableCell-head.totalColumn': {
        backgroundColor: theme.palette.primary.light,
        fontWeight: 'bolder'
      }
    }
  }
}));

export const emptyRow = { className: 'empty-row', renderCell: () => '' };

export const gapColumn = { className: 'gap' };

export function makeSectionTitle(title: React.ReactNode): React.ReactNode {
  return {
    className: 'section-header',
    renderCell: (row: unknown, col: unknown, { field }: { field: string }) =>
      field === 'name' ? (
        <Typography variant="h3" component="h4">
          {title}
        </Typography>
      ) : (
        ''
      ),
    name: title
  };
}

const ReportTable = ({ report, currency, container, reportName, ...rest }: Props) => {
  const dispatch = useDispatch();
  const isTraceabilityActive = useSelector(traceabilitySelectors.selectTraceabilityIsActive);

  const currentRow = useSelector(traceabilitySelectors.selectTraceabilityRow);
  const currentColumn = useSelector(traceabilitySelectors.selectTraceabilityColumn);
  const parent = useSelector(selectParentEntity);
  const parentEntityId = parent?.entityId;
  const styleProps = { isTraceabilityActive, currentRow, currentColumn };

  const styles = useStyles(styleProps);

  return (
    <Table
      {...(currentColumn && currentRow && { activeColumn: currentColumn, activeRow: currentRow })}
      className={styles.root}
      renderOpts={{ zeroValue: '', digits: 0, isReport: true }}
      isNotEditableShaded={false}
      reportName={reportName}
      onCellClick={(row: Record<string, any>, value: string | number, column: Column) => {
        if (!isTraceabilityActive) return;
        // PROV-687: Remove percentage condition once percentage values are supported
        if (
          container &&
          value !== undefined &&
          column.field !== 'name' &&
          row.format !== 'percentage' &&
          !row.percentageFields?.includes(column.field)
        ) {
          dispatch(
            fetchTraceabilityReport({
              column,
              row,
              report,
              currency,
              container,
              parentEntityId
            } as FetchTraceabilityReportInput)
          );
        } else {
          dispatch(onCloseTraceability());
        }
      }}
      {...rest}
    />
  );
};

ReportTable.ASC = Table.ASC;
ReportTable.DESC = Table.DESC;

type Props = {
  className?: string;
  columns: Column[];
  currency: string;
  report: Report | TraceReport;
  rows: Row[];
  container?: Container;
  reportName: string;
};

export default ReportTable;
