import { AuthStateInterface } from './auth.types';

import { decodeToken } from '../../utils/decodeToken';

const idToken = localStorage.getItem('idToken') ?? '';
const { userId, email } = decodeToken(idToken);
const isXBSEmployee = localStorage.getItem('isXBSEmployee') === 'true';

export const authInitialState: AuthStateInterface = {
  authToken: idToken,
  refreshToken: localStorage.getItem('refreshToken') ?? '',
  expiration: localStorage.getItem('expires')
    ? new Date(Number(localStorage.getItem('expires')) * 1000)
    : new Date(Date.now() - 1000),
  lastRefreshDate: localStorage.getItem('lastRefreshDate')
    ? Number(localStorage.getItem('lastRefreshDate'))
    : new Date().getDate(),
  email,
  userId,
  isXBSEmployee
};
