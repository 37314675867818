import { makeStyles, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    border: '1px solid',
    borderColor: theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'row',
    fontFamily: '"Basis Grotesque Pro Regular", sans-serif',
    height: '100px',
    width: '304px',
    padding: '24px 18px 17px 23px',
    backgroundColor: theme.palette.common.white,
    '& .MuiBox-root:not(.label)': {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      paddingRight: theme.spacing(0.5)
    },
    '&.active': {
      borderColor: theme.palette.action.focus,
      borderRadius: '4px',
      borderTopWidth: '4px',
      padding: '21px 18px 17px 23px',
      boxShadow: '0 0 6px 0 rgba(130,130,130,0.5)'
    }
  },
  boxTitle: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    flexGrow: 1,
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '14px',
    marginBottom: theme.spacing(0.5)
  },
  label: {
    textAlign: 'left',
    display: 'flex',
    borderColor: 'red',
    borderRadius: '4px',
    flexGrow: 1,
    fontFamily: '"Basis Grotesque Pro Medium", sans-serif',
    fontSize: '14px',
    minHeight: '30px',
    height: '30px',
    lineHeight: '18px',
    maxWidth: '210px',
    overflow: 'hidden',
    paddingLeft: '6px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '119px',
    '& span': {
      marginLeft: '4px',
      marginRight: '4px'
    }
  },
  'label--top': {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.info.main,
    justifyContent: 'center',
    marginBottom: '4px'
  },
  'label--bottom': {
    backgroundColor: '#E5F3FA',
    color: '#17719B',
    justifyContent: 'center'
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    transform: 'translateY(-5px)'
  },
  boxNumber: {
    color: theme.palette.primary.main,
    display: 'flex',
    fontSize: '18px',
    justifyContent: 'flex-end',
    '&.single': {
      paddingTop: theme.spacing(1),
      fontSize: '2.3rem'
    }
  }
}));

interface Props {
  title: string;
  leftContent?: string | number;
  rightLabelTop?: string | number;
  rightLabelBottom?: string | number;
  isActive?: boolean;
}

const SummaryHeaderBox = ({ title, leftContent, isActive, rightLabelTop, rightLabelBottom }: Props) => {
  const classes = useStyles();

  return (
    <Box className={`${classes.boxContainer}${isActive ? ' active' : ''}`}>
      <Box style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        {leftContent && (
          <Box style={{ display: 'flex' }}>
            <div className={classes.boxTitle}>{title}</div>
            <Box className={`${classes.boxNumber}`}>{leftContent}</Box>
          </Box>
        )}
        <Box style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          {(rightLabelTop || rightLabelBottom) && (
            <Box className={classes.labelContainer}>
              <Box className={`${classes.label} ${classes['label--top']}`}>{rightLabelTop}</Box>
              <Box className={`${classes.label} ${classes['label--bottom']}`}>{rightLabelBottom}</Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SummaryHeaderBox;
