import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SlideOut } from '@xbs/xbs-common-ui';

import { ActionsCell, CommentsPanel } from './components';
import { TableWithCommentProps } from './TableWithComment.proptype';

import { Table } from '..';
import { useContainers } from '../../hooks';
import { useCategorizableSections } from '../../hooks/useCategorizableSections';
import { CategoryFromApi } from '../../models';
import { selectDoesUserHaveRole, selectIsEntityDetailsGroupedViewToggled } from '../../selectors';
import { selectCategories } from '../../selectors/categories';
import { splitLevelStep } from '../../utils/category';
import { groupEntityDetailsCategoryRows } from '../../utils/table';
import { Row } from '../Table/Table.proptype';
import { categorizeRows, ASC, DESC } from '../Table/utils';
import { ProReadOnly, ProReviewer } from '../UserRoleStylesProvider/constants';

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableRow-root > .MuiTableCell-body:not(:last-child)': {
      borderRight: 'none'
    },
    '& .MuiTableRow-root:not(.total) > .MuiTableCell-body:last-child': {
      backgroundColor: theme.palette.common.white
    },
    '& .MuiTableCell-head.right': {
      textAlign: 'right'
    },
    '& .creditTaxPeriodColumn': {
      width: '15%',
      '& input': {
        borderBottom: '1px solid #d3d3d3'
      }
    }
  },
  categoryButton: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '4px',
    backgroundColor: theme.palette.common.white,
    fontFamily: theme.typography.body2.fontFamily,
    color: theme.palette.primary.main,
    width: theme.spacing(11.25),
    height: theme.spacing(3),
    marginRight: theme.spacing(-1.08),
    marginLeft: theme.spacing(2.5)
  }
}));

const TableWithComment = (props: TableWithCommentProps) => {
  const {
    columns,
    titleHeight = 120,
    expanded,
    handleOnRowDelete,
    toggleEditCategoryDrawer,
    onExpand,
    onCellOrCommentBlur,
    rows,
    renderOpts,
    hideActionsMenu = false,
    displayGroupActions,
    displayCategoryActions,
    toggleCategoryDrawer
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [expandedRow, setExpandedRow] = useState(expanded);
  const hasNewRow = rows?.some((row) => row.isNew) ?? false;
  const isUserReadOnly = useSelector(selectDoesUserHaveRole([ProReadOnly.Name, ProReviewer.Name]));
  const columnsToRender = isUserReadOnly ? columns.map((column) => ({ ...column, isEditable: false })) : columns;
  const IsEntityDetailsGroupedViewToggled = useSelector(selectIsEntityDetailsGroupedViewToggled);
  const { categories } = useSelector(selectCategories);
  const { currentContainer } = useContainers();
  const { pathname } = useLocation();

  useEffect(() => {
    setExpandedRow(expanded);
  }, [expanded]);

  const renderedColumns = [
    ...columnsToRender,
    {
      renderHeader: () =>
        displayCategoryActions ? (
          <Button
            className={classes.categoryButton}
            variant="outlined"
            data-roles-disable-element={currentContainer?.isFinalized ? 'finalizedContainer' : ''}
            onClick={toggleCategoryDrawer}
          >
            + {t('Add Category')}
          </Button>
        ) : null,
      renderCell: (row: Row) => (
        <ActionsCell
          displayGroupActions={displayGroupActions}
          displayCategoryActions={displayCategoryActions}
          hideMoreButton={hideActionsMenu || isUserReadOnly}
          row={row}
          hasNewRow={hasNewRow}
          handleOnRowDelete={
            handleOnRowDelete &&
            (() => {
              handleOnRowDelete({ row });
            })
          }
          handleOnCommentsClick={() => {
            if (onExpand) {
              onExpand(row);
            } else {
              setExpandedRow(row);
            }
          }}
          toggleEditCategoryDrawer={toggleEditCategoryDrawer}
        />
      ),
      className: 'right',
      width: '10%',
      isActions: true
    }
  ];

  let rowsStrategy = rows;
  let columnsStrategy = renderedColumns;
  const { level, step } = splitLevelStep(pathname);
  const categorizableSections = useCategorizableSections(level, step);
  const isCategorizableTab = Boolean(categorizableSections);
  const shouldGroupCategories = IsEntityDetailsGroupedViewToggled && isCategorizableTab;
  const shouldDisplayCategoryDot = columnsStrategy && categories;

  if (shouldDisplayCategoryDot) {
    const filteredCategories = categories.filter((category) => {
      return categorizableSections?.has(category.accounts[0].stepName);
    });

    const categoriesByAccountKey: Record<any, CategoryFromApi[]> = {};

    filteredCategories
      .map((category) => category.accounts.map((account) => ({ ...account, category })))
      .flat()
      .forEach((accountData) => {
        const standardizedAttributeName = accountData.attributeName.toLowerCase().trim();
        categoriesByAccountKey[standardizedAttributeName] = categoriesByAccountKey[standardizedAttributeName] ?? [];

        categoriesByAccountKey[standardizedAttributeName].push(accountData.category);

        return categoriesByAccountKey;
      });

    rowsStrategy = categorizeRows(rows, categoriesByAccountKey, currentContainer);

    if (shouldGroupCategories) {
      rowsStrategy = groupEntityDetailsCategoryRows(rows, columns);
    }

    /* Avoid rendering states selector on category total rows */
    columnsStrategy = columnsStrategy.map((column) => {
      if (column.field === 'state' && column.renderCell) {
        const originalRenderCell = column.renderCell;
        return {
          ...column,
          renderCell: (row, value) => {
            if (row.isTotal && row.category) {
              return <div />;
            }

            return originalRenderCell(row, value);
          }
        };
      }

      return column;
    });
  }

  return (
    <>
      <Table
        className={classes.table}
        {...props}
        columns={columnsStrategy}
        rows={rowsStrategy}
        renderOpts={renderOpts}
      />
      <SlideOut.Drawer
        isDividerHidden
        isFloating={false}
        subtitle=""
        title={t('Note')}
        titleHeight={titleHeight}
        isOpen={Boolean(expandedRow)}
        onClose={() => {
          if (onExpand) {
            onExpand(null);
          } else {
            setExpandedRow(null);
          }
        }}
      >
        <CommentsPanel
          isDisabled={hideActionsMenu || isUserReadOnly}
          // @ts-expect-error TODO - we need to figure out if expandedRow name can really be undefined
          row={expandedRow}
          hasNewRow={hasNewRow}
          rowComments={expandedRow?.comments ?? expandedRow?.note}
          onCellOrCommentBlur={onCellOrCommentBlur}
        />
        {/* Remove `expandedRow?.comments` once we hook all table to the api */}
      </SlideOut.Drawer>
    </>
  );
};

TableWithComment.ASC = ASC;
TableWithComment.DESC = DESC;

export default TableWithComment;
