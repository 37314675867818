import { CircularProgressProps } from '@material-ui/core';

import LoadingSpinner from '../LoadingSpinner';

interface Props {
  isLoading: boolean;
  children: JSX.Element;
  size?: CircularProgressProps['size'];
}

export const LoadingWrapper = ({ children, isLoading, ...props }: Props) =>
  isLoading ? <LoadingSpinner {...props} /> : children;

export default LoadingWrapper;
