import { ComponentType } from 'react';

import { ActionBarProps } from './ActionBar';

import { useContainers } from '../../hooks';
import FinalizeContainerMessage from '../FinalizeContainerMessage';

const ActionBarConnector = ({ component: ActionBar }: { component: ComponentType<ActionBarProps> }) => {
  const { containers, currentContainer, setCurrentContainerId, isLoading } = useContainers();

  return currentContainer === undefined ? null : (
    <>
      <ActionBar
        isLoading={isLoading}
        containers={containers}
        currentContainer={currentContainer}
        onContainerChanged={setCurrentContainerId}
      />
      <FinalizeContainerMessage currentContainer={currentContainer} />
    </>
  );
};

export default ActionBarConnector;
