import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';

import modalImage from '../../../../assets/img/UploadManager_Modal.svg';

const useStyles = makeStyles((theme) => ({
  modalStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '506px',
    height: '572px',
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    border: '2px solid #000',
    borderRadius: '4px',
    p: 4,
    padding: '55px 80px 55px 80px'
  },
  modalTitleDesc: {
    color: theme.palette.text.dark,
    fontSize: '18px',
    fontFamily: 'Basis Grotesque Pro Medium',
    letterSpacing: '0.25px',
    lineHeight: '24px'
  },
  modalTextDesc: {
    color: theme.palette.text.secondary,
    fontSize: '16px',
    letterSpacing: '0.15px',
    lineHeight: '22px'
  },
  modalButton: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '4px',
    backgroundColor: theme.palette.common.white,
    fontFamily: theme.typography.body2.fontFamily,
    color: theme.palette.primary.main,
    width: '100%',
    height: theme.spacing(3),
    marginBottom: '12px'
  }
}));
interface ValidationModalProps {
  isOpen: boolean;
  handleClick: () => void;
  handleClose: () => void;
}
const ValidationModal = ({ isOpen, handleClick, handleClose }: ValidationModalProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={handleClose}
    >
      <Box className={classes.modalStyle}>
        <img src={modalImage} />
        <div style={{ marginBottom: '12px' }}>
          <Typography className={classes.modalTitleDesc} id="modal-modal-title" variant="h6" component="h2">
            {t('upload-complete')}
          </Typography>
          <Typography className={classes.modalTitleDesc} id="modal-modal-title" variant="h6" component="h2">
            {t('validate-data-before-import')}
          </Typography>
        </div>
        <div style={{ marginBottom: '48px' }}>
          <Typography id="modal-modal-description" className={classes.modalTextDesc}>
            {t('your-data-selected-and-validated')}
          </Typography>
          <Typography id="modal-modal-description" className={classes.modalTextDesc}>
            {t('to-import-validate-data')}
          </Typography>
        </div>
        <Button className={classes.modalButton} variant="contained" onClick={handleClick}>
          {t('validate-data-now')}
        </Button>
        <Button className={classes.modalButton} variant="contained" onClick={handleClose}>
          {t('return-to-upload-manager')}
        </Button>
      </Box>
    </Modal>
  );
};

export default ValidationModal;
