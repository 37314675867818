import { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import { Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Adjust from '@material-ui/icons/Adjust';
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';

import { SearchBox } from '../../..';
import { ReactComponent as DeleteOutlineIcon } from '../../../../assets/img/DeleteOutlineIcon.svg';
import { SubJurisdiction } from '../../../../models';
import { formatSubJurisdictionForDisplay, sortSubJursByName } from '../../../../utils';
import LoadingSpinner from '../../../LoadingSpinner';
import EditEntityJurisdictionActionButtons from '../EditEntityJurisdictionActionButtons';

const useStyles = makeStyles<Theme, { trashDisabled?: boolean }>((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1, 2),
    overflow: 'visible',
    '& .Pro-rates': {
      marginBottom: theme.spacing(1)
    }
  },
  deleteIcon: {
    cursor: ({ trashDisabled }) => (trashDisabled ? 'not-allowed' : 'pointer'),
    position: 'absolute',
    right: theme.spacing(0.5),
    top: `-${theme.spacing(3)}px`,
    width: theme.spacing(2.5)
  },
  menu: {
    overflow: 'auto'
  },
  menuItem: {
    display: 'flex'
  },
  searchBox: {
    backgroundColor: theme.palette.common.white,
    flexGrow: 1,
    height: '50px',
    marginBottom: '12px',
    marginTop: '8px',
    '& .MuiTextField-root': {
      height: '100%'
    },
    '& .MuiAutocomplete-inputRoot': {
      height: '100%'
    }
  },
  searchHeader: {
    display: 'flex',
    position: 'relative'
  },
  sectionTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(2)
  },
  selectedSubJursMenuItem: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid rgb(226, 227, 230)',
    borderRadius: theme.shape.borderRadius,
    flexGrow: 1,
    height: '50px',
    marginBottom: '12px',
    marginTop: '8px',
    padding: theme.spacing(1, 1.5),
    width: '100%'
  }
}));

export type EditEntityJurisdictionProps = {
  isLoading: boolean;
  // eslint-disable-next-line react/boolean-prop-naming
  buttonsAreDisabled: boolean;
  currentEntitySubJurs: SubJurisdiction[];
  currentJurisdictionSubJurs: SubJurisdiction[];
  onCancel: () => void;
  onSave: (selectedSubJurs: SubJurisdiction[]) => void;
  onDelete: (subJursToDelete: Array<SubJurisdiction['id']>) => void;
};

const EditEntityJurisdiction = ({
  isLoading,
  buttonsAreDisabled,
  currentEntitySubJurs,
  currentJurisdictionSubJurs,
  onCancel,
  onSave,
  onDelete
}: EditEntityJurisdictionProps) => {
  const { t } = useTranslation();

  const [displayedSubJurs, setDisplayedSubJurs] = useState<SubJurisdiction[]>([]);
  const [checkedSubJursToRemove, setCheckedSubJursToRemove] = useState<Array<SubJurisdiction['id']>>([]);
  const [addStarted, setAddStarted] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const checkingStarted = checkedSubJursToRemove.length > 0;

  useEffect(() => {
    setDisplayedSubJurs(currentEntitySubJurs);
  }, [currentEntitySubJurs]);

  const addDisabled = buttonsAreDisabled || checkingStarted;
  const addButtonsDisabled = addDisabled || displayedSubJurs.length === currentEntitySubJurs.length;
  const deletedDisabled = buttonsAreDisabled || addStarted;
  const deleteButtonDisabled = deletedDisabled || checkedSubJursToRemove.length === 0;

  const classes = useStyles({ trashDisabled: deleteButtonDisabled });

  const toggleAllSubJurCheckBoxes = () => {
    if (checkingStarted && checkedSubJursToRemove.length <= displayedSubJurs.length) {
      setCheckedSubJursToRemove([]);
    } else {
      setCheckedSubJursToRemove(displayedSubJurs.map((subJur) => subJur.id));
    }
  };

  const displayedSubJurisdictionsFormatted = useMemo(
    () =>
      displayedSubJurs
        .map((subJur) => ({
          ...formatSubJurisdictionForDisplay(
            t,
            currentJurisdictionSubJurs.find(({ id }) => {
              return subJur?.id === id;
            })
          ),
          checked: checkedSubJursToRemove.some((id) => id === subJur.id)
        }))
        .sort(sortSubJursByName),
    [checkedSubJursToRemove, currentJurisdictionSubJurs, displayedSubJurs, t]
  );

  const searchOptions = useMemo(() => {
    const displayedSubJurisdictionIds = new Set(
      displayedSubJurisdictionsFormatted.map((subJur) => subJur.subJurisdictionId)
    );
    return currentJurisdictionSubJurs
      .filter((subJur) => !displayedSubJurisdictionIds.has(subJur.subJurisdictionId))
      .sort(sortSubJursByName);
  }, [currentJurisdictionSubJurs, displayedSubJurisdictionsFormatted]);

  const topCheckboxIsChecked = checkingStarted && checkedSubJursToRemove.length === displayedSubJurs.length;
  const topCheckBoxIsIndeterminate = checkingStarted && checkedSubJursToRemove.length < displayedSubJurs.length;

  const handleSave = () => {
    onSave(displayedSubJurs);
  };

  const handleDelete = () => {
    if (!deleteButtonDisabled) {
      onDelete(checkedSubJursToRemove);
    }
  };

  const handleSubJurChecked = (subJurId: string, isChecked: boolean) => {
    if (isChecked) {
      setCheckedSubJursToRemove((subJurList) => [...subJurList, subJurId]);
    } else {
      setCheckedSubJursToRemove((subJurList) => subJurList.filter((currSubJurId) => currSubJurId !== subJurId));
    }
  };

  return isLoading ? (
    <LoadingSpinner size={80} />
  ) : (
    <Box className={classes.content}>
      <Box key="search-box" className={classes.searchHeader}>
        <DeleteOutlineIcon className={classes.deleteIcon} onClick={handleDelete} />
        <SearchBox
          blurOnSelect
          disabled={addDisabled}
          id="attribute-selector"
          className={classes.searchBox}
          options={searchOptions}
          getOptionLabel={({ name }: SubJurisdiction) => name || ''}
          inputValue={inputValue}
          value={null}
          placeholder={t('Add new state')}
          onChange={(_e: Event, val: SubJurisdiction) => {
            setDisplayedSubJurs((subJurs) => [...subJurs, val]);
            setAddStarted(true);
            setInputValue('');
          }}
          onInputChange={(_event: Event, value: string) => {
            setAddStarted(true);
            setInputValue(value);
          }}
        />
        <Checkbox
          disabled={deletedDisabled}
          checked={topCheckboxIsChecked}
          indeterminateIcon={<Adjust />}
          indeterminate={topCheckBoxIsIndeterminate}
          icon={<RadioButtonUnchecked />}
          checkedIcon={<RadioButtonChecked />}
          onChange={toggleAllSubJurCheckBoxes}
        />
      </Box>
      <Divider key="divider" />
      <Box key="subJurisdictions" className={classes.menu}>
        {displayedSubJurisdictionsFormatted.length > 0 &&
          displayedSubJurisdictionsFormatted.map(({ id, name, checked }) => (
            <Box key={id} className={classes.menuItem}>
              <Box className={classes.selectedSubJursMenuItem}>{name}</Box>
              <Checkbox
                disabled={deletedDisabled}
                checked={checked}
                icon={<RadioButtonUnchecked />}
                checkedIcon={<RadioButtonChecked />}
                onChange={(event) => {
                  handleSubJurChecked(id, event.target.checked);
                }}
              />
            </Box>
          ))}
      </Box>
      <EditEntityJurisdictionActionButtons
        key="action-buttons"
        buttonsAreDisabled={addButtonsDisabled}
        onCancel={onCancel}
        onSave={handleSave}
      />
    </Box>
  );
};

export default EditEntityJurisdiction;
