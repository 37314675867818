import { createAsyncThunk } from '@reduxjs/toolkit';

import HTTPService from '../../services/http';

export const getUploadHistory = createAsyncThunk('uploadHistory/getUploadHistory', async (_, { rejectWithValue }) => {
  try {
    const { data } = (await HTTPService.request({
      method: 'get',
      relativePath: '/v1/upload-history'
    })) as any;
    return data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});
