import { useReducer, useEffect, useState, useCallback } from 'react';

import { useSelector } from 'react-redux';

import { useFlags } from 'launchdarkly-react-client-sdk';
import pako from 'pako';

import { useContainers } from '..';
import { REPORT_STATUS } from '../../constants';
import { ListedReportShape, Report } from '../../models';
import { ReportMeta } from '../../redux/reports';
import { selectReportsMeta } from '../../selectors/reports';
import HTTPService from '../../services/http';
import transformGroupUuid from '../../utils/transformGroupUuid';

interface UseReportState {
  report: Report | null;
  reportsList: ListedReportShape[] | null;
  isLoading: boolean;
  error: unknown;
}
const INIT = 'INIT';
const SUCCESS_REPORT = 'SUCCESS_REPORT';
const SUCCESS_LIST = 'SUCCESS_LIST';
const FAILURE = 'FAILURE';

interface Init {
  type: typeof INIT;
}
interface SuccessReport {
  type: typeof SUCCESS_REPORT;
  payload: Report;
}
interface SuccessList {
  type: typeof SUCCESS_LIST;
  payload: ListedReportShape[];
}
interface Failure {
  type: typeof FAILURE;
  payload: unknown;
}
type Action = Init | SuccessReport | SuccessList | Failure;

const initialState: UseReportState = {
  report: null,
  reportsList: null,
  isLoading: false,
  error: null
};

function reducer(state: UseReportState, action: Action): UseReportState {
  switch (action.type) {
    case INIT:
      return { ...state, isLoading: true, error: null };
    case SUCCESS_REPORT:
      return { report: action.payload, reportsList: null, isLoading: false, error: null };
    case SUCCESS_LIST:
      return { report: null, reportsList: action.payload, isLoading: false, error: null };
    case FAILURE:
      return { report: null, reportsList: null, isLoading: false, error: action.payload };
    default:
      return state;
  }
}

export const reportFetchDataUtil = async ({
  useCalcEngineReport,
  currentContainer,
  currencyISO,
  isMounted,
  isNotList,
  urlPath,
  dispatch,
  targetContainerId
}: {
  useCalcEngineReport: boolean;
  currentContainer: Record<any, any>;
  currencyISO?: string;
  targetContainerId?: string;
  isMounted?: any;
  isNotList: any;
  urlPath: string;
  dispatch?: any;
}) => {
  const reportDate = currentContainer && new Date(currentContainer.endDate).toISOString().split('T')[0];
  const reportDateCurrencyPath = isNotList ? `/${reportDate}/${currencyISO!}` : '';
  try {
    let { data }: any = await HTTPService.request({
      apiUrlKey: 'reportsApiUrl',
      method: 'get',
      relativePath: `/v1/reports/${urlPath}${reportDateCurrencyPath}${
        targetContainerId ? `?targetContainerId=${targetContainerId}` : ''
      }`
    });

    if (isNotList) {
      if (useCalcEngineReport) {
        const { data: proCalculationReport }: any = await HTTPService.request({
          apiUrlKey: 'calculationReports',
          method: 'get',
          relativePath: `/v1/reports/calc-engine/${urlPath}/${String(currencyISO)}/${String(currentContainer.taxYear)}`
        });
        data = proCalculationReport;
      } else {
        const report: any = await HTTPService.simpleRequest({
          method: 'get',
          url: data?.url ?? data
        });

        if (data?.fileExtension === 'gz') {
          const decompressedData = pako.inflate(report.data, { to: 'string' });
          const reportData = JSON.parse(decompressedData);
          data = reportData;
        } else {
          data = report;
        }
      }
    }

    if (isMounted && dispatch) {
      dispatch({
        type: isNotList ? SUCCESS_REPORT : SUCCESS_LIST,
        payload: data
      });
    } else {
      return data;
    }
  } catch (error: unknown) {
    if (isMounted) {
      dispatch({ type: FAILURE, payload: error });
    }
  }
};

export const reportListFetchDataUtil = async ({
  currencyISO,
  isMounted,
  urlPath,
  dispatch,
  reportSourceId
}: {
  currencyISO?: string;
  isMounted?: any;
  urlPath: string;
  dispatch?: any;
  reportSourceId?: string;
}) => {
  try {
    const { data }: any = await HTTPService.request({
      apiUrlKey: 'reportsApiUrl',
      method: 'get',
      relativePath: `/v1/reports/${urlPath}`,
      params: {
        currencyIsoCode: currencyISO,
        reportSourceId
      }
    });

    if (isMounted && dispatch) {
      dispatch({
        type: SUCCESS_LIST,
        payload: data
      });
    } else {
      return data;
    }
  } catch (error: unknown) {
    if (isMounted) {
      dispatch({ type: FAILURE, payload: error });
    }
  }
};

export function shouldUseCalcEngineReport(reportName: string | undefined) {
  if (
    reportName === 'current-state-etr' ||
    reportName === 'end-state-etr' ||
    reportName === 'beginning-state-etr' ||
    reportName === 'deferred-gross'
  )
    return true;
  return false;
}

export function useReport(reportType: string): UseReportState;
export function useReport(reportType: string, id: string, reportName: string, currencyISO: string): UseReportState;
export function useReport(reportType: string, id: string, reportName: string, currencyISO: string): UseReportState;
export function useReport(reportType: string): UseReportState;
export function useReport(reportType: string, id: string, reportName: string, currencyISO: string): UseReportState;
export function useReport(reportType: string, id?: string, reportName?: string, currencyISO?: string) {
  const { prov3603CalcEngine2Report } = useFlags();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { currentContainer } = useContainers();
  const isNotList = Boolean(id);
  const urlPath = isNotList ? [reportType, id, reportName].join('/') : reportType;
  const useCalcEngineReport = prov3603CalcEngine2Report && shouldUseCalcEngineReport(reportName);

  useEffect(() => {
    let isMounted = true;

    async function fetchData() {
      dispatch({ type: INIT });
      await reportFetchDataUtil({
        useCalcEngineReport,
        currentContainer: currentContainer ?? {},
        currencyISO,
        isMounted,
        isNotList,
        urlPath,
        dispatch
      });
    }

    if (currentContainer?.containerId && urlPath.length > 0) {
      void fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, [currentContainer, urlPath, isNotList, id, currencyISO, reportType, reportName, useCalcEngineReport]);

  return state;
}

export function useReportList(reportType: string, id?: string, currencyISO?: string) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { currentContainer } = useContainers();
  const isNotList = Boolean(id);
  const urlPath = reportType;

  useEffect(() => {
    let isMounted = true;

    async function fetchData() {
      dispatch({ type: INIT });
      await reportListFetchDataUtil({
        currencyISO,
        isMounted,
        reportSourceId: id,
        urlPath,
        dispatch
      });
    }

    if (currentContainer?.containerId && urlPath.length > 0 && id && currencyISO !== undefined) {
      void fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, [currentContainer, urlPath, isNotList, currencyISO, id]);

  return state;
}

// eslint-disable-next-line max-params
export function useSpecificReport(
  reportType: string,
  id?: string,
  reportName?: string,
  currencyISO?: string,
  targetContainerId?: string
) {
  const { prov3603CalcEngine2Report } = useFlags();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { currentContainer } = useContainers();
  const isNotList = Boolean(id);
  const urlPath = isNotList ? [reportType, id, reportName].join('/') : reportType;
  const useCalcEngineReport = prov3603CalcEngine2Report && shouldUseCalcEngineReport(reportName);

  useEffect(() => {
    let isMounted = true;

    async function fetchData() {
      dispatch({ type: INIT });
      await reportFetchDataUtil({
        useCalcEngineReport,
        targetContainerId,
        currentContainer: currentContainer ?? {},
        currencyISO,
        isMounted,
        isNotList,
        urlPath,
        dispatch
      });
    }

    if (currentContainer?.containerId && urlPath.length > 0) {
      void fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, [currentContainer, urlPath, isNotList, targetContainerId, currencyISO, useCalcEngineReport]);

  return state;
}

// eslint-disable-next-line max-params
export function useMiscReport(
  reportType: string,
  id?: string,
  reportName?: string,
  currencyISO?: string,
  targetContainerId?: string
) {
  const { prov3603CalcEngine2Report } = useFlags();
  const { currentContainer } = useContainers();
  const { isLoading, report: originalReport, error } = useSpecificReport(
    reportType,
    id,
    reportName,
    currencyISO,
    targetContainerId
  );
  const isCurrencyConversion = currencyISO === 'USD';
  const useCalcEngineReport = prov3603CalcEngine2Report && shouldUseCalcEngineReport(reportName);
  // report state update watch is assumed to work with reports from the container only
  const shouldWatchReportMetaUpdates = !targetContainerId || currentContainer?.containerId === targetContainerId;
  const reportsMeta = useSelector(selectReportsMeta);
  const reportsMetaKey = `${String(reportName)}_${String(transformGroupUuid(id!))}_${String(isCurrencyConversion)}`;
  const currentReportMeta = reportsMeta[reportType][reportsMetaKey];
  const [report, setReport] = useState<Report | null>(originalReport);
  const [previousReportMeta, setPreviousReportMeta] = useState<ReportMeta | null>(null);

  useEffect(() => {
    setReport(originalReport);
  }, [originalReport]);

  useEffect(() => {
    if (report && shouldWatchReportMetaUpdates) {
      const isNewReport =
        currentReportMeta?.status === REPORT_STATUS.ready &&
        JSON.stringify(previousReportMeta) !== JSON.stringify(currentReportMeta);

      if (isNewReport) {
        const isNotList = Boolean(id);
        const urlPath = isNotList ? [reportType, id, reportName].join('/') : reportType;

        // eslint-disable-next-line no-async-promise-executor
        void new Promise(async (resolve) => {
          const newReport = await reportFetchDataUtil({
            useCalcEngineReport,
            currentContainer: currentContainer ?? {},
            currencyISO,
            isNotList,
            urlPath,
            targetContainerId
          });

          resolve(newReport);
        }).then((newReport: any) => {
          setReport(newReport);
        });
      }

      setPreviousReportMeta(currentReportMeta);
    }
  }, [
    currentReportMeta,
    report,
    reportsMetaKey,
    previousReportMeta,
    id,
    reportType,
    reportName,
    currentContainer,
    currencyISO,
    shouldWatchReportMetaUpdates,
    targetContainerId,
    useCalcEngineReport
  ]);

  return {
    error,
    isLoading,
    report
  };
}

// eslint-disable-next-line max-params
export function useDetailedReport(
  reportType: string,
  id?: string,
  reportName?: string,
  currencyISO?: string,
  targetContainerId?: string
) {
  const { prov3603CalcEngine2Report } = useFlags();
  const { currentContainer } = useContainers();
  const { isLoading, report: originalReport, error } = useSpecificReport(
    reportType,
    id,
    reportName,
    currencyISO,
    targetContainerId
  );
  const useCalcEngineReport = prov3603CalcEngine2Report && shouldUseCalcEngineReport(reportName);
  const isCurrencyConversion = currencyISO === 'USD';
  const reportsMeta = useSelector(selectReportsMeta);
  const reportsMetaKey = `${String(reportName)}_${String(transformGroupUuid(id!))}_${String(isCurrencyConversion)}`;
  const currentReportMeta = reportsMeta[reportType][reportsMetaKey];
  const [report, setReport] = useState<Report | null>(originalReport);
  const [previousReportMeta, setPreviousReportMeta] = useState<ReportMeta | null>(null);
  const [isNewReportAvailable, setIsNewReportAvailable] = useState(false);
  const [newReport, setNewReport] = useState<Report | null>(null);

  const setNewReportAsCurrent = useCallback(() => {
    setIsNewReportAvailable(false);
    setReport(newReport);
  }, [newReport]);

  useEffect(() => {
    setReport(originalReport);
  }, [originalReport]);

  useEffect(() => {
    if (report) {
      const isOriginalReportEmpty = !report.values || (report.values && report.values.length === 0);
      const isNewReport =
        currentReportMeta?.status === REPORT_STATUS.ready &&
        JSON.stringify(previousReportMeta) !== JSON.stringify(currentReportMeta);

      if (isNewReport) {
        const isNotList = Boolean(id);
        const urlPath = isNotList ? [reportType, id, reportName].join('/') : reportType;

        // eslint-disable-next-line no-async-promise-executor
        void new Promise(async (resolve) => {
          const newReport = await reportFetchDataUtil({
            useCalcEngineReport,
            currentContainer: currentContainer ?? {},
            currencyISO,
            isNotList,
            urlPath
          });

          resolve(newReport);
        }).then((newReport: any) => {
          const newReportFragments = { headers: newReport?.headers, rows: newReport.values };
          const oldReportFragments = { headers: report?.headers, rows: report.values };
          const isWorthReRendering = JSON.stringify(newReportFragments) !== JSON.stringify(oldReportFragments);
          if (isOriginalReportEmpty) {
            setIsNewReportAvailable(false);
            setReport(newReport);
          } else {
            setIsNewReportAvailable(isWorthReRendering);
            setNewReport(newReport);
          }
        });
      }

      setPreviousReportMeta(currentReportMeta);
    }
  }, [
    currentReportMeta,
    report,
    previousReportMeta,
    id,
    reportType,
    reportName,
    currentContainer,
    currencyISO,
    useCalcEngineReport
  ]);

  return {
    error,
    isLoading,
    report,
    isNewReportAvailable,
    currentReportMeta,
    newReport,
    setNewReportAsCurrent
  };
}
