import { LegacyRef, forwardRef, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Box, Button, Divider, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createFilterOptions } from '@material-ui/lab';

import { Currency, Jurisdiction, Row } from '../../models';
import BoxedText from '../BoxedText';
import SearchBox from '../SearchBox';
import { disablePointerEventsForNonAdmins } from '../UserRoleStylesProvider/constants';

export type SelectorOption = { label: string; id: string | number };

const getInputField = (): HTMLInputElement | null =>
  document.querySelector(`[data-id="action-search-box-component"] input`);

const ActionSearchBox = ({
  row,
  optionsById,
  nameKey,
  valueKey,
  getOptionLabel,
  getDisplayLabel,
  handleSave
}: {
  row: Row;
  getOptionLabel?: (arg0: SelectorOption) => string;
  getDisplayLabel?: (arg0: SelectorOption) => string;
  nameKey: 'isoCode' | 'name';
  valueKey: 'jurisdictionId' | 'currencyId';
  optionsById: Record<string, Currency | Jurisdiction>;
  handleSave: any;
}) => {
  const { t } = useTranslation();
  const useStyles = makeStyles((theme) => ({
    '@global': {
      '& [data-id="action-search-box-selector"]': {
        backgroundColor: theme.palette.common.white,
        marginBottom: '12px',
        marginTop: '8px',
        '& .MuiAutocomplete-option': {
          '&:hover': {
            backgroundColor: `${theme.palette.info.main}ff!important`,
            borderLeft: 'solid 3px green',
            color: '#025962ff',
            fontWeight: 'bolder'
          }
        }
      }
    },
    actionButtons: {
      textAlign: 'right',
      padding: '5px',
      '& button': {
        margin: '5px',
        paddingTop: '2px',
        paddingBottom: '2px',
        paddingLeft: '20px',
        paddingRight: '20px'
      }
    },
    boxedText: {
      paddingLeft: '20px',
      paddingRight: '20px',
      '&:hover': {
        backgroundColor: theme.palette.action.hover
      }
    },
    viewAllButton: {
      color: theme.palette.primary.lighter,
      width: '100%',
      justifyContent: 'left',
      fontSize: 'small'
    },
    cursorPointer: {
      cursor: 'pointer'
    }
  }));
  const classes = useStyles();

  const options: SelectorOption[] =
    Object.entries(optionsById).map(([id, object]) => ({ id, label: object[nameKey] })) || [];

  const listItemsDefaultLimit = 6;
  const [listItemsLimit, setListItemsLimit] = useState<number | undefined>(listItemsDefaultLimit);
  const [selectedOption, setSelectedOption] = useState<SelectorOption | null>(null);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(true);

  const [firstFocus, setFirstFocus] = useState<boolean>(false);

  useEffect(() => {
    if (!firstFocus) {
      getInputField()?.focus();
    }
  });

  if (!getDisplayLabel) {
    getDisplayLabel = (option: SelectorOption) => option.label;
  }

  if (!getOptionLabel) {
    getOptionLabel = (option: SelectorOption) => option.label;
  }

  const viewAllToggleHandler = () => {
    setListItemsLimit(listItemsLimit ? undefined : listItemsDefaultLimit);
  };

  const actuallyClosePopup = () => {
    setListItemsLimit(listItemsDefaultLimit);
    setOpen(false);
    setIsUpdating(false);
    setFirstFocus(false);
    setSelectedOption(null);
  };

  const onSave = () => {
    if (selectedOption) {
      handleSave(row.entityId, row?.[valueKey], selectedOption.id);
    }

    actuallyClosePopup();
  };

  const onCancel = () => {
    actuallyClosePopup();
  };

  const onClose = (_event: any, eventType: string) => {
    if (!['toggleInput', 'blur'].includes(eventType)) {
      actuallyClosePopup();
    }

    if (eventType === 'escape') onCancel();
  };

  const onBlur = (event: any, _eventType: string) => {
    const clickInsidePopup = Boolean(event.relatedTarget);
    if (clickInsidePopup) {
      getInputField()?.focus();
      setOpen(true);
    } else {
      setSelectedOption(selectedOption);
      actuallyClosePopup();
    }
  };

  const onInputChange = (_event: any, inputValue: string, eventType: string) => {
    if (eventType === 'input') {
      const matchedOption = options.find(({ label }) => {
        return label.toLocaleLowerCase() === inputValue.toLocaleLowerCase();
      });

      setSelectedOption(matchedOption ? matchedOption : null);
    }
  };

  const onFocus = () => {
    if (!firstFocus) {
      setFirstFocus(true);
    }

    setOpen(true);
  };

  const onChange = (_event: any, changedValue: SelectorOption) => {
    getInputField()?.focus();
    if (changedValue) {
      setSelectedOption(changedValue);
    }
  };

  const disableSaveButton = selectedOption === null;

  const ListboxComponent = forwardRef(({ ...props }: any, ref) => {
    return (
      <>
        <ul
          ref={ref as LegacyRef<HTMLUListElement>}
          data-testid="action-search-box-selector"
          data-id="action-search-box-selector"
          {...props}
        />
        <Button className={classes.viewAllButton} onClick={viewAllToggleHandler}>
          {t(listItemsLimit ? 'VIEW ALL' : 'VIEWING ALL')}
        </Button>
        <Divider />
        <div className={classes.actionButtons}>
          <Button onClick={onCancel}>{t('cancel')}</Button>
          <Button variant="contained" color="primary" disabled={disableSaveButton} onClick={onSave}>
            {t('save')}
          </Button>
        </div>
      </>
    );
  });

  const objectToShow = options.find((option) => option.id === String(row[valueKey]));
  const labelToShow = objectToShow ? getDisplayLabel(objectToShow) : 'No Value';

  return isUpdating ? (
    <SearchBox
      disableCloseOnSelect
      forcePopupIcon
      freeSolo
      data-id="action-search-box-component"
      open={open}
      options={options}
      getOptionLabel={getOptionLabel}
      filterOptions={createFilterOptions({ limit: listItemsLimit })}
      renderInput={(params: any) => <TextField {...params} label="Currency" />}
      getOptionSelected={(nItem: SelectorOption, actualValue: SelectorOption) => nItem?.id === actualValue?.id}
      ListboxComponent={ListboxComponent}
      onInputChange={onInputChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={onChange}
      onClose={onClose}
    />
  ) : (
    <Box
      display="flex"
      width={1}
      alignItems="center"
      className={classes.cursorPointer}
      data-roles-disable-pointer-events={disablePointerEventsForNonAdmins.join(' ')}
      onClick={() => {
        setIsUpdating(true);
      }}
    >
      <BoxedText className={classes.boxedText}>{labelToShow}</BoxedText>
    </Box>
  );
};

export default ActionSearchBox;
