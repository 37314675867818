import { Theme, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles<Theme, { selected: boolean }>((theme) => ({
  container: {
    paddingTop: '6px',
    paddingBottom: '6px',
    paddingLeft: '12px',
    paddingRight: '12px',
    backgroundColor: ({ selected }) => (selected ? '#EFEFFF' : 'transparent'),
    border: ({ selected }) => `1px solid ${selected ? '#232A5C' : '#C5C5C9'}`,
    borderRadius: '4px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  searchFocusOptions: {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
    width: '100%',
    marginTop: '13px'
  },
  title: {
    color: theme.palette.text.secondary,
    fontFamily: 'Basis Grotesque Pro Medium',
    letterSpacing: '0.25px',
    fontSize: '12px'
  }
}));

const SearchFocusOption = ({ text, selected, onClick }: { text: string; selected: boolean; onClick: () => void }) => {
  const classes = useStyles({ selected });

  return (
    <Box className={classes.container} onClick={onClick}>
      {text}
    </Box>
  );
};

export default SearchFocusOption;
