import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import AuditTrailResults from './components/AuditTrailResults';
import FilterControls from './components/FilterControls';
import { FilterControlsProps } from './components/FilterControls/FilterControls';

export interface AuditTrailProps extends FilterControlsProps {
  auditTrailData: AuditTrailFromApi;
  isLoading: boolean;
  totalPages: number;
  pageSizeOptions: number[];
  currentPageNumber: number;
  currentPageSize: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (page: number) => void;
}

import { AuditTrailFromApi } from '../../models';
import PageSelector from '../PageSelector';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.primary.light,
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    padding: '19px 30px 10px'
  }
}));

const AuditTrail = ({
  auditTrailData,
  isLoading,
  startDate,
  endDate,
  entityFilter,
  entityFilterOptions,
  userFilter,
  userFilterOptions,
  dataTypeFilter,
  dataTypeFilterOptions,
  totalPages,
  pageSizeOptions,
  currentPageNumber,
  currentPageSize,
  onDatePeriodSave,
  onDatePeriodClear,
  onEntityFilterChange,
  onUserFilterChange,
  onDataTypeFilterChange,
  onPageChange,
  onPageSizeChange
}: AuditTrailProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <FilterControls
        startDate={startDate}
        endDate={endDate}
        entityFilter={entityFilter}
        userFilter={userFilter}
        dataTypeFilter={dataTypeFilter}
        entityFilterOptions={entityFilterOptions}
        userFilterOptions={userFilterOptions}
        dataTypeFilterOptions={dataTypeFilterOptions}
        onDatePeriodSave={onDatePeriodSave}
        onDatePeriodClear={onDatePeriodClear}
        onEntityFilterChange={onEntityFilterChange}
        onUserFilterChange={onUserFilterChange}
        onDataTypeFilterChange={onDataTypeFilterChange}
      />
      <AuditTrailResults response={auditTrailData} isLoading={isLoading} />
      <PageSelector
        totalPages={totalPages}
        isLoading={isLoading}
        pageSizeOptions={pageSizeOptions}
        currentPageNumber={currentPageNumber}
        currentPageSize={currentPageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      />
    </Box>
  );
};

export default AuditTrail;
