const getSubJurisdictionsSelection = (
  prevSubJurisdictionsSelection: string[],
  incomingSubJurisdictionIds: string[]
) => {
  const hasNewIds = !incomingSubJurisdictionIds.every((id) => prevSubJurisdictionsSelection.includes(id));

  const currentSubJurisdictionsSelection = hasNewIds
    ? [...new Set([...prevSubJurisdictionsSelection, ...incomingSubJurisdictionIds])]
    : prevSubJurisdictionsSelection.filter((id: string) => !incomingSubJurisdictionIds.includes(id));

  // select the first subjurisdiction if all are deselected using the select all button
  const subJurisdictionsSelection =
    currentSubJurisdictionsSelection.length > 0 ? currentSubJurisdictionsSelection : [incomingSubJurisdictionIds[0]];

  return subJurisdictionsSelection;
};

export default getSubJurisdictionsSelection;
