import { ReactNode } from 'react';

import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

type SelectedItemChipProps = {
  text: string;
  title?: string;
  icon?: ReactNode;
  className?: any;
  mainSectionRef?: any;
};

const useStyles = makeStyles((theme) => ({
  container: {
    border: '1px solid #6E56DB',
    borderRadius: '2px',
    backgroundColor: theme.palette.info.main,
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  mainSection: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  main: {
    width: '100%',
    height: '100%',
    marginLeft: '13px',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '3px',
    paddingBottom: '3px'
  },
  mainText: {
    fontSize: '14px',
    letterSpacing: '0.25px'
  },
  title: {
    paddingLeft: '13px',
    paddingRight: '13px',
    backgroundColor: '#DCDBFE',
    borderRight: '1px solid #6E56DB',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '3px',
    paddingBottom: '3px'
  },
  titleText: {
    fontSize: '14px',
    fontFamily: 'Basis Grotesque Pro Medium',
    letterSpacing: '0.25px'
  },
  icon: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

const SelectedItemChip = ({ text, title, icon, className, mainSectionRef }: SelectedItemChipProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {title && (
        <Box className={classes.title}>
          <Typography noWrap className={(className?.titleText as string) ?? classes.titleText}>
            {title}
          </Typography>
        </Box>
      )}
      <Box ref={mainSectionRef} className={classes.mainSection}>
        <Box className={classes.main}>
          <Typography className={(className?.mainText as string) ?? classes.mainText}>{text}</Typography>
        </Box>
        {icon && <Box className={classes.icon}>{icon}</Box>}
      </Box>
    </Box>
  );
};

export default SelectedItemChip;
