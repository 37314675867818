import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';

import { FEDERAL_UUID, LEVELS } from '../../../../constants';
import { useCompletionStatus, useCurrencies, useFinancialData } from '../../../../hooks';
import { FederalTabProps, Level, Step } from '../../../../models';
import { setEntityCompletionStatus } from '../../../../redux/entitiesCompletionStatus';
import LoadingWrapper from '../../../LoadingWrapper';
import BeginningBalance from '../../../SharedComponents/FederalOrStateAccounts/components/BeginningBalance';
import CurrentYearPayments from '../../../SharedComponents/FederalOrStateAccounts/components/CurrentYearPayments';
import InterestAndPenalties from '../../../SharedComponents/FederalOrStateAccounts/components/InterestAndPenalties';
import OtherAdjustments from '../../../SharedComponents/FederalOrStateAccounts/components/OtherAdjustments';
import PriorYearPayments from '../../../SharedComponents/FederalOrStateAccounts/components/PriorYearPayments';
import { ContentAndTitle, TabTitle } from '../../components';
import { EntityNumberRouteMatch } from '../../utils';

const LEVEL: Level = LEVELS.FEDERAL;
const STEP: Step = 'accounts';
const STEPS: Step[] = [STEP];
const LevelAndStep = `${LEVEL}.${STEP}`;

const useStyles = makeStyles(() => ({
  federalAccountsBox: {
    overflow: 'auto',
    '& .MuiTableCell-body.no-rows': {
      display: 'none'
    }
  }
}));

const FederalAccounts = ({ entityId }: FederalTabProps) => {
  const { federalAccountsBox } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    params: { entityNumber }
  } = useRouteMatch<EntityNumberRouteMatch>();
  const { stepCompletionStatus } = useCompletionStatus(entityNumber, LevelAndStep, FEDERAL_UUID);
  const { tabsData, failedCells, isFetchLoading } = useFinancialData(entityNumber, LEVEL, STEPS);
  const { currencyByEntityIdMap } = useCurrencies();
  const currencyIsoCode = currencyByEntityIdMap[entityId]?.isoCode;

  return (
    <LoadingWrapper isLoading={isFetchLoading}>
      <ContentAndTitle
        title={
          <TabTitle
            currencyIsoCode={currencyIsoCode}
            title={t('accounts')}
            isCompleted={stepCompletionStatus.status}
            shouldDisplayDataImportButton={false}
            onCompletionChange={(checked) => {
              dispatch(
                setEntityCompletionStatus({
                  ...stepCompletionStatus,
                  newStatus: checked
                })
              );
            }}
          />
        }
      >
        <Box className={federalAccountsBox}>
          <CurrentYearPayments
            isFederal
            entityId={entityId}
            failedCells={failedCells}
            stepCompletionStatus={stepCompletionStatus.status}
            tabsData={tabsData}
          />
          <BeginningBalance
            isFederal
            entityId={entityId}
            failedCells={failedCells}
            stepCompletionStatus={stepCompletionStatus.status}
            tabsData={tabsData}
          />
          <PriorYearPayments
            isFederal
            entityId={entityId}
            failedCells={failedCells}
            stepCompletionStatus={stepCompletionStatus.status}
            tabsData={tabsData}
            states={[]}
          />
          <InterestAndPenalties
            isFederal
            entityId={entityId}
            failedCells={failedCells}
            stepCompletionStatus={stepCompletionStatus.status}
            tabsData={tabsData}
          />
          <OtherAdjustments
            isFederal
            entityId={entityId}
            failedCells={failedCells}
            stepCompletionStatus={stepCompletionStatus.status}
            tabsData={tabsData}
          />
        </Box>
      </ContentAndTitle>
    </LoadingWrapper>
  );
};

export default FederalAccounts;
