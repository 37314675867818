import { IconButton } from '@material-ui/core';
import saveAs from 'file-saver';

import { getExcelFile } from '../ReportList/utils';

type ReportExcelDownloadButtonProps = {
  reportType: string;
  reportModelId: string;
  reportName: string;
  currencyIsoCode: string | undefined;
  taxYear: number | undefined;
  title: string | undefined;
};

const ReportExcelDownloadButton = ({
  reportType,
  reportModelId,
  reportName,
  currencyIsoCode,
  taxYear,
  title = 'report'
}: ReportExcelDownloadButtonProps) => {
  const handleButtonClick = async () => {
    const response: string = await getExcelFile({
      reportType,
      reportModelId,
      reportName,
      currencyIsoCode,
      taxYear
    });
    const byteArray = new Uint8Array(response.length / 2);
    for (let i = 0; i < byteArray.length; i++) {
      byteArray[i] = Number.parseInt(response.slice(i * 2, i * 2 + 2), 16);
    }

    const blob = new Blob([byteArray], { type: 'application/octet-stream' });
    saveAs(blob, `${title}.xlsx`);
  };

  return <IconButton onClick={handleButtonClick}>XLSX</IconButton>;
};

export default ReportExcelDownloadButton;
