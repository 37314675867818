import { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Box, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { AccountRangesTabProps, ColorSpecs } from './AccountRangesTab.proptype';
import { alphaSortById } from './utils';

import { EntityTableHeader, Layout, RangeSelector } from '..';
import { Table } from '../../..';
import { GenericRecord } from '../../../../calculations/utils';
import { AccountRange, AccountShape } from '../../../../models';
import { updateTabData } from '../../../../redux/uploadReview';
import { getInitialStateForRanges } from '../../../../utils';
import { flattenAccounts as flatten } from '../../utils';

const colorSpecs: ColorSpecs = {
  assets: { plain: '#e57373', background: '#f8efef' },
  liabilities: { plain: '#ffb74d', background: '#faf5ec' },
  shareholdersEquity: { plain: '#81c784', background: '#f0f6f0' },
  profitAndLoss: { plain: '#64b5f6', background: '#b1ddf0' }
};

const useStyles = makeStyles((theme) => ({
  rangeDivider: {
    margin: theme.spacing(1.2, 0)
  }
}));

const genericRecord: GenericRecord = {};

const AccountRangesTab = ({ accounts, rangeSpecs, entities }: AccountRangesTabProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [ranges, setRanges] = useState(rangeSpecs);

  const flattenAccounts: AccountShape[] = useMemo(() => flatten(accounts, entities).sort(alphaSortById), [
    accounts,
    entities
  ]);

  const [{ counts, rangeValidity, styles }, setState] = useState(
    getInitialStateForRanges({ flattenAccounts, colorSpecs, ranges: rangeSpecs })
  );

  useEffect(() => {
    setState(getInitialStateForRanges({ flattenAccounts, colorSpecs, ranges: rangeSpecs }));
    setRanges(rangeSpecs);
  }, [flattenAccounts, rangeSpecs]);

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: t('Account'),
        width: '10%',
        getCellStyle: ({ id }: { id: string }) => styles[id] ?? genericRecord
      },
      {
        field: 'description',
        headerName: t('Description'),
        width: '25%',
        getCellStyle: ({ id }: { id: string }) => styles[id] ?? genericRecord,
        sticky: true,
        divider: true
      },
      ...entities.map(({ id: field, name }) => ({
        field,
        isNumber: true,
        renderHeader: () => <EntityTableHeader id={field} name={name} />
      }))
    ],
    [entities, t, styles]
  );

  const handleOnChange = (index: number, rangeData: AccountRange) => {
    const clonedRanges = [...ranges];
    clonedRanges[index] = rangeData;

    dispatch(updateTabData(clonedRanges));
  };

  return (
    <Layout
      asideTitle={t('Account ranges')}
      description={t('Please define the following ranges within the Trial Balance.')}
      asideContent={
        <Box overflow="auto">
          {ranges.map(({ id, range }, index) => (
            <Box key={id}>
              {index > 0 && <Divider className={classes.rangeDivider} />}
              <RangeSelector
                name={t(id)}
                color={colorSpecs[id].plain}
                range={range}
                count={counts[id]}
                isValid={rangeValidity[id]}
                options={accounts}
                onChange={(range) => {
                  handleOnChange(index, { id, range });
                }}
                onEdit={() => {
                  setRanges([...ranges.slice(0, index), { id, range: ['', ''] }, ...ranges.slice(index + 1)]);
                }}
                onRemove={() => {
                  dispatch(updateTabData([...ranges.slice(0, index), { id, range: [] }, ...ranges.slice(index + 1)]));
                }}
              />
            </Box>
          ))}
        </Box>
      }
      mainTitle={t('Account Listing')}
      dataCountLabel={t('_ found', { count: accounts?.length })}
      mainContent={<Table columns={columns} rows={flattenAccounts} isNotEditableShaded={false} />}
    />
  );
};

export default AccountRangesTab;
