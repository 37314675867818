import { useTranslation } from 'react-i18next';
import { Link, Redirect, Route, Switch, useRouteMatch, useLocation } from 'react-router-dom';

import { Box, CircularProgress, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  const spacing = theme.spacing(2);
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    },
    title: {
      padding: spacing
    },
    progressContainer: {
      display: 'flex',
      justifyContent: 'center',
      padding: spacing
    }
  };
});

interface TabShape {
  value: string;
  render: () => JSX.Element;
}

interface Props {
  tabs: TabShape[];
  label: string;
  isLoading?: boolean;
  defaultTab: string;
}

const TitleNavigation = ({ tabs, label, isLoading, defaultTab }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();

  const tabRoot = pathname.replace(path, '').split('/')[1];
  const currentTab = tabs.some(({ value }: any) => value === tabRoot) ? tabRoot : defaultTab;

  return (
    <Box className={classes.root}>
      <Typography variant="h2" component="h1" className={classes.title}>
        {t(label)}
      </Typography>
      <Tabs indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto" value={currentTab}>
        {tabs.map(({ value }) => (
          <Tab key={value} label={t(`${label}-${value}`)} value={value} component={Link} to={`${path}/${value}`} />
        ))}
      </Tabs>
      <Switch>
        {tabs.map(({ value, render }) => (
          <Route key={value} path={`${path}/${value}`}>
            {isLoading ? (
              <Box className={classes.progressContainer}>
                <CircularProgress />
              </Box>
            ) : (
              render()
            )}
          </Route>
        ))}
        <Redirect to={`${path}/${tabs[0].value}`} />
      </Switch>
    </Box>
  );
};

export default TitleNavigation;
