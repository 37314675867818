import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { WalkMeConstants } from '../../constants';
import { selectAuthInfo, selectConfiguration, selectIsAuthenticated } from '../../selectors';
import hasKey from '../../utils/hasKey';

const WalkMe = ({ children }: { children: JSX.Element }) => {
  const authInfo = useSelector(selectAuthInfo);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const { xbsEnvironment } = useSelector(selectConfiguration);

  useEffect(() => {
    const node = document.createElement('script');
    node.type = 'text/javascript';
    if (hasKey(WalkMeConstants, xbsEnvironment)) {
      node.innerHTML = WalkMeConstants[xbsEnvironment] ?? '';
    }

    document.querySelectorAll('head')[0].append(node);
  }, [xbsEnvironment]);

  useEffect(() => {
    if (isAuthenticated) {
      (window as any).userId = authInfo.userId;
      (window as any).roleId = authInfo.roleId;
      (window as any).roleUuid = authInfo.roleUuid;
      (window as any).isXBSEmployee = authInfo.isXBSEmployee;
    }
  }, [isAuthenticated, authInfo]);

  return children;
};

export default WalkMe;
