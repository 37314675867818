import { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { reducer, makeInitialState } from './reducer';
import { StatesTabProps } from './StatesTab.proptype';

import { TabLayout } from '..';
import { EntityFromUpload, SubJurisdiction, SubJurisdictionsByEntityId } from '../../../../models';
import { formatSubJurisdictionForDisplay, sortSubJursByName } from '../../../../utils';

const StatesTab = ({ jurisdictions, entities, jurisdictionByEntityId, subJurisdictionsByEntityId }: StatesTabProps) => {
  const { t } = useTranslation();
  const [editedSubJurisdiction, setEditedSubJurisdiction] = useState<SubJurisdiction | null>(null);

  const subJurisdictions: SubJurisdiction[] = useMemo(() => {
    if (!Array.isArray(jurisdictions)) {
      return [];
    }

    const results = [];
    for (const jurisdiction of jurisdictions) {
      results.push(
        ...jurisdiction.subJurisdictions.map((subJurisdiction) => formatSubJurisdictionForDisplay(t, subJurisdiction))
      );
    }

    return results.sort(sortSubJursByName);
  }, [jurisdictions, t]);

  const assignableEntities: EntityFromUpload[] = entities.filter((entity) => {
    const candidateSubJurisdictions = jurisdictionByEntityId[entity.id].subJurisdictions;
    return editedSubJurisdiction
      ? candidateSubJurisdictions.some(({ id }) => id === editedSubJurisdiction.id)
      : candidateSubJurisdictions.length > 0;
  });

  return (
    <TabLayout
      asideTitle={t('States / Cities')}
      mainTitle={t('Entity List')}
      description={t('Please select all the states and cities applicable to each entity within the Trial Balance.')}
      searchCaption={t('Assign States / Cities to Entities')}
      searchPlaceholder={t('Select a State (New York, Florida, etc)')}
      attributeValues={subJurisdictions}
      columns={[
        {
          field: 'id',
          filterable: true,
          sortable: true,
          placeholder: t('Entity No.'),
          width: '20%'
        },
        {
          field: 'name',
          filterable: true,
          sortable: true,
          placeholder: t('Entity Name'),
          width: '40%'
        },
        {
          field: 'jurisdictions',
          filterable: true,
          sortable: true,
          placeholder: t('Jurisdictions'),
          getValue: (row: EntityFromUpload) => jurisdictionByEntityId[row.id].name
        }
      ]}
      attributeColumnName={t('States / Cities')}
      dataCountLabel={t('_ found', { count: assignableEntities.length })}
      data={assignableEntities}
      attributeByRowId={subJurisdictionsByEntityId}
      noRowLabel={t('No Entities found')}
      reducer={reducer}
      makeInitialState={makeInitialState}
      makeAttributeColumnGetter={(assignedAttributeByRowId: SubJurisdictionsByEntityId) => (row: EntityFromUpload) => {
        return assignedAttributeByRowId[row.id]?.length || '-';
      }}
      isAttributeColumnFilterable={false}
      onEditedAttribute={setEditedSubJurisdiction}
    />
  );
};

export default StatesTab;
