import { AccountRange, AccountShape, Category, Entity } from '../../../../../models';
import { isInRange } from '../../../../../utils';
import { flattenAccounts } from '../../../utils';

export const START_EDITING = 'START_EDITING';
export const SET_DATA = 'SET_DATA';
export const TOGGLE_ROWS = 'TOGGLE_ROWS';
export const STOP_EDITING = 'STOP_EDITING';

type MakeInitialStateProps = {
  categorySpecs: Category[];
  ranges: AccountRange[];
  entities: Entity[];
  accounts: AccountShape[];
  rangeIdsByCategoryId: Record<string, string[]>;
};

type SelectRowsProps = {
  accounts: AccountShape[];
  categories: Category[];
  ranges: AccountRange[];
  edited?: Category;
  rangeIdsByCategoryId: Record<string, string[]>;
};

export function makeInitialState({
  categorySpecs: categories,
  ranges,
  entities,
  accounts,
  rangeIdsByCategoryId
}: MakeInitialStateProps) {
  const flatten = flattenAccounts(accounts, entities);
  return {
    entities,
    ranges,
    accounts: flatten,
    rows: selectRows({ accounts: flatten, ranges, categories, rangeIdsByCategoryId }),
    selected: [],
    edited: null,
    categories,
    rangeIdsByCategoryId
  };
}

function selectRows({ accounts, categories, ranges, edited, rangeIdsByCategoryId }: SelectRowsProps) {
  if (!edited) {
    return accounts;
  }

  const allowedRanges = ranges.filter((range) => rangeIdsByCategoryId[edited.id].includes(range.id));
  const selectedAccountIds = new Set(
    categories.map(({ id, accountIds }: any) => (id === edited.id ? [] : accountIds)).flat()
  );
  return accounts.filter(
    ({ id }: any) => !selectedAccountIds.has(id) && allowedRanges.find(({ range }) => isInRange(id, range))
  );
}

export function reducer(state: any, { type, payload = {} }: any = {}) {
  switch (type) {
    case SET_DATA: {
      return makeInitialState({
        ...state,
        ...payload
      });
    }

    case START_EDITING: {
      const edited = state.categories.find(({ id }: any) => payload.id === id) || null;
      const rows = selectRows({ ...state, edited });
      return {
        ...state,
        edited,
        rows,
        selected: edited ? rows.filter(({ id }: any) => edited.accountIds.includes(id)) : []
      };
    }

    case STOP_EDITING: {
      const edited = null;
      return {
        ...state,
        edited,
        rows: selectRows({ ...state, edited }),
        selected: []
      };
    }

    case TOGGLE_ROWS: {
      const { categories, rows } = state;
      if (!state.edited) {
        return state;
      }

      const idx: number = categories.indexOf(state.edited);
      const accountIds = [];
      for (const accountId of state.edited.accountIds) {
        if (!payload.areSelected || !payload.rowIds.includes(accountId)) {
          accountIds.push(accountId);
        }
      }

      if (!payload.areSelected) {
        accountIds.push(...payload.rowIds);
      }

      const edited = { ...state.edited, accountIds };
      return {
        ...state,
        edited,
        selected: rows.filter(({ id }: any) => edited.accountIds.includes(id)),
        categories: [...categories.slice(0, idx), edited, ...categories.slice(idx + 1)]
      };
    }

    default:
      return state;
  }
}
