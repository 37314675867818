import { useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Box, CircularProgress, Popover, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { drawGeoJson } from './d3';
import { GeoMapProps, HoveredState } from './GeoMap.proptype';
import { formatHoveredValue, getFetchingParams, getHoveredValue, getMapValues } from './utils';
import { default as ZoomButtons } from './ZoomButtons';

import { DASHBOARD_CARD_TYPES } from '../../../../constants';
import { useDimensions } from '../../../../hooks';
import { useCountriesData } from '../../../../hooks/useCountriesData';
import ToggleButtons from '../../../ToggleButtons';

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    position: 'relative',
    height: '100%'
  },
  map: {
    height: '100%'
  },
  toggleContainer: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    zIndex: 1,
    borderRadius: '7px',
    border: `7px solid ${theme.palette.info.main}`
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1),
    overflow: 'hidden',
    position: 'relative',
    '& .country.selected': {
      cursor: 'pointer'
    }
  },
  popover: {
    pointerEvents: 'none',
    opacity: theme.palette.action.hover
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '50%',
    border: `1.48px solid ${theme.palette.info.dark}`,
    background: 'rgba(110,86,219,0.5)',
    minWidth: '3.95em',
    minHeight: '3.95em',
    height: '8.3em', // this would need to be dynamic to resize according to data
    width: '8.3em' // this would need to be dynamic to resize according to data
  },
  popoverContent: {
    background: theme.palette.common.white,
    borderRadius: '50%',
    width: '65%',
    height: '65%',
    '& > *': {
      fontWeight: theme.typography.fontWeightBold,
      padding: 0,
      margin: 0
    }
  },
  popoverContentTitle: {
    marginTop: '15px',
    marginBottom: '-7px', // simpler to add a negative value instead of overriding Typography,
    fontSize: theme.typography.caption.fontSize,
    fontFamily: theme.typography.caption.fontFamily,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.caption.fontWeight
  },
  popoverContentData: {
    fontSize: theme.typography.caption.fontSize,
    fontFamily: theme.typography.body2.fontFamily,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.body2.fontWeight
  },
  zoomContainer: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1
  }
}));

const GeoMap = ({ entities, jurisdictionById, selectedCard, reportValues }: GeoMapProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const svgRef = useRef<SVGSVGElement>(null);
  const zoomInRef = useRef<HTMLButtonElement>(null);
  const zoomOutRef = useRef<HTMLButtonElement>(null);
  const [hovered, setHovered] = useState<HoveredState>(null);
  const [totalProvisionToggle, setTotalProvisionToggle] = useState('Total');
  const { countriesData } = useCountriesData();
  const { t } = useTranslation();
  const { width, height, wrapperRef } = useDimensions();
  const colors = useMemo(
    () => ({
      background: theme.palette.action.selected,
      selectedBackground: theme.palette.primary.main,
      border: theme.palette.divider,
      selectedBorder: theme.palette.divider
    }),
    [theme]
  );

  const data = useMemo(() => {
    if (countriesData === null) {
      return null;
    }

    const { countryCodes } = getFetchingParams(entities, jurisdictionById);
    const features = [];

    for (const feature of countriesData.features) {
      const { iso_a3: iso, admin: name } = feature.properties;
      // ignores Antartica :)
      if (Boolean(iso) && iso !== 'ATA') {
        features.push({
          ...feature,
          properties: {
            name,
            iso,
            selected: countryCodes?.includes((iso as unknown) as never)
          }
        });
      }
    }

    return { ...countriesData, features };
  }, [entities, jurisdictionById, countriesData]);

  useEffect(() => {
    if (svgRef.current && data) {
      drawGeoJson(svgRef.current, {
        width,
        height,
        colors,
        data,
        zoomOutRef,
        zoomInRef,
        onEnter: (event: any, properties: any) => {
          setHovered({ top: event.clientY, left: event.clientX, ...properties });
        },
        onLeave: () => {
          setHovered(null);
        }
      });
    }
  }, [width, height, data, colors]);

  const buttons = [
    {
      label: t('Total'),
      isActive: totalProvisionToggle === 'Total',
      onClick: () => {
        setTotalProvisionToggle('Total');
      }
    },
    {
      label: t('Current'),
      isActive: totalProvisionToggle === 'Current',
      onClick: () => {
        setTotalProvisionToggle('Current');
      }
    },
    {
      label: t('Deferred'),
      isActive: totalProvisionToggle === 'Deferred',
      onClick: () => {
        setTotalProvisionToggle('Deferred');
      }
    }
  ];

  const extractedValues = getMapValues(selectedCard, reportValues, totalProvisionToggle);
  const hoveredValue = getHoveredValue(hovered, jurisdictionById, extractedValues);

  return (
    <Box className={classes.mapContainer}>
      {selectedCard === DASHBOARD_CARD_TYPES.totalProvision && (
        <Box className={classes.toggleContainer}>
          <ToggleButtons variant="contained" buttons={buttons} />
        </Box>
      )}

      <Box className={classes.map}>
        <Box ref={wrapperRef} className={classes.wrapper}>
          {data ? <svg ref={svgRef} /> : <CircularProgress />}
          <Box className={classes.zoomContainer}>
            <ZoomButtons zoomInRef={zoomInRef} zoomOutRef={zoomOutRef} />
          </Box>
        </Box>
        <Popover
          open={Boolean(hovered)}
          anchorReference="anchorPosition"
          anchorPosition={{ top: hovered?.top ?? 0, left: hovered?.left ?? 0 }}
          className={classes.popover}
          classes={{
            paper: classes.paper
          }}
        >
          {Boolean(hovered) && (
            <Box className={classes.popoverContent}>
              <Typography className={classes.popoverContentTitle}>{hovered?.iso}</Typography>
              <Typography className={classes.popoverContentData}>
                {formatHoveredValue(selectedCard, hoveredValue)}
              </Typography>
            </Box>
          )}
        </Popover>
      </Box>
    </Box>
  );
};

export default GeoMap;
