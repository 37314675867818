import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
  getPermanentKeyColumns,
  getPermanentColumns,
  getTemporaryIncomeStatementColumns,
  getTemporaryBalanceSheetColumnsForUpload,
  getAttributeColumns,
  getFederalTaxEffectedColumnsForUpload,
  getStateApportionmentKeyColumns,
  getStateApportionmentColumns,
  getStateModificationColumnsForUpload,
  getFederalPtbiColumns,
  getStateNolColumns,
  getFederalRtpColumns,
  getStateTaxEffectedColumnsForUpload,
  getStateRtpColumns,
  getDeferredColumns,
  getDeferredKeyColumn,
  getFederalCreditNameColumn,
  getStateCreditNameColumn,
  getStateDeferredColumns,
  getStateApportionmentColumnsFlagged
} from '../../components/EntityDetails/utils';
import { Column, Level, Step } from '../../models';

export const useDataImportColumns = ({
  level,
  selectedField,
  step
}: {
  level: Level;
  selectedField?: string | null;
  step: Step;
}) => {
  const { t } = useTranslation();

  const [stepColumns, setStepColumns] = useState<Column[]>();

  useEffect(() => {
    let columns: Column[];

    if (level === 'federal') {
      // NOTE: ADD NEW TAB COLUMNS HERE
      switch (step) {
        case 'permanent':
          columns = [
            ...getPermanentKeyColumns(t),
            ...getPermanentColumns(t, false).filter((column) => column.field !== 'm1Adjustment')
          ];
          break;
        case 'temporary.incomeStatement':
          columns = getTemporaryIncomeStatementColumns(t, false).filter((column) => column.field !== 'm1Adjustment');
          break;
        case 'temporary.balanceSheet':
          columns = getTemporaryBalanceSheetColumnsForUpload(t);
          break;
        case 'nol':
          columns = getAttributeColumns(t, false).filter((column) => column.field !== 'endingBalance');
          break;
        case 'ptbi':
          columns = getFederalPtbiColumns(t);
          break;
        case 'rtp':
          columns = getFederalRtpColumns(t);
          break;
        case 'tax-effected':
          columns = getFederalTaxEffectedColumnsForUpload(t);
          break;
        case 'deferred':
          columns = [
            ...getDeferredKeyColumn(t),
            ...getDeferredColumns(t).filter(
              (column) =>
                column.field === 'beginningBalance' ||
                column.field === 'deferredOnlyAdjustment' ||
                column.field === 'balanceSheetOnlyAdjustment'
            )
          ];
          break;
        case 'credits':
          columns = [
            ...getFederalCreditNameColumn(t),
            ...getAttributeColumns(t, false).filter((column) => column.field !== 'endingBalance')
          ];
          break;
        default:
          columns = [];
          break;
      }
    } else {
      switch (step) {
        case 'rtp':
          columns = getStateRtpColumns(t);
          break;
        case 'apportionment':
          columns = [...getStateApportionmentKeyColumns(t), ...getStateApportionmentColumns(t)];
          break;
        case 'apportionment-flagged':
          columns = [...getStateApportionmentKeyColumns(t), ...getStateApportionmentColumnsFlagged(t)];
          break;
        case 'nol':
          columns = getStateNolColumns(t);
          break;
        case 'tax-effected':
          columns = getStateTaxEffectedColumnsForUpload(t);
          break;
        case 'credits':
          columns = [
            ...getStateCreditNameColumn(t),
            ...getAttributeColumns(t, false).filter((column) => column.field !== 'endingBalance')
          ];
          break;
        case 'modifications':
          columns = getStateModificationColumnsForUpload(t);
          break;
        case 'deferred':
          columns = getStateDeferredColumns(t);
          break;
        default:
          columns = [];
          break;
      }
    }

    columns = columns.map((column) => {
      column.isActive = column.headerName === selectedField;
      return column;
    });

    setStepColumns(columns);
  }, [level, selectedField, step, t]);

  return stepColumns;
};
