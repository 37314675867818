import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { getUploadHistory } from './uploadHistory.actions';
import { uploadHistoryInitialState } from './uploadHistory.initialState';

import { containersOnCurrentSwitched } from '../containers';

export const uploadHistoryReducer = createReducer(uploadHistoryInitialState, (builder) => {
  builder
    .addCase(getUploadHistory.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getUploadHistory.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    .addCase(getUploadHistory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.hasLoaded = true;
      state.uploads = action.payload;
    })
    .addMatcher(isAnyOf(containersOnCurrentSwitched), () => {
      return uploadHistoryInitialState;
    });
});
