import { createAction, createReducer } from '@reduxjs/toolkit';

import { currenciesInitialState } from '.';

import { CURRENCY_ISO_TO_SYMBOL } from '../../constants';
import { CurrencyFromApi } from '../../models';

export const onFetchingCurrencies = createAction('currencies/onFetching');
export const onCurrenciesReceived = createAction<CurrencyFromApi[]>('currencies/onReceived');
export const onCurrenciesFailed = createAction<unknown>('currencies/onFailed');

export const currenciesReducer = createReducer(currenciesInitialState, (builder) => {
  builder
    .addCase(onFetchingCurrencies, (state) => {
      return { ...state, isLoading: true, error: null };
    })
    .addCase(onCurrenciesReceived, (_state, action) => {
      return {
        currencies: action.payload.map(({ name, isoCode, ...rest }) => ({
          id: name,
          name: `${isoCode} - ${name}`,
          isoCode,
          symbol: CURRENCY_ISO_TO_SYMBOL[isoCode],
          ...rest
        })),
        hasLoaded: true,
        isLoading: false,
        error: null
      };
    })
    .addCase(onCurrenciesFailed, (_state, action) => {
      return { currencies: [], hasLoaded: true, isLoading: false, error: action.payload };
    });
});
