import { useEffect, useState } from 'react';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { Filter } from '../Table/components';
import { Column, Sort, TableHeadProps } from '../Table/Table.proptype';
import { ASC, DESC, getClassName } from '../Table/utils';

interface HeaderContentProps {
  columns: Column[];
  classes: Record<string, string>;
  filters?: TableHeadProps['filters'];
  sort: Sort;
  onFilterChange: TableHeadProps['onFilterChange'];
  onSortClick: (column: Column | null) => void;
}

const HeaderContent = (props: HeaderContentProps) => {
  const { columns, classes, sort, filters, onFilterChange, onSortClick } = props;
  const [localSort, setLocalSort] = useState<Sort>(sort);

  useEffect(() => {
    if (sort.column?.isNumber) {
      setLocalSort({ column: sort.column, dir: sort.dir === ASC ? DESC : ASC });
    } else {
      setLocalSort(sort);
    }
  }, [sort]);

  if (columns.length === 0) {
    return null;
  }

  return (
    <TableRow>
      {columns?.map((column: any) => {
        if (column.renderHeader) {
          return (
            <TableCell
              key="notes-empty-column"
              variant="head"
              align={column.isNumber || false ? 'right' : 'left'}
              width={column.width ? column.width : 100}
              className={`${getClassName({ column, row: {}, classes })} ${column.filterable ? 'filter' : ''}`}
            >
              {column.renderHeader()}
            </TableCell>
          );
        }

        if (column.sortable) {
          return (
            <TableCell
              /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
              key={`${column.headerName}-${column.field}`}
              variant="head"
              align={column.isNumber || false ? 'right' : 'left'}
              width={column.width ? column.width : 100}
              className={`${getClassName({ column, row: {}, classes })} ${column.filterable ? 'filter' : ''}`}
            >
              <Filter
                value={filters.get(column.field)?.searched || ''}
                column={column}
                sort={localSort}
                onChange={onFilterChange}
                onSort={onSortClick}
              />
            </TableCell>
          );
        }

        return (
          <TableCell
            /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
            key={`${column.headerName}-${column.field}`}
            variant="head"
            align={column.isNumber || false ? 'right' : 'left'}
            width={column.width ? column.width : 100}
            className={`${getClassName({ column, row: {}, classes })} ${column.filterable ? 'filter' : ''}`}
          >
            {column.headerName}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default HeaderContent;
