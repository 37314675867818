import { useState } from 'react';

import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxOutlined from '@material-ui/icons/CheckBoxOutlined';
import classNames from 'classnames';

import { useFinancialData } from '../../hooks';
import { Column, Row } from '../../models';
import { selectDataImportSelection } from '../../selectors';
import { EntityNumberRouteMatch } from '../EntityDetails/utils';
import Table from '../Table';
import ImportButton from '../ValidateScreen/components/ImportButton';
import { useStyles } from '../ValidateScreen/ValidateScreen.styles';

const ValidateScreenFederalPtbi = () => {
  const classes = useStyles();
  const dataImportSelection = useSelector(selectDataImportSelection);
  const { t } = useTranslation();
  const ptbiValue = dataImportSelection?.['Pre-Tax Book Income Amount']?.rawValues[0];
  const adjustmentsValue = dataImportSelection?.['Adjustments Amount']?.rawValues[0];
  const formattedPtbiValue = dataImportSelection?.['Pre-Tax Book Income Amount']?.formattedValues[0];
  const formattedAdjustmentsValue = dataImportSelection?.['Adjustments Amount']?.formattedValues[0];
  const {
    params: { entityNumber }
  } = useRouteMatch<EntityNumberRouteMatch>();
  const level = 'federal';
  const step = 'ptbi';

  const { tabsData, fetchDataForSpecificStep } = useFinancialData(entityNumber, level, [step]);

  const finData = tabsData['federal.ptbi'].filter((row) => row.columnName !== 'note');

  // eslint-disable-next-line unicorn/no-new-array
  const [checkmarkIndices, setCheckmarkIndices] = useState<boolean[]>(new Array(2).fill(true));

  const matchingFinDataPtbi = finData.find(
    (datum) => datum.rowName === 'Pre-Tax Book Income / (Loss)' && datum.creditName === 'fullYearProjection'
  );
  const matchingFinDataAdjustments = finData.find(
    (datum) => datum.rowName === 'Adjustments' && datum.creditName === 'fullYearProjection'
  );

  const columns: Column[] = [
    {
      field: 'name',
      renderCell: (row: any) => {
        return (
          <Box className={classNames(classes.tableCell, classes.darkCell)}>
            {row.index === 0 ? 'Pre-Tax Book Income' : 'Adjustments'}
          </Box>
        );
      }
    },
    {
      field: 'amount',
      headerName: 'Amount',
      renderCell: (row: any) => {
        return (
          <Box
            className={classNames(classes.tableCell, {
              [classes.darkCell]: row.isSameValue,
              [classes.clearCell]: !row.isSameValue
            })}
          >
            {row.formattedAmount}
          </Box>
        );
      }
    }
  ];

  const rows: Row[] = [
    {
      index: 0,
      rowName: 'Pre-Tax Book Income Amount',
      amount: ptbiValue,
      formattedAmount: formattedPtbiValue,
      isSameValue: Number(ptbiValue) === Number(matchingFinDataPtbi?.value),
      hasPreviousValue: matchingFinDataPtbi?.value !== undefined,
      entityFinancialsTraceId: matchingFinDataPtbi?.entityFinancialsTraceId,
      isValid: !Number.isNaN(Number(ptbiValue)),
      creditName: 'fullYearProjection'
    },
    {
      index: 1,
      rowName: 'Adjustments Amount',
      amount: adjustmentsValue,
      formattedAmount: formattedAdjustmentsValue,
      isSameValue: Number(adjustmentsValue) === Number(matchingFinDataAdjustments?.value),
      hasPreviousValue: matchingFinDataAdjustments?.value !== undefined,
      entityFinancialsTraceId: matchingFinDataAdjustments?.entityFinancialsTraceId,
      isValid: !Number.isNaN(Number(adjustmentsValue)),
      creditName: 'fullYearProjection'
    }
  ];

  const handleChange = (index: number) => {
    const copy = [...checkmarkIndices];
    copy[index] = !checkmarkIndices[index];
    setCheckmarkIndices(copy);
  };

  const checkMarkColumn = {
    renderHeader: () => t('Import'),
    field: 'Import',
    renderCell: (row: Row) => {
      return (
        <Checkbox
          checked={checkmarkIndices[row.index] && row.isValid}
          checkedIcon={<CheckBoxOutlined className={classes.checkbox} />}
          icon={<CheckBoxOutlineBlank className={classes.checkbox} />}
          onChange={() => {
            handleChange(row.index);
          }}
        />
      );
    }
  };

  if (columns.length > 0) {
    columns.push(checkMarkColumn);
  }

  const handleImportFinished = () => {
    void fetchDataForSpecificStep(level, step);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.controls}>
        {createPortal(
          <ImportButton
            checkmarkIndices={checkmarkIndices}
            level={level}
            rows={rows}
            step={step}
            onImportFinished={handleImportFinished}
          />,
          document.querySelector('#button-box-for-import-button')!
        )}
      </Box>

      <Table columns={columns} rows={rows} className={classes.table} />
    </Box>
  );
};

export default ValidateScreenFederalPtbi;
