import { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Button, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import SearchboxSelector from './components/SearchboxSelector';
import { SearchboxElementType } from './components/SearchboxSelector/SearchboxSelector';
import { useStyles } from './styles';

import { MoreActionsButton } from '../../..';
import { useCategories } from '../../../../hooks';
import { useAccountsCount } from '../../../../hooks/useAccountsEntityCount';
import { useCategoryUpdate } from '../../../../hooks/useCategoryUpdate';
import { CategoryForUpdate, EditCategoryDrawerProps } from '../../../../models';
import { selectAccountsEntityCount } from '../../../../selectors/accountsEntityCount';
import { selectCategories } from '../../../../selectors/categories';
import theme from '../../../../theme';
import ColorPicker from '../../../ColorPicker';
import SearchBox from '../../../SearchBox';
import { DATA_TYPES, DataSelectionType } from '../../sharedCategoryDrawerUtils';

const EditCategoryDrawer: React.FC<EditCategoryDrawerProps> = ({
  isOpen,
  onClose = () => null,
  currentRow,
  currentContainer,
  setIsUpdating
}: EditCategoryDrawerProps) => {
  const classes = useStyles();
  const { updateCategory, updateCategoryAccounts } = useCategoryUpdate();
  const { isColorUnique, isNameUnique } = useCategories();
  const accountsEntityCountData = useSelector(selectAccountsEntityCount);
  const { getUseAccountsCount } = useAccountsCount();
  const { t } = useTranslation();
  const [categoryName, setCategoryName] = useState<string>('');
  const [color, setColor] = useState<string>(theme.palette.primary.main);
  const [accounts, setAccounts] = useState<SearchboxElementType[]>([]);
  const [initialSelectedAccounts, setInitialSelectedAccounts] = useState<SearchboxElementType[]>([]);
  const [selectedAccountsEntityCount, setSelectedAccountsEntityCount] = useState<SearchboxElementType[]>([]);
  const [unselectedAccountsEntityCount, setUnselectedAccountsEntityCount] = useState<SearchboxElementType[]>([]);
  const [selectedDataType, setSelectedDataType] = useState<DataSelectionType>();
  const [dataTypes] = useState<DataSelectionType[]>(DATA_TYPES);
  const [initialAccountsStepNames, setInitialAccountsStepNames] = useState<{ [key: string]: string }>({});
  const [accountsStepNames, setAccountsStepNames] = useState<{ [key: string]: string }>({});
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const categoriesData = useSelector(selectCategories);
  const currentStepName = selectedDataType ? `${selectedDataType.level}.${selectedDataType.step}` : '';
  const { fetchData } = useCategories();

  const getCategoryData = (categoryId: string) => {
    for (const category of categoriesData.categories) {
      if (category.categoryId === categoryId) {
        return category;
      }
    }
  };

  const currentCategoryData = getCategoryData(currentRow.id);
  const currentLevelStep = currentCategoryData?.accounts[0]?.stepName;

  useEffect(() => {
    const stepNames: { [key: string]: string } = {};
    const initialStepNames: { [key: string]: string } = {};

    if (currentCategoryData) {
      for (const { attributeName, stepName } of currentCategoryData.accounts) {
        initialStepNames[attributeName] = stepName;
      }
    }

    setInitialAccountsStepNames(initialStepNames);

    for (const { accountName, stepName } of accountsEntityCountData.accounts) {
      stepNames[accountName] = stepName;
    }

    setAccountsStepNames(stepNames);
  }, [accountsEntityCountData.accounts, currentCategoryData]);

  useEffect(() => {
    const parsedSearchboxElements: SearchboxElementType[] = accountsEntityCountData.accounts
      .filter((account) => {
        const sameDataTypeCategories = categoriesData.categories.filter((category) => {
          const stepNameFragments = category.accounts[0]?.stepName.split('.');
          const reducedStepName = `${stepNameFragments[0]}.${stepNameFragments[1]}`;
          if (reducedStepName === 'federal.temporary') {
            return reducedStepName === currentStepName;
          }

          return category.accounts[0]?.stepName === currentStepName;
        });

        const isAccountAssignable = !sameDataTypeCategories.some((category) => {
          const isAccountAssignedToCategory = category.accounts.some(
            ({ attributeName }) => attributeName === account.accountName
          );
          return isAccountAssignedToCategory && category.categoryId !== currentRow.id;
        });

        return isAccountAssignable;
      })
      .map(({ accountName, entityCount, accountNumber }) => ({
        name: accountName,
        count: entityCount,
        number: accountNumber
      }));

    setAccounts(parsedSearchboxElements);
  }, [accountsEntityCountData, categoriesData, currentStepName, currentRow.id]);

  useEffect(() => {
    if (isOpen) {
      const fetchData = async () => {
        const currentLevelStepParts = currentLevelStep?.split('.') ?? '';
        const shortenedLevelStep = `${currentLevelStepParts[0]}.${currentLevelStepParts[1]}`;

        const stepToUse = shortenedLevelStep === 'federal.temporary' ? shortenedLevelStep : currentLevelStep;

        await getUseAccountsCount(stepToUse ?? '');
      };

      void fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    setCategoryName(currentCategoryData?.name ?? '');
    setHasChanges(false);
    if (currentLevelStep) {
      let dataType;
      if (
        currentLevelStep === 'state.modifications.permanent' ||
        currentLevelStep === 'state.modifications.temporary'
      ) {
        dataType = DATA_TYPES.find((dt) => `${dt.level}.${dt.step}` === currentLevelStep);
      } else {
        const splitLevelStep = currentLevelStep.split('.');
        const shortenedLevelStep = `${splitLevelStep[0]}.${splitLevelStep[1]}`;
        dataType = DATA_TYPES.find((dt) => `${dt.level}.${dt.step}` === shortenedLevelStep);
      }

      setSelectedDataType(dataType);
    } else {
      // eslint-disable-next-line unicorn/no-useless-undefined
      setSelectedDataType(undefined);
    }

    if (currentCategoryData?.accounts) {
      const initialAccounts: SearchboxElementType[] = currentCategoryData.accounts.map(
        ({ attributeName, accountNumber, entityIds, stepName }) => ({
          name: attributeName,
          count: entityIds.length,
          stepName,
          number: accountNumber
        })
      );
      setSelectedAccountsEntityCount(initialAccounts);
      setInitialSelectedAccounts(initialAccounts);
    } else {
      setSelectedAccountsEntityCount([]);
    }
  }, [currentCategoryData, currentLevelStep]);

  useEffect(() => {
    setColor(currentCategoryData?.color ?? theme.palette.primary.main);
    setHasChanges(false);
  }, [currentCategoryData, currentLevelStep]);

  const onSelectAccountsEntityCount = (selected: SearchboxElementType[]) => {
    const unselectedAccounts = initialSelectedAccounts.filter(
      (account) => !selected.some(({ name, stepName }) => name === account.name && stepName === account.stepName)
    );
    setUnselectedAccountsEntityCount(unselectedAccounts);
    setSelectedAccountsEntityCount(selected);
    setHasChanges(true);
  };

  const onColorSelected = (selectedColor: string) => {
    setColor(selectedColor);
    setHasChanges(true);
  };

  const onChangeDataType = useCallback(
    async (_: any, selected: DataSelectionType) => {
      if (selected) {
        await getUseAccountsCount(`${selected.level}.${selected.step}`);
        setSelectedDataType(selected);
        setSelectedAccountsEntityCount([]);
        setHasChanges(true);
      }
    },
    [getUseAccountsCount]
  );

  const deleteAccountFromCategory = async (accountName: string) => {
    const accountIndex = selectedAccountsEntityCount.findIndex((account) => account.name === accountName);

    if (accountIndex !== -1) {
      const account = selectedAccountsEntityCount[accountIndex];
      setSelectedAccountsEntityCount((prev) => prev.filter((account) => account.name !== accountName));
      setUnselectedAccountsEntityCount((prev) => [...prev, account]);
      setHasChanges(true);
    }
  };

  const onSave = useCallback(async () => {
    setIsUpdating(true);
    if (isNameUnique(categoryName, currentRow) && isColorUnique(color, currentRow)) {
      const categoryToUpdate: CategoryForUpdate = {
        categoryName,
        categoryColor: color
      };
      await updateCategory(currentRow.id, categoryToUpdate);

      const promises = [];

      for (const account of unselectedAccountsEntityCount) {
        const action = 'DELETE';
        promises.push(
          updateCategoryAccounts(currentRow.id, action, [[account.name, initialAccountsStepNames[account.name]]])
        );
      }

      for (const account of selectedAccountsEntityCount) {
        const action = 'ADD';
        promises.push(updateCategoryAccounts(currentRow.id, action, [[account.name, accountsStepNames[account.name]]]));
      }

      onClose();
      await Promise.all(promises);
      await fetchData();
      setUnselectedAccountsEntityCount([]);
      setIsUpdating(false);
    }
  }, [
    setIsUpdating,
    isNameUnique,
    categoryName,
    currentRow,
    isColorUnique,
    color,
    updateCategory,
    onClose,
    fetchData,
    unselectedAccountsEntityCount,
    initialAccountsStepNames,
    accountsStepNames,
    updateCategoryAccounts,
    selectedAccountsEntityCount
  ]);

  const onCloseReset = () => {
    setCategoryName(currentCategoryData?.name ?? '');
    if (currentLevelStep) {
      const currentLevelStepParts = currentLevelStep?.split('.') ?? '';
      const shortenedLevelStep = `${currentLevelStepParts[0]}.${currentLevelStepParts[1]}`;
      const stepToUse = shortenedLevelStep === 'federal.temporary' ? shortenedLevelStep : currentLevelStep;
      setSelectedDataType(DATA_TYPES.find((dt) => `${dt.level}.${dt.step}` === stepToUse));
    } else {
      // eslint-disable-next-line unicorn/no-useless-undefined
      setSelectedDataType(undefined);
    }

    if (currentCategoryData?.accounts) {
      const initialAccounts: SearchboxElementType[] = currentCategoryData.accounts.map(
        ({ attributeName, entityIds, accountNumber }) => ({
          name: attributeName,
          count: entityIds.length,
          number: accountNumber
        })
      );
      setSelectedAccountsEntityCount(initialAccounts);
    } else {
      setSelectedAccountsEntityCount([]);
    }

    setHasChanges(false);
    onClose();
  };

  return (
    <Drawer open={isOpen} className={classes.drawer} anchor="right">
      <Box className={classes.editingCategoryBar}>
        <Typography className={classes.editingCategoryBarText}>{t('editing-category')}</Typography>
      </Box>
      <Box className={classes.header}>
        <Box className={classes.title}>
          <Typography variant="h3">{categoryName}</Typography>
        </Box>
        <Box className={classes.closeButton}>
          <IconButton role="button" onClick={onCloseReset}>
            <img src="/imgs/panel_close.svg" />
          </IconButton>
        </Box>
      </Box>
      <Box
        className={classes.container}
        data-roles-disable-element={currentContainer?.isFinalized ? 'finalizedContainer' : ''}
      >
        <Box className={classes.row}>
          <Box className={classes.categoryName}>
            <Typography variant="h3" className={classes.fileNameText}>
              {t('Category Name')}
            </Typography>
            <TextField
              fullWidth
              placeholder={t('Add Name')}
              autoComplete="off"
              variant="outlined"
              size="small"
              value={categoryName}
              onChange={({ target: { value } }) => {
                setCategoryName(value);
                setHasChanges(true);
              }}
            />
          </Box>

          <Box>
            <Typography variant="h3" className={classes.fileNameText}>
              {t('Color')}
            </Typography>
            <ColorPicker categoryColor={currentCategoryData?.color} onSelect={onColorSelected} />
          </Box>
        </Box>
        <Box className={classes.tagged}>
          <Typography variant="h3" className={classes.fileNameText}>
            {t('Automatic tagging')}
          </Typography>
          <Box>
            <SearchBox
              options={dataTypes}
              className={classes.dataType}
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              getOptionLabel={({ name }: any) => `${name}`}
              disabled={
                !categoryName?.trim() || accountsEntityCountData.isLoading || selectedAccountsEntityCount.length > 0
              }
              value={selectedDataType}
              onChange={onChangeDataType}
            />
          </Box>
          <Box>
            <SearchboxSelector
              isDisabled={!categoryName?.trim() || !selectedDataType}
              placeholder={t('Select Account Description')}
              options={accounts}
              isLoading={accountsEntityCountData.isLoading}
              value={selectedAccountsEntityCount}
              onSelect={onSelectAccountsEntityCount}
            />
          </Box>
        </Box>
        <Box className={classes.taggedAccounts}>
          <Box>
            <span>
              {selectedAccountsEntityCount?.length ?? 0} {t('Tagged Items')}
            </span>
          </Box>
          <Box>{t('Entities')}</Box>
        </Box>
        <Box className={classes.scrollContainer}>
          {selectedAccountsEntityCount?.map(({ number, name, count }: SearchboxElementType) => (
            <Box key={name} className={classes.taggedAccountElement}>
              <Box>{number}</Box>
              <Box>{name}</Box>
              <Box className={classes.entityCount}>x{count}</Box>
              <MoreActionsButton
                actions={[
                  {
                    label: t('Remove'),
                    onClick: () => {
                      void deleteAccountFromCategory(name);
                    }
                  }
                ]}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Box className={classes.footer}>
        <Box>
          <Button variant="text" onClick={onCloseReset}>
            <b>{t('Cancel')}</b>
          </Button>
          <Button
            variant="outlined"
            disabled={
              !hasChanges ||
              dataTypes.length === 0 ||
              selectedAccountsEntityCount.length === 0 ||
              !categoryName?.trim() ||
              !isNameUnique(categoryName, currentRow) ||
              !isColorUnique(color, currentRow)
            }
            onClick={onSave}
          >
            {t('Save')}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default EditCategoryDrawer;
