import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  stateButton: {
    margin: theme?.spacing(-1, -0.5)
  },
  newCell: {
    marginLeft: theme?.spacing(0.7)
  }
}));

export default useStyles;
