import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { Container } from '../../../../../../models';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '0.25rem',
    display: 'grid',
    padding: '1.42rem',
    paddingTop: '1.12rem',
    position: 'relative',
    width: '31rem',
    height: 'fit-content'
  },
  title: {
    position: 'absolute',
    left: '1.42rem',
    top: '0.81rem'
  },
  browseBtn: {
    marginLeft: 'auto'
  },
  dragDropBox: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '0.81rem',
    padding: '1.42rem',
    borderWidth: '1px',
    borderRadius: '0.25rem',
    borderColor: theme.palette.info.dark,
    borderStyle: 'dashed',
    backgroundColor: theme.palette.info.main,
    '& .upperText': {
      color: theme.palette.primary.main,
      fontSize: '1rem',
      lineHeight: '1.37rem',
      letterSpacing: '0.15px'
    },
    '& .lowerText': {
      color: '#677283',
      fontSize: '0.75rem',
      lineHeight: '0.875rem',
      letterSpacing: '0.6px'
    }
  }
}));

export type FileUploaderProps = {
  openFileBrowser: () => void;
  currentContainer?: Container;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
};

const FileUploader = ({ getInputProps, getRootProps, currentContainer, openFileBrowser }: FileUploaderProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box
      className={classes.container}
      data-roles-disable-element={currentContainer?.isFinalized ? 'finalizedContainer' : ''}
    >
      <Typography variant="h3" className={classes.title}>
        {t('upload-file')}
      </Typography>
      <Button variant="outlined" className={classes.browseBtn} onClick={openFileBrowser}>
        {t('browse-computer')}
      </Button>
      <Box {...getRootProps({ className: classes.dragDropBox })}>
        <input {...getInputProps()} data-testid="dropzone" />
        <Typography className="upperText">{t('drag-and-drop-file')}</Typography>
        <Typography className="lowerText">XLSX, XLS, XLSB, CSV</Typography>
      </Box>
    </Box>
  );
};

export default FileUploader;
