import { useState } from 'react';

import { Paper, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';

import DeletableSelectedItemChip from './DeletableSelectedItemChip';

import { FilterOption } from '../../../../../models/auditTrail.interface';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '3px',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    '& .MuiAutocomplete-popupIndicator': {
      color: theme.palette.primary.dark,
      transform: 'none'
    },
    '& .MuiInputBase-input': {
      color: theme.palette.common.black,
      letterSpacing: '0.25px',
      marginLeft: '15px'
    }
  }
}));

const SimpleAutocomplete = ({
  value,
  onChange,
  placeholder,
  options,
  getOptionLabel
}: Partial<AutocompleteProps<FilterOption, boolean | undefined, boolean | undefined, boolean | undefined>>) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');
  const val = value as FilterOption | null;

  const handleOnClear = () => {
    // Set the value equal to null
    onChange?.({} as any, null, {} as any);
  };

  return (
    <Autocomplete
      value={value as any}
      placeholder={placeholder}
      getOptionLabel={getOptionLabel}
      inputValue={inputValue}
      options={options!}
      popupIcon={<Search />}
      PaperComponent={({ children, ...props }) => (
        <Paper {...props} style={{ backgroundColor: '#fff' }}>
          {children}
        </Paper>
      )}
      renderInput={(params) => {
        return (
          <>
            <Box className={classes.container} style={{ display: val ? undefined : 'none' }}>
              <DeletableSelectedItemChip text={val?.name ?? ''} onClear={handleOnClear} />
            </Box>

            <TextField
              style={{ display: val ? 'none' : undefined }}
              {...params}
              placeholder={placeholder}
              className={classes.container}
              InputProps={{ ...params.InputProps, disableUnderline: true }}
            />
          </>
        );
      }}
      onChange={onChange}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
    />
  );
};

export default SimpleAutocomplete;
