import { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Adjust from '@material-ui/icons/Adjust';
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';

import ActionButtons from './components/ActionButtons';

import { SearchBox } from '../../..';
import { ReactComponent as DeleteOutlineIcon } from '../../../../assets/img/DeleteOutlineIcon.svg';
import { Container, Entity } from '../../../../models';
import { enqueueNotification } from '../../../../redux/notifications';
import {
  disableElementForRoles,
  disableParentEntityRadioButtonForRoles
} from '../../../UserRoleStylesProvider/constants';

const useStyles = makeStyles<Theme, { trashDisabled?: boolean }>((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1, 2),
    overflow: 'visible',
    '& .Pro-rates': {
      marginBottom: theme.spacing(1)
    }
  },
  deleteIcon: {
    cursor: ({ trashDisabled }) => (trashDisabled ? 'not-allowed' : 'pointer'),
    position: 'absolute',
    right: theme.spacing(0.5),
    top: `-${theme.spacing(3)}px`,
    width: theme.spacing(2.5)
  },
  menu: {
    overflow: 'auto'
  },
  menuItem: {
    display: 'flex'
  },
  searchBox: {
    backgroundColor: theme.palette.common.white,
    flexGrow: 1,
    height: '50px',
    marginBottom: '12px',
    marginTop: '8px',
    '& .MuiTextField-root': {
      height: '100%'
    },
    '& .MuiAutocomplete-inputRoot': {
      height: '100%'
    }
  },
  searchHeader: {
    display: 'flex',
    position: 'relative'
  },
  sectionTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(2)
  },
  selectedMenuItem: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid rgb(226, 227, 230)',
    borderRadius: theme.shape.borderRadius,
    flexGrow: 1,
    height: '50px',
    marginBottom: '12px',
    marginTop: '8px',
    padding: theme.spacing(1, 1.5),
    width: '100%'
  }
}));

export type GroupEntitiesEditorProps = {
  groupEntities: Entity[];
  containerEntities: Entity[];
  currentContainer?: Container;
  onCancel: () => void;
  onSave: (selectedEntities: Entity[]) => void;
  onDelete: (entitiesToDelete: Entity[]) => void;
};

const GroupEntitiesEditor = ({
  containerEntities,
  currentContainer,
  groupEntities,
  onCancel,
  onSave,
  onDelete
}: GroupEntitiesEditorProps) => {
  const [displayedEntities, setDisplayedEntities] = useState<Entity[]>([]);
  const [entitiesToAdd, setEntitiesToAdd] = useState<Entity[]>([]);
  const [entitiesToRemove, setEntitiesToRemove] = useState<Entity[]>([]);
  const [searchText, setSearchText] = useState('');
  const saveDisabled = entitiesToAdd.length === 0;
  const deleteDisabled = Boolean(entitiesToRemove.length === 0 || currentContainer?.isFinalized);
  const topCheckboxIsChecked = entitiesToRemove.length === displayedEntities.length && displayedEntities.length > 0;
  const { t } = useTranslation();
  const classes = useStyles({ trashDisabled: deleteDisabled });
  const dispatch = useDispatch();

  const entitiesToRemoveSet = useMemo(() => {
    return new Set(entitiesToRemove.map((entity) => entity.entityId));
  }, [entitiesToRemove]);

  useEffect(() => {
    setDisplayedEntities([...groupEntities, ...entitiesToAdd]);
  }, [groupEntities, entitiesToAdd]);

  const toggleAllCheckboxes = () => {
    if (entitiesToRemove.length === displayedEntities.length) {
      setEntitiesToRemove([]);
    } else {
      setEntitiesToRemove(displayedEntities);
    }
  };

  const handleEntityChecked = (entity: Entity, isChecked: boolean) => {
    if (isChecked) {
      setEntitiesToRemove((entitiesToRemove) => [...entitiesToRemove, entity]);
    } else {
      setEntitiesToRemove((entitiesToRemove) =>
        entitiesToRemove.filter((currentEntity) => currentEntity.entityId !== entity.entityId)
      );
    }
  };

  const searchOptions = useMemo(() => {
    const entitiesToIgnore = new Set(displayedEntities.map((entity) => entity.entityId));

    return containerEntities.filter((entity) => !entitiesToIgnore.has(entity.entityId));
  }, [displayedEntities, containerEntities]);

  const handleCancel = () => {
    setEntitiesToAdd([]);
    setEntitiesToRemove([]);
    onCancel();
  };

  const handleSave = () => {
    onSave(entitiesToAdd);
    setEntitiesToAdd([]);
  };

  const handleDelete = () => {
    // If entities have not been saved yet, remove them from local state only
    const entitiesToRemoveFromServer = groupEntities.filter((entity) => entitiesToRemoveSet.has(entity.entityId));

    if (entitiesToRemoveFromServer.length === displayedEntities.length - entitiesToAdd.length) {
      dispatch(
        enqueueNotification({
          message: 'Cannot delete all entities from group',
          options: {
            variant: 'warning'
          }
        })
      );

      return;
    }

    if (entitiesToRemoveFromServer.length > 0) {
      onDelete(entitiesToRemoveFromServer);
    }

    setEntitiesToAdd(entitiesToAdd.filter((entity) => !entitiesToRemoveSet.has(entity.entityId)));
    setEntitiesToRemove([]);
  };

  return (
    <Box className={classes.content}>
      <Box className={classes.searchHeader}>
        {!deleteDisabled && (
          <DeleteOutlineIcon data-testid="delete-icon" className={classes.deleteIcon} onClick={handleDelete} />
        )}
        <SearchBox
          blurOnSelect
          data-roles-disable-element={
            currentContainer?.isFinalized ? 'finalizedContainer' : disableElementForRoles.join(' ')
          }
          className={classes.searchBox}
          options={searchOptions}
          getOptionLabel={({ name }: Entity) => name}
          inputValue={searchText}
          value={null}
          placeholder={t('add-new-entity')}
          onChange={(_event: Event, value: Entity) => {
            setEntitiesToAdd((entitiesToAdd) => [...entitiesToAdd, value]);
            setSearchText('');
          }}
          onInputChange={(_event: Event, value: string) => {
            setSearchText(value);
          }}
        />
        <Checkbox
          data-roles-disable-element={disableElementForRoles.join(' ')}
          checked={topCheckboxIsChecked}
          indeterminateIcon={<Adjust />}
          icon={<RadioButtonUnchecked />}
          checkedIcon={<RadioButtonChecked />}
          onChange={toggleAllCheckboxes}
        />
      </Box>
      <Divider />
      <Box
        className={classes.menu}
        data-roles-disable-radio-button-element={
          currentContainer?.isFinalized ? 'finalizedContainer' : disableParentEntityRadioButtonForRoles.join(' ')
        }
      >
        {displayedEntities.map((entity) => (
          <Box key={entity.entityId} className={classes.menuItem}>
            <Box className={classes.selectedMenuItem}>{entity.name}</Box>
            <Checkbox
              checked={entitiesToRemoveSet.has(entity.entityId)}
              icon={<RadioButtonUnchecked />}
              checkedIcon={<RadioButtonChecked />}
              data-testid={`delete-${entity.entityId}`}
              onChange={(event) => {
                handleEntityChecked(entity, event.target.checked);
              }}
            />
          </Box>
        ))}
      </Box>
      <ActionButtons
        buttonsAreDisabled={Boolean(saveDisabled || currentContainer?.isFinalized)}
        onCancel={handleCancel}
        onSave={handleSave}
      />
    </Box>
  );
};

export default GroupEntitiesEditor;
