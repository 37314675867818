import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { auditTrailOnFailed, auditTrailOnReceived, auditTrailOnSendingRequest } from './auditTrail.actions';
import { auditTrailInitialState } from './auditTrail.initialState';

import { AuditTrailFromApi } from '../../models';

export const auditTrailReducer = createReducer(auditTrailInitialState, (builder) => {
  builder
    .addCase(auditTrailOnSendingRequest, (state) => {
      state.error = null;
    })
    .addCase(auditTrailOnReceived, (state, action: PayloadAction<AuditTrailFromApi>) => {
      state.auditTrail = action.payload;
      state.error = null;
    })
    .addCase(auditTrailOnFailed, (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    });
});
