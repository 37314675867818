import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';

import { createReportTable, createReportTitle, createTotalsTable, transformUiReportToXlsReport } from './utils';

import { ReportForExport } from '../../models';
import { enqueueNotification } from '../../redux/notifications';

export function useExcel() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const exportReports = useCallback(
    async (reports: ReportForExport[]) => {
      const workbook = new Workbook();
      const worksheetIds: number[] = [];
      const reportsForXls = reports.map((report) => transformUiReportToXlsReport(report));

      try {
        if (reportsForXls.length > 0) {
          const fileName = reports[0].title;

          reportsForXls.forEach((report) => {
            const worksheet = workbook.addWorksheet(report.reportName);
            worksheetIds.push(worksheet.id);

            createReportTitle(worksheet, report, t);

            createReportTable(worksheet, report);

            createTotalsTable(worksheet, report);
          });

          const buf = await workbook.xlsx.writeBuffer();

          saveAs(new Blob([buf]), `${fileName}.xlsx`);
        }
      } catch {
        dispatch(
          enqueueNotification({
            message: t(`Failed to export report${reportsForXls?.length > 1 ? 's' : ''} to excel`),
            options: {
              variant: 'error'
            }
          })
        );
      } finally {
        worksheetIds.forEach((id) => {
          workbook.removeWorksheet(id);
        });
      }
    },
    [t, dispatch]
  );

  return { exportReports };
}
