import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

import { Container } from '../../../../models';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(0.5, 0)
  },
  periodSelector: {
    backgroundColor: theme.palette.primary.contrastText,
    minWidth: '196px'
  }
}));

type PeriodSelectorProps = {
  title?: string;
  periods?: Container[];
  defaultPeriod?: Container;
  currentPeriod?: Container;
  handleChange?: any;
  selectedBaseContainerId?: string;
  selectedPreviousContainerId?: string;
  isBase?: boolean;
};

const PeriodSelector = ({
  handleChange,
  title,
  currentPeriod,
  periods,
  defaultPeriod,
  selectedBaseContainerId,
  selectedPreviousContainerId,
  isBase
}: PeriodSelectorProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [dropDownSelection, setDropDownSelection] = useState('');

  useEffect(() => {
    setDropDownSelection(defaultPeriod?.containerId ?? '');
  }, [defaultPeriod]);

  useEffect(() => {
    if (isBase) {
      setDropDownSelection(selectedBaseContainerId ?? '');
    } else {
      setDropDownSelection(selectedPreviousContainerId ?? '');
    }
  }, [isBase, selectedBaseContainerId, selectedPreviousContainerId]);

  return (
    <Box>
      <Typography variant="h3" component="h1" className={classes.title}>
        {t(title ? title : '')}
      </Typography>
      <Select
        defaultValue={defaultPeriod?.containerId ?? ''}
        IconComponent={ArrowDropDown}
        variant="outlined"
        value={dropDownSelection}
        className={classes.periodSelector}
        onChange={(event: any) => {
          const val = event?.target?.value;
          const selectedContainer = periods?.find((period) => period.containerId === val);
          const selection = isBase ? 'base' : 'compare';
          handleChange(selectedContainer, selection);
        }}
      >
        {periods?.map(({ containerId, containerName }) => (
          <MenuItem key={containerId} value={containerId}>
            {containerId === currentPeriod?.containerId ? t('Current Period') : containerName}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default PeriodSelector;
