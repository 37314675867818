import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import reducers from './redux';

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25
});

const middleware =
  process.env.NODE_ENV === 'development'
    ? // NOTE: `redux-immutable-state-invariant` slows down the app, so don't run it in production.
      // NOTE: We are using this style of import because `redux-immutable-state-invariant` is a devDependency, so it can't be imported att the top.
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      [require('redux-immutable-state-invariant').default(), thunk]
    : [thunk];

const store = createStore(reducers, composeEnhancers(applyMiddleware(...middleware)));

export default store;
