import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Input from '@material-ui/core/Input';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import ReportOutlined from '@material-ui/icons/ReportOutlined';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    justifyContent: 'space between'
  },
  cell: {
    padding: theme.spacing(1.2)
  },
  containerError: {
    position: 'relative',
    border: `2px solid ${theme.palette.error.main}`
  },
  icon: {
    color: theme.palette.error.main
  },
  error: {
    '& .MuiInput-input': {
      textAlign: 'inherit',
      fontSize: theme.typography.fontSize,
      color: theme.palette.error.main
    }
  },
  message: {
    padding: '5px 10px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.text.primary,
    textAlign: 'center',
    borderRadius: theme.shape.borderRadius,
    position: 'absolute',
    left: 0,
    bottom: theme.spacing(5.2),
    zIndex: 2
  },
  displayInlineFlex: {
    display: 'inline-flex'
  },
  negativeMargin: {
    marginLeft: '-10px'
  }
}));

export interface CreditNameBoxProps {
  setNewCreditName: (creditName: string) => void;
  isCreditNameDuplicated?: boolean;
  isCreditNameReservedName?: boolean;
  creditName?: string;
}

const CreditNameBox = ({
  setNewCreditName,
  isCreditNameDuplicated,
  isCreditNameReservedName,
  creditName
}: CreditNameBoxProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const creditNameError = isCreditNameDuplicated || isCreditNameReservedName;

  return (
    <Typography variant="h3" component="h4" className={classes.negativeMargin}>
      <Box
        role="container"
        className={classnames(classes.cell, classes.displayInlineFlex, {
          [classes.containerError]: creditNameError
        })}
      >
        {creditNameError && (
          <Box role="message" className={classes.message}>
            {isCreditNameDuplicated
              ? t('Credit already exists')
              : t(
                  `'${String(
                    creditName
                  )}' is one of a list of reserved words in the system and must not be used for naming. Please choose a different name.`
                )}
          </Box>
        )}
        <Box className={classes.box}>
          {creditNameError && <ReportOutlined role="icon" className={classes.icon} />}
          <Input
            className={classnames({ [classes.error]: creditNameError })}
            placeholder="Enter Credit Name"
            onChange={(event) => {
              if (creditNameError) {
                setNewCreditName(event.target.value?.trim());
              }
            }}
            onBlur={(event) => {
              setNewCreditName(event.target.value?.trim());
            }}
          />
        </Box>
      </Box>
    </Typography>
  );
};

export default CreditNameBox;
