import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';

import LoadingWrapper from '../../../LoadingWrapper';

export type CardProps = {
  isSelected: boolean;
  isLoading?: boolean;
  title: string;
  value: string;
  currency?: string;
  onClick: () => void;
};

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.common.white,
    boxSizing: 'border-box',
    height: '100px',
    width: '357px',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  containerSelected: {
    boxShadow: '0 0 8px 0 rgba(130,130,130,0.5)',
    borderTop: `6px solid ${theme.palette.action.focus}`
  },
  // To offset the card content on the y-axis when adding the wider top border
  floatingContainer: {
    padding: '20px',
    paddingLeft: '24px',
    position: 'relative'
  },
  floatingContainerSelected: {
    transform: 'translateY(-5px)'
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    letterSpacing: '0.25px',
    lineHeight: '18px'
  },
  value: {
    marginTop: '15px',
    fontSize: '20px',
    letterSpacing: '0.15px',
    lineHeight: '26px'
  },
  currencyChip: {
    backgroundColor: theme.palette.info.main,
    borderRadius: '4px',
    fontSize: '12px',
    letterSpacing: '0.6px',
    lineHeight: '14px',
    height: '24px',
    position: 'absolute',
    top: '20px',
    right: '20px'
  }
}));

const Card = ({ isSelected, title, value, currency, isLoading, onClick }: CardProps) => {
  const classes = useStyles();

  return (
    <Box className={classnames(classes.container, { [classes.containerSelected]: isSelected })} onClick={onClick}>
      <LoadingWrapper isLoading={Boolean(isLoading)}>
        <Box className={classnames(classes.floatingContainer, { [classes.floatingContainerSelected]: isSelected })}>
          <Typography variant="body2" className={classes.title}>
            {title}
          </Typography>
          <Typography className={classes.value}>{value}</Typography>
          {currency && <Chip className={classes.currencyChip} label={currency} />}
        </Box>
      </LoadingWrapper>
    </Box>
  );
};

export default Card;
