import { Theme } from '@material-ui/core';

export const makeStylesName = (theme: Theme) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'right',
  '& .MuiIconButton-root': {
    marginRight: theme.spacing(0.5)
  }
});
