import { Dispatch, RefObject, SetStateAction, useEffect, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';

import * as XLSX from 'xlsx';

import { getColumnsAndRowsFromSelection } from '../../components/Spreadsheet/utils';
import { resetDataImport } from '../../redux/dataImport';
import { enqueueNotification } from '../../redux/notifications';
import { decodeStringWithHtmlEntities } from '../../utils';
const ROWS_PER_SHEET = 23;
interface UseSpreadsheet {
  data?: any;
  options: any;
  selectedField?: string | null;
  setSelectedField: Dispatch<SetStateAction<string | null>>;
  workbook: XLSX.WorkBook;
  overrideColumnSelection?: boolean;
}

export const useSpreadsheet = ({
  data,
  options,
  selectedField,
  setSelectedField,
  workbook,
  overrideColumnSelection = false
}: UseSpreadsheet) => {
  const dispatch = useDispatch();
  const sheetRef: any = useRef({});
  const sheetEl: RefObject<HTMLDivElement> = useRef(null);
  const [activeSheet, setActiveSheet] = useState<XLSX.WorkSheet>();
  const [range, setRange] = useState<string | null>('A1');

  useEffect(() => {
    if (typeof window.x_spreadsheet === 'function') {
      const element = sheetEl.current;
      const sheet = window
        .x_spreadsheet('#x-spreadsheet', {
          view: {
            height: () => element?.clientHeight ?? document.documentElement.clientHeight,
            width: () => element?.clientWidth ?? document.documentElement.clientWidth
          },
          ...options
        })
        .loadData(data ?? {});
      const rowHeight: number = sheet?.data?.rows?.height ?? 25;
      const rowsLength: number = sheet?.data?.rows?.len ?? ROWS_PER_SHEET;

      if (workbook?.SheetNames) {
        const sheetName = workbook.SheetNames[0];
        setActiveSheet(workbook.Sheets[sheetName]);
      }

      sheet.on('cell-selected', (_cell: any, ri: number, ci: number) => {
        const A1FormattedSelection = XLSX.utils.encode_range({ s: { c: ci, r: ri }, e: { c: ci, r: ri } });
        setRange(A1FormattedSelection);
      });

      let relativeCursorY = 0;

      const mouseMoveListener = (e: MouseEvent) => {
        const containerTop = sheetEl.current?.getBoundingClientRect().top ?? 0;
        relativeCursorY = e.clientY - containerTop;
      };

      sheetEl.current?.addEventListener('mousemove', mouseMoveListener);

      let lastSri: number;
      let lastEri: number;
      sheet.on(
        'cells-selected',
        (_cell: any, { sri, sci, eri, eci }: { sri: number; sci: number; eri: number; eci: number }) => {
          const A1FormattedSelection = XLSX.utils.encode_range({ s: { c: sci, r: sri }, e: { c: eci, r: eri } });

          const { leftCol, rightCol } = getColumnsAndRowsFromSelection(A1FormattedSelection);
          const selectionSpansMoreThanColumn =
            leftCol !== rightCol && selectedField !== null && selectedField !== undefined && !overrideColumnSelection;

          if (selectionSpansMoreThanColumn) {
            dispatch(
              enqueueNotification({
                message: 'Selection must be from one column',
                options: {
                  variant: 'warning'
                }
              })
            );

            return;
          }

          setRange(A1FormattedSelection);

          const scrollTriggerThresholdBottom = (sheetEl.current?.clientHeight ?? 0) * 0.9;
          const scrollTriggerThresholdTop = (sheetEl.current?.clientHeight ?? 0) * 0.1;
          const isCursorBelowTriggerThresholdBottom = relativeCursorY > scrollTriggerThresholdBottom;
          const isCursorAboveTriggerThresholdTop = relativeCursorY < scrollTriggerThresholdTop;
          const isSelectionMovingDownwards = (lastSri < sri && lastEri === eri) || (lastEri < eri && lastSri === sri);
          const isSelectionMovingUpwards = (lastSri > sri && lastEri === eri) || (lastEri > eri && lastSri === sri);
          const isSelectionAtBottom = eri + 1 === rowsLength;
          const isSelectionAtTop = sri === 0;

          if (isCursorBelowTriggerThresholdBottom && isSelectionMovingDownwards && !isSelectionAtBottom) {
            sheet.sheet.data.scroll.y += rowHeight;
            sheet.sheet.data.scroll.ri++;
          }

          if (isCursorAboveTriggerThresholdTop && isSelectionMovingUpwards && !isSelectionAtTop) {
            sheet.sheet.data.scroll.y -= rowHeight;
            sheet.sheet.data.scroll.ri--;
          }

          lastSri = sri;
          lastEri = eri;
        }
      );

      sheet.bottombar.clickSwap = (item: { el: { innerHTML: string } }) => {
        if (sheet.bottombar.activeEl !== null) {
          sheet.bottombar.activeEl.toggle();
        }

        setActiveSheet(workbook.Sheets[`${decodeStringWithHtmlEntities(item.el.innerHTML)}`]);
        sheet.bottombar.activeEl = item;
        dispatch(resetDataImport());
        setSelectedField(null);
      };

      sheetRef.current = sheet;

      return () => {
        if (element) {
          element.innerHTML = '';
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workbook]);
  return { range, activeSheet, sheetEl, sheetRef, setRange };
};
