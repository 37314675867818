import { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CheckCircle from '@material-ui/icons/CheckCircle';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import Skeleton from '@material-ui/lab/Skeleton';

import { setSubJurisdictionSidemenuSelectedId } from '../../../../redux/ui';
import { ASC, DESC, filterAndSort } from '../../../Table/utils';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  listContainer: {
    backgroundColor: theme.palette.background.default,
    overflowY: 'auto',
    padding: theme.spacing(0.5)
  },
  listTitle: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1, 2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  },
  counterContainer: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'right',
    paddingRight: theme.spacing(1.5)
  },
  list: {
    minWidth: 200
  },
  listItem: {
    cursor: 'pointer',
    border: '1px solid transparent',
    borderRadius: theme.shape.borderRadius,
    '&.active': {
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.action.selected
    }
  },
  listItemIcon: {
    lineHeight: 0
  },
  moreIconContainer: {
    padding: theme.spacing(0)
  }
}));

type Props = {
  states: Array<{
    id?: string;
    name?: string;
    isCompleted?: boolean;
  }>;
  currentState?: {
    id?: string;
  };
  onSelect?: (...args: any[]) => any;
};

const StateSelector = ({ states, currentState, onSelect }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSubJurisdictionSidemenuSelectedId(currentState?.id));
    return () => {
      dispatch(setSubJurisdictionSidemenuSelectedId());
    };
  }, [currentState, dispatch]);

  const filters = [
    {
      id: 1,
      nameKey: 'Alphabetically A-Z',
      column: 'name',
      dir: ASC
    },
    {
      id: 2,
      nameKey: 'Alphabetically Z-A',
      column: 'name',
      dir: DESC
    },
    {
      id: 3,
      nameKey: 'Completed - In Progress',
      column: 'isCompleted',
      dir: DESC
    },
    {
      id: 4,
      nameKey: 'In Progress - Completed',
      column: 'isCompleted',
      dir: ASC
    }
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(filters[0]);

  const [filteredSource, setFilteredSource] = useState(states);
  const [completedCount, setCompletedCount] = useState(0);
  const [sort, setSort] = useState({ column: { field: 'name' }, dir: ASC });

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSetFilter = (filterId: any) => {
    setSelectedFilter(filters[filterId - 1]);
    handleClose();
  };

  const setCompleted = () => {
    const completed = filteredSource.filter((state) => state.isCompleted);
    setCompletedCount(completed.length);
  };

  useEffect(() => {
    setCompleted();
  });

  useEffect(() => {
    const { column, dir } = selectedFilter;
    setSort({ column: { field: column }, dir });
  }, [selectedFilter]);

  useEffect(() => {
    setFilteredSource(filterAndSort(states, [], sort));
  }, [sort, states]);
  return (
    <Box component="span" mr={2} className={classes.container}>
      <Box className={classes.listTitle}>
        {t('States')}
        <IconButton
          placeholder="Show filters"
          component="span"
          className={classes.moreIconContainer}
          onClick={handleClick}
        >
          <MoreHoriz />
        </IconButton>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          onClose={handleClose}
        >
          <Box>
            <List>
              {filters.map((filter) => (
                <ListItem
                  key={filter.id}
                  button
                  selected={selectedFilter.id === filter.id}
                  onClick={() => {
                    handleSetFilter(filter.id);
                  }}
                >
                  <ListItemText primary={t(filter.nameKey)} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Popover>
      </Box>
      {filteredSource ? (
        <Box className={classes.listContainer}>
          <Box className={classes.counterContainer}>{`${completedCount} / ${filteredSource.length}`}</Box>
          <List dense className={classes.list}>
            {filteredSource.map((state) => (
              <ListItem
                key={state.id}
                dense
                className={`${classes.listItem}${currentState?.id === state.id ? ' active' : ''}`}
                selected={currentState?.id === state.id}
                // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                onClick={() => onSelect(state)}
              >
                {/* FIXME: WRONG CLASS NAME */}
                <ListItemText className={(classes as any).listItemText} primary={state.name} data-testid="state-name" />
                <ListItemSecondaryAction className={classes.listItemIcon}>
                  {state.isCompleted ? (
                    <CheckCircle fontSize="small" data-testid="checked-icon" />
                  ) : (
                    <RadioButtonUnchecked fontSize="small" />
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Box>
      ) : (
        <Skeleton animation="wave" />
      )}
    </Box>
  );
};

export default StateSelector;
