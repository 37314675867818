import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  importButton: {
    fontFamily: theme.typography.body2.fontFamily,
    letterSpacing: '0.25px',
    lineHeight: '18px',
    height: '36px',
    width: '85px',
    borderRadius: '4px',
    border: '1px solid',
    borderColor: 'transparent'
  },
  disabled: {
    cursor: 'not-allowed'
  }
}));
