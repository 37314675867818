import { Dispatch, SetStateAction, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Box } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';

import { Rates } from '..';
import { useContainers } from '../../../../hooks';
import { EntityWithRates, Jurisdiction, RateUpdate } from '../../../../models';
import { formatSubJurisdictionForDisplay, sortSubJursByName } from '../../../../utils';
import { FailedCells } from '../../../Table/Table.proptype';
import { disableElementForRoles } from '../../../UserRoleStylesProvider/constants';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(1, 2),
    '& .Pro-rates': {
      marginBottom: theme.spacing(1)
    }
  },
  editIcon: {
    cursor: 'pointer',
    marginRight: theme.spacing(1) * -1,
    marginTop: theme.spacing(0.2) * -1
  },
  sectionTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

type Props = {
  entity: EntityWithRates;
  jurisdictions: Jurisdiction[];
  failedCells: FailedCells;
  onRateChange: (rateUpdate: RateUpdate) => void;
  setIsEditingExpandedEntity: Dispatch<SetStateAction<boolean>>;
};

const EntityJurisdiction = ({
  entity,
  jurisdictions,
  failedCells,
  onRateChange,
  setIsEditingExpandedEntity
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { currentContainer } = useContainers();

  const jurisdiction = useMemo(() => jurisdictions.find(({ id }) => id === entity.jurisdictionId)!, [
    entity,
    jurisdictions
  ]);

  const subJurisdictions = useMemo(() => {
    return entity.subJurisdictionIds
      .map((subJurisdictionId) =>
        formatSubJurisdictionForDisplay(
          t,
          jurisdiction?.subJurisdictions?.find(({ id }) => {
            return subJurisdictionId === id;
          })
        )
      )
      .filter(Boolean)
      .sort(sortSubJursByName);
  }, [entity, jurisdiction, t]);

  return (
    <Box className={classes.content}>
      <Box className={classes.sectionTitle}>{t('Federal')}</Box>
      <Rates source={jurisdiction} entity={entity} failedCells={failedCells} onRateChange={onRateChange} />
      {jurisdiction.subJurisdictions.length > 0 && (
        <>
          <Box className={classes.sectionTitle}>
            <div>{t('States / Cities')}</div>
            <Icon
              data-roles-disable-element={
                currentContainer?.isFinalized ? 'finalizedContainer' : disableElementForRoles.join(' ')
              }
              className={classes.editIcon}
              onClick={() => {
                setIsEditingExpandedEntity(true);
              }}
            >
              <img src="/imgs/EditList.svg" />
            </Icon>
          </Box>
          {subJurisdictions.map((subJurisdiction: any) => (
            <Rates
              key={subJurisdiction.id}
              source={subJurisdiction}
              entity={entity}
              failedCells={failedCells}
              onRateChange={onRateChange}
            />
          ))}
        </>
      )}
    </Box>
  );
};

export default EntityJurisdiction;
