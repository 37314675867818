import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import {
  uploadReviewSetTab,
  uploadReviewOnReceived,
  uploadReviewMetaDataOnReceived,
  uploadReviewMetaDataOnReceivedEmpty,
  uploadReviewMetaDataOnFailed,
  uploadReviewOnSendingRequest,
  uploadReviewOnFailed,
  uploadReviewFinishOnSuccess,
  uploadReviewEntityUpdate,
  uploadReviewRangesUpdate,
  uploadReviewTaxSensitiveUpdate,
  uploadReviewOnInitialUploadSuccess,
  uploadReviewOnInitialUploadFailed,
  uploadReviewOnInitialUploadSendingRequest,
  uploadReviewOnUpdateSuccess,
  uploadReviewOnUpdateFailed,
  uploadReviewResetData
} from './uploadReview.actions';
import { uploadReviewInitialState } from './uploadReview.initialState';

import { AccountRange, Category, EntityFromUpload } from '../../models';
import { containersOnCurrentSwitched } from '../containers';

export const uploadReviewReducer = createReducer(uploadReviewInitialState, (builder) => {
  builder
    .addCase(uploadReviewOnSendingRequest, (state) => {
      return { ...state, isLoading: true, error: null, hasLoaded: false };
    })
    .addCase(uploadReviewOnReceived, (state, action) => {
      const {
        fileName,
        updatedOn,
        userId,
        containerId,
        entities,
        isUploadReviewCompleted,
        isDirty,
        ...rest
      } = action.payload.trialBalance;
      return {
        ...state,
        ...rest,
        error: null,
        metaData: {
          error: null,
          fileName,
          updatedOn,
          userId,
          containerId,
          isUploadReviewCompleted,
          isDirty
        },
        entities: entities.map((entity) => ({ ...entity, subJurisdictionIds: entity.subJurisdictionIds ?? [] })),
        isLoading: false,
        hasLoaded: true,
        hasMetaDataLoaded: true
      };
    })
    .addCase(uploadReviewMetaDataOnReceivedEmpty, (_, action) => {
      const { containerId, isUploadReviewCompleted } = action.payload.metaData;
      return {
        ...uploadReviewInitialState,
        metaData: {
          ...uploadReviewInitialState.metaData,
          containerId,
          isUploadReviewCompleted
        },
        hasMetaDataLoaded: true
      };
    })
    .addCase(uploadReviewMetaDataOnReceived, (_, action) => {
      return {
        ...uploadReviewInitialState,
        metaData: { ...uploadReviewInitialState.metaData, ...action.payload.metaData },
        hasMetaDataLoaded: true
      };
    })
    .addCase(uploadReviewMetaDataOnFailed, (state, action) => {
      return {
        ...state,
        metaData: {
          ...state.metaData,
          isLoading: false,
          hasLoadedMetaData: true,
          error: action.payload
        }
      };
    })
    .addCase(uploadReviewEntityUpdate, (state, action) => {
      const { entities } = action.payload as {
        entities: EntityFromUpload[];
      };

      return {
        ...state,
        entities: entities.map((entity: EntityFromUpload) => ({
          ...entity,
          subJurisdictionIds: entity.subJurisdictionIds ?? []
        })),
        error: null
      };
    })
    .addCase(uploadReviewRangesUpdate, (state, action) => {
      const { ranges } = action.payload as {
        ranges: AccountRange[];
      };

      return {
        ...state,
        ranges,
        error: null
      };
    })
    .addCase(uploadReviewTaxSensitiveUpdate, (state, action) => {
      const { categories } = action.payload as {
        categories: Category[];
      };

      return {
        ...state,
        categories,
        error: null
      };
    })
    .addCase(uploadReviewOnInitialUploadSendingRequest, (state) => {
      return { ...state, isInitialUploadLoading: true, error: null, hasLoaded: false };
    })
    .addCase(uploadReviewOnInitialUploadSuccess, (state) => {
      return {
        ...state,
        isInitialUploadLoading: false,
        hasInitialUploadLoaded: true,
        error: null
      };
    })
    .addCase(uploadReviewOnInitialUploadFailed, (state, action) => {
      return { ...uploadReviewInitialState, metaData: state.metaData, error: action.payload };
    })
    .addCase(uploadReviewOnUpdateFailed, (state, action) => {
      return { ...uploadReviewInitialState, metaData: state.metaData, error: action.payload };
    })
    .addCase(uploadReviewOnUpdateSuccess, (state) => {
      return {
        ...state,
        isLoading: false,
        hasLoaded: true,
        error: null,
        metaData: { ...state.metaData, isDirty: true }
      };
    })
    .addCase(uploadReviewOnFailed, (state, action) => {
      return { ...state, isLoading: false, error: action.payload };
    })
    .addCase(uploadReviewSetTab, (state, action) => {
      return { ...state, tab: action.payload };
    })
    .addCase(uploadReviewFinishOnSuccess, (state) => {
      return {
        ...uploadReviewInitialState,
        tab: state.tab,
        isLoading: false,
        error: null,
        hasLoaded: false,
        hasMetaDataLoaded: false,
        metaData: { ...state.metaData, isUploadReviewCompleted: true }
      };
    })
    .addCase(uploadReviewResetData, (state) => {
      return {
        ...uploadReviewInitialState,
        error: state.error,
        hasMetaDataLoaded: state.hasMetaDataLoaded,
        metaData: state.metaData,
        tab: state.tab
      };
    })
    .addMatcher(isAnyOf(containersOnCurrentSwitched), () => {
      return uploadReviewInitialState;
    });
});
