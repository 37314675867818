import { useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Redirect, Route, useHistory, useRouteMatch } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { SlideOut } from '@xbs/xbs-common-ui';

import {
  Apportion,
  EntityDetailsHeader,
  EntityHeader,
  EntityList,
  FederalAccounts,
  FederalCredits,
  FederalDeferred,
  FederalNetOperatingLoss,
  FederalReturnToProvision,
  PermanentAdjustments,
  PreTaxBookIncome,
  StateAccounts,
  StateCredits,
  StateDeferred,
  StateModifications,
  StateNetOperatingLoss,
  StateTaxEffected,
  StateReturnToProvision,
  FederalTaxEffected,
  TemporaryAdjustments,
  DataCategories
} from './components';
import DiscreteAdjustment from './components/DiscreteAdjustment';
import { EntityNumberRouteMatch, getEntitySubJurisdictionData } from './utils';

import { discreteSteps, getFederalSteps, getStateSteps } from '../../constants';
import { useContainers, useEntities, useEntity, useJurisdictions } from '../../hooks';
import { Entity, EntityWithJurisdictionName, Step } from '../../models';
import { resetDataImport } from '../../redux/dataImport';
import {
  resetUploadManager,
  setFileName,
  setIsFileDownloaded,
  setUploadManagerIsOpen
} from '../../redux/uploadManager';
import { selectAlterSubJurisdictionsState, selectEntities } from '../../selectors';
import { selectUploadManagerIsOpen } from '../../selectors/uploadManager';
import FileUpload from '../FileUpload/FileUpload';
import LoadingWrapper from '../LoadingWrapper';
import UploadManager from '../UploadManager/UploadManager';

const useStyles = makeStyles((theme) => ({
  innerBox: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '4px',
    border: `1px solid ${theme.palette.divider}`,
    margin: `${theme.spacing(2)}px`,
    overflow: 'hidden',
    height: '100%'
  },
  outerBox: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: theme.palette.primary.light
  },
  categoriesBackground: {
    backgroundColor: theme.palette.primary.light,
    paddingTop: '25px',
    paddingBottom: '29px',
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    overflow: 'hidden',
    flexDirection: 'column',
    alignItems: 'center'
  },
  categoriesContainer: {
    overflowY: 'auto',
    backgroundColor: theme.palette.common.white,
    border: '1px solid',
    borderColor: theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
    width: '58vw',
    height: '80vh'
  },
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  }
}));

function useCurrentEntity() {
  const {
    path,
    params: { entityNumber }
  } = useRouteMatch<EntityNumberRouteMatch>();
  const history = useHistory();
  const { entity, error } = useEntity(entityNumber);
  useEffect(() => {
    if (error) {
      history.replace('/entity-details');
    }
  }, [error, history]);
  return { entity, path };
}

const FederalRouter = () => {
  const { path, entity } = useCurrentEntity();
  const classes = useStyles();
  const uploadManagerIsOpen = useSelector(selectUploadManagerIsOpen);
  const { currentContainer } = useContainers();
  const dispatch = useDispatch();
  const federalSteps = getFederalSteps();
  return (
    <Switch>
      {federalSteps.map((step) => {
        const routePath = `${path}/${step}`;
        return (
          <Route key={step} path={routePath}>
            <SlideOut.Container>
              <Box className={classes.outerBox}>
                <Box className={classes.innerBox}>
                  <EntityDetailsHeader jurisdictionType="federal" entity={entity} tab={step as Step} />
                  <UploadManager
                    containerName={currentContainer?.containerName ?? ''}
                    isOpen={uploadManagerIsOpen}
                    onClose={() => {
                      dispatch(resetUploadManager());
                      dispatch(resetDataImport());
                    }}
                  >
                    <FileUpload
                      entityName={entity?.name}
                      step={step as Step}
                      level="federal"
                      entitySubJurisdictions={[]}
                    />
                  </UploadManager>
                  {step === federalSteps[0] ? (
                    <FederalReturnToProvision entityId={entity?.entityId} />
                  ) : step === federalSteps[1] ? (
                    <PreTaxBookIncome entityId={entity?.entityId} />
                  ) : step === federalSteps[2] ? (
                    <PermanentAdjustments entityId={entity?.entityId} />
                  ) : step === federalSteps[3] ? (
                    <TemporaryAdjustments />
                  ) : step === federalSteps[4] ? (
                    <FederalNetOperatingLoss entityId={entity?.entityId} />
                  ) : step === federalSteps[5] ? (
                    <FederalCredits entityId={entity?.entityId} />
                  ) : step === federalSteps[6] ? (
                    <FederalTaxEffected entityId={entity?.entityId} />
                  ) : step === federalSteps[7] ? (
                    <FederalDeferred entityId={entity?.entityId} />
                  ) : (
                    <FederalAccounts entityId={entity?.entityId} />
                  )}
                </Box>
              </Box>
            </SlideOut.Container>
          </Route>
        );
      })}
      <Redirect to={`${path}/${federalSteps[0]}`} />
    </Switch>
  );
};

const StateRouter = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const uploadManagerIsOpen = useSelector(selectUploadManagerIsOpen);
  const { currentContainer } = useContainers();
  const dispatch = useDispatch();
  const { path, entity } = useCurrentEntity();
  const { isLoading: areStateChangesInFlight } = useSelector(selectAlterSubJurisdictionsState);
  const { jurisdictions } = useJurisdictions();
  const { subJurisdictionById, entitySubJurisdictions } = useMemo(
    () => getEntitySubJurisdictionData({ entity, jurisdictions, t }),
    [entity, jurisdictions, t]
  );
  const stateSteps = getStateSteps();
  return (
    <LoadingWrapper isLoading={areStateChangesInFlight}>
      <Switch>
        {stateSteps.map((step) => {
          const routePath = `${path}/${step}`;
          return (
            <Route key={step} path={routePath}>
              <SlideOut.Container>
                <Box className={classes.outerBox}>
                  <Box className={classes.innerBox}>
                    <EntityDetailsHeader jurisdictionType="state" entity={entity} tab={step as Step} />
                    <UploadManager
                      containerName={currentContainer?.containerName ?? ''}
                      isOpen={uploadManagerIsOpen}
                      onClose={() => {
                        dispatch(resetUploadManager());
                        dispatch(resetDataImport());
                      }}
                    >
                      <FileUpload
                        entityName={entity?.name}
                        step={step as Step}
                        level="state"
                        entitySubJurisdictions={entitySubJurisdictions}
                      />
                    </UploadManager>
                    {step === stateSteps[0] ? (
                      <StateReturnToProvision entityId={entity?.entityId} states={entitySubJurisdictions} />
                    ) : step === stateSteps[1] ? (
                      <Apportion entityId={entity?.entityId} states={entitySubJurisdictions} />
                    ) : step === stateSteps[2] ? (
                      <StateModifications entityId={entity?.entityId} states={entitySubJurisdictions} />
                    ) : step === stateSteps[3] ? (
                      <StateNetOperatingLoss entityId={entity?.entityId} states={entitySubJurisdictions} />
                    ) : step === stateSteps[4] ? (
                      <StateCredits entityId={entity?.entityId} states={entitySubJurisdictions} />
                    ) : step === stateSteps[5] ? (
                      <StateTaxEffected entityId={entity?.entityId} states={entitySubJurisdictions} />
                    ) : step === stateSteps[6] ? (
                      <StateDeferred
                        entityId={entity?.entityId}
                        states={entitySubJurisdictions}
                        subJurisdictionById={subJurisdictionById}
                      />
                    ) : (
                      <StateAccounts entityId={entity?.entityId} states={entitySubJurisdictions} />
                    )}
                  </Box>
                </Box>
              </SlideOut.Container>
            </Route>
          );
        })}
        <Redirect to={`${path}/${stateSteps[0]}`} />
      </Switch>
    </LoadingWrapper>
  );
};

const DiscreteRouter = () => {
  const { path, entity } = useCurrentEntity();
  const classes = useStyles();
  const uploadManagerIsOpen = useSelector(selectUploadManagerIsOpen);
  const { currentContainer } = useContainers();
  const dispatch = useDispatch();
  return (
    <Switch>
      {discreteSteps.map((step: Step) => {
        const routePath = `${path}/${step}`;
        return (
          <Route key={step} path={routePath}>
            <SlideOut.Container>
              <Box className={classes.outerBox}>
                <Box className={classes.innerBox}>
                  <EntityDetailsHeader jurisdictionType="discrete" entity={entity} tab={step} />
                  <UploadManager
                    containerName={currentContainer?.containerName ?? ''}
                    isOpen={uploadManagerIsOpen}
                    onClose={() => {
                      dispatch(setUploadManagerIsOpen(false));
                      dispatch(setFileName(null));
                      dispatch(setIsFileDownloaded(false));
                      dispatch(resetDataImport());
                    }}
                  >
                    <FileUpload entityName={entity?.name} step={step} level="discrete" entitySubJurisdictions={[]} />
                  </UploadManager>
                  <DiscreteAdjustment entityId={entity?.entityId} />
                </Box>
              </Box>
            </SlideOut.Container>
          </Route>
        );
      })}
      <Redirect to={`${path}/${discreteSteps[0] as string}`} />
    </Switch>
  );
};

const EntityDetails = () => {
  const { entities } = useEntities();
  const { jurisdictionById } = useJurisdictions();
  const entitiesWithJurisdictionInfo: EntityWithJurisdictionName[] = useMemo(
    () =>
      entities.map((entity: Entity) => {
        const entityInfo = jurisdictionById?.[entity.jurisdictionId];
        return {
          ...entity,
          jurisdictionName: entityInfo?.name,
          isoCode: entityInfo?.isoCode
        };
      }),
    [entities, jurisdictionById]
  );
  return <EntityList entities={entitiesWithJurisdictionInfo} />;
};

const Categories = () => {
  const { path } = useRouteMatch();
  const classes = useStyles();

  return (
    <Box className={classes.outerContainer}>
      <EntityHeader tab="categories" />
      <Box className={classes.categoriesBackground}>
        <Box className={classes.categoriesContainer}>
          <Switch>
            <Route path={`${path}/:jurisdictionLevel/:categoryName/:adjustmentName?`} />
            <Route exact path={path}>
              <DataCategories />
            </Route>
            <Redirect to={path} />
          </Switch>
        </Box>
      </Box>
    </Box>
  );
};

const EntityDetailsRouter = () => {
  const { path } = useRouteMatch();
  const { isLoaded: entitiesLoaded } = useSelector(selectEntities);
  const { currentContainer } = useContainers();
  const isInterim = currentContainer?.isInterimReportingPeriod;
  return (
    <Switch>
      <Route path={`${path}/categories`}>
        <Categories />
      </Route>
      {entitiesLoaded && (
        <Route path={`${path}/:entityNumber/federal`}>
          <FederalRouter />
        </Route>
      )}
      {entitiesLoaded && (
        <Route path={`${path}/:entityNumber/state`}>
          <StateRouter />
        </Route>
      )}
      {entitiesLoaded && isInterim && (
        <Route path={`${path}/:entityNumber/discrete`}>
          <DiscreteRouter />
        </Route>
      )}
      <Route exact path={path}>
        <EntityDetails />
      </Route>
      <Redirect to={path} />
    </Switch>
  );
};

export default EntityDetailsRouter;
