import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import classnames from 'classnames';

import useStyles from './StateCellButton.styles';

import { StateModificationsRow } from '../../StateModifications.types';

const StateCellButton = ({
  row,
  hasNewRow,
  setSelected,
  status
}: {
  row: StateModificationsRow;
  hasNewRow: boolean;
  setSelected: (row: StateModificationsRow) => void;
  status: boolean;
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const buttonText = row.state?.length ? t('_ state', { count: row.state.length }) : t('Add States');

  // prevent render in total row
  if (!Object.keys(row)?.length) {
    return null;
  }

  if (status || (hasNewRow && !row.isNew)) {
    return <div>{buttonText}</div>;
  }

  return (
    <Button
      variant="text"
      className={classnames(classes.stateButton, {
        [classes.newCell]: (!status && !hasNewRow) || row.isNew
      })}
      onClick={() => {
        if (!status) {
          setSelected(row);
        }
      }}
    >
      {buttonText}
    </Button>
  );
};

export default StateCellButton;
