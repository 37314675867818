import { makeStyles, TableCell } from '@material-ui/core';

export type Props = {
  valueToRender: number;
  valueToDetermineColor?: number;
};

const useStyles = makeStyles((theme) => ({
  taxRateChip: {
    color: ({ valueToRender, valueToDetermineColor }: { valueToRender: number; valueToDetermineColor?: number }) => {
      if ((valueToDetermineColor ?? valueToRender) < 0) {
        return theme.palette.primary.main;
      }

      if ((valueToDetermineColor ?? valueToRender) > 0) {
        return theme.palette.error.main;
      }

      if ((valueToDetermineColor ?? valueToRender) === 0) {
        return theme.palette.text.primary;
      }
    },
    backgroundColor: ({
      valueToRender,
      valueToDetermineColor
    }: {
      valueToRender: number;
      valueToDetermineColor?: number;
    }) => {
      if ((valueToDetermineColor ?? valueToRender) < 0) {
        return theme.palette.info.main;
      }

      if ((valueToDetermineColor ?? valueToRender) > 0) {
        return '#FFBAB630';
      }

      if ((valueToDetermineColor ?? valueToRender) === 0) {
        return theme.palette.primary.light;
      }
    },
    fontFamily: theme.typography.h3.fontFamily,
    fontSize: theme.typography.h3.fontSize,
    borderTop: 'none !important',
    width: '114.4px',
    height: '36px',
    textAlign: 'end',
    paddingTop: '6.6px !important',
    paddingBottom: '6.6px !important',
    borderRadius: '4px'
  }
}));

const TaxRateChip = ({ valueToRender = 0, valueToDetermineColor }: Props) => {
  const classes = useStyles({ valueToRender, valueToDetermineColor });
  return <TableCell className={classes.taxRateChip}>{`${valueToRender}%`}</TableCell>;
};

export default TaxRateChip;
