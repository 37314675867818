import { EntitiesStateInterface } from '../../models';

export const entitiesInitialState: EntitiesStateInterface = {
  entities: [],
  error: null,
  isLoading: false,
  isLoaded: false,
  alterSubJurisdictions: {
    isLoading: false,
    error: null
  },
  alterParent: {
    isLoading: false,
    error: null
  },
  updateEntityCurrency: {
    isLoading: false,
    error: null
  },
  updateEntityJurisdiction: {
    isLoading: false,
    error: null
  },
  updateEntityName: {
    isLoading: false,
    error: null
  },
  updateEntityNumber: {
    isLoading: false,
    error: null
  },
  deleteEntity: {
    isLoading: false,
    error: null
  }
};
