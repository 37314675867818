import { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useContainers } from '..';
import { UploadReviewTrialBalanceMetaData } from '../../models';
import {
  uploadReviewMetaDataOnSendingRequest,
  uploadReviewMetaDataOnReceivedEmpty,
  uploadReviewMetaDataOnReceived,
  uploadReviewMetaDataOnFailed
} from '../../redux/uploadReview';
import { selectUploadReview } from '../../selectors';
import HTTPService, { LambdaResponse } from '../../services/http';

const trialBalanceIsEmpty = (trialBalance: UploadReviewTrialBalanceMetaData) => !trialBalance.fileName;

export const useUploadMetaData = () => {
  const dispatch = useDispatch();
  const uploadReviewState = useSelector(selectUploadReview);
  const { currentContainer } = useContainers();

  const fetchMetaData = useCallback(async () => {
    dispatch(uploadReviewMetaDataOnSendingRequest());
    try {
      const { data: metaData } = await HTTPService.request<LambdaResponse<UploadReviewTrialBalanceMetaData>>({
        method: 'get',
        relativePath: '/v1/upload-meta'
      });

      if (trialBalanceIsEmpty(metaData)) {
        dispatch(uploadReviewMetaDataOnReceivedEmpty({ metaData }));
        return;
      }

      dispatch(uploadReviewMetaDataOnReceived({ metaData }));
    } catch (error: unknown) {
      dispatch(uploadReviewMetaDataOnFailed(error));
    }
  }, [dispatch]);

  useEffect(() => {
    if (!uploadReviewState.isLoading && !uploadReviewState.hasMetaDataLoaded && !uploadReviewState.error) {
      void fetchMetaData();
    }
  }, [
    currentContainer,
    uploadReviewState.isLoading,
    uploadReviewState.hasMetaDataLoaded,
    uploadReviewState.error,
    fetchMetaData
  ]);

  return { metaData: uploadReviewState.metaData ?? {}, error: uploadReviewState.error, fetchMetaData };
};
