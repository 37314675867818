import { createSelector } from '@reduxjs/toolkit';
import { UserRoles } from '@xbs/xbs-enums';

import { RootState } from '../redux/index';

export const selectAuthInfo = ({ auth }: RootState) => auth;

export const selectIsAuthenticated = ({ auth: { authToken, lastRefreshDate, expiration } }: RootState): boolean => {
  /* If the last time they refreshed was not today, kick them back to login */
  return (
    Boolean(authToken) &&
    Boolean(expiration) &&
    expiration!.getTime() > Date.now() &&
    lastRefreshDate === new Date().getDate()
  );
};

export const selectUserRoleUuid = createSelector(selectAuthInfo, (authState) => authState.roleUuid);

export const selectDoesUserHaveRoleUuid = (roleUuid: string) => ({ auth }: RootState) => auth.roleUuid === roleUuid;
export const selectDoesUserHaveRole = (roleNames: string[]) => ({ auth }: RootState) => {
  const { roleUuid } = auth;
  return roleNames.some((role) => roleUuid === UserRoles.ByName[role]?.RoleUuid);
};
