import { useCallback, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  Popover,
  TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Search, Close, AddCircleOutline } from '@material-ui/icons';

import ColorCircle from '../../../ColorCircle';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    '& > .open, &:hover > .plus': {
      opacity: '1',
      transition: 'opacity 0.3s'
    }
  },
  plusButton: {
    color: '#C5C5C9',
    width: '16px',
    height: '16px',
    opacity: '0',
    padding: '14px',
    marginRight: '-7px',
    border: 'none',
    backgroundColor: 'transparent',
    boxSizing: 'border-box',
    '& .MuiSvgIcon-root': {
      width: '20px'
    },
    '&.open': {
      border: `solid 1px ${theme.palette.divider}`,
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    borderRadius: '4px',
    backgroundColor: theme.palette.common.white
  },
  container: {
    width: '372px',
    display: 'flex',
    flexDirection: 'column',
    fontSize: theme.typography.body1.fontSize
  },
  header: {
    paddingLeft: '15px',
    height: '40px'
  },
  textSearch: {
    flex: '1',
    margin: '0 9px 0 6px',
    marginRight: '10px',
    '& .MuiInputBase-input': {
      height: '36px',
      padding: '0px'
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: '0'
    },
    '& .MuiInputAdornment-positionEnd .MuiButtonBase-root': {
      width: '34px',
      height: '34px'
    }
  },
  searchContainer: {
    display: 'flex',
    marginBottom: '10px'
  },
  list: {
    padding: '0px',
    minHeight: '36px',
    maxHeight: '144px',
    overflow: 'scroll'
  },
  element: {
    height: '36px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    paddingRight: '0px',
    '& :first-child': {
      paddingLeft: '12px'
    },
    '& :nth-child(2)': {
      marginLeft: '7px',
      lineHeight: '1',
      paddingBottom: '2px'
    },
    '&.selected': {
      backgroundColor: theme.palette.primary.light,
      borderLeft: `solid 4px ${theme.palette.info.dark}`,
      paddingLeft: '12px'
    }
  },
  checkbox: {
    marginLeft: 'auto',
    '& .MuiSvgIcon-root': {
      color: theme.palette.info.dark
    }
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '20%',
    margin: '6px 9px 12px 0'
  },
  '& .MuiButtonBase-root': {
    borderRadius: '4px',
    width: '85px'
  },
  cancelButton: {
    color: theme.palette.primary.main
  },
  updateButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  loader: {
    padding: '0'
  }
}));

export interface CategoriesPopoverOptions {
  name: string;
  color: string;
  isSelected?: boolean;
}

interface Props {
  [key: string]: any;
  singleSelected?: boolean;
  isLoading?: boolean;
  options: CategoriesPopoverOptions[];
  originalStepName: string;
  onUpdate: (options: CategoriesPopoverOptions[], stepName: string) => Promise<void>;
}

const CategoriesPopover = ({ options, singleSelected, isLoading, onUpdate, originalStepName }: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [categoryOptions, setCategoryOptions] = useState<CategoriesPopoverOptions[]>(options);
  const [visibleOptions, setVisibleOptions] = useState<CategoriesPopoverOptions[]>(options);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setCategoryOptions(options);
  }, [options]);

  useEffect(() => {
    const search = searchValue.trim().toLowerCase();
    const currentOptions = categoryOptions.filter(({ name }) => (search ? name.toLowerCase().includes(search) : true));
    setVisibleOptions(currentOptions);
  }, [searchValue, categoryOptions]);

  useEffect(() => {
    setSearchValue('');
    setCategoryOptions(categoryOptions.map((opt) => ({ ...opt, isSelected: false })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorEl]);

  const selectCategory = (name: string) => {
    const updatedOptions: CategoriesPopoverOptions[] = [];

    for (const opt of categoryOptions) {
      if (opt.name === name) {
        opt.isSelected = !opt.isSelected;
      } else if (singleSelected) {
        opt.isSelected = false;
      }

      updatedOptions.push(opt);
    }

    setCategoryOptions(updatedOptions);
    setSearchValue('');
  };

  const updateOptions = useCallback(async () => {
    let onlySelected: CategoriesPopoverOptions[] = categoryOptions.filter(({ isSelected }) => isSelected);
    onlySelected = onlySelected.map(({ name, color }) => ({ name, color }));
    setAnchorEl(null);
    await onUpdate(onlySelected, originalStepName);
  }, [categoryOptions, onUpdate, originalStepName]);

  const searchHandler = useCallback(({ target: { value: newValue } }) => {
    setSearchValue(newValue);
  }, []);

  const clearSearch = useCallback(() => {
    setSearchValue('');
  }, []);

  const open = Boolean(anchorEl);

  return (
    <Box className={classes.mainContainer}>
      {isLoading ? (
        <CircularProgress size={15} />
      ) : (
        <IconButton
          data-testid="add-button"
          className={`${classes.plusButton}${open ? ' plus open' : ' plus'}`}
          onClick={handleClick}
        >
          <AddCircleOutline />
        </IconButton>
      )}
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        classes={{
          paper: classes.paper
        }}
        onClose={handleClose}
      >
        <Box className={classes.container}>
          <Box className={classes.header}>
            <p>Select Category</p>
          </Box>
          <Box className={classes.searchContainer}>
            <TextField
              className={classes.textSearch}
              value={searchValue}
              InputProps={{
                onChange: searchHandler,
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {searchValue.trim() && (
                      <IconButton onClick={clearSearch}>
                        <Close />
                      </IconButton>
                    )}
                  </InputAdornment>
                )
              }}
              variant="outlined"
            />
          </Box>
          <List className={classes.list}>
            {visibleOptions.map(({ name, color, isSelected = false }) => (
              <ListItem
                key={name}
                className={`${classes.element}${isSelected ? ' selected' : ''}`}
                onClick={() => {
                  selectCategory(name);
                }}
              >
                <ColorCircle color={color} />
                <p>{name}</p>
                <Checkbox className={classes.checkbox} checked={isSelected} />
              </ListItem>
            ))}
          </List>
          <Box className={classes.footer}>
            <Button data-testid="cancel-button" className={classes.cancelButton} variant="text" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              data-testid="update-button"
              disabled={!categoryOptions.some(({ isSelected }) => isSelected)}
              className={classes.updateButton}
              variant="contained"
              onClick={updateOptions}
            >
              Update
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default CategoriesPopover;
