import { useState } from 'react';

import { makeStyles, Box } from '@material-ui/core';
import classnames from 'classnames';

import { ReactComponent as CategoriesToggleButton } from '../../../../../../assets/img/Icon_Table.svg';
import ToggleGroupedViewModal from '../ToggleGroupedViewModal';

const useStyles = makeStyles((theme) => ({
  categoriesToggleButton: {
    cursor: 'pointer',
    marginRight: theme.spacing(1),
    marginTop: '3px',
    position: 'relative'
  },
  activeIcon: {
    '& g > path': {
      fill: theme.palette.info.dark
    }
  },
  tabTitleCategoriesToggleRoot: {
    position: 'relative',
    '& .makeStyles-toggleGrouped': {
      position: 'absolute !important',
      right: theme.spacing(1),
      top: theme.spacing(1),
      zIndex: 100
    }
  },
  dot: {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.common.white}`,
    backgroundColor: theme.palette.info.dark,
    height: '10px',
    left: '42%',
    position: 'absolute',
    top: '2px',
    width: '10px',
    zIndex: 200
  }
}));

const TabTitleCategoriesToggle = ({ handleToggle, isToggleOn }: { handleToggle: () => void; isToggleOn: boolean }) => {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const toggleDialogOpen = () => {
    setIsDialogOpen((isOpen) => !isOpen);
  };

  return (
    <Box className={classes.tabTitleCategoriesToggleRoot}>
      <CategoriesToggleButton
        className={classnames(`js-categories-toggle-button ${classes.categoriesToggleButton}`, {
          [classes.activeIcon]: isToggleOn
        })}
        onClick={toggleDialogOpen}
      />
      {isToggleOn && <Box className={classes.dot} />}
      <ToggleGroupedViewModal
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
        isToggled={isToggleOn}
        onToggle={handleToggle}
      />
    </Box>
  );
};

export default TabTitleCategoriesToggle;
