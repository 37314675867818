/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { forwardRef } from 'react';

import { Components } from 'react-virtuoso';

import { Button } from '@material-ui/core';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Row } from '../Table/Table.proptype';
import { ActionsCell } from '../TableWithComment/components';
import { TableWithCommentProps } from '../TableWithComment/TableWithComment.proptype';

interface VirtuosoTableComponentsProps {
  Scroller: Components['Scroller'];
  Table: any;
  TableHead: any;
  TableBody: any;
  TableRow: any;
}

export const VirtuosoTableComponents: VirtuosoTableComponentsProps = {
  Scroller: forwardRef((props: any, innerRef: React.ForwardedRef<HTMLDivElement>) => (
    <TableContainer
      {...props}
      ref={innerRef}
      className={`${props.context.classes.container} ${props.context.classes.tableWithComment}`}
    />
  )),
  Table: forwardRef((props: any, innerRef: React.ForwardedRef<HTMLDivElement>) => {
    return (
      <MuiTable
        stickyHeader
        {...props}
        ref={innerRef}
        className={`${props.context.classes.table} ${props.context.classes.flat}`}
      />
    );
  }),
  TableHead: forwardRef((props: any, innerRef: React.ForwardedRef<HTMLDivElement>) => (
    <TableHead {...props} ref={innerRef} />
  )),
  TableRow: forwardRef((props: any, innerRef: React.ForwardedRef<HTMLDivElement>) => {
    const classNames = [];

    if (props.item.isCategoryTotalRow) {
      classNames.push('category-total');
    }

    if (props.item.isTotal) {
      classNames.push('total');
    }

    if (props.item.isSpace) {
      classNames.push('space');
    }

    if (props.item.isNew) {
      classNames.push('new-row');
    }

    return (
      <TableRow
        {...props}
        ref={innerRef}
        hover={!props.item.sectionHeader && !props.item.isTotal}
        className={`${classNames.join(' ')}`}
      />
    );
  }),
  TableBody: forwardRef((props: any, innerRef: React.ForwardedRef<HTMLDivElement>) => (
    <TableBody {...props} ref={innerRef} className={`${props.context.classes.table} ${props.context.classes.flat}`} />
  ))
};

interface NotesColumnProps {
  classes: Record<string, string>;
  displayCategoryActions: boolean;
  displayGroupActions: boolean;
  hideActionsMenu: boolean;
  isUserReadOnly: boolean;
  hasNewRow: boolean;
  setExpandedRow: any;
  t: any;
  stepLevel: string;
  handleOnRowDelete?: TableWithCommentProps['handleOnRowDelete'];
  onExpand?: TableWithCommentProps['onExpand'];
  toggleEditCategoryDrawer?: TableWithCommentProps['toggleEditCategoryDrawer'];
  toggleCategoryDrawer?: TableWithCommentProps['toggleCategoryDrawer'];
}

export const notesColumn = (props: NotesColumnProps) => {
  const {
    classes,
    handleOnRowDelete,
    onExpand,
    setExpandedRow,
    toggleEditCategoryDrawer,
    toggleCategoryDrawer,
    t,
    displayCategoryActions,
    displayGroupActions,
    hideActionsMenu,
    isUserReadOnly,
    hasNewRow,
    stepLevel
  } = props;

  const widthConfig = stepLevel === 'federal.rtp' ? '10%' : 100;
  return {
    renderHeader: () =>
      displayCategoryActions ? (
        <Button className={classes.categoryButton} variant="outlined" onClick={toggleCategoryDrawer}>
          + {t('Add Category')}
        </Button>
      ) : null,
    renderCell: (row: Row) => (
      <ActionsCell
        displayGroupActions={displayGroupActions}
        displayCategoryActions={displayCategoryActions}
        hideMoreButton={hideActionsMenu || isUserReadOnly}
        row={row}
        hasNewRow={hasNewRow}
        handleOnRowDelete={
          handleOnRowDelete &&
          (() => {
            handleOnRowDelete({ row });
          })
        }
        handleOnCommentsClick={() => {
          if (onExpand) {
            onExpand(row);
          } else {
            setExpandedRow(row);
          }
        }}
        toggleEditCategoryDrawer={toggleEditCategoryDrawer}
      />
    ),
    className: 'right',
    width: widthConfig,
    isActions: true,
    field: 'noteFieldForRenderCell'
  };
};
