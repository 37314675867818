import { Currency } from '../../models';

export interface CurrenciesStateInterface {
  currencies: Currency[];
  hasLoaded: boolean;
  isLoading: boolean;
  error: unknown;
}

export const currenciesInitialState: CurrenciesStateInterface = {
  currencies: [],
  hasLoaded: false,
  isLoading: false,
  error: null
};
