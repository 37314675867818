import BasisGrotesqueBoldPro from './BasisGrotesquePro-Bold.woff';
import BasisGrotesqueMediumPro from './BasisGrotesquePro-Medium.woff';
import BasisGrotesquePro from './BasisGrotesquePro-Regular.woff';
import SegoeUIBold from './Segoe-UI-Bold.ttf';
import SegoeUI from './Segoe-UI.ttf';

export const headingRegular = {
  fontFamily: 'Basis Grotesque Pro',
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: `
    local('Basis Grotesque Pro'),
    url(${BasisGrotesquePro as string})
    format('woff')
  `
};

export const headingBold = {
  fontFamily: 'Basis Grotesque Pro Bold',
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: `
    local('Basis Grotesque Pro Bold'),
    url(${BasisGrotesqueBoldPro as string})
    format('woff')
  `
};

export const medium = {
  fontFamily: 'Basis Grotesque Pro Medium',
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: `
  local('Basis Grotesque Pro Bold'),
  url(${BasisGrotesqueMediumPro as string})
  format('woff')
`
};

export const regular = {
  fontFamily: 'Segoe UI',
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: `
    local('Segoe UI'),
    url(${SegoeUI as string})
    format('ttf')
  `
};

export const bold = {
  fontFamily: 'Segoe UI Bold',
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: `
    local('Segoe UI Bold'),
    url(${SegoeUIBold as string})
    format('ttf')
  `
};
