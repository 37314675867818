import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { BalanceSheet, ContentAndTitle, IncomeStatement, TabTitle } from '.';

import { ToggleButtons } from '../..';
import { FEDERAL_UUID } from '../../../constants';
import { useCompletionStatus, useCurrencies } from '../../../hooks';
import { setEntityCompletionStatus } from '../../../redux/entitiesCompletionStatus';
import { setIsTemporaryBalanceSheetTabOpen } from '../../../redux/ui';
import { selectEntitiesMapByNumber, selectIsTemporaryBalanceSheetTabOpen } from '../../../selectors';
import { EntityNumberRouteMatch } from '../utils';

const TemporaryAdjustments = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    params: { entityNumber }
  } = useRouteMatch<EntityNumberRouteMatch>();
  const entityByNumberMap = useSelector(selectEntitiesMapByNumber);
  const { entityId } = entityByNumberMap[entityNumber];
  const { currencyByEntityIdMap } = useCurrencies();
  const currencyIsoCode = currencyByEntityIdMap[entityId]?.isoCode;
  const { stepCompletionStatus } = useCompletionStatus(entityNumber, 'federal.temporary', FEDERAL_UUID);
  const isBalance = useSelector(selectIsTemporaryBalanceSheetTabOpen);

  return (
    <ContentAndTitle
      title={
        <TabTitle
          currencyIsoCode={currencyIsoCode}
          title={t('Temporary Adjustments')}
          isCompleted={stepCompletionStatus.status}
          onCompletionChange={(checked) => {
            dispatch(
              setEntityCompletionStatus({
                ...stepCompletionStatus,
                newStatus: checked
              })
            );
          }}
        />
      }
    >
      <>
        <ToggleButtons
          buttons={[
            {
              label: t('Income Statement'),
              isActive: !isBalance,
              onClick: () => {
                dispatch(setIsTemporaryBalanceSheetTabOpen(false));
              }
            },
            {
              label: t('Balance Sheet'),
              isActive: isBalance,
              onClick: () => {
                dispatch(setIsTemporaryBalanceSheetTabOpen(true));
              }
            }
          ]}
        />
        {isBalance ? <BalanceSheet /> : <IncomeStatement />}
      </>
    </ContentAndTitle>
  );
};

export default TemporaryAdjustments;
