import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Skeleton from '@material-ui/lab/Skeleton';
import { UserRoles } from '@xbs/xbs-enums';
import classnames from 'classnames';
import { TFunction } from 'i18next';

import { stateSteps, LEVELS, discreteSteps, getFederalSteps } from '../../../../constants';
import { useContainers, useCompletionStatus } from '../../../../hooks';
import { Entity, Level, Step } from '../../../../models';
import { selectAuthInfo, selectUserRoleUuid } from '../../../../selectors/authentication';
import { disableStepInProgressStatusForRoles } from '../../../UserRoleStylesProvider/constants';
import { findStepsWithStatus } from '../../utils';

const useStyles = makeStyles((theme) => ({
  entityDetailsHeaderRoot: {
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    display: 'flex'
  },
  title: {
    padding: theme.spacing(2, 2, 2, 1),
    display: 'inline-block'
  },
  navButton: {
    backgroundColor: theme.palette.secondary.dark,
    boxSizing: 'border-box',
    borderRadius: '4px',
    color: theme.palette.text.primary,
    fontFamily: theme.typography.h3.fontFamily,
    height: theme.spacing(3),
    margin: 0,
    marginTop: 'auto',
    marginBottom: 'auto',
    maxWidth: '92px',
    minWidth: '92px',
    padding: theme.spacing(1, 1.5),
    width: '92px'
  },
  'navButton--selected': {
    '&:hover': {
      backgroundColor: '#464A590A',
      color: theme.palette.text.primary
    },
    '&:not(:hover)': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white
    }
  },
  tabs: {
    backgroundColor: theme.palette.common.white,
    '& .MuiTab-root.Mui-selected': {
      backgroundColor: theme.palette.common.white,
      borderBottom: `1px solid ${theme.palette.primary.main}`
    }
  }
}));

interface EntityDetailsHeaderProps {
  jurisdictionType: Level;
  entity: Entity;
  tab?: Step;
}

const defineNavButtons = (t: TFunction, entity: Entity, isInterim: boolean) => {
  const navButtons = [{ path: '/federal', text: t('Federal') }];

  if (entity.subJurisdictionIds.length > 0) {
    navButtons.push({ path: '/state', text: t('State') });
  }

  if (isInterim) {
    navButtons.push({ path: '/discrete', text: t('Discrete') });
  }

  return navButtons;
};

const EntityDetailsHeader = ({ jurisdictionType, entity, tab }: EntityDetailsHeaderProps) => {
  const { t } = useTranslation();
  const { entityCompletionStatus } = useCompletionStatus(entity.entityNumber);
  const classes = useStyles();
  const authInfo = useSelector(selectAuthInfo);
  const federalSteps = getFederalSteps();
  const { currentContainer } = useContainers();
  const isInterim = currentContainer?.isInterimReportingPeriod;
  const navButtons = defineNavButtons(t, entity, Boolean(isInterim));
  const showingNavButtonsIsRedundant = navButtons.length === 1;

  const completedFederalSteps = findStepsWithStatus(entityCompletionStatus?.completionStatus ?? {}, true, 1);
  const completedStateSteps = findStepsWithStatus(
    entityCompletionStatus?.completionStatus ?? {},
    true,
    entityCompletionStatus.numOfStates
  );
  const completedDiscreteSteps = findStepsWithStatus(entityCompletionStatus?.completionStatus ?? {}, true, 1);
  const inProgressFederalSteps = findStepsWithStatus(entityCompletionStatus?.completionStatus ?? {}, false, 1);
  const inProgressStateSteps = findStepsWithStatus(
    entityCompletionStatus?.completionStatus ?? {},
    false,
    entityCompletionStatus.numOfStates
  );
  const inProgressDiscreteSteps = findStepsWithStatus(entityCompletionStatus?.completionStatus?.discrete ?? {}, false);

  let steps;
  let completedSteps = completedFederalSteps;
  let inProgressSteps = inProgressFederalSteps;
  if (jurisdictionType === LEVELS.FEDERAL) {
    steps = federalSteps;
  } else if (jurisdictionType === LEVELS.STATE) {
    steps = stateSteps;
    completedSteps = completedStateSteps;
    inProgressSteps = inProgressStateSteps;
  } else {
    steps = discreteSteps;
    completedSteps = completedDiscreteSteps;
    inProgressSteps = inProgressDiscreteSteps;
  }

  const stepsWithStatuses = steps.map((step: any) => ({
    status: completedSteps.includes(step as Step)
      ? 'completed'
      : inProgressSteps.includes(step as Step)
      ? 'in-progress'
      : '',
    id: step
  }));

  const roleUuid = useSelector(selectUserRoleUuid);
  const isUserReviewer = roleUuid === UserRoles.ByName.ProReviewer.RoleUuid;

  const filteredStepsWithStatus = stepsWithStatuses.filter((step) =>
    isUserReviewer ? step.status === 'completed' : true
  );

  const navButtonsToRender = navButtons.map(({ text, path }) => {
    let stepsToCheck = completedFederalSteps;
    if (text === t('level-state')) {
      stepsToCheck = completedStateSteps;
    } else if (text === t('discrete')) {
      stepsToCheck = completedDiscreteSteps;
    }

    // isDisabled will disable the level button if there no completed steps for that level
    const isDisabled = stepsToCheck?.length === 0 ? disableStepInProgressStatusForRoles.join(' ') : '';
    // IncludedStep will redirect route to the first completed step of selected level
    const includedStep = stepsToCheck?.length > 0 ? `${path}/${stepsToCheck?.[0]}` : `${path}`;
    const pathStep = disableStepInProgressStatusForRoles.includes(authInfo?.roleUuid)
      ? `/entity-details/${entity?.entityNumber}${includedStep}`
      : `/entity-details/${entity?.entityNumber}${path}`;

    return (
      <Button
        key={path}
        disableElevation
        className={classnames(classes.navButton, {
          [classes['navButton--selected']]: path.includes(jurisdictionType)
        })}
        component={Link}
        to={pathStep}
        data-roles-disable-element={isDisabled}
      >
        {text}
      </Button>
    );
  });

  return (
    <>
      <Box className={classes.entityDetailsHeaderRoot} pl={1}>
        <IconButton component={Link} to="/entity-details">
          <ArrowBack />
        </IconButton>
        <Typography variant="h2" component="h1" className={classes.title}>
          {entity ? entity.name : <Skeleton width={150} />}
        </Typography>
        {!showingNavButtonsIsRedundant && navButtonsToRender}
      </Box>
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        value={tab ?? steps[0]}
        className={classes.tabs}
      >
        {filteredStepsWithStatus.map((step) => {
          if (step.id === 'adjustment') {
            return;
          }

          return (
            <Tab
              key={step.id}
              label={t(step.id)}
              value={step.id}
              component={Link}
              to={`/entity-details/${entity?.entityNumber}/${jurisdictionType}/${step.id as string}`}
            />
          );
        })}
      </Tabs>
    </>
  );
};

export default EntityDetailsHeader;
