import { Box, Chip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    height: '100%',
    gap: theme.spacing(1.5),

    '& > *': {
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(3),
      borderRadius: theme.shape.borderRadius
    },
    '& aside': {
      width: '30%',
      display: 'flex',
      flexDirection: 'column'
    },
    '& main': {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
    }
  },
  subtitle: {
    padding: theme.spacing(2, 0)
  },
  mainTitle: {
    marginBottom: theme.spacing(4)
  },
  count: {
    borderRadius: theme.shape.borderRadius,
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.info.main,
    color: theme.palette.primary.main
  }
}));

interface Props {
  asideTitle: string;
  description: string;
  asideContent: JSX.Element;
  mainTitle: string;
  dataCountLabel: string;
  mainContent: JSX.Element;
}

const Layout = ({ asideTitle, description, asideContent, mainTitle, dataCountLabel, mainContent }: Props) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box component="aside">
        <Typography variant="h2">{asideTitle}</Typography>
        <Typography variant="subtitle1" className={classes.subtitle}>
          {description}
        </Typography>
        {asideContent}
      </Box>
      <Box component="main">
        <Typography variant="h2" component="h3" className={classes.mainTitle}>
          {mainTitle}
          <Chip className={classes.count} label={dataCountLabel} />
        </Typography>
        {mainContent}
      </Box>
    </Box>
  );
};

export default Layout;
