import { Level, Step } from '../../models';

export interface DataSelectionType {
  level: Level;
  step: Step;
  name: string;
}

export const DATA_TYPES: DataSelectionType[] = [
  { level: 'federal', step: 'permanent', name: 'Federal - Permanent Adjustment' },
  { level: 'federal', step: 'temporary', name: 'Federal - Temporary Adjustment' },
  { level: 'federal', step: 'tax-effected', name: 'Federal - Tax-Effected' },
  { level: 'state', step: 'modifications.permanent', name: 'State - Modifications - Permanent' },
  { level: 'state', step: 'modifications.temporary', name: 'State - Modifications - Temporary' },
  { level: 'state', step: 'tax-effected', name: 'State - Tax-Effected' }
];
