import decodeJWT from 'jwt-decode';

export function decodeToken(accessToken: string) {
  try {
    const jwt = decodeJWT(accessToken);

    return {
      email: (jwt as any).email,
      userId: (jwt as any).userId,
      role: (jwt as any).role
    };
  } catch {
    return {
      email: '',
      userId: 0,
      role: ''
    };
  }
}
