import { useSelector } from 'react-redux';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { selectAuthInfo } from '../../selectors';

type FeatureFlagForTesting = 'test';

export type FeatureFlag =
  | FeatureFlagForTesting
  | 'alwaysShowSummaryTab'
  | 'alwaysShowSummaryTabUserAccess'
  | 'flagTestLaunchDarklyWeb'
  | 'flagRemoveStateRtpData'
  | 'flagRemoveStateRtpDataUserAccess';

export const useFeatureFlags = () => {
  const flagsFromProvider = useFlags();
  const authInfo = useSelector(selectAuthInfo);

  const checkConventionalFlag = (flag: FeatureFlag) => {
    return Boolean(flagsFromProvider[flag]);
  };

  const checkUserIdsFlag = (flag: FeatureFlag) => {
    return Boolean(flagsFromProvider[flag]?.userIds?.includes(authInfo.userId));
  };

  return {
    checkConventionalFlag,
    checkUserIdsFlag
  };
};
