import { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { TableWithComment } from '../..';
import { calculateBalanceSheet } from '../../../calculations';
import {
  FEDERAL_UUID,
  FED_TEMP_BALANCE_SHEET_LEVEL,
  FED_TEMP_BALANCE_SHEET_LEVEL_AND_STEP,
  FED_TEMP_BALANCE_SHEET_STEP,
  FED_TEMP_INCOME_STATEMENT_STEP,
  LEVELS
} from '../../../constants';
import { useCompletionStatus, useEntity, useFinancialData } from '../../../hooks';
import { Step } from '../../../models';
import LoadingWrapper from '../../LoadingWrapper';
import { Row } from '../../Table/Table.proptype';
import {
  BalanceSheetAccountShape,
  EntityNumberRouteMatch,
  getRowNamesFromLevelSteps,
  getTableDataFromFinancialData,
  getTemporaryBalanceSheetColumns,
  handleEditRowForEntityDetails,
  handleNewRowForEntityDetails,
  handleOnCellOrCommentBlurForEntityDetails,
  handleOnRowDeleteForEntityDetails,
  mergeTemporaryBalanceSheetRowData
} from '../utils';

export type BalanceSheetAccountRows = Row & BalanceSheetAccountShape;

const useStyles = makeStyles(() => ({
  table: {
    '& .MuiTableRow-head:nth-child(2) > th:nth-child(1)': {
      paddingLeft: '0 !important'
    },
    '& .MuiTableCell-body': {
      minWidth: '127px' // This is to accommodate 10-digit numbers in the UI (PROV-1825)
    },
    '& .MuiOutlinedInput-inputAdornedEnd': {
      width: 'inherit'
    },
    '& .MuiTableRow-hover > .MuiTableCell-body:nth-child(n+3):nth-last-child(n+2)': {
      paddingLeft: '5px',
      paddingRight: '5px'
    },
    '& .MuiTableRow-hover > .MuiTableCell-body > div:first-child': {
      minWidth: '127px',
      paddingLeft: '5px',
      paddingRight: '5px'
    }
  }
}));

const STEPS: Step[] = [FED_TEMP_BALANCE_SHEET_STEP, FED_TEMP_INCOME_STATEMENT_STEP, 'deferred', 'rtp'];

const BalanceSheet = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    params: { entityNumber }
  } = useRouteMatch<EntityNumberRouteMatch>();
  const { entity } = useEntity(entityNumber);
  const {
    prov3322EditPerformanceFix: isPerformanceFixEnabled,
    prov3736ReservedWordValidation: showReservedWordsError
  } = useFlags();
  const entityId = entity?.entityId;

  const [hasNewRow, setHasNewRow] = useState(false);
  const [rows, setRows] = useState<BalanceSheetAccountRows[]>([]);
  const { stepCompletionStatus } = useCompletionStatus(
    entityNumber,
    `${FED_TEMP_BALANCE_SHEET_LEVEL}.${FED_TEMP_BALANCE_SHEET_STEP}`,
    FEDERAL_UUID
  );

  const { tabsData, failedCells, isFetchLoading } = useFinancialData(entityNumber, FED_TEMP_BALANCE_SHEET_LEVEL, STEPS);

  const balanceSheetFinancialInfo = useMemo(() => tabsData[FED_TEMP_BALANCE_SHEET_LEVEL_AND_STEP] ?? [], [tabsData]);
  const rtpFinancialInfo = useMemo(() => tabsData['federal.rtp'] ?? [], [tabsData]);
  const deferredFinancialInfo = useMemo(() => tabsData['federal.deferred'] ?? [], [tabsData]);
  const incomeStatementFinancialInfo = useMemo(() => tabsData['federal.temporary.incomeStatement'] ?? [], [tabsData]);

  const mergedFinancialInfo = useMemo(
    () => [
      ...balanceSheetFinancialInfo,
      ...rtpFinancialInfo,
      ...deferredFinancialInfo,
      ...incomeStatementFinancialInfo
    ],
    [balanceSheetFinancialInfo, rtpFinancialInfo, deferredFinancialInfo, incomeStatementFinancialInfo]
  );

  const balanceSheetRowNames = useMemo(
    () =>
      getRowNamesFromLevelSteps(tabsData, FED_TEMP_BALANCE_SHEET_LEVEL, [FED_TEMP_BALANCE_SHEET_STEP])[
        FED_TEMP_BALANCE_SHEET_STEP
      ],
    [tabsData]
  );
  const rtpRowNames = useMemo(() => getRowNamesFromLevelSteps(tabsData, LEVELS.FEDERAL, ['rtp']).rtp, [tabsData]);

  const balanceSheetRowsWithData = useMemo(
    () => getTableDataFromFinancialData(balanceSheetRowNames, balanceSheetFinancialInfo),
    [balanceSheetFinancialInfo, balanceSheetRowNames]
  ) as BalanceSheetAccountRows[];
  const rtpRowsWithData = useMemo(() => getTableDataFromFinancialData(rtpRowNames, rtpFinancialInfo), [
    rtpFinancialInfo,
    rtpRowNames
  ]) as BalanceSheetAccountRows[];

  const mergedRowsWithData = useMemo(
    () =>
      mergeTemporaryBalanceSheetRowData({
        balanceSheetRows: balanceSheetRowsWithData,
        rtpRows: rtpRowsWithData
      }),
    [balanceSheetRowsWithData, rtpRowsWithData]
  );

  useEffect(() => {
    setRows(mergedRowsWithData.map((row) => calculateBalanceSheet(row)));
  }, [mergedRowsWithData]);

  const columns = [
    {
      field: 'accountNumber',
      filterable: true,
      sortable: true,
      placeholder: t('TB #')
    },
    {
      field: 'name',
      divider: true,
      filterable: true,
      sortable: true,
      placeholder: t('Adjustment'),
      isNewRowEditable: true
    },
    ...getTemporaryBalanceSheetColumns(t, stepCompletionStatus.status)
  ];

  const dataForMethods = {
    calculateFunc: calculateBalanceSheet,
    columns,
    dispatch,
    entityId,
    financialInfo: mergedFinancialInfo,
    hasNewRow,
    level: FED_TEMP_BALANCE_SHEET_LEVEL,
    rows,
    setHasNewRow,
    setRows,
    step: FED_TEMP_BALANCE_SHEET_STEP as Step,
    t,
    showReservedWordsError
  };

  return (
    <LoadingWrapper isLoading={isFetchLoading}>
      <TableWithComment
        className={classes.table}
        columns={columns}
        failedCells={failedCells}
        rows={rows}
        totalHeaderName={t('Total')}
        newRowButtonLabel={
          stepCompletionStatus.status ? null : t(hasNewRow ? 'Save Adjustment' : 'Add Temporary Adjustment')
        }
        hideActionsMenu={stepCompletionStatus.status}
        handleOnRowDelete={(params) => {
          handleOnRowDeleteForEntityDetails({
            ...dataForMethods,
            ...params
          });
        }}
        onCellChange={(params) => {
          if (!isPerformanceFixEnabled) {
            handleEditRowForEntityDetails({
              ...dataForMethods,
              ...params
            });
          }
        }}
        onCellOrCommentBlur={(params) => {
          if (isPerformanceFixEnabled) {
            handleEditRowForEntityDetails({
              ...dataForMethods,
              ...params
            });
          }

          handleOnCellOrCommentBlurForEntityDetails({
            ...dataForMethods,
            ...params
          });
        }}
        onNewRowClick={() => {
          handleNewRowForEntityDetails({
            ...dataForMethods
          });
        }}
      />
    </LoadingWrapper>
  );
};

export default BalanceSheet;
