import { createAction, Dispatch } from '@reduxjs/toolkit';

import PlatformService from '../../services/platform';
export const eulaOnSendingRequest = createAction('eula/onSendingRequest');
export const eulaOnReceived = createAction<boolean>('eula/onReceived');
export const eulaOnFailed = createAction<unknown>('eula/onFailed');

export const acceptEula = () => {
  return async (dispatch: Dispatch) => {
    dispatch(eulaOnSendingRequest());
    try {
      await PlatformService.updateEula(1);
      dispatch(eulaOnReceived(true));
    } catch (error: unknown) {
      dispatch(eulaOnFailed(error));
    }
  };
};
