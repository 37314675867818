import { useTranslation } from 'react-i18next';

import { Box } from '@material-ui/core';

import { ToggleButtons } from '../..';
import { federalTab, foreignTab, stateTab, Tab, totalTab } from '../utils';

interface Props {
  tab: Tab;
  worldLabel: string;
  onChange: (tabName: Props['tab']) => void;
}

const TabButtons = ({ tab, worldLabel, onChange }: any) => {
  const { t } = useTranslation();
  return (
    <Box mb={1}>
      <ToggleButtons
        variant="contained"
        buttons={[
          {
            label: worldLabel,
            isActive: tab === totalTab,
            onClick: () => onChange(totalTab)
          },
          {
            label: t('Federal'),
            isActive: tab === federalTab,
            onClick: () => onChange(federalTab)
          },
          {
            label: t('Foreign'),
            isActive: tab === foreignTab,
            onClick: () => onChange(foreignTab)
          },
          {
            label: t('State / Cities'),
            isActive: tab === stateTab,
            onClick: () => onChange(stateTab)
          }
        ]}
      />
    </Box>
  );
};

export default TabButtons;
