import { useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Entity, EntityFromApi } from '../../models';
import { entitiesOnFailed, entitiesOnReceived, entitiesOnSendingRequest } from '../../redux/entities';
import { updateModelsStatus } from '../../redux/reports';
import {
  selectEntities,
  selectEntitiesMapById,
  selectEntitiesMapByNumber,
  selectEntitiesWithRatesByEntityNumber,
  selectParentEntity
} from '../../selectors/entities';
import HTTPService, { LambdaResponse } from '../../services/http';

export function useEntities() {
  const dispatch = useDispatch();
  const entitiesState = useSelector(selectEntities);
  const isLoading = useRef(false);
  const entityByNumber = useSelector(selectEntitiesMapByNumber);
  const entityById = useSelector(selectEntitiesMapById);
  const parentEntity = useSelector(selectParentEntity);
  const entitiesWithRatesByEntityNumber = useSelector(selectEntitiesWithRatesByEntityNumber);
  const { entityNumber } = useParams<{ entityNumber: string }>();
  const currentEntity: Entity | undefined = entityNumber ? entitiesWithRatesByEntityNumber[entityNumber] : undefined;

  useEffect(() => {
    async function fetchData() {
      isLoading.current = true;
      dispatch(entitiesOnSendingRequest());
      try {
        const { data: entities } = await HTTPService.request<LambdaResponse<EntityFromApi[]>>({
          method: 'get',
          relativePath: '/v1/entities'
        });
        dispatch(entitiesOnReceived(entities));

        // Set entities initial report model status
        const modelsStatus = entities.map((entity) => {
          return (
            entity.modelStatuses?.map((meta) => {
              return {
                reportSourceId: entity.entityId,
                isCurrencyConversion: meta.isCurrencyConversion,
                status: meta.status
              };
            }) ?? []
          );
        });

        const modelsStatusPayload = {
          reportType: 'single-entity',
          statuses: modelsStatus.flat()
        };

        dispatch({ type: updateModelsStatus, payload: modelsStatusPayload });
      } catch (error: unknown) {
        dispatch(entitiesOnFailed(error));
      } finally {
        isLoading.current = false;
      }
    }

    if (!entitiesState.isLoaded && !isLoading.current && !entitiesState.error) {
      void fetchData();
    }
  }, [dispatch, entitiesState]);

  return {
    ...entitiesState,
    currentEntity,
    entityByNumber,
    entityById,
    parentEntity,
    entitiesWithRatesByEntityNumber
  };
}
