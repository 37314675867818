import { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { Box, makeStyles } from '@material-ui/core';

import { PeriodSelector, DifferencePeriodHeader, createTableRows, cannotRender } from './';

import TaxRateChip from './TaxRateChip/TaxRateChip';

import { Table } from '../..';
import { Container, Row, TrendAnalysis, TrendAnalysisValue } from '../../../models';
import { abbreviateNumber } from '../../../utils';

const gapColumn = { className: 'gap' };

const useStyles = makeStyles((theme) => {
  const spacing = theme.spacing(2);

  return {
    headerContainer: {
      display: 'flex',
      flexDirection: 'row'
    },
    periodSelector: {
      backgroundColor: theme.palette.primary.contrastText
    },
    table: {
      overflowX: 'hidden',
      '& .MuiTable-root': {
        '& .MuiTableCell-body:not(:last-child)': {
          borderRight: 'none'
        },
        '& .MuiTableCell-body': {
          borderColor: theme.palette.divider,
          borderTop: '1px solid',
          borderBottom: 'none',
          paddingTop: '4px',
          paddingBottom: '5px'
        },
        '& .MuiTableRow-hover:hover': {
          '& .MuiTableCell-root.MuiTableCell-body.empty-row, & .MuiTableCell-root.MuiTableCell-body.gap': {
            backgroundColor: theme.palette.common.white
          }
        },
        '& .MuiTableCell-root.gap': {
          '&.MuiTableCell-body, &.MuiTableCell-head': {
            backgroundColor: theme.palette.common.white,
            maxWidth: spacing,
            paddingLeft: '10px',
            paddingRight: '10px'
          }
        },
        '& .MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader:first-child': {
          backgroundColor: theme.palette.common.white,
          paddingTop: '0px',
          paddingRight: '0px',
          paddingLeft: '0px',
          paddingBottom: '8px',
          '& .MuiBox-root': {
            backgroundColor: theme.palette.secondary.dark,
            borderRadius: '4px',
            display: 'inline-flex',
            padding: '8.45px 302px 7.55px 16px',
            width: '100%'
          },
          '& .MuiTypography-h3': {
            padding: '4px 22px 8px 0px',
            alignSelf: 'center',
            color: theme.palette.text.primary,
            letterSpacing: 0.22,
            lineHeight: '24px'
          },
          '& .MuiOutlinedInput-root': {
            width: '208px',
            height: '36px'
          }
        },
        '& .MuiTableCell-root.comparisonPeriodSection': {
          '&.MuiTableCell-body, &.MuiTableCell-head': {
            backgroundColor: (theme.palette.background as any).comparison,
            paddingTop: '11px',
            paddingBottom: '8px',
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.primary.main,
            marginTop: '10px',
            lineHeight: '18px'
          },
          '&.MuiTableCell-head': {
            paddingTop: '0px',
            paddingBottom: '10px',
            width: '98px',
            '& .MuiBox-root': {
              backgroundColor: theme.palette.info.dark,
              borderRadius: '4px',
              display: 'inline-flex',
              padding: '8.45px 9px 7.55px 21px',
              width: '100%'
            },
            '& .MuiTypography-h3': {
              padding: '4px 14px 8px 0px',
              alignSelf: 'center',
              color: theme.palette.common.white,
              width: '167px',
              letterSpacing: 0.22,
              lineHeight: '24px'
            },
            '& .MuiOutlinedInput-root': {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
              width: '196px',
              height: '36px',
              marginLeft: '10px'
            }
          }
        },
        '& .MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader:nth-child(5)': {
          backgroundColor: theme.palette.common.white,
          paddingTop: '0px',
          paddingLeft: '0px',
          paddingRight: '0px !important',
          '& .MuiBox-root': {
            backgroundColor: theme.palette.secondary.dark,
            borderRadius: '4px',
            display: 'inline-flex',
            padding: '8.47px 302px 7.57px 21px',
            width: '100%'
          },
          '& .MuiTypography-h3': {
            padding: '4px 22px 8px 0px',
            alignSelf: 'center',
            letterSpacing: 0.22,
            lineHeight: '24px'
          }
        },
        '& .MuiTableRow-root.MuiTableRow-head': {
          fontFamily: theme.typography.fontFamily
        },
        '& .MuiTableCell-root.rowHeader': {
          '&.MuiTableCell-body': {
            lineHeight: '18px',
            width: '322px',
            paddingRight: '0px'
          }
        },
        '& .MuiTableCell-root.baselinePeriodSection': {
          '&.MuiTableCell-body, &.MuiTableCell-head': {
            fontFamily: theme.typography.fontFamily,
            lineHeight: '18px'
          },
          '&.MuiTableCell-head:not(:nth-child(1))': {
            paddingBottom: '10px',
            paddingLeft: '0px',
            paddingRight: '32px',
            width: '132px'
          },
          '&.MuiTableCell-head:nth-child(4)': {
            paddingBottom: '10px',
            paddingLeft: '0.54px',
            paddingRight: '17.29px',
            width: '85.25px'
          },
          '&.MuiTableCell-head': {
            backgroundColor: theme.palette.common.white
          },
          '&.MuiTableCell-body': {
            paddingTop: '11px',
            paddingBottom: '8px'
          },
          '&.MuiTableCell-body:nth-child(2)': {
            paddingRight: '23px',
            paddingLeft: '10px'
          },
          '&.MuiTableCell-body:nth-child(3)': {
            paddingLeft: '0px',
            paddingRight: '22px'
          }
        },
        '& .MuiTableCell-root.differencesSection': {
          '&.MuiTableCell-head': {
            backgroundColor: theme.palette.common.white,
            fontFamily: theme.typography.fontFamily,
            paddingBottom: '10px',
            lineHeight: '18px'
          },

          '&.MuiTableCell-body': {
            fontFamily: theme.typography.h3.fontFamily,
            fontSize: theme.typography.h3.fontSize,
            paddingRight: '0px',
            lineHeight: '22px'
          }
        },
        flexGrow: 1
      }
    },
    title: {
      color: theme.palette.text.secondary,
      paddingBottom: theme.spacing(1)
    },
    trendTableContainer: {
      padding: spacing,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      overflow: 'hidden',
      zIndex: 0,
      paddingTop: '0px',
      width: 'fit-content'
    },
    taxRateHeader: {
      backgroundColor: theme.palette.common.white,
      textAlign: 'right',
      fontFamily: theme.typography.fontFamily,
      paddingRight: '0px !important',
      width: '114.4px',
      '&.MuiTableCell-stickyHeader:last-child': {
        paddingBottom: '10px !important',
        paddingRight: '14.4px !important'
      }
    },
    differencesBoxes: {
      backgroundColor: theme.palette.primary.light,
      paddingTop: '6px',
      paddingBottom: '8px',
      paddingRight: '12px',
      borderRadius: '4px',
      height: theme.spacing(3),
      width: '157px',
      letterSpacing: '0.15px'
    }
  };
});

type Props = {
  currentPeriod?: Container;
  periods?: Container[];
  baselineTrendAnalysis?: TrendAnalysis;
  comparisonTrendAnalysis?: TrendAnalysis;
  previousPeriod: Container;
  selectedBaseContainerId?: string;
  selectedPreviousContainerId?: string;
  handleChange(val: Container, dropdown: string): void;
};

const TrendTable = ({
  currentPeriod,
  periods,
  baselineTrendAnalysis,
  comparisonTrendAnalysis,
  previousPeriod,
  selectedBaseContainerId,
  selectedPreviousContainerId,
  handleChange
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [unableToRender, setUnableToRender] = useState(false);
  const [rows, setRows] = useState<TrendAnalysisValue[]>([]);
  useEffect(() => {
    const cantRender = cannotRender(baselineTrendAnalysis, comparisonTrendAnalysis);
    setUnableToRender(cantRender);
    if (baselineTrendAnalysis || comparisonTrendAnalysis) {
      const rowsTable =
        // redundant checks needed for type narrowing
        cantRender || baselineTrendAnalysis === undefined || comparisonTrendAnalysis === undefined
          ? []
          : createTableRows(t, baselineTrendAnalysis, comparisonTrendAnalysis);
      rowsTable.pop();
      setRows(rowsTable);
    }
  }, [t, baselineTrendAnalysis, comparisonTrendAnalysis]);

  const BaselineHeader = () => {
    return (
      <PeriodSelector
        isBase
        title="Baseline"
        periods={periods}
        defaultPeriod={currentPeriod}
        currentPeriod={currentPeriod}
        handleChange={handleChange}
        selectedBaseContainerId={selectedBaseContainerId}
        selectedPreviousContainerId={selectedPreviousContainerId}
      />
    );
  };

  const ComparisonHeader = () => {
    return (
      <PeriodSelector
        title="Comparison Period"
        periods={periods}
        defaultPeriod={previousPeriod}
        currentPeriod={currentPeriod}
        handleChange={handleChange}
        selectedBaseContainerId={selectedBaseContainerId}
        selectedPreviousContainerId={selectedPreviousContainerId}
      />
    );
  };

  const DifferenceHeader = () => {
    return <DifferencePeriodHeader />;
  };

  const columns = [
    {
      renderHeaderGroup: BaselineHeader,
      headerGroup: t('Baseline'),
      field: 'name',
      className: 'rowHeader'
    },
    {
      renderHeaderGroup: BaselineHeader,
      headerGroup: t('Baseline'),
      headerName: t('Pre-Tax Amount'),
      field: 'preTaxAmount',
      isNumber: true,
      shouldAbbreviate: true,
      className: 'baselinePeriodSection'
    },
    {
      renderHeaderGroup: BaselineHeader,
      headerGroup: t('Baseline'),
      headerName: t('Tax-Effected Amount'),
      field: 'taxEffectedAmount',
      isNumber: true,
      shouldAbbreviate: true,
      className: 'baselinePeriodSection'
    },
    {
      renderHeaderGroup: BaselineHeader,
      headerGroup: t('Baseline'),
      headerName: t('Tax Rate'),
      field: 'taxRate',
      isPercentage: true,
      className: 'baselinePeriodSection'
    },
    gapColumn,
    {
      renderHeaderGroup: ComparisonHeader,
      headerGroup: t('Comparison'),
      headerName: t('Pre-Tax Amount'),
      field: 'preTaxAmountComparison',
      isNumber: true,
      shouldAbbreviate: true,
      className: 'comparisonPeriodSection'
    },
    {
      renderHeaderGroup: ComparisonHeader,
      headerGroup: t('Comparison'),
      headerName: t('Tax-Effected Amount'),
      field: 'taxEffectedAmountComparison',
      isNumber: true,
      shouldAbbreviate: true,
      className: 'comparisonPeriodSection'
    },
    {
      renderHeaderGroup: ComparisonHeader,
      headerGroup: t('Comparison'),
      headerName: t('Tax Rate'),
      field: 'taxRateComparison',
      isPercentage: true,
      className: 'comparisonPeriodSection'
    },
    gapColumn,
    {
      renderHeaderGroup: DifferenceHeader,
      headerGroup: t('Difference'),
      headerName: t('Pre-Tax Amount'),
      getValue: ({ preTaxAmount, preTaxAmountComparison }: any) => (preTaxAmount || 0) - (preTaxAmountComparison || 0),
      isNumber: true,
      shouldAbbreviate: true,
      renderCell: (_row: Row, value?: number) => (
        <Box className={classes.differencesBoxes}>
          {abbreviateNumber(Math.round(value ?? 0).toLocaleString('en-US'))}
        </Box>
      ),
      className: 'differencesSection'
    },
    {
      renderHeaderGroup: DifferenceHeader,
      headerGroup: t('Difference'),
      headerName: t('Tax-Effected Amount'),
      getValue: ({ taxEffectedAmount, taxEffectedAmountComparison }: any) =>
        (taxEffectedAmount || 0) - (taxEffectedAmountComparison || 0),
      isNumber: true,
      shouldAbbreviate: true,
      renderCell: (_row: Row, value?: number) => (
        <Box className={classes.differencesBoxes}>
          {abbreviateNumber(Math.round(value ?? 0).toLocaleString('en-US'))}
        </Box>
      ),
      className: 'differencesSection'
    },
    {
      renderHeaderGroup: DifferenceHeader,
      headerGroup: t('Difference'),
      headerName: t('Tax Rate'),
      className: classes.taxRateHeader,
      getValue: ({ taxRate, taxRateComparison }: any) => {
        const value = (taxRate || 0) - (taxRateComparison || 0);
        return value;
      },
      renderCell: (_row: Row, value?: number) => (
        <TaxRateChip valueToRender={Number.parseFloat(((value ?? 0) * 100).toFixed(2) ?? '0')} />
      )
    }
  ];

  return (
    <Box className={classes.trendTableContainer}>
      <Table
        className={classes.table}
        columns={columns}
        rows={rows}
        isNotEditableShaded={false}
        renderOpts={{ zeroValue: '' }}
        renderError={unableToRender}
        isBackgroundColorWhite={false}
      />
    </Box>
  );
};

export default TrendTable;
