import { AccountShape, CurrencyByEntityId, EntityFromUpload, EntityWithRates, PeriodRates } from '../../models';

export function flattenAccounts(accounts: AccountShape[], entities: EntityFromUpload[]): AccountShape[] {
  if (!Array.isArray(accounts) || !Array.isArray(entities)) {
    return [];
  }

  const flatten: AccountShape[] = Array.from({ length: accounts.length });
  let i = 0;
  for (const { id, description, totals } of accounts) {
    flatten[i] = { id, description };
    for (const { id: entityId } of entities) {
      flatten[i][entityId as keyof AccountShape] = totals[entityId];
    }

    i++;
  }

  return flatten;
}

export function convertAccountsToUSD(
  accounts: AccountShape[],
  containerFxRatesByCurrencyId: Record<string, Partial<PeriodRates>>,
  entitiesWithRatesByEntityNumber: Record<string, EntityWithRates>,
  currencyByEntityId: CurrencyByEntityId
): AccountShape[] {
  const accountsInUsd = accounts.map((account) => {
    const accountInUsd = { ...account };
    accountInUsd.totals = {};
    Object.entries(account.totals).forEach(([entityId, value]) => {
      const entityCurrency = currencyByEntityId[entityId];
      const entityCustomFxRate =
        entitiesWithRatesByEntityNumber[entityId]?.foreignExchangeRates?.[entityCurrency?.currencyId]?.current;
      const containerFxRate = containerFxRatesByCurrencyId[entityCurrency?.currencyId]?.current;
      const currencyConversionRate = entityCustomFxRate ?? containerFxRate ?? 1;
      const convertedValue =
        entityCurrency?.isoCode === 'USD' ? value : Math.round(((value as number) ?? 0) / currencyConversionRate);
      accountInUsd.totals[entityId] = convertedValue;
    });
    return accountInUsd;
  });
  return accountsInUsd;
}
