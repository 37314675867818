import { calculateBalanceSheet } from './balance-sheet';
import { calculateIncomeStatement } from './income-statement';
import { calculateReturnToProvision } from './return-to-provision';
import { GenericRecord, GenericNumericalRecord, makeListExtractor, sum } from './utils';

interface CalculateBalanceSheetForDeferredReturn extends GenericRecord {
  beginningBalance: number;
}

export const calculateBalanceSheetForDeferred = (
  inputs: GenericRecord = {}
): CalculateBalanceSheetForDeferredReturn => {
  inputs = calculateBalanceSheet(inputs);
  const { beginningBalance, beginningPayments }: GenericNumericalRecord = inputs;
  return {
    ...inputs,
    beginningBalance: (beginningBalance ?? 0) + (beginningPayments ?? 0)
  };
};

export const calculateIncomeStatementForDeferred = (inputs: GenericRecord = {}) => {
  return calculateIncomeStatement(calculateReturnToProvision(inputs));
};

interface CalculateModificationForDeferredReturn {
  [key: string]: any;
  rtp: number;
  effectiveTaxRate: number;
  beginningBalance: number;
}

export const calculateModificationForDeferred = (
  inputs: GenericRecord = {}
): CalculateModificationForDeferredReturn => {
  const { rtp } = calculateReturnToProvision(inputs);
  const { stateTaxRate, apportion, amount, federalAmount } = inputs;
  const effectiveTaxRate = (apportion || 0) * (stateTaxRate || 0);
  return {
    ...inputs,
    rtp: rtp * effectiveTaxRate,
    effectiveTaxRate,
    beginningBalance: (amount - federalAmount) * effectiveTaxRate
  };
};

export const calculateNetOperatingLossSumForDeferred = (
  inputs: GenericRecord = {},
  deferredDataForState: GenericRecord = {},
  taxRates: GenericRecord = {}
) => {
  let {
    beginningBalance = 0,
    balanceSheetOnlyAdjustment = 0,
    oci = 0,
    goodwill = 0,
    fin48 = 0,
    deferredOnlyAdjustment = 0,
    generatedAmount = 0,
    id,
    taxRates: inputTaxRates = {},
    rtp = 0,
    usedAmount = 0
  } = inputs;

  const mergedTaxRates = {
    beginning: taxRates[id]?.beginning ?? inputTaxRates.beginning ?? 0,
    current: taxRates[id]?.current ?? inputTaxRates.current ?? 0,
    end: taxRates[id]?.end ?? inputTaxRates.end ?? 0
  };

  const {
    beginning: beginningStateTaxRate = 0,
    current: currentStateTaxRate = 0,
    end: endingStateTaxRate = 0
  } = mergedTaxRates;

  const { manualEndingBalance = 0, note } = deferredDataForState;

  beginningBalance = Math.round(beginningBalance * beginningStateTaxRate);
  balanceSheetOnlyAdjustment = Math.round(balanceSheetOnlyAdjustment * endingStateTaxRate);
  oci = Math.round(oci * endingStateTaxRate);
  goodwill = Math.round(goodwill * endingStateTaxRate);
  fin48 = Math.round(fin48 * endingStateTaxRate);
  deferredOnlyAdjustment = Math.round(deferredOnlyAdjustment * endingStateTaxRate);
  rtp = Math.round(rtp * beginningStateTaxRate);
  usedAmount = Math.round(((usedAmount as number) + (generatedAmount as number)) * currentStateTaxRate);

  return {
    ...inputs,
    balanceSheetOnlyAdjustment,
    beginningBalance,
    creditName: 'state.nol',
    deferredOnlyAdjustment,
    generatedAmount,
    note,
    rtp,
    manualEndingBalance,
    nonEditableFields: [
      'beginningBalance',
      'deferredOnlyAdjustment',
      'oci',
      'goodwill',
      'fin48',
      'balanceSheetOnlyAdjustment'
    ],
    m1Adjustment: usedAmount,
    usedAmount,
    oci,
    goodwill,
    fin48
  };
};
// TODO: reduce code duplication: EntityDetails/components/FederalDeferred/utils.tsx

export const extractListsForDeferred = makeListExtractor('data', [
  'rtp',
  'beginningBalance',
  'm1Adjustment',
  'deferredOnlyAdjustment',
  'balanceSheetOnlyAdjustment',
  'manualEndingBalance',
  'oci',
  'goodwill',
  'fin48'
]);

export const calculateDeferredSum = (inputs = {}) => {
  const {
    beginningBalanceList,
    rtpList,
    m1AdjustmentList,
    deferredOnlyAdjustmentList,
    balanceSheetOnlyAdjustmentList,
    manualEndingBalanceList,
    ociList,
    goodwillList,
    fin48List
  } = extractListsForDeferred(inputs);

  return {
    ...inputs,
    beginningBalance: sum(beginningBalanceList),
    rtp: sum(rtpList),
    m1Adjustment: sum(m1AdjustmentList),
    deferredOnlyAdjustment: sum(deferredOnlyAdjustmentList),
    balanceSheetOnlyAdjustment: sum(balanceSheetOnlyAdjustmentList),
    manualEndingBalance: sum(manualEndingBalanceList),
    oci: sum(ociList),
    goodwill: sum(goodwillList),
    fin48: sum(fin48List)
  };
};

export const calculateEndingBalanceForDeferred = (inputs: GenericNumericalRecord = {}) => {
  const {
    beginningBalance,
    rtp,
    m1Adjustment,
    deferredOnlyAdjustment,
    balanceSheetOnlyAdjustment,
    manualEndingBalance
  } = inputs;
  const endingBalance =
    (beginningBalance || 0) +
    (rtp || 0) +
    (m1Adjustment || 0) +
    (deferredOnlyAdjustment || 0) +
    (balanceSheetOnlyAdjustment || 0);
  return {
    ...inputs,
    endingBalance,
    difference: endingBalance - (manualEndingBalance || 0)
  };
};
