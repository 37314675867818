import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';

import SortDownIcon from '../../../assets/imgs/SortDownIcon';
import { Column, Sort, TableHeadProps } from '../Table.proptype';
import { ASC } from '../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 0),
      color: theme.palette.text.primary
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: theme.spacing(0.8),
      color: theme.palette.text.secondary
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: theme.spacing(0.8),
      color: theme.palette.text.secondary
    },
    '& .MuiOutlinedInput-inputAdornedEnd': {
      borderRight: `1px solid ${theme.palette.divider}`
    },
    '& .MuiInputAdornment-positionEnd': {
      backgroundColor: theme.palette.common.white,
      cursor: 'pointer',
      opacity: 0.5,
      '&.sortActive': {
        opacity: 1,
        color: theme.palette.text.primary
      },
      '&:hover': {
        opacity: 1,
        color: theme.palette.text.primary
      }
    }
  }
}));

interface Props {
  column: Column;
  sort: Sort | null;
  value: string;
  onChange: TableHeadProps['onFilterChange'];
  onSort: TableHeadProps['onSortClick'];
}

const Filter = ({ column, sort, onSort, value, onChange }: Props) => {
  const classes = useStyles();
  const isSortActive = sort?.column === column;

  return (
    <TextField
      fullWidth
      className={classes.root}
      placeholder={column.placeholder}
      variant="outlined"
      value={value}
      InputProps={{
        onChange: ({ target: { value: newValue } }) => {
          onChange(column, newValue);
        },
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: sort && (
          <InputAdornment
            role="button"
            className={isSortActive ? 'sortActive' : ''}
            position="end"
            onClick={() => {
              onSort(column);
            }}
          >
            {sort.dir === ASC && isSortActive ? (
              <Box style={{ transform: 'rotateZ(180deg) translateY(1px)' }}>
                <SortDownIcon />
              </Box>
            ) : (
              <Box>
                <SortDownIcon />
              </Box>
            )}
          </InputAdornment>
        )
      }}
    />
  );
};

export default Filter;
