import { IconButton, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { ArrowForwardIos } from '@material-ui/icons';
import { useFlags } from 'launchdarkly-react-client-sdk';

import LoadingSpinner from '../../../LoadingSpinner';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '0.25rem',
    display: 'grid',
    padding: '1.42rem',
    paddingTop: '1.12rem',
    position: 'relative',
    height: 'fit-content',
    width: '361px'
  },
  header: {
    marginBottom: '14px',
    display: 'flex',
    '& .MuiSvgIcon-root': {
      width: theme.spacing(1.2),
      height: theme.spacing(1.2)
    },
    '& *:last-child': {
      marginLeft: 'auto'
    },
    '& *:first-child': {
      marginRight: 'auto',
      marginLeft: 0
    }
  },
  title: {
    display: 'inline-block'
  },
  body: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridAutoColumns: 'max-content',
    fontSize: theme.typography.fontSize,
    gap: '4px',
    '& > *': {
      whiteSpace: 'nowrap',
      width: 'fit-content',
      borderRadius: '4px'
    },
    '& .info': {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.info.dark
    },
    '& .warning': {
      backgroundColor: '#FEF7E0',
      color: '#82703A'
    }
  },
  contentMessage: {
    padding: '10px',
    boxSizing: 'border-box'
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > *': {}
  },
  browseBtn: {
    marginLeft: 'auto'
  }
}));

export type InfoCardProps = {
  title: string;
  hasEventButton?: boolean;
  contentMessages: Array<{ text: string; type: 'info' | 'warning' }>;
  footerMessage?: string;
  isLoading: boolean;
};

const shortenText = (text: string, maxLength: number) => {
  const extensionIndex = text.lastIndexOf('.');
  const ext = text.slice(Math.max(0, extensionIndex + 1));
  const name = text.slice(0, Math.max(0, extensionIndex));
  return `${name.slice(0, maxLength)}...${ext}`;
};

const InfoCard = ({ title, hasEventButton = false, contentMessages = [], footerMessage, isLoading }: InfoCardProps) => {
  const classes = useStyles();
  const { prov3117UploadManagerShortenFileName } = useFlags();
  const maxLength = 23;
  const shouldShortenText = (text: string) => {
    return prov3117UploadManagerShortenFileName && text.length > maxLength;
  };

  return (
    <Box className={classes.container}>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Box className={classes.header}>
            <Typography variant="h3" className={classes.title}>
              {title}
            </Typography>
            {hasEventButton && (
              <IconButton color="inherit">
                <ArrowForwardIos />
              </IconButton>
            )}
          </Box>
          <Box className={classes.body}>
            {contentMessages.map(({ text, type }) => (
              <Typography key={text} variant="h3" className={`${classes.contentMessage} ${type}`}>
                {shouldShortenText(text) ? <span title={text}>{shortenText(text, maxLength)}</span> : text}
              </Typography>
            ))}
          </Box>
          <Box className={classes.footer}>
            <Typography key={footerMessage} variant="inherit">
              {footerMessage}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

export default InfoCard;
