import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const alertStyle = makeStyles((theme) => ({
  container: {
    width: '98%',
    height: '45px',
    font: theme.typography.body2.fontFamily,
    fontSize: '12px',
    marginTop: '15px',
    marginRight: 'auto',
    backgroundColor: theme.palette.error.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    width: theme.spacing(1.3)
  }
}));

interface ErrorMessageBannerProps {
  message: string;
}

const ErrorMessageBanner = ({ message }: ErrorMessageBannerProps) => {
  const classes = alertStyle();
  return (
    <Box className={classes.container}>
      <div style={{ display: 'inline-block', paddingRight: '15px' }}>{message}</div>
    </Box>
  );
};

export default ErrorMessageBanner;
