interface UploadHistoryItem {
  uploadId: number;
  fileName: string;
  version: number;
  statusName: string;
  modifiedAt: string;
  uploadUserId: number;
  uploadedAt: string;
  updatedAt: string;
  updatedBy: number;
  uploadUserFirstName: string;
  uploadUserLastName: string;
}

export type UploadHistoryState = {
  error?: unknown;
  hasLoaded: boolean;
  isLoading: boolean;
  uploads: UploadHistoryItem[];
};

export const uploadHistoryInitialState: UploadHistoryState = {
  error: null,
  hasLoaded: false,
  isLoading: false,
  uploads: []
};
