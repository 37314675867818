import { useTranslation } from 'react-i18next';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { FEDERAL_UUID, LEVELS } from '../../../../../constants';
import { useFinancialData, useCompletionStatus } from '../../../../../hooks';
import { Step } from '../../../../../models';
import ColorTooltip from '../../../../ColorTooltip/ColorTooltip';
import CategoriesPopover from '../../CategoriesPopover';
import AdjustmentNameSelector from '../components/AdjustmentNameSelector';
import StateCellButton from '../components/StateCellButton';
import { STATE_MODIFICATIONS, MODIFICATIONS_STEP } from '../constants';
import {
  getModificationTypeOptions,
  getStepValue,
  calculateM1AdjustmentValue,
  getFederalM1AmountCalculatedValue
} from '../helpers';
import { StateModificationsRow } from '../StateModifications.types';

const useStateModificationsColumns = ({
  entityNumber,
  rows,
  setSelected,
  hasNewRow,
  handleCellChange
}: {
  entityNumber: string;
  rows?: StateModificationsRow[];
  setSelected: (row: StateModificationsRow) => void;
  hasNewRow: boolean;
  handleCellChange: (params: any) => void;
}) => {
  const federalSteps: Step[] = ['permanent', 'temporary', 'rtp'];
  const { stepCompletionStatus } = useCompletionStatus(entityNumber, STATE_MODIFICATIONS, FEDERAL_UUID);
  const { tabsData } = useFinancialData(entityNumber, LEVELS.FEDERAL, federalSteps);
  const { t } = useTranslation();
  const { prov4011TotalRowFix, prov3736ReservedWordValidation: showReservedWordsError } = useFlags();

  const handleChange = (props: any) => {
    handleCellChange({ ...props, column: { field: 'name' } });
  };

  return [
    {
      field: 'step',
      headerName: t('Modification Type'),
      isNumber: false,
      isNewRowEditable: true,
      placeholder: t('Modification Type'),
      filterable: true,
      sortable: true,
      usePlaceholderForSelect: true,
      getValue: getStepValue,
      getOptions: () => getModificationTypeOptions(MODIFICATIONS_STEP)
    },
    {
      field: 'name',
      headerName: t('Adjustment Name'),
      filterable: true,
      sortable: true,
      placeholder: t('Adjustment Name'),
      renderCell: (row: StateModificationsRow) => {
        const props = { row, tabsData, rows, showReservedWordsError, onChange: handleChange };
        const originalStepName = `state.${row.step!}`;

        return row?.isNew ? (
          <AdjustmentNameSelector {...props} />
        ) : (
          <div>
            {row.name}
            {row.category && row.step ? (
              <div className="float-right">
                <ColorTooltip
                  originalStepName={originalStepName}
                  category={row.category}
                  accountName={row.name ?? ''}
                />
              </div>
            ) : (
              row.step && (
                <div className="float-right">
                  <CategoriesPopover originalStepName={originalStepName} accountName={row.name ?? ''} />
                </div>
              )
            )}
          </div>
        );
      }
    },
    {
      field: 'federalAmount',
      headerName: t('Federal Amount'),
      isNumber: true,
      getValue: (row: StateModificationsRow) => getFederalM1AmountCalculatedValue({ row, tabsData })
    },
    {
      field: 'amount',
      headerName: t('State Amount'),
      isEditable: !stepCompletionStatus.status,
      isNumber: true,
      isNewRowEditable: true
    },
    {
      field: 'm1Adjustment',
      headerName: t('Modification'),
      isNumber: true,
      getValue: (row: StateModificationsRow) => calculateM1AdjustmentValue({ row, tabsData })
    },
    {
      headerName: t('States'),
      field: 'state',
      isActions: prov4011TotalRowFix,
      isNumber: false,
      isEditable: !stepCompletionStatus.status,
      renderCell: (row: StateModificationsRow) => (
        <StateCellButton
          row={row}
          hasNewRow={hasNewRow}
          setSelected={setSelected}
          status={Boolean(stepCompletionStatus.status)}
        />
      )
    }
  ];
};

export default useStateModificationsColumns;
