import { FailedCellsNoCredit } from '../../components/Table/Table.proptype';
import { Rates } from '../../models';

export interface RatesStateInterface {
  tax: Rates;
  exchange: Rates;
  failedCells: FailedCellsNoCredit;
  isLoading: boolean;
  isLoaded: boolean;
  error: unknown;
}

export const ratesInitialState: RatesStateInterface = {
  tax: {
    defaultRates: {},
    customRates: {},
    customEntityRates: {}
  },
  exchange: {
    defaultRates: {},
    customRates: {},
    customEntityRates: {}
  },
  failedCells: {},
  isLoading: false,
  isLoaded: false,
  error: null
};
