import { TFunction } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  calculateDeferredSum,
  calculateEndingBalanceForDeferred,
  calculateNetOperatingLossSumForDeferred
} from '../../../../calculations';
import { GenericNumericalRecord, GenericRecord } from '../../../../calculations/utils';
import { LEVELS } from '../../../../constants';
import {
  FinancialInfo,
  FinancialInfoTabsData,
  PeriodRates,
  Step,
  StepCompletionStatusReturnType,
  SubJurisdiction
} from '../../../../models';
import { groupByCreditName, sortOnColumn } from '../../../../utils';
import { Column, Row } from '../../../Table/Table.proptype';
import { CreditRow } from '../../creditUtils';
import {
  computeStateGrossTemporaryDifference,
  emptySectionHeader,
  getRowNamesAndJurisdictionIdFromLevelSteps,
  getRowNamesFromLevelSteps,
  makeSectionTitle,
  makeSectionTitleWithCreditName,
  VALUATION_ALLOWANCE_ROW_NAME
} from '../../utils';
import { getFederalM1AmountCalculatedValue } from '../StateModifications/helpers';

interface ValuationAllowanceRows {
  [name: string]: Row;
}

export const getValuationAllowance = (deferredRowsWithData?: Row[]): ValuationAllowanceRows => ({
  grossTemporaryDifferences:
    deferredRowsWithData?.find(
      (row: any) => row.name === VALUATION_ALLOWANCE_ROW_NAME && row.creditName === 'federal.temporary'
    ) ?? {},
  taxEffectedStateNOL:
    deferredRowsWithData?.find(
      (row: any) => row.name === VALUATION_ALLOWANCE_ROW_NAME && row.creditName === 'state.nol'
    ) ?? {},
  credits:
    deferredRowsWithData?.find(
      (row: any) => row.name === VALUATION_ALLOWANCE_ROW_NAME && row.creditName === 'state.credits'
    ) ?? {},
  modifications:
    deferredRowsWithData?.find(
      (row: any) => row.name === VALUATION_ALLOWANCE_ROW_NAME && row.creditName === 'state.modifications.temporary'
    ) ?? {}
});

export const useEntityTaxRatesByJurisdictionId = (entityId: string): Record<string, PeriodRates> =>
  useSelector((state: any) => ({
    ...(state?.rates?.tax?.customEntityRates?.[entityId] ?? {})
  }));

export const getFullStateData = ({
  states,
  tabsData
}: {
  states: SubJurisdiction[];
  tabsData: Record<string, FinancialInfo[]>;
}) => {
  const nolCells = tabsData['state.nol'] ?? [];

  const jurisdictionGroups = groupByJurisdictionId(nolCells);

  const totalProperties = getTotalOfPropertiesKeyedByKey(jurisdictionGroups, 'jurisdictionId', 'columnName');

  const fullStateData = states
    ?.filter((state) => Boolean(totalProperties[state.subJurisdictionId]))
    .map((state) => {
      const stateData = totalProperties[state.subJurisdictionId];
      return { ...state, ...stateData };
    });

  return fullStateData;
};

export const groupByJurisdictionId = (cells: any[]) => {
  const result: GenericRecord = {};
  for (const cell of cells) {
    result[cell.jurisdictionId] = [...(result[cell.jurisdictionId] || []), cell];
  }

  return Object.values(result);
};

// TODO: Check if this function already exists.
export const groupByKey = (cells: any[], key: string) => {
  const result: GenericRecord = {};
  for (const cell of cells) {
    result[cell[key]] = [...(result[cell[key]] || []), cell];
  }

  return Object.values(result);
};

export const getTotalOfPropertiesKeyedByKey = (
  arrayOfArrayOfObjects: GenericNumericalRecord[][],
  key: string,
  otherKey: string
) => {
  const result = {} as any;
  for (const arrayOfObjects of arrayOfArrayOfObjects) {
    result[arrayOfObjects[0][key]] = result[arrayOfObjects[0][key]] ?? {};
    const groupedByColumn = groupByKey(arrayOfObjects, otherKey);
    for (const columnArr of groupedByColumn) {
      if (columnArr.length === 0) continue;
      result[arrayOfObjects[0][key]][columnArr[0][otherKey]] =
        result[arrayOfObjects[0][key]][columnArr[0][otherKey]] ?? {};
      // eslint-disable-next-line unicorn/no-array-reduce
      const total = columnArr.reduce((acc: any, el: any) => (acc as number) + (el.value as number), 0);
      result[columnArr[0][key]][columnArr[0][otherKey]] = total;
    }
  }

  return result;
};

export const sumNumericalPropertiesInArrayOfObjects = (array: GenericRecord[]) => {
  const result: GenericRecord = {};
  for (const object of array) {
    for (const [key, value] of Object.entries(object)) {
      if (typeof value !== 'number') continue;
      result[key] = ((result[key] as number) ?? 0) + value;
    }
  }

  return result;
};

const FEDERAL_STEPS_FOR_STATE_DEFERRED: Step[] = [
  'deferred',
  'permanent',
  'rtp',
  'temporary.balanceSheet',
  'temporary.incomeStatement',
  'tax-effected'
];

const STATE_STEPS_FOR_STATE_DEFERRED: Step[] = [
  'apportionment',
  'deferred',
  'nol',
  'credits',
  'modifications.temporary',
  'rtp'
];

export const getStateDeferredRowNames = (tabsData: FinancialInfoTabsData) => {
  const federalRowNames = getRowNamesFromLevelSteps(tabsData, LEVELS.FEDERAL, FEDERAL_STEPS_FOR_STATE_DEFERRED);
  const stateRowNames = getRowNamesAndJurisdictionIdFromLevelSteps(
    tabsData,
    LEVELS.STATE,
    STATE_STEPS_FOR_STATE_DEFERRED
  );
  return {
    permanent: federalRowNames.permanent,
    balanceSheet: federalRowNames['temporary.balanceSheet'],
    incomeStatement: federalRowNames['temporary.incomeStatement'],
    taxEffectedAdjustments: federalRowNames['tax-effected'],
    credits: stateRowNames.credits,
    rtp: federalRowNames.rtp,
    stateRtp: stateRowNames.rtp,
    deferred: stateRowNames.deferred,
    federalDeferred: federalRowNames.deferred,
    nol: stateRowNames.nol,
    modifications: stateRowNames['modifications.temporary'],
    stateApportionment: stateRowNames.apportionment
  };
};

interface ComputeNolRows {
  columns: Column[];
  deferred: Row[];
  states: SubJurisdiction[];
  stepCompletionStatus: StepCompletionStatusReturnType['status'];
  t: TFunction;
  taxRates: Record<string, PeriodRates>;
  valuationAllowance: ValuationAllowanceRows;
}

export const computeNOLRows = ({
  columns,
  deferred,
  states = [],
  stepCompletionStatus,
  t,
  taxRates,
  valuationAllowance
}: ComputeNolRows) => {
  const nolRows = [];
  for (const state of states) {
    const { name } = state;
    const deferredDataForState = Object.assign(
      {},
      ...deferred.filter((cell: any) => cell.name === name && cell.creditName === 'state.nol')
    );

    nolRows.push({
      ...calculateNetOperatingLossSumForDeferred(state, deferredDataForState, taxRates),
      categorizable: false
    });
  }

  return [
    makeSectionTitle(t('Tax-Effected State Net Operating Loss')),
    ...sortOnColumn(nolRows, columns[0]),
    {
      isTotal: true,
      name: t('Total Tax-Effected State Net Operating Loss'),
      ...calculateDeferredSum({ data: nolRows }),
      creditName: 'state.nol',
      categorizable: false
    },
    emptySectionHeader,
    {
      name: t(VALUATION_ALLOWANCE_ROW_NAME),
      isEditable: !stepCompletionStatus,
      nonEditableFields: ['endingBalance', 'difference'],
      ...valuationAllowance.taxEffectedStateNOL,
      creditName: 'state.nol',
      categorizable: false
    },
    {
      isTotal: true,
      name: t('Tax-Effected State Net Operating Loss, Net of Valuation Allowance.'),
      ...calculateDeferredSum({ data: [...nolRows, valuationAllowance.taxEffectedStateNOL] }),
      creditName: 'state.nol',
      categorizable: false
    }
  ];
};

export const makeCreditRows = ({
  columns,
  creditsRowsGroupedByJurisdiction,
  deferred,
  stepCompletionStatus,
  subJurisdictionById,
  t,
  valuationAllowance
}: any) => {
  const creditRows: Row[] = [];

  let creditsData: CreditRow[] = [];

  for (const array of creditsRowsGroupedByJurisdiction) {
    for (const [_, creditList] of groupByCreditName(array)) {
      const sumOfProperties = sumNumericalPropertiesInArrayOfObjects(creditList);
      creditsData.push({ ...creditList[0], ...sumOfProperties });
    }
  }

  creditsData = creditsData.map((credit) => {
    const subJurisdictionName: string = subJurisdictionById.get(credit.jurisdictionId)?.name;
    const newCreditName = 'state.credits';
    const displayName = `${subJurisdictionName} - ${credit.creditName!}`;
    const matchingDeferredRow = Object.assign(
      {
        beginningBalance: 0,
        deferredOnlyAdjustment: 0,
        balanceSheetOnlyAdjustment: 0
      },
      ...deferred.filter((cell: any) => cell.name === displayName && cell.creditName === newCreditName),
      {
        beginningBalance: credit.beginningBalance,
        deferredOnlyAdjustment: credit.deferredOnlyAdjustment,
        balanceSheetOnlyAdjustment: credit.balanceSheetOnlyAdjustment
      }
    );
    return {
      ...credit,
      ...matchingDeferredRow,
      creditName: newCreditName,
      m1Adjustment: (credit.usedAmount ?? 0) + (credit.generatedAmount ?? 0),
      name: displayName,
      nonEditableFields: [
        'beginningBalance',
        'm1Adjustment',
        'deferredOnlyAdjustment',
        'oci',
        'goodwill',
        'fin48',
        'balanceSheetOnlyAdjustment'
      ],
      subJurisdictionName,
      rowId: matchingDeferredRow.rowId,
      step: 'deferred',
      categorizable: false
    };
  });

  creditsData = sortOnColumn(creditsData, columns[0]);

  creditRows.push(...creditsData);

  return [
    makeSectionTitle(t('Tax Credits')),
    ...sortOnColumn(creditRows, columns[0]),
    {
      isTotal: true,
      name: t('Total Tax Credits'),
      ...calculateDeferredSum({ data: creditRows }),
      categorizable: false
    },
    emptySectionHeader,
    {
      name: t(VALUATION_ALLOWANCE_ROW_NAME),
      isEditable: !stepCompletionStatus,
      nonEditableFields: ['endingBalance', 'difference'],
      ...valuationAllowance.credits,
      creditName: 'state.credits',
      categorizable: false
    },
    {
      isTotal: true,
      name: t('Tax Credits, Net of Valuation Allowance'),
      isEditable: !stepCompletionStatus,
      ...calculateDeferredSum({ data: [...creditRows, valuationAllowance.credits] }),
      categorizable: false
    }
  ];
};

export const makeModificationRows = ({
  columns,
  deferredRowsWithData = [],
  modificationsRowsWithData,
  stateRtpRowsWithData,
  stepCompletionStatus,
  subJurisdictionById,
  t,
  tabsData,
  taxRates,
  valuationAllowance,
  stateApportionmentRowsWithData,
  dispatch,
  className
}: {
  columns: Column[];
  deferredRowsWithData: Row[];
  modificationsRowsWithData: Row[];
  stateRtpRowsWithData: Row[];
  stepCompletionStatus: StepCompletionStatusReturnType['status'];
  subJurisdictionById: Map<string, SubJurisdiction>;
  t: TFunction;
  tabsData: Record<string, FinancialInfo[]>;
  taxRates: Record<string, PeriodRates>;
  valuationAllowance: any;
  stateApportionmentRowsWithData: Row[];
  dispatch?: any;
  className?: string;
}) => {
  let mergedModificationRows: Row[] = [];

  for (const modRow of modificationsRowsWithData) {
    const rowToMerge = { ...modRow };
    if (!rowToMerge.state?.length) {
      continue;
    }

    for (const subJurisdictionId of rowToMerge.state) {
      const state = subJurisdictionById.get(subJurisdictionId);
      const beginningTaxRate = taxRates?.[subJurisdictionId]?.beginning ?? state?.taxRates?.beginning ?? 0;
      const currentTaxRate = taxRates?.[subJurisdictionId]?.current ?? state?.taxRates?.current ?? 0;
      const displayName = `${state!.name ?? ''} - ${modRow?.name! ?? ''}`;
      const matchingDeferredRow = Object.assign(
        {
          beginningBalance: 0,
          deferredOnlyAdjustment: 0,
          balanceSheetOnlyAdjustment: 0
        },
        ...deferredRowsWithData.filter(
          (cell: any) => cell.name === displayName && cell.creditName === 'state.modifications.temporary'
        )
      );
      const matchingStateRtpRow = Object.assign(
        {},
        ...stateRtpRowsWithData.filter(
          (cell: any) =>
            cell.name === modRow.name &&
            cell.jurisdictionId === subJurisdictionId &&
            cell.creditName === 'state.modifications.temporary'
        )
      );
      const matchingStateApportionmentRow = Object.assign(
        {},
        ...stateApportionmentRowsWithData.filter(
          (cell: any) => cell.name === `${state!.name}${state!.isCombined ? ' - Combined' : ''}`
        )
      );
      const matchingApportionmentRow = Object.assign(
        {},
        ...stateRtpRowsWithData.filter(
          (cell: any) => cell.name === 'Apportionment Factor' && cell.jurisdictionId === subJurisdictionId
        )
      );

      const apportionmentRate = matchingStateApportionmentRow.amount;

      const federalAmount = getFederalM1AmountCalculatedValue({ row: modRow, tabsData });
      const variance = (matchingStateRtpRow.taxReturn ?? 0) - (matchingStateRtpRow.taxProvision ?? 0);
      const apportionmentTaxProvision = matchingApportionmentRow?.taxProvision ?? 0;
      const rtp = Math.round(variance * apportionmentTaxProvision * beginningTaxRate);

      const m1Adjustment = ((modRow.amount ?? 0) - federalAmount) * currentTaxRate * apportionmentRate;

      const mergedRow = {
        ...matchingDeferredRow,
        rtp,
        m1Adjustment,
        name: displayName,
        creditName: 'state.modifications.temporary',
        level: 'state',
        step: 'deferred'
      };

      mergedModificationRows.push({ ...mergedRow });
    }
  }

  mergedModificationRows = [...sortOnColumn(mergedModificationRows, columns[0])];

  return [
    makeSectionTitleWithCreditName({
      title: t('Tax-Effected State Modifications'),
      options: { withImportLink: true, stepCompletionStatus },
      dispatch,
      className,
      creditName: 'state.modifications.temporary'
    }),
    ...mergedModificationRows,
    {
      isTotal: true,
      name: t('Total Tax-Effected State Modifications'),
      ...calculateDeferredSum({ data: mergedModificationRows }),
      categorizable: false
    },
    emptySectionHeader,
    {
      name: t(VALUATION_ALLOWANCE_ROW_NAME),
      isEditable: !stepCompletionStatus,
      nonEditableFields: ['endingBalance', 'difference'],
      ...valuationAllowance.modifications,
      creditName: 'state.modifications.temporary',
      categorizable: false
    },
    {
      isTotal: true,
      name: t('Tax-Effected State Modifications, Net of Valuation Allowance'),
      ...calculateDeferredSum({ data: [...mergedModificationRows, valuationAllowance.modifications] }),
      categorizable: false
    }
  ];
};

export const makeRows = (
  t: TFunction,
  columns: any,
  {
    creditsRowsGroupedByJurisdiction,
    deferredRowsWithData,
    federalDeferredRowsWithData,
    modificationsRowsWithData,
    stateRtpRowsWithData,
    stateApportionmentRowsWithData,
    states,
    subJurisdictionById,
    tabsData,
    valuationAllowance,
    balanceSheet,
    incomeStatement,
    rtp,
    taxRates,
    dispatch,
    className
  }: any,
  stepCompletionStatus: StepCompletionStatusReturnType['status']
) => {
  if (!states || !balanceSheet || !incomeStatement || !rtp) {
    return [];
  }

  const grossTemporaryDifferencesRows = computeStateGrossTemporaryDifference(
    t,
    columns,
    balanceSheet,
    incomeStatement,
    rtp,
    deferredRowsWithData,
    federalDeferredRowsWithData,
    valuationAllowance,
    stepCompletionStatus,
    dispatch,
    className
  );

  const nolRows = computeNOLRows({
    columns,
    deferred: deferredRowsWithData,
    states,
    stepCompletionStatus,
    t,
    taxRates,
    valuationAllowance
  });

  const creditRows = makeCreditRows({
    columns,
    creditsRowsGroupedByJurisdiction,
    deferred: deferredRowsWithData,
    stepCompletionStatus,
    subJurisdictionById,
    t,
    valuationAllowance
  });

  const modificationRows = makeModificationRows({
    columns,
    deferredRowsWithData,
    modificationsRowsWithData,
    stateRtpRowsWithData,
    stepCompletionStatus,
    subJurisdictionById,
    t,
    tabsData,
    taxRates,
    valuationAllowance,
    stateApportionmentRowsWithData,
    dispatch,
    className
  });

  return [...grossTemporaryDifferencesRows, ...nolRows, ...creditRows, ...modificationRows].map((row) =>
    calculateEndingBalanceForDeferred(row)
  );
};
