import { useCallback, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Delete from '@material-ui/icons/Delete';
import { ToggleButton } from '@xbs/xbs-common-ui';

import TabTitleCategoriesToggle from './components/TabTitleCategoriesToggle';

import { ReactComponent as LinkIcon } from '../../../../assets/img/Icon_Link.svg';
import { useContainers, useEntity } from '../../../../hooks';
import { useCategorizableSections } from '../../../../hooks/useCategorizableSections';
import { useFeatureFlags } from '../../../../hooks/useFeatureFlags';
import { Currency } from '../../../../models';
import { setEntityDetailsGroupedView } from '../../../../redux/ui';
import { setUploadManagerIsOpen } from '../../../../redux/uploadManager';
import { selectIsEntityDetailsGroupedViewToggled } from '../../../../selectors';
import { splitLevelStep } from '../../../../utils/category';
import { ConfirmationDialogWithButtons } from '../../../ConfirmationDialog';
import CurrencyWithSymbol from '../../../CurrencyWithSymbol';
import { disableElementForRoles, hideElementForRoles } from '../../../UserRoleStylesProvider/constants';
import { EntityNumberRouteMatch, handleDeleteStateRTPTaxProvisionCol } from '../../utils';
import { getLinkButtonCssProps } from '../../utils/styles';

interface Props {
  title?: string | JSX.Element;
  isCompleted: boolean;
  isStateRTP?: boolean;
  currencyIsoCode: Currency['isoCode'];
  shouldDisplayDataImportButton?: boolean;
  onCompletionChange?: (checked: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  linkButton: {
    ...getLinkButtonCssProps(theme),
    '&.Mui-disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'inherit'
    }
  },
  marginRight: {
    marginRight: theme.spacing(2)
  },
  tabBox: {
    display: 'flex',
    alignItems: 'start'
  },
  toggleButtonBox: {
    '& div': {
      height: '30px'
    }
  },
  trashButton: {
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: '4px',
    minWidth: 'fit-content',
    paddingLeft: '6px',
    paddingRight: '6px',
    marginRight: '12px',
    marginTop: '-2.5px'
  }
}));

const TabTitle = ({
  title,
  isCompleted,
  isStateRTP,
  currencyIsoCode,
  onCompletionChange,
  shouldDisplayDataImportButton = true
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { checkConventionalFlag, checkUserIdsFlag } = useFeatureFlags();
  const dispatch = useDispatch();
  const { params } = useRouteMatch<EntityNumberRouteMatch>();
  const { pathname } = useLocation();
  const { level, step } = splitLevelStep(pathname);
  const isCategorizableTab = Boolean(useCategorizableSections(level, step));
  const { entity } = useEntity(params.entityNumber);
  const entityId = entity?.entityId;
  const { currentContainer } = useContainers();

  const shouldDisplayRemoveStateRTPData =
    checkConventionalFlag('flagRemoveStateRtpData') || checkUserIdsFlag('flagRemoveStateRtpDataUserAccess');

  const shouldDisplayCategoryToggle = isCategorizableTab;

  const handleOnSubmit = () => {
    handleDeleteStateRTPTaxProvisionCol({ dispatch, entityId });
  };

  const isToggleOn = useSelector(selectIsEntityDetailsGroupedViewToggled);

  useEffect(() => {
    const storedShouldGroupCategories = localStorage.getItem('shouldGroupCategories');
    if (storedShouldGroupCategories !== null) {
      dispatch(setEntityDetailsGroupedView(JSON.parse(storedShouldGroupCategories)));
    }
  }, [dispatch]);

  const handleToggle = useCallback(() => {
    dispatch(setEntityDetailsGroupedView(!isToggleOn));
    localStorage.setItem('shouldGroupCategories', JSON.stringify(!isToggleOn));
  }, [dispatch, isToggleOn]);

  return (
    <Box className={classes.tabBox}>
      <Box flexGrow={1}>{title}</Box>
      {isStateRTP && shouldDisplayRemoveStateRTPData && (
        <div
          data-roles-hide-element={hideElementForRoles.join(' ')}
          data-roles-disable-element={currentContainer?.isFinalized ? 'finalizedContainer' : ''}
        >
          <ConfirmationDialogWithButtons
            buttonClass={classes.trashButton}
            buttonIcon={<Delete />}
            dialogTitle={t('Delete Tax Provision column data?')}
            onSubmit={handleOnSubmit}
          />
        </div>
      )}
      {shouldDisplayCategoryToggle && (
        <Box id="toggleWrapper" style={{ display: 'relative' }}>
          <TabTitleCategoriesToggle isToggleOn={isToggleOn} handleToggle={handleToggle} />
        </Box>
      )}
      <CurrencyWithSymbol className={classes.marginRight} isoCode={currencyIsoCode} />
      <Box
        className={classes.toggleButtonBox}
        data-roles-disable-element={
          currentContainer?.isFinalized ? 'finalizedContainer' : disableElementForRoles.join(' ')
        }
      >
        <ToggleButton
          checked={isCompleted}
          handleChange={(_, checked) => {
            onCompletionChange?.(checked);
          }}
          rightCaption={t(isCompleted ? 'Completed' : 'In Progress')}
        />
      </Box>
      {shouldDisplayDataImportButton && (
        <Button
          className={classes.linkButton}
          data-roles-hide-element={hideElementForRoles.join(' ')}
          aria-label="link button"
          onClick={() => {
            dispatch(setUploadManagerIsOpen(true));
          }}
        >
          <LinkIcon />
        </Button>
      )}
    </Box>
  );
};

export default TabTitle;
