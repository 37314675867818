import {
  calculateM1Adjustment,
  calculateBalanceSheet,
  calculateIncomeStatement,
  CalculateIncomeStatementReturn
} from '../../../../../calculations';
import { FinancialInfoTabsData } from '../../../../../models';
import { RowForGetTableDataFromFinancialData } from '../../../../../utils';
import { getStepBasedTableDataFromFinancialData, getRowValues } from '../../../utils';
import { MODIFICATIONS_STEP } from '../constants';
import { StateModificationsRow } from '../StateModifications.types';

export const getValuesFromMatchingKeys = (source: FinancialInfoTabsData, matcher: string) =>
  Object.keys(source)
    .filter((key) => key.startsWith(matcher))
    .flatMap((key) => source[key]);

export const getFinancialInfo = (source: FinancialInfoTabsData, matchers: string[]) =>
  Object.keys(source)
    .filter((key) => matchers.includes(key))
    .flatMap((key) => {
      return source[key];
    });

type ModificationTypeOption = {
  name: string;
  value: string;
};
type ModificationTypeOptions = ModificationTypeOption[];

export const getModificationTypeOptions = (step: string): ModificationTypeOptions => [
  { name: 'Permanent', value: `${step}.permanent` },
  { name: 'Temporary', value: `${step}.temporary` }
];

export const getIsRowNameUsedInMatchingStepValues = (rows: any, step: any, rowName: string) =>
  rows
    .filter((item: any) => item.step === step)
    .map(({ name }: any) => name)
    .includes(rowName);

export const getStepBasedNameDropdownOptions = ({
  row,
  tabsData,
  rows
}: {
  row: StateModificationsRow;
  tabsData: FinancialInfoTabsData;
  rows?: StateModificationsRow[];
}) => {
  const step: string = row.step?.split('.').pop() ?? 'undefined';
  const stepForFederalData = `federal.${step}`;
  const namesFromMatchingKeys = getValuesFromMatchingKeys(tabsData, stepForFederalData).map((obj) => obj.rowName);
  if (row.isNew && row.name) {
    namesFromMatchingKeys.push(row.name);
  }

  const nameOptions = [...new Set(namesFromMatchingKeys)].map((name) => {
    return {
      name,
      value: name,
      disabled: getIsRowNameUsedInMatchingStepValues(rows, row.step, name)
    };
  });

  return nameOptions;
};

export const getValuesFromTypeAndAdjustment = (source: FinancialInfoTabsData, step: string, name?: string) =>
  Object.keys(source)
    .filter((key) => key.startsWith(step))
    .flatMap((key) => source[key])
    .find(({ rowName }) => rowName === name);

export const calculateM1AdjustmentValue = ({
  row = {},
  tabsData = {}
}: {
  row: StateModificationsRow;
  tabsData: FinancialInfoTabsData;
}) => {
  const value = getFederalM1AmountCalculatedValue({ row, tabsData });
  if (value) {
    return Number(row.amount ?? 0) - Number(value);
  }

  return row.amount ?? 0;
};

export const getFederalAmountValue = ({
  row,
  tabsData
}: {
  row: StateModificationsRow;
  tabsData: FinancialInfoTabsData;
}) => {
  const step: string = row.step?.split('.').pop() ?? 'undefined';
  const { value } = getValuesFromTypeAndAdjustment(tabsData, `federal.${step}`, row.name) ?? {};
  return (value as number) ?? 0;
};

export const getStepValue = (row: StateModificationsRow) => {
  const namedValue = getModificationTypeOptions(MODIFICATIONS_STEP).find((obj) => obj.value === row.step)?.name;
  return row.isNew ? row.step ?? '' : namedValue ?? '';
};

const getCalculatedFederalM1AdjustmentByStep = (federalAdjustmentRow: any) => {
  switch (federalAdjustmentRow?.step?.split('.').slice(0, 2).join('.')) {
    case 'permanent':
      return federalAdjustmentRow ? calculateM1Adjustment(federalAdjustmentRow) : 0;
    case 'temporary.incomeStatement':
      return federalAdjustmentRow
        ? calculateIncomeStatement(federalAdjustmentRow as CalculateIncomeStatementReturn).m1Adjustment
        : 0;
    case 'temporary.balanceSheet':
      return federalAdjustmentRow ? calculateBalanceSheet(federalAdjustmentRow).m1Adjustment : 0;
    default:
      return 0;
  }
};

export const getFederalM1AmountCalculatedValue = ({
  row = {},
  tabsData = {}
}: {
  row: StateModificationsRow;
  tabsData: FinancialInfoTabsData;
}) => {
  const step: string = row.step?.split('.').pop() ?? 'undefined';
  const financialInfo = getValuesFromMatchingKeys(tabsData, `federal.${step}`);
  const simpleNameAndStepObjects = [...new Set(financialInfo.map((obj) => ({ name: obj.rowName, step: obj.step })))];
  const rowsWithDataAndStep = getStepBasedTableDataFromFinancialData(simpleNameAndStepObjects, financialInfo);

  const matchingFederalRow = rowsWithDataAndStep.find((data: RowForGetTableDataFromFinancialData) => {
    return data.name === row.name;
  });

  if (matchingFederalRow?.step.includes('temporary.balanceSheet')) {
    // add in taxProvision and taxReturn for rtp calculation
    const rtpRowPreCalculation = getRowValues({
      rowName: matchingFederalRow.name,
      financialData: tabsData['federal.rtp'],
      creditName: matchingFederalRow.creditName
    });
    const { taxProvision, taxReturn } = rtpRowPreCalculation;

    return getCalculatedFederalM1AdjustmentByStep({ ...matchingFederalRow, taxProvision, taxReturn });
  }

  return getCalculatedFederalM1AdjustmentByStep(matchingFederalRow);
};
