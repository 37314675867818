import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../redux';

export const selectContainers = (state: RootState) => state.containers;
export const selectContainerContainers = (state: RootState) => state.containers.containers;
export const selectCurrentContainer = (state: RootState) => state.containers.currentContainer;

export const selectContainerPriorToCurrentContainerTaxYear = createSelector(
  selectContainerContainers,
  selectCurrentContainer,
  (containers, currentContainer) => {
    if (!currentContainer) return null;

    const sortedContainers = containers
      .slice()
      .sort((containerA, containerB) =>
        containerA.taxYear > containerB.taxYear ? -1 : containerA.taxYear < containerB.taxYear ? 1 : 0
      );

    const taxYears = [
      ...new Set(
        sortedContainers.map((container: any) => {
          return container.taxYear;
        })
      )
    ];
    /* eslint-disable unicorn/prefer-array-index-of */
    const currentContainerYearIndex = taxYears.findIndex((year: number) => year === currentContainer?.taxYear);
    const targetContainerYear =
      taxYears.length - 1 > currentContainerYearIndex
        ? taxYears[currentContainerYearIndex + 1]
        : taxYears[taxYears.length - 1];
    let targetContainer;
    sortedContainers.every((container) => {
      if (container?.taxYear === targetContainerYear) {
        targetContainer = container;
        return false;
      }

      return true;
    });

    return targetContainer ?? sortedContainers[sortedContainers.length - 1];
  }
);
