export const excludedProperties = new Set(['className', 'renderCell', 'type', 'isTotal', 'format']);
export const reportsOrder = {
  summary: 1,
  'federal-current-provision': 2,
  'federal-current-provision-state-tax-deduction-rtp': 3,
  'state-current-provision': 4,
  'deferred-gross': 5,
  'deferred-federal': 6,
  'deferred-state': 7,
  'deferred-state-fbos': 8,
  'deferred-fbos': 9,
  'deferred-fed-state-fbos': 10,
  'rate-reconciliation-summary': 11,
  'rate-reconciliation-detail': 12,
  'federal-rtp': 13,
  'state-rtp': 14,
  'beginning-state-etr': 15,
  'current-state-etr': 16,
  'end-state-etr': 17
};

export const reportsOrderDeprecated = {
  summary: 1,
  'federal-current-provision': 2,
  'state-current-provision': 3,
  'deferred-gross': 4,
  'deferred-federal': 5,
  'deferred-state': 6,
  'deferred-state-fbos': 7,
  'deferred-fbos': 8,
  'deferred-fed-state-fbos': 9,
  'rate-reconciliation-summary': 10,
  'rate-reconciliation-detail': 11,
  'federal-rtp': 12,
  'state-rtp': 13,
  'beginning-state-etr': 14,
  'current-state-etr': 15,
  'end-state-etr': 16
};

export const MULTI_TABLE_REPORTS = ['beginning-state-etr', 'current-state-etr', 'end-state-etr'];
