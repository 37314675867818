import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { REPORT_STATUS } from '../../../constants';
import { formatDate } from '../../../utils';
import UpdatingBadge from '../../UpdatingBadge';
import { useReportIcon } from '../utils';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '12px',
    background: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
    paddingRight: '25px'
  },
  reportName: {
    marginLeft: '10px'
  },
  button: {
    padding: theme.spacing(1, 1.5),
    fontFamily: 'Basis Grotesque Pro Medium',
    fontSize: '14px',
    letterSpacing: '0.21px',
    color: '#3C2A8E'
  },
  icon: {
    '&.MuiSvgIcon-root': {
      fontSize: '3rem'
    }
  },
  lastUpdate: {
    color: theme.palette.text.secondary,
    marginLeft: 'auto',
    '& .date': {
      marginLeft: '15px'
    }
  },
  status: {
    marginLeft: '20px',
    marginRight: '75px',
    width: '80px'
  }
}));

interface Props {
  reportStatus?: number;
  reportTimestamp?: string;
  name: string;
  type: string;
  to: string;
}

const getStatusChip = (reportStatus?: number) => {
  switch (reportStatus) {
    case REPORT_STATUS.inProgress:
      return <UpdatingBadge />;
    case REPORT_STATUS.failed:
      return 'Failed';
    default:
      return null;
  }
};

const ReportBox = ({ name, type, to, reportStatus, reportTimestamp }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { prov3137ReportsStatusRework } = useFlags();
  const ReportIcon = useReportIcon(type);

  return (
    <Paper variant="outlined" className={classes.container} data-testid="ReportBox">
      <ReportIcon className={classes.icon} />
      <Typography variant="h3" className={classes.reportName}>
        {t(name)}
      </Typography>
      <Typography variant="caption" className={classes.lastUpdate}>
        {prov3137ReportsStatusRework && `${t('last-update').toUpperCase()}:`}
        {prov3137ReportsStatusRework && (
          <span className="date">{formatDate(reportTimestamp!, 'HH:mm YYYY/MM/DD')}</span>
        )}
      </Typography>
      {prov3137ReportsStatusRework && <Box className={classes.status}>{getStatusChip(reportStatus)}</Box>}
      <Button variant="outlined" className={classes.button} component={Link} to={to}>
        {t('View Report')}
      </Button>
    </Paper>
  );
};

export default ReportBox;
