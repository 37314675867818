import { makeListExtractor, sum } from './utils';

export const extractListsForNetOperatingLosses = makeListExtractor('netOperatingLosses', [
  'beginningBalance',
  'rtp',
  'generatedAmount',
  'usedAmount'
]);

export const calculateNetOperatingLossSum = (inputs = {}) => {
  const { beginningBalanceList, rtpList, generatedAmountList, usedAmountList } = extractListsForNetOperatingLosses(
    inputs
  );

  const generatedAmount = sum(generatedAmountList);
  const usedAmount = sum(usedAmountList);

  return {
    ...inputs,
    beginningBalance: sum(beginningBalanceList),
    rtp: sum(rtpList),
    usedAmount,
    generatedAmount,
    m1Adjustment: usedAmount - generatedAmount
  };
};
