import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';

import { useStyles } from './SummaryAdditionalCard.styles';

import LoadingWrapper from '../../../LoadingWrapper';

export type SummaryAdditionalCardProps = {
  isLoading?: boolean;
  title: string;
  firstValue: string;
  secondValue: string;
};

const Card = ({ title, firstValue, secondValue, isLoading }: SummaryAdditionalCardProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.container}>
      <LoadingWrapper isLoading={Boolean(isLoading)}>
        <Box className={classes.floatingContainer}>
          <Typography variant="body2" className={classes.title}>
            {title}
          </Typography>
          <Box display="flex" alignItems="center">
            <Box
              className={classNames(classes.jurisdictionFirstBox, {
                [classes.entityStatusFirstBox]: title === 'Entity Status'
              })}
            >
              {firstValue}
            </Box>
            {title === 'Entity Status' && (
              <Typography variant="body2" className={classes.completeText}>
                {t('entity-complete')}
              </Typography>
            )}
            <Box
              className={classNames(classes.jurisdictionSecondBox, {
                [classes.entityStatusSecondBox]: title === 'Entity Status'
              })}
            >
              {secondValue}
            </Box>
            {title === 'Entity Status' && (
              <Typography variant="body2" className={classes.inProgressText}>
                {t('entity-in-progress')}
              </Typography>
            )}
          </Box>
        </Box>
      </LoadingWrapper>
    </Box>
  );
};

export default Card;
