import { NULL_UUID } from '../constants';

export interface RowForGetTableDataFromFinancialData {
  [key: string]: any;
  name: string;
  hideIfEmpty?: boolean;
  accountId?: string;
}

export interface GetTableDataFromFinancialDataReturnType extends RowForGetTableDataFromFinancialData {
  accountId: string;
}

export function addNullAccountIdToRowsWhichDoNotHaveAccountId<T>(
  row: T & { accountId?: string }
): T & { accountId: string } {
  return { ...row, accountId: row.accountId ?? NULL_UUID };
}
