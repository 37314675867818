// import { useState } from 'react';

// import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, /* Tabs, Tab */ makeStyles } from '@material-ui/core';

import TraceabilityReport from './components/TraceabilityReport';

import { selectTraceabilityIsLoading } from '../../../../selectors/traceability';
import LoadingSpinner from '../../../LoadingSpinner';

const useStyles = makeStyles((theme) => ({
  root: {
    // '& .MuiBox-root': {
    //   overflow: 'visible'
    // },
    padding: theme.spacing(0, 1),
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }
}));

/**
 * Leaving the "tabs" structure in, in case we decide to have other tabs in the future.
 */

// const tabs = [
//   {
//     labelKey: 'Data Details',
//     key: 'data-details',
//     render: () => <TraceabilityReport />
//   },
//   PROV-687: // Reenable once audit is supported
//   {
//     labelKey: 'Audit Trails',
//     key: 'audit-trails',
//     render: () => 'Stay tuned...'
//   }
// ];

const TraceabilityContent = () => {
  // const { t } = useTranslation();
  const classes = useStyles();
  const traceabilityIsLoading = useSelector(selectTraceabilityIsLoading);
  // const [currentTab, setCurrentTab] = useState(tabs[0]);

  return traceabilityIsLoading ? (
    <LoadingSpinner />
  ) : (
    <Box className={classes.root}>
      {/* <Tabs indicatorColor="primary" textColor="primary" value={currentTab?.key}>
        {tabs.map((tab) => (
          <Tab
            key={tab.key}
            label={t(tab.labelKey)}
            value={tab.key}
            onClick={() => {
              setCurrentTab(tab);
            }}
          />
        ))}
      </Tabs> */}
      <Box flexGrow={1} overflow="auto">
        <TraceabilityReport />
      </Box>
    </Box>
  );
};

export default TraceabilityContent;
