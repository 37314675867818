/* eslint-env node */
import i18next from 'i18next';

import { MONTHS, MONTHS_SHORT } from '../constants';

const formats = {};
function initFormats() {
  (formats as any).period = new Intl.DateTimeFormat(i18next.language, {
    year: 'numeric',
    month: 'numeric',
    timeZone: (global as any).TIMEZONE
  });
  (formats as any).short = new Intl.DateTimeFormat(i18next.language, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: (global as any).TIMEZONE
  });
  (formats as any).long = new Intl.DateTimeFormat(i18next.language, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: (global as any).TIMEZONE
  });
}

initFormats();
i18next.on('languageChanged', initFormats);
function format(date: any, spec: any) {
  try {
    return date && spec ? spec.format(new Date(date)) : '-';
  } catch {
    return '-';
  }
}

export function formatDatePeriod(date: any): string {
  return format(date, (formats as any).period);
}

export function formatDateShort(date: any): string {
  return format(date, (formats as any).short) ?? '';
}

// Intl adds special spaces like (\u202f) or (\xa0) -- these are Unicode escape sequences.
// These spaces break jest unit tests.
// Linting required these to be /u00A0 and /u202F these values instead
export function formatDateLong(date: any): string {
  return format(date, (formats as any).long)
    .replace(/\u00A0/g, ' ')
    .replace(/\u202F/g, ' ');
}

export function formatDateLongDayFirst(timestamp: string | null): string {
  if (!timestamp) return '-';

  const date = new Date(timestamp);
  const day: string = date.getDate().toString().padStart(2, '0');
  const month: string = date.toLocaleString('en-US', { month: 'long' }).toUpperCase();
  const year: number = date.getFullYear();
  const hours: number = date.getHours();
  const minutes: string = date.getMinutes().toString().padStart(2, '0');
  const period: string = hours >= 12 ? 'PM' : 'AM';

  const twelveHourFormat = hours % 12 || 12;
  const forced2DigitHour: string = twelveHourFormat.toString().padStart(2, '0');
  return `${day} ${month} ${year} ${forced2DigitHour}:${minutes}${period}`;
}

type DateFormat =
  | 'HH:mm YYYY/MM/DD'
  | 'DD MMMM YY HH:mm'
  | 'DD/MM/YYYY HH:mm'
  | 'MM/DD/YYYY HH:mm'
  | 'DD MMM YYYY'
  | 'MM/DD/YYYY'
  | 'YYYY-MM-DD';

export const formatDate = (date: string | Date, format: DateFormat) => {
  if (!date) {
    return '';
  }

  const dateObj = typeof date === 'string' ? new Date(date) : date;

  const month = dateObj.getMonth();
  const monthName = MONTHS[month];
  const monthShortName = MONTHS_SHORT[month];
  const day = String(dateObj.getDate()).padStart(2, '0');
  const year = dateObj.getFullYear();
  const hours = String(dateObj.getHours()).padStart(2, '0');
  const minutes = String(dateObj.getMinutes()).padStart(2, '0');

  switch (format) {
    case 'HH:mm YYYY/MM/DD':
      return `${hours}:${minutes} ${year}/${String(month + 1).padStart(2, '0')}/${day}`;
    case 'DD MMMM YY HH:mm':
      return `${day} ${monthName} ${String(year).split('').slice(2, 4).join('')} ${hours}:${minutes}`;
    case 'DD MMM YYYY':
      return `${day} ${monthShortName.toUpperCase()} ${year}`;
    case 'MM/DD/YYYY':
      return `${String(month + 1).padStart(2, '0')}/${day}/${year}`;
    case 'YYYY-MM-DD':
      return `${year}-${String(month + 1).padStart(2, '0')}-${day}`;
    case 'DD/MM/YYYY HH:mm':
      return `${day}/${String(month + 1).padStart(2, '0')}/${year} ${hours}:${minutes}`;
    case 'MM/DD/YYYY HH:mm':
      return `${String(month + 1).padStart(2, '0')}/${day}/${year} ${hours}:${minutes}`;
    default:
      return dateObj.toLocaleString('en-US');
  }
};
