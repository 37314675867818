import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import tinycolor from 'tinycolor2';

const useStyles = makeStyles((theme) => ({
  circle: {
    width: theme.spacing(1),
    height: theme.spacing(1),
    borderRadius: '50%',
    marginRight: theme.spacing(0.83)
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '15px',
    border: 'solid 1px black',
    minWidth: theme.spacing(7.5),
    width: 'fit-content',
    maxWidth: '100%',
    height: theme.spacing(2),
    paddingLeft: theme.spacing(0.83),
    overflow: 'auto',
    whiteSpace: 'nowrap',
    paddingRight: theme.spacing(0.83),
    flex: 1,
    backgroundColor: theme.palette.background.default
  }
}));

interface Props {
  [key: string]: any;
  color: string;
  text: string;
}

const ColorCategoryName = ({ color, text, ...props }: Props) => {
  const classes = useStyles();
  const lightColor = tinycolor(color).lighten(40).toString();
  const darkColor = tinycolor(color).darken(20).toString();

  return (
    <Box {...props}>
      <div className={classes.text} style={{ backgroundColor: lightColor, borderColor: color, color: darkColor }}>
        <Box className={classes.circle} style={{ backgroundColor: `${color}` }} />
        <Typography variant="caption">{text}</Typography>
      </div>
    </Box>
  );
};

export default ColorCategoryName;
