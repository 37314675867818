import { Card, makeStyles } from '@material-ui/core';

import { Collection, LineItem, TotalRow } from './TraceabilityReportRowComponents';

const useStyles = makeStyles((theme) => ({
  lineItemCard: {
    backgroundColor: theme.palette.common.white,
    padding: '32px'
  },
  totalCard: {
    padding: '32px'
  }
}));

/**
 * Traceability reports contains "values" which is an array of either single line items ("rows" or possibly "totals") or collections ("row groups").
 * This component is designed to accommodate both, hence "TraceabilityReportRowOrRowGroup."
 */
const TraceabilityReportRowOrRowGroup = (props: { row: any }) => {
  const { row } = props;
  const classes = useStyles();

  if (row.type === 'collection') {
    return <Collection row={row} />;
  }

  if (row.type === 'lineItem') {
    return (
      <Card className={classes.lineItemCard}>
        <LineItem hasTopBorder row={row} />
      </Card>
    );
  }

  if (row.type === 'total') {
    return (
      <Card className={classes.totalCard}>
        <TotalRow row={row} />
      </Card>
    );
  }

  return null;
};

export default TraceabilityReportRowOrRowGroup;
