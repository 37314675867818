import { DataImportSelection, FormattedWorksheet } from '../../../../models';

export const calculateTotalsRange = (entityRange: string, accountRange: string) => {
  const [entityStart, entityEnd = entityStart] = entityRange.split(':');
  const [accountStart, accountEnd = accountStart] = accountRange.split(':');

  const startColumn = entityStart.replace(/\d/g, '');
  const startRow = accountStart.replace(/\D/g, '');
  const start = startColumn + startRow;

  const endColumn = entityEnd.replace(/\d/g, '');
  const endRow = accountEnd.replace(/\D/g, '');
  const end = endColumn + endRow;

  return start + ':' + end;
};

const replaceDashOrEmptyWithZero = (value: string | number) => {
  if (value.toString().trim() === '-' || value.toString().trim().length === 0) {
    return 0;
  }

  return value;
};

export const createAccountsUploadArray = (
  dataImportSelection: DataImportSelection,
  result: {
    formatted: FormattedWorksheet;
    raw: FormattedWorksheet;
  }
) => {
  const accountsArray = [];
  const entityNumbers = dataImportSelection['Entity Number'].rawValues;
  const accountNumbers = dataImportSelection['Acc. Number'].formattedValues;
  const accountDescriptions = dataImportSelection['Acc. Description'].formattedValues;
  const rawValues = result.raw;

  for (const [i, accountNumber] of accountNumbers.entries()) {
    const valueObject: { [key: string]: any } = {};
    for (const [j, entityNumber] of entityNumbers.entries()) {
      if (Array.isArray(rawValues[i])) {
        valueObject[String(entityNumber)] = replaceDashOrEmptyWithZero(rawValues[i][j]);
      }
    }

    accountsArray.push({
      description: accountDescriptions[i],
      id: accountNumber,
      totals: valueObject
    });
  }

  return accountsArray;
};

export const createEntitiesUploadArray = (dataImportSelection: DataImportSelection) => {
  const entitiesArray = [];
  const entityNumbers = dataImportSelection['Entity Number'].formattedValues;
  const entityNames = dataImportSelection['Entity Name'].formattedValues;

  for (const [i, entityNumber] of entityNumbers.entries()) {
    entitiesArray.push({
      id: entityNumber,
      name: entityNames[i]
    });
  }

  return entitiesArray;
};

export const createEntityRangeObject = (dataImportSelection: DataImportSelection, sheetName: string) => {
  const entityNumberRange = dataImportSelection['Entity Number'].range;
  const entityNameRange = dataImportSelection['Entity Name'].range;

  return {
    numberRange: `'${sheetName}'!${entityNumberRange}`,
    nameRange: `'${sheetName}'!${entityNameRange}`
  };
};

export const createAccountsRangeObject = (dataImportSelection: DataImportSelection, sheetName: string) => {
  const accountNumberRange = dataImportSelection['Acc. Number'].range;
  const accountNameRange = dataImportSelection['Acc. Description'].range;

  return {
    numberRange: `'${sheetName}'!${accountNumberRange}`,
    nameRange: `'${sheetName}'!${accountNameRange}`
  };
};
