import { useMemo } from 'react';

import { getStateDeferredRowNames } from '.';

import { FinancialInfo, FinancialInfoTabsData } from '../../../../models';
import { getTableDataFromFinancialData } from '../../utils';
import { STATE_MODIFICATIONS_TEMPORARY } from '../StateModifications/constants';

export const useStateDeferredRowsWithData = (tabsData: FinancialInfoTabsData) => {
  const formattedTabsData: FinancialInfoTabsData = useMemo(
    () => ({
      balanceSheetCellData: tabsData['federal.temporary.balanceSheet'] ?? [],
      creditsCellData: tabsData['state.credits'] ?? [],
      deferredCellData: tabsData['state.deferred'] ?? [],
      federalDeferredCellData: tabsData['federal.deferred'] ?? [],
      incomeStatementCellData: tabsData['federal.temporary.incomeStatement'] ?? [],
      modificationsCellData: tabsData[STATE_MODIFICATIONS_TEMPORARY] ?? [],
      nolCellData: tabsData['state.nol'] ?? [],
      rtpCellData: tabsData['federal.rtp'] ?? [],
      stateRtpCellData: tabsData['state.rtp'] ?? [],
      stateApportionmentCellData: tabsData['state.apportionment'] ?? []
    }),
    [tabsData]
  );

  const concatenatedCellData: FinancialInfo[] = useMemo(() => Object.values(formattedTabsData).flat(), [
    formattedTabsData
  ]);

  const {
    balanceSheetCellData,
    incomeStatementCellData,
    rtpCellData,
    deferredCellData,
    federalDeferredCellData,
    creditsCellData,
    modificationsCellData,
    nolCellData,
    stateRtpCellData,
    stateApportionmentCellData
  } = formattedTabsData;

  const rowNames = useMemo(() => getStateDeferredRowNames(tabsData), [tabsData]);

  const {
    credits: creditsRowNames,
    balanceSheet: balanceSheetRowNames,
    incomeStatement: incomeStatementRowNames,
    deferred: deferredRowNames,
    federalDeferred: federalDeferredRowNames,
    modifications: modificationsRowNames,
    nol: nolRowNames,
    rtp: rtpRowNames,
    stateRtp: stateRtpRowNames,
    stateApportionment: stateApportionmentRowNames
  } = rowNames;

  const balanceSheetRowsWithData = useMemo(
    () => getTableDataFromFinancialData(balanceSheetRowNames, balanceSheetCellData),
    [balanceSheetCellData, balanceSheetRowNames]
  );

  const incomeStatementRowsWithData = useMemo(
    () => getTableDataFromFinancialData(incomeStatementRowNames, incomeStatementCellData),
    [incomeStatementCellData, incomeStatementRowNames]
  );

  const rtpRowsWithData = useMemo(() => getTableDataFromFinancialData(rtpRowNames, rtpCellData), [
    rtpCellData,
    rtpRowNames
  ]);

  const stateRtpRowsWithData = useMemo(() => getTableDataFromFinancialData(stateRtpRowNames, stateRtpCellData), [
    stateRtpCellData,
    stateRtpRowNames
  ]);

  const deferredRowsWithData = useMemo(() => getTableDataFromFinancialData(deferredRowNames, deferredCellData), [
    deferredCellData,
    deferredRowNames
  ]);

  const federalDeferredRowsWithData = useMemo(
    () => getTableDataFromFinancialData(federalDeferredRowNames, federalDeferredCellData),
    [federalDeferredRowNames, federalDeferredCellData]
  );

  const creditsRowsWithData = useMemo(() => getTableDataFromFinancialData(creditsRowNames, creditsCellData), [
    creditsCellData,
    creditsRowNames
  ]);

  const modificationsRowsWithData = useMemo(
    () => getTableDataFromFinancialData(modificationsRowNames, modificationsCellData),
    [modificationsCellData, modificationsRowNames]
  );

  const nolRowsWithData = useMemo(() => getTableDataFromFinancialData(nolRowNames, nolCellData), [
    nolCellData,
    nolRowNames
  ]);

  const stateApportionmentRowsWithData = useMemo(
    () => getTableDataFromFinancialData(stateApportionmentRowNames, stateApportionmentCellData),
    [stateApportionmentCellData, stateApportionmentRowNames]
  );

  return {
    balanceSheetRowsWithData,
    concatenatedCellData,
    creditsRowsWithData,
    deferredRowsWithData,
    federalDeferredRowsWithData,
    incomeStatementRowsWithData,
    modificationsRowsWithData,
    nolRowsWithData,
    rtpRowsWithData,
    stateRtpRowsWithData,
    stateApportionmentRowsWithData
  };
};
