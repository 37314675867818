import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { Box, Button, makeStyles } from '@material-ui/core';
import classnames from 'classnames';

import { ContainerOperation, PanelProps } from '../../../../models';
import { SET_CONTAINER_OPERATION } from '../../localReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    fontSize: '18px',
    margin: 'auto',
    width: '80%'
  },
  rowContent: {
    backgroundColor: theme.palette.common.white,
    borderColor: `2px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(1),
    fontSize: '18px',
    height: theme.spacing(10),
    color: theme.palette.text.primary,
    letterSpacing: '0.32px',
    marginTop: theme.spacing(4),
    textAlign: 'center',
    padding: theme.spacing(1),
    width: '45%'
  },
  rowContentSelected: {
    backgroundColor: theme.palette.info.main,
    fontWeight: 600,
    color: theme.palette.primary.main,
    border: `2px solid ${theme.palette.info.dark}`
  },
  fullRowContent: {
    color: theme.palette.text.primary,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    width: '100%'
  },
  buttonsContainer: {
    display: 'flex',
    gap: '0.5rem',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(8)
  },
  buttonsContainerCentered: {
    justifyContent: 'center'
  }
}));

const ContainerOperationMenu = ({ localDispatch, localState, setIsRightActionEnabled }: PanelProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { containerOperation } = localState;

  useEffect(() => {
    setIsRightActionEnabled(true);
  }, [setIsRightActionEnabled]);

  const setContainerOperation = (containerOperation: ContainerOperation) => {
    localDispatch({
      type: SET_CONTAINER_OPERATION,
      payload: containerOperation
    });
  };

  const secondButton = (
    <Button
      disableElevation
      variant="contained"
      className={classnames(classes.rowContent, {
        [classes.rowContentSelected]: containerOperation === 'rollforward-interim'
      })}
      onClick={() => {
        setContainerOperation('rollforward-interim');
      }}
    >
      {t('Rollforward - Interim Container')}
    </Button>
  );

  return (
    <Box className={classes.root}>
      <Box>
        <Box className={classes.fullRowContent}>{t('How would you like to set up the new period?')}</Box>
        <Box className={classes.buttonsContainer}>
          <Button
            disableElevation
            variant="contained"
            className={classnames(classes.rowContent, {
              [classes.rowContentSelected]: containerOperation === 'rollforward-yearly'
            })}
            onClick={() => {
              setContainerOperation('rollforward-yearly');
            }}
          >
            {t('Rollforward - Annual Container')}
          </Button>
          {secondButton}
          <Button
            disableElevation
            variant="contained"
            className={classnames(classes.rowContent, {
              [classes.rowContentSelected]: containerOperation === 'copy'
            })}
            onClick={() => {
              setContainerOperation('copy');
            }}
          >
            {t('Copy Container')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ContainerOperationMenu;
