import { Box, Divider, makeStyles } from '@material-ui/core';

import AddNewPeriod from '../../AddNewPeriod';

interface DropdownFooterProps {
  setIsDropdownOpen: (isOpen: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  footer: {
    fontSize: theme.spacing(1.5),
    '& p': {
      padding: theme.spacing(1.5)
    }
  }
}));

const DropdownFooter = ({ setIsDropdownOpen }: DropdownFooterProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.footer}>
      <Divider />
      <AddNewPeriod
        onClick={() => {
          setIsDropdownOpen(false);
        }}
      />
    </Box>
  );
};

export default DropdownFooter;
