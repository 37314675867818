import { createReducer, isAnyOf, PayloadAction } from '@reduxjs/toolkit';

import {
  accountsEntityCountOnFailed,
  accountsEntityCountOnReceived,
  accountsEntityCountOnSendingRequest
} from './accountsEntityCount.actions';
import { accountsEntityCountInitialState } from './accountsEntityCount.initialState';

import { AccountsEntityCountInterface } from '../../models';
import { containersOnCurrentSwitched } from '../containers';

export const accountsEntityCountReducer = createReducer(accountsEntityCountInitialState, (builder) => {
  builder
    .addCase(accountsEntityCountOnSendingRequest, (state) => {
      return { ...state, isLoading: true, error: null };
    })
    .addCase(accountsEntityCountOnReceived, (state, action: PayloadAction<AccountsEntityCountInterface[]>) => {
      return {
        ...state,
        accounts: action.payload,
        isLoading: false,
        isLoaded: true,
        error: null
      };
    })
    .addCase(accountsEntityCountOnFailed, (state, action: PayloadAction<string>) => {
      return {
        ...state,
        accounts: accountsEntityCountInitialState.accounts,
        isLoading: false,
        error: action.payload
      };
    })
    .addMatcher(isAnyOf(containersOnCurrentSwitched), () => {
      return accountsEntityCountInitialState;
    });
});
