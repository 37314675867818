import { ReactNode, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import GetApp from '@material-ui/icons/GetApp';
import Skeleton from '@material-ui/lab/Skeleton';
import { ToggleButton, SlideOut } from '@xbs/xbs-common-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { REPORT_STATUS } from '../../../../constants';
import { Container } from '../../../../models';
import { ReportMeta } from '../../../../redux/reports';
import {
  onCloseTraceability,
  onTraceabilitySetActive,
  onTraceabilitySetInactive
} from '../../../../redux/traceability';
import { resetCategoryRowsReportToggle, setShouldGroupCategoriesOnReports } from '../../../../redux/ui';
import {
  selectTraceabilityIsActive,
  selectTraceabilityColumn,
  selectTraceabilityRow
} from '../../../../selectors/traceability';
import { formatDateShort, formatDateLong } from '../../../../utils';
import UpdatingBadge from '../../../UpdatingBadge';
import RefreshReportAlertBanner from '../RefreshReportAlertBanner';
import ReportCompareDownloadButton from '../ReportCompareDownloadButton';
import ReportExcelDownloadButton from '../ReportExcelDownloadButton';
import TraceabilityContent from '../TraceabilityContent';

const useStyles = makeStyles((theme) => ({
  appBar: {
    padding: theme.spacing(1, 1.5),
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.secondary
  },
  appTitle: {
    flexGrow: 1,
    padding: theme.spacing(0, 2.5),
    '& h1': {
      color: theme.palette.text.primary
    },
    '& h1, & h2': {
      padding: 0
    }
  },
  toggleContainer: {
    padding: theme.spacing(0, 2)
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  paperContainer: {
    height: '100%',
    overflow: 'hidden',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default
  },
  paper: {
    borderTop: `5px solid ${theme.palette.primary.light}`,
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    height: '100%',
    margin: '0 auto',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      '&:not(.full-width)': {
        maxWidth: 900
      }
    }
  },
  inlineBlock: {
    display: 'inline-block'
  }
}));

type ReportLayoutProps = {
  children?: string | ReactNode;
  closeUrl: string;
  title?: string;
  container?: Container;
  currency?: string;
  icon?: ReactNode;
  hasPaperFullWidth?: boolean;
  modelTimestamp?: string;
  isNewReportAvailable: boolean;
  currentReportMeta: ReportMeta;
  onEnableTraceability?: () => void;
  onDisableTraceability?: () => void;
  onDownloadClicked?: () => void;
  onReloadReportClick: () => void;
};

const ReportLayout = ({
  children,
  title,
  container,
  currency,
  icon,
  closeUrl,
  hasPaperFullWidth,
  modelTimestamp,
  isNewReportAvailable,
  currentReportMeta,
  onEnableTraceability,
  onDisableTraceability,
  onDownloadClicked,
  onReloadReportClick
}: ReportLayoutProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { prov3580DisableTraceability, prov3757CalcEngineReportComparison, prov3799ExcelExportPocUi } = useFlags();

  useEffect(() => {
    const storedShouldGroupCategories = localStorage.getItem('shouldGroupCategories');
    if (storedShouldGroupCategories !== null) {
      dispatch(setShouldGroupCategoriesOnReports(JSON.parse(storedShouldGroupCategories)));
    }
  }, [dispatch]);

  const closeTraceability = () => {
    dispatch(resetCategoryRowsReportToggle());
    dispatch(onCloseTraceability());
  };

  const isTraceabilityActive = useSelector(selectTraceabilityIsActive);
  const traceabilityRow = useSelector(selectTraceabilityRow);
  const traceabilityColumn = useSelector(selectTraceabilityColumn);
  const isTraceabilityOpen = isTraceabilityActive && traceabilityRow !== null && traceabilityColumn !== null;

  const reportStatusMessage = modelTimestamp
    ? `This report's data was last updated on ${formatDateLong(modelTimestamp)}`
    : t('This report is currently being generated, this will not have the latest changes.');

  const toggleTraceability = () => {
    if (isTraceabilityActive) {
      dispatch(onTraceabilitySetInactive());
      onDisableTraceability?.();
    } else {
      dispatch(onTraceabilitySetActive());
      onEnableTraceability?.();
    }
  };

  return (
    <Box className={classes.root}>
      <AppBar position="static" elevation={0} className={classes.appBar}>
        <Toolbar disableGutters>
          {icon}
          <Box className={classes.appTitle}>
            <Typography variant="h2" component="h1">
              {title ? title : <Skeleton width={400} />}
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                className={classes.inlineBlock}
                variant="caption"
                color="primary"
                component="h2"
                data-testid="report-status-message"
              >
                {reportStatusMessage}
              </Typography>
              {currentReportMeta?.status === REPORT_STATUS.inProgress && (
                <div className={classes.inlineBlock} style={{ marginLeft: '15px', fontSize: '12px', width: '99px' }}>
                  <UpdatingBadge variant="warning" />
                </div>
              )}
            </div>
            <Typography variant="caption" component="h2">
              {container?.startDate ? (
                `${formatDateShort(container.startDate)} - ${formatDateShort(container.endDate)}`
              ) : (
                <Skeleton width={150} />
              )}
            </Typography>
            <Typography variant="caption">{currency && t('currency _', { value: currency })}</Typography>
          </Box>
          {!prov3580DisableTraceability && (
            <Box className={classes.toggleContainer}>
              <ToggleButton
                checked={isTraceabilityActive}
                handleChange={toggleTraceability}
                rightCaption={t('Traceablility')}
              />
            </Box>
          )}
          {prov3757CalcEngineReportComparison && (
            <ReportCompareDownloadButton
              reportType={currentReportMeta?.reportType}
              reportModelId={currentReportMeta?.reportSourceId}
              reportName={currentReportMeta?.reportName}
              currencyIsoCode={currency}
              taxYear={container?.taxYear}
              timestamp={modelTimestamp}
            />
          )}
          {currentReportMeta?.reportName === 'current-state-etr' && prov3799ExcelExportPocUi && (
            <ReportExcelDownloadButton
              reportType={currentReportMeta?.reportType}
              reportModelId={currentReportMeta?.reportSourceId}
              reportName={currentReportMeta?.reportName}
              currencyIsoCode={currency}
              taxYear={container?.taxYear}
              title={title}
            />
          )}
          <IconButton color="primary" aria-label="Download report" component="span" onClick={onDownloadClicked}>
            <GetApp />
          </IconButton>
          <Button variant="outlined" color="primary" component={Link} to={closeUrl} onClick={closeTraceability}>
            {t('Close')}
          </Button>
        </Toolbar>
      </AppBar>
      <SlideOut.Container>
        <Box className={classes.paperContainer}>
          <Paper className={`${classes.paper}${hasPaperFullWidth ? ' full-width' : ''}`} elevation={0}>
            {children}
            {isNewReportAvailable && <RefreshReportAlertBanner refreshHandler={onReloadReportClick} />}
          </Paper>
        </Box>
        <SlideOut.Drawer
          isDividerHidden
          isFloating
          title={t('Traceability')}
          isOpen={traceabilityRow !== null && traceabilityColumn !== null}
          subtitle=""
          titleHeight={72}
          fixedWidth="577px"
          onClose={closeTraceability}
        >
          {isTraceabilityOpen ? <TraceabilityContent /> : ''}
        </SlideOut.Drawer>
      </SlideOut.Container>
    </Box>
  );
};

export default ReportLayout;
