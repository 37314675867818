import { ReactNode } from 'react';

import { useInView } from 'react-intersection-observer';

import { Box } from '@material-ui/core';

interface InViewObserverWrapperProps {
  children: any;
  fillHeight?: number;
  fallback?: ReactNode;
}

const InViewObserverWrapper = ({ children, fillHeight, fallback }: InViewObserverWrapperProps) => {
  const fallbackComponent = fallback ?? <Box data-testid="fallback ui" height={fillHeight} />;

  const { ref, inView } = useInView({
    rootMargin: '200px 0px'
  });

  return <div ref={ref}>{inView ? children : fallbackComponent}</div>;
};

export default InViewObserverWrapper;
