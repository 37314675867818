import { Jurisdiction } from '../../models';

export interface JurisdictionsStateInterface {
  jurisdictions: Jurisdiction[];
  hasLoaded: boolean;
  isLoading: boolean;
  error: unknown;
}

export const jurisdictionsInitialState: JurisdictionsStateInterface = {
  jurisdictions: [],
  hasLoaded: false,
  isLoading: false,
  error: null
};
