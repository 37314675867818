import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import { UploadReviewTrialBalanceMetaData } from '../../../models';
import { formatDateLong } from '../../../utils';
import { hideElementForRoles } from '../../UserRoleStylesProvider/constants';

const useStyles = makeStyles((theme) => ({
  disclaimer: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    margin: theme.spacing(1),
    padding: theme.spacing(1),

    '& .MuiButtonBase-root': {
      padding: theme.spacing(0.5, 3)
    },
    '& h3': {
      padding: theme.spacing(0, 3)
    },
    '& :nth-child(4)': {
      flexGrow: 1
    }
  },
  date: {
    marginLeft: 'auto'
  },
  container: {
    alignItems: 'center',
    backgroundColor: theme.palette.action.focus,
    borderRadius: '4px',
    color: theme.palette.common.white,
    display: 'flex',
    height: '44px',
    padding: '7px',
    '& .MuiButtonBase-root': {
      borderRadius: '4px',
      lineHeight: '18px'
    }
  },
  filename: {
    backgroundColor: `${theme.palette.primary.main}4C`,
    borderRadius: '4px',
    marginLeft: '24px',
    padding: '5px',
    paddingLeft: '13px',
    paddingRight: '13px'
  },
  title: {
    marginLeft: '12px'
  },
  viewButton: {
    marginLeft: '25px',
    width: '94px'
  }
}));

interface Props {
  upload: UploadReviewTrialBalanceMetaData;
}

const UploadNotification = ({ upload }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.container} data-roles-hide-element={hideElementForRoles.join(' ')}>
      <Typography className={classes.title} variant="h3">
        {t('upload-manager')}
      </Typography>
      <Typography className={classes.filename} variant="body2">
        {upload.fileName}
      </Typography>
      <Typography className={classes.date} variant="body2">
        {formatDateLong(upload.updatedOn)}
      </Typography>
      <Button
        className={classes.viewButton}
        variant="contained"
        color="primary"
        component={Link}
        to={`/upload-review/${upload.containerId}`}
      >
        <Typography variant="body2">{t('view')}</Typography>
      </Button>
    </Box>
  );
};

export default UploadNotification;
