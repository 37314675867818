import { useReducer, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Box, makeStyles } from '@material-ui/core';

import { BACK, NEXT, INITIAL_STATE, reducer } from './localReducer';
import { PANELS } from './panels';

import TodayIcon from '../../assets/imgs/TodayIcon';
import { AddNewPeriodModalProps, RollforwardApiPayload } from '../../models';
import ProModal, { ModalActions } from '../ProModal';

const useStyles = makeStyles((theme) => ({
  backButton: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '4px'
  },
  headerElement: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    marginLeft: theme.spacing(0.8)
  },
  nextButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main
  },
  createButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    width: '40%'
  },
  todayIcon: {
    backgroundColor: theme.palette.primary.light,
    border: `1px solid ${theme.palette.primary.light}`,
    lineHeight: '24px',
    borderRadius: '50%',
    padding: '4px',
    fontSize: '32px'
  }
}));

const TodayIconWrapper = () => {
  const classes = useStyles();
  return (
    <div className={classes.todayIcon}>
      <TodayIcon />
    </div>
  );
};

const AddNewPeriodModal = ({ containers, isCreateDisabled, onCreate, onClose }: AddNewPeriodModalProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const HeaderElement = () => <Box className={classes.headerElement}>{t('Add New Period')}</Box>;

  const [localState, localDispatch] = useReducer(reducer, { ...INITIAL_STATE, containers });
  const [isRightActionEnabled, setIsRightActionEnabled] = useState<boolean>(true);

  const CurrentPanel = PANELS[localState.currentPanelIndex];

  const handleBack = () => {
    localDispatch({
      type: BACK
    });
  };

  const handleNext = () => {
    localDispatch({
      type: NEXT
    });
  };

  const handleCreate = () => {
    const rfPayload: RollforwardApiPayload = {
      existingContainerId: localState.rolledFromContainer!.containerId,
      newName: localState.newContainerName,
      newIsInterimReportingPeriod: localState.containerOperation === 'rollforward-interim' ? 1 : 0,
      newIsCopy: localState.containerOperation === 'copy',
      newTaxYear: Number(localState.defineContainerPeriods.taxYear),
      newPeriodStart: localState.defineContainerPeriods.periodStart!,
      newPeriodEnd: localState.defineContainerPeriods.periodEnd!
    };
    onCreate(rfPayload);
  };

  const leftActions: ModalActions[] =
    localState.currentPanelIndex === 0
      ? []
      : [{ name: t('Back'), handler: handleBack, variant: 'outlined', className: classes.backButton }];
  const rightActions: ModalActions[] = [
    {
      name: t('Cancel'),
      handler: onClose
    },
    {
      name: localState.currentPanelIndex === localState.nPanels - 1 ? t('Create') : t('Next'),
      handler: localState.currentPanelIndex === localState.nPanels - 1 ? handleCreate : handleNext,
      className: localState.currentPanelIndex === localState.nPanels - 1 ? classes.createButton : classes.nextButton,
      disabled: !isRightActionEnabled || isCreateDisabled
    }
  ];

  return (
    <ProModal
      isOpen
      maxWidth="lg"
      headerIcon={TodayIconWrapper}
      title={<HeaderElement />}
      rightActions={rightActions}
      leftActions={leftActions}
      onClose={onClose}
    >
      <CurrentPanel
        localDispatch={localDispatch}
        localState={localState}
        setIsRightActionEnabled={setIsRightActionEnabled}
      />
    </ProModal>
  );
};

export default AddNewPeriodModal;
