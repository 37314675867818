import { RootState } from '../redux';

export const selectTraceabilityColumn = (state: RootState) => state.traceability.column;
export const selectTraceabilityError = (state: RootState) => state.traceability.error;
export const selectTraceabilityIsActive = (state: RootState) => state.traceability.isActive;
export const selectTraceabilityIsLoading = (state: RootState) => state.traceability.isLoading;
export const selectTraceabilityReport = (state: RootState) => state.traceability.report;
export const selectTraceabilityRow = (state: RootState) => state.traceability.row;

export const traceabilitySelectors = {
  selectTraceabilityColumn,
  selectTraceabilityError,
  selectTraceabilityIsActive,
  selectTraceabilityIsLoading,
  selectTraceabilityReport,
  selectTraceabilityRow
};
