import { GenericRecord, makeListExtractor, sum } from './utils';

import { Row } from '../components/Table/Table.proptype';

export interface CalculateReturnToProvisionReturn {
  taxReturn?: number;
  taxProvision?: number;
}

export const calculateReturnToProvision = <T extends CalculateReturnToProvisionReturn>(inputs: T) => {
  const { taxReturn, taxProvision } = inputs ?? {};
  return {
    ...inputs,
    rtp: (taxReturn ?? 0) - (taxProvision ?? 0)
  };
};

export const extractListsForReturnToProvision = makeListExtractor('data', ['taxReturn', 'taxProvision']);

interface CalculateReturnToProvisionSumReturn extends GenericRecord {
  rtp: number;
  taxReturn: number;
  taxProvision: number;
}

export const calculateReturnToProvisionSum = (inputs: GenericRecord = {}): CalculateReturnToProvisionSumReturn => {
  const { taxReturnList, taxProvisionList } = extractListsForReturnToProvision(inputs);

  const taxReturn = sum(taxReturnList);
  const taxProvision = sum(taxProvisionList);

  return {
    ...inputs,
    taxReturn,
    taxProvision,
    rtp: taxReturn - taxProvision
  };
};

export const calculateRTPProduct = (row1: Row, row2: Row) => {
  return {
    taxProvision: (row1.taxProvision || 0) * (row2.taxProvision || 0),
    taxReturn: (row1.taxReturn || 0) * (row2.taxReturn || 0)
  };
};
