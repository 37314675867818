import { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';

import { GenericRecord } from '../../../../calculations/utils';
import { useContainers } from '../../../../hooks';
import { Jurisdiction, RateDomain, RateType, RateUpdate, RenderCellProps } from '../../../../models';
import { formatSubJurisdictionForDisplay } from '../../../../utils';
import Table from '../../../Table';
import { TableProps, FailedCells, Row } from '../../../Table/Table.proptype';
import { disableTableInputForRoles } from '../../../UserRoleStylesProvider/constants';
import { makeStylesName } from '../utils/styles';

const useStyles = makeStyles((theme) => ({
  name: makeStylesName(theme),
  stateName: {
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'right'
  }
}));

function formatRow(row: Row) {
  return {
    ...row,
    shouldExpand: Array.isArray(row.subJurisdictions) && row.subJurisdictions.length > 0,
    'beginning.tax': row.taxRates?.beginning,
    'current.tax': row.taxRates?.current,
    'end.tax': row.taxRates?.end,
    'beginning.exchange': row.foreignExchangeRates?.beginning,
    'current.exchange': row.foreignExchangeRates?.current,
    'end.exchange': row.foreignExchangeRates?.end
  };
}

type RateInfo = [RateType, RateDomain];

type Props = {
  jurisdictions?: Jurisdiction[];
  failedCells: FailedCells;
  onRateChange: (rateUpdate: RateUpdate) => void;
};

const JurisdictionTable = ({ jurisdictions, failedCells, onRateChange }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { currentContainer } = useContainers();
  const [expanded, setExpanded] = useState<GenericRecord>({});
  const [isFiltered, setIsFiltered] = useState(false);
  const [rows, setRows] = useState<Row[]>([]);

  const handleOnCellOrCommentBlur: TableProps['onCellOrCommentBlur'] = useCallback(
    ({ value, column, row }) => {
      const [rateType, rateDomain] = column.field.split('.') as RateInfo;
      const rateUpdate: RateUpdate = {
        jurisdictionId: row.jurisdictionId ?? row.subJurisdictionId,
        rateType,
        rateDomain,
        rate: Number(value),
        rowName: row.name,
        columnName: column.field,
        currencyId: row.currencyId
      };
      onRateChange(rateUpdate);
    },
    [onRateChange]
  );

  const columns = useMemo(() => {
    return [
      {
        field: 'name',
        filterable: true,
        placeholder: t('Jurisdiction'),
        divider: true,
        width: '25%',
        renderCell: ({ shouldExpand, id }: RenderCellProps, value: string | number) => (
          <Box className={classes.name}>
            {shouldExpand && !isFiltered && (
              <IconButton
                size="small"
                onClick={() => {
                  setExpanded({ ...expanded, [id]: !expanded[id] });
                }}
              >
                {expanded[id] ? <ArrowDropUp /> : <ArrowDropDown />}
              </IconButton>
            )}
            <Box className={classes.stateName}>{value}</Box>
          </Box>
        )
      },
      {
        headerGroup: t('Tax Rates'),
        field: 'beginning.tax',
        headerName: t('Beginning'),
        divider: true,
        isPercentage: true,
        isEditable: !currentContainer?.isFinalized
      },
      {
        headerGroup: t('Tax Rates'),
        field: 'current.tax',
        headerName: t('Current'),
        divider: true,
        isPercentage: true,
        isEditable: !currentContainer?.isFinalized
      },
      {
        headerGroup: t('Tax Rates'),
        field: 'end.tax',
        headerName: t('End'),
        divider: true,
        isPercentage: true,
        isEditable: !currentContainer?.isFinalized
      }
    ];
  }, [classes, expanded, isFiltered, t, currentContainer?.isFinalized]);

  useEffect(() => {
    if (!Array.isArray(jurisdictions)) {
      return;
    }

    const displayRows = Array.from({ length: jurisdictions.length });
    let i = 0;
    for (const row of jurisdictions) {
      const displayRow = formatRow(row);
      displayRows[i++] = displayRow;

      if (displayRow.shouldExpand && (expanded[row.id] || isFiltered)) {
        for (const stateRow of row.subJurisdictions) {
          displayRows[i++] = { ...formatRow(formatSubJurisdictionForDisplay(t, stateRow)), isSubjurisdiction: true };
        }
      }
    }

    setRows(displayRows as Row[]);
  }, [jurisdictions, expanded, isFiltered, t]);

  return (
    <Box data-roles-disable-table-input={disableTableInputForRoles.join(' ')}>
      <Table
        columns={columns}
        rows={rows}
        noRowLabel={t('No Jurisdictions')}
        isNotEditableShaded={false}
        renderOpts={{ numberOfDecimals: 4 }}
        failedCells={failedCells}
        onFilter={(filters: any) => {
          setIsFiltered(filters.size > 0);
        }}
        onCellOrCommentBlur={handleOnCellOrCommentBlur}
      />
    </Box>
  );
};

export default JurisdictionTable;
