import { makeStyles } from '@material-ui/core';
import Close from '@material-ui/icons/Close';

import SelectedItemChip from '../../SelectedItemChip';

const useStyles = makeStyles(() => ({
  mainText: {
    fontSize: '12px',
    letterSpacing: '0.25px',
    fontFamily: 'Basis Grotesque Pro Medium'
  },
  closeButton: {
    marginLeft: '15px',
    marginRight: '5px',
    fontSize: '19px'
  }
}));

type TimePeriodSelectedItemChipProps = {
  text: string;
  onClear: () => void;
};

const TimePeriodSelectedItemChip = ({ text, onClear }: TimePeriodSelectedItemChipProps) => {
  const classes = useStyles();

  return (
    <SelectedItemChip
      className={classes}
      text={text}
      icon={
        <Close
          className={classes.closeButton}
          onClick={(e) => {
            e.stopPropagation();
            onClear();
          }}
        />
      }
    />
  );
};

export default TimePeriodSelectedItemChip;
