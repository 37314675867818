import { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { Box, Tabs, Tab, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SlideOut } from '@xbs/xbs-common-ui';

import { EntityTable, JurisdictionTable } from './components';
import CurrencyTable from './components/CurrencyTable';

import { useCurrencies, useEntities, useJurisdictions, useRates } from '../../hooks';
import { EntityWithRates, FxRateUpdate, RateUpdate } from '../../models';
import { setParentEntity } from '../../redux/entities';
import { updateExchangeRate, updateTaxRate } from '../../redux/rates';
import { selectEntitiesWithRates, selectEntityCurrencies } from '../../selectors';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    // required for EntityTable to take the available height and gracefully scroll
    height: '100%',
    overflow: 'hidden'
  },
  title: {
    padding: theme.spacing(2, 2, 0),
    display: 'flex'
  },
  entityCountContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.text.secondary
  },
  entityCount: {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.text.secondary}`,
    padding: theme.spacing(1.5),
    fontSize: '1.4em',
    minWidth: '2.5em',
    textAlign: 'center',
    fontFamily: theme.typography.h1.fontFamily
  },
  entityCountLabel: {
    fontSize: '0.7em',
    paddingTop: theme.spacing(1)
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginTop: theme.spacing(-2),
    overflow: 'auto'
  },
  tabContent: {
    paddingTop: theme.spacing(2),
    overflow: 'auto'
  }
}));

const EntityReview = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  useEntities();
  const { failedCells } = useRates();
  const entities: EntityWithRates[] = useSelector(selectEntitiesWithRates);
  const { currencyById, containerFxRatesByCurrencyId: containerFxRates } = useCurrencies();
  const { entityJurisdictions } = useJurisdictions();
  const [tab, setTab] = useState('entities');
  const currencies = useSelector(selectEntityCurrencies);

  const handleRateChange = useCallback(
    (rateUpdate: RateUpdate | FxRateUpdate) => {
      // NOTE: Type narrowing.
      if (rateUpdate.rateDomain === 'tax' && 'jurisdictionId' in rateUpdate) {
        dispatch(updateTaxRate(rateUpdate));
      } else if (!('jurisdiction' in rateUpdate)) {
        dispatch(updateExchangeRate(rateUpdate));
      }
    },
    [dispatch]
  );

  const handleParentEntityChange = useCallback(
    (entityId: EntityWithRates['id']) => {
      dispatch(setParentEntity(entityId));
    },
    [dispatch]
  );

  const handleTabChange = useCallback((event: any, newValue: any) => {
    setTab(newValue);
  }, []);

  return (
    <SlideOut.Container>
      <Box className={classes.container}>
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        <Typography variant="h2" component="h1" className={classes.title} pd={1}>
          <Box component="span" flexGrow={1}>
            {t('Entity Review')}
          </Box>
          <Box className={classes.entityCountContainer}>
            <Box className={classes.entityCount}>{entities?.length || 0}</Box>
            <Box className={classes.entityCountLabel}>{t('entity', { count: entities?.length || 0 })}</Box>
          </Box>
        </Typography>
        <Box className={classes.content}>
          <Tabs indicatorColor="primary" textColor="primary" value={tab} onChange={handleTabChange}>
            <Tab label={t('Entities')} value="entities" />
            <Tab label={t('Jurisdictions')} value="jurisdictions" />
            <Tab label={t('Currencies')} value="currencies" />
          </Tabs>
          <Box className={classes.tabContent}>
            {tab === 'entities' ? (
              <EntityTable
                entities={entities}
                jurisdictions={entityJurisdictions}
                currencyById={currencyById}
                failedCells={failedCells}
                onRateChange={handleRateChange}
                onParentEntityChange={handleParentEntityChange}
              />
            ) : tab === 'jurisdictions' ? (
              <JurisdictionTable
                jurisdictions={entityJurisdictions}
                failedCells={failedCells}
                onRateChange={handleRateChange}
              />
            ) : (
              <CurrencyTable
                fxRates={containerFxRates}
                currencies={currencies}
                failedCells={failedCells}
                onRateChange={handleRateChange}
              />
            )}
          </Box>
        </Box>
      </Box>
    </SlideOut.Container>
  );
};

export default EntityReview;
