import { Button, ButtonGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiButton-root': {
      color: theme.palette.text.primary
    },
    '& .MuiButtonGroup-groupedTextHorizontal:not(:last-child),& .MuiButtonGroup-groupedContainedHorizontal:not(:last-child)': {
      borderRightColor: 'transparent'
    },
    '& .MuiButtonGroup-groupedContainedHorizontal:not(.active)': {
      background: theme.palette.primary.light
    },
    '& .MuiButton-contained': {
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(0.5, 2.5)
    },
    '& .active': {
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white
    }
  }
}));

interface Props {
  variant?: 'text' | 'contained';
  buttons: Array<{
    isActive?: boolean;
    label: string;
    onClick: () => void;
  }>;
}

const ToggleButtons = ({ variant = 'text', buttons }: Props) => {
  const classes = useStyles();

  return (
    <ButtonGroup disableElevation variant={variant} className={classes.root}>
      {buttons.map(({ label, isActive, ...otherProps }) => (
        <Button key={label} className={isActive ? 'active' : ''} {...otherProps}>
          {label}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default ToggleButtons;
