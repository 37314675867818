import { RootState } from '../redux';

export const selectUploadManagerIsOpen = (state: RootState) => state.uploadManager.isOpen;
export const selectFileName = (state: RootState) => state.uploadManager.fileName;
export const selectFileVersion = (state: RootState) => state.uploadManager.fileVersion;
export const selectIsFileUploaded = (state: RootState) => state.uploadManager.isFileUploaded;
export const selectFileMetadata = (state: RootState) => state.uploadManager.fileMetadata;
export const selectFile = (state: RootState) => state.uploadManager.file;
export const selectIsFileDownloaded = (state: RootState) => state.uploadManager.isFileDownloaded;
export const selectUploadManagerCreditName = (state: RootState) => state.uploadManager.creditName;
