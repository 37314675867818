import { AccountShape, Category, UploadReviewConfirmTabLocalState } from '../../../../../models';
import { computePTBI } from '../../../../../utils';

export const SET_DATA = 'SET_DATA';
export const CONFIRM_IMPORT = 'CONFIRM_IMPORT';
export const SELECT_ENTITY = 'SELECT_ENTITY';

export const initialState: UploadReviewConfirmTabLocalState = {
  checked: false,
  currentEntity: null,
  accounts: [],
  profitAndLossRange: [],
  categories: [],
  ptbi: 0,
  permanent: 0,
  temporary: 0
};

function countAdjustments(categories: Category[], accounts: AccountShape[], entityId?: string) {
  if (categories.length === 0 || accounts.length === 0) {
    return {};
  }

  const permanentsIds = categories.find(({ id }) => id === 'permanent-adjustments')?.accountIds ?? [];
  const temporaryIds = categories.find(({ id }) => id === 'temporary-adjustments')?.accountIds ?? [];
  if (!entityId) {
    return { permanent: permanentsIds.length, temporary: temporaryIds.length };
  }

  let permanent = 0;
  let temporary = 0;
  for (const { id, totals } of accounts) {
    if (totals[entityId] !== 0 && Boolean(totals[entityId])) {
      if (permanentsIds.includes(id)) {
        permanent++;
      }

      if (temporaryIds.includes(id)) {
        temporary++;
      }
    }
  }

  return { permanent, temporary };
}

export function reducer(state: UploadReviewConfirmTabLocalState, { type, payload = {} }: any = {}) {
  switch (type) {
    case CONFIRM_IMPORT: {
      return {
        ...state,
        checked: payload
      };
    }

    case SELECT_ENTITY: {
      const { categories, accounts, profitAndLossRange } = state;
      return {
        ...state,
        currentEntity: payload,
        ...computePTBI(profitAndLossRange, categories, accounts, payload?.id),
        ...countAdjustments(categories, accounts, payload?.id)
      };
    }

    case SET_DATA: {
      const { accounts, categories, profitAndLossRange } = payload;
      return {
        ...state,
        accounts,
        categories,
        currentEntity: null,
        profitAndLossRange,
        ...computePTBI(profitAndLossRange, categories, accounts),
        ...countAdjustments(categories, accounts)
      };
    }

    default:
      return state;
  }
}
