import { useTranslation } from 'react-i18next';

import { Box, Grid, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { DottedProgressProps } from './DottedProgress.proptype';

import { Step } from '../../../../models';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'inline-flex',
    width: 'auto',
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.primary.light,
    gap: theme.spacing(1.2),
    [theme.breakpoints.up('xl')]: {
      gap: theme.spacing(2)
    },
    flexWrap: 'nowrap',
    '& > *': {
      lineHeight: 0,
      cursor: 'pointer',
      transition: 'transform 100ms',
      '&:hover': {
        borderRadius: '50%',
        boxShadow: `0 0 0 ${theme.spacing(0.4)}px ${theme.palette.secondary.light}`
      }
    }
  },
  step: {
    display: 'inline-block',
    width: theme.spacing(1),
    height: theme.spacing(1),
    borderRadius: '100%',
    backgroundColor: theme.palette.primary.light,
    border: '1px solid',
    borderColor: theme.palette.action.focus,
    '&.completed': {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main
    },
    '&.in-progress': {
      backgroundColor: theme.palette.primary.main,
      boxShadow: `inset 0 0 0 ${theme.spacing(0.2)}px ${theme.palette.secondary.light}`
    },
    '&.all-completed': {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main
    }
  },
  tooltipStep: {
    marginRight: theme.spacing(0.5),
    verticalAlign: 'text-bottom'
  },
  tooltip: {
    padding: theme.spacing(1),
    background: theme.palette.common.white,
    boxShadow: theme.shadows[4],
    color: theme.palette.text.primary,
    cursor: 'pointer'
  }
}));

const DottedProgress = ({
  entityNumber,
  isUserReviewer,
  level,
  steps,
  completed,
  inProgress,
  onNavigate
}: DottedProgressProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const stepsWithStatuses = steps.map((step: Step) => ({
    status: completed.includes(step) ? 'completed' : inProgress.includes(step) ? 'in-progress' : '',
    id: step
  }));

  const filteredStepsWithStatus = stepsWithStatuses.filter((step) =>
    isUserReviewer ? step.status === 'completed' : true
  );

  return (
    <Grid container className={classes.container} role="list">
      {filteredStepsWithStatus.map(({ status, id }) => {
        return (
          <Grid key={id} item xs data-testid={`dotted-progress_${entityNumber}_${level}.${id}`}>
            <Box
              role="listitem"
              onClick={() => {
                onNavigate(id);
              }}
            >
              <Tooltip
                interactive
                title={
                  <Box display="flex" alignItems="center">
                    <Box className={`${classes.step} ${status} ${classes.tooltipStep}`} />
                    <Typography variant="body2">{t(id)}</Typography>
                  </Box>
                }
                enterDelay={200}
                classes={{ tooltip: classes.tooltip }}
                placement="bottom"
              >
                <Box
                  className={`${classes.step} ${status}`}
                  onClick={(evt) => {
                    onNavigate(id);
                    evt.stopPropagation();
                  }}
                />
              </Tooltip>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default DottedProgress;
