import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import {
  fetchFileMetadata,
  fetchFile,
  FileMetadata,
  setFileName,
  setIsFileDownloaded,
  setIsFileUploaded,
  setUploadManagerIsOpen,
  setUploadManagerCreditName,
  resetUploadManager
} from './uploadManager.actions';

import { containersOnCurrentSwitched } from '../containers';

export type UploadManagerState = {
  creditName?: string;
  isOpen: boolean;
  fileName: string | null;
  fileVersion: string | null;
  isFileUploaded: boolean;
  isFileDownloaded: boolean;
  fileMetadata: FileMetadata[];
  file: ArrayBuffer;
  isLoading: boolean;
  isLoaded: boolean;
  error: any;
};

export const uploadManagerInitialState: UploadManagerState = {
  creditName: undefined,
  isOpen: false,
  fileName: null,
  fileVersion: null,
  isFileUploaded: false,
  isFileDownloaded: false,
  fileMetadata: [],
  file: Buffer.from([]),
  isLoading: false,
  isLoaded: false,
  error: null
};

export const uploadManagerReducer = createReducer(uploadManagerInitialState, (builder) => {
  builder
    .addCase(setUploadManagerIsOpen, (state, action) => {
      return {
        ...state,
        isOpen: action.payload
      };
    })
    .addCase(setUploadManagerCreditName, (state, action) => {
      return { ...state, creditName: action.payload };
    })
    .addCase(setFileName, (state, action) => {
      return { ...state, fileName: action.payload };
    })
    .addCase(setIsFileUploaded, (state, action) => {
      return { ...state, isFileUploaded: action.payload };
    })
    .addCase(setIsFileDownloaded, (state, action) => {
      return { ...state, isFileDownloaded: action.payload };
    })
    .addCase(fetchFileMetadata.pending, (state) => {
      if (!state.isLoading) {
        return { ...state, isLoading: true };
      }

      return state;
    })
    .addCase(fetchFileMetadata.fulfilled, (state, action) => {
      const fileMetadata = action.payload;
      return {
        ...state,
        fileMetadata,
        error: null,
        isLoading: false
      };
    })
    .addCase(fetchFileMetadata.rejected, (state, action) => {
      return { ...state, error: action.payload, isLoading: false };
    })
    .addCase(fetchFile.pending, (state) => {
      if (!state.isLoading) {
        return { ...state, isLoading: true, isFileDownloaded: false };
      }

      return state;
    })
    .addCase(fetchFile.fulfilled, (state, action) => {
      const { file, fileVersion } = action.payload;
      return {
        ...state,
        file,
        fileVersion,
        error: null,
        isLoading: false,
        isFileDownloaded: true
      };
    })
    .addCase(fetchFile.rejected, (state, action) => {
      return { ...state, error: action.payload, isLoading: false, isFileDownloaded: false };
    })
    .addCase(resetUploadManager, () => {
      return { ...uploadManagerInitialState };
    })
    .addMatcher(isAnyOf(containersOnCurrentSwitched), () => {
      return uploadManagerInitialState;
    });
});
