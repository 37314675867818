import xbsPlatformModule from '@xbs/xbs-platform-module';

import { Provision } from '../../components/UserRoleStylesProvider/constants';
import { ConfigurationStateInterface } from '../../redux/configuration';

class PlatformService {
  _platformInstance: any;

  init(config: ConfigurationStateInterface) {
    const { xbsEnvironmentShort } = config;
    this._platformInstance = xbsPlatformModule.getInstance(Provision.Uuid, xbsEnvironmentShort);
    // Triggering the async getIdentitySession so it would be served from memory for future calls
    return this._platformInstance.IdentityInfo.getIdentitySession();
  }

  async switchContainers(containerUuid: string) {
    return this._platformInstance.Containers.switchContainers({ containerUuid });
  }

  async updateEula(eula: number) {
    return this._platformInstance.Eula.updateEula(eula);
  }

  async getIdentitySession() {
    return this._platformInstance.IdentityInfo.getIdentitySession();
  }
}

export default PlatformService;
