import { useState, useReducer } from 'react';

import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';

import TaxRateChip from './TaxRateChip/TaxRateChip';
import TotalBar from './TotalBar';
import { getTrendAnalysis } from './utils';

import { Table } from '../..';
import { GenericRecord } from '../../../calculations/utils';
import { Jurisdiction, Report } from '../../../models';
import { abbreviateNumber, formatNumber, formatPercentage } from '../../../utils';
import Slider from '../../Slider';
import { Row } from '../../Table/Table.proptype';
import { CHANGE_RATE, CHANGE_PTBI, makeInitialState, reducer, RESET } from '../reducer';

const gapColumn = { className: 'gap' };

const useStyles = makeStyles((theme) => {
  const spacing = theme.spacing(2);

  return {
    button: {
      borderRadius: theme.shape.borderRadius,
      height: '24px',
      width: '37px',
      color: theme.palette.common.white,
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,
      fontWeight: theme.typography.body2.fontWeight,
      letterSpacing: '0.19px',
      lineHeight: '24px',
      backgroundColor: theme.palette.info.dark
    },
    headerBody: {
      display: 'flex',
      flexDirecction: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '52px',
      padding: 'none'
    },
    ptbiInput: {
      backgroundColor: theme.palette.common.white,
      borderRadius: '4px',
      height: '36px',
      padding: '0px'
    },
    table: {
      '& .MuiOutlinedInput-input': {
        padding: '10px 0px 10px 18px'
      },
      '& .MuiTable-root': {
        '& .MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader:nth-child(2).MuiTableCell-head, .MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader:nth-child(4).MuiTableCell-head': {
          backgroundColor: theme.palette.common.white
        },
        // container for slider column
        '& .MuiTableCell-root.MuiTableCell-body:nth-child(3), .MuiTableCell-head:nth-child(3)': {
          backgroundColor: (theme.palette.background as any).comparison,
          border: 'none'
        }, // Tax Rate Driver Header Container
        '& .MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader:nth-child(3).MuiTableCell-head': {
          padding: '0px',
          backgroundColor: theme.palette.info.dark,
          borderRadius: '4px 4px 0 0'
        },
        '& .MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader:nth-child(3)': {
          '&.MuiTableCell-body, &.MuiTableCell-head': {
            paddingTop: '16.55px',
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.primary.main,
            marginTop: '10px'
          },
          '& .MuiTable-root.MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader:first-child': {
            backgroundColor: theme.palette.info.dark,
            borderRadius: '4px',
            display: 'inline-flex',
            padding: '20.45px 9px 7.55px 21px',
            width: '100%'
          },
          '&.MuiTableCell-head': {
            paddingTop: '0px',
            paddingBottom: '0px',
            '& .MuiBox-root': {
              backgroundColor: theme.palette.info.dark,
              borderRadius: '4px',
              display: 'inline-flex',
              padding: '8.45px 9px 7.55px 21px',
              width: '100%'
            },
            '& .MuiTypography-h3': {
              padding: '4px 14px 8px 0px',
              alignSelf: 'center',
              color: theme.palette.common.white,
              width: '167px'
            },
            '& .MuiOutlinedInput-root': {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
              width: '196px',
              height: '36px',
              marginLeft: '10px'
            }
          }
        }, // removes borders inside gap column
        '& .MuiTableCell-root.MuiTableCell-body.gap': {
          border: 'none'
        }, // Baseline Header
        '& .MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader:first-child': {
          padding: '0px',
          backgroundColor: theme.palette.secondary.dark,
          borderRadius: '4px',
          '& .MuiBox-root': {
            display: 'inline-flex',
            padding: '8.45px 302px 7.55px 16px',
            width: '100%'
          },
          '& .MuiTypography-h3': {
            padding: '4px 22px 8px 0px',
            alignSelf: 'center',
            color: theme.palette.text.primary
          }
        }, // Container for Output column headers
        '& .MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader:nth-child(5), .MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader:nth-child(6), .MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader:nth-child(7)': {
          paddingBottom: '0px',
          paddingTop: '0px',
          paddingLeft: '0px',
          paddingRight: '0px !important',
          backgroundColor: theme.palette.secondary.dark,
          justifyContent: 'flex-end',
          '& .MuiBox-root': {
            display: 'inline-flex',
            width: 'max-content',
            justifyContent: 'right'
          },
          '& .MuiTypography-h3': {
            paddingTop: '11.15px',
            paddingBottom: '10.25px'
          }
        },
        // Pre Tax Amount Header Container
        '& .MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader:nth-child(5)': {
          borderTopLeftRadius: '4px',
          borderBottomLeftRadius: '4px',
          '& .MuiTypography-h3': {
            paddingLeft: '40px',
            color: theme.palette.text.secondary
          }
        }, // Tax Effected Amount Container
        '& .MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader:nth-child(6)': {
          '& .MuiTypography-h3': {
            paddingLeft: '6px',
            paddingRight: '2px',
            color: theme.palette.text.secondary
          }
        }, // Tax Rate Header Container
        '& .MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader:nth-child(7)': {
          borderTopRightRadius: '4px',
          borderBottomRightRadius: '4px',
          '& .MuiTypography-h3': {
            paddingLeft: '55px',
            color: theme.palette.text.secondary
          }
        },
        '& .MuiTableCell-head': {
          textAlign: 'left'
        },
        flexGrow: 1
      },
      '& .MuiTableCell-alignRight': {
        textAlign: '-webkit-right',
        minWidth: '173px'
      },
      '& .MuiTableCell-body': {
        border: `1px solid ${theme.palette.divider}`,
        borderRightWidth: '0px !important',
        borderLeftWidth: '0px !important'
      }, // removes top of table cell borders
      '& .MuiTableCell-root.MuiTableCell-body': {
        borderTop: 'none',
        paddingTop: '3.5px',
        paddingBottom: '3.5px',
        height: '36px'
      },
      '& .MuiTableRow-root:not(:first-of-type)': {
        '& .MuiTableCell-root.MuiTableCell-body:not(:last-child).MuiTableCell-alignRight': {
          opacity: '80%'
        }
      },
      '& .MuiTableRow-root:first-of-type': {
        height: '57.95px',
        '& .MuiTableCell-root.MuiTableCell-body:nth-child(3)': {
          backgroundColor: theme.palette.info.dark,
          borderRadius: '0 0 4px 4px'
        },
        '& .MuiTableCell-root.MuiTableCell-body:nth-child(5), .MuiTableCell-root.MuiTableCell-body:nth-child(6)': {
          fontSize: theme.typography.h3.fontSize,
          fontWeight: theme.typography.h3.fontWeight
        }
      }
    },
    title: {
      color: theme.palette.text.primary,
      paddingBottom: theme.spacing(1)
    },
    etrTableContainer: {
      backgroundColor: theme.palette.common.white,
      border: '1px solid #D6D6D6',
      borderRadius: '4px',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      overflow: 'hidden'
    },
    etrTableInnerContainer: {
      margin: spacing,
      overflow: 'auto'
    },
    sliderContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    sliderAddIcon: {
      paddingLeft: '10px',
      color: '#83A9AD'
    },
    sliderRemoveIcon: {
      paddingRight: '10px',
      color: '#83A9AD'
    },
    rateDriverTaxRate: {
      fontFamily: theme.typography.h3.fontFamily,
      fontSize: theme.typography.h3.fontSize,
      border: 'none !important',
      width: '114.4px',
      height: '36px',
      textAlign: 'right',
      paddingTop: '6.6px !important',
      paddingBottom: '6.6px !important',
      paddingRight: '25px',
      backgroundColor: 'white'
    }
  };
});

const sliderDataRowsTitles = {
  permanent: 'Permanent Differences',
  stateBenefit: 'State Tax, Net of Federal Benefit',
  foreignRateDifferential: 'Foreign Rate Differential',
  credits: 'Tax Credits',
  allowance: 'Change in Valuation Allowance',
  deferredRate: 'Deferred Rate Change',
  deferred: 'Deferred Only Adjustments',
  returnToProvison: 'Return to Provision',
  federalTaxAdjustments: 'Federal Tax-Effected Adjustments'
};

const sum = (data: GenericRecord[], attribute: string): number => {
  let total = 0;
  for (const element of data) {
    total += element[attribute] || 0;
  }

  return total;
};

const BaselineHeader = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.headerBody}>
      <Typography variant="h3" component="h1" className={classes.title}>
        {t('Baseline')}
      </Typography>
    </Box>
  );
};

const RateDriverHeader = ({ onClick }: RateDriverHeaderProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.headerBody}>
      <Typography variant="h3" component="h1" className={classes.title}>
        {t('Tax Rate Driver')}
      </Typography>
      <Button className={classes.button} onClick={onClick}>
        {t('Reset')}
      </Button>
    </Box>
  );
};

const PreTaxAmountHeader = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.headerBody}>
      <Typography variant="h3" component="h1" className={classes.title}>
        {t('Pre Tax Amount')}
      </Typography>
    </Box>
  );
};

const TaxEffectedAmountHeader = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.headerBody}>
      <Typography variant="h3" component="h1" className={classes.title}>
        {t('Tax Effected Amount')}
      </Typography>
    </Box>
  );
};

const TaxRateHeader = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.headerBody}>
      <Typography variant="h3" component="h1" className={classes.title}>
        {t('Tax Rate (%)')}
      </Typography>
    </Box>
  );
};

type RateDriverHeaderProps = {
  onClick: (...args: any[]) => any;
};

type EtrAnalyzerProps = {
  jurisdictions: Jurisdiction[];
  worldwideReport?: Report;
};

const EtrAnalyzer = ({ jurisdictions, worldwideReport }: EtrAnalyzerProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const taxRate = jurisdictions[0].taxRates.current;

  const trendAnalysis = getTrendAnalysis(worldwideReport);

  const [isFocused, setIsFocused] = useState(false);

  const [
    {
      rows: { ptbi, ...sliderRows },
      sliders,
      sliderProps
    },
    dispatch
  ] = useReducer(reducer, { ...trendAnalysis, taxRate }, makeInitialState);

  // FIXME: any
  const handlePtbiChange = ({ target: { value: newValue } }: any) => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
    dispatch({ type: CHANGE_PTBI, payload: { ptbi: newValue } });
  };

  const handleTaxRateChange = (newValue: any, rowId: any) => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
    dispatch({ type: CHANGE_RATE, payload: { rowId, rate: newValue } });
  };

  const handleResetClick = () => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
    dispatch({ type: RESET });
  };

  const columns = [
    {
      renderHeader: () => <BaselineHeader />,
      field: 'name',
      width: '33%'
    },
    gapColumn,
    {
      renderHeader: () => <RateDriverHeader onClick={handleResetClick} />,
      field: 'rateDriver',
      width: '33%',
      renderCell(row: any) {
        return row.rateDriverType === 'ptbi' ? (
          <TextField
            fullWidth
            variant="outlined"
            placeholder={t('Add Pre-Tax Amount')}
            className={classes.ptbiInput}
            value={(isFocused ? ptbi.preTaxAmount : formatNumber(ptbi.preTaxAmount, 0, '')) || ''}
            onChange={handlePtbiChange}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsFocused(false);
            }}
          />
        ) : row.rateDriverType === 'slider' ? (
          <Box className={classes.sliderContainer}>
            <Remove className={classes.sliderRemoveIcon} />
            <Slider
              value={sliders[row.id]}
              startPoint={0}
              step={sliderProps[row.id].step}
              min={sliderProps[row.id].min}
              max={sliderProps[row.id].max}
              onChange={(newValue) => {
                handleTaxRateChange(newValue, row.id);
              }}
            />
            <Add className={classes.sliderAddIcon} />
          </Box>
        ) : (
          ''
        );
      }
    },
    gapColumn,
    {
      renderHeader: () => <PreTaxAmountHeader />,
      field: 'preTaxAmount',
      isNumber: true,
      shouldAbbreviate: true
    },
    {
      renderHeader: () => <TaxEffectedAmountHeader />,
      field: 'taxEffectedAmount',
      isNumber: true,
      shouldAbbreviate: true
    },
    {
      renderHeader: () => <TaxRateHeader />,
      field: 'taxRate',
      isPercentage: true,
      renderCell: (row: Row) => {
        return row.rateDriverType === 'ptbi' ? (
          <TableCell className={classes.rateDriverTaxRate}>{Math.round(row.taxRate * 10000) / 100}%</TableCell>
        ) : (
          <TaxRateChip valueToRender={Math.round(row.taxRate * 10000) / 100} valueToDetermineColor={sliders[row.id]} />
        );
      }
    }
  ];

  const sliderDataRows = [];

  for (const row of Object.keys(sliderRows)) {
    sliderDataRows.push({
      id: row,
      name: t(sliderDataRowsTitles[row as keyof typeof sliderDataRowsTitles]),
      preTaxAmount: sliderRows[row].preTaxAmount || 0,
      taxEffectedAmount: sliderRows[row].taxEffectedAmount,
      taxRate: sliderRows[row].taxRate,
      rateDriverType: 'slider'
    });
  }

  const taxEffectedAmount = abbreviateNumber(
    formatNumber(Number(sum(sliderDataRows, 'taxEffectedAmount')) + Number(ptbi.taxEffectedAmount ?? 0))
  );

  const totalTaxRate = abbreviateNumber(
    formatPercentage(Number(sum(sliderDataRows, 'taxRate')) + Number(ptbi.taxRate ?? 0))
  );

  const rows = [
    {
      name: t('Adjusted Pre-Tax Book Income / (Loss)'),
      preTaxAmount: ptbi.preTaxAmount,
      taxEffectedAmount: ptbi.taxEffectedAmount,
      taxRate: ptbi.taxRate,
      rateDriverType: 'ptbi'
    },
    ...sliderDataRows
  ];

  return (
    <>
      <Box className={classes.etrTableContainer}>
        <Box className={classes.etrTableInnerContainer}>
          <Table
            className={classes.table}
            columns={columns}
            rows={rows}
            isNotEditableShaded={false}
            renderOpts={{ zeroValue: '-' }}
            isBackgroundColorWhite={false}
          />
        </Box>
      </Box>
      <TotalBar totalTaxEffected={taxEffectedAmount} totalTaxRate={totalTaxRate} />
    </>
  );
};

export default EtrAnalyzer;
