import { useState } from 'react';

import { Box, Button, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  addText: {
    color: theme.palette.primary.main,
    padding: theme.spacing(0.5, 1),
    cursor: 'pointer'
  },
  inputAndButton: {
    height: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.25),
    padding: theme.spacing(0.5)
  }
}));

interface Props {
  onButtonClicked: (modificationName: string) => void;
}

const AddCustomModification = ({ onButtonClicked }: Props) => {
  const classes = useStyles();

  const [wasAddCustomModificationClicked, setWasCustomModificationClicked] = useState(false);
  const [customModification, setCustomModification] = useState('');

  return wasAddCustomModificationClicked ? (
    <Box className={classes.inputAndButton}>
      <Typography variant="h3">
        <TextField
          autoFocus
          error={customModification.length > 75}
          label="Custom Modification"
          variant="outlined"
          size="small"
          onChange={(event) => {
            setCustomModification(event.target.value);
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        />
      </Typography>
      <Button
        variant="contained"
        color="primary"
        disabled={customModification.length === 0 || customModification.length > 75}
        onClick={() => {
          setWasCustomModificationClicked(false);
          onButtonClicked(customModification.trim());
        }}
      >
        Done
      </Button>
    </Box>
  ) : (
    <Typography
      className={classes.addText}
      onClick={() => {
        setWasCustomModificationClicked(true);
      }}
    >
      + Add Custom Modification
    </Typography>
  );
};

export default AddCustomModification;
