import { useMemo } from 'react';

import {
  grossTemporaryDifferencesCreditName,
  grossFederalNolCreditName,
  taxCreditsCreditName
} from '../../components/EntityDetails/components/FederalDeferred/utils';
import { TaxPeriodShape } from '../../components/EntityDetails/models';
import { VALUATION_ALLOWANCE_ROW_NAME } from '../../components/EntityDetails/utils';

const useValuationAllowance = (rows: TaxPeriodShape[]) => {
  return useMemo(
    () => ({
      grossFederalNol:
        rows.find((row) => row.name === VALUATION_ALLOWANCE_ROW_NAME && row.creditName === grossFederalNolCreditName) ??
        {},
      grossTemporaryDifferences:
        rows?.find(
          (row: any) =>
            row.name === VALUATION_ALLOWANCE_ROW_NAME && row.creditName === grossTemporaryDifferencesCreditName
        ) ?? {},
      taxCredits:
        rows.find((row) => row.name === VALUATION_ALLOWANCE_ROW_NAME && row.creditName === taxCreditsCreditName) ?? {}
    }),
    [rows]
  );
};

export default useValuationAllowance;
