import { Level, Step } from '../models';

export const splitLevelStep = (pathname: string) => {
  const pathnameSplit = pathname.split('/');
  const level = pathnameSplit.slice(-2)[0] as Level;
  const step = pathnameSplit.slice(-1)[0] as Step;

  return { level, step };
};

export const colorRandomizer = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }

  return color;
};
