import { RootState } from '../../../..';
import { ATTRIBUTE_NAMES, COLUMNS, federalStepsByName, LEVELS } from '../../../../../constants';
import { FinancialInfo } from '../../../../../models';
import { selectEntitiesWithRates, selectJurisdictionsWithRatesById } from '../../../../../selectors';
import { FailureStrategy } from '../cellFailureStrategies.types';

export const StateTaxRateReconciliation: FailureStrategy = {
  shouldTest(finData: FinancialInfo) {
    return (
      finData.level === LEVELS.STATE &&
      finData.step === federalStepsByName.RTP &&
      finData.columnName === COLUMNS.taxProvision &&
      finData.rowName === ATTRIBUTE_NAMES.priorYearStateTaxRate
    );
  },

  test(finData: FinancialInfo, entityId: string, state: RootState) {
    const entities = selectEntitiesWithRates(state);
    const entity = entities.find((entity) => entity.entityId === entityId);
    const entityJurisdiction = selectJurisdictionsWithRatesById(state)[entity!.jurisdictionId];
    const subJurisdiction = entityJurisdiction.subJurisdictions.find(({ id }) => id === finData.jurisdictionId);
    const entityTaxRates = entity!.taxRates?.[finData.jurisdictionId ?? ''];
    const subJurTaxRates = subJurisdiction!.taxRates;
    const begTaxRate = entityTaxRates?.beginning ?? subJurTaxRates!.beginning;
    const error =
      begTaxRate === finData.value
        ? null
        : `This rate does not match ${
            subJurisdiction!.name
          } beginning tax rate in Entity Review; The Rate Reconciliation report will be off as a result.`;
    return error;
  },

  shouldSave() {
    return true;
  }
};
