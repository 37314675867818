import makeStyles from '@material-ui/core/styles/makeStyles';

import theme from '../../theme';

const buttonShared = (theme: any) => ({
  border: '1px solid',
  borderColor: theme.palette.primary.main,
  borderRadius: '4px',
  height: '36px',
  lineHeight: '18px',
  width: '85px'
});

export const useStyles = makeStyles(() => ({
  browseButton: {
    width: '180px',
    height: '37px',
    marginTop: '49px',
    marginBottom: '31px',
    marginLeft: '60px',
    marginRight: '60px',
    border: '1px solid',
    borderRadius: '4px',
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.body2.fontFamily,
    lineHeight: '18px'
  },
  importButton: {
    ...buttonShared(theme),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    fontFamily: theme.typography.body2.fontFamily,
    letterSpacing: '0.25px',
    borderColor: theme.palette.primary.main,
    '&:hover:disabled': {
      color: theme.palette.primary.main
    },
    '&:hover': {
      color: theme.palette.primary.main
    },
    '&:disabled': {
      color: theme.palette.common.white,
      cursor: 'not-allowed',
      pointerEvents: 'inherit'
    }
  },
  selectionBox: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(2)
  },
  buttonBox: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '45px',
    paddingTop: '13px'
  },
  dragDropText: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '5px',
    color: theme.palette.primary.main,
    fontSize: '16px',
    letterSpacing: '0.15px',
    lineHeight: '22px'
  },
  dragDropBox: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    width: '102px',
    height: '102px',
    marginTop: '60px',
    marginBottom: '17px',
    marginLeft: '99px',
    borderWidth: '1px',
    borderColor: theme.palette.info.dark,
    borderStyle: 'dashed',
    backgroundColor: theme.palette.info.main
  },
  fileTypeText: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.caption.fontFamily,
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.caption.fontWeight,
    letterSpacing: 0.6,
    lineHeight: '14px'
  },
  gridOn: {
    width: '38px',
    height: '38px',
    marginLeft: '31px',
    color: theme.palette.primary.main,
    stroke: theme.palette.info.main
  },
  rowCountChip: {
    alignItems: 'center',
    backgroundColor: theme.palette.text.secondary,
    borderRadius: '12px',
    color: theme.palette.common.white,
    display: 'flex',
    fontSize: '12px',
    height: '23px',
    justifyContent: 'center',
    marginRight: '12px',
    width: '115px'
  },
  spreadsheetBox: {
    height: '85%',
    marginLeft: '24px',
    '& #x-spreadsheet > div > div.x-spreadsheet-bottombar > ul > li:nth-child(1)': {
      display: 'none'
    },
    '& #x-spreadsheet > div > div.x-spreadsheet-bottombar': {
      height: '0px'
    },
    '& #x-spreadsheet > div > div.x-spreadsheet-bottombar > ul > li.active': {
      fontFamily: theme.typography.body2.fontFamily,
      letterSpacing: '0.25px',
      lineHeight: '18px',
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.info.main,
      borderRadius: '4px',
      border: '1px solid',
      borderColor: theme.palette.info.dark
    },
    '& #x-spreadsheet > div > div.x-spreadsheet-bottombar > ul > li': {
      fontFamily: theme.typography.body2.fontFamily,
      fontWeight: theme.typography.body2.fontWeight,
      fontSize: theme.typography.body2.fontSize,
      letterSpacing: '0.25px',
      lineHeight: '18px',
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.secondary.dark,
      borderRadius: '4px',
      border: '1px solid',
      borderColor: '#C5C5C9',
      marginTop: '13px',
      marginBottom: '-5px',
      marginRight: '24px',
      height: '28px',
      display: 'flex',
      alignItems: 'center'
    }
  },
  uploadBox: {
    backgroundColor: 'white',
    width: '300px',
    minHeight: '336px',
    border: '1px solid',
    borderColor: theme.palette.divider,
    borderRadius: '4px'
  },
  uploadContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    paddingTop: '43px'
  },
  uploadHeader: {
    color: theme.palette.text.dark,
    fontSize: theme.typography.h2.fontSize,
    fontFamily: theme.typography.h3.fontFamily,
    fontWeight: theme.typography.h3.fontWeight,
    lineHeight: '24px',
    paddingRight: '205px',
    paddingBottom: '10px'
  },
  toggleContainer: {
    marginRight: '12.5px',
    marginLeft: '-3px'
  },
  activeButton: {
    ...buttonShared(theme),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.info.main,
    fontFamily: theme.typography.body2.fontFamily,
    letterSpacing: '0.21px'
  },
  inactiveButton: {
    ...buttonShared(theme),
    color: theme.palette.primary.main,
    fontFamily: theme.typography.body2.fontFamily,
    letterSpacing: '0.25px'
  },
  validateSection: {
    height: 'fit-content',
    margin: 'auto',
    marginTop: '-83px'
  },
  titleContainer: {
    color: theme.palette.text.dark,
    display: 'flex'
  },
  chooseFileSection: {
    paddingRight: '33px',
    backgroundColor: theme.palette.secondary.lighter,
    '& div > table > thead > tr > th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader': {
      backgroundColor: theme.palette.secondary.lighter,
      borderColor: theme.palette.text.secondary
    },
    '& div > table > tbody > tr:nth-child(n) > td:nth-child(n)': {
      backgroundColor: theme.palette.secondary.lighter,
      borderBottom: `1px solid ${theme.palette.divider}`,
      paddingTop: '11px',
      paddingBottom: '12px'
    },
    '& div > table > tbody > tr > td > button': {
      textAlign: 'start',
      width: '285px',
      fontFamily: theme.typography.fontFamily,
      overflowWrap: 'break-word'
    },
    '& .MuiTableCell-body:not(:last-child)': {
      borderRight: 'none',
      paddingRight: '15px'
    },
    '& div > table > tbody > tr:nth-child(n) > td:last-child': {
      textAlign: 'right',
      color: theme.palette.text.secondary,
      fontFamily: theme.typography.fontFamily
    },
    '& div > table > thead > tr > th:last-child': {
      textAlign: 'right',
      fontFamily: theme.typography.fontFamily,
      lineHeight: '18px',
      paddingBottom: '0px'
    },
    '& div > table > thead > tr > th.MuiTableCell-root.MuiTableCell-head.filter.MuiTableCell-stickyHeader > div > div': {
      width: '206px',
      height: '36px',
      '& input': {
        height: '10px'
      }
    },
    '& div > table > thead > tr.MuiTableRow-root.makeStyles-colGroups-340.MuiTableRow-head': {
      display: 'flex',
      height: '24px'
    }
  },
  chooseFileHeader: {
    color: theme.palette.text.dark,
    fontSize: theme.typography.h2.fontSize,
    fontFamily: theme.typography.h3.fontFamily,
    fontWeight: theme.typography.h3.fontWeight,
    lineHeight: '24px',
    paddingRight: '136px',
    paddingBottom: '10px',
    paddingLeft: '13px'
  },
  chooseFileSubHeader1: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.h3.fontWeight,
    lineHeight: '24px',
    paddingRight: '136px',
    paddingBottom: '10px',
    marginTop: '-8px',
    paddingLeft: '14px'
  },
  chooseFileSubHeader2: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.h3.fontWeight,
    lineHeight: '24px',
    paddingRight: '136px',
    marginTop: '-8px',
    paddingLeft: '14px'
  }
}));
