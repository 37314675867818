import { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Button, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import SearchboxSelector from './components/SearchboxSelector';
import { SearchboxElementType } from './components/SearchboxSelector/SearchboxSelector';
import { useStyles } from './styles';

import { useCategories } from '../../../../hooks';
import { useAccountsCount } from '../../../../hooks/useAccountsEntityCount';
import { useCategoryCreate } from '../../../../hooks/useCategoryCreate';
import { CategoryForCreate } from '../../../../models';
import { selectAccountsEntityCount } from '../../../../selectors/accountsEntityCount';
import { selectCategories } from '../../../../selectors/categories';
import { colorRandomizer } from '../../../../utils/category';
import ColorPicker from '../../../ColorPicker';
import SearchBox from '../../../SearchBox';
import { DATA_TYPES, DataSelectionType } from '../../sharedCategoryDrawerUtils';

export interface NewCategoryDrawerProps {
  containerName?: string;
  isOpen?: boolean;
  onClose?: () => void;
}

const NewCategoryDrawer: React.FC<NewCategoryDrawerProps> = ({
  isOpen,
  onClose = () => null
}: NewCategoryDrawerProps) => {
  const classes = useStyles();
  const { createCategory } = useCategoryCreate();
  const { isColorUnique, isNameUnique } = useCategories();
  const accountsEntityCountData = useSelector(selectAccountsEntityCount);
  const { getUseAccountsCount } = useAccountsCount();
  const { t } = useTranslation();
  const [categoryName, setCategoryName] = useState<string>('');
  const [color, setColor] = useState<string>('');
  const [accounts, setAccounts] = useState<SearchboxElementType[]>([]);
  const [selectedAccountsEntityCount, setSelectedAccountsEntityCount] = useState<SearchboxElementType[]>([]);
  const [selectedDataType, setSelectedDataType] = useState<DataSelectionType>();
  const [dataTypes] = useState<DataSelectionType[]>(DATA_TYPES);
  const [accountsStepNames, setAccountsStepNames] = useState<{ [key: string]: string }>({});
  const categoriesData = useSelector(selectCategories);

  useEffect(() => {
    const generateRandomColor = () => {
      let randomColor;
      do {
        randomColor = colorRandomizer();
      } while (!isColorUnique(randomColor));

      return randomColor;
    };

    setColor(generateRandomColor());
  }, [isColorUnique]);

  useEffect(() => {
    const parsedSearchboxElements: SearchboxElementType[] = [];
    const stepNames: { [key: string]: string } = {};
    const currentStepName = selectedDataType ? `${selectedDataType.level}.${selectedDataType.step}` : '';

    for (const { accountNumber, accountName, entityCount, stepName } of accountsEntityCountData.accounts) {
      const foundCategory = categoriesData.categories.find((category) => {
        const foundAccount = category.accounts.find(({ attributeName, stepName }) => {
          const stepNameFragments = stepName.split('.');
          const reducedStepName = `${stepNameFragments[0]}.${stepNameFragments[1]}`;
          if (reducedStepName === 'federal.temporary') {
            return attributeName === accountName && reducedStepName === currentStepName;
          }

          return attributeName === accountName && stepName === currentStepName;
        });
        return Boolean(foundAccount);
      });

      if (!foundCategory) {
        parsedSearchboxElements.push({
          name: accountName,
          count: entityCount,
          number: accountNumber
        });
        stepNames[accountName] = stepName;
      }
    }

    setAccounts(parsedSearchboxElements);
    setAccountsStepNames(stepNames);
  }, [accountsEntityCountData, categoriesData, selectedDataType]);

  useEffect(() => {
    if (!isOpen) {
      setCategoryName('');
      // eslint-disable-next-line unicorn/no-useless-undefined
      setSelectedDataType(undefined);
      setSelectedAccountsEntityCount([]);
    }
  }, [isOpen]);

  const onSelectAccountsEntityCount = (selected: SearchboxElementType[]) => {
    setSelectedAccountsEntityCount(selected);
  };

  const onColorSelected = (selectedColor: string) => {
    setColor(selectedColor);
  };

  const onChangeDataType = useCallback(
    async (_: any, selected: DataSelectionType) => {
      if (selected) {
        await getUseAccountsCount(`${selected.level}.${selected.step}`);
      }

      setSelectedDataType(selected);
      setSelectedAccountsEntityCount([]);
    },
    [getUseAccountsCount]
  );

  const onSave = useCallback(async () => {
    if (isNameUnique(categoryName) && isColorUnique(color)) {
      const selectedDataTypeStepName: string = selectedDataType
        ? `${selectedDataType.level}.${selectedDataType.step}`
        : '';
      const categoryToCreate: CategoryForCreate = {
        categoryName,
        categoryColor: color,
        categoryAccounts:
          selectedAccountsEntityCount.map(({ name }) => ({
            attributeName: name,
            stepName: accountsStepNames[name] ?? selectedDataTypeStepName
          })) ?? []
      };
      await createCategory(categoryToCreate);
      onClose();
    }
  }, [
    isNameUnique,
    categoryName,
    isColorUnique,
    color,
    selectedAccountsEntityCount,
    createCategory,
    onClose,
    accountsStepNames,
    selectedDataType
  ]);

  return (
    <Drawer open={isOpen} className={classes.drawer} anchor="right">
      <Box className={classes.header}>
        <Box className={classes.title}>
          <Typography variant="h3">{t('new-category-setup')}</Typography>
        </Box>
        <Box className={classes.closeButton}>
          <IconButton role="button" onClick={onClose}>
            <img src="/imgs/panel_close.svg" />
          </IconButton>
        </Box>
      </Box>
      <Box className={classes.container}>
        <Box className={classes.row}>
          <Box className={classes.categoryName}>
            <Typography variant="h3" className={classes.fileNameText}>
              {t('Category Name')}
            </Typography>
            <TextField
              fullWidth
              placeholder={t('Add Name')}
              autoComplete="off"
              variant="outlined"
              size="small"
              value={categoryName}
              onChange={({ target: { value } }) => {
                setCategoryName(value);
              }}
            />
          </Box>

          <Box>
            <Typography variant="h3" className={classes.fileNameText}>
              {t('Color')}
            </Typography>
            <ColorPicker categoryColor={color} onSelect={onColorSelected} />
          </Box>
        </Box>
        <Box className={classes.tagged}>
          <Typography variant="h3" className={classes.fileNameText}>
            {t('Automatic tagging')}
          </Typography>
          <Box>
            <SearchBox
              options={dataTypes}
              className={classes.dataType}
              placeholder={t('Select Data Type (eg. Temporary Adjustments)')}
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              getOptionLabel={({ name }: any) => `${name}`}
              disabled={!categoryName?.trim() || accountsEntityCountData.isLoading}
              onChange={onChangeDataType}
            />
          </Box>
          <Box>
            <SearchboxSelector
              isDisabled={!categoryName?.trim() || !selectedDataType}
              placeholder={t('Select Account Description')}
              options={accounts}
              isLoading={accountsEntityCountData.isLoading}
              value={selectedAccountsEntityCount}
              onSelect={onSelectAccountsEntityCount}
            />
          </Box>
        </Box>
        <Box className={classes.taggedAccounts}>
          <Box>
            <span>
              {selectedAccountsEntityCount?.length ?? 0} {t('Tagged Items')}
            </span>
          </Box>
          <Box>{t('Entities')}</Box>
        </Box>
        <Box className={classes.scrollContainer}>
          {selectedAccountsEntityCount?.map(({ number, name, count }: SearchboxElementType) => (
            <Box key={name} className={classes.taggedAccountElement}>
              <Box>{number}</Box>
              <Box>{name}</Box>
              <Box>x{count}</Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box className={classes.footer}>
        <Box>
          <Button variant="text" onClick={onClose}>
            <b>{t('Cancel')}</b>
          </Button>
          <Button
            variant="outlined"
            disabled={
              dataTypes.length === 0 ||
              selectedAccountsEntityCount.length === 0 ||
              !categoryName?.trim() ||
              !isNameUnique(categoryName) ||
              !isColorUnique(color)
            }
            onClick={onSave}
          >
            {t('Save')}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default NewCategoryDrawer;
