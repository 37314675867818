import { createReducer } from '@reduxjs/toolkit';

import { onFetchingJurisdictions, onJurisdictionsFailed, onJurisdictionsReceived } from './jurisdictions.actions';
import { jurisdictionsInitialState } from './jurisdictions.initialState';

import { addIdToJurisdictions, turnToObjById } from '../../utils';

export const jurisdictionsReducer = createReducer(jurisdictionsInitialState, (builder) => {
  builder
    .addCase(onFetchingJurisdictions, (state) => {
      return { ...state, isLoading: true, error: null };
    })
    .addCase(onJurisdictionsReceived, (state, action) => {
      const jurisdictions = addIdToJurisdictions(action.payload);

      return {
        ...state,
        jurisdictions,
        jurisdictionById: turnToObjById(jurisdictions),
        hasLoaded: true,
        isLoading: false,
        error: null
      };
    })
    .addCase(onJurisdictionsFailed, (_state, action) => {
      return { ...jurisdictionsInitialState, hasLoaded: true, isLoading: false, error: action.payload };
    });
});
