import { Box, Button, makeStyles } from '@material-ui/core';

import { ReactComponent as LinkIcon } from '../../../../assets/img/Icon_Link.svg';
import { ReactComponent as SelectedTickIcon } from '../../../../assets/img/SelectedTickIcon.svg';

const commonButtonStyles = (theme: any) => ({
  border: `1px solid ${theme.palette.divider as string}`,
  borderRadius: '4px',
  cursor: 'pointer',
  display: 'flex',
  fontFamily: 'Basis Grotesque Pro Medium',
  justifyContent: 'space-between',
  height: '36px',
  margin: '4px 8px 4px 0px',
  width: '173px'
});

const useStyles = makeStyles((theme) => ({
  button: {
    ...commonButtonStyles(theme),
    backgroundColor: theme.palette.secondary.dark,
    margin: '4px 8px 4px 0px',
    '& *': {
      color: theme.palette.text.primary,
      fontWeight: 600
    },
    '& button:hover': {
      backgroundColor: theme.palette.secondary.dark
    }
  },
  buttonActive: {
    ...commonButtonStyles(theme),
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.common.white,
    margin: '4px 8px 4px 0px',
    '& *': {
      color: theme.palette.common.white,
      fontWeight: 600
    }
  },
  reduxValue: {
    alignItems: 'center',
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: '4px',
    cursor: 'pointer',
    width: '173px',
    height: '36px',
    backgroundColor: theme.palette.info.main,
    display: 'flex',
    fontFamily: 'Basis Grotesque Pro Medium',
    justifyContent: 'left',
    margin: '4px 8px 4px 0px',
    padding: '10px 12px',
    '& *': {
      color: theme.palette.primary.main,
      fontWeight: 600
    },
    '& button:hover': {
      backgroundColor: theme.palette.secondary.dark
    }
  }
}));

interface SelectionButtonProps {
  buttonText: string;
  isActive: boolean;
  handleEvent?: () => void;
  reduxValue?: string;
}

const SelectionButton = ({ handleEvent, buttonText, isActive, reduxValue }: SelectionButtonProps) => {
  const classes = useStyles();
  const linkIcon = <LinkIcon />;
  const checkIcon = <SelectedTickIcon />;

  return (
    <Box style={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        className={isActive ? classes.buttonActive : classes.button}
        onClick={() => {
          handleEvent?.();
        }}
      >
        <Button style={{ lineHeight: '16px', textAlign: 'left' }}>{buttonText}</Button>
        <Box style={{ alignItems: 'center', display: 'flex', marginRight: '10px' }}>
          {reduxValue ? checkIcon : linkIcon}
        </Box>
      </Box>
      {reduxValue && <Box className={classes.reduxValue}>{reduxValue}</Box>}
    </Box>
  );
};

export default SelectionButton;
