import { useEffect, useState, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Table } from '../../..';
import { SubJurisdiction } from '../../../../models';
import { selectDoesUserHaveRole } from '../../../../selectors';
import { OUTLINED } from '../../../Table/utils';
import { ProReadOnly } from '../../../UserRoleStylesProvider/constants';

const DISABLED_SELECTORS_MS = 1200;
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    '& .MuiTable-root': {
      '& .MuiTableCell-root, .selected .MuiTableCell-root.MuiTableCell-body.checkbox, .MuiTableRow-hover:hover .MuiTableCell-root.MuiTableCell-body.checkbox': {
        backgroundColor: theme.palette.background.paper
      },
      '& .MuiTableRow-hover:hover .MuiTableCell-root.MuiTableCell-body': {
        backgroundColor: theme.palette.action.selected
      }
    }
  }
}));

export type ApplicableStatesSelectorProps = {
  appliedSubJurisdictionIds?: Array<SubJurisdiction['id']>;
  states: SubJurisdiction[];
  onChange?: (selectedRows: SubJurisdiction[]) => void;
};

const ApplicableStatesSelector = ({ appliedSubJurisdictionIds, states, onChange }: ApplicableStatesSelectorProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selected, setSelected] = useState<SubJurisdiction[]>([]);
  const [filters, setFilters] = useState(new Map());
  const isUserReadOnly = useSelector(selectDoesUserHaveRole([ProReadOnly.Name]));
  const [selectorsDisabled, setSelectorsDisabled] = useState<boolean>(isUserReadOnly);

  const columns = [
    {
      field: 'name',
      placeholder: t('Search state list'),
      filterable: true
    }
  ];
  const rows = useMemo(() => (states.length > 0 ? states : []), [states]);

  useEffect(() => {
    setSelected(rows.filter(({ id }) => (appliedSubJurisdictionIds ?? []).includes(id)));
    setFilters(new Map());
  }, [rows, appliedSubJurisdictionIds, onChange]);

  function handleSelection(selectedRows: SubJurisdiction[], areSelected: boolean) {
    setSelectorsDisabled(true);
    setSelected(areSelected ? selected.filter((row) => !selectedRows.includes(row)) : [...selected, ...selectedRows]);
    onChange?.(selectedRows);
    setTimeout(() => {
      setSelectorsDisabled(false);
    }, DISABLED_SELECTORS_MS);
  }

  return (
    <Box className={classes.root}>
      <Table
        isSelectable
        selectorsDisabled={selectorsDisabled}
        variant={OUTLINED}
        columns={columns}
        rows={rows}
        selected={selected}
        filters={filters}
        onSelect={handleSelection}
      />
    </Box>
  );
};

export default ApplicableStatesSelector;
