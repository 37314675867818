import { Dispatch, SetStateAction } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@material-ui/core';
import { ArrowNavigationTabsBar, ArrowNavigationTab } from '@xbs/xbs-common-ui';

import { useJurisdictions } from '../../../hooks';
import { Level, Step } from '../../../models';
import {
  selectDataImportSelection,
  selectSubJurisdictionSidemenuSelectedId,
  selectUploadManagerCreditName
} from '../../../selectors';
import { capitalize } from '../../../utils';
import { dispatchWarning, translateCreditName } from '../../Spreadsheet/utils';
import { TabName, dataTab, validateTab } from '../FileUpload';
import { useStyles } from '../FileUpload.styles';
import { isValidateTabEnabled } from '../utils';

interface Props {
  activeTab: string;
  entityName: string;
  keyHeaderNames: string[];
  mapToStepTitle: Record<string, string>;
  numberOfDataImportRows: number[];
  numberOfValidateImportRows: number;
  setActiveTab: Dispatch<SetStateAction<TabName>>;
  level: Level;
  step: Step;
}

const FileUploadHeader = ({
  activeTab,
  entityName,
  keyHeaderNames,
  mapToStepTitle,
  numberOfDataImportRows,
  numberOfValidateImportRows,
  setActiveTab,
  level,
  step
}: Props) => {
  const classes = useStyles({ activeTab: dataTab });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dataImportSelection = useSelector(selectDataImportSelection);
  const creditName = useSelector(selectUploadManagerCreditName);
  const subJurisdictionId = useSelector(selectSubJurisdictionSidemenuSelectedId);
  const stateName = useJurisdictions().subJurisdictionById[subJurisdictionId!]?.name;
  const noSelectionHasBeenMade = Boolean(dataImportSelection) && Object.keys(dataImportSelection).length === 0;
  const canAccessValidateTab = isValidateTabEnabled({
    noSelectionHasBeenMade,
    dataImportSelection,
    keyHeaderNames,
    numberOfDataImportRows,
    step
  });
  const creditNameString = creditName === undefined ? '' : ` - ${translateCreditName(creditName)}`;
  const stepTitle = `${mapToStepTitle[step] ?? ''}${creditNameString}`;
  const stateTitle = subJurisdictionId ? ` - ${stateName}` : '';
  const shouldShowDataRows = numberOfDataImportRows !== undefined && activeTab === validateTab;

  const handleDataTabClick = () => {
    setActiveTab(dataTab);
  };

  const handleValidateTabClick = () => {
    if (canAccessValidateTab) {
      setActiveTab(validateTab);
      return;
    }

    if (keyHeaderNames.length > 0) {
      let columnFormat = `'${keyHeaderNames.join(' ')}'`;

      if (keyHeaderNames.length > 1) {
        columnFormat = `'${keyHeaderNames.join("' '")}'`;
      }

      // Add per-tab required fields here
      dispatchWarning(
        step === 'credits'
          ? `Columns 'Credit Name' and ${columnFormat} must be selected`
          : `Column ${columnFormat} must be selected`,
        dispatch
      );
    } else {
      dispatchWarning('Please enter at least one selection', dispatch);
    }
  };

  const tabs: ArrowNavigationTab[] = [
    {
      key: dataTab,
      label: 'Data',
      onTabClick: handleDataTabClick,
      disabled: false
    },
    {
      key: validateTab,
      label: 'Validate',
      onTabClick: handleValidateTabClick,
      disabled: false
    }
  ];

  return (
    <Box className={classes.buttonBox} id="button-box-for-import-button">
      <Box className={classes.selectionBox}>
        <Box className={classes.titleContainer}>
          <Box style={{ fontSize: '16px' }}>{entityName} </Box>
          <Box style={{ lineHeight: '24px', marginLeft: '8px', opacity: 0.7 }}>
            {t(capitalize(level))} - {t(stepTitle)}
            {t(stateTitle)}
          </Box>
        </Box>
      </Box>
      {shouldShowDataRows && <Box className={classes.rowCountChip}>{numberOfValidateImportRows} DATA ROWS</Box>}
      <ArrowNavigationTabsBar tabs={tabs} selectedTab={activeTab} />
    </Box>
  );
};

export default FileUploadHeader;
