import { PropsWithChildren } from 'react';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { AuditTrailSearchFocus } from '../../../../../../../constants';
import SearchFocusOption from '../SearchFocusOption';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '21px',
    paddingBottom: '30px',
    backgroundColor: theme.palette.common.white
  },
  searchFocus: {
    width: '100%'
  },
  searchFocusOptions: {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
    width: '100%',
    marginTop: '13px'
  },
  title: {
    color: theme.palette.text.secondary,
    fontFamily: 'Basis Grotesque Pro Medium',
    letterSpacing: '0.25px',
    fontSize: '12px'
  },
  optionsContainer: {
    overflowY: 'hidden'
  }
}));

type TopSearchPopoverLayoutProps = {
  searchFocus: keyof typeof AuditTrailSearchFocus;
  onSearchFocusChange: (val: keyof typeof AuditTrailSearchFocus) => void;
  showingRecentSearches: boolean;
};

const TopSearchPopoverLayout = ({
  children,
  searchFocus,
  showingRecentSearches,
  onSearchFocusChange
}: PropsWithChildren<TopSearchPopoverLayoutProps>) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper
      tabIndex={0}
      className={classes.container}
      onMouseDown={(event) => {
        event.preventDefault();
      }}
    >
      <Box className={classes.searchFocus}>
        <Typography className={classes.title}>{t('search-focus').toUpperCase()}</Typography>
        <Box className={classes.searchFocusOptions}>
          <SearchFocusOption
            text={t('all')}
            selected={searchFocus === AuditTrailSearchFocus.all}
            onClick={() => {
              onSearchFocusChange(AuditTrailSearchFocus.all);
            }}
          />
          <SearchFocusOption
            text={t('tabs')}
            selected={searchFocus === AuditTrailSearchFocus.tabs}
            onClick={() => {
              onSearchFocusChange(AuditTrailSearchFocus.tabs);
            }}
          />
          <SearchFocusOption
            text={`${t('account-no')}.`}
            selected={searchFocus === AuditTrailSearchFocus.accountNumbers}
            onClick={() => {
              onSearchFocusChange(AuditTrailSearchFocus.accountNumbers);
            }}
          />
          <SearchFocusOption
            text={t('adjustments')}
            selected={searchFocus === AuditTrailSearchFocus.adjustments}
            onClick={() => {
              onSearchFocusChange(AuditTrailSearchFocus.adjustments);
            }}
          />
          <SearchFocusOption
            text={t('categories')}
            selected={searchFocus === AuditTrailSearchFocus.categories}
            onClick={() => {
              onSearchFocusChange(AuditTrailSearchFocus.categories);
            }}
          />
        </Box>
      </Box>
      <Typography className={classes.title} style={{ marginBottom: '10px', marginTop: '18px' }}>
        {t(showingRecentSearches ? 'recent-searches' : 'results').toUpperCase()}
      </Typography>
      <Box className={classes.optionsContainer}>{children}</Box>
    </Paper>
  );
};

export default TopSearchPopoverLayout;
