import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  title: {
    padding: theme.spacing(2),
    display: 'flex'
  }
}));

interface Props {
  tab: 'entities' | 'categories';
}

const EntityHeader = ({ tab }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <Typography variant="h2" component="h1" className={classes.title}>
        {t('Entity Details')}
      </Typography>
      <Tabs indicatorColor="primary" textColor="primary" value={tab}>
        <Tab label={t('Entities')} value="entities" component={Link} to="/entity-details" />
        <Tab label={t('data-categories')} value="categories" component={Link} to="/entity-details/categories" />
      </Tabs>
    </>
  );
};

export default EntityHeader;
