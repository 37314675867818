import { FedTaxRateReconciliation } from './fedTaxRateReconciliation/fedTaxRateReconciliation';
import { StateTaxRateReconciliation } from './stateTaxRateReconciliation/stateTaxRateReconciliation';

import { RootState } from '../../..';
import { FinancialInfo } from '../../../../models';

const failureStrategies = [FedTaxRateReconciliation, StateTaxRateReconciliation];

export const testForFailure = (
  finData: FinancialInfo,
  entityId: string,
  state: RootState
): { customError: string | null; shouldSave: boolean } => {
  const toRun = failureStrategies.find((strategy) => strategy.shouldTest(finData));
  const customError = toRun ? toRun.test(finData, entityId, state) : null;
  const shouldSave = toRun ? toRun.shouldSave() : true;
  return { customError, shouldSave };
};
