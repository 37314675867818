import { Typography, TypographyTypeMap } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
  colorBox: {
    padding: theme.spacing(0.5, 1),
    background: theme.palette.info.main,
    borderRadius: 2,
    textAlign: 'center',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  oneLine: {
    whiteSpace: 'pre'
  }
}));

type BoxedTextProps = {
  children: TypographyTypeMap['props']['children'];
  className?: string;
  isOneLine?: boolean;
};

export const BoxedText = ({ children, className, isOneLine = false }: BoxedTextProps) => {
  const classes = useStyles();
  return (
    <Typography
      variant="caption"
      className={classnames(classes.colorBox, className, {
        [classes.oneLine]: isOneLine
      })}
    >
      {children}
    </Typography>
  );
};

export default BoxedText;
