import { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { CircularProgress } from '@material-ui/core';

import Eula from '../../components/Eula';
import { AUTH_REFRESH_TIME_WINDOW_MILLISECONDS, AUTH_REFRESH_CHRON_KEY } from '../../constants';
import { useEula } from '../../hooks/useEula';
import { setAuthTokens } from '../../redux/auth';
import { selectIsAuthenticated, selectAuthInfo } from '../../selectors';
import ChronService from '../../services/chron';
import HTTPService from '../../services/http';
import LocationService from '../../services/location';

interface AuthenticateProps {
  children: JSX.Element;
}

const Authentication = ({ children }: AuthenticateProps) => {
  const dispatch = useDispatch();
  const { expiration } = useSelector(selectAuthInfo);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const { accepted: isEulaAccepted, isLoading: isEulaStatusLoading, isLoaded: isEulaStatusLoaded } = useEula();

  const shouldRenderLoading = !isAuthenticated || !isEulaStatusLoaded || isEulaStatusLoading;
  const shouldRedirectToEula = !isEulaAccepted;

  /* pre-emptively refresh the token 20 min before expiration */
  useEffect(() => {
    const refreshToken = async () => {
      const newTokens = await HTTPService.refreshToken();
      dispatch(setAuthTokens(newTokens));
    };

    if (!ChronService.getChron(AUTH_REFRESH_CHRON_KEY) && expiration) {
      const now = new Date();

      const nextRefreshMilliseconds = expiration.getTime() - AUTH_REFRESH_TIME_WINDOW_MILLISECONDS;

      if (nextRefreshMilliseconds < now.getTime()) {
        void refreshToken();
      } else {
        ChronService.addTimeout(AUTH_REFRESH_CHRON_KEY, refreshToken, nextRefreshMilliseconds - now.getTime());
      }
    }
  }, [expiration, dispatch]);

  useEffect(() => {
    if (!isAuthenticated) {
      LocationService.login();
    }
  }, [isAuthenticated]);

  return shouldRenderLoading ? <CircularProgress /> : shouldRedirectToEula ? <Eula /> : children;
};

export default Authentication;
