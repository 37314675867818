import { TFunction } from 'react-i18next';

import { Grid, Box, Typography, makeStyles } from '@material-ui/core';

import { Container } from '../../../models';
import { formatDateShort } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  periodBeginning: {
    color: theme.palette.text.secondary,
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  startDate: {
    color: theme.palette.primary.main
  }
}));

interface Props {
  currentContainer: Container;
  t: TFunction;
}

const PeriodBeginningItem = ({ currentContainer, t }: Props) => {
  const classes = useStyles();

  return (
    <Grid item>
      <Box mr={4}>
        <Typography variant="caption">
          <span className={classes.periodBeginning}>{t('Period Beginning')}</span>
          <span className={classes.startDate}>{t(formatDateShort(currentContainer.startDate))}</span>
        </Typography>
      </Box>
    </Grid>
  );
};

export default PeriodBeginningItem;
