import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { CircularProgress } from '@material-ui/core';
import axios from 'axios';

import { setRoleData } from '../../redux/auth';
import { setConfigData } from '../../redux/configuration';
import { selectConfiguration } from '../../selectors/configuration';
import PlatformService from '../../services/platform';

interface ConfigurationProps {
  children: JSX.Element;
}

const Configuration = ({ children }: ConfigurationProps) => {
  const dispatch = useDispatch();
  const configuration = useSelector(selectConfiguration);

  useEffect(() => {
    const hasConfig = Object.keys(configuration).length;
    if (!hasConfig) {
      (async () => {
        const { data: config } = await axios.get(`${window.location.origin}/configuration.json`);
        dispatch(setConfigData(config));
        const platformResponse = await PlatformService.init(config);
        const { roleId, roleUuid } = platformResponse;
        dispatch(setRoleData({ roleId, roleUuid }));
      })();
    }
  }, [configuration, dispatch]);

  const shouldRenderApp = Object.keys(configuration).length > 0;

  return shouldRenderApp ? children : <CircularProgress />;
};

export default Configuration;
