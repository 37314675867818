import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as d3 from 'd3';

import { Column, Row } from '../../../../models';
import Table from '../../../Table'; // don't use components/index.js to avoid circular dep
import { TableProps } from '../../../Table/Table.proptype';
import { renderValue, getCell } from '../../../Table/utils';
import { totalColor } from '../TotalProvision';

const useStyles = makeStyles((theme) => ({
  barCell: {
    width: '100%',
    position: 'relative',
    height: '32px'
  },
  bar: {
    position: 'absolute',
    opacity: 0.8,
    backgroundColor: totalColor,
    bottom: 0,
    top: ({ singleBarPlot }: Partial<Props>) => (singleBarPlot ? '25%' : '0'),
    height: ({ barHeight }: Partial<Props>) => barHeight,
    '&:nth-child(2)': {
      backgroundColor: 'rgba(232,247,247,0.7)',
      top: '33.333%',
      border: `1px solid #45D1AA`
    },
    '&:nth-child(3)': {
      backgroundColor: 'rgba(229,243,250,0.7)',
      top: '66.666%',
      border: `1px solid #409BC7`
    }
  },
  barHeader: {
    width: '100%',
    position: 'relative'
  },
  tick: {
    position: 'absolute',
    bottom: 0,
    color: theme.palette.text.hint,
    '& > span': {
      position: 'relative',
      left: '-50%',
      backgroundColor: theme.palette.common.white
    },
    '&::after': {
      display: 'block',
      content: '""',
      height: 10,
      width: 1,
      backgroundColor: theme.palette.divider
    }
  },
  tickZero: {
    position: 'relative',
    bottom: 0,
    color: theme.palette.text.hint,
    '& > span': {
      position: 'relative',
      left: '-97.5%',
      bottom: '10px',
      backgroundColor: theme.palette.primary.light,
      padding: '5px 7px',
      width: '15px',
      height: '15px'
    },
    '&::after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      height: ({ rows }: Partial<Props>) => `calc(61.8px * ${(rows?.length ?? 2) - 1 ?? 1})`,
      borderLeft: `1px dashed ${theme.palette.divider}`,
      transform: 'translateY(-10px)',
      width: 0
    }
  },
  table: (props: Partial<Props>) => ({
    overflowX: 'hidden',
    '& .MuiTableCell-body.plot': {
      padding: theme.spacing(1.2, 1.5)
    },
    '& .MuiTableCell-head.plot': {
      padding: theme.spacing(0, 1.5),
      verticalAlign: 'bottom'
    },
    '& .MuiTableCell-root': {
      border: props.showCellBorders ? `0.5px solid ${theme.palette.divider}` : 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderRightWidth: '0px',
      borderLeftWidth: '0px',
      fontWeight: 400
    },
    '& .MuiTableCell-root:not(.MuiTableCell-head):not(:last-child)': {
      color: theme.palette.text.primary,
      opacity: 0.8
    },
    '& .MuiTableCell-root:first-child': {
      fontFamily: '"Basis Grotesque Pro Medium", sans-serif',
      paddingLeft: '0px'
    },
    '& .MuiTableCell-root:not(:first-child)': {
      fontFamily: '"Basis Grotesque Pro Regular", sans-serif'
    },
    '& .MuiTableCell-root:last-child': {
      border: props.showCellBorders ? `0.5px solid ${theme.palette.divider}` : 'none',
      borderRightWidth: '0px'
    },
    '& .MuiTableCell-root.MuiTableCell-head': {
      borderTop: 'none'
    },
    '& .MuiTableCell-body:not(:last-child)': {
      paddingRight: '30px'
    },
    '& .MuiTableRow-root:last-child > td': {
      borderBottomWidth: '0px'
    },
    '& .MuiTableCell-head:last-child, & .MuiTableCell-body:last-child': {
      paddingLeft: '32px',
      paddingRight: '32px'
    }
  })
}));

interface Props extends TableProps {
  rows: Row[];
  columns: Column[];
  showCellBorders?: boolean;
  barHeight?: string;
  singleBarPlot?: boolean;
}

const TableWithPlot = ({
  barHeight,
  columns,
  rows,
  showCellBorders = true,
  singleBarPlot = false,
  ...props
}: Props) => {
  const classes = useStyles({ barHeight, rows, showCellBorders, singleBarPlot });
  const [displayedRows, setDisplayedRows] = useState(rows);

  useEffect(() => {
    setDisplayedRows(rows);
  }, [rows]);

  const renderedColumns = useMemo(() => {
    const result = [];
    for (const column of columns) {
      if (Array.isArray(column.series)) {
        let min = 0;
        let max = 0;
        for (const row of displayedRows) {
          for (const series of column.series) {
            const value = getCell(row, series) || 0;
            min = Math.min(value, min);
            max = Math.max(value, max);
          }
        }

        const scale = d3.scaleLinear([min, max], [0, 100]);
        result.push({
          ...column,
          className: 'plot',
          renderHeader() {
            const ticks = scale.ticks(8);
            return (
              <Box className={classes.barHeader}>
                {ticks.map((tick: any, index: number) =>
                  tick === 0 ? (
                    <Box key={tick} className={classes.tickZero} style={{ left: `${String(scale(tick))}%` }}>
                      <Box component="span">
                        {column.formatTick ? column.formatTick(tick) : renderValue(tick, column)}
                      </Box>
                    </Box>
                  ) : index % 2 === 0 ? (
                    <Box key={tick} className={classes.tick} style={{ left: `${String(scale(tick))}%` }}>
                      <Box component="span">
                        {column.formatTick ? column.formatTick(tick) : renderValue(tick, column)}
                      </Box>
                    </Box>
                  ) : (
                    <Box key={tick} className={classes.tick} style={{ left: `${String(scale(tick))}%` }} />
                  )
                )}
              </Box>
            );
          },
          renderCell(row: any) {
            // map series in an optimized way without dynamically resizing the bars array
            const bars: Array<{ element: ReactElement; value: number }> = Array.from({ length: column.series.length });
            let i = 0;
            for (const series of column.series) {
              const value = getCell(row, series);
              const origin = scale(0);
              const width = scale(value);
              bars[i++] = {
                element: (
                  <Box
                    key={i}
                    className={classes.bar}
                    title={renderValue(value, column, {}, row)}
                    style={{
                      left: `${value > 0 ? String(origin) : String(width)}%`,
                      width: `${value > 0 ? width - origin : origin - width}%`,
                      backgroundColor: series.color
                    }}
                  />
                ),
                value
              };
            }

            i = 0;
            for (const { element } of bars) {
              // @ts-expect-error
              bars[i++] = element;
            }

            return <Box className={classes.barCell}>{bars}</Box>;
          }
        });
      } else {
        result.push(column);
      }
    }

    return result;
  }, [displayedRows, columns, classes]);

  function handleDisplayedRows(displayed: any) {
    if (displayed.length !== displayedRows.length) {
      setDisplayedRows(displayed);
    }
  }

  return (
    <Table
      className={classes.table}
      {...props}
      rows={rows}
      columns={renderedColumns}
      onDisplayedRows={handleDisplayedRows}
    />
  );
};

TableWithPlot.ASC = Table.ASC;
TableWithPlot.DESC = Table.DESC;

export default TableWithPlot;
