import { TFunction } from 'react-i18next';

import composeTableColumns from './composeTableColumns';
import composeTableRows from './composeTableRows';
import getPercentageFields from './getPercentageFields';
import getReportTotalsSummary from './getReportTotalsSummary';
import { processHeaders } from './processHeaders';

import { CategoryFromApi, EntityById, Report } from '../../../../models';

type ComposeTableDataProps = {
  entityById: EntityById;
  report: Report;
  t: TFunction;
  categories?: CategoryFromApi[];
  shouldGroupCategories?: boolean;
  toggledCategories?: Record<string, boolean>;
};

const composeTableData = ({
  entityById,
  report,
  t,
  categories,
  shouldGroupCategories,
  toggledCategories
}: ComposeTableDataProps) => {
  const headers = processHeaders({ entityById, report });

  // simple list of fields that headers specify should be percentages
  const percentageFields = getPercentageFields(headers);
  const reportName = report.name;
  const rows = composeTableRows(report, {
    t,
    percentageFields,
    categories,
    headers,
    shouldGroupCategories,
    toggledCategories,
    isExcelDownloadData: false,
    reportName
  });
  const rowsForExcel = composeTableRows(report, {
    t,
    percentageFields,
    categories,
    headers,
    shouldGroupCategories,
    toggledCategories,
    isExcelDownloadData: true,
    reportName
  });
  const columns = composeTableColumns({ headers });
  const totals = getReportTotalsSummary(report);

  return { rows, rowsForExcel, columns, totals, reportName };
};

export default composeTableData;
