import { createAction } from '@reduxjs/toolkit';

import { DASHBOARD_CARD_TYPES } from '../../constants';

export const toggleIsRfModalOpen = createAction<boolean>('ui/toggleIsRfModalOpen');
export const setIsTemporaryBalanceSheetTabOpen = createAction<boolean>('ui/setIsTemporaryBalanceSheetTabOpen');
export const setSubJurisdictionSidemenuSelectedId = createAction<string | undefined>(
  'ui/setSubJurisdictionSidemenuSelectedId'
);
export const toggleEntityDetailsGroupedView = createAction('ui/toggleEntityDetailsGroupedView');
export const setEntityDetailsGroupedView = createAction<boolean>('ui/setEntityDetailsGroupedView');
export const turnOffEntityDetailsGroupedView = createAction('ui/turnOffEntityDetailsGroupedView');
export const setShouldGroupCategoriesOnReports = createAction<boolean>('ui/setShouldGroupCategoriesOnReports');
export const categoryRowsReportToggle = createAction<string>('ui/categoryRowsReportToggle');
export const resetCategoryRowsReportToggle = createAction('ui/resetCategoryRowsReportToggle');
export const setDashboardSelectedCard = createAction<keyof typeof DASHBOARD_CARD_TYPES>('ui/setDashboardSelectedCard');
export const setCountriesData = createAction<null | any[]>('ui/setCountriesData');
