import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Input from '@material-ui/core/Input';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ReportOutlined from '@material-ui/icons/ReportOutlined';

import { parseFloatLocal } from '../../../../utils';
import { Column, EditableCellProps, FailedCells, FailedCellsNoCredit, onCellEvent, Row } from '../../Table.proptype';
import { getCell, renderValue } from '../../utils';

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    justifyContent: 'space between'
  },
  cell: {
    padding: theme.spacing(1.2)
  },
  container: {
    position: 'relative',
    padding: theme.spacing(1),
    border: `2px solid ${theme.palette.action.focus}`
  },
  containerError: {
    position: 'relative',
    border: `2px solid ${theme.palette.error.main}`
  },
  icon: {
    color: theme.palette.error.main
  },
  input: {
    '& .MuiInput-input': {
      textAlign: 'inherit',
      fontSize: theme.typography.fontSize
    }
  },
  error: {
    '& .MuiInput-input': {
      textAlign: 'inherit',
      fontSize: theme.typography.fontSize,
      color: theme.palette.error.main
    }
  },
  message: {
    padding: '5px 10px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.text.primary,
    textAlign: 'center',
    borderRadius: theme.shape.borderRadius,
    position: 'absolute',
    left: 0,
    bottom: theme.spacing(5.2),
    zIndex: 2
  }
}));

type HandleEvent = {
  target: {
    value: any;
  };
};

function isCellFailed(failedCells: FailedCells, column: Column, row: Row): boolean {
  if (row.name && column.field && failedCells) {
    return Boolean((failedCells[row.name] ?? {})[column.field]);
  }

  return false;
}

export function isValidNumber(value: string): boolean {
  if (value === '') {
    return true;
  }

  // Matches: Numbers with an optional whole number portion with optional comma thousands separators, and a period decimal separator
  // Non-negative numbers may be wrapped by a single, balanced set of parentheses
  const validCharacters = new RegExp(
    /^(\(((\.\d+)|(?:\d{1,3}(?:,\d{3})+|\d+)(?:\.\d+)?)\)|-?((\.\d+)|(?:\d{1,3}(?:,\d{3})+|\d+)(?:\.\d+)?))$/
  );

  return validCharacters.test(value);
}

export function isNegWithParenthesis(value: string): boolean {
  // Matches: Non-negative number wrapped by a single, balanced set of parentheses
  const negWithParenthesisMatcher = new RegExp(/^\(((\.\d+)|(?:\d{1,3}(?:,\d{3})+|\d+)(?:\.\d+)?)\)$/);
  return negWithParenthesisMatcher.test(value);
}

const getTrailingDigits = (numString: string) =>
  numString
    .replace(/^\(/, '') // remove any leading parens
    .replace(/\)$/, '') // remove any trailing parens
    .replace(/(^-?\d*\.?)(\d)?/, '$2'); // capture the group of digits after the decimal

const EditableNumericCell = ({
  failedCells,
  column,
  row,
  renderOpts,
  onChange,
  onCellOrCommentBlur
}: EditableCellProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isFocused, setIsFocused] = useState(false);
  const [edited, setEdited] = useState();
  const [isValid, setIsValid] = useState(true);

  const failedCellNoCredit: FailedCellsNoCredit = row.creditName
    ? ((failedCells[row.creditName] ?? failedCells) as FailedCellsNoCredit)
    : (failedCells as FailedCellsNoCredit);

  useEffect(() => {
    if (!isFocused && isValid) {
      setEdited(getCell(row, column));
    }
  }, [isFocused, isValid, row, column]);

  function handleEvent(
    { target: { value: newValue } }: HandleEvent,
    isBlur: boolean,
    callback?: (arg: onCellEvent) => void
  ): void {
    const isValidValue = isValidNumber(newValue);

    if (isValidValue || newValue.startsWith('(')) {
      const trailingDigits = getTrailingDigits(newValue);
      const maximumAllowedTrailingDigits = 9;
      if (trailingDigits.length > maximumAllowedTrailingDigits) return; // we only persist up to 9 decimal places.
    }

    if (isBlur) {
      newValue = renderOpts?.isNotPercentage
        ? Math.round((Number(newValue) + Number.EPSILON) * 1e4) / 1e4
        : Math.round((Number(newValue) + Number.EPSILON) * 1e6) / 1e6;
      newValue = newValue.toString();
    }

    setIsValid(isValidValue);
    setEdited(newValue);

    if (callback && isValidValue) {
      if (isNegWithParenthesis(newValue)) {
        newValue = newValue.slice(1, -1);
        newValue = -parseFloatLocal(newValue);
      } else {
        newValue = parseFloatLocal(newValue);
      }

      const roundedValue = column.isNumber && !column.shouldNotRoundValue ? Math.round(newValue) : newValue;
      callback({
        value: roundedValue || 0,
        row,
        column
      });
    }
  }

  function getClassName(): string {
    if (isFocused && isValid) {
      return classes.container;
    }

    if (isFocused || !isValid || isCellFailed(failedCellNoCredit, column, row)) {
      return classes.containerError;
    }

    return '';
  }

  return (
    <Box role="container" className={`${classes.cell} ${getClassName()}`}>
      {isCellFailed(failedCellNoCredit, column, row) && !isFocused ? (
        <Box role="message" className={classes.message}>
          {
            // @ts-expect-error TODO - we need to figure out if column field and row name can really be undefined}
            (failedCellNoCredit[row.name ?? ''] ?? {})[column.field] === true
              ? t('Save Unsuccessful')
              : // @ts-expect-error TODO - we need to figure out if column field and row name can really be undefined}
                t((failedCellNoCredit[row.name ?? ''] ?? {})[column.field])
          }
        </Box>
      ) : isValid ? null : (
        <Box role="message" className={classes.message}>
          {t('Invalid number')}
        </Box>
      )}
      <Box className={classes.box}>
        {(!isValid || (isCellFailed(failedCellNoCredit, column, row) && !isFocused)) && (
          <ReportOutlined role="icon" className={classes.icon} />
        )}
        <Input
          disableUnderline
          fullWidth
          className={!isValid || isCellFailed(failedCellNoCredit, column, row) ? classes.error : classes.input}
          value={(isFocused || !isValid ? edited : renderValue(edited, column || {}, renderOpts)) || ''}
          onChange={(event) => {
            handleEvent(event, false, onChange);
          }}
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={(event) => {
            setIsFocused(false);
            handleEvent(event, true, onCellOrCommentBlur);
          }}
        />
      </Box>
    </Box>
  );
};

export default EditableNumericCell;
