import { useEffect, useReducer } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Box, Button, Checkbox, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { initialState, reducer, CONFIRM_IMPORT, SELECT_ENTITY, SET_DATA } from './reducer';

import { SearchBox } from '../../..';
import { Category, AccountRange, EntityFromUpload, AccountShape } from '../../../../models';
import { finishUploadReview } from '../../../../redux/uploadReview';
import { formatCurrency } from '../../../../utils';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    gap: theme.spacing(0.5),
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    margin: '0 auto',
    height: '100%',
    width: '100%',
    overflow: 'auto',
    [theme.breakpoints.up('md')]: {
      width: 900
    }
  },
  title: {
    marginBottom: theme.spacing(3.5)
  },
  searchbox: {
    marginBottom: theme.spacing(1),
    width: '30%',
    [theme.breakpoints.up('md')]: {
      width: 300
    }
  },
  kpi: {
    display: 'flex',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2.5),
    color: theme.palette.text.secondary,
    fontSize: '1.1rem',
    '& > :first-child': {
      flexGrow: 1
    }
  },
  confirm: {
    marginTop: theme.spacing(3),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
    fontSize: '1.1rem',
    '& > *': {
      display: 'flex',
      alignItems: 'center',

      '&:not(:first-child)': {
        marginTop: theme.spacing(4)
      },
      '& > :first-child': {
        flexGrow: 1
      }
    }
  },
  confirmText: {
    maxWidth: '75%'
  },
  button: {
    padding: theme.spacing(1, 4)
  }
}));

type Props = {
  container?: {
    containerName: string;
  };
  entities: EntityFromUpload[];
  accounts: AccountShape[];
  categories?: Category[];
  profitAndLossRange: AccountRange['range'];
  isUploadReviewCompleted?: boolean;
  isImportDisabled: boolean;
};

const ConfirmTab = ({
  container,
  accounts,
  entities,
  categories,
  profitAndLossRange,
  isUploadReviewCompleted,
  isImportDisabled
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [{ checked, ptbi, permanent, temporary, currentEntity }, localDispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
    localDispatch({
      type: SET_DATA,
      payload: { accounts, categories, profitAndLossRange }
    });
  }, [localDispatch, accounts, categories, profitAndLossRange]);

  useEffect(() => {
    if (isUploadReviewCompleted) {
      history.push('/');
    }
  }, [isUploadReviewCompleted, history]);

  return (
    <Box className={classes.container}>
      <Typography variant="h2" className={classes.title}>
        {t('Confirm & Import')}
      </Typography>
      <SearchBox
        id="entity-finder"
        className={classes.searchbox}
        options={entities}
        // FIXME : Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        getOptionLabel={({ name, id }: any) => `${id} - ${name}`}
        value={currentEntity}
        placeholder={t('all _ entities', { count: entities?.length || 0 })}
        onChange={(event: any, payload: any) => {
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
          localDispatch({ type: SELECT_ENTITY, payload });
        }}
      />
      <Paper className={classes.kpi} elevation={0}>
        <Box component="span">{t('Pre-Tax Book Income / (Loss)')}</Box>
        <Box component="span">{formatCurrency(ptbi)}</Box>
      </Paper>
      <Paper className={classes.kpi} elevation={0}>
        <Box component="span">{t('permanent-adjustments')}</Box>
        <Box component="span">{permanent}</Box>
      </Paper>
      <Paper className={classes.kpi} elevation={0}>
        <Box component="span">{t('temporary-adjustments')}</Box>
        <Box component="span">{temporary}</Box>
      </Paper>
      <Box className={classes.confirm}>
        <Box>
          <Box>
            <Typography variant="h3">{t('Confirmation')}</Typography>
            <Typography variant="body2" className={classes.confirmText}>
              {t('Any existing figures within mapped data categories will be replaced. This cannot be undone.')}
            </Typography>
          </Box>
          <Checkbox
            checked={checked}
            onChange={({ target }) => {
              // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
              localDispatch({ type: CONFIRM_IMPORT, payload: target.checked });
            }}
          />
        </Box>
        <Box>
          <Box>
            <Typography variant="h3">{t('Import to Tax Provision')}</Typography>
            <Typography variant="body2" className={classes.confirmText}>
              {container?.containerName}
            </Typography>
          </Box>
          <Button
            disabled={!checked || isImportDisabled}
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              dispatch(finishUploadReview());
            }}
          >
            {t('Import data')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ConfirmTab;
