import { TFunction } from 'react-i18next';

import isTotalsSummaryCollection from './isTotalsSummaryCollection';

import { CategoryFromApi, Header, ReportFragment, Row } from '../../../../models';
import { groupReportsCategoryRows } from '../../../Table/utils';
import { sortCategorizableCollection } from '../../utils';
import composeRowProperties from '../../utils/data-processing/composeRowProperties';

const isLineItemWithoutFinancialValues = (value: ReportFragment) =>
  value.type === 'lineItem' &&
  !Object.values(value).some((objectValue) => typeof objectValue === 'number' && objectValue !== 0);

type ComposeTableRowsProps = {
  obj: ReportFragment;
  misc: {
    t: TFunction;
    percentageFields: string[];
    isCollection?: boolean;
    isCategorizable?: boolean;
    shouldGroupCategories?: boolean;
    toggledCategories?: Record<string, boolean>;
    isExcelDownloadData: boolean;
    categories?: CategoryFromApi[];
    headers: Header[];
    reportName: string;
  };
};

const handleCategorizableCollections = (
  rows: Row[],
  headers: Header[],
  category: {
    isExcelDownloadData: boolean;
    shouldGroupCategories?: boolean;
  },
  toggledCategories?: Record<string, boolean>
) => {
  const groupedRows = [...groupReportsCategoryRows(rows, headers)];

  if (!category.shouldGroupCategories) {
    return groupedRows
      .flat()
      .sort(sortCategorizableCollection)
      .filter((row) => !row.isReportCategoryTotal);
  }

  if (category.isExcelDownloadData) {
    return groupedRows.sort(sortCategorizableCollection).flat();
  }

  if (toggledCategories) {
    return groupedRows
      .sort(sortCategorizableCollection)
      .flat()
      .filter((row) => !row.category || toggledCategories[row.category.categoryId] || row.isReportCategoryTotal);
  }

  return groupedRows.flat().filter((row) => !row.category || row.isReportCategoryTotal);
};

const composeTableRows = (
  obj: ComposeTableRowsProps['obj'],
  {
    t,
    percentageFields,
    isCollection,
    isCategorizable,
    categories,
    headers,
    shouldGroupCategories,
    toggledCategories,
    isExcelDownloadData,
    reportName
  }: ComposeTableRowsProps['misc']
): Row[] => {
  const isReportRoot = obj.type === 'report';
  const emptyTableRow = { className: 'empty-row', renderCell: () => '' };

  return (
    obj.values?.flatMap((value: ReportFragment) => {
      const isRootTableCalculation = isReportRoot && ['lineItem', 'total'].includes(value.type);
      const isNamelessCollection = value.name?.trim() === '';

      // do not display zero value rows and do not include totals collections
      if (isLineItemWithoutFinancialValues(value) || isTotalsSummaryCollection(value)) {
        return [];
      }

      const composedTableRows = [
        ...composeTableRows(value, {
          t,
          percentageFields,
          isCollection: isNamelessCollection ? false : value.type === 'collection',
          isCategorizable: isNamelessCollection ? false : value.categorized,
          categories,
          headers,
          shouldGroupCategories,
          toggledCategories,
          isExcelDownloadData,
          reportName
        })
      ];

      if (isNamelessCollection) {
        return composedTableRows;
      }

      return [
        ...(isRootTableCalculation ? [emptyTableRow] : []),
        composeRowProperties({
          value,
          isCollection: Boolean(isCollection),
          t,
          percentageFields,
          isCategorizable: Boolean(isCategorizable),
          categories,
          reportName
        }),
        ...(value.categorized
          ? handleCategorizableCollections(
              composedTableRows,
              headers,
              {
                isExcelDownloadData,
                shouldGroupCategories
              },
              toggledCategories
            )
          : composedTableRows)
      ];
    }) || []
  );
};

export default composeTableRows;
