import { useMemo } from 'react';

import { IconButton, makeStyles } from '@material-ui/core';

import { ReactComponent as InterlinkedIcon } from '../../../../assets/img/Icon_Interlinked.svg';
import { ReactComponent as EqualIcon } from '../../../../assets/img/IconEqual.svg';
import { ReactComponent as NotEqualIcon } from '../../../../assets/img/IconNotEqual.svg';
import { renderCell } from '../../../Table/components/TableBody';
import { Column, FailedCells, Row, TableProps } from '../../../Table/Table.proptype';

const useStyles = makeStyles(() => ({
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  arrowIcon: {
    width: '70px'
  },
  arrowIconOpaque: {
    width: '70px',
    opacity: '0.4'
  },
  rightArrowIcon: {
    width: '70px',
    transform: 'rotate(-90deg)'
  },
  rightArrowIconOpaque: {
    width: '70px',
    transform: 'rotate(-90deg)',
    opacity: '0.4'
  },
  notEqualButton: {
    padding: '0px'
  }
}));

interface RtpColumnIconsProps {
  row: Row;
  value: number;
  column: Column;
  failedCells: FailedCells;
  onCellChange: TableProps['onCellChange'];
  onCellOrCommentBlur: TableProps['onCellOrCommentBlur'];
  renderOpts: any;
  rows: Row[];
  onCellClick?: TableProps['onCellClick'];
  ignoreRenderCell?: boolean;
  totalDifference?: number;
  isTotalRow: boolean;
  handleIconButtonClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  rightArrow?: boolean;
  buttonRef?: any;
  disableButton?: boolean;
}
const RtpColumnIcons = ({
  row,
  value,
  column,
  failedCells,
  onCellChange,
  onCellOrCommentBlur,
  renderOpts,
  rows,
  onCellClick,
  ignoreRenderCell,
  totalDifference,
  isTotalRow,
  handleIconButtonClick,
  rightArrow = false,
  buttonRef,
  disableButton
}: RtpColumnIconsProps) => {
  const classes = useStyles();
  const getIconClassName = (value: number, iconToDisplay: string) => {
    const className = value ? iconToDisplay : `${String(iconToDisplay)}Opaque`;
    return classes[className as keyof typeof classes];
  };

  const isEqual = useMemo(() => {
    return totalDifference === value;
  }, [totalDifference, value]);

  return (
    <div className={classes.iconContainer} data-testid="rtp-column-icon-container">
      {isTotalRow ? (
        isEqual ? (
          <EqualIcon data-testid="rtp-column-equalIcon" />
        ) : (
          <IconButton
            ref={buttonRef}
            className={classes.notEqualButton}
            disabled={disableButton}
            onMouseDown={(event) => {
              event.stopPropagation();
            }}
            onClick={handleIconButtonClick}
          >
            <NotEqualIcon data-testid="rtp-column-notEqualIcon" />
          </IconButton>
        )
      ) : (
        <InterlinkedIcon
          className={getIconClassName(value, rightArrow ? 'rightArrowIcon' : 'arrowIcon')}
          data-testid="rtp-column-interlinkedIcon"
        />
      )}
      {renderCell(
        row,
        value,
        column,
        failedCells,
        onCellChange,
        onCellOrCommentBlur,
        renderOpts,
        rows,
        '',
        onCellClick,
        ignoreRenderCell
      )}
    </div>
  );
};

export default RtpColumnIcons;
