import { CategoriesStateInterface } from '../../models';

export const categoriesInitialState: CategoriesStateInterface = {
  categories: [],
  isLoading: false,
  isLoaded: false,
  error: null,
  updateCategory: {
    isLoading: false,
    error: null
  }
};
