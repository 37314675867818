import GroupEntitiesEditor from './GroupEntitiesEditor';
import GroupEntitiesEditorConnector from './GroupEntitiesEditorConnector';

import { Container, CustomGroup } from '../../../../models';

type ConnectedComponentProps = {
  group: CustomGroup | null;
  onCancel: () => void;
  onUpdate: () => void;
  currentContainer?: Container;
};

const ConnectedComponent = ({ group, onCancel, onUpdate, currentContainer }: ConnectedComponentProps) => (
  <GroupEntitiesEditorConnector
    component={GroupEntitiesEditor}
    group={group}
    currentContainer={currentContainer}
    onCancel={onCancel}
    onUpdate={onUpdate}
  />
);

export default ConnectedComponent;
