import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';

import ActualYearToDate from './ActualYearToDate';
import FullYearProjection from './FullYearProjection';

import { ContentAndTitle, TabTitle } from '..';
import { FEDERAL_UUID, LEVELS } from '../../../../constants';
import { useCompletionStatus, useContainers, useCurrencies, useFinancialData } from '../../../../hooks';
import { PreTaxBookIncomeProps } from '../../../../models/preTaxBookIncome.interface';
import { setEntityCompletionStatus } from '../../../../redux/entitiesCompletionStatus';
import { updateFinancialData } from '../../../../redux/financialData';
import LoadingWrapper from '../../../LoadingWrapper';
import { TableWithCommentProps } from '../../../TableWithComment/TableWithComment.proptype';
import { EntityNumberRouteMatch } from '../../utils';

const useStyles = makeStyles(() => ({
  preTaxBookIncomeBox: {
    overflow: 'auto'
  }
}));

const PreTaxBookIncome = ({ entityId }: PreTaxBookIncomeProps) => {
  const { preTaxBookIncomeBox } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currencyByEntityIdMap } = useCurrencies();
  const currencyIsoCode = currencyByEntityIdMap[entityId]?.isoCode;
  const {
    params: { entityNumber }
  } = useRouteMatch<EntityNumberRouteMatch>();
  const { stepCompletionStatus } = useCompletionStatus(entityNumber, `federal.ptbi`, FEDERAL_UUID);
  const { tabsData, failedCells, isFetchLoading } = useFinancialData(entityNumber, LEVELS.FEDERAL, ['ptbi']);
  const { currentContainer } = useContainers();
  const isInterim = currentContainer?.isInterimReportingPeriod;

  const handleOnCellOrCommentBlurFullYear: TableWithCommentProps['onCellOrCommentBlur'] = ({ value, column, row }) => {
    dispatch(
      updateFinancialData(
        {
          accountId: row.accountId,
          // @ts-expect-error TODO - we need to figure out if row name can really be undefined
          rowName: row.name,
          // @ts-expect-error TODO - we need to figure out if column field can really be undefined
          columnName: column.field,
          rowId: row.rowId,
          level: LEVELS.FEDERAL,
          step: 'ptbi',
          creditName: 'fullYearProjection',
          value
        },
        entityId
      )
    );
  };

  const handleOnCellOrCommentBlurActualYTD: TableWithCommentProps['onCellOrCommentBlur'] = ({ value, column, row }) => {
    dispatch(
      updateFinancialData(
        {
          accountId: row.accountId,
          // @ts-expect-error TODO - we need to figure out if row name can really be undefined
          rowName: row.name,
          // @ts-expect-error TODO - we need to figure out if row name can really be undefined
          columnName: column.field,
          rowId: row.rowId,
          level: LEVELS.FEDERAL,
          step: 'ptbi',
          creditName: 'actualYearToDate',
          value
        },
        entityId
      )
    );
  };

  return (
    <LoadingWrapper isLoading={isFetchLoading}>
      <ContentAndTitle
        title={
          <TabTitle
            currencyIsoCode={currencyIsoCode}
            title={t('ptbi')}
            isCompleted={stepCompletionStatus.status}
            onCompletionChange={(checked) => {
              dispatch(
                setEntityCompletionStatus({
                  ...stepCompletionStatus,
                  newStatus: checked
                })
              );
            }}
          />
        }
      >
        <Box className={preTaxBookIncomeBox}>
          <FullYearProjection
            tabsData={tabsData}
            failedCells={failedCells}
            stepCompletionStatus={stepCompletionStatus}
            handleOnCellOrCommentBlur={handleOnCellOrCommentBlurFullYear}
          />
          {isInterim && (
            <ActualYearToDate
              tabsData={tabsData}
              failedCells={failedCells}
              stepCompletionStatus={stepCompletionStatus}
              handleOnCellOrCommentBlur={handleOnCellOrCommentBlurActualYTD}
            />
          )}
        </Box>
      </ContentAndTitle>
    </LoadingWrapper>
  );
};

export default PreTaxBookIncome;
