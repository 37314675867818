import { TFunction } from 'react-i18next';

import { Style, Worksheet } from 'exceljs';

import { formatSpecificCell } from './formatUtils';

import { CURRENCY_ISO_TO_SYMBOL } from '../../constants';
import { Modify, ReportForExport } from '../../models';
import theme from '../../theme';
import { formatDateShort, isPercentage } from '../../utils';

interface ReportRowForXls {
  values: any[];
  isReportCategoryTotal: boolean;
  className: string;
  type: string;
  usingSpecializedFormatting: boolean;
}

export interface ReportForXls
  extends Modify<
    ReportForExport,
    {
      rows: ReportRowForXls[];
      totals?: any[][];
    }
  > {
  columnWidths: Array<{ width: number }>;
  headers: string[];
  headerGroupsWithLengths: Array<{ headerGroupName: string; numOfCells: number }>;
  headerGroups2WithLengths: Array<{ headerGroupName: string; numOfCells: number }>;
}

const titleStyle: Partial<Style> = {
  font: { bold: true },
  alignment: { horizontal: 'left' }
};

const cellStyle: Partial<Style> = {
  border: {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' }
  },
  alignment: { horizontal: 'center' }
};

const numberStyle: Partial<Style> = {
  alignment: { horizontal: 'right' }
};

const headerStyle: Partial<Style> = {
  ...cellStyle,
  font: { bold: true }
};

const highlightedCellStyle: Partial<Style> = {
  font: { bold: true },
  fill: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'BFBFBF' }
  }
};

const highlightedCellOnFirstColStyle: Partial<Style> = {
  alignment: { horizontal: 'left' }
};

const hashlessSecondaryMainHex = theme.palette.secondary.main.slice(1, theme.palette.secondary.main.length);
const headerGroupStyle: Partial<Style> = {
  font: { bold: true },
  fill: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: hashlessSecondaryMainHex }
  }
};

export function transformUiReportToXlsReport(report: ReportForExport): ReportForXls {
  const keyList: string[] = [];
  const headers: string[] = [];
  const headerGroupNamesAndLengths: Record<string, { headerGroupName: string; numOfCells: number }> = {};
  const headerGroupsWithLengths: Array<{ headerGroupName: string; numOfCells: number }> = [];
  const headerGroup2NamesAndLengths: Array<{ headerGroupName: string; numOfCells: number }> = [];
  const headerGroups2WithLengths: Array<{ headerGroupName: string; numOfCells: number }> = [];
  const keyWidthsMap: Record<string, number> = {};

  report.columns.forEach((column) => {
    if (column.headerName && column.field) {
      keyWidthsMap[column.field] =
        keyWidthsMap[column.field] && keyWidthsMap[column.field] > column.headerName.length
          ? keyWidthsMap[column.field]
          : column.headerName.length;
      keyList.push(column.field);
      headers.push(column.headerName);
    }

    if (column.headerGroup) {
      if (headerGroupNamesAndLengths[column.headerGroup]) {
        headerGroupNamesAndLengths[column.headerGroup].numOfCells++;
      } else {
        headerGroupNamesAndLengths[column.headerGroup] = {
          headerGroupName: column.headerGroup,
          numOfCells: 1
        };
        headerGroupsWithLengths.push(headerGroupNamesAndLengths[column.headerGroup]);
      }
    } else {
      const randomName = String(Math.random());
      headerGroupNamesAndLengths[randomName] = {
        headerGroupName: 'gapColumn',
        numOfCells: 1
      };
      headerGroupsWithLengths.push(headerGroupNamesAndLengths[randomName]);
    }

    if (column.headerGroup2) {
      if (headerGroup2NamesAndLengths[column.headerGroup2]) {
        headerGroup2NamesAndLengths[column.headerGroup2].numOfCells++;
      } else {
        headerGroup2NamesAndLengths[column.headerGroup2] = {
          headerGroupName: column.headerGroup2,
          numOfCells: 1
        };
        headerGroups2WithLengths.push(headerGroup2NamesAndLengths[column.headerGroup2]);
      }
    } else {
      const randomName: any = String(Math.random());
      headerGroups2WithLengths[randomName] = {
        headerGroupName: 'gapColumn',
        numOfCells: 1
      };
      headerGroups2WithLengths.push(headerGroups2WithLengths[randomName]);
    }
  });

  const rowsForExcel = report.rows.map((row) => {
    const rowForExcel: ReportRowForXls = {
      values: [],
      isReportCategoryTotal: row?.isReportCategoryTotal ?? false,
      className: row.className ?? '',
      type: row.type,
      usingSpecializedFormatting: row?.usingSpecializedFormatting
    };
    keyList.forEach((key) => {
      const value = row[key] ?? null;
      keyWidthsMap[key] =
        keyWidthsMap[key] > (value ?? '').toString().length ? keyWidthsMap[key] : (value ?? '').toString().length;
      rowForExcel.values.push(value);
    });
    return rowForExcel;
  });

  const columnWidths: Array<{ width: number }> = [];
  report.columns.forEach((column) => {
    if (column.headerName && column.field) {
      columnWidths.push({ width: keyWidthsMap[column.field] + 2 });
    }
  });

  const totalsForExcel = report.totals?.map((totalRow) => [totalRow.name, totalRow.value]);
  return {
    ...report,
    rows: rowsForExcel,
    totals: totalsForExcel,
    columnWidths,
    headers,
    headerGroupsWithLengths,
    headerGroups2WithLengths
  };
}

export function createReportTitle(worksheet: Worksheet, report: ReportForXls, t: TFunction): void {
  worksheet.mergeCells('A1', 'E1');
  worksheet.mergeCells('A2', 'E2');
  worksheet.mergeCells('A3', 'E3');

  worksheet.getCell('A1').value = report.title;
  worksheet.getCell('A1').style = titleStyle;
  worksheet.getCell('A2').value = report.startDate
    ? `${formatDateShort(report.startDate)} - ${formatDateShort(report.endDate)}`
    : null;
  worksheet.getCell('A2').style = titleStyle;
  const currencyText = t('currency _', {
    value: report.currency,
    symbol: CURRENCY_ISO_TO_SYMBOL[report?.currency ?? 'USD']
  });
  worksheet.getCell('A3').value = report.currency ? currencyText : null;
  worksheet.getCell('A3').style = titleStyle;
}

function createHeaderGroups(
  worksheet: Worksheet,
  headerGroupsWithLengths: Array<{ headerGroupName: string; numOfCells: number }>,
  headersRowNum: number
) {
  let currCol = 1; // Header Groups start after 2 columns
  headerGroupsWithLengths.forEach((headerGroup) => {
    const currCell = worksheet.getCell(headersRowNum, currCol);
    currCell.value = headerGroup.headerGroupName;
    currCell.style = { ...cellStyle, ...headerGroupStyle };
    if (headerGroup.headerGroupName === 'gapColumn') {
      currCell.value = ' ';
      currCell.style = {};
    }

    worksheet.mergeCells(headersRowNum, currCol, headersRowNum, currCol + headerGroup.numOfCells - 1);
    currCol += headerGroup.numOfCells;
  });
}

export function createReportTable(worksheet: Worksheet, report: ReportForXls): void {
  try {
    let headersRowNum = 5;

    if (report.headerGroups2WithLengths.length > 0) {
      createHeaderGroups(worksheet, report.headerGroups2WithLengths, headersRowNum);
      headersRowNum++;
    }

    if (report.headerGroupsWithLengths.length > 0) {
      createHeaderGroups(worksheet, report.headerGroupsWithLengths, headersRowNum);
      headersRowNum++;
    }

    const headersRow = worksheet.getRow(headersRowNum);
    headersRow.values = report.headers;
    headersRow.eachCell({ includeEmpty: false }, (headerCell) => {
      headerCell.style = headerStyle;
    });

    worksheet.columns = report.columnWidths;
    report.rows.forEach((reportRow) => {
      const currXlsRow = worksheet.addRow(reportRow.values);
      const currentRow = reportRow?.values?.[0];
      const isSpecializedFormat = Boolean(reportRow?.usingSpecializedFormatting);

      const isRowHighlighted = reportRow.className === 'section-header' || reportRow.type === 'total';
      currXlsRow.eachCell({ includeEmpty: true }, (singleCell) => {
        const isNumeralField =
          typeof singleCell?.value === 'number' ||
          (typeof singleCell?.value === 'string' && isPercentage(singleCell?.value?.replace(/[()]/g, '')));
        singleCell.style = {
          ...singleCell.style,
          ...cellStyle,
          ...(isRowHighlighted && highlightedCellStyle),
          // @ts-expect-error - This is a bug in exceljs, the type says "string" but they return a number
          ...(isRowHighlighted && singleCell.col === 1 && highlightedCellOnFirstColStyle),
          ...(isNumeralField && numberStyle)
        };
        const [value, format] = formatSpecificCell({
          name: report.rawName,
          value: singleCell.value,
          columnIndex: singleCell.col,
          headers: report.headers,
          isSpecializedFormat,
          itemValue: currentRow,
          isReportCategoryTotal: reportRow.isReportCategoryTotal
        });

        singleCell.value = value;
        singleCell.numFmt = format;
      });
    });
    // Leaving this because the errors do not show up on console.
    // eslint-disable-next-line @typescript-eslint/no-implicit-any-catch
  } catch (error: any) {
    console.warn(error);
  }
}

export function createTotalsTable(worksheet: Worksheet, report: ReportForXls): void {
  if (report.totals) {
    worksheet.addRows([[], []]);
    const rows = worksheet.addRows(report.totals);
    rows.forEach((row) => {
      row.getCell(1).style = { ...cellStyle, ...highlightedCellStyle, ...highlightedCellOnFirstColStyle };
      row.getCell(2).style = { ...cellStyle, ...highlightedCellStyle };
      row.eachCell((singleCell) => {
        const isNumeralField =
          typeof singleCell?.value === 'number' ||
          (typeof singleCell?.value === 'string' && isPercentage(singleCell?.value?.replace(/[()]/g, '')));
        const [value, format] = formatSpecificCell({
          name: report.rawName,
          value: singleCell.value,
          columnIndex: '1',
          headers: report.headers,
          isSpecializedFormat: false,
          itemValue: '',
          isReportCategoryTotal: false
        });
        singleCell.style = {
          ...singleCell.style,
          ...(isNumeralField && numberStyle)
        };
        singleCell.value = value;
        singleCell.numFmt = format;
      });
    });
  }
}
