import { makeStyles } from '@material-ui/core';

import { Row } from '../../../../../../../../models';
import { formatNumber, formatPercentage } from '../../../../../../../../utils';

const useStyles = makeStyles(() => ({
  totalRow: {
    display: 'flex',
    backgroundColor: '#eeeeee',
    paddingBottom: '10px',
    paddingTop: '10px'
  },
  totalRowLeft: {
    paddingRight: '12px',
    paddingLeft: '10px',
    width: '100%'
  },
  totalValue: {
    paddingRight: '10px',
    width: '20%',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center'
  }
}));

export const TotalRow = ({ row }: { row: Row }) => {
  const classes = useStyles();

  let value: number;
  let formattedValue;
  if (row.format === 'percentage') {
    value = row.traceValue ?? 0;
    formattedValue = formatPercentage(value);
  } else {
    value = Math.round(row.traceValue ?? 0);
    formattedValue = formatNumber(value, 0, '0');
  }

  return (
    <div className={classes.totalRow}>
      <div className={classes.totalRowLeft}>{row.name}</div>
      <div className={classes.totalValue}>{formattedValue}</div>
    </div>
  );
};
