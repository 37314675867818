import * as uuid from '@xbs/xbs-uuid';
import { Dispatch } from 'redux';
import * as XLSX from 'xlsx';

import { filterOutInvalidStates, generateSubJurValidationArray, validateCellValue } from './validations';

import { NULL_UUID, reservedNames } from '../../../constants';
import { Column, Entity, FinancialInfo, FormattedWorksheet, Level, Row, Step, SubJurisdiction } from '../../../models';
import { saveDataImport } from '../../../redux/dataImport';
import { enqueueNotification } from '../../../redux/notifications';
import { formatDateLong } from '../../../utils';
import { allowedSpecialCharacters } from '../../EntityReview/components/EntityTable/utils';
import { formatSubjurisdictionName, parseSubjurisdictionsByName } from '../../ValidateScreen/utils';

interface GetColumnsAndRowsFromSelectionReturn {
  leftCol?: string;
  rightCol?: string;
  lowerRow?: number;
  upperRow?: number;
  numRows?: number;
}

const capitalize = (name: string) => {
  if (name.length === 0) return name;
  if (name.length === 1) {
    return name.toUpperCase();
  }

  if (name.length > 1) {
    name = name.toLowerCase();
    name = name[0].toUpperCase() + name.slice(1);
  }

  return name;
};

const LEVEL_STEP_TO_RENDER_VALUE_FUNCTION: {
  federal: Partial<Record<Step, any>>;
  state: Partial<Record<Step, any>>;
  discrete: Partial<Record<Step, any>>;
} = {
  federal: {},
  state: {
    apportionment: capitalize
  },
  discrete: {}
};

const CELLS_WITH_JURISDICTION_NAME: {
  federal: Partial<Record<Step, string>>;
  state: Partial<Record<Step, string>>;
  discrete: Partial<Record<Step, string>>;
} = {
  federal: {},
  state: {
    'tax-effected': 'State',
    modifications: 'State',
    apportionment: 'State',
    'apportionment-flagged': 'State'
  },
  discrete: {}
};

export const getColumnsAndRowsFromSelection = (selection: string): GetColumnsAndRowsFromSelectionReturn => {
  const oneCellExec = /^([A-Z]+)(\d+)$/.exec(selection);
  if (oneCellExec) {
    // This handles selection of just one cell/row
    const column = oneCellExec[1];
    const row = Number(oneCellExec[2]);

    return { leftCol: column, rightCol: column, lowerRow: row, upperRow: row, numRows: 1 };
  }

  const execRow = /(\d+)\D*(\d+)/.exec(selection);
  const lowerRow = Number(execRow?.[1]);
  const upperRow = Number(execRow?.[2]);
  const numRows = upperRow - lowerRow + 1;

  const execCol = /([A-Z]+)[^A-Z]*([A-Z]+)/.exec(selection);
  const leftCol = execCol?.[1];
  const rightCol = execCol?.[2];

  return { leftCol, rightCol, lowerRow, upperRow, numRows };
};

export const getValuesFromSheet = ({ selection, sheet }: { selection: string; sheet: XLSX.WorkSheet }) => {
  const formatted: FormattedWorksheet = XLSX.utils.sheet_to_json(sheet, {
    raw: false,
    header: 1,
    range: selection,
    defval: ''
  });

  const raw: FormattedWorksheet = XLSX.utils.sheet_to_json(sheet, {
    raw: true,
    header: 1,
    range: selection,
    defval: ''
  });

  return {
    formatted,
    raw
  };
};

export interface ComposeRowProps {
  creditName?: string;
  selectedData: any;
  finData: FinancialInfo[];
  keyHeaderNames: string[];
  entitySubJurisdictions: SubJurisdiction[];
  level: Level;
  step: Step;
  subJurisdictionId?: string;
}

export interface OtherColumns {
  federal: Partial<Record<Step, string[]>>;
  state: Partial<Record<Step, string[]>>;
  discrete: Partial<Record<Step, string[]>>;
}

const OTHER_COLUMNS: OtherColumns = {
  // NOTE: These are column headers that we match with the corresponding cell in the matching row
  // if such a row exists.
  // This affects the styling of the cells. If you want a column to have cells that are styled differently
  // when they "match" the existing values, enter them into an array under the corresponding key.
  federal: {
    permanent: ['Amount', 'Percentage Disallowed'],
    'temporary.incomeStatement': ['Book Balance', 'Tax Balance'],
    'temporary.balanceSheet': ['Beginning Balance', 'Beginning Payments', 'Ending Balance', 'Ending Payments'],
    nol: [
      'Beginning Balance',
      'Return to Provision',
      'Amount Generated in Current Year',
      'Amount (Used) in Current Year',
      'Deferred Only Adjustment',
      'Balance Sheet Only Adjustment',
      'Carry Over Period Remaining'
    ],
    rtp: ['Tax Provision', 'Tax Return'],
    'tax-effected': ['Amount'],
    deferred: ['Beginning Balance', 'Deferred Only Adjustment', 'Balance Sheet Only Adjustment'],
    credits: [
      'Beginning Balance',
      'Return to Provision',
      'Amount Generated in Current Year',
      'Amount (Used) in Current Year',
      'Deferred Only Adjustment',
      'Balance Sheet Only Adjustment',
      'Carry Over Period Remaining'
    ]
  },
  state: {
    'apportionment-flagged': ['Beginning', 'Current', 'End'],
    apportionment: ['Apportionment'],
    modifications: ['Amount', 'State'],
    nol: [
      'Beginning Balance',
      'Return to Provision',
      'Amount Generated in Current Year',
      'Amount (Used) in Current Year',
      'Deferred Only Adjustment',
      'Balance Sheet Only Adjustment',
      'Carry Over Period Remaining'
    ],
    rtp: ['Tax Provision', 'Tax Return'],
    'tax-effected': ['State', 'Amount'],
    credits: [
      'Beginning Balance',
      'Return to Provision',
      'Amount Generated in Current Year',
      'Amount (Used) in Current Year',
      'Deferred Only Adjustment',
      'Balance Sheet Only Adjustment',
      'Carry Over Period Remaining'
    ],
    deferred: ['Beginning Balance', 'Deferred Only Adjustment', 'Balance Sheet Only Adjustment']
  },
  discrete: {}
};

interface SingleCellTabs {
  federal: Partial<Record<Step, boolean>>;
  state: Partial<Record<Step, boolean>>;
  discrete: Partial<Record<Step, boolean>>;
}

export const SINGLE_CELL_TABS: SingleCellTabs = {
  federal: {
    ptbi: true
  },
  state: {},
  discrete: {}
};

interface KeyHeaderNames {
  federal: Partial<Record<Step, string[]>>;
  state: Partial<Record<Step, string[]>>;
  discrete: Partial<Record<Step, string[]>>;
}

export const KEY_HEADER_NAMES: KeyHeaderNames = {
  federal: {
    permanent: ['Account Description'],
    'temporary.incomeStatement': ['Account Description'],
    'temporary.balanceSheet': ['Account Description'],
    nol: ['Tax Period'],
    rtp: ['Name'],
    'tax-effected': ['Account Description'],
    deferred: ['Adjustment Name'],
    credits: ['Tax Period']
  },
  state: {
    apportionment: ['State'],
    modifications: ['Account Description', 'Modification Type', 'State'],
    nol: ['Tax Period'],
    rtp: ['Name'],
    'tax-effected': ['Adjustment Name'],
    credits: ['Tax Period'],
    deferred: ['Adjustment Name']
  },
  discrete: {}
};

export const getKeyHeaderName = (level: Level, step: Step) => KEY_HEADER_NAMES[level]?.[step] ?? [];

export const getOtherColumns = (level: Level, step: Step) => OTHER_COLUMNS[level]?.[step];

// 'temporary' step has two children steps, which are the ones that are actually used. So we do that transform here
export const transformTemporaryStepName = (step: Step, isTemporaryBalanceSheet: boolean) => {
  if (step === 'temporary') {
    step = isTemporaryBalanceSheet ? 'temporary.balanceSheet' : 'temporary.incomeStatement';
  }

  return step;
};

type ComposedCell = {
  value: any;
  formattedValue: any;
  isNew: boolean;
  isIgnored: boolean;
  isSameValue: boolean;
  isValid: boolean;
  entityFinancialsTraceId: string | null;
  rowId: string | null;
};

export type ComposedRow = {
  amount?: any;
  accountId: string;
  cells: {
    [columnNames: string]: ComposedCell;
  };
  creditName?: string;
  entityFinancialsTraceId?: string;
  existingRowName?: string;
  hasPreviousValue?: boolean;
  index: number;
  isNew: boolean;
  isValid: boolean;
  jurisdictionId?: string;
  level: Level;
  renderValueFunction?: any;
  step: Step;
};

// Generates a hashtable to match financial info - spreadsheet data in O(1)
const createFinancialInfoTable = (financialInfo: FinancialInfo[], level?: string, step?: string) => {
  const table: {
    [key: string]: {
      value: any;
      index: number;
    };
  } = {};

  financialInfo.forEach((financialInfoItem, index) => {
    let key = `${financialInfoItem.rowName.toLowerCase()}${financialInfoItem.columnName}`;
    // added deferred here because federal.temporary column name Beginning Balance is
    // the same as the federal.deferred column name Beginning Balance which would cause
    // an update of the temporary value instead of the deferred value without this addition
    if (level === 'federal' && step === 'deferred') {
      key =
        financialInfoItem.step === 'deferred'
          ? `deferred${financialInfoItem.rowName.toLowerCase()}${financialInfoItem.columnName}`
          : `${financialInfoItem.rowName.toLowerCase()}${financialInfoItem.columnName}`;
    }

    if (level === 'state' && step === 'modifications') {
      key = `${financialInfoItem.step.toLowerCase()}${financialInfoItem.rowName.toLowerCase()}${
        financialInfoItem.columnName
      }`;
    }

    if (step === 'credits') {
      key =
        financialInfoItem.step === 'credits'
          ? `${String(financialInfoItem.creditName)}${financialInfoItem.rowName.toLowerCase()}${
              financialInfoItem.columnName
            }`
          : `${financialInfoItem.rowName.toLowerCase()}${financialInfoItem.columnName}`;
    }

    table[key] = {
      value: financialInfoItem.value,
      index
    };
  });

  return table;
};

export type mapToStepTitleObj = Partial<Record<Step, string>>;

export const mapToStepTitle: mapToStepTitleObj = {
  permanent: 'Permanent Adjustments',
  'temporary.incomeStatement': 'Temporary Adjustments - Income Statement',
  'temporary.balanceSheet': 'Temporary Adjustments - Balance Sheet',
  apportionment: 'Apportionment',
  nol: 'Net Operating Loss',
  'tax-effected': 'Tax Effected',
  ptbi: 'Pre-Tax Book Income',
  rtp: 'Return To Provision',
  credits: 'Credits',
  modifications: 'Modifications',
  deferred: 'Deferred'
};

export const CREDIT_NAME_TO_LONG_FORM = {
  'federal.permanent': 'Permanent Differences',
  'federal.temporary': 'Temporary Differences',
  'state.modifications.permanent': 'Permanent Modifications',
  'state.modifications.temporary': 'Temporary Modifications',
  'state.deferred': 'Deferred Rollforward'
};

export const translateCreditName = (creditName: string) =>
  CREDIT_NAME_TO_LONG_FORM[creditName as keyof typeof CREDIT_NAME_TO_LONG_FORM] ?? creditName;

export interface FileUploadProps {
  entityName: Entity['name'];
  level: Level;
  step: Step;
  entitySubJurisdictions: SubJurisdiction[];
}

export const ROW_NAME_KEY_MAP: {
  federal: Partial<Record<Step, string>>;
  state: Partial<Record<Step, string>>;
  discrete: Partial<Record<Step, string>>;
} = {
  federal: {
    permanent: 'Account Description',
    'temporary.incomeStatement': 'Account Description',
    'temporary.balanceSheet': 'Account Description',
    nol: 'Tax Period',
    rtp: 'Name',
    'tax-effected': 'Account Description',
    deferred: 'Adjustment Name',
    credits: 'Tax Period'
  },
  state: {
    apportionment: 'State',
    'apportionment-flagged': 'State',
    modifications: 'Account Description',
    nol: 'Tax Period',
    rtp: 'Name',
    'tax-effected': 'Adjustment Name',
    credits: 'Tax Period',
    deferred: 'Adjustment Name'
  },
  discrete: {}
};

export const translateColumnName = (columnName: string) => {
  // NOTE: This map translates the header name into the camel-case columnName equivalent.
  // ASSUMPTION: Same header names are always mapped to same field/camel-case equivalent across all tabs.
  const map = {
    'Adjustment Name': 'name',
    Amount: 'amount',
    'Amount Generated in Current Year': 'generatedAmount',
    'Amount (Used) in Current Year': 'usedAmount',
    Apportionment: 'amount',
    'Balance Sheet Only Adjustment': 'balanceSheetOnlyAdjustment',
    Beginning: 'beginning',
    'Beginning Balance': 'beginningBalance',
    'Beginning Payments': 'beginningPayments',
    'Book Balance': 'bookBalance',
    'Carry Over Period Remaining': 'remaining',
    'Credit Name': 'creditName',
    Current: 'amount',
    'Deferred Only Adjustment': 'deferredOnlyAdjustment',
    End: 'end',
    'Ending Balance': 'endingBalance',
    'Ending Payments': 'endingPayments',
    'Return to Provision': 'rtp',
    'Percentage Disallowed': 'disallowedPct',
    State: 'state',
    'Tax Balance': 'taxBalance',
    'Tax Provision': 'taxProvision',
    'Tax Return': 'taxReturn'
  };

  return map[columnName as keyof typeof map] ?? columnName;
};

export const TABS_THAT_DO_NOT_ALLOW_NEW_ROWS: {
  federal: Partial<Record<Step, boolean>>;
  state: Partial<Record<Step, boolean>>;
  discrete: Partial<Record<Step, boolean>>;
} = {
  federal: {
    rtp: true,
    deferred: true
  },
  state: {
    apportionment: true,
    'apportionment-flagged': true,
    deferred: true
  },
  discrete: {}
};

export const getDoesAllowNewRows = (level: Level, step: Step) => !TABS_THAT_DO_NOT_ALLOW_NEW_ROWS[level][step];

export type ImportButtonProps = {
  checkmarkIndices: boolean[];
  level: Level;
  rows: any[];
  step: Step;
  hasErrorState: boolean;
  onImportFinished: () => void;
  isTabCompleted: boolean;
};

interface FormatRowsForCreateReturn {
  accountId: string;
  level: Level;
  step: Step;
  columnName: string;
  value: any;
  rowName: string;
  jurisdictionId?: string;
}

const setRowMetaDataForStateModifications = (row: any, step: string, rowMetaData: any) => {
  if (row?.cells['Modification Type']?.value) {
    /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
    rowMetaData.step = `${step}.${row.cells['Modification Type'].value.toLowerCase()}`;
  }

  rowMetaData.stateValue = row?.jurisdictionId ?? '';
  rowMetaData.jurisdictionId = row?.jurisdictionId?.[0];
};

interface rowMetaDataProps {
  step: any;
  stateValue?: string | string[];
  jurisdictionId?: string;
}

const getRowNameByStepAndLevel = (level: Level, step: Step, row: ComposedRow, rowNameKey: string): string => {
  const existingRowName: string = row.existingRowName ?? row.cells[rowNameKey].value.toString();
  if (level === 'state' && step === 'deferred' && row.creditName === 'state.modifications.temporary') {
    const stateName: string = formatSubjurisdictionName(row.cells.State.value as string);
    return `${stateName} - ${existingRowName}`;
  }

  return existingRowName;
};

// eslint-disable-next-line complexity
export const formatRowsForCreate = ({
  level,
  rowNameKey,
  rows,
  shouldCreateNewRows = true,
  step
}: {
  level: Level;
  rowNameKey: string;
  rows: ComposedRow[];
  shouldCreateNewRows?: boolean;
  step: Step;
}): FormatRowsForCreateReturn[] => {
  if (step === 'apportionment-flagged') step = 'apportionment';
  const result = [];
  for (const row of rows) {
    if (!row.isValid) {
      continue;
    }

    for (const headerName of Object.keys(row.cells)) {
      const rowMetaData: rowMetaDataProps = {
        step,
        stateValue: [row.jurisdictionId ?? ''],
        jurisdictionId: row.jurisdictionId
      };
      if (level === 'state' && step === 'modifications') {
        setRowMetaDataForStateModifications(row, step, rowMetaData);
      }

      const newEntry = {
        accountId: row.accountId || NULL_UUID,
        creditName: step === 'credits' ? row.cells['Credit Name'].value : row.creditName,
        level,
        step: rowMetaData.step,
        columnName: translateColumnName(headerName),
        value:
          (level === 'state' && step === 'tax-effected' && headerName === 'State') ||
          (level === 'state' && step === 'modifications' && headerName === 'State')
            ? rowMetaData.stateValue
            : row.cells[headerName].value,
        rowName: getRowNameByStepAndLevel(level, step, row, rowNameKey),
        jurisdictionId: level === 'state' && step === 'modifications' ? undefined : rowMetaData.jurisdictionId,
        rowId: row.cells[headerName].rowId
      };

      if (
        !row.cells[headerName].isIgnored &&
        row.cells[headerName].isValid &&
        row.cells[headerName].isNew &&
        (shouldCreateNewRows || row.existingRowName)
      ) {
        result.push(newEntry);
      }
    }
  }

  return result;
};

interface FormatRowsForUpdateReturn {
  entityFinancialsTraceId: string | null;
  value: any;
}

export const formatRowsForUpdate = (rows: ComposedRow[], level?: Level, step?: Step): FormatRowsForUpdateReturn[] => {
  const result: FormatRowsForUpdateReturn[] = [];

  for (const row of rows) {
    if (!row.isValid) {
      continue;
    }

    for (const headerName of Object.keys(row.cells)) {
      if (
        !row.cells[headerName].isIgnored &&
        row.cells[headerName].isValid &&
        !row.cells[headerName].isSameValue &&
        row.cells[headerName].entityFinancialsTraceId
      ) {
        const rowMetaData: rowMetaDataProps = {
          step,
          stateValue: [row.jurisdictionId ?? ''],
          jurisdictionId: row.jurisdictionId
        };
        if (level === 'state' && step === 'modifications') {
          setRowMetaDataForStateModifications(row, step, rowMetaData);
        }

        result.push({
          entityFinancialsTraceId: row.cells[headerName].entityFinancialsTraceId,
          value:
            (level === 'state' && step === 'tax-effected' && headerName === 'State') ||
            (level === 'state' && step === 'modifications' && headerName === 'State')
              ? rowMetaData.stateValue
              : row.cells[headerName].value
        });
      }
    }
  }

  return result;
};

export const formatRowsForFederalPtbiCreate = ({
  level,
  rows,
  step,
  creditName
}: {
  level: Level;
  rows: ComposedRow[];
  step: Step;
  creditName: string | undefined;
}) => {
  const rowNameTranslation: Record<string, string> = {
    'Pre-Tax Book Income Amount': 'Pre-Tax Book Income / (Loss)',
    'Adjustments Amount': 'Adjustments'
  };

  return rows.map((row: any) => {
    return {
      accountId: NULL_UUID,
      level,
      step,
      columnName: translateColumnName('Amount'),
      creditName,
      value: row.amount,
      rowName: rowNameTranslation[row.rowName]
    };
  });
};

export const formatRowsForFederalPtbiUpdate = (rows: ComposedRow[]) => {
  return rows.map((row) => {
    return {
      entityFinancialsTraceId: row.entityFinancialsTraceId,
      value: row.amount
    };
  });
};

interface GetFormattedRows {
  checkmarkIndices: boolean[];
  creditName?: string;
  level: Level;
  rows: ComposedRow[];
  step: Step;
  fileName: string;
}

const addNoteColumn = (fileName: string): ComposedRow['cells'] => {
  const timestamp = formatDateLong(new Date());
  const value = `Adjustment created through data import ${timestamp} by '${fileName}' without any amounts associated`;

  return {
    note: {
      value,
      formattedValue: value,
      isNew: true,
      isIgnored: false,
      isSameValue: false,
      isValid: true,
      entityFinancialsTraceId: null,
      rowId: uuid.v1()
    }
  };
};

const getRowsToCreate = ({
  checkmarkIndices,
  level,
  step,
  creditName,
  rows,
  fileName
}: {
  checkmarkIndices: boolean[];
  rows: ComposedRow[];
  level: Level;
  step: Step;
  creditName?: string;
  fileName: string;
}) => {
  const isCreditNameFederalTemporary = creditName === 'federal.temporary';
  const isCreditsStep = step === 'credits';

  switch (`${level}.${step}`) {
    case 'federal.ptbi':
      return rows.filter((row, index) => checkmarkIndices[index] && !row.hasPreviousValue && row.isValid);
    default:
      return rows.filter((row, index) => {
        let rowHasNewValues = Object.values(row.cells ?? {}).some((cell: any) => !cell.hasPreviousValue);
        const hasRowBeenSelectedForImport = checkmarkIndices[index];
        const isCreditNameOfRowFromFederalTemporaryScreen = row.creditName?.includes('federal.temporary');
        const isCreditNameOfRowSameAsCreditName = row.creditName === creditName;
        const rowHasNoUnignoredColumns = !Object.values(row.cells ?? {}).some((cell: any) => !cell.isIgnored);
        const isEmptyNewRowWithPrimaryKeys = row.isValid && rowHasNoUnignoredColumns;

        if (isEmptyNewRowWithPrimaryKeys) {
          rows[index].cells = { ...rows[index].cells, ...addNoteColumn(fileName) };
          row = rows[index];
          rowHasNewValues = true;
        }

        return (
          hasRowBeenSelectedForImport &&
          (row.isNew || rowHasNewValues) &&
          ((isCreditNameFederalTemporary && isCreditNameOfRowFromFederalTemporaryScreen) ||
            isCreditsStep ||
            isCreditNameOfRowSameAsCreditName)
        );
      });
  }
};

const getRowsToUpdate = ({
  checkmarkIndices,
  creditName,
  level,
  rows,
  step
}: {
  checkmarkIndices: boolean[];
  level: Level;
  rows: ComposedRow[] | Row[];
  step: Step;
  creditName?: string;
}) => {
  switch (`${level}.${step}`) {
    case 'federal.ptbi':
      return (rows as Row[]).filter(
        (row, index) =>
          checkmarkIndices[index] &&
          row.hasPreviousValue &&
          row.isValid &&
          row.creditName === creditName &&
          !row.isSameValue
      ) as ComposedRow[];
    default:
      return (rows as ComposedRow[]).filter((row, index) => checkmarkIndices[index]);
  }
};

const getFormattedRowsToCreate = ({
  level,
  step,
  rowsToCreate,
  creditName,
  shouldCreateNewRows
}: {
  level: Level;
  step: Step;
  rowsToCreate: ComposedRow[];
  creditName?: string;
  shouldCreateNewRows: boolean;
}) => {
  switch (`${level}.${step}`) {
    case 'federal.ptbi':
      return formatRowsForFederalPtbiCreate({ level, rows: rowsToCreate, step, creditName });
    default:
      return formatRowsForCreate({
        level,
        rowNameKey: ROW_NAME_KEY_MAP[level][step]!,
        rows: rowsToCreate,
        shouldCreateNewRows,
        step
      });
  }
};

const getFormattedRowsToUpdate = ({
  level,
  step,
  rowsToUpdate
}: {
  level: Level;
  step: Step;
  rowsToUpdate: ComposedRow[];
}) => {
  switch (`${level}.${step}`) {
    case 'federal.ptbi':
      return formatRowsForFederalPtbiUpdate(rowsToUpdate);
    default:
      return formatRowsForUpdate(rowsToUpdate, level, step);
  }
};

export const getFormattedRows = ({ checkmarkIndices, creditName, level, rows, step, fileName }: GetFormattedRows) => {
  const rowsToCreate = getRowsToCreate({ level, step, rows, fileName, creditName, checkmarkIndices });
  const rowsToUpdate = getRowsToUpdate({ level, step, rows, creditName, checkmarkIndices });
  const shouldCreateNewRows = getDoesAllowNewRows(level, step);
  const formattedRowsToCreate = getFormattedRowsToCreate({
    level,
    step,
    rowsToCreate,
    creditName,
    shouldCreateNewRows
  });
  const formattedRowsToUpdate = getFormattedRowsToUpdate({ level, step, rowsToUpdate });

  return [formattedRowsToCreate, formattedRowsToUpdate];
};

interface FindExistingRowProps {
  creditName?: string;
  entitySubJurisdictions: SubJurisdiction[];
  finData: FinancialInfo[];
  level: Level;
  step: Step;
  keyHeaderName: string;
  composedRow: any;
}

const findExistingRow = ({
  entitySubJurisdictions = [],
  finData,
  composedRow,
  level,
  step,
  keyHeaderName
}: FindExistingRowProps) => {
  return (
    finData.find((row: FinancialInfo) => {
      let logicCheck = row.rowName.toLowerCase() === composedRow.cells[keyHeaderName].value?.toString()?.toLowerCase();
      if (step === 'modifications' && level === 'state') {
        logicCheck =
          row.rowName.toLowerCase() === composedRow.cells[keyHeaderName].value?.toString()?.toLowerCase() &&
          row.step.toLowerCase() ===
            `${composedRow.step as string}.${composedRow.cells['Modification Type'].value.toLowerCase() as string}`;
      }

      if (step === 'credits') {
        logicCheck =
          row.rowName.toLowerCase() === composedRow.cells[keyHeaderName].value?.toString()?.toLowerCase() &&
          row.creditName?.toLowerCase() === composedRow.cells['Credit Name'].value?.toString()?.toLowerCase();
      }

      return logicCheck;
    }) ??
    entitySubJurisdictions.find(
      (subJur: SubJurisdiction) =>
        subJur.name.toLowerCase() === composedRow.cells[keyHeaderName].value?.toString()?.toLowerCase()
    )
  );
};

const parseMultiStateRows = (result: any) => {
  // Filter to Unique [Modifiction Type, Account Description, and jurisdictionId] of the last updated amount
  let stateModificationResult = result.filter(
    (stateRow: any, index: any, stateModificationArray: any) =>
      stateModificationArray.findLastIndex((stateRowInner: any) =>
        ['Modification Type', 'Account Description', 'State'].every(
          (column) =>
            stateRowInner.cells[column]?.value?.toString()?.toLowerCase() ===
            stateRow.cells[column]?.value?.toString()?.toLowerCase()
        )
      ) === index
  );
  // Combine States of duplicate account description and modification type
  for (const stateRow of stateModificationResult) {
    const stateIds = [];
    const stateNames = [];
    for (const stateRowInner of stateModificationResult) {
      if (
        stateRow.cells['Modification Type']?.value?.toString()?.toLowerCase() ===
          stateRowInner.cells['Modification Type']?.value?.toString()?.toLowerCase() &&
        stateRow.cells['Account Description']?.value?.toString()?.toLowerCase() ===
          stateRowInner.cells['Account Description']?.value?.toString()?.toLowerCase()
      ) {
        stateIds.push(stateRowInner?.jurisdictionId);
        stateNames.push(stateRowInner?.cells?.State?.value);
      }
    }

    stateRow.stateJurisdictionIds = stateIds;
    stateRow.stateNames = stateNames;
  }

  // Filter to Unique [Modifiction Type, Account Description] of the last updated amount
  stateModificationResult = stateModificationResult
    .filter(
      (stateRow: any, index: any, stateModificationArray: any) =>
        stateModificationArray.findLastIndex((stateRowInner: any) =>
          ['Modification Type', 'Account Description'].every(
            (column) =>
              stateRowInner.cells[column]?.value?.toString()?.toLowerCase() ===
              stateRow.cells[column]?.value?.toString()?.toLowerCase()
          )
        ) === index
    )
    // Update index, update existing state and jurisdiction to pass API validation for batching financial data
    .map((stateRow: any, index: any) => {
      stateRow.index = index;

      if (stateRow?.cells?.State?.value) {
        stateRow.cells.State.value = stateRow?.stateNames;
      }

      if (stateRow?.cells?.['Modification Type']?.value) {
        stateRow.cells['Modification Type'].value = stateRow?.cells?.['Modification Type']?.value?.toLowerCase();
      }

      stateRow.jurisdictionId = stateRow?.stateJurisdictionIds;
      return {
        ...stateRow
      };
    });

  return stateModificationResult;
};

export const composeRows = ({
  creditName,
  selectedData,
  finData,
  level,
  step,
  keyHeaderNames,
  entitySubJurisdictions,
  subJurisdictionId
}: ComposeRowProps): ComposedRow[] => {
  const keyHeaderName = keyHeaderNames[0];
  let result: any[] = [];
  const keyColumnValues = selectedData?.[keyHeaderName]?.rawValues ?? [];

  const subJurisdictionByName: Record<string, SubJurisdiction> = parseSubjurisdictionsByName(entitySubJurisdictions);

  // Build initial cell data -- This does not need the full keyHeaderArray
  for (const [i] of keyColumnValues.entries()) {
    const newEntry: any = {
      accountId: '',
      index: i,
      isNew: true,
      isValid: false,
      cells: {},
      level,
      step,
      creditName,
      jurisdictionId: subJurisdictionId
    };

    for (const headerName of Object.keys(selectedData)) {
      if (newEntry.cells[headerName]) {
        continue;
      }

      newEntry.cells[headerName] = {
        value: selectedData[headerName].rawValues[i],
        formattedValue: selectedData[headerName].formattedValues[i]
      };

      if (headerName?.toLowerCase() === CELLS_WITH_JURISDICTION_NAME[level][step]?.toLowerCase()) {
        const parsedSubjurisdictionName: string = formatSubjurisdictionName(
          newEntry.cells[headerName].value
        ).toLowerCase();
        const subjurisdictionFound: SubJurisdiction = subJurisdictionByName[parsedSubjurisdictionName];
        if (subjurisdictionFound) {
          newEntry.jurisdictionId = subjurisdictionFound.subJurisdictionId;
          newEntry.cells[headerName].value = subjurisdictionFound.name;
        }
      }
    }

    result.push(newEntry);
  }

  // Do not add row with empty keyHeader for entity detail data tab
  result = result.filter((res) => res.cells[keyHeaderName].value !== '');

  // Aggregate data based on selected entity tab data
  if (level === 'state' && step === 'modifications') {
    result = parseMultiStateRows(result);
  }

  // Actual validation of cell data
  result = validateCells({
    result,
    finData,
    level,
    step,
    entitySubJurisdictions,
    creditName,
    keyHeaderNames
  });

  return result;
};

interface validateCellsProps {
  result: any;
  finData: FinancialInfo[];
  level: Level;
  step: Step;
  entitySubJurisdictions: SubJurisdiction[];
  creditName?: string;
  keyHeaderNames: string[];
}

/* eslint-disable-next-line complexity */
const validateCells = ({
  result,
  finData,
  level,
  step,
  entitySubJurisdictions,
  creditName,
  keyHeaderNames
}: validateCellsProps) => {
  const finDataTable = createFinancialInfoTable(finData, level, step);
  const otherColumnsSet = new Set(getOtherColumns(level, step));
  const subJurisdictionNamesSet = new Set(entitySubJurisdictions.map((subJur: any) => subJur.name));

  for (const res of result) {
    const rowId = uuid.v1();
    const existingRow = findExistingRow({
      creditName,
      entitySubJurisdictions,
      finData,
      composedRow: res,
      level,
      step,
      keyHeaderName: keyHeaderNames[0]
    });
    res.isNew = !existingRow;
    for (const cell of Object.keys(res.cells)) {
      let cellIsValid = validateCellValue({
        isExistingRow: Boolean(existingRow),
        value: res.cells[cell].value,
        headerName: cell,
        level,
        step,
        subJurisdictionNamesSet
      });
      let subJurisdictionValidationArray: boolean[];

      if (level === 'state' && step === 'modifications' && cell === 'State' && !cellIsValid) {
        // filters out invalid states
        subJurisdictionValidationArray = generateSubJurValidationArray(res.cells[cell].value, subJurisdictionNamesSet);
        res.cells.State.value = filterOutInvalidStates(res.cells.State.value, subJurisdictionValidationArray);
        res.stateNames = res.cells.State.value;
        res.jurisdictionId = filterOutInvalidStates(res.jurisdictionId, subJurisdictionValidationArray);
        res.stateJurisdictionIds = res.jurisdictionId;

        // post-filter validation evaluation (is set to true after filtering if any valid states exist)
        cellIsValid = subJurisdictionValidationArray.includes(true);
      }

      res.cells[cell] = {
        value: res.cells[cell].value,
        formattedValue: res.cells[cell].formattedValue,
        isNew: false,
        isIgnored: !otherColumnsSet.has(cell) || res.cells[cell].value === '' || res.cells[cell]?.length < 1,
        isSameValue: false,
        isValid: cellIsValid,
        entityFinancialsTraceId: null,
        rowId: null
      };

      if (keyHeaderNames.length > 1) {
        if (step === 'modifications' && level === 'state') {
          const checkCellIsValid = keyHeaderNames
            .map((key) => {
              return res.cells[key].isValid;
            })
            .every((key) => key);

          res.isValid = checkCellIsValid;

          /* eslint-disable-next-line max-depth */
          if (cell === 'Account Description') {
            res.cells[cell].value =
              (existingRow as FinancialInfo)?.rowName ??
              (existingRow as SubJurisdiction)?.name ??
              res.cells[cell].value.toString();
          }
        }
      } else if (cell.toLowerCase() === keyHeaderNames[0].toLowerCase()) {
        res.isValid = cellIsValid;
        res.cells[cell].value =
          (existingRow as FinancialInfo)?.rowName ??
          (existingRow as SubJurisdiction)?.name ??
          res.cells[cell].value.toString();
      }

      if (LEVEL_STEP_TO_RENDER_VALUE_FUNCTION[level][step]) {
        res.renderValueFunction = LEVEL_STEP_TO_RENDER_VALUE_FUNCTION[level][step];
      }

      if (!existingRow) {
        res.cells[cell].isNew = true;
        if (cell.toLowerCase() !== keyHeaderNames[0].toLowerCase()) {
          res.cells[cell].rowId = rowId;
        }

        continue;
      }

      if ((existingRow as FinancialInfo).creditName) {
        res.creditName = (existingRow as FinancialInfo).creditName;
      }

      if (res.cells[cell].isIgnored) {
        continue;
      }

      const finDataTableKey = () => {
        if (level === 'federal' && step === 'deferred') {
          return `deferred${(
            (existingRow as FinancialInfo).rowName ?? (existingRow as SubJurisdiction).name
          ).toLowerCase()}${translateColumnName(cell)}`;
        }

        if (level === 'state' && step === 'modifications') {
          /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
          return `${step}.${res.cells['Modification Type'].value.toLowerCase()}${(
            (existingRow as FinancialInfo).rowName.toLowerCase() ?? (existingRow as SubJurisdiction).name
          ).toLowerCase()}${translateColumnName(cell)}`;
        }

        if (step === 'credits') {
          return `${res.creditName as string}${(
            (existingRow as FinancialInfo).rowName ?? (existingRow as SubJurisdiction).name
          ).toLowerCase()}${translateColumnName(cell)}`;
        }

        if (level === 'state' && step === 'deferred' && res.creditName === 'state.modifications.temporary') {
          return `${formatSubjurisdictionName(res.cells.State.value.toLowerCase())} - ${(
            (existingRow as FinancialInfo).rowName.toLowerCase() ?? (existingRow as SubJurisdiction).name
          ).toLowerCase()}${translateColumnName(cell)}`;
        }

        return `${(
          (existingRow as FinancialInfo).rowName ?? (existingRow as SubJurisdiction).name
        ).toLowerCase()}${translateColumnName(cell)}`;
      };

      const matchedFinData = finDataTable[finDataTableKey()];
      if (matchedFinData) {
        res.cells[cell].isNew = false;
        let checkIfMatched = matchedFinData.value === res.cells[cell].value;
        // Need to match based on array if state modification state data
        const modificationsStateCheck = level === 'state' && step === 'modifications' && cell === 'State';
        const taxEffectedStateCheck = level === 'state' && step === 'tax-effected' && cell === 'State';
        if (taxEffectedStateCheck) {
          checkIfMatched = String(matchedFinData?.value?.[0]) === res?.jurisdictionId;
        }

        if (modificationsStateCheck) {
          const sortedMatchedData = [...matchedFinData.value].sort((a, b) => a.localeCompare(b));
          const sortedJurisdictionData = [...res.jurisdictionId].sort((a, b) => a.localeCompare(b));
          checkIfMatched = JSON.stringify(sortedMatchedData) === JSON.stringify(sortedJurisdictionData);
        }

        if (checkIfMatched) {
          res.cells[cell].isSameValue = true;
        } else {
          res.cells[cell].entityFinancialsTraceId = finData[matchedFinData.index].entityFinancialsTraceId;
          res.cells[cell].isSameValue = false;
          res.cells[cell].rowId = finData[matchedFinData.index].rowId;
        }
      } else {
        res.cells[cell].isNew = true;
        res.existingRowName = (existingRow as FinancialInfo).rowName ?? (existingRow as SubJurisdiction).name;
        res.cells[cell].rowId = (existingRow as FinancialInfo).rowId;
      }

      res.accountId = (existingRow as FinancialInfo).accountId;
    }
  }

  return result;
};

export const isMoreThanSingleCellSelection = (selection: string) => {
  return selection?.includes(':');
};

export const dispatchWarning = (message: string, dispatch: Dispatch) => {
  dispatch(
    enqueueNotification({
      message,
      options: {
        variant: 'warning'
      }
    })
  );
};

interface SpreadsheetSelectionHandleDoneEvent {
  column: Column;
  dispatch: Dispatch;
  keyHeaderNames: string[];
  level: Level;
  nRows: number;
  selection: string;
  setSelectedField: any;
  setNRows: any;
  sheet: XLSX.Sheet;
  step: Step;
  showReservedWordsError: boolean;
}

export const spreadsheetSelectionHandleDoneEvent = ({
  column,
  dispatch,
  keyHeaderNames,
  level,
  nRows,
  selection,
  setSelectedField,
  setNRows,
  sheet,
  step,
  showReservedWordsError
}: SpreadsheetSelectionHandleDoneEvent) => {
  if (SINGLE_CELL_TABS[level][step] && isMoreThanSingleCellSelection(selection)) {
    dispatchWarning('Please select a single cell for each column', dispatch);

    return;
  }

  if (selection === null) {
    dispatchWarning('Please make a selection', dispatch);

    return;
  }

  const { leftCol, rightCol, numRows } = getColumnsAndRowsFromSelection(selection);

  const columnsMatch = selection && leftCol === rightCol;
  const result = getValuesFromSheet({ selection, sheet });

  if ((column.isNumber || column.isPercentage) && result.raw.some((res) => Number.isNaN(Number(res[0])))) {
    dispatchWarning('Attempting to enter a non-numerical value in a numerical field', dispatch);
    return;
  }

  if (column.field === 'name' || column.field === 'creditName') {
    const reservedWord = result.raw.find((res) => reservedNames.includes(String(res).toLowerCase()));
    if (showReservedWordsError && reservedWord) {
      dispatchWarning(
        `'${String(
          reservedWord
        )}' is one of a list of reserved words in the system and must not be used for naming. Please choose a different name.`,
        dispatch
      );
      return;
    }
  }

  if (nRows === 0 && columnsMatch) {
    setNRows(numRows!);
  } else {
    const selectionHasIncorrectNumberOfRows = numRows !== nRows || nRows === 0;
    if (selectionHasIncorrectNumberOfRows) {
      dispatchWarning('Selected ranges must have the same number of rows', dispatch);
      return;
    }
  }

  if (columnsMatch) {
    if (column.headerName !== undefined) {
      const isColumnKeyHeaderEqual: boolean = keyHeaderNames.some((key) => key === column.headerName);
      if (isColumnKeyHeaderEqual && result.raw.length !== numRows) {
        dispatchWarning('This column may not have empty cells', dispatch);
        setSelectedField(null);
        return;
      }

      dispatch(
        saveDataImport({
          field: column.headerName,
          range: selection,
          formattedValues: result.formatted.flat(),
          rawValues: result.raw.flat()
        })
      );
    }

    setSelectedField(null);
  } else {
    dispatch(
      enqueueNotification({
        message: 'Selected range must be within one column',
        options: {
          variant: 'warning'
        }
      })
    );
    setSelectedField(null);
  }
};

interface TrialBalanceSpreadsheetSelectionHandleDoneEvent {
  column: Column;
  dispatch: Dispatch;
  selection: string;
  setSelectedField: any;
  sheet: XLSX.Sheet;
  dataImportSelection: any;
  setDataSelectionError: any;
  showReservedWordsError: boolean;
}

// eslint-disable-next-line complexity
export const trialBalanceSpreadsheetSelectionHandleDoneEvent = ({
  column,
  dispatch,
  selection,
  setSelectedField,
  sheet,
  dataImportSelection,
  setDataSelectionError,
  showReservedWordsError
}: TrialBalanceSpreadsheetSelectionHandleDoneEvent) => {
  if (selection === null) {
    dispatchWarning('Please make a selection', dispatch);

    return;
  }

  const result = getValuesFromSheet({ selection, sheet });
  // You can only select horizontally or vertically
  const resultLength = result?.raw?.length > 1 ? result?.raw?.length : result.raw?.[0].length;
  setDataSelectionError(null);

  if (column.field === 'accountNumber') {
    const accountNumbers = result.formatted.flat();
    const duplicateAccountNumbers = accountNumbers.filter((item, index) => accountNumbers.indexOf(item) !== index);
    if (duplicateAccountNumbers && duplicateAccountNumbers.length > 0) {
      const unqiueDuplicateAccounts = duplicateAccountNumbers
        .filter((c, index) => {
          return duplicateAccountNumbers.indexOf(c) === index;
        })
        .join(', ');
      setDataSelectionError(
        `Some account numbers are duplicate. Unselect the undesired numbers before proceeding. Duplicates: ${unqiueDuplicateAccounts}`
      );
      return;
    }
  }

  if ((column.isNumber || column.isPercentage) && result.raw.some((res) => Number.isNaN(Number(res[0])))) {
    dispatchWarning('Attempting to enter a non-numerical value in a numerical field', dispatch);
    return;
  }

  if (column.field === 'entityName') {
    const entityNameRegex = new RegExp(`^[a-zA-Z0-9${allowedSpecialCharacters}]*$`);
    const failedRegex = result.raw.find((res: any) => !entityNameRegex.test(String(res)));
    if (failedRegex) {
      setSelectedField(null);
      dispatchWarning(`Entity Name '${String(failedRegex)}' includes disallowed special character`, dispatch);
      return;
    }
  }

  if (
    column.field === 'entityName' &&
    dataImportSelection['Entity Number'] &&
    resultLength !== dataImportSelection['Entity Number']?.rawValues.length
  ) {
    setSelectedField(null);
    dispatchWarning('Entity Name and Entity Number must have the same selected range', dispatch);
    return;
  }

  if (
    column.field === 'entityNumber' &&
    dataImportSelection['Entity Name'] &&
    resultLength !== dataImportSelection['Entity Name']?.rawValues.length
  ) {
    setSelectedField(null);
    dispatchWarning('Entity Name and Entity Number must have the same selected range', dispatch);
    return;
  }

  if (
    column.field === 'accountNumber' &&
    dataImportSelection['Acc. Description'] &&
    resultLength !== dataImportSelection['Acc. Description']?.rawValues.length
  ) {
    setSelectedField(null);
    dispatchWarning('Account Number and Account Description must have the same selected range', dispatch);
    return;
  }

  if (column.field === 'accountDescription') {
    const reservedWord = result.raw.find((res) => reservedNames.includes(String(res).toLowerCase()));
    if (showReservedWordsError && reservedWord) {
      setSelectedField(null);
      dispatchWarning(
        `'${String(
          reservedWord
        )}' is one of a list of reserved words in the system and must not be used for naming. Please choose a different name.`,
        dispatch
      );
      return;
    }
  }

  if (
    column.field === 'accountDescription' &&
    dataImportSelection['Acc. Number'] &&
    resultLength !== dataImportSelection['Acc. Number']?.rawValues.length
  ) {
    setSelectedField(null);
    dispatchWarning('Account Number and Account Description must have the same selected range', dispatch);
    return;
  }

  if (column.headerName !== undefined) {
    dispatch(
      saveDataImport({
        field: column.headerName,
        range: selection,
        formattedValues: result.formatted.flat(),
        rawValues: result.raw.flat()
      })
    );
  }

  setSelectedField(null);
};

type setSheetSelectedRangeProps = {
  startRowIndex: number;
  endRowIndex: number;
  startColumnIndex: number;
  endColumnIndex: number;
  sheetRef: any;
};
export const setSheetSelectedRange = ({
  sheetRef,
  startColumnIndex,
  startRowIndex,
  endColumnIndex,
  endRowIndex
}: setSheetSelectedRangeProps) => {
  if (sheetRef) {
    /**
     * set and setEnd function implementations can be seen in
     * https://github.com/myliang/x-spreadsheet/blob/master/src/component/selector.js#L292
     */
    sheetRef.sheet?.selector?.set(startRowIndex, startColumnIndex);
    sheetRef.sheet?.selector?.setEnd(endRowIndex, endColumnIndex, false);
  }
};
