import { ComponentType, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { GroupEntitiesEditorProps } from './GroupEntitiesEditor';

import { useEntities } from '../../../../hooks';
import { Container, CustomGroup, Entity } from '../../../../models';
import { updateCustomGroupEntities } from '../../../../redux/customGroups';
import { selectCustomGroupsEntities } from '../../../../selectors/customGroups';
import LoadingWrapper from '../../../LoadingWrapper';

type GroupEntitiesEditorConnectorProps = {
  component: ComponentType<GroupEntitiesEditorProps>;
  currentContainer?: Container;
  group: CustomGroup | null;
  onCancel: () => void;
  onUpdate: () => void;
};

const GroupEntitiesEditorConnector = ({
  component: GroupEntitiesEditor,
  currentContainer,
  group,
  onCancel,
  onUpdate
}: GroupEntitiesEditorConnectorProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { entities: containerEntities } = useEntities();
  const groupsEntities = useSelector(selectCustomGroupsEntities);
  const dispatch = useDispatch();

  const handleOnSave = async (entities: Entity[]) => {
    setIsLoading(true);

    const entityIds = entities.map((entity) => entity.entityId);

    // eslint-disable-next-line @typescript-eslint/await-thenable
    await dispatch(updateCustomGroupEntities({ action: 'ADD', entityIds, groupId: group!.groupId }));

    setIsLoading(false);
    onUpdate();
  };

  const handleOnDelete = async (entities: Entity[]) => {
    setIsLoading(true);

    const entityIds = entities.map((entity) => entity.entityId);

    // eslint-disable-next-line @typescript-eslint/await-thenable
    await dispatch(updateCustomGroupEntities({ action: 'REMOVE', entityIds, groupId: group!.groupId }));

    setIsLoading(false);
    onUpdate();
  };

  return (
    <LoadingWrapper isLoading={isLoading}>
      <GroupEntitiesEditor
        groupEntities={groupsEntities[group?.groupId ?? ''] ?? []}
        currentContainer={currentContainer}
        containerEntities={containerEntities}
        onCancel={onCancel}
        onSave={handleOnSave}
        onDelete={handleOnDelete}
      />
    </LoadingWrapper>
  );
};

export default GroupEntitiesEditorConnector;
