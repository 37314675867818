import { Theme } from '@material-ui/core/styles/createMuiTheme';

export const getLinkButtonCssProps = (theme: Theme, isReadOnlyUser?: boolean) => ({
  ...(isReadOnlyUser && { display: 'none' }),
  backgroundColor: theme.palette.secondary.dark,
  minWidth: 'fit-content',
  paddingLeft: '6px',
  paddingRight: '6px',
  marginLeft: '12px',
  borderRadius: '4px',
  marginTop: '-2.5px'
});
