import { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import tinycolor from 'tinycolor2';

interface Props {
  [key: string]: any;
  color: string;
  withLightBackground?: boolean;
}

const useStyles = makeStyles(() => ({
  outerCircle: {
    display: 'flex',
    boxSizing: 'border-box',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    width: '16px',
    height: '16px'
  },
  innerCircle: {
    zIndex: 100,
    borderRadius: '50%',
    transform: 'none',
    filter: 'none',
    width: '10px',
    height: '10px',
    margin: '1px'
  }
}));
const ColorElement = ({ color, withLightBackground, ...props }: Props) => {
  const [lightColor, setLightColor] = useState<string>(tinycolor(color).darken(20).toString());
  const classes = useStyles();

  useEffect(() => {
    setLightColor(tinycolor(color).darken(20).toString());
  }, [color]);

  return withLightBackground ? (
    <div
      className={classes.outerCircle}
      style={{
        borderColor: color,
        backgroundColor: lightColor
      }}
      {...props}
    >
      <div
        className={classes.innerCircle}
        style={{
          backgroundColor: color
        }}
      />
    </div>
  ) : (
    <div
      className={classes.outerCircle}
      style={{
        backgroundColor: color
      }}
      {...props}
    />
  );
};

export default ColorElement;
