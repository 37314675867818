import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as XLSX from 'xlsx';

import {
  calculateTotalsRange,
  createAccountsUploadArray,
  createEntitiesUploadArray,
  createEntityRangeObject,
  createAccountsRangeObject
} from './utils';
import ValidationModal from './ValidationModal';

import '../../../Spreadsheet/xspreadsheet.css';
import { useSpreadsheet } from '../../../../hooks';
import { clearDataImport } from '../../../../redux/dataImport';
import { uploadData } from '../../../../redux/uploadReview';
import { selectDataImportSelection, selectFileName } from '../../../../selectors';
import ErrorMessageBanner from '../../../ErrorMessageBanner/ErrorMessageBanner';
import SpreadsheetSelection from '../../../Spreadsheet/components/SpreadsheetSelection';
import { Options, SpreadsheetData } from '../../../Spreadsheet/Spreadsheet.proptype';
import {
  getValuesFromSheet,
  setSheetSelectedRange,
  trialBalanceSpreadsheetSelectionHandleDoneEvent
} from '../../../Spreadsheet/utils';
import SpreadsheetCellSelectionBar from '../../../SpreadsheetCellSelectionBar/SpreadsheetCellSelectionBar';

const useStyles = makeStyles((theme) => ({
  inputField: {
    alignItems: 'center',
    border: `1px solid ${theme.palette.info.dark}`,
    borderRadius: '4px',
    boxShadow: '0 0 9px 0 rgba(0,0,0,0.05)',
    display: 'flex',
    height: '36px',
    margin: '4px 0 14px 0',
    paddingLeft: '12px',
    paddingRight: '1px',
    width: '98%'
  },
  button: {
    fontFamily: theme.typography.body2.fontFamily,
    letterSpacing: '0.25px',
    lineHeight: '18px',
    borderRadius: '4px',
    border: '1px solid',
    borderColor: 'transparent'
  },
  doneButton: {
    marginLeft: '0.25rem'
  },
  cancelButton: {
    marginLeft: 'auto'
  },
  validateButton: {
    lineHeight: '16px',
    height: '36px',
    width: '209px',
    borderRadius: '4px',
    margin: '4px 8px 4px 32px'
  }
}));

interface SpreadsheetProps {
  options: Options;
  data?: SpreadsheetData;
  height: string;
  width: string;
  workbook: XLSX.WorkBook;
}

const formatText = (text: string | number) => {
  return `${text}`.trim();
};

const TrialBalanceSpreadsheet = ({ options, data, height, width, workbook }: SpreadsheetProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const dataImportSelection = useSelector(selectDataImportSelection);
  const [selectedField, setSelectedField] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [canUploadSelectedData, setCanUploadSelectedData] = useState(false);
  const [dataSelectionError, setDataSelectionError] = useState(null);
  const {
    prov3045EditableSpreadsheetSelection,
    prov3127TrimAccountsDescriptions: shouldTrimText,
    prov3736ReservedWordValidation: showReservedWordsError
  } = useFlags();

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const dispatch = useDispatch();

  const tbColumns = [
    {
      field: 'entityName',
      headerName: 'Entity Name',
      isNumber: false,
      isActive: selectedField === 'Entity Name'
    },
    {
      field: 'entityNumber',
      headerName: 'Entity Number',
      isNumber: true,
      isActive: selectedField === 'Entity Number'
    },
    {
      field: 'accountNumber',
      headerName: 'Acc. Number',
      isNumber: false,
      isActive: selectedField === 'Acc. Number'
    },
    {
      field: 'accountDescription',
      headerName: 'Acc. Description',
      isNumber: false,
      isActive: selectedField === 'Acc. Description'
    }
  ];

  const selectedFieldColumn = tbColumns?.find((col) => col.headerName === selectedField);

  useEffect(() => {
    setCanUploadSelectedData(Object.keys(dataImportSelection).length === 4);
  }, [dataImportSelection]);

  const { range, activeSheet, sheetEl, sheetRef, setRange } = useSpreadsheet({
    data,
    options,
    selectedField,
    setSelectedField,
    workbook,
    overrideColumnSelection: true
  });

  let totalsRange;
  let accountsUploadArray;
  let entitiesUploadArray;
  let entityRangeObject;
  let accountRangeObject;

  if (
    'Entity Number' in dataImportSelection &&
    'Entity Name' in dataImportSelection &&
    'Acc. Number' in dataImportSelection &&
    'Acc. Description' in dataImportSelection
  ) {
    const entityRange = dataImportSelection['Entity Number'].range;
    const accountRange = dataImportSelection['Acc. Number'].range;

    if (entityRange && accountRange) {
      totalsRange = calculateTotalsRange(entityRange, accountRange);
    }

    const selection = totalsRange ?? '';
    const sheet: XLSX.WorkSheet = activeSheet!;
    const result = getValuesFromSheet({ selection, sheet });
    const sheetName = sheetRef.current.sheet.data.name;
    accountsUploadArray = createAccountsUploadArray(dataImportSelection, result);
    entitiesUploadArray = createEntitiesUploadArray(dataImportSelection);
    entityRangeObject = createEntityRangeObject(dataImportSelection, sheetName);
    accountRangeObject = createAccountsRangeObject(dataImportSelection, sheetName);
  }

  const fileName = useSelector(selectFileName);
  const currentTime = new Date().toISOString();
  const userId = 1;
  const requestBody = {
    trialBalance: {
      data: {
        accounts: accountsUploadArray,
        entities: entitiesUploadArray
      },
      metadata: {
        fileName,
        ranges: {
          entities: entityRangeObject,
          glAccounts: accountRangeObject
        },
        updatedOn: currentTime,
        userId
      }
    }
  };

  const handleValidateClick = () => {
    if (shouldTrimText) {
      requestBody.trialBalance.data.accounts = requestBody.trialBalance.data.accounts?.map((acc) => ({
        ...acc,
        description: formatText(acc.description),
        id: formatText(acc.id)
      }));
      requestBody.trialBalance.data.entities = requestBody.trialBalance.data.entities?.map((ent) => ({
        ...ent,
        name: formatText(ent.name),
        id: formatText(ent.id)
      }));
    }

    dispatch(uploadData(requestBody));
    history.push('/');
  };

  const autoSelectCells = (selectedRangeForHeaderName: string) => {
    setRange(selectedRangeForHeaderName);
    const decodedRange = XLSX.utils.decode_range(selectedRangeForHeaderName);

    setSheetSelectedRange({
      sheetRef: sheetRef.current,
      startRowIndex: decodedRange.s.r,
      startColumnIndex: decodedRange.s.c,
      endRowIndex: decodedRange.e.r,
      endColumnIndex: decodedRange.e.c
    });
  };

  const onFieldSelected = (headerName: string) => {
    setSelectedField(headerName);

    const selectedRangeForHeaderName = dataImportSelection[headerName]?.range;

    if (selectedRangeForHeaderName) {
      autoSelectCells(selectedRangeForHeaderName);
    }
  };

  const handleClearSelectionEvent = (headerName?: string) => {
    if (headerName !== undefined) {
      dispatch(
        clearDataImport({
          headerName
        })
      );
    }

    setSelectedField(null);
  };

  const handleCellSelectionInputBlur = (inputSelection: string) => {
    autoSelectCells(inputSelection);
  };

  return (
    <Box style={{ height: height ?? '100%', width: width ?? '100%', marginTop: '20px', marginLeft: '25px' }}>
      {dataSelectionError && <ErrorMessageBanner message={dataSelectionError} />}

      <div>{t('trial-balance-upload')}</div>
      <div style={{ display: 'flex' }}>
        <SpreadsheetSelection
          dataImportSelection={dataImportSelection}
          stepColumns={tbColumns}
          onFieldSelected={onFieldSelected}
        />
        <Button
          className={classes.validateButton}
          variant="contained"
          color="primary"
          disabled={!canUploadSelectedData}
          onClick={handleOpen}
        >
          {t('upload-selected-data')}
        </Button>
      </div>
      <ValidationModal isOpen={isOpen} handleClick={handleValidateClick} handleClose={handleClose} />
      {selectedField &&
        (prov3045EditableSpreadsheetSelection ? (
          <SpreadsheetCellSelectionBar
            range={range}
            onBlur={handleCellSelectionInputBlur}
            onCancel={() => {
              handleClearSelectionEvent(selectedField);
            }}
            onDone={(inputSelection: string) => {
              trialBalanceSpreadsheetSelectionHandleDoneEvent({
                dispatch,
                column: selectedFieldColumn!,
                selection: inputSelection,
                setSelectedField,
                sheet: activeSheet!,
                dataImportSelection,
                setDataSelectionError,
                showReservedWordsError
              });
            }}
          />
        ) : (
          <Box className={classes.inputField}>
            {range}
            <Button
              className={`${classes.button} ${classes.cancelButton}`}
              color="primary"
              variant="contained"
              onClick={() => {
                handleClearSelectionEvent(selectedField);
              }}
            >
              {t('cancel')}
            </Button>
            <Button
              className={`${classes.button} ${classes.doneButton}`}
              color="primary"
              variant="contained"
              onClick={() => {
                trialBalanceSpreadsheetSelectionHandleDoneEvent({
                  dispatch,
                  column: selectedFieldColumn!,
                  selection: range ?? '',
                  setSelectedField,
                  sheet: activeSheet!,
                  dataImportSelection,
                  setDataSelectionError,
                  showReservedWordsError
                });
              }}
            >
              {t('done')}
            </Button>
          </Box>
        ))}
      <div
        ref={sheetEl}
        style={{ height: height ?? '100%', width: width ?? '100%' }}
        id="x-spreadsheet"
        aria-label="spreadsheet"
      />
    </Box>
  );
};

export default TrialBalanceSpreadsheet;
