import { GenericRecord } from './utils';

interface CalculatePermanentReturn {
  amount?: number;
  disallowedPct?: number;
  m1Adjustment?: number;
}

export const calculatePermanent = <T extends CalculatePermanentReturn>(inputs: T) => {
  let { amount, disallowedPct } = inputs ?? {};
  disallowedPct = disallowedPct || disallowedPct === 0 ? disallowedPct : 1;

  return {
    ...inputs,
    disallowedPct,
    m1Adjustment: (amount ?? 0) * disallowedPct
  };
};

export const calculateM1Adjustment = ({ amount, disallowedPct }: GenericRecord) => {
  return ((amount as number) ?? 0) * ((disallowedPct as number) ?? 1);
};
