import { useCallback, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Box, CircularProgress, makeStyles, Typography } from '@material-ui/core';

import { ContainerFromApi, UseContainersReturn } from '../../models';
import {
  containersOnFailed,
  containersOnReceived,
  containersOnSendingRequest,
  containersOnCurrentSwitched
} from '../../redux/containers';
import { selectContainers } from '../../selectors';
import HTTPService, { LambdaResponse } from '../../services/http';
import PlatformService from '../../services/platform';

const useStyles = makeStyles(() => ({
  containerProviderRoot: {
    display: 'flex',
    fontSize: '32px',
    width: '100vw',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const fetchContainers = async () => {
  return HTTPService.request<LambdaResponse<ContainerFromApi[]>>({
    method: 'get',
    apiUrlKey: 'principleUrl',
    relativePath: '/v1/container'
  });
};

export const ContainersProvider = ({ children }: { children: JSX.Element }) => {
  const dispatch = useDispatch();
  const containersState = useSelector(selectContainers);
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      dispatch(containersOnSendingRequest());

      try {
        const { data: containers } = await fetchContainers();
        dispatch(containersOnReceived(containers));
      } catch (error: unknown) {
        dispatch(containersOnFailed(error));
      }
    }

    if (!containersState.isLoading && !containersState.hasLoaded && !containersState.error) {
      void fetchData();
    }
  }, [containersState, dispatch]);

  const shouldRenderApp =
    containersState.containers.length > 0 && containersState.currentContainer && !containersState.error;

  const userHasContainers = containersState.containers.length > 0;

  if (containersState.isLoading) {
    return <CircularProgress />;
  }

  if (!userHasContainers) {
    return (
      <Box className={classes.containerProviderRoot}>
        <Typography variant="h1">{t('You do not have any Tax Periods assigned')}</Typography>
      </Box>
    );
  }

  return shouldRenderApp ? children : <CircularProgress />;
};

export function useContainers(): UseContainersReturn {
  const dispatch = useDispatch();
  const history = useHistory();
  const containersState = useSelector(selectContainers);
  const setCurrentContainerId: (containerId: string) => void = useCallback(
    async (containerId) => {
      try {
        // We need to abort calls before AND after the container change as there's
        // a period where we don't know for sure what is the working container
        HTTPService.abortPendingRequests();
        dispatch(containersOnSendingRequest());
        await PlatformService.switchContainers(containerId);
        HTTPService.abortPendingRequests();
        history.push('/');
        dispatch(containersOnCurrentSwitched(containerId));
      } catch (error: unknown) {
        dispatch(containersOnFailed(error));
      }
    },
    [dispatch, history]
  );

  return { ...containersState, setCurrentContainerId, fetchContainers };
}
