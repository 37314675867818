import { Reducer } from 'redux';

import { configurationInitialState } from './configuration.initialState';
import { ConfigurationStateInterface, ConfigActionTypes, SET_CONFIG_DATA } from './configuration.types';

const configurationReducer: Reducer<ConfigurationStateInterface, ConfigActionTypes> = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  configState: ConfigurationStateInterface = configurationInitialState,
  action: ConfigActionTypes
): ConfigurationStateInterface => {
  switch (action.type) {
    case SET_CONFIG_DATA:
      return {
        ...configState,
        ...action.payload
      };

    default:
      return configState;
  }
};

export default configurationReducer;
