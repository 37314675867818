import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import TimePeriodSelectedItemChip from './components/TimePeriodSelectedItemChip';
import TimePeriodSelectorPopover from './components/TimePeriodSelectorPopover';

import { ReactComponent as MenuOpenIcon } from '../../assets/img/Icon_MenuOpen.svg';
import { formatDate } from '../../utils';

const useStyles = makeStyles((theme) => ({
  button: {
    paddingTop: '3px',
    paddingBottom: '3px',
    width: '100%',
    marginTop: 0,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px'
  }
}));

type TimePeriodSelectorProps = {
  startDate: Date;
  endDate: Date;
  onClear: () => void;
  onSave: (startDate: Date, endDate: Date) => void;
};

const TimePeriodSelector = ({ startDate, endDate, onClear, onSave }: TimePeriodSelectorProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showAllTime, setShowAllTime] = useState(true);

  const showPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const hidePopover = () => {
    setAnchorEl(null);
  };

  const getSelectedPeriodWidget = () => {
    if (!showAllTime) {
      return (
        <Box style={{ marginLeft: '10px' }}>
          <TimePeriodSelectedItemChip
            text={`${formatDate(startDate?.toString()!, 'DD MMM YYYY')} - ${formatDate(
              endDate?.toString()!,
              'DD MMM YYYY'
            )}`}
            onClear={() => {
              onClear();
              setShowAllTime(true);
            }}
          />
        </Box>
      );
    }

    return <Box style={{ marginLeft: '10px' }}>{t('all-time')}</Box>;
  };

  return (
    <>
      <Button className={classes.button} onClick={showPopover}>
        {`${t('time-period')}: `}
        <span>{getSelectedPeriodWidget()}</span>
        <MenuOpenIcon />
      </Button>

      <TimePeriodSelectorPopover
        initialStartDate={startDate}
        initialEndDate={endDate}
        anchorEl={anchorEl}
        onClose={hidePopover}
        onSave={(startDate: Date, endDate: Date) => {
          onSave(startDate, endDate);
          setShowAllTime(false);
        }}
      />
    </>
  );
};

export default TimePeriodSelector;
