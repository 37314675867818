import { Box, Button, Dialog } from '@material-ui/core';

import HTTPService from '../../../services/http';

type DebugInfoModalProps = {
  data: string;
  isOpen: boolean;
  hasFinalizeContainerButton: boolean;
  onClose: () => void;
};

export const DebugDataModal = ({ data, isOpen, onClose, hasFinalizeContainerButton }: DebugInfoModalProps) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Box
        style={{
          padding: '3rem'
        }}
      >
        {hasFinalizeContainerButton && (
          <Box style={{ display: 'flex' }}>
            <Button
              style={{ backgroundColor: '#232A5C', color: '#EFEFFF', margin: 'auto' }}
              onClick={async () => {
                await HTTPService.request({
                  apiUrlKey: 'apiUrl',
                  method: 'post',
                  relativePath: `/v1/finalize-container`
                });
                window.location.reload();
              }}
            >
              Finalize Container
            </Button>
          </Box>
        )}
        <pre>{data}</pre>
      </Box>
    </Dialog>
  );
};
