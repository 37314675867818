import { createReducer } from '@reduxjs/toolkit';

import { enqueueNotification, closeNotification, removeNotification } from './notifications.actions';
import { notificationsInitialState } from './notifications.initialState';

export const notificationsReducer = createReducer(notificationsInitialState, (builder) => {
  builder
    .addCase(enqueueNotification, (state, action) => ({
      ...state,
      notifications: [
        ...state.notifications,
        {
          key: action.payload.message,
          message: action.payload.message,
          options: action.payload.options,
          dismissed: action.payload.options?.dismissed
        }
      ]
    }))
    .addCase(closeNotification, (state, action) => ({
      ...state,
      notifications: state.notifications.map((notification) =>
        action.payload === undefined || notification.key === action.payload
          ? { ...notification, dismissed: true }
          : { ...notification }
      )
    }))
    .addCase(removeNotification, (state, action) => ({
      ...state,
      notifications: state.notifications.filter((notification) => notification.key !== action.payload)
    }));
});
