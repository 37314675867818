import { Clone } from '../../../../../utils';
import { reducer as tabLayoutReducer, TOGGLE_ROWS, INIT } from '../../TabLayout/reducer';

// FIXME this should be fixed once we move to redux (or local state)
// @ts-expect-error
export function makeInitialState({
  attributes = [],
  assignedAttributeByRowId = {}
}: {
  attributes: any[];
  assignedAttributeByRowId: Record<string, any[]>;
} = {}) {
  const selected: any = [];
  for (const assignedAttributes of Object.values(assignedAttributeByRowId)) {
    for (const attribute of assignedAttributes) {
      if (!selected.includes(attribute)) {
        selected.push(attribute);
      }
    }
  }

  return {
    attributes,
    editedId: null,
    selected,
    ...formatAttributes(selected, { ...assignedAttributeByRowId })
  };
}

function formatAttributes(selected: any, assignedAttributeByRowId: Record<string, any[]>) {
  const assignedRowIdsByAttrId: Record<string, any[]> = {};
  for (const { id } of selected) {
    const rowIds = [];
    for (const [rowId, attributes] of Object.entries(assignedAttributeByRowId)) {
      if (attributes.some((attr: any) => attr.id === id)) {
        rowIds.push(rowId);
      }
    }

    assignedRowIdsByAttrId[id] = rowIds;
  }

  return { assignedAttributeByRowId, assignedRowIdsByAttrId };
}

export function reducer(state: any, { type, payload = {} }: any = {}) {
  switch (type) {
    case INIT: {
      const { attributes, assignedAttributeByRowId } = payload;
      return makeInitialState({ attributes, assignedAttributeByRowId });
    }

    case TOGGLE_ROWS: {
      const { editedId, assignedAttributeByRowId, attributes, selected } = state;
      let cloneAssignedAttributeByRowId = Clone.json(assignedAttributeByRowId);
      const edited = attributes.find(({ id }: any) => id === editedId);
      if (!edited) {
        return state;
      }

      for (const rowId of payload.rowIds) {
        let assignedAttributes = cloneAssignedAttributeByRowId[rowId];
        if (payload.areSelected) {
          assignedAttributes.splice(
            assignedAttributes.findIndex((attr: any) => attr.id === editedId),
            1
          );
          if (assignedAttributes.length === 0) {
            const { [rowId]: _, ...cloneWithoutRowId } = cloneAssignedAttributeByRowId;
            cloneAssignedAttributeByRowId = cloneWithoutRowId;
          }
        } else {
          if (!assignedAttributes) {
            assignedAttributes = [];
            cloneAssignedAttributeByRowId[rowId] = assignedAttributes;
          }

          assignedAttributes.push(edited);
        }
      }

      return {
        ...state,
        ...formatAttributes(selected, { ...cloneAssignedAttributeByRowId })
      };
    }

    default:
      return tabLayoutReducer(state, { type, payload });
  }
}
