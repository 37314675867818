import { Constants } from '@crossbordersolutions/pro-utils';

import { UiTabs } from '../../constants';
import { DataTypeFilterOption } from '../../models/auditTrail.interface';

export const tabsFilterOptions: DataTypeFilterOption[] = [
  { name: 'Federal RTP', id: `federal.${UiTabs.federal.rtp as string}`, type: 'tabs' },
  { name: 'Federal PTBI', id: `federal.${UiTabs.federal.ptbi as string}`, type: 'tabs' },
  { name: 'Federal Permanent', id: `federal.${UiTabs.federal.permanent as string}`, type: 'tabs' },
  { name: 'Federal Temporary', id: `federal.${UiTabs.federal.temporaryIncomeStatement as string}`, type: 'tabs' },
  { name: 'Federal NOL', id: `federal.${UiTabs.federal.nol as string}`, type: 'tabs' },
  { name: 'Federal Credits', id: `federal.${UiTabs.federal.credits as string}`, type: 'tabs' },
  { name: 'Federal Tax-Effected', id: `federal.${UiTabs.federal.taxEffected as string}`, type: 'tabs' },
  { name: 'Federal Deferred', id: `federal.${UiTabs.federal.deferred as string}`, type: 'tabs' },
  { name: 'Federal Accounts', id: `federal.${UiTabs.federal.accounts as string}`, type: 'tabs' },
  { name: 'State RTP', id: `state.${UiTabs.state.rtp as string}`, type: 'tabs' },
  { name: 'State Apportionment', id: `state.${UiTabs.state.apportionment as string}`, type: 'tabs' },
  { name: 'State Modifications', id: `state.${UiTabs.state.modificationsPermanent as string}`, type: 'tabs' },
  { name: 'State NOL', id: `state.${UiTabs.state.nol as string}`, type: 'tabs' },
  { name: 'State Credits', id: `state.${UiTabs.state.credits as string}`, type: 'tabs' },
  { name: 'State Tax-Effected', id: `state.${UiTabs.state.taxEffected as string}`, type: 'tabs' },
  { name: 'State Deferred', id: `state.${UiTabs.state.deferred as string}`, type: 'tabs' },
  { name: 'State Accounts', id: `state.${UiTabs.state.accounts as string}`, type: 'tabs' }
];

export const tabsFilterOptionsForInterim: DataTypeFilterOption[] = [
  ...tabsFilterOptions,
  { name: 'Discrete Adjustments', id: `discrete.${UiTabs.discrete.adjustment as string}`, type: 'tabs' }
];

export const DataTypeIdsByName = Constants.AuditTrail.datatypeIdsByName;
