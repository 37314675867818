import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useStateDeferredRowsWithData } from '.';

import {
  getFullStateData,
  getValuationAllowance,
  groupByJurisdictionId,
  makeRows,
  useEntityTaxRatesByJurisdictionId
} from './utils';

import { ContentAndTitle, TabTitle } from '..';
import { TableWithComment } from '../../..';
import { FEDERAL_UUID, LEVELS, TABS_WITH_IMPORT_BUTTON_AT_TOP } from '../../../../constants';
import { useCompletionStatus, useCurrencies, useFinancialData } from '../../../../hooks';
import { Entity, Step, SubJurisdiction, SubJurisdictionById } from '../../../../models';
import { setEntityCompletionStatus } from '../../../../redux/entitiesCompletionStatus';
import { selectDoesUserHaveRole } from '../../../../selectors';
import LoadingWrapper from '../../../LoadingWrapper';
import { ProReadOnly, ProReviewer } from '../../../UserRoleStylesProvider/constants';
import {
  getDeferredColumns,
  EntityNumberRouteMatch,
  handleOnCellOrCommentBlurForEntityDetails,
  getDeferredColumnsFlagged
} from '../../utils';
import { getLinkButtonCssProps } from '../../utils/styles';
import { MODIFICATIONS_TEMPORARY } from '../StateModifications/constants';

type Props = {
  entityId: Entity['entityId'];
  states?: SubJurisdiction[];
  subJurisdictionById?: SubJurisdictionById;
};

const useStyles = makeStyles((theme) => ({
  linkButton: ({ isUserReadOnly }: { isUserReadOnly: boolean }) => ({
    ...getLinkButtonCssProps(theme, isUserReadOnly),
    '&.Mui-disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'inherit'
    }
  })
}));

const TAB_LEVEL_AND_STEP = 'state.deferred';

const StateDeferred = ({ entityId, states = [], subJurisdictionById = new Map() }: Props) => {
  const { t } = useTranslation();
  const {
    params: { entityNumber }
  } = useRouteMatch<EntityNumberRouteMatch>();
  const { currencyByEntityIdMap } = useCurrencies();
  const currencyIsoCode = currencyByEntityIdMap[entityId]?.isoCode;
  const { stepCompletionStatus } = useCompletionStatus(entityNumber, TAB_LEVEL_AND_STEP, FEDERAL_UUID);
  const { prov2826TarfEnhancements } = useFlags();
  const isTarfFlagActive = prov2826TarfEnhancements;
  const isUserReadOnly = useSelector(selectDoesUserHaveRole([ProReadOnly.Name, ProReviewer.Name]));
  const columns = useMemo(
    () =>
      isTarfFlagActive
        ? getDeferredColumnsFlagged(t, stepCompletionStatus.status || isUserReadOnly)
        : getDeferredColumns(t, stepCompletionStatus.status || isUserReadOnly),
    [isTarfFlagActive, isUserReadOnly, stepCompletionStatus.status, t]
  );
  const dispatch = useDispatch();
  const { tabsData, failedCells, isFetchLoading } = useFinancialData(entityNumber, LEVELS.FEDERAL, [
    'deferred',
    'temporary',
    'rtp',
    'credits',
    'permanent'
  ]);
  useFinancialData(entityNumber, 'state', [
    'apportionment',
    'deferred',
    'nol',
    'credits',
    MODIFICATIONS_TEMPORARY,
    'rtp'
  ]);
  const taxRates = useEntityTaxRatesByJurisdictionId(entityId);
  const classes = useStyles({ isUserReadOnly });

  const {
    balanceSheetRowsWithData,
    concatenatedCellData,
    creditsRowsWithData,
    deferredRowsWithData,
    federalDeferredRowsWithData,
    incomeStatementRowsWithData,
    modificationsRowsWithData,
    rtpRowsWithData,
    stateRtpRowsWithData,
    stateApportionmentRowsWithData
  } = useStateDeferredRowsWithData(tabsData);

  const creditsRowsGroupedByJurisdiction = groupByJurisdictionId(creditsRowsWithData);

  const fullStateData = getFullStateData({ states, tabsData });

  const valuationAllowance = getValuationAllowance(deferredRowsWithData);

  const rows = makeRows(
    t,
    columns,
    {
      states: fullStateData,
      deferredRowsWithData,
      federalDeferredRowsWithData,
      modificationsRowsWithData,
      subJurisdictionById,
      creditsRowsGroupedByJurisdiction,
      stateRtpRowsWithData,
      stateApportionmentRowsWithData,
      tabsData,
      valuationAllowance,
      balanceSheet: balanceSheetRowsWithData,
      incomeStatement: incomeStatementRowsWithData,
      rtp: rtpRowsWithData,
      taxRates,
      dispatch,
      className: classes.linkButton
    },
    stepCompletionStatus.status
  );

  const dataForMethods = {
    columns,
    dispatch,
    entityId,
    financialInfo: concatenatedCellData,
    level: LEVELS.STATE,
    rows,
    step: 'deferred' as Step,
    t
  };

  return (
    <LoadingWrapper isLoading={isFetchLoading}>
      <ContentAndTitle
        title={
          <TabTitle
            currencyIsoCode={currencyIsoCode}
            title={t('Deferred Rollforward')}
            isCompleted={stepCompletionStatus.status}
            shouldDisplayDataImportButton={TABS_WITH_IMPORT_BUTTON_AT_TOP.state.rtp}
            onCompletionChange={(checked) => {
              dispatch(
                setEntityCompletionStatus({
                  ...stepCompletionStatus,
                  newStatus: checked
                })
              );
            }}
          />
        }
      >
        <TableWithComment
          columns={columns}
          rows={rows}
          failedCells={failedCells}
          hideActionsMenu={stepCompletionStatus.status}
          onCellOrCommentBlur={(params) => {
            handleOnCellOrCommentBlurForEntityDetails({
              ...dataForMethods,
              ...params
            });
          }}
        />
      </ContentAndTitle>
    </LoadingWrapper>
  );
};

export default StateDeferred;
