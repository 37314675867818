/* eslint complexity: 0 */
import { useMemo } from 'react';

import {
  formatSubjurisdictionName,
  TABS_TO_FILTER_FINANCIAL_INFO_BY_SUBJURISDICTION
} from '../../components/ValidateScreen/utils';
import { DataImportSelection, FinancialInfo, FinancialInfoTabsData, Level, Step, SubJurisdiction } from '../../models';

type UsePreProcessedFinancialInfoProps = {
  creditName?: string;
  dataImportSelection: DataImportSelection;
  level: Level;
  step: Step;
  tabsData: FinancialInfoTabsData;
  subJurisdictionId?: string;
  entitySubJurisdictions?: SubJurisdiction[];
};

export const usePreProcessedFinancialInfo = ({
  creditName,
  dataImportSelection,
  level,
  step,
  tabsData,
  subJurisdictionId,
  entitySubJurisdictions
}: UsePreProcessedFinancialInfoProps) => {
  const financialInfo = useMemo(() => {
    let tabDataSelection = tabsData?.[`${level}.${step}`];
    if (level === 'state' && step === 'modifications') {
      if (tabsData?.['state.modifications.permanent'] && tabsData?.['state.modifications.temporary']) {
        tabDataSelection = [...tabsData['state.modifications.permanent'], ...tabsData['state.modifications.temporary']];
      } else if (tabsData?.['state.modifications.permanent']) {
        tabDataSelection = [...tabsData['state.modifications.permanent']];
      } else if (tabsData?.['state.modifications.temporary']) {
        tabDataSelection = [...tabsData['state.modifications.temporary']];
      }
    }

    const filterByCreditName = (financialInfo: FinancialInfo, creditName?: string) => {
      if (step === 'credits') {
        const creditNameValues = Object.values(dataImportSelection['Credit Name'].rawValues);
        return creditNameValues.includes(String(financialInfo.creditName));
      }

      return !creditName || financialInfo.creditName === creditName;
    };

    const filterBySubJurisdictionId = (financialInfo: FinancialInfo, subJurisdictionId?: string) => {
      return !subJurisdictionId || financialInfo.jurisdictionId === subJurisdictionId;
    };

    const financialInfoFederalRtpTemp =
      tabDataSelection?.filter(
        (data) =>
          data.creditName === `${String(creditName)}.balanceSheet` ||
          data.creditName === `${String(creditName)}.incomeStatement`
      ) ?? [];

    const financialInfo =
      tabDataSelection?.filter((data) => {
        const shouldFilterBySubJurisdictionId = TABS_TO_FILTER_FINANCIAL_INFO_BY_SUBJURISDICTION[level][step];
        return (
          filterByCreditName(data, creditName) &&
          (!shouldFilterBySubJurisdictionId || filterBySubJurisdictionId(data, subJurisdictionId))
        );
      }) ?? [];

    if (level === 'federal' && step === 'rtp' && creditName === 'federal.permanent') {
      const dataImportRowNames = new Set(dataImportSelection.Name.rawValues.map((val) => val.toString().toLowerCase()));
      const permanentFinancialData =
        tabsData?.['federal.permanent'].filter((data) => {
          return dataImportRowNames.has(data.rowName.toLowerCase());
        }) ?? [];
      const updatedPermanentFinancialData = permanentFinancialData.map((cell) => {
        return { ...cell, creditName: 'federal.permanent' };
      });
      financialInfo.push(...updatedPermanentFinancialData);
    }

    if (level === 'federal' && (step === 'rtp' || step === 'deferred') && creditName === 'federal.temporary') {
      const dataImportRowNames =
        step === 'rtp'
          ? new Set(dataImportSelection.Name.rawValues.map((val) => val.toString().toLowerCase()))
          : new Set(dataImportSelection['Adjustment Name'].rawValues.map((val) => val.toString().toLowerCase()));
      const tempBalanceSheetFinancialData =
        tabsData?.['federal.temporary.balanceSheet'].filter((data) => {
          return dataImportRowNames.has(data.rowName.toLowerCase());
        }) ?? [];
      const tempIncomeStatementFinancialData =
        tabsData?.['federal.temporary.incomeStatement'].filter((data) => {
          return dataImportRowNames.has(data.rowName.toLowerCase());
        }) ?? [];
      const updatedTempBalanceSheetFinancialData = tempBalanceSheetFinancialData.map((cell) => {
        return { ...cell, creditName: 'federal.temporary.balanceSheet' };
      });
      const updatedTempIncomeStatementFinancialData = tempIncomeStatementFinancialData.map((cell) => {
        return { ...cell, creditName: 'federal.temporary.incomeStatement' };
      });
      financialInfoFederalRtpTemp.push(
        ...updatedTempBalanceSheetFinancialData,
        ...updatedTempIncomeStatementFinancialData
      );
    }

    const financialInfoStateRtp: FinancialInfo[] = [];

    if (
      level === 'state' &&
      step === 'rtp' &&
      ['state.modifications.permanent', 'state.modifications.temporary'].includes(creditName ?? '')
    ) {
      const dataImportRowNames = new Set(dataImportSelection.Name.rawValues.map((val) => val.toString().toLowerCase()));
      const rowNamesToIgnoreInStateRtp = new Set<string>();

      const modificationsTabFinancialData =
        tabsData?.[creditName!]?.filter((data) => {
          if (data.columnName !== 'state') {
            return false;
          }

          const modificationsRecordRowName = data.rowName.toLowerCase();

          const rowNameDoesExist = dataImportRowNames.has(modificationsRecordRowName);
          const rowNameIsAssociatedToSubJurisdiction = (data.value as string[]).includes(subJurisdictionId!);
          const modificationShouldBeIncludedInStateRtp = rowNameDoesExist && rowNameIsAssociatedToSubJurisdiction;

          if (!modificationShouldBeIncludedInStateRtp) {
            rowNamesToIgnoreInStateRtp.add(modificationsRecordRowName);
          }

          return modificationShouldBeIncludedInStateRtp;
        }) ?? [];

      const updatedModificationsTabFinancialData = modificationsTabFinancialData.map((cell) => {
        return {
          ...cell,
          step,
          creditName,
          jurisdictionId: subJurisdictionId
        };
      });

      for (const financialInfoItem of financialInfo) {
        if (!rowNamesToIgnoreInStateRtp.has(financialInfoItem.rowName.toLowerCase())) {
          financialInfoStateRtp.push(financialInfoItem);
        }
      }

      financialInfoStateRtp.push(...updatedModificationsTabFinancialData);
    }

    const financialInfoStateDeffered: FinancialInfo[] = [];
    if (level === 'state' && step === 'deferred' && creditName === 'state.modifications.temporary') {
      const dataImportRowStates = dataImportSelection.State.rawValues as string[];
      const dataImportRowNames = dataImportSelection['Adjustment Name'].rawValues as string[];
      for (let [i, stateName] of dataImportRowStates.entries()) {
        const currentSubjurisdictionId = entitySubJurisdictions?.find(({ name }) =>
          name.toLowerCase().includes(formatSubjurisdictionName(stateName.toLowerCase()))
        )?.subJurisdictionId;

        const modificationsTabFinancialData =
          tabsData?.['state.modifications.temporary']?.filter((data) => {
            if (data.columnName !== 'state') {
              return false;
            }

            const isAssociatedToSubJurisdiction: boolean = (data.value as string[]).includes(currentSubjurisdictionId!);
            const rowNameExists: boolean = data.rowName.toLowerCase() === dataImportRowNames[i].toLowerCase();
            const modificationShouldBeIncludedInDeffered: boolean = isAssociatedToSubJurisdiction && rowNameExists;

            return modificationShouldBeIncludedInDeffered;
          }) ?? [];

        if (modificationsTabFinancialData.length === 0) continue;

        stateName = formatSubjurisdictionName(stateName.toLowerCase());
        const compositeStateDefferedRowName = `${stateName} - ${dataImportRowNames[i].toLocaleLowerCase()}`;

        const stateDefferedFinancialData =
          tabDataSelection.filter(({ rowName }) => {
            return rowName.toLocaleLowerCase() === compositeStateDefferedRowName;
          }) ?? [];

        financialInfoStateDeffered.push(...modificationsTabFinancialData, ...stateDefferedFinancialData);
      }

      return financialInfoStateDeffered;
    }

    switch (creditName) {
      case 'federal.temporary':
        return financialInfoFederalRtpTemp;
      case 'state.modifications.permanent':
      case 'state.modifications.temporary':
        return financialInfoStateRtp;
      default:
        return financialInfo;
    }
  }, [creditName, dataImportSelection, level, step, tabsData, subJurisdictionId, entitySubJurisdictions]);

  return financialInfo;
};
