import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import HTTPService, { LambdaResponse } from '../../services/http';

interface SaveDataImportAction {
  field: string;
  range: string;
  formattedValues: Array<number | string>;
  rawValues: Array<number | string>;
}

export const saveDataImport = createAction<SaveDataImportAction>('dataImport/saveData');
export const clearDataImport = createAction<{ headerName: string }>('dataImport/clearData');
export const resetDataImport = createAction('dataImport/resetData');

interface AddDataImportProps {
  entityId: string;
  createItems: any;
  updateItems: any;
  selectionData: any;
}
export const addDataImport = createAsyncThunk(
  'dataImport/addData',
  async ({ entityId, createItems, updateItems, selectionData }: AddDataImportProps, { rejectWithValue }) => {
    try {
      const { data: _financialInfo } = await HTTPService.request<LambdaResponse<string>>({
        method: 'post',
        relativePath: `/v1/entities/${entityId}/financial-values-batch`,
        data: {
          uploadMetadata: selectionData,
          financialInfoBatch: {
            create: createItems,
            update: updateItems
          }
        }
      });

      return { message: `Done posting...` };
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);
