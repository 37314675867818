import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Add from '@material-ui/icons/Add';

import { SearchBox } from '../../..';
import { LEVELS } from '../../../../constants';
import { AdjustmentWithCategoryShape, CategoriesShape } from '../../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid',
    borderColor: theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(2),
    '& > h3': {
      padding: theme.spacing(1, 0)
    }
  },
  title: {
    backgroundColor: theme.palette.action.selected,
    borderRadius: theme.shape.borderRadius
  },
  clear: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(1, 2),
    lineHeight: 'inherit'
  },
  searchbox: {
    marginLeft: theme.spacing(2),
    width: '40%',
    transition: 'min-width 150ms ease-in-out, width 150ms ease-in-out',
    '&.collapsed:not(:hover)': {
      width: 0,
      minWidth: 56,
      '& .MuiAutocomplete-inputRoot.MuiOutlinedInput-root': {
        paddingRight: 50
      },
      '& .MuiAutocomplete-input:first-child': {
        flexGrow: 0,
        paddingLeft: 0,
        paddingRight: 0,
        minWidth: 0
      }
    }
  },
  option: {
    display: 'flex',
    width: '100%',
    '& > :first-child': {
      flexGrow: 1
    },
    '& > :last-child': {
      color: theme.palette.text.secondary
    }
  },
  addOption: {
    margin: theme.spacing(1, 1, 0),
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 0)
  },
  select: {
    backgroundColor: theme.palette.background.paper,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent'
    }
  }
}));

type ListBoxProps = {
  children?: any[] | React.ReactElement | string;
};

const ListBox = ({ children, ...props }: ListBoxProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Paper {...props}>
      {children}
      <Box className={classes.addOption}>
        <Button variant="text" startIcon={<Add fontSize="small" />}>
          {t('Add New Data Category')}
        </Button>
      </Box>
    </Paper>
  );
};

function addCategoryToAdjustments(adjustments: any, category: any) {
  return adjustments?.map((adjustment: any) => ({
    ...adjustment,
    category
  }));
}

type AdjustmentSearchProps = {
  categories?: CategoriesShape;
  value: AdjustmentWithCategoryShape;
  onChange?: (...args: any[]) => any;
};

const AdjustmentSearch = ({ categories, value, onChange }: AdjustmentSearchProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { adjustment, adjustments, categoryAdjustments } = useMemo(() => {
    if (!categories) {
      return {};
    }

    const { jurisdictionLevel: categoryLevel, name: categoryName } = value.category || {};
    const mappedAdjustments = {
      federal: {
        permanent: addCategoryToAdjustments(categories.federal?.permanent, {
          name: 'permanent',
          jurisdictionLevel: LEVELS.FEDERAL
        }),
        temporary: addCategoryToAdjustments(categories.federal?.temporary, {
          name: 'temporary',
          jurisdictionLevel: LEVELS.FEDERAL
        }),
        ptbi: addCategoryToAdjustments((categories.federal as any)?.ptbi, {
          name: 'ptbi',
          jurisdictionLevel: LEVELS.FEDERAL
        }),
        'tax-effected': addCategoryToAdjustments((categories.federal as any)?.taxEffected, {
          name: 'tax-effected',
          jurisdictionLevel: LEVELS.FEDERAL
        })
      },
      state: {
        modifications: addCategoryToAdjustments((categories.state as any)?.modifications, {
          name: 'modifications',
          jurisdictionLevel: LEVELS.STATE
        }),
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        'tax-effected': addCategoryToAdjustments(categories.state?.taxEffected, {
          name: 'tax-effected',
          jurisdictionLevel: LEVELS.STATE
        })
      }
    };

    return {
      adjustment: value,
      adjustments: [
        ...(Array.isArray(mappedAdjustments.federal.permanent) ? mappedAdjustments.federal.permanent : []),
        ...(Array.isArray(mappedAdjustments.federal.temporary) ? mappedAdjustments.federal.temporary : []),
        ...(Array.isArray(mappedAdjustments.federal.ptbi) ? mappedAdjustments.federal.ptbi : []),
        ...(Array.isArray(mappedAdjustments.federal['tax-effected']) ? mappedAdjustments.federal['tax-effected'] : []),
        ...(Array.isArray(mappedAdjustments.state.modifications) ? mappedAdjustments.state.modifications : []),
        ...(Array.isArray(mappedAdjustments.state['tax-effected']) ? mappedAdjustments.state['tax-effected'] : []),
        ''
      ],
      // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
      categoryAdjustments: mappedAdjustments[categoryLevel]?.[categoryName] || []
    };
  }, [categories, value]);

  function handleChange(event: any, adjustment: any) {
    if (!adjustment && event?.target?.value) {
      adjustment = categoryAdjustments?.find(({ name }: any) => name === event.target.value);
    }

    if (adjustment && onChange) {
      onChange(adjustment);
    }
  }

  return (
    <Box className={classes.root}>
      <Typography variant="h2" component="h3">
        {t('Search categories')}
      </Typography>
      <SearchBox
        disableClearable
        id="category-finder"
        className={`${classes.searchbox}${adjustment?.name ? ' collapsed' : ''}`}
        // FIXME : Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        options={adjustments || ['']}
        getOptionSelected={(option: any, value: any) =>
          option === '' ||
          (option.category.name === value.category.name &&
            option.category.jurisdictionLevel === value.category.jurisdictionLevel &&
            option.name === value.name)
        }
        getOptionLabel={(adjustment: any) => adjustment?.name || ''}
        value={adjustment ?? ''}
        renderOption={({ name, category }: any) =>
          category?.name && (
            <Box className={classes.option}>
              <Box>{name}</Box>
              <Box>{`${t(category.jurisdictionLevel)} ${t(category.name)}`}</Box>
            </Box>
          )
        }
        PaperComponent={ListBox}
        onChange={handleChange}
      />
      {adjustment?.category.name && (
        <>
          <Box className={`${classes.title} ${classes.clear}`}>
            {/* FIXME : Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
            {t(`level-${adjustment.category.jurisdictionLevel}`)} {t(adjustment.category.name)}
          </Box>
          {value.category.name !== 'ptbi' && (
            <Select
              className={classes.select}
              variant="outlined"
              value={value.name ?? ''}
              onChange={(event) => {
                // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
                handleChange(event);
              }}
            >
              {categoryAdjustments?.map((option: any) => (
                <MenuItem key={option.name} selected={value === option} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          )}
          <Button
            variant="outlined"
            className={classes.clear}
            onClick={() => {
              handleChange(null, { category: {} });
            }}
          >
            {t('Clear')}
          </Button>
        </>
      )}
    </Box>
  );
};

export default AdjustmentSearch;
