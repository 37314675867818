import { SvgIcon } from '@material-ui/core';

const CurrentIcon = (props: any) => (
  <SvgIcon viewBox="0 0 256 256" {...props}>
    <path d="M215.9,232.3H40.1V17.7h113l62.7,58.9V232.3z M43.1,229.3h169.8V77.9L152,20.7H43.1V229.3z" />

    <g>
      <path
        d="M183.2,107.4c-4.7,0-7-3.2-9-6.1c-1.9-2.7-3.5-4.9-6.8-4.9c-3.2,0-4.9,2.3-6.8,4.9c-2,2.9-4.4,6.1-9,6.1
        
        c-4.6,0-7-3.2-9-6.1c-1.9-2.7-3.5-4.9-6.8-4.9s-4.9,2.3-6.8,4.9c-2,2.9-4.4,6.1-9,6.1s-7-3.2-9-6.1c-1.9-2.7-3.5-4.9-6.8-4.9
        
        c-3.2,0-4.9,2.3-6.8,4.9c-2,2.9-4.4,6.1-9,6.1s-7-3.2-9-6.1c-1.9-2.7-3.5-4.9-6.8-4.9c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4
        
        c4.6,0,7,3.2,9,6.1c1.9,2.7,3.5,4.9,6.8,4.9s4.9-2.3,6.8-4.9c2-2.9,4.4-6.1,9-6.1s7,3.2,9,6.1c1.9,2.7,3.5,4.9,6.8,4.9
        
        c3.2,0,4.9-2.3,6.8-4.9c2-2.9,4.4-6.1,9-6.1c4.6,0,7,3.2,9,6.1c1.9,2.7,3.5,4.9,6.8,4.9c3.2,0,4.9-2.3,6.8-4.9c2-2.9,4.4-6.1,9-6.1
        
        c4.6,0,7,3.2,9,6.1c1.9,2.7,3.5,4.9,6.8,4.9c0.8,0,1.4,0.6,1.4,1.4S183.9,107.4,183.2,107.4z"
      />

      <path
        d="M183.2,131.9c-4.7,0-7-3.2-9-6.1c-1.9-2.7-3.5-4.9-6.8-4.9c-3.2,0-4.9,2.3-6.8,4.9c-2,2.9-4.4,6.1-9,6.1
        
        c-4.6,0-7-3.2-9-6.1c-1.9-2.7-3.5-4.9-6.8-4.9s-4.9,2.3-6.8,4.9c-2,2.9-4.4,6.1-9,6.1s-7-3.2-9-6.1c-1.9-2.7-3.5-4.9-6.8-4.9
        
        c-3.2,0-4.9,2.3-6.8,4.9c-2,2.9-4.4,6.1-9,6.1s-7-3.2-9-6.1c-1.9-2.7-3.5-4.9-6.8-4.9c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4
        
        c4.6,0,7,3.2,9,6.1c1.9,2.7,3.5,4.9,6.8,4.9s4.9-2.3,6.8-4.9c2-2.9,4.4-6.1,9-6.1s7,3.2,9,6.1c1.9,2.7,3.5,4.9,6.8,4.9
        
        c3.2,0,4.9-2.3,6.8-4.9c2-2.9,4.4-6.1,9-6.1c4.6,0,7,3.2,9,6.1c1.9,2.7,3.5,4.9,6.8,4.9c3.2,0,4.9-2.3,6.8-4.9c2-2.9,4.4-6.1,9-6.1
        
        c4.6,0,7,3.2,9,6.1c1.9,2.7,3.5,4.9,6.8,4.9c0.8,0,1.4,0.6,1.4,1.4S183.9,131.9,183.2,131.9z"
      />

      <path
        d="M183.2,156.4c-4.7,0-7-3.2-9-6.1c-1.9-2.7-3.5-4.9-6.8-4.9c-3.2,0-4.9,2.3-6.8,4.9c-2,2.9-4.4,6.1-9,6.1
        
        c-4.6,0-7-3.2-9-6.1c-1.9-2.7-3.5-4.9-6.8-4.9s-4.9,2.3-6.8,4.9c-2,2.9-4.4,6.1-9,6.1s-7-3.2-9-6.1c-1.9-2.7-3.5-4.9-6.8-4.9
        
        c-3.2,0-4.9,2.3-6.8,4.9c-2,2.9-4.4,6.1-9,6.1s-7-3.2-9-6.1c-1.9-2.7-3.5-4.9-6.8-4.9c-0.8,0-1.4-0.6-1.4-1.4
        
        c0-0.8,0.6-1.4,1.4-1.4c4.6,0,7,3.2,9,6.1c1.9,2.7,3.5,4.9,6.8,4.9s4.9-2.3,6.8-4.9c2-2.9,4.4-6.1,9-6.1s7,3.2,9,6.1
        
        c1.9,2.7,3.5,4.9,6.8,4.9c3.2,0,4.9-2.3,6.8-4.9c2-2.9,4.4-6.1,9-6.1c4.6,0,7,3.2,9,6.1c1.9,2.7,3.5,4.9,6.8,4.9
        
        c3.2,0,4.9-2.3,6.8-4.9c2-2.9,4.4-6.1,9-6.1c4.6,0,7,3.2,9,6.1c1.9,2.7,3.5,4.9,6.8,4.9c0.8,0,1.4,0.6,1.4,1.4
        
        S183.9,156.4,183.2,156.4z"
      />
    </g>
  </SvgIcon>
);

export default CurrentIcon;
