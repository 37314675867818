import { styled } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';

const DefaultSwitch = styled(Switch)(({ theme }) => ({
  width: 30,
  height: 15,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)'
    }
  },
  '& .MuiSwitch-switchBase': {
    padding: 1,
    '&.Mui-checked': {
      transform: 'translateX(15px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1
      }
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 13,
    height: 13,
    borderRadius: 7,
    transition: theme.transitions.create(['width'], {
      duration: 200
    })
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    boxSizing: 'border-box',
    backgroundColor: '#C5C5C9',
    '& .Mui-checked': {
      backgroundColor: 'red'
    }
  },
  '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
    backgroundColor: theme.palette.info.dark
  }
}));

export default DefaultSwitch;
