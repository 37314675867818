import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { Entity, EntityFromApi, SubJurisdiction } from '../../models';
import HTTPService, { LambdaResponse } from '../../services/http';

export const entitiesOnSendingRequest = createAction('entities/onSendingRequest');
export const entitiesOnReceived = createAction<EntityFromApi[]>('entities/onReceived');
export const entitiesOnFailed = createAction<unknown>('entities/onFailed');

interface AlterSubJurisdictionsToEntityProps {
  entityId: Entity['id'];
  subjurisdictionIds: Array<SubJurisdiction['id']>;
}

interface UpdateEntityNameProps {
  entityId: Entity['entityId'];
  entityName: Entity['name'];
}

interface updateEntityCurrencyProps {
  entityId: Entity['entityId'];
  currencyId: Entity['currencyId'];
}

interface updateEntityJurisdictionProps {
  entityId: Entity['entityId'];
  jurisdictionId: Entity['jurisdictionId'];
}

interface UpdateEntityNumberProps {
  entityId: Entity['entityId'];
  entityNumber: Entity['entityNumber'];
}

interface DeleteEntityProps {
  entityId: Entity['entityId'];
}

export const addSubJurisdictionsToEntity = createAsyncThunk(
  'entities/addSubJurisdictionsToEntity',
  async ({ entityId, subjurisdictionIds }: AlterSubJurisdictionsToEntityProps) => {
    await HTTPService.request<LambdaResponse<Array<SubJurisdiction['id']>>>({
      method: 'post',
      relativePath: `/v1/entities/${entityId}/subjurisdictions`,
      data: {
        subjurisdictionIds
      }
    });
    return { entityId, subjurisdictionIds };
  }
);

export const deleteSubJurisdictionsFromEntity = createAsyncThunk(
  'entities/deleteSubJurisdictionsFromEntity',
  async ({ entityId, subjurisdictionIds }: AlterSubJurisdictionsToEntityProps) => {
    await HTTPService.request<LambdaResponse<Array<SubJurisdiction['id']>>>({
      method: 'patch',
      relativePath: `/v1/entities/${entityId}/subjurisdictions`,
      data: {
        subjurisdictionIds
      }
    });
    return { entityId, subjurisdictionIds };
  }
);

export const setParentEntity = createAsyncThunk<Entity['id'], Entity['id']>(
  'entities/setParentEntity',
  async (entityId) => {
    await HTTPService.request({
      method: 'patch',
      relativePath: `/v1/entities`,
      data: { parentEntityId: entityId }
    });

    return entityId;
  }
);

export const updateEntityName = createAsyncThunk<Entity['id'], UpdateEntityNameProps>(
  'entities/updateEntityName',
  async ({ entityId, entityName }) => {
    await HTTPService.request({
      method: 'patch',
      relativePath: `/v1/entities/${entityId}/entity-name`,
      data: { entityName }
    });

    return entityId;
  }
);

export const updateEntityNumber = createAsyncThunk<Entity['id'], UpdateEntityNumberProps>(
  'entities/updateEntityNumber',
  async ({ entityId, entityNumber }) => {
    await HTTPService.request({
      method: 'patch',
      relativePath: `/v1/entities/${entityId}/entity-number`,
      data: { entityNumber }
    });

    return entityId;
  }
);

export const updateEntityCurrency = createAsyncThunk<Entity['id'], updateEntityCurrencyProps>(
  'entities/updateEntityCurrency',
  async ({ entityId, currencyId }) => {
    await HTTPService.request({
      method: 'patch',
      relativePath: `/v1/entities/${entityId}/entity-currency`,
      data: { currencyId }
    });

    return entityId;
  }
);

export const updateEntityJurisdiction = createAsyncThunk<Entity['id'], updateEntityJurisdictionProps>(
  'entities/updateEntityJurisdiction',
  async ({ entityId, jurisdictionId }) => {
    await HTTPService.request({
      method: 'patch',
      relativePath: `/v1/entities/${entityId}/entity-jurisdiction`,
      data: { jurisdictionId }
    });

    return entityId;
  }
);

export const deleteEntity = createAsyncThunk<Entity['id'], DeleteEntityProps>(
  'entities/deleteEntity',
  async ({ entityId }) => {
    await HTTPService.request({
      method: 'delete',
      relativePath: `/v1/entities/${entityId}`
    });

    return entityId;
  }
);
