import { GenericRecord } from './utils';

export const calculatePreTaxAmount = (inputs: GenericRecord = {}) => {
  const { taxEffectedAmount, taxRate, rowId } = inputs;
  const rows: GenericRecord = {
    permanent: (taxEffectedAmount ?? 0) / (taxRate ?? 0),
    stateBenefit: (taxEffectedAmount ?? 0) / (1 - (taxRate ?? 0))
  };

  return rows[rowId] || 0;
};

export const calculateRates = (inputs: GenericRecord = {}) => {
  const { analysis, ptbi } = inputs;

  return {
    permanent: (analysis?.permanent?.taxEffectedAmount || 0) / ptbi,
    stateBenefit: (analysis?.stateBenefit?.taxEffectedAmount || 0) / ptbi,
    foreignRateDifferential: (analysis?.foreignRateDifferential?.taxEffectedAmount || 0) / ptbi,
    credits: (analysis?.credits?.taxEffectedAmount || 0) / ptbi,
    allowance: (analysis?.allowance?.taxEffectedAmount || 0) / ptbi,
    deferredRate: (analysis?.deferredRate?.taxEffectedAmount || 0) / ptbi,
    deferred: (analysis?.deferred?.taxEffectedAmount || 0) / ptbi,
    returnToProvison: (analysis?.returnToProvison?.taxEffectedAmount || 0) / ptbi,
    federalTaxAdjustments: (analysis?.federalTaxAdjustments?.taxEffectedAmount || 0) / ptbi
  };
};

interface SliderProps {
  [key: string]: {
    step: number;
    min: number;
    max: number;
  };
}

export const calculateSlidersProps = (inputs: GenericRecord = {}) => {
  const { taxRates, etrSliderSmallStep, etrSliderLimit } = inputs;
  const sliderProps: SliderProps = {};

  for (const row of Object.keys(taxRates ?? {})) {
    sliderProps[row] = {
      step: etrSliderSmallStep,
      min: Math.abs(taxRates[row]) * -etrSliderLimit || -1,
      max: Math.abs(taxRates[row]) * etrSliderLimit || 1
    };
  }

  return sliderProps;
};

export const calculateTaxEffectedAmount = (inputs: GenericRecord = {}): number => {
  const { ptbi, taxRate } = inputs;
  return (ptbi ?? 0) * (taxRate ?? 0);
};
