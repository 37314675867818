import { useTranslation } from 'react-i18next';

import { makeStyles, Box, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
    fontSize: '1rem',
    padding: theme.spacing(0.7, 0)
  }
}));

const DifferencePeriodHeader = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box>
      <Typography variant="h3" component="h1" className={classes.title}>
        {t('Differences')}
      </Typography>
    </Box>
  );
};

export default DifferencePeriodHeader;
