import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { DataTypeFilterOption } from '../../../../../../../models/auditTrail.interface';

const useStyles = makeStyles((theme) => ({
  inputTitle: {
    color: theme.palette.text.secondary,
    fontFamily: 'Basis Grotesque Pro Medium',
    letterSpacing: '0.25px',
    fontSize: '14px',
    lineHeight: '18px',
    marginBottom: '9px'
  },
  popoverContent: {
    width: '564px',
    padding: '21px',
    paddingBottom: '20px',
    backgroundColor: theme.palette.common.white
  },
  searchFocus: {
    width: '100%'
  },
  titleSection: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px'
  },
  title: {
    color: theme.palette.text.secondary,
    fontFamily: 'Basis Grotesque Pro Medium',
    letterSpacing: '0.6px',
    fontSize: '12px'
  },
  subtitle: {
    marginLeft: '10px',
    color: theme.palette.primary.main,
    fontFamily: 'Basis Grotesque Pro Medium',
    letterSpacing: '0.6px',
    fontSize: '12px'
  },
  optionsContainer: {
    height: '60px',
    overflowY: 'auto'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: '3px',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    '& .MuiInputBase-input': {
      color: theme.palette.common.black,
      letterSpacing: '0.25px',
      marginLeft: '15px'
    },
    '& .MuiInputBase-root': {
      paddingRight: '0px !important'
    }
  },
  autocompleteOption: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingTop: '6px',
    paddingBottom: '6px',
    paddingLeft: '5px',
    paddingRight: '5px',
    '&:hover': {
      backgroundColor: theme.palette.info.main,
      cursor: 'pointer'
    }
  }
}));

type InnerSearchPopoverProps = {
  subtitle: string;
  anchorEl: HTMLDivElement | null;
  options: DataTypeFilterOption[];
  onChange: any;
  onClose: () => void;
};

const InnerSearchPopover = ({ anchorEl, options, subtitle, onClose, onChange }: InnerSearchPopoverProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const filteredOptions = options.filter((option) => option.name.toLowerCase().includes(inputValue.toLowerCase()));
  // We keep the width of the anchor element to set the width of the popover
  const [width, setWidth] = useState<number | undefined>();

  if (anchorEl?.clientWidth !== undefined && width !== anchorEl?.clientWidth) {
    setWidth(anchorEl?.clientWidth);
  }

  const renderOptions = () => {
    return filteredOptions.map((option) => {
      const key = `${option.name}${option.id}`;

      return (
        <Box
          key={key}
          className={classes.autocompleteOption}
          onClick={() => {
            onChange({} as any, option, {} as any);
            onClose();
          }}
        >
          {option.component?.() ?? <Typography>{option.name}</Typography>}
        </Box>
      );
    });
  };

  return (
    <Popover
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      onClose={onClose}
    >
      <Box className={classes.popoverContent} style={{ width }}>
        <Box className={classes.titleSection}>
          <Typography className={classes.title}>{t('search-within').toUpperCase()}</Typography>
          <Typography className={classes.subtitle}>{subtitle.toUpperCase()}</Typography>
        </Box>

        <TextField
          value={inputValue}
          className={classes.container}
          InputProps={{
            disableUnderline: true,
            fullWidth: true
          }}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
        />

        <Typography className={classes.title} style={{ marginBottom: '10px', marginTop: '18px' }}>
          {t('results').toUpperCase()}
        </Typography>

        <Box className={classes.optionsContainer}>{renderOptions()}</Box>
      </Box>
    </Popover>
  );
};

export default InnerSearchPopover;
