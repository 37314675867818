import { useTranslation } from 'react-i18next';

import { CurrenciesTabProps } from './CurrenciesTab.proptype';

import { TabLayout } from '..';

const CurrenciesTab = ({ currencies, entities, currencyByEntityId }: CurrenciesTabProps) => {
  const { t } = useTranslation();
  // TODO this is a patch that should be removed once we use thunk to assign currencies
  return currencies?.length ? (
    <TabLayout
      asideTitle={t('Currencies')}
      mainTitle={t('Entity List')}
      description={t('Please select the currency used for each entity within the Trial Balance.')}
      searchCaption={t('Assign currencies to entities')}
      searchPlaceholder={t('Select a currency (USD, GBP, etc)')}
      attributeValues={currencies}
      columns={[
        {
          field: 'id',
          filterable: true,
          sortable: true,
          placeholder: t('Entity No.'),
          width: '20%'
        },
        {
          field: 'name',
          filterable: true,
          sortable: true,
          placeholder: t('Entity Name'),
          width: '50%'
        }
      ]}
      attributeColumnName={t('Currency')}
      dataCountLabel={t('_ found', { count: entities?.length })}
      data={entities}
      attributeByRowId={currencyByEntityId}
      noRowLabel={t('No Entities found')}
    />
  ) : null;
};

export default CurrenciesTab;
