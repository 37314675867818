import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as XLSX from 'xlsx';

import SpreadsheetSelection from './components/SpreadsheetSelection';
import { Options, SpreadsheetData } from './Spreadsheet.proptype';
import { setSheetSelectedRange, spreadsheetSelectionHandleDoneEvent } from './utils';

import './xspreadsheet.css';
import { useDataImportColumns, useSpreadsheet } from '../../hooks';
import { Level, Step } from '../../models';
import { clearDataImport } from '../../redux/dataImport';
import { selectDataImportSelection } from '../../selectors';
import SpreadsheetCellSelectionBar from '../SpreadsheetCellSelectionBar/SpreadsheetCellSelectionBar';

const useStyles = makeStyles((theme) => ({
  inputField: {
    alignItems: 'center',
    border: `1px solid ${theme.palette.info.dark}`,
    borderRadius: '4px',
    boxShadow: '0 0 9px 0 rgba(0,0,0,0.05)',
    display: 'flex',
    height: '36px',
    margin: '4px 0 14px 0',
    paddingLeft: '12px',
    paddingRight: '1px',
    width: '98%'
  },
  button: {
    fontFamily: theme.typography.body2.fontFamily,
    letterSpacing: '0.25px',
    lineHeight: '18px',
    borderRadius: '4px',
    border: '1px solid',
    borderColor: 'transparent'
  },
  doneButton: {
    marginLeft: '0.25rem'
  },
  cancelButton: {
    marginLeft: 'auto'
  }
}));

interface SpreadsheetProps {
  options: Options;
  data?: SpreadsheetData;
  height: string;
  keyHeaderNames: string[];
  level: Level;
  step: Step;
  width: string;
  workbook: XLSX.WorkBook;
}

const Spreadsheet = ({ options, data, height, keyHeaderNames, level, step, width, workbook }: SpreadsheetProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [nRows, setNRows] = useState(0);
  const dataImportSelection = useSelector(selectDataImportSelection);
  const [selectedField, setSelectedField] = useState<string | null>(null);
  const {
    prov3045EditableSpreadsheetSelection,
    prov3250AddColumnsToApportionmentTab,
    prov3736ReservedWordValidation: showReservedWordsError
  } = useFlags();
  const shouldUseFlaggedApportionmentTab =
    prov3250AddColumnsToApportionmentTab && level === 'state' && step === 'apportionment';
  step = shouldUseFlaggedApportionmentTab ? 'apportionment-flagged' : step;

  const dispatch = useDispatch();

  const stepColumns = useDataImportColumns({
    level,
    selectedField,
    step
  });

  const selectedFieldColumn = stepColumns?.find((col) => col.headerName === selectedField);

  useEffect(() => {
    if (Object.keys(dataImportSelection ?? {}).length === 0) {
      setNRows(0);
    }
  }, [dataImportSelection]);

  const { range, activeSheet, sheetEl, sheetRef, setRange } = useSpreadsheet({
    data,
    options,
    selectedField,
    setSelectedField,
    workbook
  });

  const autoSelectCells = (selectedRangeForHeaderName: string) => {
    setRange(selectedRangeForHeaderName);
    const decodedRange = XLSX.utils.decode_range(selectedRangeForHeaderName);

    setSheetSelectedRange({
      sheetRef: sheetRef.current,
      startRowIndex: decodedRange.s.r,
      startColumnIndex: decodedRange.s.c,
      endRowIndex: decodedRange.e.r,
      endColumnIndex: decodedRange.e.c
    });
  };

  const onFieldSelected = (headerName: string) => {
    setSelectedField(headerName);

    const selectedRangeForHeaderName = dataImportSelection[headerName]?.range;

    if (selectedRangeForHeaderName) {
      autoSelectCells(selectedRangeForHeaderName);
    }
  };

  const handleClearSelectionEvent = (headerName?: string) => {
    if (headerName !== undefined) {
      dispatch(
        clearDataImport({
          headerName
        })
      );
    }

    setSelectedField(null);
  };

  const handleCellSelectionInputBlur = (inputSelection: string) => {
    autoSelectCells(inputSelection);
  };

  return (
    <Box style={{ height: height ?? '100%', width: width ?? '100%' }}>
      <SpreadsheetSelection
        dataImportSelection={dataImportSelection}
        stepColumns={stepColumns!}
        onFieldSelected={onFieldSelected}
      />
      {selectedField &&
        (prov3045EditableSpreadsheetSelection ? (
          <SpreadsheetCellSelectionBar
            range={range}
            onBlur={handleCellSelectionInputBlur}
            onCancel={() => {
              handleClearSelectionEvent(selectedField);
            }}
            onDone={(inputSelection: string) => {
              spreadsheetSelectionHandleDoneEvent({
                dispatch,
                column: selectedFieldColumn!,
                keyHeaderNames,
                level,
                nRows,
                selection: inputSelection,
                setSelectedField,
                setNRows,
                sheet: activeSheet!,
                step,
                showReservedWordsError
              });
            }}
          />
        ) : (
          <Box className={classes.inputField}>
            {range}
            <Button
              className={`${classes.button} ${classes.cancelButton}`}
              color="primary"
              variant="contained"
              onClick={() => {
                handleClearSelectionEvent(selectedField);
              }}
            >
              {t('cancel')}
            </Button>
            <Button
              className={`${classes.button} ${classes.doneButton}`}
              color="primary"
              variant="contained"
              onClick={() => {
                spreadsheetSelectionHandleDoneEvent({
                  dispatch,
                  column: selectedFieldColumn!,
                  keyHeaderNames,
                  level,
                  nRows,
                  selection: range ?? '',
                  setSelectedField,
                  setNRows,
                  sheet: activeSheet!,
                  step,
                  showReservedWordsError
                });
              }}
            >
              {t('done')}
            </Button>
          </Box>
        ))}
      <div
        ref={sheetEl}
        style={{ height: height ?? '100%', width: width ?? '100%' }}
        id="x-spreadsheet"
        aria-label="spreadsheet"
      />
    </Box>
  );
};

export default Spreadsheet;
