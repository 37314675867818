import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CommentOutlined from '@material-ui/icons/CommentOutlined';

import ActionsCellMoreActionsButton from './ActionsCellMoreActionsButton';

import { Row } from '../../../models';
import { TableWithCommentProps } from '../TableWithComment.proptype';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: theme.spacing(-0.5, 0)
  },
  icon: {
    margin: theme.spacing(0, 1)
  }
}));

interface Props {
  displayGroupActions?: boolean;
  displayCategoryActions?: boolean;
  hasNewRow: boolean;
  handleOnRowDelete: TableWithCommentProps['handleOnRowDelete'];
  toggleEditCategoryDrawer: TableWithCommentProps['toggleEditCategoryDrawer'];
  handleOnCommentsClick: () => void;
  hideMoreButton?: boolean;
  row: Row;
}

const ActionsCell = ({
  displayGroupActions,
  displayCategoryActions,
  hasNewRow,
  handleOnCommentsClick,
  handleOnRowDelete,
  toggleEditCategoryDrawer,
  hideMoreButton,
  row
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const comments = row.comments ?? row.note; // Remove `row.comments` once we hook all table to the api

  return (
    <Box className={classes.root}>
      {comments && (
        <IconButton
          data-testid="table-with-comment-comment-icon"
          disabled={hasNewRow}
          size="small"
          className={classes.icon}
          onClick={handleOnCommentsClick}
        >
          <CommentOutlined />
        </IconButton>
      )}
      {!hideMoreButton && !row.isDefaultGroup && (
        <ActionsCellMoreActionsButton
          displayGroupActions={displayGroupActions}
          displayCategoryActions={displayCategoryActions}
          handleOnCommentsClick={handleOnCommentsClick}
          hasNewRow={hasNewRow}
          handleOnRowDelete={handleOnRowDelete}
          toggleEditCategoryDrawer={toggleEditCategoryDrawer}
          row={row}
          t={t}
        />
      )}
    </Box>
  );
};

export default ActionsCell;
