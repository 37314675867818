const IconPanel = ({ className, handleClick }: { className: string; handleClick: () => void }) => (
  <svg
    className={className}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    onClick={handleClick}
  >
    <title>273F7AAB-CE05-4D73-BB7C-D9B3028415F8</title>
    <g id="Reports" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="TaxPro-Report-Trace-v2-Card-3" transform="translate(-1874.000000, -108.000000)">
        <g id="Buttons/Button-Icon-3-(Contained)" transform="translate(1868.000000, 102.000000)">
          <g id="Icon/PanelClose" transform="translate(6.000000, 6.000000)">
            <polygon id="Path" points="0 0 24 0 24 24 0 24" />
            <path
              d="M18,2 C17.2410482,2 13.2410482,2 6,2 C4.9,2 4,2.9 4,4 L4,20 C4,21.1 4.89,22 5.99,22 L18,22 M18,20 L6,20 L6,4 L18,4"
              id="Shape"
              fill="#677283"
            />
            <polygon
              id="Path"
              fill="#677283"
              points="13 16.3399562 14.2235227 17.5 20 12.003079 14.2235227 6.5 13 7.66004383 17.5529547 12.003079"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default IconPanel;
