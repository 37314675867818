import { LEVELS } from '../../../../constants';
import { Step } from '../../../../models';

export const STATE_LEVEL = LEVELS.STATE;
export const MODIFICATIONS_STEP: Step = 'modifications';
export const STATE_MODIFICATIONS = `state.modifications`;
export const MODIFICATIONS_PERMANENT: Step = `modifications.permanent`;
export const MODIFICATIONS_TEMPORARY: Step = `modifications.temporary`;
export const STATE_MODIFICATIONS_PERMANENT = `state.modifications.permanent`;
export const STATE_MODIFICATIONS_TEMPORARY = `state.modifications.temporary`;
