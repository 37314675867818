import { createReducer } from '@reduxjs/toolkit';

import {
  containersOnFailed,
  containersOnReceived,
  containersOnSendingRequest,
  containersOnCurrentSwitched
} from './containers.actions';
import { ContainersInitialState } from './containers.initialState';

import { FinalizedContainerStatuses } from '../../constants';
import { Container, ContainerFromApi } from '../../models';

const getOffsetDate = (date: string) => {
  const parsedDate = new Date(date);
  return new Date(parsedDate.getTime() + parsedDate.getTimezoneOffset() * 60 * 1000);
};

const mapContainerFromApiToContainer = (container: ContainerFromApi): Container => ({
  startDate: container.periodStart
    ? getOffsetDate(container.periodStart).toISOString()
    : new Date(container.taxYear, 0, 1).toISOString(),
  endDate: container.periodEnd
    ? getOffsetDate(container.periodEnd).toISOString()
    : new Date(container.taxYear, 11, 31).toISOString(),
  containerId: container.containerUuid,
  containerName: container.name,
  tenantId: container.tenantUuid,
  tenantName: container.tenant,
  taxYear: container.taxYear,
  isInterimReportingPeriod: Boolean(container.isInterimReportingPeriod),
  containerStatus: container.containerStatus,
  isFinalized: Number(FinalizedContainerStatuses.includes(container.containerStatus)),
  finalizedAt: container.finalizedAt
});

export const containersReducer = createReducer(ContainersInitialState, (builder) => {
  builder
    .addCase(containersOnSendingRequest, (state) => {
      return { ...state, isLoading: true, error: null };
    })
    .addCase(containersOnReceived, (state, action) => {
      // eslint-disable-next-line unicorn/no-array-callback-reference
      const containers: Container[] = action.payload.map(mapContainerFromApiToContainer);

      const workingContainer = action.payload.find((container) => container.isWorkingContainer === 1);

      return {
        containers,
        currentContainer: workingContainer && mapContainerFromApiToContainer(workingContainer),
        hasLoaded: true,
        isLoading: false,
        error: null
      };
    })
    .addCase(containersOnFailed, (state, action) => {
      return {
        ...state,
        hasLoaded: true,
        isLoading: false,
        error: action.payload
      };
    })
    .addCase(containersOnCurrentSwitched, (state, action) => {
      return {
        ...state,
        currentContainer: state.containers.find((container) => container.containerId === action.payload),
        hasLoaded: false,
        isLoading: false,
        error: null
      };
    });
});
