import { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { AuditTrailFromApi, AuditTrailRequestFilters, AuditTrailStateInterface } from '../../models';
import { auditTrailOnSendingRequest, auditTrailOnReceived, auditTrailOnFailed } from '../../redux/auditTrail';
import { selectAuditTrail } from '../../selectors/auditTrail';
import HTTPService, { LambdaResponse } from '../../services/http';

export function useAuditTrail(filters: AuditTrailRequestFilters, pageNumber: number, pageSize: number) {
  const dispatch = useDispatch();
  const auditTrailState: AuditTrailStateInterface = useSelector(selectAuditTrail);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    dispatch(auditTrailOnSendingRequest());
    try {
      const { data: auditTrail } = await HTTPService.request<LambdaResponse<AuditTrailFromApi>>({
        method: 'get',
        relativePath: '/v1/audit-trail',
        params: { ...filters, pageNumber, pageSize }
      });
      dispatch(auditTrailOnReceived(auditTrail));
    } catch (error: unknown) {
      dispatch(auditTrailOnFailed(error as any));
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, filters, pageNumber, pageSize]);

  useEffect(() => {
    void fetchData();
  }, [fetchData]);

  return {
    fetchData,
    isLoading,
    ...auditTrailState
  };
}
