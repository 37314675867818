import { calculateReturnToProvision } from './return-to-provision';
import { GenericNumericalRecord, GenericRecord } from './utils';

interface CalculateBalanceSheetReturn {
  [key: string]: any;
  rtp: number;
  beginningAdjusted: number;
  endingAdjusted: number;
  m1Adjustment: number;
}

export const calculateBalanceSheet = (inputs: GenericRecord = {}): CalculateBalanceSheetReturn => {
  const { beginningBalance, beginningPayments, endingBalance, endingPayments }: GenericNumericalRecord = inputs;
  const { rtp } = calculateReturnToProvision(inputs);
  const beginningAdjusted = (beginningBalance ?? 0) + (beginningPayments ?? 0) + rtp;
  const endingAdjusted = (endingBalance ?? 0) + (endingPayments ?? 0);
  return {
    ...inputs,
    rtp,
    beginningAdjusted,
    endingAdjusted,
    m1Adjustment: endingAdjusted - beginningAdjusted
  };
};
