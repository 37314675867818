import { useMemo } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { CATEGORIZABLE_SECTIONS_BY_TAB } from '../../constants';
import { Level, Step } from '../../models';

export const useCategorizableSections = (level: Level, step: Step) => {
  const { prov3111FederalRtpCategories, prov3112StateRtpCategories } = useFlags();

  const stepName = useMemo(() => `${level}.${step}`, [level, step]);

  switch (stepName) {
    case 'federal.rtp':
      return prov3111FederalRtpCategories ? CATEGORIZABLE_SECTIONS_BY_TAB[level]?.[step] : undefined;
    case 'state.rtp':
      return prov3112StateRtpCategories ? CATEGORIZABLE_SECTIONS_BY_TAB[level]?.[step] : undefined;
    default:
      return CATEGORIZABLE_SECTIONS_BY_TAB[level]?.[step];
  }
};
