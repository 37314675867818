import { AccountShape } from '../../../../models';

export const isString = (element: string) => Number.isNaN(Number(element));
export const isNumberString = (element: string) => !Number.isNaN(Number(element));

export const alphaSortById = (a: AccountShape, b: AccountShape) => alphaSort(a.id, b.id);

export const alphaSort = (a: string, b: string) => {
  if (isString(a[0]) && isNumberString(b[0])) {
    return -1;
  }

  if (isNumberString(a[0]) && isString(b[0])) {
    return 1;
  }

  return a < b ? -1 : a > b ? 1 : 0;
};
