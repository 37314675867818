import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { abbreviateNumber } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  totalContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.palette.primary.main,
    height: '56px',
    width: (props: { width: string }) => props.width ?? 'fit-content',
    borderRadius: '4px',
    boxShadow: '0 0 13px 0 rgba(0,0,0,0.26)',
    marginTop: '16.5px',
    paddingRight: '21px'
  },
  totalTextContainer: {
    paddingLeft: '31px',
    paddingRight: '115px'
  },
  totalText: {
    height: '22px',
    width: '258px',
    color: theme.palette.common.white,
    fontFamily: theme.typography.body2.fontFamily,
    letterSpacing: theme.typography.body2.letterSpacing,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight,
    lineHeight: '22px'
  },
  baselineTaxEffected: {
    height: '36px',
    width: '121px',
    color: theme.palette.common.white,
    fontFamily: theme.typography.body2.fontFamily,
    letterSpacing: theme.typography.h3.letterSpacing,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight,
    textAlign: 'right',
    lineHeight: '22px',
    marginRight: '9px',
    paddingTop: '5px'
  },
  baselineTaxRate: {
    height: '36px',
    width: '84px',
    color: theme.palette.common.white,
    fontFamily: theme.typography.body2.fontFamily,
    letterSpacing: theme.typography.h3.letterSpacing,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight,
    textAlign: 'right',
    lineHeight: '22px',
    marginRight: '9px',
    paddingTop: '5px'
  },
  comparisonTaxEffected: {
    height: '36px',
    width: '121px',
    color: theme.palette.common.white,
    fontFamily: theme.typography.body2.fontFamily,
    letterSpacing: theme.typography.h3.letterSpacing,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight,
    textAlign: 'right',
    lineHeight: '22px',
    marginRight: '9px',
    paddingTop: '5px'
  },
  comparisonTaxRate: {
    height: '36px',
    width: '84px',
    color: theme.palette.common.white,
    fontFamily: theme.typography.body2.fontFamily,
    letterSpacing: theme.typography.h3.letterSpacing,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight,
    textAlign: 'right',
    lineHeight: '22px',
    marginRight: '9px',
    paddingTop: '5px'
  },
  differencesTaxEffected: {
    height: '36px',
    width: '121px',
    color: theme.palette.common.white,
    fontFamily: theme.typography.body2.fontFamily,
    letterSpacing: theme.typography.h3.letterSpacing,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight,
    textAlign: 'right',
    lineHeight: '22px',
    marginRight: '9px',
    paddingTop: '5px'
  },
  differencesTaxRate: {
    height: '36px',
    width: '84px',
    color: theme.palette.common.white,
    fontFamily: theme.typography.body2.fontFamily,
    letterSpacing: theme.typography.h3.letterSpacing,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight,
    textAlign: 'right',
    lineHeight: '22px',
    marginRight: '9px',
    paddingTop: '5px'
  },
  totalNumbersContainer: {
    alignContent: 'center',
    borderRadius: '4px',
    backgroundColor: theme.palette.info.dark
  },
  baselineTaxEffectedContainer: {
    alignContent: 'center',
    borderRadius: '4px',
    backgroundColor: theme.palette.info.dark,
    marginRight: '12px'
  },
  baselineTaxRateContainer: {
    alignContent: 'center',
    borderRadius: '4px',
    backgroundColor: theme.palette.info.dark,
    marginRight: '150px'
  },
  comparisonTaxEffectedContainer: {
    alignContent: 'center',
    borderRadius: '4px',
    backgroundColor: theme.palette.info.dark,
    marginRight: '21px',
    marginLeft: '44px'
  },
  comparisonTaxRateContainer: {
    alignContent: 'center',
    borderRadius: '4px',
    backgroundColor: theme.palette.info.dark,
    marginRight: '233px'
  },
  differencesTaxEffectedContainer: {
    alignContent: 'center',
    borderRadius: '4px',
    backgroundColor: theme.palette.info.dark,
    marginLeft: '6px'
  },
  differencesTaxRateContainer: {
    alignContent: 'center',
    borderRadius: '4px',
    backgroundColor: theme.palette.info.dark,
    marginLeft: '11px'
  },
  differencesContainer: {
    display: 'flex',
    marginLeft: 'auto'
  }
}));

type totalBarProps = {
  totalTaxEffected: number;
  totalTaxRate: number;
  totalTaxEffectedComparison: number;
  totalTaxRateComparison: number;
  totalTaxEffectedDifference: number;
  totalTaxRateDifference: number;
  width: string;
};

const convertNumberToTwoDecimalPlacesPct = (num: number) =>
  `${Number.parseFloat(((num ?? 0) * 100).toFixed(2) ?? '0')}%`;

const roundNumberLocaleString = (num: number) => Math.round(num).toLocaleString('en-US');

const TotalBarTrendAnalysis = ({
  totalTaxEffected,
  totalTaxRate,
  totalTaxEffectedComparison,
  totalTaxRateComparison,
  totalTaxEffectedDifference,
  totalTaxRateDifference,
  width
}: totalBarProps) => {
  const classes = useStyles({ width });
  const { t } = useTranslation();
  return (
    <Box className={classes.totalContainer}>
      <Box className={classes.totalTextContainer}>
        <Typography className={classes.totalText}>
          {`${t('Total Tax Provision')} / ${t('Effective Tax Rate')}`}
        </Typography>
      </Box>
      <Box className={classes.baselineTaxEffectedContainer}>
        <Typography className={classes.baselineTaxEffected}>
          {abbreviateNumber(roundNumberLocaleString(totalTaxEffected))}
        </Typography>
      </Box>
      <Box className={classes.baselineTaxRateContainer}>
        <Typography className={classes.baselineTaxRate}>
          {abbreviateNumber(convertNumberToTwoDecimalPlacesPct(totalTaxRate))}
        </Typography>
      </Box>
      <Box className={classes.comparisonTaxEffectedContainer}>
        <Typography className={classes.comparisonTaxEffected}>
          {abbreviateNumber(roundNumberLocaleString(totalTaxEffectedComparison))}
        </Typography>
      </Box>
      <Box className={classes.comparisonTaxRateContainer}>
        <Typography className={classes.comparisonTaxRate}>
          {abbreviateNumber(convertNumberToTwoDecimalPlacesPct(totalTaxRateComparison))}
        </Typography>
      </Box>
      <Box className={classes.differencesContainer}>
        <Box className={classes.differencesTaxEffectedContainer}>
          <Typography className={classes.differencesTaxEffected}>
            {abbreviateNumber(roundNumberLocaleString(totalTaxEffectedDifference))}
          </Typography>
        </Box>
        <Box className={classes.differencesTaxRateContainer}>
          <Typography className={classes.differencesTaxRate}>
            {abbreviateNumber(convertNumberToTwoDecimalPlacesPct(totalTaxRateDifference))}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TotalBarTrendAnalysis;
