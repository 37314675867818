import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import HTTPService, { LambdaResponse } from '../../services/http';

export interface FileMetadata {
  fileName: string;
  lastModified: string;
}

export const setUploadManagerIsOpen = createAction<boolean>('uploadManager/setUploadManagerIsOpen');
export const setUploadManagerCreditName = createAction<string | undefined>('uploadManager/setUploadManagerCreditName');
export const setFileName = createAction<string | null>('uploadManager/setFileName');
export const setIsFileUploaded = createAction<boolean>('uploadManager/setIsFileUploaded');
export const setIsFileDownloaded = createAction<boolean>('uploadManager/setIsFileDownloaded');
export const resetUploadManager = createAction('uploadManager/resetUploadManager');

export const fetchFileMetadata = createAsyncThunk<FileMetadata[], void, { rejectValue: Error }>(
  'uploadManager/fetchFileMetadata',
  async (_, { rejectWithValue }) => {
    try {
      const { data: metaDataList } = await HTTPService.request<LambdaResponse<FileMetadata[]>>({
        method: 'get',
        relativePath: '/v1/data-imports'
      });
      return metaDataList;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchFile = createAsyncThunk<any, string, { rejectValue: Error }>(
  'uploadManager/fetchFile',
  async (fileName: string, { rejectWithValue }) => {
    try {
      const { data } = await HTTPService.request<
        LambdaResponse<{ preSignedUrl: string; fileName: string; fileVersion: string }>
      >({
        method: 'get',
        relativePath: `/v1/data-imports/${fileName}`
      });

      const { preSignedUrl, fileVersion } = data;
      const response: any = await HTTPService.simpleRequest({
        method: 'get',
        url: preSignedUrl,
        responseType: 'arraybuffer'
      });
      return { fileVersion, file: response };
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);
