import { Jurisdiction, JurisdictionFromApi, SubJurisdiction } from '../models';

export function findJurisdictionName(jurisdictions: any, searchedId: any) {
  return (jurisdictions || []).find(({ id }: any) => id === searchedId)?.name;
}

export function findStateMap(jurisdictions?: Jurisdiction[], jurisdictionId?: string) {
  const subJurisdictionById: Map<string, SubJurisdiction> = new Map();
  if (Array.isArray(jurisdictions)) {
    for (const { id, subJurisdictions } of jurisdictions) {
      if (id === jurisdictionId) {
        for (const subJurisdiction of subJurisdictions ?? []) {
          subJurisdictionById.set(subJurisdiction.id, subJurisdiction);
        }
      }
    }
  }

  return subJurisdictionById;
}

export function formatSubJurisdictionForDisplay(t: any, state: SubJurisdiction = {} as SubJurisdiction) {
  return {
    ...state,
    name: state?.isCombined ? t('_ combined', state) : state?.name
  };
}

export const addIdToJurisdictions = (jurisdictionsFromApi: JurisdictionFromApi[]): Jurisdiction[] => {
  return jurisdictionsFromApi.map((item) => ({
    ...item,
    id: item.jurisdictionId,
    subJurisdictions: item.subJurisdictions.map((subJurisdiction) => ({
      ...subJurisdiction,
      id: subJurisdiction.subJurisdictionId
    }))
  })) as Jurisdiction[];
};

export const sortSubJursByName = (subJurA: SubJurisdiction, subJurB: SubJurisdiction): number =>
  subJurA.name === 'Blended State'
    ? 1
    : subJurB.name === 'Blended State'
    ? -1
    : subJurA.name < subJurB.name
    ? -1
    : subJurA.name > subJurB.name
    ? 1
    : 0;
