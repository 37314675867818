import React from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Dialog, DialogActions, DialogContent, makeStyles, Typography } from '@material-ui/core';

interface Props {
  dialogTitle: string;
  dialogBody?: string;
  onSubmit: () => void;
  open: boolean;
  injectedClasses?: Record<string, any>;
  renderDialogTitle?: (classes: string) => any;
  setOpen: (boolean: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  confirmButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    fontSize: '13px',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CC`
    }
  },
  cancelButton: {
    fontSize: '13px',
    borderRadius: '4px'
  },
  dialogTitle: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.h3.fontSize,
    padding: '10px 20px 10px 20px'
  },
  dialogActions: {},
  dialogContent: {},
  dialog: {}
}));

const ConfirmationDialog = ({
  dialogTitle,
  dialogBody,
  onSubmit,
  setOpen,
  open,
  injectedClasses,
  renderDialogTitle
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleClose = (e: any) => {
    setOpen(false);
    e.stopPropagation();
    e.preventDefault();
  };

  const handleSubmitAndClose = (e: any) => {
    onSubmit();
    setOpen(false);
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <Dialog open={open} className={injectedClasses?.dialog ?? classes.dialog} onClose={handleClose}>
      {renderDialogTitle ? (
        renderDialogTitle(injectedClasses?.dialogTitle ?? classes.dialogTitle)
      ) : (
        <Typography className={injectedClasses?.dialogTitle ?? classes.dialogTitle} id="alert-dialog-title">
          {dialogTitle}
        </Typography>
      )}

      {dialogBody ? (
        <DialogContent className={injectedClasses?.dialogContent ?? classes.dialogContent}>{dialogBody}</DialogContent>
      ) : (
        <div />
      )}

      <DialogActions className={injectedClasses?.dialogActions ?? classes.dialogActions}>
        <Button
          data-testid="cancelBtn"
          className={injectedClasses?.cancelButton ?? classes.cancelButton}
          aria-label="cancel button"
          onClick={handleClose}
        >
          {t('Cancel')}
        </Button>

        <Button
          data-testid="confirmBtn"
          className={injectedClasses?.confirmButton ?? classes.confirmButton}
          aria-label="confirm button"
          onClick={handleSubmitAndClose}
        >
          {t('Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
