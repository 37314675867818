import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { WorkBook } from 'xlsx';

import FileUploader from './components/FileUploader/FileUploader';

import { useContainers, useDropzoneForSpreadsheet } from '../../../../hooks';
import { resetDataImport } from '../../../../redux/dataImport';
import { getUploadHistory } from '../../../../redux/uploadHistory';
import { selectUploadHistoryState } from '../../../../selectors';
import { formatDate } from '../../../../utils';
import InfoCard from '../InfoCard/InfoCard';
import UploadOverview from '../UploadOverview/UploadOverview';

const useStyles = makeStyles((theme) => ({
  container: {
    borderTop: `1px solid ${theme.palette.divider}`,
    borderRadius: '0.25rem',
    display: 'flex',
    padding: '3.75rem',
    paddingBottom: '3.06rem',
    paddingTop: '1.5rem',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    gap: '25px'
  },
  fileUploader: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridTemplateRows: '1fr',
    gap: '26px',
    '& > *': {
      gridColumn: 'span 1'
    },
    '& > *:last-child': {
      gridColumn: '4'
    },
    height: 'fit-content'
  },
  tableContainer: {
    height: '100%',
    overflow: 'auto'
  }
}));

export type MainPageProps = {
  setWorkbook: (workbook: WorkBook) => void;
};

const reviewIsPendingOrInProgress = (upload: any) =>
  ['review-pending', 'review-in-progress'].includes(upload?.statusName);

const createHeaderContent = (upload: any) => {
  const messages = [];
  if (upload) {
    messages.push(
      {
        text: upload.fileName,
        type: 'info'
      },
      {
        text: `V${upload.version as string}`,
        type: 'info'
      },
      {
        text: reviewIsPendingOrInProgress(upload) ? 'Link Data' : 'Summary',
        type: 'warning'
      }
    );
  } else {
    messages.push({
      text: 'No uploads',
      type: 'info'
    });
  }

  return messages;
};

const formatUserName = (upload: any) =>
  Boolean(upload?.uploadUserFirstName) &&
  Boolean(upload?.uploadUserLastName) &&
  upload?.uploadUserLastName !== 'placeholer' // NOTE: This is the user with user_id = 1. This means the upload comes from the Dynamo-to-S3 migration.
    ? `${upload?.uploadUserFirstName?.[0] as string}${upload?.uploadUserLastName as string}`
    : '';

const createTableArgs = (uploads: any[], history: any) => ({
  rows: uploads.map((upload: any, index: number) => ({
    name: upload.fileName,
    type: 'Trial Balance',
    uploaded: formatDate(upload.uploadedAt, 'DD MMMM YY HH:mm'),
    user: formatUserName(upload),
    status: reviewIsPendingOrInProgress(upload) ? ['warning', 'DATA LINKING REQUIRED'] : ['info', 'IMPORTED'],
    action: reviewIsPendingOrInProgress(upload) ? ['warning', 'Link Data'] : [null, 'Summary'],
    onClickAction:
      reviewIsPendingOrInProgress(upload) && index === 0
        ? () => history.push(`/upload-review/${uploads?.[0]?.uploadId as string}`)
        : undefined,
    version: upload?.version as string
  }))
});

const MainPage = ({ setWorkbook }: MainPageProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { getInputProps, getRootProps, open, workbook } = useDropzoneForSpreadsheet();
  const classes = useStyles();
  const [headerContent, setHeaderContent] = useState<any>([]);
  const [tableArgs, setTableArgs] = useState<any>([]);
  const { currentContainer } = useContainers();
  const uploadHistoryState = useSelector(selectUploadHistoryState);
  const latestUpload = uploadHistoryState?.uploads?.[0];

  useEffect(() => {
    setWorkbook(workbook);
  }, [workbook, setWorkbook]);

  useEffect(() => {
    const messages = createHeaderContent(latestUpload);
    setHeaderContent(messages);
  }, [latestUpload]);

  useEffect(() => {
    dispatch(resetDataImport());
  }, [dispatch]);

  useEffect(() => {
    const { uploads } = uploadHistoryState;
    const args = createTableArgs(uploads, history);
    setTableArgs(args);
  }, [history, uploadHistoryState]);

  useEffect(() => {
    dispatch(getUploadHistory());
  }, [dispatch]);

  return (
    <Box className={classes.container}>
      <Box className={classes.fileUploader}>
        <InfoCard
          isLoading={uploadHistoryState.isLoading}
          title="Latest upload"
          contentMessages={headerContent}
          footerMessage={formatDate(latestUpload?.uploadedAt, 'DD MMMM YY HH:mm') ?? ''}
        />
        <FileUploader
          currentContainer={currentContainer}
          openFileBrowser={open}
          getInputProps={getInputProps}
          getRootProps={getRootProps}
        />
      </Box>
      <Box className={classes.tableContainer}>
        <UploadOverview isLoading={uploadHistoryState.isLoading} {...tableArgs} />
      </Box>
    </Box>
  );
};

export default MainPage;
