import { calculateBalanceSheet } from './balance-sheet';
import { calculateReturnToProvision } from './return-to-provision';
import { GenericNumericalRecord, GenericRecord } from './utils';

import { FederalShape } from '../components/EntityDetails/utils';
import { findAdjustment, foundAdjustment } from '../utils/adjustments';

interface CalculateModificationReturn extends GenericRecord {
  rtp: number;
  m1Adjustment: number;
}

export const calculateModification = (inputs: GenericRecord = {}): CalculateModificationReturn => {
  const { amount, federalAmount }: GenericNumericalRecord = inputs;
  return {
    ...inputs,
    rtp: calculateReturnToProvision(inputs).rtp,
    m1Adjustment: (amount ?? 0) - (federalAmount ?? 0)
  };
};

function getTemporaryFederalAmount(modification: GenericRecord, temporary: GenericRecord, rtp: GenericRecord) {
  let account = findAdjustment(modification, temporary.incomeStatement);
  if (foundAdjustment(account)) {
    return (account.bookBalance ?? 0) - (account.taxBalance ?? 0);
  }

  account = findAdjustment(modification, temporary.balanceSheet);
  return calculateBalanceSheet({ ...account, ...findAdjustment(account, rtp?.temporary) }).m1Adjustment;
}

export function getFederalAmountForModification(
  modification: GenericRecord,
  { temporary, permanent, rtp }: FederalShape
) {
  const adjustment = findAdjustment(modification, permanent);
  const isPermanent = foundAdjustment(adjustment);
  return {
    isPermanent,
    federalAmount: isPermanent
      ? adjustment.amount * adjustment.disallowedPct
      : // @ts-expect-error TODO - temporary and rtp supposedly can be undefined, we need to figure out why
        getTemporaryFederalAmount(modification, temporary, rtp)
  };
}
