import { UPLOAD_REVIEW_TABS } from '../../constants';
import {
  Category,
  AccountRange,
  UploadReviewAccount,
  EntityFromUpload,
  UploadReviewTrialBalanceMetaData
} from '../../models';

const tabKeys = UPLOAD_REVIEW_TABS.map(({ key }) => key);

export interface UploadReviewStateBaseDataInterface {
  entities: EntityFromUpload[];
  ranges: AccountRange[];
  categories: Category[];
  accounts: UploadReviewAccount[];
}

export interface UploadReviewStateInterface extends UploadReviewStateBaseDataInterface {
  hasLoaded: boolean;
  hasMetaDataLoaded: boolean;
  entityParent: EntityFromUpload | null;
  tab: string;
  isUploadReviewCompleted?: boolean;
  isLoading: boolean;
  error: unknown;
  metaData: UploadReviewTrialBalanceMetaData;
  isInitialUploadLoading: boolean;
  hasInitialUploadLoaded: boolean;
}

export const uploadReviewInitialState: UploadReviewStateInterface = {
  tab: tabKeys[0],
  isLoading: false,
  hasLoaded: false,
  isInitialUploadLoading: false,
  hasInitialUploadLoaded: false,
  hasMetaDataLoaded: false,
  error: null,
  entityParent: null,
  entities: [],
  accounts: [],
  ranges: [
    { id: 'assets', range: [] },
    { id: 'liabilities', range: [] },
    { id: 'shareholdersEquity', range: [] },
    { id: 'profitAndLoss', range: [] }
  ],
  categories: [
    { id: 'temporary-adjustments', accountIds: [] },
    { id: 'permanent-adjustments', accountIds: [] },
    { id: 'income-tax', accountIds: [] }
  ],
  metaData: {
    error: null,
    fileName: '',
    updatedOn: '',
    containerId: '',
    isUploadReviewCompleted: false
  }
};
