import { Except } from 'type-fest';

import { EntityById, SummaryEntityDataRow, SummaryReportHeader, SummaryReportRow } from '../../../../models';
import { federalTab, foreignTab, stateTab, Tab, totalTab } from '../../utils';

const ROW_NAME_AND_TAB_FROM_ROW_TYPE = {
  federal: { name: 'Total Federal', tab: federalTab },
  foreign: { name: 'Total Foreign', tab: foreignTab },
  state: { name: 'Total State / Cities', tab: stateTab }
};

const getRowNameAndTabFromType = (rowType: string) => {
  const indexOfFirstUppercase = rowType.search(/[A-Z]/);
  const rowNameKey = rowType.slice(0, indexOfFirstUppercase) as 'federal' | 'foreign' | 'state';
  return ROW_NAME_AND_TAB_FROM_ROW_TYPE[rowNameKey] ?? {};
};

export function parseEntitiesAndTotalsFromDashboardReport(
  reportRows: SummaryReportRow[],
  entityById: EntityById
): SummaryEntityDataRow[] {
  const entitiesWithValuesObj: Record<string, SummaryEntityDataRow> = {};
  reportRows.forEach(({ name, value }) => {
    const [rowType, entityId] = name.split('_') as [
      keyof Except<SummaryEntityDataRow, 'name' | 'showOnTabs' | 'isEntityRow'>,
      string
    ];

    const isEntityRow = Boolean(entityId);
    const isEntityOrTotalRow = isEntityRow || rowType.endsWith('Tab');

    if (isEntityOrTotalRow) {
      const { name: rowNameFromType, tab: tabFromType } = getRowNameAndTabFromType(rowType);
      const rowName = isEntityRow ? entityById[entityId]?.name : rowNameFromType;
      entitiesWithValuesObj[rowName] = entitiesWithValuesObj[rowName] ?? {};
      entitiesWithValuesObj[rowName].name = rowName;
      entitiesWithValuesObj[rowName][rowType] = value;
      entitiesWithValuesObj[rowName].isEntityRow = isEntityRow;
      let showOnTabs: Tab[] = [];
      if (isEntityRow) {
        showOnTabs = [totalTab];
        showOnTabs = entityById[entityId]?.isLocal ? [...showOnTabs, federalTab] : [...showOnTabs, foreignTab];
        showOnTabs = entityById[entityId]?.hasStates ? [...showOnTabs, stateTab] : showOnTabs;
      } else {
        showOnTabs = [tabFromType];
      }

      entitiesWithValuesObj[rowName].showOnTabs = showOnTabs;
    }
  });
  const entitiesWithValues = Object.values(entitiesWithValuesObj);
  return entitiesWithValues;
}

export function getEntityRowsForSummaryTab(
  entitiesWithValues: SummaryEntityDataRow[],
  tab: keyof Except<SummaryEntityDataRow, 'name'>
) {
  return entitiesWithValues.filter((row) => row.isEntityRow).map(({ name, [tab]: value }) => ({ name, value }));
}

export function filterRowsByTab(reportEntityRows: SummaryEntityDataRow[], tab: Tab) {
  return reportEntityRows.filter((row) => row.showOnTabs.includes(tab));
}

export function parseHeaderValuesFromReport(reportRows: SummaryReportRow[]): SummaryReportHeader {
  const headerValues: SummaryReportHeader = {};
  reportRows.forEach(({ name, value }) => {
    const [rowType, _] = name.split('_') as [keyof SummaryReportHeader, string];
    const isHeader = rowType.endsWith('Box');
    if (isHeader) {
      headerValues[rowType] = value;
    }
  });
  return headerValues;
}
