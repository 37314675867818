import { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { ContentAndTitle, TabTitle } from '../';
import { TableWithComment } from '../../..';
import { FEDERAL_UUID, LEVELS } from '../../../../constants';
import { useCompletionStatus, useCurrencies, useFinancialData } from '../../../../hooks';
import { Entity, Step, SubJurisdiction } from '../../../../models';
import { setEntityCompletionStatus } from '../../../../redux/entitiesCompletionStatus';
import LoadingWrapper from '../../../LoadingWrapper';
import { Row } from '../../../Table/Table.proptype';
import {
  ApportionShape,
  EntityNumberRouteMatch,
  getTableDataFromFinancialData,
  handleEditRowForEntityDetails,
  handleOnCellOrCommentBlurForEntityDetails
} from '../../utils';

export type ApportionRow = Row & ApportionShape;

type Props = {
  entityId: Entity['entityId'];
  states?: SubJurisdiction[];
};

const LEVEL = LEVELS.STATE;
const STEP: Step = 'apportionment';
const LevelAndStep = `${LEVEL}.${STEP}`;

const Apportion = ({ entityId, states = [] }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    prov3322EditPerformanceFix: isPerformanceFixEnabled,
    prov3250AddColumnsToApportionmentTab: showAdditionalColumns
  } = useFlags();
  const [rows, setRows] = useState<ApportionRow[]>([]);
  const {
    params: { entityNumber }
  } = useRouteMatch<EntityNumberRouteMatch>();
  const { stepCompletionStatus } = useCompletionStatus(entityNumber, LevelAndStep, FEDERAL_UUID);
  const { tabsData, failedCells, isFetchLoading } = useFinancialData(entityNumber, LEVEL, [STEP]);
  const { currencyByEntityIdMap } = useCurrencies();
  const currencyIsoCode = currencyByEntityIdMap[entityId]?.isoCode;
  const financialInfo = useMemo(() => tabsData[LevelAndStep] ?? [], [tabsData]);
  const [rowNames, jurisdictionIdByName] = useMemo(() => {
    const rowNames: Array<{ name: string }> = [];
    const jurisdictionIdByName: Record<string, string> = {};
    states.forEach((state) => {
      rowNames.push({ name: state.name });
      jurisdictionIdByName[state.name] = state.subJurisdictionId;
    });
    return [rowNames, jurisdictionIdByName];
  }, [states]);
  const rowsWithData = useMemo(() => getTableDataFromFinancialData(rowNames, financialInfo), [financialInfo, rowNames]);

  useEffect(() => {
    setRows(rowsWithData);
  }, [rowsWithData]);

  const columns = showAdditionalColumns
    ? [
        {
          field: 'name',
          headerName: t('State'),
          width: '30%'
        },
        {
          field: 'beginning',
          headerName: t('Beginning'),
          isEditable: !stepCompletionStatus.status,
          isPercentage: true,
          isTotaled: true,
          width: '20%'
        },
        {
          field: 'amount',
          headerName: t('Current'),
          isEditable: !stepCompletionStatus.status,
          isPercentage: true,
          isTotaled: true,
          width: '20%'
        },
        {
          field: 'end',
          headerName: t('End'),
          isEditable: !stepCompletionStatus.status,
          isPercentage: true,
          isTotaled: true,
          width: '20%'
        }
      ]
    : [
        {
          field: 'name',
          headerName: t('State'),
          width: '30%'
        },
        {
          field: 'amount',
          headerName: t('Apportionment'),
          isEditable: !stepCompletionStatus.status,
          isPercentage: true,
          isTotaled: true,
          width: '20%'
        }
      ];

  const dataForMethods = {
    columns,
    dispatch,
    entityId,
    financialInfo,
    level: LEVEL,
    rows,
    setRows,
    step: STEP,
    t
  };

  return (
    <LoadingWrapper isLoading={isFetchLoading}>
      <ContentAndTitle
        title={
          <TabTitle
            currencyIsoCode={currencyIsoCode}
            title={t('Apportionment')}
            isCompleted={stepCompletionStatus.status}
            onCompletionChange={(checked) => {
              dispatch(
                setEntityCompletionStatus({
                  ...stepCompletionStatus,
                  newStatus: checked
                })
              );
            }}
          />
        }
      >
        <TableWithComment
          columns={columns}
          failedCells={failedCells}
          rows={rows}
          totalHeaderName={t('Total')}
          sort={{ column: columns[0], dir: TableWithComment.ASC }}
          hideActionsMenu={stepCompletionStatus.status}
          onCellChange={(params) => {
            if (!isPerformanceFixEnabled) {
              handleEditRowForEntityDetails({
                ...dataForMethods,
                ...params
              });
            }
          }}
          onCellOrCommentBlur={(params) => {
            if (isPerformanceFixEnabled) {
              handleEditRowForEntityDetails({
                ...dataForMethods,
                ...params
              });
            }

            handleOnCellOrCommentBlurForEntityDetails({
              ...dataForMethods,
              ...params,
              jurisdictionId: jurisdictionIdByName[params.row.name ?? '']
            });
          }}
        />
      </ContentAndTitle>
    </LoadingWrapper>
  );
};

export default Apportion;
