import { ChangeEvent, useEffect, useMemo, useState } from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowRight from '@material-ui/icons/ArrowRight';
import classnames from 'classnames';

import theme from '../../theme';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiAccordion-root.Mui-expanded': {
      margin: 0
    },
    '& .MuiAccordionDetails-root': {
      padding: 0
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 0
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: `${theme.spacing(1)}px 0 0 0`
    },
    '&.MuiButton-label': {
      width: '76px',
      height: '24px'
    }
  },
  accordion: {
    backgroundColor: theme.palette.common.white,
    boxShadow: 'none',
    color: theme.palette.text.dark,
    margin: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingDown: theme.spacing(0.5),
    paddingUp: theme.spacing(0.5),
    '&::before': {
      opacity: 0
    },
    '& .MuiAccordionSummary-root': {
      padding: 0
    }
  },
  accordionButton: {
    backgroundColor: theme.palette.secondary.dark,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    fontSize: '12px',
    justifyContent: 'left',
    height: '24px',
    lineHeight: '24px',
    padding: 0,
    paddingLeft: theme.spacing(0.5),
    width: '76px'
  },
  accordionDetails: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(0.5),
    marginBottom: theme.spacing(0.2),
    marginTop: theme.spacing(0.2),
    width: '100%'
  },
  flexColumn: {
    alignItems: 'baseline',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  menuItemSpacing: {
    borderRadius: theme.spacing(0.5),
    boxSizing: 'border-box',
    marginBottom: theme.spacing(0.2),
    marginTop: theme.spacing(0.2),
    '&:hover': {
      backgroundColor: theme.palette.info.main
    },
    textAlign: 'left',
    width: '100%'
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'left'
  },
  notSelectedMenuItem: {
    backgroundColor: theme.palette.common.white
  },
  selectedMenuItem: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold
  }
}));

export interface Props {
  // NOTE: The row values within each group are expected to be unique to that group.
  groupedRows?: Array<Record<string, string[]>>;
  onGroupClick?: (value: string, event?: ChangeEvent<Record<string, unknown>>, expanded?: boolean) => void;
  onRowClick?: (val: string, groupKey: string) => void;
  selectedRow?: {
    groupKey: string;
    value?: string;
  };
  options?: {
    isDefaultExpanded?: boolean;
    isSingleExpanded?: boolean;
  };
  classesOverrides?: Record<string, string>;
}

const getGroupKeys = (groupedRows: Array<Record<string, string[]>>) =>
  groupedRows.map((group) => Object.keys(group)[0]);

const MultiAccordion = ({
  groupedRows = [],
  onGroupClick,
  onRowClick,
  selectedRow,
  options = {},
  classesOverrides
}: Props) => {
  const classes = { ...useStyles(theme), ...classesOverrides };
  const groupKeys = useMemo(() => getGroupKeys(groupedRows), [groupedRows]);
  const [collapsedAccordions, setCollapsedAccordions] = useState<Set<string>>(
    new Set(options.isDefaultExpanded ? [] : groupKeys)
  );

  useEffect(() => {
    if (selectedRow) {
      setCollapsedAccordions((collAccs) => {
        const newSet = new Set(collAccs);
        newSet.delete(selectedRow.groupKey);
        return newSet;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.root}>
      {groupedRows.map((group) => {
        const groupKey = Object.keys(group)[0];
        const groupValues = Object.values(group)[0];

        return (
          <Accordion
            key={groupKey}
            expanded={!collapsedAccordions.has(groupKey)}
            data-testid="accordion"
            className={classes.accordion}
            onChange={(event, expanded) => {
              onGroupClick?.(groupKey, event, expanded);
              if (expanded) {
                setCollapsedAccordions((collAccs) => {
                  const newSet = new Set(options.isSingleExpanded ? groupKeys : collAccs);
                  newSet.delete(groupKey);
                  return newSet;
                });
              } else {
                setCollapsedAccordions((collAccs) => {
                  const newSet = new Set(collAccs);
                  newSet.add(groupKey);
                  return newSet;
                });
              }
            }}
          >
            <AccordionSummary>
              <Button
                data-testid="accordion-summary-button"
                className={classes.accordionButton}
                startIcon={collapsedAccordions.has(groupKey) ? <ArrowRight /> : <ArrowDropDown />}
              >
                {groupKey}
              </Button>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Box className={classnames(classes.accordionDetails, classes.flexColumn)}>
                {groupValues.map((val) => (
                  <Button
                    key={val}
                    data-testid="accordion-row"
                    className={classnames(classes.menuItemSpacing, classes.menuItem, {
                      [classes.selectedMenuItem]: selectedRow?.groupKey === groupKey && selectedRow?.value === val,
                      [classes.notSelectedMenuItem]: !(selectedRow?.groupKey === groupKey && selectedRow?.value === val)
                    })}
                    onClick={() => {
                      onRowClick?.(val, groupKey);
                    }}
                  >
                    {val}
                  </Button>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};

export default MultiAccordion;
