import { useMemo, useRef, useState, useEffect, MouseEventHandler } from 'react';

import { useTranslation } from 'react-i18next';

import { Box, Popover, makeStyles, useTheme, CircularProgress, Typography } from '@material-ui/core';

import { useDimensions } from './../../../../hooks';
import { formatPercentage } from './../../../../utils';
import { drawGroupBarChartJson } from './d3';

const useStyles = makeStyles((theme) => ({
  svgWrapper: {
    height: '100%',
    display: 'flex',
    minHeight: 300,
    width: '100%'
  },
  chartAxis: {
    font: 'inherit',
    fontSize: theme.typography.overline.fontSize,
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightBold,
    '& path': { color: theme.palette.divider },
    '& line': {
      visibility: 'hidden'
    }
  },
  chartBar: {
    cursor: 'pointer'
  },
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    background: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    width: '21.55em',
    height: '5em',
    boxShadow: 'none'
  },
  popoverContent: {
    '& *': {
      fontSize: theme.typography.overline.fontSize,
      fontWeight: theme.typography.fontWeightBold
    },
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: '100%',
    textTransform: theme.typography.overline.textTransform
  }
}));

type Props = {
  rows?: any[];
};

const GroupBarChart = ({ rows }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [hovered, setHovered] = useState<any>(null);
  const { t } = useTranslation();
  const { width, height, wrapperRef } = useDimensions();
  const colors = useMemo(
    () => ({
      border: theme.palette.divider,
      mainBackground: theme.palette.primary.main,
      secondaryBackground: theme.palette.secondary.main
    }),
    [theme]
  );

  const data = useMemo(() => {
    if (rows) {
      return rows.map((row) => ({
        name: row.name,
        taxRate: row.taxRate * 100,
        taxRateComparison: row.taxRateComparison * 100
      }));
    }
  }, [rows]);

  useEffect(() => {
    const findHoveredRow = (d: { key: string; name: string }) => ({
      key: d.key,
      ...(rows?.find?.((row) => row.name === d.name) ?? {})
    });
    if (svgRef.current && data) {
      drawGroupBarChartJson(svgRef.current, {
        width,
        height,
        colors,
        data,
        onEnter: (event: MouseEventHandler, d: any) => {
          setHovered({ event, ...findHoveredRow(d) });
        },
        onLeave: () => {
          setHovered(null);
        },
        classes
      });
    }
  }, [width, height, data, rows, colors, classes]);
  return (
    <>
      <Box ref={wrapperRef} className={classes.svgWrapper}>
        {rows ? <svg ref={svgRef} /> : <CircularProgress />}
      </Box>
      <Popover
        open={Boolean(hovered)}
        anchorReference="anchorPosition"
        anchorPosition={{ top: hovered?.event?.y ?? 0, left: hovered?.event?.x ?? 0 }}
        className={classes.popover}
        classes={{
          paper: classes.paper
        }}
      >
        {Boolean(hovered) && (
          <Box className={classes.popoverContent}>
            <Box>
              <Typography>{t('Tax Rate BaseLine')}</Typography>
              <Typography>{formatPercentage(hovered.taxRate)}</Typography>
            </Box>
            <Box>
              <Typography>{t('Tax Rate Comparison')}</Typography>
              <Typography>{formatPercentage(hovered.taxRateComparison)}</Typography>
            </Box>
          </Box>
        )}
      </Popover>
    </>
  );
};

export default GroupBarChart;
